<div class="left">
  <div class="btn full-copy-button"
    (click)="onClickCopyFullEvent()"
  ><edc-icon name="copy"></edc-icon> {{'auditing.copyToClipboard' | localize }}</div>
</div>
<div class="show-empty-switch-container right">
  <form materialize>
    <div class="switch">
      <label>
        {{ 'auditing.showEmptyFields' | localize }}
        <input type="checkbox" name="showEmptyFields" [(ngModel)]="showEmptyFields">
        <span class="lever"></span>
      </label>
    </div>
  </form>
</div>

<hr>

<div *ngIf="event && !loadingEventDetails">
  <table #eventDetailsTable class="striped">
    <ng-container *ngFor="let header of event.headers; let i = index;">
      <tr attr.data-fieldId="{{ header.id }}" class="field-row"
        *ngIf="showEmptyFields || formattedCells[i] !== ''"
        (mouseenter)="onRowHover(i)"
        (mouseleave)="onRowLeave()"
      >
        <th>{{header.label}}</th>

        <td><span [innerHTML]="formattedCells[i]"></span></td>

        <td class="value-actions">
          <div class="button-container">

            <ng-container *ngIf="hoveredRowNum === i">
              <ul id="event-action-dropdown-{{i}}" class="dropdown-content">
                <li *ngIf="displayAddFilter">
                  <span class="add-to-query-button" (click)="onClickAddClause()"
                    ><edc-icon name="filter"></edc-icon> {{ 'auditing.addFilterToQuery' | localize }}</span>
                </li>
                <li>
                  <span class="add-to-new-search-button" (click)="onClickNewSearch()"
                    ><edc-icon name="search"></edc-icon> {{ 'auditing.newSearchOnValue' | localize }}</span>
                </li>
              </ul>

              <ng-container *ngIf="showActionButtons">

                <span
                  (click)="onClickCopy(header)"
                  class="copy-to-clipboard-button tooltipped"
                  materialize="tooltip"
                  data-delay="50"
                  attr.data-tooltip="{{ 'auditing.copyToClipboard' | localize }}"
                  data-position="left"><edc-icon name="copy"></edc-icon></span>

                <div
                  class="more-options-button-container tooltipped right"
                  materialize="tooltip"
                  data-delay="50"
                  attr.data-tooltip="{{ 'auditing.moreActionsMenuTitle' | localize }}"
                  data-position="top"
                  *ngIf="header.drilldownAllowed">
                  <span
                  class="menu-button"
                  (click)="onClickMore(header)"
                  materialize="dropdown"
                  attr.data-activates="event-action-dropdown-{{i}}"
                  [materializeParams]="[dropdownOptions]"
                  ><edc-icon name="more"></edc-icon></span>
                </div>

              </ng-container>

            </ng-container>

          </div>
        </td>

      </tr>
    </ng-container>
  </table>

</div>

<textarea #clipboardArea class="clipboard-area" name="clipboard-area">{{textToCopy}}</textarea>
