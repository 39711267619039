import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WidgetID } from '../../../models/widget-info';
import { WidgetService } from '../../../../../../shared/services/widget-service';
import { TopActiveUsersWidgetResponse } from '../models/top-active-users.server.response';
import {
  UpdateTopActiveUsersSettings,
  UpdateWidgetConfiguration
} from '../../../../../../shared/models/update-widget-configuration';

@Injectable()
export class TopActiveUsersDataService {
  constructor(private widgetService: WidgetService) {}

  getTopActiveUser(): Observable<TopActiveUsersWidgetResponse> {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.TopActiveUsers)
      .pipe(
        map((response: HttpResponse<TopActiveUsersWidgetResponse>) => response.body)
      );
  }

  UpdateTopActiveUsersConfig(
    updateSettings: UpdateTopActiveUsersSettings
  ): Observable<HttpResponse<any>> {
    const updateObj = {
      topActiveUsers: updateSettings
    } as UpdateWidgetConfiguration;

    return this.widgetService.putWidgetSettingsWithHttpInfo(
      WidgetID.TopActiveUsers,
      updateObj
    );
  }
}
