import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { BaseComponent, BaseChartType } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { Query } from '../../../../auditing/models/query.model';
import { DonutChartDataExtractorService } from './services/donut-chart-data-extractor.service';
import { ChartQueryService } from '../services/chart-query.service';
import { EventFieldsService } from '../../../../auditing/services/event-fields.service';
import { Results } from '../../../../auditing/components/queries/results/results.model';
import { EventField } from '../../../../auditing/models/event-field.model';
import { isDonutChartQuery, getDonutChartId } from '../utils/chart-utils';
import { CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH } from '../../../critical-activity/configuration/critical-activity.config';
import { InvokeWithErrorHandling } from '../../../utils/error.handling.wrapper';
import { ChartColor, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import {
  LegendDataItem,
  LegendEventArgs,
  formatLegendItemLabel
} from './legend/legend-model';
import { ChartDataItem } from './chart-data-model';
import { ChartPosition } from '../../../../auditing/models/chart-options/chart-models';
import { chartCanvasBox } from '../models/chart-constants';
import { DonutConfigurationService } from './services/donut-config.generator';
import { ODChartColors } from '../../../models/oda-colors';

@Component({
  selector: 'donut-chart',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss']
})
export class DonutComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() query: Query;
  @Input() chartPosition: ChartPosition = chartCanvasBox.positionOne;
  @Output() renderCompleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() errorLoadingChart: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() chartAreaClick: EventEmitter<Query> = new EventEmitter<Query>();
  @ViewChild('canvasContainer', { static: true })
  canvasContainer: ElementRef<HTMLDivElement>;

  results: Results;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  noResultsImagePath = CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH;
  eventCount: number;
  totalEventCountLabel: string;
  totalEventsString: string;
  showEventCountTotal = true;
  legendContent: {};
  checked = true;
  chart: Chart;
  categoryLabels: string[];
  categoryValues: string[];
  originalCategoryValues: string[];
  chartLegendItems: LegendDataItem[];
  chartTempData: any[];
  chartOriginalDataMap: Map<string, ChartDataItem>;
  chartOptions: any;
  chartDataSets: ChartDataSets[];
  chartColors: Color[];
  legendTitle: string;
  readonly chartType = BaseChartType.Donut;
  readonly containerStyle = { width: '50%', height: '50%' };
  chartDataReady: boolean;

  // loading error props.
  error: boolean;
  errorText: string;
  tryAgainText: string;

  // query required
  private groupByField: EventField;

  private _loadingData: boolean;
  get loadingData(): boolean {
    return this._loadingData;
  }
  set loadingData(val: boolean) {
    if (this._loadingData !== val) {
      this._loadingData = val;
      this.renderCompleted.emit(this._loadingData);
    }
  }

  get groupByColumn() {
    return this.query.q.charts[0].donut.labelColumnName;
  }
  constructor(
    private chartService: ChartQueryService,
    private chartConfigService: DonutConfigurationService,
    private chartDataExtractorService: DonutChartDataExtractorService,
    private eventFieldsService: EventFieldsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.chartOriginalDataMap = new Map<string, ChartDataItem>();
    this.initializeLegendTitle();
    this.setupTemplateText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      InvokeWithErrorHandling(
        () => {
          this.runChartQuery();
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  runChartQuery(): void {
    if (isDonutChartQuery(this.query, 0)) {
      this.chartDataReady = false;
      this.error = false;
      this.loadingData = true;
      const chartId = getDonutChartId(this.query);
      this.chartService
        .runAdhocChartQuery(this.query, chartId)
        .pipe(
          take(1),
          catchError(err => throwError(err))
        )
        .subscribe(
          response =>
            InvokeWithErrorHandling(
              () => {
                this.renderDonutChart(response.body);
              },
              err => {
                this.handleError(err);
              }
            ),
          err => {
            this.handleError(err);
          }
        );
    }
  }

  /**
   * Uses chart query data to populate donut chart with legend.
   */
  renderDonutChart(results: Results): void {
    const chartQueryOptions = this.query.q.charts[0].donut;
    const groupingColumn = this.query.q.charts[0].donut.labelColumnName;

    setTimeout(() => {
      this.eventCount =
        this.chartDataExtractorService.countTotalEvents(results);
      this.showEventCountTotal =
        chartQueryOptions.showTotal && this.eventCount > 0;

      if (this.showEventCountTotal) {
        if (this.eventCount === 1) {
          this.totalEventCountLabel = 'Event';
        } else {
          this.totalEventCountLabel = chartQueryOptions.totalTextFormat;
        }
        this.totalEventsString = this.eventCount.toLocaleString();
      }

      // Further proceed only if event count is available.
      if (this.eventCount > 0) {
        const chartData = this.chartDataExtractorService.extractData(
          results,
          groupingColumn
        );

        const predefinedColorList = ODChartColors.slice(0, chartData[1].length);

        // changes with legend interaction
        this.categoryLabels = [...chartData[2]];

        const originalCategoryLabels = chartData[2];

        // originalCategoryValues holds original category values.
        this.originalCategoryValues = chartData[0];

        // categoryValues changes when lengend is interacted (items are enabled/disabled)
        this.categoryValues = [...chartData[0]];
        if (!this.chartTempData) {
          // Creates a deep copy of chart data which is used to keep track of ability to hide/show chart section.
          this.chartTempData = Array.from(chartData[1]).map((item, index) => ({
            item,
            label: this.categoryLabels[index],
            color: predefinedColorList[index]
          }));
        }

        Array.from(chartData[1]).forEach((item, index) => {
          this.chartOriginalDataMap.set(this.categoryLabels[index], {
            item: chartData[1][index],
            label: this.categoryLabels[index],
            value: this.categoryValues[index],
            color: predefinedColorList[index]
          } as ChartDataItem);
        });

        this.chartOptions = this.chartConfigService.generateDonutOptions(
          this.query,
          this.categoryLabels,
          this.categoryValues,
          this.groupByField,
          this.chartAreaClick
        );
        this.chartLegendItems = this.getLegendItems(
          originalCategoryLabels,
          this.originalCategoryValues,
          predefinedColorList
        );
        this.chartDataSets = this.getDataSet(chartData[1], predefinedColorList);
        this.chartColors = [{} as Color];

        this.chartDataReady = true;
      }
      this.loadingData = false;
    }, 100);
  }

  handleError(error: any): void {
    this.error = true;
    this.errorLoadingChart.emit(error);
    this.loadingData = false;
  }

  retryOnError() {
    this.runChartQuery();
  }

  legendItemSelectionChange(legendEvent: LegendEventArgs): void {
    setTimeout(() => {
      // tooltip preserves original label.
      const currentItemLabel = this.chartLegendItems[legendEvent.index].tooltip;
      if (legendEvent.show) {
        // Add new chart label when an item is checked in legend.
        this.categoryLabels.push(currentItemLabel);
        // Refresh chart labels with updated order.
        this.categoryLabels = this.reOrderChartLabels();

        // Every update in legend re-assigns background color to retain a unique background color.
        this.chartLegendItems[legendEvent.index].backColor =
          this.getItemColor(currentItemLabel);

        this.chartDataSets = this.getDataSet(
          this.getChartDataByLabels(this.categoryLabels),
          this.getColorsByLabels(this.categoryLabels)
        );
      } else {
        // Remove label for hidden element.
        this.categoryLabels.splice(
          this.categoryLabels.indexOf(currentItemLabel),
          1
        );
        this.chartDataSets = this.getDataSet(
          this.getChartDataByLabels(this.categoryLabels),
          this.getColorsByLabels(this.categoryLabels)
        );
        // legend item is unchecked so remove background color
        this.chartLegendItems[legendEvent.index].backColor = '#fff';
      }
      const enabledItems = legendEvent.currentLegendItems.filter(
        x => x.checked
      );
      this.categoryValues = enabledItems.map(x => x.value);

      this.chartOptions = this.chartConfigService.generateDonutOptions(
        this.query,
        this.categoryLabels,
        this.categoryValues,
        this.groupByField,
        this.chartAreaClick
      );

      this.totalEventsString = this.getTotalEvent();
    }, 100);
  }

  private getDataSet(
    data: number[],
    predefinedColorList: ChartColor[]
  ): ChartDataSets[] {
    return [
      {
        data,
        backgroundColor: predefinedColorList,
        hoverBackgroundColor: predefinedColorList.map((item, index) =>
          window.Chart.helpers.getHoverColor(predefinedColorList[index])
        ),
        label: 'Chart service'
      } as ChartDataSets
    ];
  }

  // With every lengend check/un-check, categoryLabels are re-arranged in the original order.
  private reOrderChartLabels(): string[] {
    const tempLabels: string[] = [];
    this.chartLegendItems.forEach(item => {
      const foundItem = this.categoryLabels.find(x => x === item.tooltip);
      if (foundItem) {
        tempLabels.push(foundItem);
      }
    });
    return tempLabels;
  }

  private getLegendItems(
    data: string[],
    dataValue: string[],
    predefinedColorList: string[]
  ): LegendDataItem[] {
    let items = data.map(
      (x, index) =>
        ({
          label: formatLegendItemLabel(x),
          value: dataValue[index],
          tooltip: x,
          checked: true,
          borderColor: predefinedColorList[index],
          backColor: predefinedColorList[index],
          order: index,
          disable: false
        } as LegendDataItem)
    );
    return items;
  }

  private getColorsByLabels(labels: string[]): string[] {
    const colors = labels.map(label => this.getItemColor(label));
    return colors;
  }

  private getChartDataByLabels(labels: string[]): number[] {
    return labels.map(label => this.chartOriginalDataMap.get(label).item);
  }

  private getItemColor(key: string): string {
    return this.chartOriginalDataMap.get(key).color;
  }

  private getTotalEvent(): string {
    return this.getChartDataByLabels(this.categoryLabels)
      .reduce((first, second) => first + second)
      .toLocaleString();
  }

  private async initializeLegendTitle(): Promise<void> {
    this.groupByField = await this.eventFieldsService
      .getFields()
      .pipe(
        take(1),
        map(response => {
          const groupById =
            this.query.q.charts[0].donut.topXGroupOptions.topXGroupName;
          const dataFields = response.availableFields as object[];
          const eventFields = dataFields.map(data => new EventField(data));
          const groupByField = eventFields.find(
            field => field.id === groupById
          );
          return groupByField;
        })
      )
      .toPromise();

    if (this.groupByField) {
      this.legendTitle = this.groupByField.displayName;
    }
  }

  private setupTemplateText(): void {
    const provider = AuditingDisplayStringsProvider.auditing;
    this.noResultsImagePath = CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH;
    this.errorText = provider.errorLoadingVisualization;
    this.tryAgainText = provider.pages.auditingDashboard.tryAgain;
  }
}
