/** Service for setting navigation breadcrumbs */
import { Injectable } from '@angular/core';
import { IBreadcrumb } from '@ondemand/core';
import { ReplaySubject } from 'rxjs';

export interface BreadcrumbUpdate {
  breadcrumbs: IBreadcrumb[];
  relativePath?: boolean;
}

@Injectable()
export class AuditingBreadcrumbsService {
  breadcrumbs$: ReplaySubject<BreadcrumbUpdate> = new ReplaySubject(1);

  /**
   *
   * @param breadcrumbs List of breadcrumb items
   * @param relativePath Whether this path is relative (as opposed to absolute)
   * @param truncate Limit on number of characters to display (0 for no limit)
   */
  set(breadcrumbs: IBreadcrumb[], relativePath: boolean = false, truncate: number = 0) {
    if (truncate > 0) {
      breadcrumbs.forEach(crumb => {
        if (crumb.title.length > truncate) {
          crumb.title = crumb.title.substr(0, truncate) + '…';
        }
      });
    }

    this.breadcrumbs$.next({
      breadcrumbs,
      relativePath
    });
  }
}
