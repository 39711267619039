import { Injectable } from '@angular/core';

@Injectable()
export class ExportQueryResultsService {
  exportStatus: boolean;

  /**
   * Set export progress status
   */
  setExportQueryResultsStatus(status: boolean) {
    this.exportStatus = status;
  }

  /**
   * Determine if export is in-progress
   */
  getExportQueryResultsStatus() {
    return this.exportStatus;
  }
}
