import {
  CriticalActivityDataSet,
  CriticalActivityDismissedOrHideItem,
  CriticalActivityResponse
} from '../../models/critical-activity.shared.models';

function hiddenOrDissmissalEquals(
  l: CriticalActivityDismissedOrHideItem,
  r: CriticalActivityDismissedOrHideItem
): boolean {
  const dataNotValid =
    !l || !r || !l.queryId || !r.queryId;
  return (
    !dataNotValid && l.queryId === r.queryId && l.parameter === r.parameter
  );
}

export function isCriticalActivityConfigValid(
  source: CriticalActivityResponse
): boolean {
  const notValid: boolean =
    !source || !source.configuration || !source.configuration.criticalActivity;
  return !notValid;
}

export function isCriticalActivityDatasetValid(
  source: CriticalActivityResponse
): boolean {
  const notValid: boolean =
    !source ||
    !source.data ||
    !source.data.criticalActivity ||
    !source.data.criticalActivity.datasets;
  return !notValid;
}

export function getCriticalActivityHiddenDatasets(
  source: CriticalActivityResponse
): CriticalActivityDataSet[] {
  if (
    isCriticalActivityConfigValid(source) &&
    isCriticalActivityDatasetValid(source)
  ) {
    const hiddenList = source.configuration.criticalActivity.hiddenItems;
    const datasets = source.data.criticalActivity.datasets;
    return datasets.filter(
      dataset =>
        !!hiddenList.find(item =>
          hiddenOrDissmissalEquals(item, dataset.dismissOrHideData)
        )
    );
  }

  return [];
}

export function getCriticalActivityDismissedDatasets(
  source: CriticalActivityResponse
): CriticalActivityDataSet[] {
  if (
    isCriticalActivityConfigValid(source) &&
    isCriticalActivityDatasetValid(source)
  ) {
    const dismissedList = source.configuration.criticalActivity.dismissedItems;
    const datasets = source.data.criticalActivity.datasets;
    return datasets.filter(
      dataset =>
        !!dismissedList.find(item =>
          hiddenOrDissmissalEquals(item, dataset.dismissOrHideData)
        )
    );
  }

  return [];
}

export function getCriticalActivityVisibleDatasets(
  source: CriticalActivityResponse
): CriticalActivityDataSet[] {
  if (isCriticalActivityDatasetValid(source)) {
    if (isCriticalActivityConfigValid(source)) {
      const invisibleList = [
        ...source.configuration.criticalActivity.hiddenItems,
        ...source.configuration.criticalActivity.dismissedItems
      ];
      const datasets = source.data.criticalActivity.datasets;
      return datasets.filter(
        dataset =>
          !(!!invisibleList.find(item =>
            hiddenOrDissmissalEquals(item, dataset.dismissOrHideData))
          )
      );
    } else {
      // if no configuration found, make all data visible
      return source.data.criticalActivity.datasets;
    }
  }
  return [];
}
