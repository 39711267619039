import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UpdateFavoriteSearchService } from '../services/update-favorite-search.service';
import {
  UpdateFavoriteSearch,
  UpdateFavoriteSearchFail,
  UpdateFavoriteSearchSuccess,
  UPDATE_FAVORITE_SEARCH
} from './favorite-search-editor.actions';

@Injectable()
export class FavoriteSearchEditorEffects {
  updateFavoriteSearch$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateFavoriteSearch>(UPDATE_FAVORITE_SEARCH),
    switchMap(action =>
      this.updatefavoriteSearchService.updateSettings(action.payload).pipe(
        map(response => response.status === 200
            ? new UpdateFavoriteSearchSuccess()
            : new UpdateFavoriteSearchFail(
                `Unexpected response code received: ${response.status}`
              )),
        catchError(error => of(new UpdateFavoriteSearchFail(error)))
      )
    )
  ));

  constructor(
    private updatefavoriteSearchService: UpdateFavoriteSearchService,
    private actions$: Actions
  ) {}
}
