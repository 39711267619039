/**
 * Generalized service for storing and comparing "original" and "current"
 * values to allow us to allow us to warn users about unsaved changes
 * before they discard them
 */

import { Injectable } from '@angular/core';

@Injectable()
export class UnsavedChangesService {
  private originalValue: any;
  private currentValue: any;

  getOriginalValue() {
    return this.originalValue;
  }

  setOriginalValue(value: any) {
    this.originalValue = JSON.parse(JSON.stringify(value));
  }

  getCurrentValue() {
    return this.currentValue;
  }

  setCurrentValue(value: any) {
    this.currentValue = JSON.parse(JSON.stringify(value));
  }

  /**
   * Reset original and current value
   *
   */
  clear() {
    this.originalValue = null;
    this.currentValue = null;
  }

  /**
   * Determine if the active query has unsaved changes
   *
   */
  isChanged() {
    const originalJSON = JSON.stringify(this.originalValue);
    const currentJSON = JSON.stringify(this.currentValue);
    return originalJSON !== currentJSON;
  }
}
