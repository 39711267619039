<form #editorActionsForm="ngForm" materialize>
  <div class="row">
    <!-- Search name -->
    <div class="col s12 l5 search-name-column">
      <div class="input-field inline">
        <input
          id="search-name"
          name="search-name"
          type="text"
          (keyup)="resetError()"
          (change)="onNameChange()"
          (input)="onNameChange()"
          [(ngModel)]="query.name"
          class="search-name"
          maxlength="250"
          [class.invalid]="isDuplicateQuery"
          materialize="tooltip"
          data-delay="50"
          data-position="top"
          [attr.data-tooltip]="
            'auditing.pages.newSearches.searchName' | localize
          "
          autocomplete="off"
          required
        />
      </div>
      <error-message class="error-duplicate" *ngIf="isDuplicateQuery">{{
        'auditing.pages.newSearches.errorDuplicateName' | localize
      }}</error-message>
      <error-message class="error-empty" *ngIf="nameIsEmpty">{{
        'auditing.pages.newSearches.errorEmptyName' | localize
      }}</error-message>
      <error-message class="" *ngIf="errorMessage">{{
        errorMessage
      }}</error-message>
    </div>

    <div class="col s12 l7">
      <div class="right">
        <!-- Action buttons -->
        <div class="left buttons-container">
          <div class="inline right">
            <ng-container *ngIf="userCanManageThisSearchAlert()">
              <alert-button *ngIf="query" [query]="query"></alert-button>
            </ng-container>

            <button
              *ngIf="!query.isSystemDefined && !isNewSearch"
              type="button"
              class="waves-effect left waves-light btn-flat delete-query-button"
              id="delete-query-button"
              (click)="onDeleteClick()"
            >
              <edc-icon name="trash"></edc-icon
              >{{ 'auditing.deleteQueryLabel' | localize }}
            </button>

            <!-- Button for system-defined queries, only allowing "Save As" -->
            <button
              *ngIf="query.isSystemDefined"
              type="button"
              class="waves-effect waves-light btn js-save-as"
              id="save-as-button"
              (click)="saveAs()"
            >
              <edc-icon class="save-btn" name="save"></edc-icon
              >{{ 'auditing.saveAsButtonLabel' | localize }}
            </button>

            <!-- Button for user-created queries -->
            <dropdown-button
              *ngIf="
                !query.isSystemDefined &&
                query.id &&
                primarySaveButtonOptions &&
                saveMenuOptions
              "
              class="left save-button-menu"
              [primaryButtonOptions]="primarySaveButtonOptions"
              [menuOptions]="saveMenuOptions"
              (action)="handleAction($event)"
            ></dropdown-button>

            <button
              *ngIf="
              !query.isSystemDefined &&
              !query.id
              "
              type="button"
              class="waves-effect waves-light btn js-save"
              id="save-button"
              [ngClass]="{ disabled: saveButtonDisabled }"
              (click)="save()"
            >
            <edc-icon class="save-btn" name="save"></edc-icon
            >{{ 'auditing.saveButtonLabel' | localize }}
            </button>
            <a
              class="waves-effect waves-light btn js-runquery"
              id="run-query-button"
              [ngClass]="{ disabled: !formValid || !editorActionsForm.valid }"
              (click)="runQuery()"
              *odaIfHasPermissions="[
              fromPermissions.canRunPrivateSearch,
              fromPermissions.canRunSharedSearches
              ]"
            >
              <edc-icon class="run-btn" name="task-run"></edc-icon
              >{{ 'auditing.runButtonLabel' | localize }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
