import { Query } from '../../models/query.model';
import { AuditModulePermissionsService } from '../../services/audit-module-permissions.service';
import * as fromPermissions from '../../models/audit-permissions.model';

export const checkUserAlertPermissions = (
  query: Query,
  permissionsService: AuditModulePermissionsService
) => {
  if (query && query.isShared) {
    return permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageSharedAlertsAndAlertPlans
    ]);
  }

  return permissionsService.hasAnyOfPermissions([
    fromPermissions.canManagePrivateAlertsAndAlertPlans
  ]);
};
