import { Component, Input } from '@angular/core';

/**
 * A component to wrap the materialize spinner. It defaults to using the Quest
 * Orange color.
 *
 * @export
 */
@Component({

  selector: 'materialize-spinner',
  template: `
    <div class="preloader-wrapper {{size}} active">
      <div class="spinner-layer"
           [ngClass]='customClasses()'
           [ngStyle]='customStyles()'>
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  `
})
export class MaterializeSpinnerComponent {
  /**
   * Applies the materialize default classes of spinner size. To override the
   * size of the spinner, add styles for the `.preloader-wrapper` class.
   *
   */
  @Input() size: 'big' | '' | 'small' = '';

  /**
   * Use the default Materialize classes to add color to the spinner
   *
   */
  @Input() defaultColors: '' | 'green' | 'red' | 'yellow' | 'blue' = '';

  /**
   * Provide a custom color for the spinner rather than using a Materialize
   * default. Must be a valid hex color string.
   *
   */
  @Input() overrideColor = '';

  /**
   * Uses the `defaultColor` property to determine which class should be applied
   * to the spinner
   *
   * @returns An {Object} to be used for the `ngClasses` directive
   */
  customClasses() {
    return {
      'spinner-green-only': this.defaultColors === 'green',
      'spinner-red-only': this.defaultColors === 'red',
      'spinner-yellow-only': this.defaultColors === 'yellow',
      'spinner-blue-only': this.defaultColors === 'blue',
      'quest-orange': this.defaultColors === ''
    };
  }

  /**
   * Uses the `overrideColor` property to add a style directly to the spinner
   * component
   *
   * @returns An {Object} to be used to the `ngStyle` directive
   */
  customStyles() {
    return {
      'border-color': this.overrideColor
    };
  }
}
