/**
 * This wraps the audithub subscription service to give us what we need
 */
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { ESubscriptionState, ESubscriptionType, IModuleFeature } from '@ondemand/core';
import { auditModuleName } from '../../util/constants';
import { AuditSubscriptionService } from '../audit-subscription/audit-subscription.service';

export enum EAuditFeatureName {
  O365 = 'o365',
  O365_EXPIRED = 'o365Expired',
  O365_OFFBOARDING = 'o365Offboarding',
  O365_OFFBOARDED = 'o365Offboarded',
  CHANGE_AUDITOR = 'changeAuditor',
  CHANGE_AUDITOR_EXPIRED = 'changeAuditorExpired',
  CHANGE_AUDITOR_OFFBOARDING = 'changeAuditorOffboarding',
  CHANGE_AUDITOR_OFFBOARDED = 'changeAuditorOffboarded',
  AZURE_AD_AUDIT_LOGS = 'azureADAuditLogs',
  AZURE_AD_AUDIT_LOGS_EXPIRED = 'azureADAuditLogsExpired',
  AZURE_AD_AUDIT_LOGS_OFFBOARDING = 'azureADAuditLogsOffboarding',
  AZURE_AD_AUDIT_LOGS_OFFBOARDED = 'azureADAuditLogsOffboarded',
  AZURE_AD_SIGN_INS = 'azureADSignIns',
  AZURE_AD_SIGN_INS_EXPIRED = 'azureADSignInsExpired',
  AZURE_AD_SIGN_INS_OFFBOARDING = 'azureADSignInsOffboarding',
  AZURE_AD_SIGN_INS_OFFBOARDED = 'azureADSignInsOffboarded',
  EXCHANGE_ONLINE_ADMIN_ACTIVITY = 'exchangeOnlineAdminActivity',
  EXCHANGE_ONLINE_ADMIN_ACTIVITY_EXPIRED = 'exchangeOnlineAdminActivityExpired',
  EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDING = 'exchangeOnlineAdminActivityOffboarding',
  EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDED = 'exchangeOnlineAdminActivityOffboarded',
  EXCHANGE_ONLINE_MAILBOX_ACTIVITY = 'exchangeOnlineMailboxActivity',
  EXCHANGE_ONLINE_MAILBOX_ACTIVITY_EXPIRED = 'exchangeOnlineMailboxActivityExpired',
  EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDING = 'exchangeOnlineMailboxActivityOffboarding',
  EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDED = 'exchangeOnlineMailboxActivityOffboarded',
  SHAREPOINT_ONLINE = 'sharepointOnline',
  SHAREPOINT_ONLINE_EXPIRED = 'sharepointOnlineExpired',
  SHAREPOINT_ONLINE_OFFBOARDING = 'sharepointOnlineOffboarding',
  SHAREPOINT_ONLINE_OFFBOARDED = 'sharepointOnlineOffboarded',
  ONEDRIVE = 'onedrive',
  ONEDRIVE_EXPIRED = 'onedriveExpired',
  ONEDRIVE_OFFBOARDING = 'onedriveOffboarding',
  ONEDRIVE_OFFBOARDED = 'onedriveOffboarded',
  TEAMS = 'teams',
  TEAMS_EXPIRED = 'teamsExpired',
  TEAMS_OFFBOARDING = 'teamsOffboarding',
  TEAMS_OFFBOARDED = 'teamsOffboarded',
}

export enum ESubscriptionCode {
  O365_ALL = 'o365.all',
  CA_AD = 'ca.ad',
}

@Injectable()
export class FeatureSubscriptionService implements OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private subscriptionService: AuditSubscriptionService) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hasSubscription$(name: EAuditFeatureName): Observable<boolean> {
    return this.subscriptionService
      .getSubscription$().pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap(async (moduleSubscription) => {
        if (moduleSubscription) {
          return await this.hasFeature(name, moduleSubscription.features);
        } else {
          console.error('Module subscription not found');
          return false;
        }
      }));
  }

  getSubscription$(name: EAuditFeatureName): Observable<IModuleFeature> {
    return this.subscriptionService
      .getSubscription$().pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap(async (moduleSubscription) => {
        if (moduleSubscription) {
          return await this.getFeatureSubscription(
            name,
            moduleSubscription.features
          );
        } else {
          console.error('Module subscription not found');
          return null;
        }
      }));
  }

  getSubscriptionByCode$(
    code: ESubscriptionCode,
    states: ESubscriptionState[]
  ): Observable<IModuleFeature> {
    return this.subscriptionService
      .getSubscription$().pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap(async (moduleSubscription) => {
        if (moduleSubscription) {
          return this.getMatchingSubscription(
            moduleSubscription.features,
            [code],
            states
          );
        } else {
          console.error('Module subscription not found');
          return null;
        }
      }));
  }

  private async getFeatureSubscription(
    name: EAuditFeatureName,
    currentSubscriptions: IModuleFeature[]
  ): Promise<IModuleFeature> {
    const allO365 = ESubscriptionCode.O365_ALL;
    const o365Subscriptions = [allO365];
    const changeAuditorSubscriptions = [
      ...o365Subscriptions,
      ESubscriptionCode.CA_AD
    ];

    if (name === EAuditFeatureName.O365) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];

      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.SUBSCRIBED, ESubscriptionState.EXPIRING]
      );
    } else if (name === EAuditFeatureName.O365_EXPIRED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];

      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.EXPIRED]
      );
    } else if (name === EAuditFeatureName.O365_OFFBOARDING) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDING]
      );
    } else if (name === EAuditFeatureName.O365_OFFBOARDED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDED]
      );
    } else if (name === EAuditFeatureName.CHANGE_AUDITOR) {
      let validSubscriptions = [...changeAuditorSubscriptions];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.SUBSCRIBED, ESubscriptionState.EXPIRING]
      );
    } else if (name === EAuditFeatureName.CHANGE_AUDITOR_EXPIRED) {
      let validSubscriptions = [...changeAuditorSubscriptions];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.EXPIRED]
      );
    } else if (name === EAuditFeatureName.CHANGE_AUDITOR_OFFBOARDING) {
      let validSubscriptions = [...changeAuditorSubscriptions];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDING]
      );
    } else if (name === EAuditFeatureName.CHANGE_AUDITOR_OFFBOARDED) {
      let validSubscriptions = [...changeAuditorSubscriptions];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDED]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_AUDIT_LOGS) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.SUBSCRIBED, ESubscriptionState.EXPIRING]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_AUDIT_LOGS_EXPIRED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.EXPIRED]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_AUDIT_LOGS_OFFBOARDING) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDING]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_AUDIT_LOGS_OFFBOARDED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDED]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_SIGN_INS) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.SUBSCRIBED, ESubscriptionState.EXPIRING]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_SIGN_INS_EXPIRED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.EXPIRED]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_SIGN_INS_OFFBOARDING) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDING]
      );
    } else if (name === EAuditFeatureName.AZURE_AD_SIGN_INS_OFFBOARDED) {
      let validSubscriptions = [
        ...o365Subscriptions,
        ...changeAuditorSubscriptions
      ];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDED]
      );
    } else if (
      [
        EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY,
        EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY,
        EAuditFeatureName.SHAREPOINT_ONLINE,
        EAuditFeatureName.ONEDRIVE,
        EAuditFeatureName.TEAMS
      ].includes(name)
    ) {
      let validSubscriptions = [allO365];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.SUBSCRIBED, ESubscriptionState.EXPIRING]
      );
    } else if (
      [
        EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_EXPIRED,
        EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_EXPIRED,
        EAuditFeatureName.SHAREPOINT_ONLINE_EXPIRED,
        EAuditFeatureName.ONEDRIVE_EXPIRED,
        EAuditFeatureName.TEAMS_EXPIRED
      ].includes(name)
    ) {
      let validSubscriptions = [allO365];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.EXPIRED]
      );
    } else if (
      [
        EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDING,
        EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDING,
        EAuditFeatureName.SHAREPOINT_ONLINE_OFFBOARDING,
        EAuditFeatureName.ONEDRIVE_OFFBOARDING,
        EAuditFeatureName.TEAMS_OFFBOARDING
      ].includes(name)
    ) {
      let validSubscriptions = [allO365];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDING]
      );
    } else if (
      [
        EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDED,
        EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDED,
        EAuditFeatureName.SHAREPOINT_ONLINE_OFFBOARDED,
        EAuditFeatureName.ONEDRIVE_OFFBOARDED,
        EAuditFeatureName.TEAMS_OFFBOARDED
      ].includes(name)
    ) {
      let validSubscriptions = [allO365];
      return await this.getMatchingSubscription(
        currentSubscriptions,
        validSubscriptions,
        [ESubscriptionState.OFFBOARDED]
      );
    } else if ((name as string) === 'auditing') {
      // TODO: Remove since this is not a real feature subscription
      return this.getMockSubscription('auditing');
    } else {
      console.error('Invalid feature name:', name);
      return null;
    }
  }

  private async hasFeature(
    name: EAuditFeatureName,
    currentSubscriptions: IModuleFeature[]
  ): Promise<boolean> {
    const subscription = await this.getFeatureSubscription(
      name,
      currentSubscriptions
    );
    if (subscription) {
      return true;
    } else {
      return false;
    }
  }

  private async getMatchingSubscription(
    currentSubscriptions: IModuleFeature[],
    validSubscriptions: ESubscriptionCode[],
    states: ESubscriptionState[]
  ): Promise<IModuleFeature> {
    if (this.hasPreSKUSubscription(currentSubscriptions)) {
      return currentSubscriptions.find(
        (subscription) => subscription.name === auditModuleName
      );
    } else {
      return currentSubscriptions.find(
        (subscription) =>
          validSubscriptions.includes(subscription.name as ESubscriptionCode) &&
          states.includes(subscription.subscriptionState)
      );
    }
  }

  private hasPreSKUSubscription(currentSubscriptions: IModuleFeature[]) {
    return currentSubscriptions.some(
      (subscription) =>
        subscription.name === auditModuleName &&
        subscription.subscriptionActive &&
        (subscription.subscriptionType === ESubscriptionType.DEMO ||
          subscription.subscriptionType === ESubscriptionType.TECHPREVIEW ||
          subscription.subscriptionType === ESubscriptionType.PAID ||
          subscription.subscriptionType === ESubscriptionType.TRIAL)
    );
  }

  private getMockSubscription(name: string) {
    return {
      expiryDate: '2050-05-28T13:13:45.4357358Z',
      name,
      subscriptionActive: true,
      subscriptionType: ESubscriptionType.PAID,
      // TODO: Enable when core repo adds updated enum list for subscription types
      // subscriptionType: ESubscriptionType.STANDARD,
      subscriptionState: ESubscriptionState.SUBSCRIBED
    };
  }
}
