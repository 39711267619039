import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';

import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { Results } from '../results.model';
import { formatResultsForDataTable } from './formatResultsForDataTable';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DisplayStringsProvider } from '@ondemand/core';
import { Rect } from '../../../../../shared/models/html.models';
import {
  COLUMN_WIDTH_RRESERVE,
  MIN_RESULT_COLUMN_WIDTH
} from '../results-constants';

export interface CellClickEvent {
  field: string;
  value: string;
  cell: Element;
}

@Component({
  selector: 'results-preview',
  templateUrl: './results-preview.component.html',
  styleUrls: ['./results-preview.component.scss']
})
export class ResultsPreviewComponent implements OnInit, OnChanges {
  columnMode: ColumnMode;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  coreStrings = DisplayStringsProvider;
  datatableRows: any;
  datatableColumns: any;
  errorMessage: string = this.applicationStrings.queryFailedError;

  @Input() loadingResults = false;
  @Input() loadingError: string = null;
  @Input() results: Results;
  @Output() cellClick = new EventEmitter<CellClickEvent>();
  @Output() editColumns = new EventEmitter<any>();
  @Output() reorder = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @ViewChild('datatableContainer', { static: true })
  datatableContainer: ElementRef;

  get hasResults() {
    return this.results && this.results.rows && this.results.rows.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.results) {
      this.renderResults();
    }
  }

  ngOnInit() {
    this.columnMode = ColumnMode.standard;
    this.renderResults();
  }

  onCellEvent(event: any) {
    if (event.type !== 'click') {
      return;
    } else if (!event.column || !event.column.prop) {
      return;
    }

    const cellEvent: CellClickEvent = {
      field: event.column.prop,
      value: event.value,
      cell: event.cellElement
    };

    this.cellClick.emit(cellEvent);
  }

  getResultsMessage(): string {
    let formattedMax = this.results.rows.length.toLocaleString();
    let formattedTotal = this.results.totalRows.toLocaleString();
    // TODO: Internationalize
    return `Previewing 1-${formattedMax} of ${formattedTotal} result${
      this.results.totalRows !== 1 ? 's' : ''
    }.`;
  }

  triggerEditColumns() {
    this.editColumns.emit();
  }

  /**
   * Propagate "reorder" event data from datatable
   *
   * @param event Event data from ngx-datatable
   */
  onReorder(event: any) {
    this.reorder.emit(event);
  }

  /**
   * Propagate "sort" event
   *
   * @param event Sort event data
   */
  onSort(event: any) {
    this.sort.emit(event);
  }

  onSizeChange(): void {
    if (this.datatableColumns && this.datatableColumns.length) {
      this.updateDataTable(this.datatableColumns, this.datatableRows);
    }
  }

  private renderResults() {
    if (this.results !== null && this.results !== undefined) {
      const columnsAndRows = formatResultsForDataTable(this.results);
      this.updateDataTable(
        columnsAndRows.datatableColumns,
        columnsAndRows.datatableRows
      );
    }
  }

  private getWidths(numOfCols: number): [number, number, number] {
    const containerDiv = this.datatableContainer
      .nativeElement as HTMLDivElement;
    const rect = containerDiv.getBoundingClientRect() as Rect;
    const containerWidth = rect.width;
    const widthReserved = COLUMN_WIDTH_RRESERVE; // preview always has  15 rows, vertical scroll bar is not needed

    let colWidth = (containerWidth - widthReserved) / numOfCols;

    colWidth =
      colWidth < MIN_RESULT_COLUMN_WIDTH ? MIN_RESULT_COLUMN_WIDTH : colWidth;

    return [containerWidth, colWidth, widthReserved];
  }

  private updateDataTable(columns: any[], rows: any[]): void {
    const [containerWidth, colWidth, widthReserved] = this.getWidths(
      columns.length
    );
    const colWidthInt = Math.floor(colWidth);

    columns.forEach(column => (column.width = colWidthInt));

    if (colWidth > MIN_RESULT_COLUMN_WIDTH) {
      const lastColumnWidth =
        containerWidth - ((columns.length - 1) * colWidthInt + widthReserved);
      columns[columns.length - 1].width = lastColumnWidth;
    }

    this.datatableColumns = [...columns];
    this.datatableRows = [...rows];
  }
}
