import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  StaticIndicatorResponse,
  StaticIndicatorDataResponse,
  StaticIndicatorConfigurationResponse
} from '../models/static-indicator.models';
import * as fromFeature from '../../../reducers/index';
import { StaticIndicatorID } from '../../../models/widget-info';

const staticIndicatorState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.staticIndicator
);

export const createDataSelector = (
  key: StaticIndicatorID
): MemoizedSelector<object, StaticIndicatorResponse> => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return createSelector(
        staticIndicatorState,
        state => state.dataAdAccountLockouts
      );

    case StaticIndicatorID.AllFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.dataAllFailedSignins
      );

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return createSelector(
        staticIndicatorState,
        state => state.dataAzureADCriticalRoleChanges
      );

    case StaticIndicatorID.AzureADFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.dataAzureADFailedSignins
      );

    case StaticIndicatorID.AzureADRiskEvents:
      return createSelector(
        staticIndicatorState,
        state => state.dataAzureADRiskEvents
      );

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return createSelector(
        staticIndicatorState,
        state => state.dataCloudOnlyAzureADUsersCreated
      );

    case StaticIndicatorID.O365ExternalUserActions:
      return createSelector(
        staticIndicatorState,
        state => state.dataO365ExternalUserActions
      );

    default:
      throw new Error('Unrecognized ID');
  }
};

export const createLoadingSelector = (
  key: StaticIndicatorID
): MemoizedSelector<object, boolean> => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return createSelector(
        staticIndicatorState,
        state => state.loadingAdAccountLockouts
      );

    case StaticIndicatorID.AllFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.loadingAllFailedSignins
      );

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return createSelector(
        staticIndicatorState,
        state => state.loadingAzureADCriticalRoleChanges
      );

    case StaticIndicatorID.AzureADFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.loadingAzureADFailedSignins
      );

    case StaticIndicatorID.AzureADRiskEvents:
      return createSelector(
        staticIndicatorState,
        state => state.loadingAzureADRiskEvents
      );

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return createSelector(
        staticIndicatorState,
        state => state.loadingCloudOnlyAzureADUsersCreated
      );

    case StaticIndicatorID.O365ExternalUserActions:
      return createSelector(
        staticIndicatorState,
        state => state.loadingO365ExternalUserActions
      );

    default:
      throw new Error('Unrecognized ID');
  }
};

export const createErrorSelector = (
  key: StaticIndicatorID
): MemoizedSelector<object, any> => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return createSelector(
        staticIndicatorState,
        state => state.errorAdAccountLockouts
      );

    case StaticIndicatorID.AllFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.errorAllFailedSignins
      );

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return createSelector(
        staticIndicatorState,
        state => state.errorAzureADCriticalRoleChanges
      );

    case StaticIndicatorID.AzureADFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.errorAzureADFailedSignins
      );

    case StaticIndicatorID.AzureADRiskEvents:
      return createSelector(
        staticIndicatorState,
        state => state.errorAzureADRiskEvents
      );

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return createSelector(
        staticIndicatorState,
        state => state.errorCloudOnlyAzureADUsersCreated
      );

    case StaticIndicatorID.O365ExternalUserActions:
      return createSelector(
        staticIndicatorState,
        state => state.errorO365ExternalUserActions
      );

    default:
      throw new Error('Unrecognized ID');
  }
};

export const createTrendColorSelector = (
  key: StaticIndicatorID
): MemoizedSelector<object, string> => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorAdAccountLockouts
      );

    case StaticIndicatorID.AllFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorAllFailedSignins
      );

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorAzureADCriticalRoleChanges
      );

    case StaticIndicatorID.AzureADFailedSignins:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorAzureADFailedSignins
      );

    case StaticIndicatorID.AzureADRiskEvents:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorAzureADRiskEvents
      );

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorCloudOnlyAzureADUsersCreated
      );

    case StaticIndicatorID.O365ExternalUserActions:
      return createSelector(
        staticIndicatorState,
        state => state.trendColorO365ExternalUserActions
      );

    default:
      throw new Error('Unrecognized ID');
  }
};
