<general-qod-component
  [loading]="dataLoading$ | async"
  [error]="dataError$ | async"
  [data]="data$ | async"

>
  <!--Header-->
  <div component-header #componentHeader tabindex="-1">
    <div class="header">
      <div
        class="icon-and-link filter-editor-box-size"
        (click)="showFilters = !showFilters"
      >
        <qod-btn-grid-header
          iconName="filter-alternate"
          text="Filter"
          [count]="filtersCount"
          [isDropdownTrigger]="true"
        >
        </qod-btn-grid-header>
      </div>

      <div class="vert-line"></div>

      <qod-btn-grid-header
        iconName="cog"
        text="Edit Hidden Items"
        [count]="hiddenListCount"
        [isDropdownTrigger]="true"
        [disabled]="hiddenListCount < 1 || (dataLoading$ | async) || (dataError$ | async)"
        (click)="handleEditHiddenItemsClicked()" ></qod-btn-grid-header>

      <div class="vert-line"></div>

    </div>
    <div *ngIf="showFilters" class="expand-collapse-filter">
      <qod-filter-editor
        [config]="inputConfig"
        (filtersChange)="onFiltersChanged($event)"
      ></qod-filter-editor>
    </div>
  </div>

  <!-- Errors -->
  <div component-error>
    <error-card
      error="{{ 'auditing.pages.criticalActivity.error' | localize }}"
      button="{{ 'auditing.pages.criticalActivity.tryAgain' | localize }}"
      (buttonClick)="loadCriticalActivityList()"
    ></error-card>
  </div>

  <!-- Loading -->
  <div component-loading>
    <internal-loading [isLoading]="true"></internal-loading>
  </div>

  <!-- Data -->
  <div component-data class="ag-theme-balham grid-container">
    <div
      class="left-column"
      [ngClass]="{
        'left-column-no-flyout-width': !showFlyoutRightColumn,
        'left-column-flyout-width': showFlyoutRightColumn
      }"
    >
      <ag-grid-angular
        class="od-ag-grid"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationAutoPageSize]="true"
        (gridSizeChanged)="onGridSizeChanged($event)"
        (gridReady)="onGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        (rowDataChanged)="onRowDataChanged($event)"
        [rowData]="filteredDataRows"
        [overlayNoRowsTemplate]="noResultsTemplate"
      >
      </ag-grid-angular>
    </div>
    <div class="right-column" *ngIf="showFlyoutRightColumn">
      <flyout-chart
        [query]="selectedRowQuery"
        [queryId]="flyoutChartQueryId"
        (removeFlyoutChart)="handleRemoveFlyoutChart()"
        (hideItem)="handleHideOrDismissEvent($event)"
       ></flyout-chart>
    </div>
  </div>
</general-qod-component>

<hidden-item-editor
  [hiddenItems]="hiddenList"
  (hiddenItemsUpdated)="handleHiddemItemsUpdated($event)"
></hidden-item-editor>
