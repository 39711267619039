<modal-window #favoriteSearchEditorModal [params]="modalParams" *ngIf="modalParams">
  <div modal-content class="favorite-search-editor-modal">

    <div class="editor-description">
      {{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.description' | localize }}
    </div>

    <form #editSearchesForm="ngForm">

      <div class="select-items-box">

        <error-message *ngIf="errorOccurred">{{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.updateErrorMessage' | localize }}</error-message>

        <ng-container *ngIf="!errorOccurred">
          <div class="select-items-header-row">
            <div class="category-header"> Category </div>
            <div class="search-header"> Search </div>
          </div>
          <div class="drag-drop-items-box">
            <ul class="favorite-searches-bag"  [dragula]="'favorite-searches-bag'" [(dragulaModel)]='selectionEntries'>
              <li class="search-bag-item" *ngFor="let selectionEntry of selectionEntries; index as i; trackBy: trackById">
                <div class="grabber-container tooltipped"
                  id="grabber-{{i}}"
                  materialize="tooltip"
                  data-delay="150"
                  data-position="top"
                  attr.data-tooltip="{{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.grabberTooltip' | localize }}">
                  <div class="item-order-grabber"></div>
                </div>

                <div class="category-selection-box">
                  <oda-dropdown-select
                    id="favorite-category-selection-id-{{i}}"
                    name="favorite-category-selection-{{i}}"
                    [dropdownItems]="displayCategories"
                    (change)="onSelectCategoryChange(selectionEntry)"
                    [(ngModel)]="selectionEntry.categoryId"
                    required
                  ></oda-dropdown-select>
                </div>

                <div class="search-selection-box">
                  <oda-dropdown-select
                    id="favorite-search-selection-id-{{i}}"
                    name="favorite-search-selection-{{i}}"
                    [dropdownItems]="selectionEntry.queries"
                    (change)="onSearchSelectionChange()"
                    [(ngModel)]="selectionEntry.queryId"
                    [noSelectionPrompt]="noSelectedSearchPrompt"
                    [noItemsPrompt]="noAvailableSearchesPrompt"
                    required
                  ></oda-dropdown-select>
                </div>

                <div
                  class="remove-entry-button tooltipped"
                  id="remove-selection-id-{{i}}"
                  *ngIf="selectionEntries.length > 1"
                  materialize="tooltip"
                  data-delay="50"
                  data-position="top"
                  attr.data-tooltip="{{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.removeButtonTooltip' | localize }}"
                  (click)="onRemoveButtonClick(selectionEntry)">
                  <edc-icon name="cross"></edc-icon>
                </div>

              </li>
            </ul>

          </div>
        </ng-container>
      </div>

      <div class="action-button-container">
          <button
            id="favorite-search-editor-add-btn"
            type="button"
            class="btn left-aligned"
            (click)="onAddSearchClick()"
            [disabled]="(selectionEntries && selectionEntries.length >= 5) || errorOccurred"
            >
            <edc-icon name="plus"></edc-icon> {{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.addButtonText' | localize }}
          </button>
          <button
            type="button"
            class="btn-flat right-aligned"
            (click)="onCancelClick()"
            >
            {{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.cancelButtonText' | localize }}
          </button>
          <button
            id="favorite-search-editor-ok-btn"
            type="button"
            class="btn right-aligned"
            (click)="onOKeyClick()"
            [disabled]="!editSearchesForm.valid || !editSearchesForm.dirty || errorOccurred"
            >
            {{ 'auditing.pages.auditingDashboard.favoriteSearches.editor.okButtonText' | localize }}
          </button>
      </div>
    </form>
  </div>
</modal-window>
