import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Query } from '../../../../../models/query.model';
import { ActiveQueryService } from '../../../../../services/active-query.service';
import { SigninsComponentInterface } from '../sign-ins.component';

@Injectable()
export class SigninsMouseEventService {
  constructor(
    private activeQueryService: ActiveQueryService,
    private router: Router
  ) {}

  clickHandler(
    event: any,
    chartElement: any[],
    signinsWidget: SigninsComponentInterface
  ): any {
    // This method will be executed inside the context of the ChartElement
    // so, 'this' will be referring to the Chart Element
    if (signinsWidget.userHasRunSearchPermissions && chartElement.length > 0) {
      const eventY = event.offsetY;

      const chartElemClicked = chartElement.find(elem => {
        const lowY = elem._view.y - elem._view.radius;
        const hiY = elem._view.y + elem._view.radius;
        return eventY >= lowY && eventY <= hiY;
      });

      const selectedDataset = signinsWidget.getDataset(
        chartElemClicked._datasetIndex,
        chartElemClicked._index
      );

      if (selectedDataset) {
        const selectedQueryObjectSource =
          selectedDataset.details[chartElemClicked._index].query;
        const activeQuery = new Query(selectedQueryObjectSource);
        const resultsUrl = '/auditing/auditing/queries/results';
        this.activeQueryService.setQuery(activeQuery, activeQuery);
        this.router.navigate([resultsUrl]);
      }
    }
  }

  hoverHandler(
    event: any,
    chartElement: any[],
    signinsWidget: SigninsComponentInterface
  ): any {
    // This method will be executed inside the context of the ChartElement
    // so, 'this' will be referring to the Chart Element
    const widgetTag = document.getElementsByTagName('sign-ins-widget')[0];
    const chartCanvasTag = widgetTag.getElementsByTagName('canvas')[0];
    chartCanvasTag.style.cursor = 'default';

    if (signinsWidget.userHasRunSearchPermissions && chartElement.length > 0) {
      chartCanvasTag.style.cursor = 'pointer';
    }
  }
}
