import { take } from 'rxjs/operators';
import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { Query } from '../../../../models/query.model';
import { SavedQueryCategory } from '../../saved-queries/saved-query-category.model';
import { DropdownButtonMenuOption } from '../../../dropdown-button/dropdown-button.component';
import { stripUnprintable } from '../../../../util/strip-unprintable';
import { BaseComponent, AppFacadeService } from '@ondemand/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { AlertService } from '../../../../services/alerts.service';
import { AlertRule } from '../../../../models/alert-rule.model';

import * as fromPermissions from '../../../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import { checkUserAlertPermissions } from '../../alert-rule-permissions';

export const saveNewQueryAction = 'save';
export const updateSavedQueryAction = 'update';
export const saveQueryAsAction = 'saveAs';
export const runQueryAction = 'runQuery';
export const resetQueryAction = 'resetQuery';
export const deleteQueryAction = 'delete';

@Component({

  selector: 'ca-query-editor-actions',
  templateUrl: './query-editor-actions.component.html',
  styleUrls: ['./query-editor-actions.component.scss']
})
export class QueryEditorActionsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() categories: SavedQueryCategory[] = [];
  @Input() formValid: boolean;
  @Input() isPristine: boolean;
  @Input() query: Query;
  @Input() isDuplicateQuery = false;
  @Input() errorMessage: string;
  @Input() isSaving = false;

  @Output() actionEmitter = new EventEmitter<string>();
  @Output() queryPreviewEmitter = new EventEmitter<boolean>();

  @ViewChild('editorActionsForm', {static: true}) form: NgForm;

  nameChanged = false;
  previewStatus = true;
  saveButtonDisabled = false;
  primarySaveButtonOptions: DropdownButtonMenuOption;
  saveMenuOptions: DropdownButtonMenuOption[];

  fromPermissions = fromPermissions;

  constructor(
    private localeStrings: LocaleStringsService,
    private facade: AppFacadeService,
    private alertService: AlertService,
    private permissionsService: AuditModulePermissionsService
  ) {
    super();
  }

  get previewIsOn(): boolean {
    return this.previewStatus;
  }

  set previewIsOn(val: boolean) {
    this.previewStatus = val;
    this.queryPreviewEmitter.emit(this.previewStatus);
  }

  get disableSave() {
    const nameIsPristine = this.form.pristine;
    const queryIsValid = this.formValid;
    const queryIsPristine = this.isPristine;
    const formsArePristine = nameIsPristine && queryIsPristine;
    return this.query.isSystemDefined || this.isSaving || this.nameIsEmpty || formsArePristine || !queryIsValid;
  }

  get isNewSearch() {
    return this.query.id === undefined || this.query.id === null;
  }

  get nameIsEmpty(): boolean {
    return !this.query.name || this.query.name.trim() === '';
  }

  async ngOnInit() {
    this.primarySaveButtonOptions = {
      label: await this.localeStrings.string$('auditing.saveButtonLabel').pipe(take(1)).toPromise(),
      action: 'update',
      icon: 'save',
      disabled: true,
      id: 'save-query-button'
    };

    this.saveMenuOptions = [
      {
        label: await this.localeStrings.string$('auditing.saveAsButtonLabel').pipe(take(1)).toPromise(),
        action: 'saveAs'
      }
    ];
    this.setSaveButtonStatus();
    this.query.hideCharts = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Set disabled status of save button as the form changes
    if (changes.isPristine || changes.isSaving || changes.formValid) {
      this.setSaveButtonStatus();
    }

    if (changes.isSaving && this.primarySaveButtonOptions) {
      let labelString: string;
      if (changes.isSaving.currentValue === true) {
        labelString = 'auditing.saving';
      } else {
        labelString = 'auditing.saveButtonLabel';
      }
      this.primarySaveButtonOptions.label = await this.localeStrings.string$(labelString).pipe(
        take(1)).toPromise();
    }
  }

  handleAction(action: string) {
    if (action === 'update') {
      this.update();
    } else if (action === 'saveAs') {
      this.saveAs();
    } else {
      console.error('Invalid button action');
    }
  }

  onNameChange() {
    this.query.name = stripUnprintable(this.query.name);
    this.nameChanged = true;
    this.setSaveButtonStatus();
  }

  setSaveButtonStatus() {
    if (this.primarySaveButtonOptions) {
      this.primarySaveButtonOptions.disabled = this.isSaving
        || !this.formValid
        || !this.form.valid
        || this.query.name.length === 0;
    }

    this.saveButtonDisabled = !this.formValid
      || !this.form.valid
      || this.query.name.length === 0;
  }

  resetError() {
    this.isDuplicateQuery = false;
    this.errorMessage = null;
  }

  update() {
    if (this.query.isSystemDefined) {
      return;
    }
    this.isSaving = true;
    this.query.name = this.query.name.trim();
    this.actionEmitter.emit(updateSavedQueryAction);
  }

  save() {
    if (this.query.isSystemDefined) {
      return;
    }
    this.query.name = this.query.name.trim();
    this.actionEmitter.emit(saveNewQueryAction);
  }

  saveAs() {
    if (this.query.isSystemDefined || this.query.isProtected) {
      this.query.isProtected = false;
    }
    this.actionEmitter.emit(saveQueryAsAction);
  }

  runQuery() {
    this.actionEmitter.emit(runQueryAction);
  }

  resetQuery() {
    this.actionEmitter.emit(resetQueryAction);
  }

  async onDeleteClick() {
    const deletePromptTitle = await this.localeStrings.string$('auditing.pages.savedSearches.deleteSearchHeader').pipe(
      take(1)).toPromise();
    let deletePromptMessage = await this.localeStrings.string$('auditing.pages.savedSearches.deleteSearchWarning',
                                                                 { name: this.query.name }).pipe(take(1)).toPromise();

    this.isAlertEnabled().then(async (enabled) => {
      if (enabled) {
        deletePromptMessage += await this.localeStrings.string$(
          'auditing.alertEnabledSearchDeletionWarning').pipe(take(1)).toPromise();
      }
      if (await this.facade.confirm(deletePromptTitle, deletePromptMessage)) {
        this.actionEmitter.emit(deleteQueryAction);
      }
    });
  }

  userCanManageThisSearchAlert(): boolean {
    return checkUserAlertPermissions(this.query, this.permissionsService);
  }

  private isAlertEnabled(): Promise<boolean> {
    return new Promise((resolve) => {
      this.alertService.getAlertRules(this.query.id).subscribe((response) => {
        let enabled = false;
        if (response.status === 200) {
          let data: AlertRule[] = response.body;
          if (data.length > 0) {
            let alertRule = data[0];
            enabled = alertRule.enabled;
          }
        }
        resolve(enabled);
      }, (error) => {
        console.error('Failed to check alert rule status:', error);
        resolve(false);
      });
    });
  }
}
