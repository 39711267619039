import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalConfig } from '@ondemand/ui-components';
import {
  AuditConstants,
  ODADefaultIcons
} from '../../../../../shared/models/ui-constants';
import { BHEStringsEN } from '../../../../../shared/application-strings/bhe-strings-EN';
import { AppFacadeService } from '@ondemand/core';
import { take } from 'rxjs/operators';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'bhe-service-connected-modal',
  templateUrl: './bhe-service-connected.modal.component.html',
  styleUrls: ['./bhe-service-connected.modal.component.scss']
})
export class BHEServiceConnectedModalComponent implements OnInit {
  @Input() modalConfig: ModalConfig = {
    customWidth: 720,
    customHeight: 278,
    headerText: BHEStringsEN.ServiceConnectedModalTitle,
    headerIcon: ODADefaultIcons.Info.name,
    headerIconColor: ODADefaultIcons.Info.defaultColor
  };

  modalMessage: string;
  actionLabel: string;
  actionDescription: string;
  confirmButtonText: string;
  organizationId: string;

  get alertPlanUrl(): string {
    return `/#/auditing/auditing/alerts/plans/AP.${AuditConstants.BloodhoundAlertPlanId}.${this.organizationId}`;
  }

  constructor(
    private facadeService: AppFacadeService,
    //TODO: Use new dialog ref when core UI component upgrades
    private dialogRef: MatLegacyDialogRef<BHEServiceConnectedModalComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.organizationId = await this.facadeService.selectedOrgId$
      .pipe(take(1))
      .toPromise();
    this.setupText();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  setupText(): void {
    this.modalMessage = BHEStringsEN.ServiceConnectedModalMessage;
    this.actionLabel = BHEStringsEN.ServiceConnectedModalActionLabel;
    this.actionDescription =
      BHEStringsEN.ServiceConnectedModalActionDescription;
    this.confirmButtonText =
      BHEStringsEN.ServiceConnectedModalConfirmButtonLabel;
  }
}
