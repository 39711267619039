import { EModuleName } from '@ondemand/core';

export const auditModuleName = EModuleName.AUDITING;
export const changeAuditorSubscriptionURL =
  'https://www.quest.com/BuyOnDemandAudit';

export const defaultHttpRequestTimeout = 30000;

export const visualizationRowLimit = 1000000;

export const exportRowLimit = 50000;
