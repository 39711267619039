import { ODToastService } from '@ondemand/ui-components';

export enum ToastType {
  Information = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}

export function toastLowerLeft(toastService: ODToastService, displayText: string, type: ToastType): void {
  const toastData = {
    toastType: type,
    message: displayText,
    hideDismissIcon: true,
    preventDismiss: false
  };

  toastService.open(toastData, 'left', 'bottom');
}
