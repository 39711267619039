import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetService } from '../../../../../../shared/services/widget-service';
import { StaticIndicatorID } from '../../../models/widget-info';
import { StaticIndicatorResponse } from '../models/static-indicator.models';

@Injectable()
export class StaticIndicatorDataService {
  constructor(private widgetService: WidgetService) {}

  getAdAccountLockouts(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.AdAccountLockouts);
  }

  getAllFailedSignins(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.AllFailedSignins);
  }

  getAzureADCriticalRoleChanges(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.AzureADCriticalRoleChanges);
  }

  getAzureADFailedSignins(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.AzureADFailedSignins);
  }

  getAzureADRiskEvents(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.AzureADRiskEvents);
  }

  getCloudOnlyAzureADUsersCreated(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.CloudOnlyAzureADUsersCreated);
  }

  getO365ExternalUserActions(): Observable<StaticIndicatorResponse> {
    return this.getWidgetData(StaticIndicatorID.O365ExternalUserActions);
  }

  private getWidgetData(
    widgetId: StaticIndicatorID
  ): Observable<StaticIndicatorResponse> {
    return this.widgetService.getWidgetDataWithHttpInfo(widgetId).pipe(
      map(response => response.body)
    );
  }
}
