import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppFacadeService } from '@ondemand/core';
import { Observable } from 'rxjs';

const urlMap = {
  config: 'o365auditconfig',
  query: 'query',
  event: 'query/event',
  alerts: 'alerts',
  caConfig: 'CAConfig',
  auditHub: 'AuditHub',
  auditConfig: 'AuditConfig',
  dashboard: 'dashboard',
  chart: 'query/chartQuery',
  bloodhound: 'tierzero',
  bloodhoundWorkers: 'tierzeroWorkers'
};

@Injectable()
export class ServiceDiscoveryService {
  constructor(private facadeService: AppFacadeService) {}

  getQueryUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandCommonStorageAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.query}`)
    );
  }

  getChartUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandCommonStorageAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.chart}`)
    );
  }

  getAuditingConfigServiceUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.config}`)
    );
  }

  getEventUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandCommonStorageAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.event}`)
    );
  }

  getAlertsUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandCommonStorageAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.alerts}`)
    );
  }

  getCAConfigUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.caConfig}`)
    );
  }

  getAuditHubUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandCommonStorageAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.auditHub}`)
    );
  }

  getAuditConfigUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.auditConfig}`)
    );
  }

  getBloodHoundUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.bloodhound}`)
    );
  }

  getBloodHoundWorkersUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.bloodhoundWorkers}`)
    );
  }

  getDashboardUrl$(): Observable<string> {
    return this.getBaseUrlForOnDemandAuditingAPI$().pipe(
      map(baseUrl => `${baseUrl}/${urlMap.dashboard}`)
    );
  }

  private getBaseUrlForOnDemandAuditingAPI$(): Observable<string> {
    return this.facadeService.selectedRegion$.pipe(
      filter(regionConfig => regionConfig !== null),
      map(regionConfig => regionConfig.AUDITING_API_BASE_URL)
    );
  }

  private getBaseUrlForOnDemandCommonStorageAPI$(): Observable<string> {
    return this.facadeService.selectedRegion$.pipe(
      filter(regionConfig => regionConfig !== null),
      map(regionConfig => regionConfig.COMMON_STORAGE_API_BASE_URL)
    );
  }
}
