import { EventField } from '../../../../../models/event-field.model';
import {
  OdaChartType,
  RenderType,
  IntervalCategory
} from '../../../../../../shared/components/chart/models/visualization-constants';
import { QueryClause, IInputConfig } from '@ondemand/core';

export interface VisualizeParameters {
  visualizeAs?: RenderType;
  chartType?: OdaChartType;
  groupByField?: EventField;
  summarizeBy?: IntervalCategory;
}

export function validateVisualParams(params: VisualizeParameters): boolean {
  if (!params) {
    return false;
  }

  if (!params.visualizeAs) {
    return false;
  }

  if (params.visualizeAs === RenderType.Grid) {
    return true;
  }

  return !!params.chartType && !!params.groupByField &&
          ( params.chartType === OdaChartType.Donut || params.chartType === OdaChartType.H_BAR || !!params.summarizeBy);
}

export interface ColumnEditorOptions {
  visualizeParameters: VisualizeParameters;
  newColumns: string[];
}

export enum EditorStatus {
  OK = 'OK',
  EditingInProgress = 'Editing-In-Progress',
  Initialized = 'Editor-Initialized-Not-Yet-Touched',
}

export interface IColumnData {
  name?: string;
  deprecated?: boolean;
  dragulaId?: number;
  clause?: QueryClause;
  clauseKey?: string;
  inputConfig?: IInputConfig;
  fieldValid?: boolean;
}
