// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import {
  takeUntil
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CriticalActivityState } from './reducers/index';
import { LoadCriticalActivityList } from './critical-activity-list/state/critical-activity-list.actions';
import { AuditingBreadcrumbsService } from '../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../services/locale-strings.service';

@Component({
  templateUrl: './critical-activity-tab.component.html',
  styleUrls: ['./critical-activity-tab.component.scss']
})
export class CriticalActivityTabComponent extends BaseComponent implements OnInit {
  constructor(
    private store: Store<CriticalActivityState>,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private localeStringsService: LocaleStringsService
  ) {
    super();
  }

  async ngOnInit() {
    this.loadCriticalActivity();
    this.localeStringsService.strings$.pipe(takeUntil(this.destructionSubject))
      .subscribe((labels: any) => {
        this.breadcrumbsService.set([
          {
            title: labels.auditing.pages.criticalActivity.title,
            url: 'auditing/auditing/criticalActivity'
          }
        ]);
      });
  }

  private loadCriticalActivity() {
    this.store.dispatch(new LoadCriticalActivityList());
  }
}
