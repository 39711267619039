<general-qod-component
[loading]="dataLoading$ | async"
[error]="dataError$ | async"
[data]="data$ | async"
[height]="cardHeight"
[card]="true"
>

<div
  component-header
  class="card-header">
  <qod-multiselect-dropdown
    *ngIf="dataReady"
    listId="workload-list"
    dropdownText="{{ 'auditing.pages.auditingDashboard.signins.dropdownText' | localize }}"
    allSelectionHeaderText="{{ 'auditing.pages.auditingDashboard.signins.dropdownSelectAllHeader' | localize }}"
    allSelectionSubText="{{ 'auditing.pages.auditingDashboard.signins.dropdownSelectAllDescription' | localize }}"
    [items]="workloadLabels"
    [selected]="selectedWorkloads"
    (selectedChange)="onSelectedChanged($event)"></qod-multiselect-dropdown>
  <card-header-with-date
    [header]="cardHeader"
    [date]="dateHeader">
  </card-header-with-date>
</div>

<skeleton-block component-loading type="TitleWithLineGraph"></skeleton-block>

<div component-error>
  <error-card
    error="{{ 'auditing.pages.auditingDashboard.error' | localize }}"
    button="{{ 'auditing.pages.auditingDashboard.tryAgain' | localize }}"
    (buttonClick)="loadSignins()"
  ></error-card>
</div>

<div component-data>
  
  <ng-container *ngIf="dataReady">
    
    <base-generic-chart
    [displayLegend]="false"
    [chartLabels]="xAxisLabels"
    [options]="chartOptions"
    [chartType]="chartType"
    [containerStyle]="containerStyle"
    [chartPosition]="chartPosition"
    [datasets]="chartDatasets"
    [chartColors]="chartColors"
    >
    </base-generic-chart>

    <qod-legend-row [legendItems]="legendItems"></qod-legend-row>

  </ng-container>

</div>

</general-qod-component>