/**
 * Service for getting trial status information.
 */
import { of, Observable } from 'rxjs';
import { catchError, map, switchMap, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { TrialStatus } from '../models/trial-status.model';
import { ServiceDiscoveryService } from './service-discovery.service';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class TrialStatusService {
  protected basePath$: Observable<string>;
  public defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = {
    withCredentials: false
  };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = this.serviceDiscoveryService.getAuditHubUrl$();
  }

  /**
   * Get the trial status. Indicates whether the trial limit is reached.
   */
  public getTrialStatus(): Observable<TrialStatus> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = `${basePath}/trialStatus`;
      let headers = this.defaultHeaders;
      let requestOptions = {
        headers,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(
            timeout(defaultHttpRequestTimeout),
            map((response: HttpResponse<any>) => {
              const trialStatus = new TrialStatus();

              if (response.status === 200) {
                return Object.assign(trialStatus, response.body);
              } else if (response.status === 404) {
                return trialStatus;
              }

              console.error('Unexpected response:', response);
              return trialStatus;
            }),
            catchError((_error) => of(new TrialStatus()))
          );
    }));
  }

  private getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }
}
