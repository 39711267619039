/**
 * model class used to call update query api
 */

import { QueryBody } from './query-body';
import { Query } from './query.model';

export class UpdateQuery {
  name: string;
  starred = false;
  q: QueryBody;
  categoryId: string;
  hideCharts?: boolean;
  hideEvents?: boolean;

  constructor(fullQueryObj?: Query) {
    if (fullQueryObj !== null && fullQueryObj !== undefined) {
      this.name = fullQueryObj.name;
      this.starred = fullQueryObj.starred;
      this.q = fullQueryObj.q;
      this.categoryId = fullQueryObj.categoryId;
      this.hideCharts = fullQueryObj.hideCharts;
      this.hideEvents = fullQueryObj.hideEvents;
    }
  }
}
