import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BaseComponent } from '@ondemand/core';
import { Color } from 'ng2-charts';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import { TopActiveUsersWidgetResponse } from './models/top-active-users.server.response';
import { TopActiveUsersState } from './state/top-active-users.reducer';
import {
  selectTopActiveUsersData,
  selectTopActiveUsersError,
  selectTopActiveUsersExpired,
  selectTopActiveUsersLoading
} from './state/top-active-users.selectors';
import { LoadTopActiveUsers } from './state/top-active-users.actions';
import { TopActiveUsersMouseEventService } from './services/top-active-users.mouse-event.services';
import { ChartDataSets } from 'chart.js';
import { ExcludedUsersEditorComponent } from './editor/excluded-users-editor.component';
import { AuditSerivceName } from '../../../../../shared/models/audit-workload.models';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { TopActiveUserDataModel } from './models/top-active-user.domain.model';
import { topActiveUsersChartTooltipHandler } from './services/top-active-users.chart.tooltip';
import * as SearchPermissions from '../../../../models/audit-permissions.model';

@Component({
  selector: 'top-active-users-widget',
  templateUrl: './top-active-users.component.html',
  styleUrls: ['./top-active-users.component.scss']
})
export class TopActiveUsersComponent extends BaseComponent implements OnInit {
  @Input() refreshObservable$: Observable<void>;
  @ViewChild(ExcludedUsersEditorComponent)
  editorModal: ExcludedUsersEditorComponent;

  readonly cardHeight = '21rem';

  // Chart Parameters
  readonly chartType = 'horizontalBar';
  readonly containerStyle = { width: '100%', height: '90%' };
  readonly chartPosition = { width: '100%', height: '12.2rem' };
  chartColors: Color[];
  chartOptions: any;
  chartDatasets: ChartDataSets[];

  // obervable for data storage
  data$: Observable<TopActiveUsersWidgetResponse>;
  dataLoading$: Observable<boolean>;
  dataExpired$: Observable<boolean>;
  dataError$: Observable<boolean>;

  // text for template
  cardHeader: string;
  dateHeader: string;
  dropdownText: string;
  dropdownSelectAllHeader: string;
  dropdownSelectAllDescription: string;
  editBtnText: string;
  errorLabel: string;
  tryAgainLabel: string;

  // boolean flags for syncing statuses among components/templates
  userHasRunSearchPermissions: boolean;
  userHasPermissionToEditor: boolean;
  editorOpened: boolean;
  chartDataReady: boolean;
  isLoadingData: boolean;
  errorOccurred: boolean;
  dataExist: boolean;
  isDataExpired: boolean;

  // data
  topActiveUserModel: TopActiveUserDataModel;
  cachedResponse: TopActiveUsersWidgetResponse;

  constructor(
    private permissionsService: AuditModulePermissionsService,
    private store: Store<TopActiveUsersState>,
    private mouseEventService: TopActiveUsersMouseEventService
  ) {
    super();
    this.topActiveUserModel = new TopActiveUserDataModel();
  }

  ngOnInit(): void {
    this.setupTemplateText();

    this.data$ = this.store.select(selectTopActiveUsersData);
    this.data$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => !this.editorOpened && !!response)
      )
      .subscribe(data => {
        this.dataExist = !!data;
        this.isDataExpired = false;
        this.initializeChartData(data);
      });

    this.dataLoading$ = this.store.select(selectTopActiveUsersLoading);
    this.dataLoading$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(_ => !this.editorOpened)
      )
      .subscribe(loadingFlag => (this.isLoadingData = loadingFlag));

    this.dataExpired$ = this.store.select(selectTopActiveUsersExpired);
    this.dataExpired$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(expired => {
        if (this.editorOpened) {
          if (expired) {
            // while the editor opened
            // we want to capture any expired events
            this.isDataExpired = true;
          }
        } else {
          this.isDataExpired = expired;
          if (expired) {
            this.loadTopActiveUser();
          }
        }
      });

    this.dataError$ = this.store.select(selectTopActiveUsersError);
    this.dataError$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(_ => !this.editorOpened)
      )
      .subscribe(errorFlag => (this.errorOccurred = errorFlag));

    this.initializeUserPermissions();

    this.loadTopActiveUser();
    this.listenToRefresh();
  }

  loadTopActiveUser(): void {
    this.chartDataReady = false;
    this.store.dispatch(new LoadTopActiveUsers());
  }

  onSelectedServicesChanged(selectedValues: AuditSerivceName[]) {
    this.topActiveUserModel.selectedServices = selectedValues;
    this.store.dispatch(this.topActiveUserModel.createUpdateAction);
  }

  handleEditButtonClick() {
    this.editorOpened = true;
    this.editorModal.openEditor(this.cachedResponse);
  }

  handleEditorClose() {
    this.editorOpened = false;
    if (this.isDataExpired) {
      this.loadTopActiveUser();
    }
  }

  private listenToRefresh() {
    this.refreshObservable$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(_ => this.loadTopActiveUser());
  }

  private initializeChartData(
    dataFromRedux: TopActiveUsersWidgetResponse
  ): void {
    this.cachedResponse = dataFromRedux;
    this.topActiveUserModel = new TopActiveUserDataModel(dataFromRedux);

    // this will let ngIf to destroy the chart
    this.chartDataReady = false;

    // allow some time for angular to do the change detection
    // that will remove the old chart with previous data
    // and then re-render a new chart
    setTimeout(() => {
      this.chartDatasets = this.topActiveUserModel.chartDataSets;
      this.chartColors = [{} as Color];
      this.chartOptions = this.generateOptions(
        this.topActiveUserModel.maxUserTotalEventCount
      );
      this.chartDataReady = true;
    }, 50);
  }

  private generateOptions(maxValue: number): any {
    const default_tick = {
      suggestedMax: 10,
      suggestedMin: 1,
      precision: 0,
      beginAtZero: true,
      stepSize: 1
    };

    return {
      responsive: true,
      scaleShowVerticalLines: true,
      maintainAspectRatio: false,
      title: {},
      legend: { display: false },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
              drawBorder: true
            },
            ticks: maxValue <= 10 ? default_tick : {}
          }
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ]
      },
      tooltips: {
        enabled: false,
        mode: 'nearest',
        custom: (tooltipModel: any) =>
          topActiveUsersChartTooltipHandler(
            tooltipModel,
            this.topActiveUserModel.userToolTips
          )
      },
      events: ['click', 'mousemove', 'mouseout'],
      onClick: (event: any, chartElement: any[]) =>
        this.mouseEventService.clickHandler(
          event,
          chartElement,
          this.topActiveUserModel.queries,
          this.userHasRunSearchPermissions
        ),
      onHover: (event: any, chartElement: any[]) =>
        this.mouseEventService.hoverHandler(
          chartElement,
          this.userHasRunSearchPermissions
        )
    };
  }

  private initializeUserPermissions(): void {
    this.userHasRunSearchPermissions =
      this.permissionsService.hasAnyOfPermissions([
        SearchPermissions.canRunPrivateSearch,
        SearchPermissions.canRunSharedSearches
      ]);

    this.userHasPermissionToEditor =
      this.canRunAndViewPrivateSearches() || this.canRunAndViewSharedSearches();
  }

  private canRunAndViewPrivateSearches(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunPrivateSearch
    ]);
  }

  private canRunAndViewSharedSearches(): boolean {
    return this.permissionsService.hasAllOfPermissions([
      SearchPermissions.canRunSharedSearches,
      SearchPermissions.canViewSharedSearches
    ]);
  }

  private setupTemplateText(): void {
    this.errorLabel =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard.error;
    this.tryAgainLabel =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard.tryAgain;

    const stringProvider: any =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard
        .topActiveUsers;

    this.cardHeader = stringProvider.title;
    this.dateHeader = stringProvider.timeRangeDisplay;
    this.dropdownText = stringProvider.dropdownText;
    this.dropdownSelectAllHeader = stringProvider.dropdownSelectAllHeader;
    this.dropdownSelectAllDescription =
      stringProvider.dropdownSelectAllDescription;
    this.editBtnText = stringProvider.editBtnText;
  }
}
