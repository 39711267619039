<h4 class="activity-name">{{ activity }}</h4>
<h5 class="service">{{ service }}</h5>

<div class="event-summary">

  <div class="summary-item time-detected" title="{{ 'auditing.timeDetectedFieldName' | localize }}">
    <edc-icon name="clock"></edc-icon> {{ timeDetected | dateFromNow }} - {{ timeDetected | date:'medium' }}
  </div>

  <ng-container *ngIf="userDisplayName">
    <div class="summary-item user-info" title="{{ 'auditing.userFieldName' | localize }}">
     <edc-icon name="user"></edc-icon> {{ userDisplayName }} <span *ngIf="userEmailAddress">({{ userEmailAddress }})</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!userDisplayName">
    <div class="summary-item user-info" title="{{ 'auditing.userFieldName' | localize }}">
      <edc-icon name="user"></edc-icon> {{ userEmailAddress }}
    </div>
  </ng-container>

  <div class="summary-item target" title="{{ 'auditing.targetFieldName' | localize }}">
    <edc-icon name="target"></edc-icon><span [innerHTML]="target"></span>
  </div>

  <div class="summary-item location" *ngIf="origin || geographicLocation" title="{{ 'auditing.originFieldName' | localize }}">
    <edc-icon name="location"></edc-icon>
    <span *ngIf="geographicLocation">{{ geographicLocation }}</span>
    <span class="location-separator" *ngIf="origin && geographicLocation"> - </span>
    <span *ngIf="origin">{{ origin }}</span>
  </div>

  <div *ngIf="successful" class="summary-item event-status successful" title="{{ 'auditing.statusFieldName' | localize }}"><edc-icon name="status-done"></edc-icon> {{ 'auditing.eventSuccessful' | localize }}</div>
  <div *ngIf="!successful" class="summary-item event-status failed" title="{{ 'auditing.statusFieldName' | localize }}"><edc-icon name="status-error"></edc-icon> {{ 'auditing.eventFailed' | localize }}</div>

  <div *ngIf="modifiedProperty" class="summary-item modified-property">
    <span title="{{ 'auditing.modifiedPropertyName' | localize }}">
      <edc-icon name="category"></edc-icon>
      <span>{{ modifiedProperty.name }}</span>
    </span>
    <span *ngIf="modifiedProperty.before || modifiedProperty.after" class="modified-property-before-and-after">
      <del title="{{ modifiedProperty.before }}">{{ modifiedProperty.before || "null" }}</del>
      <span class="modified-property-arrow"><div></div><div></div></span>
      <ins title="{{ modifiedProperty.after }}">{{ modifiedProperty.after || "null"  }}</ins>
    </span>
  </div>

  <div class="summary-item additional-summary" *ngIf="eventSummary" title="{{ 'auditing.additionalEventSummaryName' | localize }}">
    <edc-icon name="note"></edc-icon>
    <span *ngIf="eventSummary">{{ eventSummary }}</span>
  </div>

</div>
