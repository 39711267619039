<qod-sheet
  variant="medium"
  [isOpen]="isOpen"
  [heading]="headingText"
  [subHeading]="subHeadingText"
  [ignoreBackdropClick]="true"
  (close)="handleCloseEvent()"
  >

  <div sheet-content>

    <ng-container *ngIf="savingConfig">
      <internal-loading [information]="savingMessage" isLoading="true"></internal-loading>
    </ng-container>

    <ng-container *ngIf="!savingConfig">

      <div class="learn-more-block">

        <div class="learn-more-title">
          {{this.learnMoreDescription}}
          <span class="learn-more-toggle" (click)="toggleLearnMore()">
            {{this.learnMoreButtonLabel}}
            <span class="chevron-icon-box">
              <mat-icon class="chevron-icon" [svgIcon]="chevronIconName"></mat-icon>
            </span>
          </span>
        </div>

        <div *ngIf="learnMoreExpanded" class="pat-token-steps">
          <span>
            {{this.howtoStepsTitle}}
          </span>
          <ol type="1">
            <li>{{this.howtoStep1}}</li>
            <li>{{this.howtoStep2}}</li>
            <li>{{this.howtoStep3}}</li>
            <li>{{this.howtoStep4}}</li>
            <li>{{this.howtoStep5}}</li>
            <li>{{this.howtoStep6}}</li>
            <li>{{this.howtoStep7}}</li>
          </ol>
        </div>

      </div>

      <div *ngIf="showBanner" class="bottom-gap">
        <od-notification-banner
          [text]="notificationMessage"
          [mode]="notificationMode"
          [icon]="notificationIconName"
          dismissable="true"
          (dismissClicked)="dismissBanner()">
        </od-notification-banner>
      </div>

      <form [formGroup]="bheConfigForm">

          <div class="bottom-gap">
            <od-form-field
            type="input"
            [label]="urlFieldLabel"
            [placeholder]="urlFieldPlaceHolder"
            formControlName="bheUrlField">
          </od-form-field>
          <div class="form-field-hint">
            {{this.urlFieldHint}}
          </div>
          </div>

          <div class="bottom-gap">
            <od-form-field
              type="input"
              [label]="tokenFieldLabel"
              [placeholder]="tokenFieldPlaceHolder"
              formControlName="bheTokenId">
            </od-form-field>
            <div class="form-field-hint">
              {{this.tokenFieldHint}}
            </div>
          </div>


          <div class="bottom-gap">
            <od-form-field
              type="input"
              inputType="password"
              [label]="keyFieldLabel"
              [placeholder]="keyFieldPlaceHolder"
              formControlName="bheKey">
            </od-form-field>
          </div>


      </form>

      <button id="bhe-config-validate-button" class="btn btn-secondary" [disabled]="diableValidateButton" (click)="validateBheConfig()">
        {{this.validateBtnLabel}}
      </button>

    </ng-container>

  </div>

  <div sheet-footer-left>

    <ng-container *ngIf="!savingConfig">

      <button id="bhe-config-save-button" class="btn btn-primary" [disabled]="!inputValidated" (click)="saveBheConfig()">
        {{this.saveBtnLabel}}
      </button>
      <button
        id="bhe-config-cancel-button"
        class="btn btn-secondary"
        (click)="handleCloseEvent()">
        {{this.cancelBtnLabel}}
      </button>

    </ng-container>

  </div>

</qod-sheet>
