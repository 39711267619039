<form #f="ngForm">

  <ng-container *ngIf="predefinedOnly">
    <div class="dropdown-container">
      <!--TODO: Make this conditional on field type-->
      <predefined-value-field
        [index]="index"
        name="predefined-menu-{{index}}"
        [(ngModel)]="selectedMenuOptions"
        [outputMode]="'object'"
        [field]="field"
        [multiple]="true"
        (change)="onPredefinedMenuChange($event)"
      ></predefined-value-field>
    </div>
  </ng-container>

  <ng-container *ngIf="chipsParams">
    <div class="input-field chips-container" [ngClass]="{predefined: predefinedOnly}">
      <div #chips class="chips" materialize="material_chip" [materializeParams]="[chipsParams]"
        (chip.add)="onAdd($event.detail)" (chip.delete)="onDelete($event.detail)"
        [materializeActions]="chipsActions"
      ></div>
    </div>
  </ng-container>

</form>
