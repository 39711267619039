import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuditHealthDataService } from '../services/audit-health.data.service';
import {
  LoadAuditHealth,
  LoadAuditHealthFail,
  LoadAuditHealthSuccess,
  LOAD_AUDIT_HEALTH,
  UPDATE_AUDIT_HEALTH,
  UpdateAuditHealth,
  UpdateAuditHealthFail,
  UpdateAuditHealthSuccess
} from './audit-health.actions';

@Injectable()
export class AuditHealthEffects {
  loadAuditHealth$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAuditHealth>(LOAD_AUDIT_HEALTH),
    switchMap(action =>
      this.auditHealthService.getAuditHealth().pipe(
        map(response => new LoadAuditHealthSuccess(response)),
        catchError(error => of(new LoadAuditHealthFail(error)))
      )
    )
  ));

  updateAuditHealth$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateAuditHealth>(UPDATE_AUDIT_HEALTH),
    switchMap(action =>
      this.auditHealthService.updateSelectedAuditHealth(action.payload).pipe(
        map(response => response.status === 200
            ? new UpdateAuditHealthSuccess()
            : new UpdateAuditHealthFail(
                `Unexpected response code received: ${response.status}`
              )),
        catchError(error => of(new UpdateAuditHealthFail(error)))
      )
    )
  ));
  constructor(
    private auditHealthService: AuditHealthDataService,
    private actions$: Actions
  ) {}
}
