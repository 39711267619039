/**
 * Component for navigating between pages and displaying related information in query results
 */

import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { AuditingDisplayStringsProvider } from '../../../application-strings-EN';

@Component({

  selector: 'result-pagination',
  templateUrl: './result-pagination.component.html',
  styleUrls: ['./result-pagination.component.scss']
})
export class ResultPaginationComponent implements OnInit, OnChanges {
  @Input() currentPage: number;
  @Input() totalRows: number;
  @Input() resultsPerPage: number;
  @Input() showButtons: boolean;
  @Input() includeCountView: boolean;
  @Input() pageOptionsShown: number;

  paginationOptions: number[] = [];
  pagesAvailable: number;
  auditingStrings = AuditingDisplayStringsProvider.auditing;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.refreshPagination();
  }

  ngOnChanges(changes: any) {
    if (changes.currentPage || changes.totalRows) {
      this.refreshPagination();
    }
  }

  /**
   * Get the number of the last result item currently being displayed
   */
  getLastPaginationNum() {
    return Math.min((this.currentPage + 1) * this.resultsPerPage, this.totalRows);
  }

  /**
   * Re-calculate the displayed page options
   */
  refreshPagination() {
    this.pagesAvailable = Math.ceil(this.totalRows / this.resultsPerPage);
    let pages = [this.currentPage];
    // Add prev pages
    for (let i = this.currentPage - 1; i >= 0 && pages.length < this.pageOptionsShown / 2; i--) {
      pages.unshift(i);
    }

    // Add following pages
    for (let i = this.currentPage + 1; i < this.pagesAvailable && pages.length < this.pageOptionsShown; i++) {
      pages.push(i);
    }

    // Add more prev pages if needed
    for (let i = pages[0] - 1; i >= 0 && pages.length < this.pageOptionsShown; i--) {
      pages.unshift(i);
    }
    this.paginationOptions = pages;
  }

  /**
   * Update current page to a specific page number
   *
   * @param pageNum Page number
   */
  goToPage(pageNum: number) {
    this.currentPage = pageNum;
    this.pageChange.emit(this.currentPage);
    this.refreshPagination();
  }

  /**
   * Advance pagination to next page
   */
  onNextPageClick() {
    if (this.currentPage < this.pagesAvailable - 1) {
      this.goToPage(this.currentPage + 1);
    }
  }

  /**
   * Return pagination to previous page
   */
  onPrevPageClick() {
    if (this.currentPage > 0) {
      this.goToPage(this.currentPage - 1);
    }
  }

  /**
   * Navigate to a specific page
   *
   * @param pageNum Page number
   */
  onPageNumClick(pageNum: number) {
    if (pageNum < this.pagesAvailable && pageNum >= 0) {
      this.goToPage(pageNum);
    }
  }
}
