import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from '@ondemand/core';
import { UpdateTopActiveUsersSettings } from '../../../../../../shared/models/update-widget-configuration';
import { AuditingDisplayStringsProvider } from '../../../../../../application-strings-EN';
import {
  ExcludeTopActiveUserEditorData,
  MAX_EXCLUDED_USER_ROWS
} from './models/top-active-user-editor.domain.model';
import {
  LoadTopActiveUsers,
  UpdateTopActiveUsersConfig
} from '../state/top-active-users.actions';
import { TopActiveUsersState } from '../state/top-active-users.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  selectTopActiveUsersData,
  selectTopActiveUsersError,
  selectTopActiveUsersExpired,
  selectTopActiveUsersLoading,
  selectTopActiveUsersUpdating
} from '../state/top-active-users.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { TopActiveUsersWidgetResponse } from '../models/top-active-users.server.response';

@Component({
  selector: 'excluded-users-editor',
  templateUrl: './excluded-users-editor.component.html',
  styleUrls: [
    './excluded-users-editor.component.scss',
    '../../../../../../shared/styles/core-style-overrides.scss'
  ]
})
export class ExcludedUsersEditorComponent
  extends BaseComponent
  implements OnInit {
  @Output() closeEvent = new EventEmitter();
  @ViewChild('edtiorForm') form: NgForm;

  MaxExcludedUserCountAllowed: number = MAX_EXCLUDED_USER_ROWS;

  // text for template
  headingText: string;
  subHeadingText: string;
  activeUsernameColumnHeader: string;
  eventCountColumnHeader: string;
  maxExcludeUserWarningText: string;

  addSelectedTopActiveUserButtonText: string;
  addSelectedTopActiveUserTextWithCount: string;
  excludedUsersHeadingText: string;
  excludedUsersHeadingTextWithCount: string;
  excludedUsernameColumnHeader: string;
  excludedUserActionColumnHeader: string;
  removeExcludedUsersButtonText: string;
  removeExcludedUsersButtonTextWithCount: string;
  closeButtonLabel: string;
  loadingLabel: string;
  noUsersMessage: string;
  errorMessage: string;
  tryAgainButtonLabel: string;
  // end of Text for template

  isOpen: boolean;
  isLoadingData: boolean;
  isUpdatingData: boolean;
  errorOccurred: boolean;
  selectAllTopActiveUsers: boolean;
  selectedActiveUserCount: number;
  selectAllExcludedUsers: boolean;
  selectedToExcludeUserCount: number;
  topActiveUserCount: number;
  excludedUserCount: number;
  maxExcludeUserWarning: boolean;

  // data
  editorData: ExcludeTopActiveUserEditorData;

  // obervable for data storage
  data$: Observable<TopActiveUsersWidgetResponse>;
  dataLoading$: Observable<boolean>;
  dataExpired$: Observable<boolean>;
  dataUpdating$: Observable<boolean>;
  dataError$: Observable<boolean>;

  constructor(private store: Store<TopActiveUsersState>) {
    super();
    this.editorData = new ExcludeTopActiveUserEditorData();
    this.isOpen = false;
  }

  ngOnInit(): void {
    this.setupTemplateText();

    this.data$ = this.store.select(selectTopActiveUsersData);
    this.data$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => this.isOpen && !!response)
      )
      .subscribe(response => this.initializeEditorData(response));

    this.dataError$ = this.store.select(selectTopActiveUsersError);
    this.dataError$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => this.isOpen)
      )
      .subscribe(errorFlag => (this.errorOccurred = errorFlag));

    this.dataLoading$ = this.store.select(selectTopActiveUsersLoading);
    this.dataLoading$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => this.isOpen)
      )
      .subscribe(loadingFlag => (this.isLoadingData = loadingFlag));

    this.dataUpdating$ = this.store.select(selectTopActiveUsersUpdating);
    this.dataUpdating$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => this.isOpen)
      )
      .subscribe(updateFlag => (this.isUpdatingData = updateFlag));

    this.dataExpired$ = this.store.select(selectTopActiveUsersExpired);
    this.dataExpired$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(expired => this.isOpen && expired)
      )
      .subscribe(expired => {
        this.loadEditorData();
      });
  }

  openEditor(cachedResponse: TopActiveUsersWidgetResponse): void {
    this.initializeEditorData(cachedResponse);
    this.isOpen = true;
  }

  loadEditorData(): void {
    this.errorOccurred = false;
    this.store.dispatch(new LoadTopActiveUsers());
  }

  handleCloseEvent(): void {
    this.isOpen = false;
    this.closeEvent.emit();
  }

  handleDismissWarningClick(): void {
    this.maxExcludeUserWarning = false;
  }

  handleSelectAllTopActiveUsersCheckChange(): void {
    this.editorData.users.forEach(
      user => (user.isSelected = this.selectAllTopActiveUsers)
    );

    this.updateSelectedTopActiveUserStatuses();
  }

  handleSelectAllExcludedUsersCheckChange(): void {
    this.editorData.excludedUsers.forEach(
      user => (user.isSelected = this.selectAllExcludedUsers)
    );

    this.updateExcludedUsersStatuses();
  }

  updateSelectedTopActiveUserStatuses(): void {
    const selectedActiveUsers = this.editorData.users.filter(
      user => user.isSelected
    );
    this.topActiveUserCount = this.editorData.users.length;
    this.selectedActiveUserCount = selectedActiveUsers.length;
    this.selectAllTopActiveUsers =
      this.selectedActiveUserCount === this.editorData.users.length &&
      this.editorData.users.length > 0;

    this.updateButtonLabel();
  }

  updateExcludedUsersStatuses(): void {
    this.excludedUserCount = this.editorData.excludedUsers.length;
    this.excludedUsersHeadingTextWithCount = `${this.excludedUsersHeadingText} (${this.excludedUserCount})`;
    const selectedExcludedUsers = this.editorData.excludedUsers.filter(
      user => user.isSelected
    );
    this.selectedToExcludeUserCount = selectedExcludedUsers.length;
    this.selectAllExcludedUsers =
      this.selectedToExcludeUserCount ===
        this.editorData.excludedUsers.length &&
      this.editorData.excludedUsers.length > 0;

    this.updateButtonLabel();
  }

  handleAddExcludedUsers(): void {
    const selectedUsernames: string[] = this.editorData.users
      .filter(user => user.isSelected)
      .map(user => user.fullUsername);

    const existingExcludedUsernames: string[] =
      this.editorData.excludedUsers.map(x => x.fullUsername);

    const usersToExclude: string[] = Array.from(
      new Set<string>([...existingExcludedUsernames, ...selectedUsernames])
    );
    this.persistDataChanges(usersToExclude);
  }

  handleRemoveExcludedUsers(): void {
    const usersToExclude = this.editorData.excludedUsers
      .filter(user => !user.isSelected)
      .map(user => user.fullUsername);

    this.persistDataChanges(usersToExclude);
  }

  handleRemoveSingleExcludedUser(username: string): void {
    const existingExcludedUsernames: string[] =
      this.editorData.excludedUsers.map(x => x.fullUsername);
    const usersToExclude: string[] = existingExcludedUsernames.filter(
      excludeUsername => excludeUsername !== username
    );
    this.persistDataChanges(usersToExclude);
  }

  private persistDataChanges(usersToExclude: string[]): void {
    const updateSettings = {
      selectedServices: this.editorData.selectedServices,
      excludedUsers: usersToExclude
    } as UpdateTopActiveUsersSettings;

    const updateAction = new UpdateTopActiveUsersConfig(updateSettings);
    this.store.dispatch(updateAction);
    // reset select all checkboxes
    this.selectAllTopActiveUsers = false;
    this.selectAllExcludedUsers = false;
  }

  private updateButtonLabel(): void {
    if (!this.selectedActiveUserCount || this.selectedActiveUserCount < 1) {
      this.selectedActiveUserCount = 0;
    }
    this.addSelectedTopActiveUserTextWithCount = `${this.addSelectedTopActiveUserButtonText} (${this.selectedActiveUserCount})`;

    if (
      !this.selectedToExcludeUserCount ||
      this.selectedToExcludeUserCount < 1
    ) {
      this.selectedToExcludeUserCount = 0;
    }
    this.removeExcludedUsersButtonTextWithCount = `${this.removeExcludedUsersButtonText} (${this.selectedToExcludeUserCount})`;
  }

  private initializeEditorData(
    serverResponse: TopActiveUsersWidgetResponse
  ): void {
    this.errorOccurred = false;
    this.isLoadingData = false;
    this.isUpdatingData = false;
    this.selectAllTopActiveUsers = false;
    this.selectAllExcludedUsers = false;
    this.form.reset();
    this.editorData = new ExcludeTopActiveUserEditorData(serverResponse);
    this.updateSelectedTopActiveUserStatuses();
    this.updateExcludedUsersStatuses();
    this.checkMaxExcludedLimit();
  }

  private setupTemplateText(): void {
    const editorText =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard
        .topActiveUsers.editor;

    this.headingText = editorText.topActiveUsersSelector.title;
    this.subHeadingText = editorText.topActiveUsersSelector.description;
    this.activeUsernameColumnHeader =
      editorText.topActiveUsersSelector.columnHeader.userLabel;
    this.eventCountColumnHeader =
      editorText.topActiveUsersSelector.columnHeader.eventCountLabel;
    this.addSelectedTopActiveUserButtonText =
      editorText.topActiveUsersSelector.addButtonLabel;
    this.maxExcludeUserWarningText =
      editorText.topActiveUsersSelector.excludeUserCountLimitMessage;
    this.closeButtonLabel = editorText.closeButtonLabel;
    this.loadingLabel = editorText.loadingLabel;
    this.noUsersMessage = editorText.noUsersMessage;
    this.excludedUsersHeadingText = editorText.excludedUsersSelector.title;
    this.excludedUsernameColumnHeader =
      editorText.excludedUsersSelector.columnHeader.userLabel;
    this.excludedUserActionColumnHeader =
      editorText.excludedUsersSelector.columnHeader.actionLabel;
    this.removeExcludedUsersButtonText =
      editorText.excludedUsersSelector.removeButtonLabel;
    this.errorMessage =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard.error;
    this.tryAgainButtonLabel =
      AuditingDisplayStringsProvider.auditing.pages.auditingDashboard.tryAgain;
  }

  private checkMaxExcludedLimit(): void {
    this.maxExcludeUserWarning =
      this.excludedUserCount >= this.MaxExcludedUserCountAllowed;
  }
}
