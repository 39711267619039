import { OperatorType, QueryClause } from '@ondemand/core';
import { getCriticalLevel } from '../../../../../shared/critical-activity/critical-level-translator';
import { CriticalActivityDataSet } from '../../../../../shared/models/critical-activity.shared.models';
import { ColumnHeaders } from '../configuration/critical-activity-list.config';

export function executeStringFilter(clause: QueryClause, value: string): boolean {
  const operator = clause.operator;
  value = value.toLowerCase();
  const clauseValue = clause.value.value.toLowerCase();

  switch (operator) {
    case OperatorType.Equals: {
      return value === clauseValue;
    }
    case OperatorType.NotEquals: {
      return value !== clauseValue;
    }
    case OperatorType.Contains: {
      return value.includes(clauseValue);
    }
    case OperatorType.NotContains: {
      return !value.includes(clauseValue);
    }
    case OperatorType.StartsWith: {
      return value.startsWith(clauseValue);
    }
    case OperatorType.DoesNotStartWith: {
      return !value.startsWith(clauseValue);
    }
    case OperatorType.EndsWith: {
      return value.endsWith(clauseValue);
    }
    case OperatorType.DoesNotEndWith: {
      return !value.endsWith(clauseValue);
    }
    default: {
      return false;
    }
  }
}

export function executeNumberFilter(clause: QueryClause, value: number): boolean {
  const operator = clause.operator;
  const evaluator = Number(clause.value.value);

  switch (operator) {
    case OperatorType.Equals: {
      return evaluator === value;
    }
    case OperatorType.NotEquals: {
      return evaluator !== value;
    }
    case OperatorType.GreaterThan: {
      return evaluator < value;
    }
    case OperatorType.GreaterThanOrEqualTo: {
      return evaluator <= value;
    }
    case OperatorType.LessThan: {
      return evaluator > value;
    }
    case OperatorType.LessThanOrEqualTo: {
      return evaluator >= value;
    }
    default: {
      return false;
    }
  }
}

// evaluate each datapoint against all valid filter clauses
// to determine if this data point is a match
export function executeCriticalActivityFilter(
  data: CriticalActivityDataSet,
  clauses: QueryClause[]
): boolean {
  let filterResult = true;

  for (let queryClause of clauses) {
    switch (queryClause.field) {
      case ColumnHeaders.priorityName: {
        // priority from source data is a number 10 - 400
        // we need top first convert it to critical level for filtering
        const criticalLevel = getCriticalLevel(data.priority);
        const isPriorityMatched = executeStringFilter(
          queryClause,
          criticalLevel
        );
        filterResult = filterResult && isPriorityMatched;
        break;
      }
      case ColumnHeaders.criticalActivityName: {
        const isActivityNameMatched = executeStringFilter(
          queryClause,
          data.label
        );
        filterResult = filterResult && isActivityNameMatched;
        break;
      }
      default: {
        // filter for event count
        const eventCountString = data.data.replace(',', '');
        // remove thousand separators
        const eventCount = Number(eventCountString);
        const isEventCountMatched = executeNumberFilter(queryClause, eventCount);
        filterResult = filterResult && isEventCountMatched;
        break;
      }
    }

    if (filterResult === false) {
      // if any clauses caused aggregated filterResult to be false
      // since we only apply the "AND" logic operator among clauses
      // we can terminate the evaluation early, and retun false.
      return false;
    }
  }

  return filterResult;
}
