import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'oda-small-summary-card-information',
  templateUrl: './oda-small-summary-card-information.component.html',
  styleUrls: ['./oda-small-summary-card-information.component.scss']
})
export class OdaSmallSummaryCardInformationComponent {
  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() figureValue: string;
  @Input() figureUnit: string;
  @Input() includeChange: boolean;
  @Input() change: number;
  @Input() changeTimeFrame: string;
  @Input() negativeTrend: boolean;
  @Input() noChangeText: string;
  @Input() enablePercentage: boolean;
  @Output() errorActionClicked = new EventEmitter();
}
