import { EventField } from '../../../models/event-field.model';

/**
 * Configuration for search clause operators based on field data types
 */

export const operatorMap: any = {
  predefinedString: ['equals', 'does_not_equal' , 'in', 'not_in'],
  predefinedBoolean: ['equals_number', 'does_not_equal_number'],
  string: [
    'contains',
    'does_not_contain',
    'equals',
    'does_not_equal',
    'in',
    'not_in',
    'starts_with',
    'does_not_start_with',
    'ends_with',
    'does_not_end_with'
  ],
  datetime: ['during_last', 'between_date', 'before', 'after'],
  boolean: ['equals_number', 'does_not_equal_number']
};

// Populate lists of operators for numeric types
export const betweenNumberOperator = 'between_number';
export const numericDataTypes: string[] = ['int32', 'int64', 'double'];
const numericOperators: string[] = [
  'equals_number',
  'does_not_equal_number',
  'greater_than',
  'less_than',
  betweenNumberOperator,
  'greater_than_or_equals',
  'less_than_or_equals'
];
numericDataTypes.forEach(dataType => {
  operatorMap[dataType] = numericOperators;
});

export const getSearchOperators = (field: EventField): string[] => {
  if (field.dataType === 'string') {
    if (field.predefinedValuesOnly) {
      return operatorMap.predefinedString;
    } else {
      return operatorMap.string;
    }
  } else if (field.dataType === 'datetime') {
    return operatorMap.datetime;
  } else if (field.dataType === 'boolean') {
    if (field.predefinedValuesOnly) {
      return operatorMap.predefinedBoolean;
    } else {
      return operatorMap.boolean;
    }
  } else if (numericDataTypes.includes(field.dataType)) {
    return operatorMap[field.dataType];
  } else {
    console.error(`No search operator mapping found for data type "${field.dataType}"`);
    return [];
  }
};
