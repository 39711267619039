import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocaleStringsService } from './locale-strings.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorMessageService {

  constructor(private localeStrings: LocaleStringsService) {}

  async getErrorMessage(serviceResponse: HttpErrorResponse): Promise<string> {
    // Set default error message
    let message = await this.localeStrings.string$('auditing.queryFailedError').pipe(take(1)).toPromise();

    let errors = [];
    if (serviceResponse.status === 400) {
      let responseBody = serviceResponse.error;

      if (responseBody.error) {
        if (responseBody.error.code === 'BadArgument' && responseBody.error.details) {
          for (let error of responseBody.error.details) {
            let stringPath;
            if (error.code === 'FieldNotSearchable') {
              stringPath = 'auditing.pages.newSearches.unsearchableColumnMessage';
            } else if (error.code === 'FieldNotFound') {
              stringPath = 'auditing.pages.newSearches.unknownColumnMessage';
            } else {
              stringPath = 'auditing.pages.newSearches.invalidColumnMessage';
            }
            if (stringPath) {
              errors.push(await this.localeStrings.string$(stringPath, { field: error.target}).pipe(
                take(1)).toPromise());
            }
          }
        } else if (responseBody.error.code === 'InvalidInput') {
          for (let error of responseBody.error.details) {
            let stringPath: string;
            if (error.code === 'ContainsInvalidCharacters') {
              if (error.target === 'Name') {
                stringPath = 'auditing.pages.newSearches.nameContainsInvalidCharacters';
              } else {
                stringPath = 'auditing.pages.newSearches.invalidCharactersError';
              }
            } else if (error.code === 'NullOrEmpty') {
              stringPath = 'auditing.pages.newSearches.valueCannotBeEmpty';
            } else if (error.code === 'ValueTooLong' && error.target === 'name') {
              stringPath = 'auditing.pages.newSearches.errorNameTooLong';
            } else if (error.code === 'ValueTooLong' && error.target === 'QuickSearchText') {
              stringPath = 'auditing.pages.newSearches.quickSearchTermTooLongError';
            } else if (error.code === 'TooManyTerms') {
              stringPath = 'auditing.pages.newSearches.quickSearchTooManyTermsError';
            }

            if (stringPath) {
              errors.push(await this.localeStrings.string$(stringPath).pipe(take(1)).toPromise());
            }
          }

        }
      }
    }
    if (errors.length > 0) {
      message = errors.join(' ');
    }

    return message;
  }
}
