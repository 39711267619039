import {
  BarChartOptions,
  ChartQueryIntervals,
  DonutChartOptions,
  FilterOptions,
  TimeSeriesChartOptions,
  TopXGroupOptions
} from '../../../../auditing/models/chart-options/chart-models';
import { VisualizeParameters } from '../../../../auditing/components/queries/editor/column-editor-flyout/models/editor-parameters';
import { QueryChart } from '../../../../auditing/models/chart-options/query-chart';
import {
  IntervalCategory,
  OdaChartType,
  RenderType
} from '../models/visualization-constants';
import { EventField } from '../../../../auditing/models/event-field.model';
import { Query } from '../../../../auditing/models/query.model';
import { Util } from '@ondemand/core';

export function buildChartQuery(
  options: VisualizeParameters,
  existingId: string
): QueryChart {
  if (options) {
    const chartType = options.chartType;

    switch (chartType) {
      case OdaChartType.TimeSeries:
        return generateTimeSeriesChartQuery(options, existingId);
      case OdaChartType.Donut:
        return generateDonutChartQuery(options, existingId);
      case OdaChartType.H_BAR:
        return generateHBarChartQuery(options, existingId);
      default:
        break;
    }
  }

  return undefined;
}

export function buildVisualizeParams(
  allFields: EventField[],
  query: Query
): VisualizeParameters {
  if (
    query.q.charts === undefined ||
    query.hideCharts === null ||
    query.hideCharts === undefined ||
    query.userId === 'system'
  ) {
    // in this case, it means that the user never initialized the
    // chart parameters or it's a new search.
    // we should start with an empty parameter

    // when open from a built-in serach, we always assume no prior
    // visualization parameters
    return {};
  }

  let chartClause: QueryChart;
  if (query.q.charts && query.q.charts.length > 0) {
    chartClause = query.q.charts[0];
  }

  const renderType = findRenderType(query.hideEvents, query.hideCharts);
  const chartType = findChartType(chartClause);

  if (!renderType || !chartType) {
    return {};
  }

  switch (chartType) {
    case OdaChartType.TimeSeries:
      return generateTimeSeriesChartParams(allFields, chartClause, renderType);
    case OdaChartType.Donut:
      return generateDonutChartParams(allFields, chartClause, renderType);
    case OdaChartType.H_BAR:
      return generateHBarChartParams(allFields, chartClause, renderType);
    default:
      break;
  }

  return {}; // should returns an empty object so the UI won't break;
}

export function getIntervalCategory(
  queryInterval: ChartQueryIntervals
): IntervalCategory {
  switch (queryInterval) {
    case ChartQueryIntervals.Hour:
      return IntervalCategory.Hour;
    case ChartQueryIntervals.Day:
      return IntervalCategory.Day;
    case ChartQueryIntervals.Week:
      return IntervalCategory.Week;
    case ChartQueryIntervals.Month:
      return IntervalCategory.Month;
    default:
      return IntervalCategory.Year;
  }
}

function findChartType(chartClause: QueryChart): OdaChartType {
  if (chartClause) {
    if (chartClause.timeSeries) {
      // the timeseries should be the only valid field
      if (
        !chartClause.bar &&
        !chartClause.donut &&
        !chartClause.timeSeriesAnomalyDetection
      ) {
        return OdaChartType.TimeSeries;
      }
    } else if (chartClause.donut) {
      // the donut should be the only valid field
      if (
        !chartClause.bar &&
        !chartClause.timeSeries &&
        !chartClause.timeSeriesAnomalyDetection
      ) {
        return OdaChartType.Donut;
      }
    } else if (chartClause.bar) {
      // the donut should be the only valid field
      if (
        !chartClause.donut &&
        !chartClause.timeSeries &&
        !chartClause.timeSeriesAnomalyDetection
      ) {
        return OdaChartType.H_BAR;
      }
    }
  }
  return undefined;
}

function findRenderType(hideEvents: boolean, hideCharts: boolean): RenderType {
  if (hideEvents) {
    return hideCharts ? undefined /* cannot hide both */ : RenderType.Chart;
  } else {
    return hideCharts ? RenderType.Grid : RenderType.ChartAndGrid;
  }
}

function generateTimeSeriesChartParams(
  allFields: EventField[],
  chartClause: QueryChart,
  renderType: RenderType
): VisualizeParameters {
  const params: VisualizeParameters = {
    visualizeAs: renderType,
    chartType: OdaChartType.TimeSeries
  };

  if (
    chartClause &&
    chartClause.timeSeries &&
    chartClause.timeSeries.filterOptions &&
    chartClause.timeSeries.filterOptions.topXFilterColumn &&
    chartClause.timeSeries.seriesInterval
  ) {
    const fieldId = chartClause.timeSeries.filterOptions.topXFilterColumn;
    params.groupByField = allFields.find(field => field.id === fieldId);
    params.summarizeBy = getIntervalCategory(
      chartClause.timeSeries.seriesInterval
    );
  }

  return params;
}

function generateDonutChartParams(
  allFields: EventField[],
  chartClause: QueryChart,
  renterType: RenderType
): VisualizeParameters {
  const params: VisualizeParameters = {
    visualizeAs: renterType,
    chartType: OdaChartType.Donut
  };

  if (chartClause && chartClause.donut && chartClause.donut.topXGroupOptions) {
    const fieldId = chartClause.donut.topXGroupOptions.topXGroupName;
    params.groupByField = allFields.find(field => field.id === fieldId);
  }

  return params;
}

function generateHBarChartParams(
  allFields: EventField[],
  chartClause: QueryChart,
  renterType: RenderType
): VisualizeParameters {
  const params: VisualizeParameters = {
    visualizeAs: renterType,
    chartType: OdaChartType.H_BAR
  };

  if (chartClause && chartClause.bar && chartClause.bar.topXGroupOptions) {
    const fieldId = chartClause.bar.topXGroupOptions.topXGroupName;
    params.groupByField = allFields.find(field => field.id === fieldId);
  }

  return params;
}

function generateTimeSeriesChartQuery(
  options: VisualizeParameters,
  existingId: string
): QueryChart {
  const timeseriesQuery = new QueryChart();
  timeseriesQuery.timeSeries = {
    id: existingId ?? Util.UUID(),
    filterOptions: {
      topXFilter: 10,
      topXFilterColumn: options.groupByField.id
    } as FilterOptions,
    seriesInterval: parseSummarizeByOption(options.summarizeBy),
    timeZoneOffset: updateTimeZoneOffsetOfChart(),
    maxBins: getMaxDataPointLimit(options.summarizeBy)
  } as TimeSeriesChartOptions;
  return timeseriesQuery;
}

function generateDonutChartQuery(
  options: VisualizeParameters,
  existingId: string
): QueryChart {
  const donutChartQuery = new QueryChart();
  const dataColumn = 'EventCount';
  donutChartQuery.donut = {
    id: existingId ??  Util.GUID(),
    labelColumnName: options.groupByField.id,
    dataColumnName: dataColumn,
    showTotal: true,
    totalTextFormat: 'Events',
    chartQueryOptions: null,
    topXGroupOptions: {
      topXGroupCount: 10,
      topXGroupName: options.groupByField.id,
      addOthers: true
    } as TopXGroupOptions
  } as DonutChartOptions;

  return donutChartQuery;
}

function generateHBarChartQuery(
  options: VisualizeParameters,
  existingId: string
): QueryChart {
  const barChartQuery = new QueryChart();
  barChartQuery.bar = {
    id: existingId ?? Util.GUID(),
    chartQueryOptions: null,
    topXGroupOptions: {
      topXGroupCount: 10,
      topXGroupName: options.groupByField.id,
      addOthers: true
    } as TopXGroupOptions
  } as BarChartOptions;

  return barChartQuery;
}

export function parseSummarizeByOption(
  summarizeBy: IntervalCategory
): ChartQueryIntervals {
  switch (summarizeBy) {
    case IntervalCategory.Hour:
      return ChartQueryIntervals.Hour;
    case IntervalCategory.Day:
      return ChartQueryIntervals.Day;
    case IntervalCategory.Week:
      return ChartQueryIntervals.Week;
    case IntervalCategory.Month:
      return ChartQueryIntervals.Month;
    default:
      return ChartQueryIntervals.Year;
  }
}

export function getMaxDataPointLimit(seriesInterval: IntervalCategory): number {
  switch (seriesInterval) {
    case IntervalCategory.Hour:
      return 200;
    case IntervalCategory.Day:
      return 180;
    case IntervalCategory.Week:
      return 200;
    case IntervalCategory.Month:
      return 120;
    case IntervalCategory.Year:
      return 10;
    default:
      return 200;
  }
}

export function updateTimeZoneOffsetOfChart(): number {
  return new Date().getTimezoneOffset() * -1;
}
