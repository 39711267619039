<div class="alert-action-container">
  <ng-container *ngIf="action">
    <form #actionForm="ngForm">
      <div class="action-type-menu-container">
        <select
          materialize="material_select"
          [materializeSelectOptions]="actionTypes"
          name="action-types"
          id="action-types"
          [(ngModel)]="action.actionType"
          (change)="onActionTypeChange()"
          required
          disabled
        >
          <ng-container *ngFor="let actionType of actionTypes">
            <option [value]="actionType.id">
              {{ actionType.displayName }}
            </option>
          </ng-container>
        </select>
      </div>

      <div
        class="email-value-field-container"
        *ngIf="action.actionType === 'email'"
      >
        <multi-email-field
          name="email-value"
          [(ngModel)]="action.emailSettings.recipients"
          (change)="onEmailValueChange()"
          [canEdit]="canEdit"
        ></multi-email-field>
      </div>
    </form>
  </ng-container>
</div>
