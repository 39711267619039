<div
  *ngIf="isTenantConfigured; then tenantConfigured; else tenantNotConfigured"
></div>

<ng-template #tenantNotConfigured>
  <div *ngIf="isLoadingConsent; then loadingConsent; else consentOrError"></div>
</ng-template>

<ng-template #consentOrError>
  <div
    *ngIf="tenantHasError; then errorGettingStatus; else consentNotGranted"
  ></div>
</ng-template>

<ng-template #tenantConfigured>
  <span>
    <edc-icon
      alt="Success checkmark symbol"
      name="status-done"
      state="success-green"
    ></edc-icon>
    <span class="consent-message" id="consent-{{ questTenantId }}">{{
      'auditing.consentGranted' | localize
    }}</span>
  </span>
</ng-template>

<ng-template #loadingConsent>
  <materialize-spinner [size]="'small'"></materialize-spinner>
  <span class="consent-message">{{
    displayStringsProvider.adminConsent.checkingConsent
  }}</span>
</ng-template>

<ng-template #consentNotGranted>
  <div *ngIf="isConsentPending; then pendingConsent; else noConsent"></div>
</ng-template>

<ng-template #pendingConsent>
  <div class="consent-warning">
    <edc-icon
      alt="Information symbol"
      name="status-inprogress"
      state="info-blue"
      spin
    ></edc-icon>
    <a
      (click)="handleClickToGrantConsent()"
      id="consent-{{ questTenantId }}"
      materialize="tooltip"
      class="tooltipped waves-effect btn-flat consent-pending"
      data-delay="50"
      data-position="right"
      [attr.data-tooltip]="displayStringsProvider.adminConsent.addConsentInfo"
      >{{ 'auditing.pendingConsent' | localize }}</a
    >
  </div>
</ng-template>

<ng-template #noConsent>
  <ng-container *ngIf="isConsentRevoked">
    <div class="consent-warning">
      <edc-icon
        alt="Warning symbol"
        name="status-warning"
        state="warning-yellow"
      ></edc-icon>
      <a
        (click)="handleClickToGrantConsent()"
        id="consent-{{ questTenantId }}"
        materialize="tooltip"
        class="tooltipped waves-effect btn-flat no-consent"
        data-delay="50"
        data-position="right"
        [attr.data-tooltip]="displayStringsProvider.adminConsent.addConsentInfo"
        >{{ 'auditing.noConsentGranted' | localize }}</a
      >
    </div>
  </ng-container>

  <ng-container *ngIf="isNoConsent">
    <edc-icon
      alt="Information symbol"
      class="info-icon"
      name="status-info"
    ></edc-icon>
    <span class="consent-message" *ngIf="hasManageAzureADPermission">{{
      'auditing.selectServicesPrompt' | localize
    }}</span>
    <span class="consent-message" *ngIf="!hasManageAzureADPermission">{{
      'auditing.consentRequired' | localize
    }}</span>
  </ng-container>
</ng-template>

<ng-template #errorGettingStatus>
  <div class="consent-error">
    <edc-icon name="status-error" state="danger-red"></edc-icon>
    <span class="consent-message">{{ errorMessage }}</span>
  </div>
</ng-template>
