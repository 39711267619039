/**
 * Component for specifying a list of values
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgForm, Validator, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, forwardRef,
         ViewChild } from '@angular/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { AvailableValuesService } from '../../../../services/available-values/available-values.service';

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PredefinedValueFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PredefinedValueFieldComponent),
    multi: true
  }
  ],
  selector: 'predefined-value-field',
  templateUrl: './predefined-value-field.component.html',
  styleUrls: ['./predefined-value-field.component.scss']
})
export class PredefinedValueFieldComponent implements ControlValueAccessor, OnInit, OnChanges, Validator {
  @Input() index: number;
  @Input() field: string;
  @Input() multiple = false;
  @Input() outputMode: 'string' | 'object' = 'string';
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f') form: NgForm;
  onChange: any;
  labels = AuditingDisplayStringsProvider.auditing;
  availableValues: any[] = [];
  error: string;
  selectedOptions: any[] = [];
  selectedOption: any;
  value: any;
  valuesLoading = false;
  availableValuesLoaded = false;
  invalidSelections: string;

  constructor(private availableValuesService: AvailableValuesService) {
    return;
  }

  ngOnInit() {
    this.loadAvailableValues();
  }

  /**
   * Handle changes to inputs to this component
   *
   * @param changes Set of changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.field) {
      // reset selections
      this.selectedOption = null;
      this.selectedOptions = [];
      this.loadAvailableValues();
    }
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
      this.setSelections();
    }
  }

  loadAvailableValues() {
    if (this.valuesLoading) {
      return;
    }

    // Reset error status
    this.error = null;
    this.valuesLoading = true;

    this.availableValuesService.getAvailableValues(this.field, null, 0, 1000).subscribe((values) => {
      this.availableValues = values.filter((item: any) => item.value !== 'Enterprise Security');
      this.valuesLoading = false;
      this.availableValuesLoaded = true;
      this.setSelections();
      if (this.onChange) {
        this.onChange();
      }
    }, (_response) => {
      this.valuesLoading = false;
      this.error = this.labels.pages.newSearches.availableValuesRetrievalError;
    });
  }

  /**
   * Set selections from the available values
   */
  setSelections() {
    if (this.value === null || this.value === undefined || !this.availableValuesLoaded) {
      this.invalidSelections = null;
      return;
    }

    if (this.multiple) {
      let currentSelections: any[];
      if (this.value && typeof this.value[0] === 'object') {
        currentSelections = this.value.map((item: any) => item.value);
      } else {
        currentSelections = this.value;
      }
      let invalidValues: string[] = [];
      let newSelections: any[] = [];
      currentSelections.forEach((val) => {
        let match = this.availableValues.find((option) => option.value.toLowerCase() === val.toLowerCase());
        if (match) {
          newSelections.push(match);
        } else {
          invalidValues.push(val);
        }
      });
      this.selectedOptions = newSelections;
      this.invalidSelections = invalidValues.join(', ');

      // Force the Materialize select menu component to re-render
      // This is a workaround for the fact that it doesn't update
      // properly for "multiple" lists
      this.availableValues = [...this.availableValues];
    } else {
      this.selectedOption = this.availableValues.find((option: any) => {
        if (typeof this.value === 'string') {
          return this.value.toLowerCase() === option.value.toLowerCase();
        } else {
          return this.value === option.value;
        }
      });
    }

    // Trigger change event if this is in Object ouptut mode
    // because the containing component needs access to the full object data
    if (this.outputMode === 'object' && this.onChange) {
      this.onChange(); // updating chips on multi-value-field control.
    }

  }

  getValue() {
    if (this.outputMode === 'object') {
      return this.selectedOptions;
    } else if (this.multiple) {
      return this.selectedOptions.map((option: any) => option.value);
    } else {
      if (this.selectedOption) {
        return this.selectedOption.value;
      } else {
        return null;
      }
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      const value = this.getValue();
      fn(value);
      this.change.emit(value);
    };
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  onValueChange() {
    this.onChange();
  }

  validate(_control: UntypedFormControl): any {
    // Allow empty list in multi-selection mode
    if (this.multiple) {
      return null;
    } else {
      if (this.selectedOption === null || this.selectedOption === undefined) {
        return  {
          message: 'No value specified'
        };
      } else {
        return null;
      }
    }
  }
}
