/**
 * Component for specifying a number or number range
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgForm, Validator, UntypedFormControl } from '@angular/forms';
import { Component, Input, EventEmitter, Output, forwardRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { betweenNumberOperator } from '../search-operators';

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumberFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => NumberFieldComponent),
    multi: true
  }
  ],
  selector: 'number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss']
})
export class NumberFieldComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() index: number;
  @Input() operator: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f') form: NgForm;
  onChange: any;
  onValidatorChange: any;
  labels = AuditingDisplayStringsProvider.auditing;
  value: number;
  values: number[];
  rangeMin: number;
  rangeMax: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operator) {
      // Reset old value if operator type has changed
      if (changes.operator.currentValue === betweenNumberOperator ||
        changes.operator.previousValue === betweenNumberOperator) {
        this.values = null;
        this.value = null;
        this.rangeMin = null;
        this.rangeMax = null;
      }
    }

    if (this.onValidatorChange) {
      this.onValidatorChange();
    }
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: any): void {
    if (value !== null) {
      if (this.operator === betweenNumberOperator) {
        this.values = value;
        [this.rangeMin, this.rangeMax] = this.values;
      } else {
        this.value = value;
      }

      if (this.onChange) {
        this.onChange();
      }
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      if (this.operator === betweenNumberOperator) {
        fn(this.values);
      } else {
        fn(this.value);
      }
      this.change.emit();
    };
    this.onChange();
  }

  registerOnValidatorChange(fn: any) {
    this.onValidatorChange = () => {
      fn();
    };
    this.onValidatorChange();
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Validate whether any values have been entered
   *
   * @param _control Reference to this control
   */
  validate(_control: UntypedFormControl): any {
    if (this.operator === betweenNumberOperator) {
      if (this.values && this.values.length === 2) {
        let valid = this.values.every(val => val !== undefined && val !== null);
        if (valid) {
          return null;
        }
      }
    } else {
      if (this.value !== undefined && this.value !== null) {
        return null;
      }
    }

    return {
      errorType: 'undefined'
    };

  }

  onFieldChange() {
    if (this.operator === betweenNumberOperator) {
      this.values = [this.rangeMin, this.rangeMax];
    }
    this.onChange();
  }

}
