const CUTOFF_SUFFIX = '...';

export class FixedWidthLabelCaculator {
  private _char2Width: Map<string, number>;

  private _maxFixedLabelWidth: number;

  private _defaultCharacterWidth: number;

  constructor(private _maxCharacterCount: number = 16) {
    this._char2Width = new Map<string, number>();
    this.initialize();
  }

  public getFixedWidthLabel(label: string): string {
    if (this._getTextWidth(label) <= this._maxFixedLabelWidth) {
      return label;
    }

    for (let len = label.length - 1; len > 0; len--) {
      const shorterLabel = label.substr(0, len) + CUTOFF_SUFFIX;
      if (this._getTextWidth(shorterLabel) <= this._maxFixedLabelWidth) {
        return shorterLabel;
      }
    }

    return '';
  }

  private _getTextWidth(text: string): number {
    const characters: string[] = Array.from(text);

    return characters
      .map(ch =>
        this._char2Width.has(ch)
          ? this._char2Width.get(ch)
          : this._defaultCharacterWidth
      )
      .reduce((a, b) => a + b, 0);
  }

  private initialize(): void {
    const lowers = 'abcdefghijklmnopqrstuvwxyz';
    const uppers = lowers.toUpperCase();
    const digits = '0123456789';
    const others = ' .\\~!@#$%^&*()_+{}[],?"\'<>';
    const possibleCharacters = Array.from(lowers + uppers + digits + others);

    const canvasElement = document.createElement('canvas');
    const ctx = canvasElement.getContext('2d');
    ctx.font = 'bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif';

    possibleCharacters.forEach(ch => {
      const characterWidth = ctx.measureText(ch).width;
      this._char2Width.set(ch, characterWidth);
    });

    // use 'w' to presever enough character width when a character cannot be found
    this._defaultCharacterWidth = this._char2Width.get('w');
    this._maxFixedLabelWidth =
      this._maxCharacterCount * this._defaultCharacterWidth;

    canvasElement.remove();
  }
}
