import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ca-page-header',
  template: `
    <div class="row title-container">
      <edc-icon class="logo" name="auditing"></edc-icon>
      <h5 class="title"><ng-content></ng-content></h5>
    </div>
  `,
  styles: [`
    .title-container {
      margin: 0;
    }

    .logo {
      float: left;
      margin-right: 0.5rem
    }

    :host ::ng-deep {
      .title {
        position: relative;
      }
    }
  `]
})

export class AuditingPageHeaderComponent implements OnInit {
  constructor() {
 return;
}

  ngOnInit() {
 return;
}
}
