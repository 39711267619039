// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.

import { Route } from '@angular/router';
import { AuthGuard, FeatureFlagGuard, MaintenanceGuard } from '@ondemand/core';

import { AuditingWrapperComponent } from './components/auditing-wrapper/auditing-wrapper.component';
import { QueriesComponent } from './components/queries/queries.component';
import { ResultsComponent } from './components/queries/results/results.component';
import { EditorComponent } from './components/queries/editor/editor.component';
import { SavedQueriesComponent } from './components/queries/saved-queries/saved-queries.component';
import { InterstitialComponent } from './components/interstitial/interstitial.component';
import { RetentionSettingsComponent } from './components/retention-settings/retention-settings.component';
import { EventComponent } from './components/event/event.component';
import { AlertPlanEditorComponent } from './components/alerts/alert-plan-editor/alert-plan-editor.component';
import { AlertPlanListComponent } from './components/alerts/alert-plan-list/alert-plan-list.component';
import { AlertRuleListComponent } from './components/alerts/alert-rule-list/alert-rule-list.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ChangeAuditorSetupWizardComponent } from './components/configuration/ca-setup-wizard/ca-setup-wizard.component';
import { QuickSearchResultsComponent } from './components/queries/results/quick-search-results/quick-search-results.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { AuditingDashboardComponent } from './modules/auditing-dashboard/auditing-dashboard.component';
import { HasPermissionsGuard } from './route-guards/has-permissions.guard';
import { UnsavedChangesGuard } from './route-guards/unsaved-changes.guard';
import { UnsavedEditorChangesGuard } from './route-guards/unsaved-editor-changes.guard';
import { ExportQueryResultsGuard } from './route-guards/export-query-results.guard';
import { CriticalActivityTabComponent } from './modules/critical-activity-tab/critical-activity-tab.component';
import { ProvisioningGuard } from './route-guards/provisioning.guard';
import { ProvisioningSequenceComponent } from './components/auditing-wrapper/provisioning/provisioning-sequence.component';

/*
  =====================================================================================
  IMPORTANT: Keep help urls in auditing.help-urls.ts updated when updating these routes
  =====================================================================================
*/

export const AuditingRoutes: Route[] = [
  {
    path: 'auditing/auditing',
    component: AuditingWrapperComponent,
    canActivate: [AuthGuard],
    canActivateChild: [HasPermissionsGuard],
    children: [
      {
        path: '',
        redirectTo: 'interstitial',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: AuditingDashboardComponent,
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard]
      },
      {
        path: 'criticalActivity',
        component: CriticalActivityTabComponent,
        canActivate: [
          AuthGuard,
          MaintenanceGuard,
          ProvisioningGuard,
          FeatureFlagGuard
        ],
        data: {}
      },
      {
        path: 'criticalActivity/:label',
        component: CriticalActivityTabComponent,
        canActivate: [
          AuthGuard,
          MaintenanceGuard,
          ProvisioningGuard,
          FeatureFlagGuard
        ],
        canDeactivate: [ExportQueryResultsGuard],
        data: {}
      },
      {
        path: 'queries',
        component: QueriesComponent,
        children: [
          { path: '', redirectTo: 'saved', pathMatch: 'full' },
          {
            path: 'results/:queryId',
            component: ResultsComponent,
            canDeactivate: [UnsavedEditorChangesGuard, ExportQueryResultsGuard]
          },
          {
            path: 'results',
            component: ResultsComponent,
            canDeactivate: [UnsavedEditorChangesGuard]
          },
          {
            path: 'editor/:queryId',
            component: EditorComponent,
            canDeactivate: [UnsavedEditorChangesGuard]
          },
          {
            path: 'editor',
            component: EditorComponent,
            canDeactivate: [UnsavedEditorChangesGuard]
          },
          { path: 'saved', component: SavedQueriesComponent },
          {
            path: 'quickresults',
            component: QuickSearchResultsComponent
          },
          {
            path: 'quickresults/:q',
            component: QuickSearchResultsComponent,
            canDeactivate: [ExportQueryResultsGuard]
          }
        ],
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard]
      },
      {
        path: 'config/:tenantId',
        component: ConfigurationComponent,
        canActivate: [AuthGuard, MaintenanceGuard]
      },
      {
        path: 'config',
        component: ConfigurationComponent,
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard]
      },
      {
        path: 'ca-setup',
        component: ChangeAuditorSetupWizardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'provision',
        component: ProvisioningSequenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'alerts',
        component: AlertsComponent,
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: AlertRuleListComponent
          },
          {
            path: 'plans',
            component: AlertPlanListComponent,
            canActivate: [HasPermissionsGuard]
          },
          {
            path: 'plans/:planId',
            component: AlertPlanEditorComponent,
            canDeactivate: [UnsavedChangesGuard]
          }
        ]
      },
      {
        path: 'events/:eventId',
        component: EventComponent,
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard]
      },
      {
        path: 'retention',
        component: RetentionSettingsComponent,
        canActivate: [AuthGuard, MaintenanceGuard, ProvisioningGuard]
      },
      {
        path: 'interstitial',
        component: InterstitialComponent
      }
    ]
  }
];
