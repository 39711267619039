// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import moment from 'moment';

import { AuditingBreadcrumbsService } from '../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../services/locale-strings.service';
import {
  StaticIndicatorConfiguration,
  StaticIndicatorSetting,
  WidgetID,
  WidgetInformation
} from './models/widget-info';
import { Store } from '@ngrx/store';
import { WidgetListState } from './widgets/state/widget-list.reducer';
import { LoadWidgetList } from './widgets/state/widget-list.actions';
import {
  selectWidgetList,
  selectWidgetListError
} from './widgets/state/widget-list.selector';

@Component({
  selector: 'change-auditor-dashboard',
  templateUrl: './auditing-dashboard.component.html',
  styleUrls: ['./auditing-dashboard.component.scss']
})
export class AuditingDashboardComponent
  extends BaseComponent
  implements OnInit
// eslint-disable-next-line brace-style
{
  staticIndicatorsSettings: StaticIndicatorSetting[];
  enableMyFavoriteSearch: boolean;
  enableCriticalActivity: boolean;
  enableSignins: boolean;
  enableAuditHealth: boolean;
  enableTopActiveUsers: boolean;

  refreshText: string;
  refresh = new EventEmitter();
  refreshObservable$ = this.refresh.asObservable();

  widgetList$: Observable<WidgetInformation[]> =
    this.store.select(selectWidgetList);
  loadWidgetListError$: Observable<boolean> = this.store.select(
    selectWidgetListError
  );

  constructor(
    private store: Store<WidgetListState>,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private localeStringsService: LocaleStringsService
  ) {
    super();
  }

  ngOnInit() {
    this.refreshText = `Last updated: ${moment().format(
      'MMMM Do YYYY [at] h:mm:ss a'
    )}`;
    this.localeStringsService.strings$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.breadcrumbsService.set([
          {
            title: labels.auditing.pages.auditingDashboard.title,
            url: 'auditing/auditing/dashboard'
          }
        ]);
      });

    this.widgetList$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(widgetList => !!widgetList)
      )
      .subscribe(widgetList => {
        const nonStaticWidgets = widgetList.filter(
          widget => !StaticIndicatorConfiguration.has(widget.widgetId)
        );
        const staticWidgets = widgetList.filter(widget =>
          StaticIndicatorConfiguration.has(widget.widgetId)
        );
        this.staticIndicatorsSettings = staticWidgets.map(widget =>
          StaticIndicatorConfiguration.get(widget.widgetId)
        );

        // only 4 static indicators are going to be displayed
        this.staticIndicatorsSettings = this.staticIndicatorsSettings.slice(
          0,
          4
        );

        // other non-static widgets
        this.enableAuditHealth = !!nonStaticWidgets.find(
          widget => widget.widgetId === WidgetID.AuditHealth
        );
        this.enableCriticalActivity = !!nonStaticWidgets.find(
          widget => widget.widgetId === WidgetID.CriticalActivities
        );
        this.enableMyFavoriteSearch = !!nonStaticWidgets.find(
          widget => widget.widgetId === WidgetID.MyFavoriteSearches
        );
        this.enableSignins = !!nonStaticWidgets.find(
          widget => widget.widgetId === WidgetID.Signins
        );
        this.enableTopActiveUsers = !!nonStaticWidgets.find(
          widget => widget.widgetId === WidgetID.TopActiveUsers
        );
      });

    this.loadWidgetList();
  }

  triggerRefresh() {
    this.refreshText = `Last updated: ${moment().format(
      'MMMM Do YYYY [at] h:mm:ss a'
    )}`;
    this.refresh.emit();
  }

  loadWidgetList() {
    this.store.dispatch(new LoadWidgetList());
  }
}
