/**
 * Component for specifying dates or a ranges of dates, with times
 */
import { takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgForm, Validator } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, forwardRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { getCurrentISOTimezoneOffset } from '../../../../util/date-utils';
import moment from 'moment';

const momentTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const BETWEEN_OPERATOR = 'between_date';
// eslint-disable-next-line
const ISO_8601_FORMAT = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateTimeFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateTimeFieldComponent),
    multi: true
  }
  ],
  selector: 'datetime-field',
  templateUrl: './datetime-field.component.html',
  styleUrls: ['./datetime-field.component.scss']
})
export class DateTimeFieldComponent extends BaseComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() index: number;
  @Input() operator: string;
  @Output() change: EventEmitter<any>;
  @ViewChild('dateForm') dateForm: NgForm;
  onChange: any;
  outputString: string = null;
  formValues: any = {
    firstDate: {},
    secondDate: {}
  };
  datepickerOptions: any;
  timepickerOptions = {};
  valid = true;

  constructor(private localeStringsService: LocaleStringsService) {
    super();
    this.change = new EventEmitter<any>();
    this.localeStringsService.strings$.pipe(takeUntil(this.destructionSubject)).subscribe(() => {
      this.datepickerOptions = {
        format: 'yyyy-mm-dd',
        selectYears: 10,
        today: this.localeStringsService.get('auditing.datepicker.today'),
        clear: this.localeStringsService.get('auditing.datepicker.clear'),
        close: this.localeStringsService.get('auditing.datepicker.close'),
        onClose: () => {
          this.onDateChange();
        }
      };
    });
  }

  ngOnInit() {
    setTimeout(() => {
      // Wait a moment for the parent form to set the value before attempting to
      // set a default date
      if (this.outputString === null) {
        this.setDefaultDates();
      }
    }, 100);

  }

  /**
   * Handle changes to inputs to this component
   *
   * @param changes Set of changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.operator) {
      // If changing from "between" operator, reset form data since the second date is
      // no longer relevant
      if (changes.operator.previousValue === BETWEEN_OPERATOR) {
        this.formValues.secondDate = {};
        this.onDateChange();
      } else if (changes.operator.currentValue === BETWEEN_OPERATOR) {
        this.setDefaultSecondDate();
        this.onDateChange();
      }
    }
  }

  setDefaultDates() {
    this.setDefaultFirstDate();
    if (this.operator === BETWEEN_OPERATOR) {
      this.setDefaultSecondDate();
    }
    this.onDateChange();
  }

  setDefaultFirstDate() {
    this.formValues.firstDate = this.getDateString();
  }

  setDefaultSecondDate() {
    let dayOffset = 1;
    this.formValues.secondDate = this.getDateString(dayOffset);
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: any): void {
    if (value !== null) {
      if (this.isValidDateString(value)) {
        // Pass through moment to get format with timezone offset included

        // TODO: Verify this is safe to do
        this.outputString = value;

        // Parse date values from string to set up form
        if (this.operator === BETWEEN_OPERATOR) {
          let [firstDateString, secondDateString] = value.split('_');
          let firstDateWithTimezoneOffset = moment(firstDateString).format(momentTimeFormat);
          this.formValues.firstDate = this.getDateObjectFromString(firstDateWithTimezoneOffset);
          let secondDateWithTimezoneOffset = moment(secondDateString).format(momentTimeFormat);
          this.formValues.secondDate = this.getDateObjectFromString(secondDateWithTimezoneOffset);
        } else {
          let firstDateWithTimezoneOffset = moment(value).format(momentTimeFormat);
          this.formValues.firstDate = this.getDateObjectFromString(firstDateWithTimezoneOffset);
        }
      } else {
        this.setDefaultDates();
      }
      if (this.onChange) {
        this.onChange();
      }
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.outputString);
      this.change.emit();
    };
    this.onChange();
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Update date object when a date input is modified
   */
  onDateChange() {
    // Skip setting the date if there is no date set for one of the required fields
    if (!this.formValues.firstDate.date ||
      (this.operator === BETWEEN_OPERATOR && !this.formValues.secondDate.date)) {
      return;
    }
    let firstDate = this.getISOStringWithCurrentTimezoneOffset(this.formValues.firstDate);
    if (this.operator === BETWEEN_OPERATOR) {
      let secondDate = this.getISOStringWithCurrentTimezoneOffset(this.formValues.secondDate);
      this.outputString = `${firstDate}_${secondDate}`;
    } else {
      this.outputString = firstDate;
    }

    if (this.onChange) {
      this.onChange();
    }
  }

  /**
   * Validate proper dates have been specified
   *
   * @param _control Reference to this control
   */
  validate(_control: UntypedFormControl): any {
    let valid = this.isValidDateString(this.outputString);
    let error = {
      invalidValue: this.outputString
    };
    this.valid = valid;
    return valid ? null : error;
  }

  /**
   * Check if date string is valid for this field
   *
   * @param value Date string, which can be a single date or a range
   */
  private isValidDateString(value: string) {
    let valid = false;
    if (value) {
      valid = value.split('_').every((piece) => ISO_8601_FORMAT.test(piece));
    }
    return valid;
  }

  /**
   * Get date object needed for form inputs from date string
   *
   * @param dateString ISO 8601-formatted date string
   */
  private getDateObjectFromString(dateString: string) {
    return {
      date: dateString.substring(0, 10),
      hour: dateString.substring(11, 13),
      minute: dateString.substring(14, 16)
    };
  }

  /**
   * Get a default date for use in the form when no date is set yet.
   * This defaults to the current date.
   *
   * @param dayOffset Number of days to go forward or backward from today's date
   */
  private getDateString(dayOffset: number = 0) {
    let date = new Date();
    date.setDate(date.getDate() + dayOffset);
    return {
      date: date.toISOString().substring(0, 10)
    };
  }

  /**
   * Get ISO-formatted date string
   *
   * @param date Object with `date`, `hour`, and `minute` as properties
   * @param useCurrentTimezone apply current timezone offset to date
   */
  private getISOStringWithCurrentTimezoneOffset(date: any) {
    let hour = (date.hour || '00').padStart(2, '0');
    let minute = (date.minute || '00').padStart(2, '0');
    let timeZoneOffset = getCurrentISOTimezoneOffset();

    return `${date.date}T${hour}:${minute}:00${timeZoneOffset}`;
  }

}
