// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { Results } from './results.model';
import {
  IModalWindow,
  EDialogType,
  BaseComponent,
  DisplayStringsProvider
} from '@ondemand/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, ReplaySubject } from 'rxjs';

import { QueryService } from '../../../services/query/query.service';
import { SavedQueriesService } from '../../../services/saved-queries.service';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { ActiveQueryService } from '../../../services/active-query.service';
import { Query } from '../../../models/query.model';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { substituteTemplateValues } from '../../../util/template-substituter';
import { ResultsViewerComponent } from './results-viewer/results-viewer.component';
import { maxTitleLength } from '../breadcrumb-config';
import { EventFieldsService } from '../../../services/event-fields.service';
import { EventField } from '../../../models/event-field.model';
import { ColumnEditorFlyoutComponent } from '../editor/column-editor-flyout/column-editor-flyout.component';
import { DropdownButtonMenuOption } from '../../dropdown-button/dropdown-button.component';
import { SaveAsModalComponent } from '../editor/save-as-modal/save-as-modal.component';
import { SaveModalComponent } from '../editor/save-modal/save-modal.component';
import { SavedQueryCategory } from '../saved-queries/saved-query-category.model';
import { FilterEvent } from './event-details.table.model';
import { QueryClause } from '../../../models/query-clause';
import { numericDataTypes } from '../editor/search-operators';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { ErrorMessageService } from '../../../services/error-message.service';
import { visualizationRowLimit } from '../../../util/constants';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { checkUserAlertPermissions } from '../alert-rule-permissions';
import {
  getLastSavedLabelText,
  getLastSavedToolTipInfo
} from '../../../util/last-saved-info';
import {
  OdaChartType,
  RenderType
} from '../../../../shared/components/chart/models/visualization-constants';
import {
  getTimeseriesChartId,
  getDonutChartId,
  isChartQuery,
  getBarChartId
} from '../../../../shared/components/chart/utils/chart-utils';
import {
  ColumnEditorOptions,
  VisualizeParameters
} from '../editor/column-editor-flyout/models/editor-parameters';
import * as queryConverter from '../../../../shared/components/chart/services/chart-query-converter';
import { cloneAndUnlockObject } from '../../../../shared/utils/object.tools';
import { getIntervalCategory } from '../../../../shared/components/chart/services/chart-query-converter';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';

@Component({
  selector: 'ca-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('saveAsModal') saveAsModal: SaveAsModalComponent;
  @ViewChild('saveModal') saveModal: SaveModalComponent;
  @ViewChild('container') container: ElementRef;

  loadingResults = true;
  loadingQuery = true;
  loadingQueryError = false;
  loadQueryFailedErrorMessage: string;
  queryFailedErrorMessage: string;
  results: Results;
  visualizationLimitModalParams: IModalWindow = { showModal: false };
  resultsPerPage = 100;
  currentPage = 0;
  currentQuery: Query;
  adHocMode = false;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  coreStrings = DisplayStringsProvider;
  rowLimitDescription: string;
  isExporting = false;
  hideCharts: boolean;
  hideEvents: boolean;
  enableHBarChart: boolean;
  enableDonutChart: boolean;
  enableTimeSeriesChart: boolean;
  protected eventFields: EventField[];
  lastSavedToolTipInfoSub: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastSavedToolTipRefreshProp: string[] = [];
  lastSavedLabelInfoSub: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastSavedLabelInfo: string;
  lastSavedToolTipInfo: string;
  visualizationLoaded = true;

  // Save-related properties
  dirty = false;
  saving = false;
  saveError: string;
  saveAsFormData: any = {
    name: null,
    categoryId: null,
    isShared: null
  };

  saveFormData: any = {
    name: null,
    categoryId: null,
    isShared: null
  };

  primarySaveButtonOptions: DropdownButtonMenuOption = {
    label: this.applicationStrings.saveButtonLabel,
    action: 'save',
    icon: 'save',
    disabled: false,
    id: 'save-query-button'
  };
  saveMenuOptions: DropdownButtonMenuOption[] = [
    {
      label: this.applicationStrings.saveAsButtonLabel,
      action: 'saveAs'
    }
  ];
  categories: SavedQueryCategory[] = [];

  visualizeParams: VisualizeParameters;

  fromPermissions = fromPermissions;

  protected ngUnsubscribe: Subject<any> = new Subject<any>();

  @ViewChild(ResultsViewerComponent) resultsViewer: ResultsViewerComponent;
  @ViewChild(ColumnEditorFlyoutComponent)
  columnEditor: ColumnEditorFlyoutComponent;

  private _userSearchPermissions = false;
  private _userSearchSaveAsPermission = false;
  private _userSearchVisualizationPermission = false;
  private _userChartDrilldownPermission = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected queryService: QueryService,
    private savedQueryService: SavedQueriesService,
    protected activeQueryService: ActiveQueryService,
    private router: Router,
    private breadcrumbsService: AuditingBreadcrumbsService,
    protected eventFieldsService: EventFieldsService,
    private savedQueriesService: SavedQueriesService,
    private location: Location,
    private localeStrings: LocaleStringsService,
    private errorService: ErrorMessageService,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  get userHasManageSearchPermissions() {
    return this._userSearchPermissions;
  }

  get userHasSearchSaveAsPermission() {
    return this._userSearchSaveAsPermission;
  }

  get userSearchVisualizationPermission() {
    return this._userSearchVisualizationPermission;
  }

  get userChartDrilldownPermission() {
    return this._userChartDrilldownPermission;
  }

  ngAfterViewInit(): void {
    this.container.nativeElement.focus();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit() {
    this.visualizeParams = {};
    this.eventFields = await this.eventFieldsService
      .getFieldsWithHttpInfo()
      .pipe(
        take(1),
        map(response => {
          const dataFields = response.body.availableFields as object[];

          return dataFields.map(data => new EventField(data));
        })
      )
      .toPromise();

    // initialize default charts/events condition
    this.hideCharts = true;
    this.hideEvents = false;
    // Reset breadcrumbs while the query metadata is loading
    this.updateBreadcrumbs();

    this.activatedRoute.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        if (params.queryId) {
          this.adHocMode = false;
          this.savedQueryService
            .getSavedQuery(params.queryId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              query => {
                this.currentQuery = query;
                this.updateBreadcrumbs();
                this.loadingQuery = false;
                this.updateVisualizeParams();
                this.renderResultsAndChart(
                  this.currentQuery.q.columns,
                  this.visualizeParams,
                  cloneAndUnlockObject<Query>(query)
                );
                this.setPermissions();
                this.updateLastSavedInfo();
              },
              error => {
                if (error.status === 403 || error.status === 401) {
                  this.loadQueryFailedErrorMessage =
                    this.applicationStrings.queryAccessDenied;
                } else {
                  this.loadQueryFailedErrorMessage =
                    this.applicationStrings.queryLoadError;
                }
                this.loadingQuery = false;
                this.loadingQueryError = true;
              }
            );
        } else {
          this.adHocMode = true;
          // If query Id not provided through URL path, get it from the active query service.
          // This is used for passing an ad hoc query from the query builder
          const activeQuery = this.activeQueryService.getQuery().activeQuery;
          const origQuery = !this.activeQueryService.getQuery().originalQuery
            ? undefined
            : cloneAndUnlockObject<Query>(
                this.activeQueryService.getQuery().originalQuery
              );

          if (activeQuery) {
            this.currentQuery = activeQuery;
            this.updateBreadcrumbs();
            this.updateVisualizeParams();
            this.renderResultsAndChart(
              this.currentQuery.q.columns,
              this.visualizeParams,
              origQuery
            );
            this.setPermissions();

          } else {
            this.loadQueryFailedErrorMessage =
              this.applicationStrings.queryLoadError;
            this.loadingQueryError = true;
          }
          this.loadingQuery = false;
        }
      });

    this.savedQueriesService.getCategories().subscribe(
      categories => {
        this.categories = categories;
      },
      () => {
        console.error('Failed to load categories');
      }
    );

    this.lastSavedLabelInfoSub.subscribe(value => {
      this.lastSavedLabelInfo = value;
    });

    this.lastSavedToolTipInfoSub.subscribe(value => {
      this.lastSavedToolTipInfo = value;
    });
  }

  updateBreadcrumbs() {
    let title;
    if (this.currentQuery) {
      if (this.adHocMode) {
        title = `*${this.currentQuery.name}`;
      } else {
        title = this.currentQuery.name;
      }
    } else {
      title = this.applicationStrings.querySingular;
    }

    const relativePaths = false;
    this.breadcrumbsService.set(
      [
        {
          title: this.applicationStrings.queries,
          url: 'auditing/auditing/queries'
        },
        {
          title,
          url: ''
        }
      ],
      relativePaths,
      maxTitleLength
    );
  }

  onPageChange(pageNum: number): Promise<any> {
    this.currentPage = pageNum;
    // go to diffferent page, no need to update the chart
    return this.getResults();
  }

  /**
   * Navigate to a specified page and open a particular event in the viewer
   *
   * @param eventData Object representing pagination/event number
   */
  onSeek(eventData: any) {
    this.onPageChange(eventData.pageNum).then(
      () => {
        this.resultsViewer.selectRowAtIndex(eventData.eventIndex);
      },
      () => {
        this.resultsViewer.closeDetails();
      }
    );
  }

  /**
   * Run query and load results to component
   *
   */
  getResults(): Promise<any> {
    this.saveError = null;
    this.loadingResults = true;
    if (!this.currentQuery) {
      return Promise.reject('Current query not loaded yet');
    }

    if (!this.adHocMode) {
      this.activeQueryService.setQuery(
        this.currentQuery.deepClone(),
        this.currentQuery
      );
    }

    let promise: Promise<any> = new Promise<any>((resolve, reject) => {
      this.loadingResults = true;
      this.queryFailedErrorMessage = null;
      let skip = this.resultsPerPage * this.currentPage;
      let params: any = {
        skip,
        limit: this.resultsPerPage
      };

      if (!this.adHocMode && !this.currentQuery.id) {
        this.loadingResults = false;
        this.queryFailedErrorMessage =
          this.applicationStrings.noQueryFoundError;
        return reject();
      }

      this.queryService
        .runQueryWithHttpInfo(this.currentQuery, params, this.adHocMode)
        .subscribe(
          response => {
            this.results = new Results(response.body);
            this.loadingResults = false;
            resolve(this.results);
          },
          async response => {
            // Set default error message
            this.queryFailedErrorMessage =
              await this.errorService.getErrorMessage(response);
            this.loadingResults = false;
            this.results = null;
          }
        );
    });

    return promise;
  }

  /**
   * Reload query results from server and refresh UI
   *
   */
  refresh() {
    if (!this.currentQuery.hideEvents) {
      this.resultsViewer.closeDetails();
    }
    this.renderResultsAndChart(
      this.currentQuery.q.columns,
      this.visualizeParams,
      this.activeQueryService.originalQuery
    );
  }

  /**
   * Navigate to query editor when user clicks edit button
   */
  editQuery() {
    let editorUrl = '/auditing/auditing/queries/editor';
    if (this.currentQuery.id && !this.adHocMode) {
      editorUrl += `/${this.currentQuery.id}`;
    }
    this.router.navigate([editorUrl]);
  }

  /**
   * Route user to visualization view when clicking Visualize button
   */
  onVisualizeClick() {
    if (this.results.totalRows > visualizationRowLimit) {
      this.showVisualizeLimitWarning();
    } else {
      this.goToVisualization();
    }
  }

  canClickVisualize(): boolean {
    const totalRowsIsDefined =
      this.results !== undefined &&
      this.results !== null &&
      this.results.totalRows !== undefined &&
      this.results.totalRows !== null;
    return !this.adHocMode && this.currentQuery && totalRowsIsDefined;
  }

  /**
   * Display modal dialog warning users that they have hit the limit on the number
   * of events that can be effectively visualized
   */
  showVisualizeLimitWarning() {
    let replacements: any = {
      limit: visualizationRowLimit.toLocaleString(),
      totalRows: this.results.totalRows.toLocaleString()
    };
    this.rowLimitDescription = substituteTemplateValues(
      this.applicationStrings.visualizationRowLimitDescription,
      replacements
    );
    this.visualizationLimitModalParams = {
      showModal: true,

      dialogParams: {
        title: this.applicationStrings.visualizationRowLimitTitle,
        type: EDialogType.WARNING,
        actions: [
          {
            name: this.applicationStrings.yes,
            action: () => {
              this.goToVisualization();
            }
          }
        ],
        cancelText: this.applicationStrings.no,
        cancelButtonAction: () => {
          this.visualizationLimitModalParams.showModal = false;
        }
      }
    };
  }

  /**
   * Navigate to visualization for this search
   */
  goToVisualization() {
    this.activatedRoute.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        if (params.queryId) {
          this.router.navigate([
            '/auditing/auditing/queries/visual',
            params.queryId
          ]);
        }
      });
  }

  onColumnChange(options: ColumnEditorOptions) {
    this.visualizeParams = options.visualizeParameters;
    this.currentQuery.q.columns = options.newColumns;

    if (this.currentQuery.userId === 'system') {
      this.currentQuery.userId = undefined;
      this.currentQuery.id = undefined;
      // when the user start editing built-in search,
      // we want to force it to be a "NEW" user query.

      // for system built-in queries, some have  donut chart queries predfined
      // we need to wipe it out, after editing, if visualize parameters is {}
      if (Object.keys(this.visualizeParams).length === 0) {
        // visualize parameters is {}, the user didn't add visualize parameter
        this.currentQuery.q.charts = [];
      }
    }

    this.adHocMode = true;
    this.renderResultsAndChart(
      options.newColumns,
      options.visualizeParameters,
      this.activeQueryService.getQuery().originalQuery
    );

    // we have received the changes, close the flyout
    this.columnEditor.closeEditColumnsFlyout();
  }

  onChartCategoryClicked(updatedQuery: Query) {
    this.adHocMode = true; // curent query becomes adhoc.
    this.currentQuery = updatedQuery;
    this.activeQueryService.setQuery(
      this.activeQueryService.originalQuery,
      this.currentQuery
    );
    this.updateBreadcrumbs();
    this.getResults();
  }

  onChartElementClicked(updatedQuery: Query) {
    this.adHocMode = true;

    this.visualizeParams.summarizeBy = getIntervalCategory(
      updatedQuery.q.charts[0].timeSeries.seriesInterval
    );
    this.renderResultsAndChart(updatedQuery.q.columns, this.visualizeParams, updatedQuery);
  }

  onVisualizationLoaded(loaded: boolean) {
    this.visualizationLoaded = loaded;
  }

  openEditColumnsFlyout() {
    if (
      !this.isExporting &&
      !this.loadingQuery &&
      !this.loadingQueryError &&
      this.currentQuery &&
      this.currentQuery.q &&
      this.currentQuery.q.columns &&
      this.currentQuery.q.columns.length > 0 &&
      this.eventFields &&
      this.eventFields.length > 0
    ) {
      this.updateVisualizeParams();
      this.columnEditor.openEditColumnsFlyout();
    }
  }

  saveQuery() {
    this.saving = true;
    this.saveError = null;
    if (!this.currentQuery.id) {
      this.savedQueriesService.createSavedQuery(this.currentQuery).subscribe(
        query => this.handleSavedQuerySuccess(query),
        error => this.handleSaveError(error)
      );
    } else {
      this.savedQueriesService.updateSavedQuery(this.currentQuery).subscribe(
        query => this.handleSavedQuerySuccess(query),
        error => this.handleSaveError(error)
      );
    }
  }

  handleSavedQuerySuccess(query: Query) {
    this.currentQuery = query;
    this.activeQueryService.setQuery(query.deepClone(), query);
    toastLowerLeft(
      this.toastService,
      this.applicationStrings.pages.newSearches.saveAsSuccessfulToast,
      ToastType.Success
    );
    this.saving = false;
    this.adHocMode = false;
    this.dirty = false;
    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if (!params.queryId || params.queryId !== query.id) {
        this.location.go(
          `/auditing/auditing/queries/results/${this.currentQuery.id}`
        );
      }
    });
    this.updateBreadcrumbs();
    this.updateLastSavedInfo();
  }

  handleSaveError(response: HttpErrorResponse) {
    this.saving = false;
    if (response.status === 409) {
      this.saveError =
        this.applicationStrings.pages.newSearches.errorDuplicateName;
    } else {
      this.saveError = this.applicationStrings.saveQueryError;
    }
  }

  /**
   * Handle export started event
   *
   * @param event status from "exportStarted" event from create-export component
   */
  onExportStarted(exportStarted: boolean) {
    this.isExporting = exportStarted;
  }

  handleAction(action: string) {
    if (action === 'save') {
      this.saveQuery();
    } else if (action === 'saveAs') {
      this.saveAs();
    }
  }

  saveAs() {
    this.saveAsFormData.name = this.currentQuery.name;
    this.saveAsFormData.categoryId = this.currentQuery.categoryId;
    this.saveAsFormData.isShared = this.currentQuery.isShared;
    this.saveAsModal.openModal();
  }

  handleSaveAs(savedQuery: Query) {
    this.handleSavedQuerySuccess(savedQuery);
    this.updateBreadcrumbs();
    this.setPermissions();
  }

  showSavePrompt() {
    this.saveFormData.name = this.currentQuery.name;
    this.saveFormData.categoryId = this.currentQuery.categoryId;
    this.saveFormData.isShared = this.currentQuery.isShared;
    this.saveModal.openModal();
  }

  /**
   * Handle reorder of a datatable column
   *
   * @param event Data from "reorder" event from ngx-datatable
   */
  onReorder(event: any) {
    let oldIndex = event.prevValue;
    let newIndex = event.newValue;
    let temp = this.currentQuery.q.columns[newIndex];
    this.currentQuery.q.columns[newIndex] =
      this.currentQuery.q.columns[oldIndex];
    this.currentQuery.q.columns[oldIndex] = temp;
  }

  /**
   * Update query params for sorting using event data from
   * ngx-datatable instance in results viewer
   *
   * @param event Sort event data from ngx-datatable instance
   */
  onSort(event: any) {
    this.currentQuery.q.sortDir = event.newValue;
    this.currentQuery.q.sortBy = event.column.prop;
    this.adHocMode = true;
    this.updateBreadcrumbs();
    // changing the sorting order, no need to re-render the chart
    this.getResults();
  }

  async onFilter(event: FilterEvent) {
    let newClause = await this.getNewClause(event.field, event.value);
    if (event.type === 'add') {
      // Go to editor with this added to the search
      this.addClauseToQuery(newClause);
    }
  }

  userCanManageThisSearchAlert(): boolean {
    return checkUserAlertPermissions(
      this.currentQuery,
      this.permissionsService
    );
  }

  shouldShowLastSavedInfo(): boolean {
    return (
      this.currentQuery &&
      this.currentQuery.id &&
      this.currentQuery.isShared &&
      !this.currentQuery.isSystemDefined &&
      !this.adHocMode
    );
  }

  private renderResultsAndChart(
    updatedColumns: string[],
    visualParams: VisualizeParameters,
    origQuery: Query
  ): Promise<any> {
    // Taken from sign-ins.component.ts:
    // this will let ngIf to destroy the chart
    // allow some time for angular to do the change detection
    // that will remove the old chart with previous data
    // and then re-render a new chart
    this.enableTimeSeriesChart = false;
    this.enableDonutChart = false;
    this.enableHBarChart = false;
    this.updateRenderingOptions(updatedColumns, visualParams, origQuery);
    return this.getResults();
  }

  private updateRenderingOptions(
    updatedColumns: string[],
    visualParams: VisualizeParameters,
    origQuery: Query
  ): void {
    setTimeout(() => {
      this.currentQuery.q.columns = updatedColumns;
      this.updateBreadcrumbs();

      if (!visualParams) {
        visualParams = {};
      }

      if (visualParams.visualizeAs) {
        if (visualParams.visualizeAs === RenderType.Chart) {
          this.currentQuery.hideCharts = false;
          this.currentQuery.hideEvents = true;
          this.hideCharts = false;
          this.hideEvents = true;
        } else if (visualParams.visualizeAs === RenderType.ChartAndGrid) {
          this.currentQuery.hideCharts = false;
          this.currentQuery.hideEvents = false;
          this.hideCharts = false;
          this.hideEvents = false;
        } else if (visualParams.visualizeAs === RenderType.Grid) {
          this.currentQuery.hideCharts = true;
          this.currentQuery.hideEvents = false;
          this.hideCharts = true;
          this.hideEvents = false;
        }
      }

      let existingChartId: string;
      switch (visualParams.chartType) {
        case OdaChartType.H_BAR:
          existingChartId = getBarChartId(origQuery);
          break;
        case OdaChartType.TimeSeries:
          existingChartId = getTimeseriesChartId(origQuery);
          break;
        case OdaChartType.Donut:
          existingChartId = getDonutChartId(origQuery);
          break;
        default:
          // don't do anything for now
          return;
      }

      const chartOption = queryConverter.buildChartQuery(
        visualParams,
        existingChartId
      );
      if (chartOption) {
        this.currentQuery.q.charts = [chartOption];
        this.activeQueryService.setQuery(origQuery, this.currentQuery);

        // enable corresponding chart only at the last step
        this.enableHBarChart = visualParams.chartType === OdaChartType.H_BAR;
        this.enableDonutChart = visualParams.chartType === OdaChartType.Donut;
        this.enableTimeSeriesChart =
          visualParams.chartType === OdaChartType.TimeSeries;
      }
    }, 200);
  }

  private updateVisualizeParams() {
    if (isChartQuery(this.currentQuery)) {
      this.visualizeParams = queryConverter.buildVisualizeParams(
        this.eventFields,
        this.currentQuery
      );
    }

    if (!this.visualizeParams) {
      this.visualizeParams = {};
    }
  }

  private async updateLastSavedInfo() {
    const lastSavedLabel = await this.localeStrings
      .string$('auditing.lastSavedLabel')
      .pipe(take(1))
      .toPromise();
    const lastSavedTooltip = await this.localeStrings
      .string$('auditing.lastSavedTooltip')
      .pipe(take(1))
      .toPromise();

    setTimeout(() => {
      this.lastSavedToolTipRefreshProp = [];
    }, 200);

    this.lastSavedToolTipInfoSub.next(
      getLastSavedToolTipInfo(
        lastSavedTooltip,
        this.currentQuery.createdDate,
        this.currentQuery.userId,
        this.currentQuery.lastUpdated,
        this.currentQuery.lastUpdatedBy
      )
    );

    this.lastSavedLabelInfoSub.next(
      getLastSavedLabelText(
        lastSavedLabel,
        this.currentQuery.lastUpdatedBy,
        this.currentQuery.lastUpdated
      )
    );
  }

  protected setPermissions(): void {
    if (this.currentQuery !== null && this.currentQuery.isShared) {
      this._userSearchPermissions = this.permissionsService.hasAnyOfPermissions(
        [fromPermissions.canManageSharedSearches]
      );

    } else {
      this._userSearchPermissions = this.permissionsService.hasAnyOfPermissions(
        [fromPermissions.canManagePrivateSearch]
      );
    }
    this._userSearchSaveAsPermission =
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManagePrivateSearch,
        fromPermissions.canManageSharedSearches
      ]);
    this._userSearchVisualizationPermission =
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canRunSearchVisual
      ]);

    if (this.adHocMode || (this.currentQuery !== null && !this.currentQuery.isShared)) {
      this._userChartDrilldownPermission =
        this.permissionsService.hasAnyOfPermissions([
          fromPermissions.canRunPrivateSearch
        ]);
    }
  }

  private async getNewClause(field: string, value: any) {
    let data;
    if (this.eventFields) {
      data = this.eventFields;
    } else {
      let response = await this.eventFieldsService.getFields().toPromise();
      data = response.availableFields;
    }
    const fieldMetadata = data.find((item: any) => item.id === field);
    const dataType = fieldMetadata.dataType;

    // Build query
    let operator;
    if (numericDataTypes.includes(dataType) || dataType === 'boolean') {
      operator = 'equals_number';
    } else if (dataType === 'string') {
      operator = 'equals';
    } else {
      console.error('Invalid data type for doing new search:', dataType);
    }
    return new QueryClause({
      field,
      value,
      operator
    });
  }

  private async addClauseToQuery(clause: QueryClause) {
    this.adHocMode = true;
    this.currentQuery.q.clauses.push(clause);
    this.activeQueryService.setQuery(
      this.activeQueryService.getQuery().originalQuery,
      this.currentQuery
    );
    this.refresh();
    let fieldMeta = this.eventFields.find(item => item.id === clause.field);
    let message = await this.localeStrings
      .string$('auditing.filterAddedNotification', {
        field: fieldMeta.displayName
      })
      .pipe(take(1))
      .toPromise();
    toastLowerLeft(this.toastService, message, ToastType.Success);
  }
}
