import { StaticIndicatorComponent } from './static-indicator.component';
import { OdaSmallSummaryCardInformationComponent } from './oda-small-summary-card-information/oda-small-summary-card-information.component';
import { StaticIndicatorEffects } from './state/static-indicator.effects';
import { StaticIndicatorDataService } from './services/static-indicator-data.service';

export const staticIndicatorFiles = {
  components: [
    StaticIndicatorComponent,
    OdaSmallSummaryCardInformationComponent
  ],
  effects: [StaticIndicatorEffects],
  services: [StaticIndicatorDataService]
};
