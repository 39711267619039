<div class="navigation-tabs-container">
  <ul class="tabs">
    <li
      class="tab"
      [ngClass]="{ disabled: (navigationAllowed$ | async) === false }"
      *odaIfHasPermissions="fromPermissions.canViewDashboard"
    >
      <a
        id="nav-link-dashboard"
        [routerLink]="['/auditing/auditing/dashboard']"
        routerLinkActive="active"
        >{{ 'auditing.dashboard' | localize }}</a
      >
    </li>

    <ng-container>
      <li
        class="tab"
        [ngClass]="{ disabled: (navigationAllowed$ | async) === false }"
        *odaIfHasAllPermissions="[
          fromPermissions.canRunSearchVisual,
          fromPermissions.canRunSharedSearches
        ]"
      >
        <a
          id="nav-link-critical-activity"
          [routerLink]="['/auditing/auditing/criticalActivity']"
          routerLinkActive="active"
          >{{ 'auditing.pages.criticalActivity.title' | localize }}</a
        >
      </li>
    </ng-container>

    <li
      class="tab"
      [ngClass]="{ disabled: (navigationAllowed$ | async) === false }"
      *odaIfHasPermissions="[
        fromPermissions.canRunPrivateSearch,
        fromPermissions.canManagePrivateSearch,
        fromPermissions.canManageSharedSearches,
        fromPermissions.canViewSharedSearches,
        fromPermissions.runQuickSearches
      ]"
    >
      <a
        id="nav-link-saved-queries"
        [routerLink]="['/auditing/auditing/queries']"
        routerLinkActive="active"
        >{{ 'auditing.queries' | localize }}</a
      >
    </li>

    <li
      class="tab"
      [ngClass]="{ disabled: (navigationAllowed$ | async) === false }"
      *odaIfHasPermissions="[
        fromPermissions.canManageSharedAlertsAndAlertPlans,
        fromPermissions.canManagePrivateAlertsAndAlertPlans
      ]"
    >
      <a
        id="nav-link-alerts"
        [routerLink]="['/auditing/auditing/alerts']"
        routerLinkActive="active"
        >{{ 'auditing.alertTabTitle' | localize }}</a
      >
    </li>

    <li
      class="tab"
      [ngClass]="{ disabled: (navigationAllowed$ | async) === false }"
      *odaIfHasPermissions="fromPermissions.canViewEventRetentionSettings"
    >
      <a
        id="nav-link-retention"
        [routerLink]="['/auditing/auditing/retention']"
        routerLinkActive="active"
        >{{ 'auditing.retentionTabTitle' | localize }}</a
      >
    </li>

    <li class="tab">
      <a
        id="nav-link-config"
        [routerLink]="['/auditing/auditing/config']"
        routerLinkActive="active"
        *odaIfHasPermissions="[
          fromPermissions.canManageAzureADTenants,
          fromPermissions.canManageChangeAuditor
        ]"
        >{{ 'auditing.configuration' | localize }}</a
      >
    </li>
  </ul>

  <div class="quick-search-container" *ngIf="navigationAllowed$ | async">
    <quick-search
      *odaIfHasPermissions="fromPermissions.runQuickSearches"
    ></quick-search>
  </div>
</div>
