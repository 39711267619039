import { Column } from './results.column.model';
import { Cell } from './results.cell.model';
import { Row } from './results.row.model';

export class Results {
  resultSetId: string;
  page?: number = 0;
  limit: number;
  totalRows: number;
  columnMetadata: Column[];
  headers: Cell[];
  rows: Row[];
  sortBy: string;
  sortDir: 'asc' | 'desc';
  [propName: string]: any;

  constructor(params?: any) {
    Object.assign(this, params);
  }

  get sorts() {
    if (this.sortBy) {
      return [{
        prop: this.sortBy,
        dir: this.sortDir
      }];
    } else {
      return [];
    }
  }
}
