import { ReplaySubject } from 'rxjs';

export class AlertRule {
  id: string;
  queryId: string;
  name: string;
  description?: string;
  runFrequency: string;
  countThreshold: number;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  lastTriggeredDate: string;
  lastCheckedDate: string;
  enabled: boolean;
  timesTriggeredPastDay?: number;
  numAlertPlans: number;
  isShared: boolean;
  lastSavedToolTipInfo: ReplaySubject<string>;
  lastSavedLabelInfo: ReplaySubject<string>;
  isBuiltIn: boolean;
  ownerModule: string;
  dataTags: string[];

  constructor(params?: any) {
    Object.assign(this, params);
  }
}
