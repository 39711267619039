import moment from 'moment';
import { substituteTemplateValues } from './template-substituter';

export const getLastSavedLabelText = (
  lastSavedLabel: string,
  lastUpdatedBy: string,
  lastUpdatedTime: string
) => {
  if (lastSavedLabel === null || lastSavedLabel === undefined) {
    return '';
  }

  let byString = 'by';
  if (lastUpdatedBy === null) {
    byString = '';
    lastUpdatedBy = '';
  }

  const lastUpdatedDate = moment(lastUpdatedTime).fromNow();
  let TemplateValues: any = {
    lastUpdatedTime: lastUpdatedDate,
    by: byString,
    lastUpdatedBy
  };

  return substituteTemplateValues(lastSavedLabel, TemplateValues);
};

export const getLastSavedToolTipInfo = (
  lastSavedTooltip: string,
  createdTime: string,
  createdBy: string,
  lastUpdatedTime: string,
  lastUpdatedBy: string
) => {
  if (lastSavedTooltip === null || lastSavedTooltip === undefined) {
    return '';
  }

  let byString = 'by';
  const createdTimeValue = moment(createdTime).format(
    'MMM DD, YYYY HH:mm:ss A'
  );
  const lastUpdatedTimeValue = moment(lastUpdatedTime).format(
    'MMM DD, YYYY HH:mm:ss A'
  );

  if (lastUpdatedBy === null) {
    byString = '';
    lastUpdatedBy = '';
  }

  let tooltipTemplateValues: any = {
    createdTime: createdTimeValue,
    createdBy,
    lastUpdatedTime: lastUpdatedTimeValue,
    by: byString,
    lastUpdatedBy
  };

  return substituteTemplateValues(lastSavedTooltip, tooltipTemplateValues);
};
