<form #f="ngForm">
  <div class="input-field">
    <select
      materialize="material_select"
      [materializeSelectOptions]="menuOptions"
      name="value-{{index}}"
      id="value-{{index}}"
      [(ngModel)]="selectedOption"
      (change)="onChange()"
      >
      <option [ngValue]="null">{{ 'auditing.pages.newSearches.predefinedPlaceholder' | localize }}</option>
      <ng-container *ngFor="let option of menuOptions">
        <option [ngValue]="option">{{ option.label }}</option>
      </ng-container>
    </select>

  </div>
</form>
