import { Injectable } from '@angular/core';

@Injectable()
export class SigninsChartTicksFormatService {
  public calculateTicks(
    maxTicks: number,
    minPoint: number,
    maxPoint: number
  ): [number, number, number] {
    let range = this.niceNumber(maxPoint - minPoint, false);
    let tickSpacing = this.niceNumber(range / (maxTicks - 1), true);
    let niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
    let niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
    return [tickSpacing, niceMin, niceMax];
  }

  private niceNumber(range: number, round: boolean): number {
    // nice numbering algorithm from:
    // https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks

    let exponent: number;
    /** exponent of range */
    let fraction: number;
    /** fractional part of range */
    let niceFraction: number;
    /** nice, rounded fraction */

    exponent = Math.floor(Math.log10(range));
    fraction = range / Math.pow(10, exponent);

    if (round) {
      if (fraction < 1.5) niceFraction = 1;
      else if (fraction < 3) niceFraction = 2;
      else if (fraction < 7) niceFraction = 5;
      else niceFraction = 10;
    } else {
      if (fraction <= 1) niceFraction = 1;
      else if (fraction <= 2) niceFraction = 2;
      else if (fraction <= 5) niceFraction = 5;
      else niceFraction = 10;
    }

    return niceFraction * Math.pow(10, exponent);
  }
}
