import { AvailableValuesService } from './available-values/available-values.service';
import { FeatureSubscriptionService } from './feature-subscription/feature-subscription.service';
import { AuditSubscriptionService } from './audit-subscription/audit-subscription.service';
import { QueryService } from './query/query.service';
import { ActiveQueryService } from './active-query.service';
import { RetentionInfoService } from './retention-info/retention-info.service';
import { AlertService } from './alerts.service';
import { AuditingBreadcrumbsService } from './auditing-breadcrumbs.service';
import { AuditingFeatureFlagsService } from './auditing-feature-flags.service';
import { ChangeAuditorInstallationService } from './ca-installation.service';
import { ConsentService } from './consent.service';
import { ErrorMessageService } from './error-message.service';
import { EventFieldsService } from './event-fields.service';
import { EventService } from './event.service';
import { LocaleStringsService } from './locale-strings.service';
import { SavedQueriesService } from './saved-queries.service';
import { ServiceDiscoveryService } from './service-discovery.service';
import { TenantConfigurationService } from './tenant-config.service';
import { UnsavedChangesService } from './unsaved-changes.service';
import { AuditModulePermissionsService } from './audit-module-permissions.service';
import { TrialStatusService } from './trial-status.service';
import { ExportQueryResultsService } from './export-query-results.service';
import { SearchPermissionsService } from './search-permissions.service';
import { Renderer2 } from '@angular/core';
import { ProvisioningService } from './organization-provisioning/provisioning.service';
import { BloodHoundConfigurationService } from './bhe-service/bhe-config.service';

// All the services are in this array so that we can easily provide them to the
// module.
export const services: any[] = [
  AvailableValuesService,
  FeatureSubscriptionService,
  AuditSubscriptionService,
  QueryService,
  Renderer2,
  ActiveQueryService,
  RetentionInfoService,
  AlertService,
  AuditingBreadcrumbsService,
  AuditingFeatureFlagsService,
  ChangeAuditorInstallationService,
  ConsentService,
  ErrorMessageService,
  EventFieldsService,
  EventService,
  LocaleStringsService,
  SavedQueriesService,
  ServiceDiscoveryService,
  TenantConfigurationService,
  UnsavedChangesService,
  AuditModulePermissionsService,
  TrialStatusService,
  ExportQueryResultsService,
  SearchPermissionsService,
  ProvisioningService,
  BloodHoundConfigurationService
];
