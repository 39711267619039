/**
 * Component for viewing and managing saved query categories
 */
import { take } from 'rxjs/operators';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  OnInit
} from '@angular/core';
import { SavedQueryCategory } from '../saved-query-category.model';
import { UpsertCategory } from '../upsert-category.model';
import { IModalWindow, BaseComponent } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { SavedQueriesService } from '../../../../services/saved-queries.service';
import { NgForm } from '@angular/forms';
import { stripUnprintable } from '../../../../util/strip-unprintable';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import {
  SEARCH_PERMISSION,
  SearchPermissionsService
} from '../../../../services/search-permissions.service';
import * as fromPermissions from '../../../../models/audit-permissions.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../../shared/utils/toast.wrapper';

@Component({
  selector: 'query-categories',
  templateUrl: './query-categories.component.html',
  styleUrls: ['./query-categories.component.scss']
})
export class QueryCategoriesComponent extends BaseComponent implements OnInit {
  @Input() categories: SavedQueryCategory[];
  @Output() categorySelect = new EventEmitter<SavedQueryCategory>();
  @Output() categoryAdd = new EventEmitter<SavedQueryCategory>();
  @Output() categoryEdit = new EventEmitter<SavedQueryCategory>();
  @ViewChild('newCategoryNameField', {static: true}) newCategoryNameField: ElementRef;
  @ViewChild('addCategoryForm', {static: true}) addForm: NgForm;
  @ViewChild('editCategoryForm', {static: true}) editForm: NgForm;
  @ViewChildren('input') inputs: any;
  newCategoryName = '';
  addCategoryModalParams: IModalWindow;
  editCategoryModalParams: IModalWindow;
  labels = AuditingDisplayStringsProvider.auditing.pages.savedSearches;
  addingCategory = false;
  addingCategoryError: string;
  editingCategory = false;
  editingCategoryError: string;
  categoryBeingEdited: SavedQueryCategory;
  editCategoryName: string;
  deletePendingConfirmation = false;
  deleteSubmitting = false;
  cannotDelete = false;
  deleteError: string;

  fromPermissions = fromPermissions;

  isPrivateSelectionAllowed = false;
  isSharedSelectionAllowed = false;
  isSharedCategory = false;

  constructor(
    private queryService: SavedQueriesService,
    private localeStrings: LocaleStringsService,
    private searchPermissionsService: SearchPermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  ngOnInit() {
    // Set up modals
    this.addCategoryModalParams = {
      showModal: false,
      dialogParams: {
        title: this.labels.addCategoryHeader,
        hideCancel: true,
        cancelButtonAction: () => {
          this.closeAddCategoryModal();
        }
      }
    };

    this.editCategoryModalParams = {
      showModal: false,
      dialogParams: {
        title: this.labels.editCategory,
        hideCancel: true,
        cancelButtonAction: () => {
          this.closeEditModal();
        }
      }
    };
  }

  /**
   * Handle clicking on a category in the list, selecting it
   *
   * @param selectedCategory Category to view
   */
  onCategoryClick(selectedCategory: SavedQueryCategory) {
    this.categorySelect.next(selectedCategory);
  }

  onCategoryEditClick(category: SavedQueryCategory) {
    this.isSharedCategory = category.isShared;

    // private/shared type selector only to show what type it is;
    // we do not allow user to change the priavte/shared type of a category after creation
    this.isPrivateSelectionAllowed = !this.isSharedCategory;
    this.isSharedSelectionAllowed = this.isSharedCategory;

    this.categoryBeingEdited = category;
    this.editCategoryName = category.name;
    this.editCategoryModalParams.showModal = true;
    this.editCategoryModalParams = { ...this.editCategoryModalParams };
  }

  /**
   * Handle clicking on add link to open prompt for adding new category
   */
  onAddCategoryClick() {
    this.setSharedSelectionAllowed();
    this.addCategoryModalParams.showModal = true;
    this.addCategoryModalParams = { ...this.addCategoryModalParams };
    // Focus on name field
    setTimeout(() => {
      this.newCategoryNameField.nativeElement.focus();
    });
  }

  /**
   * Submit form for adding a new category
   */
  addCategory() {
    this.addingCategory = true;
    this.addingCategoryError = null;

    let newCategoryToCreate = new UpsertCategory();
    newCategoryToCreate.name = this.newCategoryName;
    newCategoryToCreate.isShared = this.isSharedCategory;

    this.queryService
      .createCategory(newCategoryToCreate)
      .toPromise()
      .then(
        (response: any) => {
          let newCategory = new SavedQueryCategory(response);
          this.closeAddCategoryModal();
          this.categoryAdd.next(newCategory);
          this.addingCategory = false;
          toastLowerLeft(this.toastService, this.labels.addCategorySuccessful, ToastType.Success);
        },
        async (response: HttpErrorResponse) => {
          this.addingCategory = false;
          this.addingCategoryError = await this.getSaveErrorMessage(response);
        }
      );
  }

  onDeleteCategoryClick() {
    this.deleteError = null;
    if (this.categoryBeingEdited.queries.length === 0) {
      this.deletePendingConfirmation = true;
    } else {
      this.cannotDelete = true;
    }
  }

  onDeleteConfirmClick() {
    // Delete for real
    this.deleteSubmitting = true;
    this.queryService
      .deleteCategory(this.categoryBeingEdited.id)
      .toPromise()
      .then(
        () => {
          this.deleteSubmitting = false;
          this.categories = this.categories.filter(
            cat => cat.id !== this.categoryBeingEdited.id
          );
          this.closeEditModal();
          this.categoryBeingEdited = null;
          toastLowerLeft(this.toastService, this.labels.deleteCategorySuccessful, ToastType.Success);
        },
        _error => {
          this.deleteError = this.labels.deletedFailed;
          this.deleteSubmitting = false;
        }
      );
  }

  onCancelDeleteClick() {
    this.deletePendingConfirmation = false;
  }

  onSubmitEditCategory() {
    this.editingCategory = true;
    let categoryToUpdate = new UpsertCategory();
    categoryToUpdate.name = this.editCategoryName;
    this.queryService
      .updateCategory(this.categoryBeingEdited.id, categoryToUpdate)
      .toPromise()
      .then(
        (response: any) => {
          let responseCategory = new SavedQueryCategory();
          Object.assign(responseCategory, response);
          this.editingCategory = false;
          let realCategory = this.categories.find(
            cat => cat.id === this.categoryBeingEdited.id
          );
          realCategory.name = this.editCategoryName;
          this.categoryEdit.next(realCategory);
          this.closeEditModal();
          toastLowerLeft(this.toastService, this.labels.updateCategorySuccessful, ToastType.Success);
        },
        async (response: HttpErrorResponse) => {
          this.editingCategoryError = await this.getSaveErrorMessage(response);
          this.editingCategory = false;
        }
      );
  }

  /**
   * Close modal for adding a category
   */
  closeAddCategoryModal() {
    // Clear value
    this.newCategoryName = '';
    this.addCategoryModalParams.showModal = false;
    this.addingCategoryError = null;
    this.addCategoryModalParams = { ...this.addCategoryModalParams };
    this.addForm.form.markAsPristine();
  }

  /**
   * Close modal for adding a category
   */
  closeEditModal() {
    // Clear value
    this.categoryBeingEdited = null;
    this.editCategoryModalParams.showModal = false;
    this.deleteError = null;
    this.editingCategoryError = null;
    this.deletePendingConfirmation = false;
    this.cannotDelete = false;
    this.editCategoryModalParams = { ...this.editCategoryModalParams };
    this.editForm.form.markAsPristine();
  }

  onNewCategoryNameChange() {
    // Remove bad characters
    this.newCategoryName = stripUnprintable(this.newCategoryName);
    this.addingCategoryError = null;
  }

  onEditNameChange() {
    // Remove bad characters
    this.editCategoryName = stripUnprintable(this.editCategoryName);
    this.editingCategoryError = null;
    this.deleteError = null;
    this.cannotDelete = false;
  }

  /**
   * Determines whether the user has permission to manage the category.
   * User defined shared category require a different permission then private category.
   * System-defined categories are shared searches.
   *
   * @param category The search category the user has highlighted.
   */
  canUserManageCategory(category: SavedQueryCategory): boolean {
    let categoryPermission = this.searchPermissionsService.getManageSearchPermission();

    if (categoryPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED) {
      return true;
    }

    if (category.isShared) {
      return categoryPermission === SEARCH_PERMISSION.SHARED;
    } else {
      return categoryPermission === SEARCH_PERMISSION.PRIVATE;
    }
  }

  onCategoryTypeToggleButtonsChanged(selectedType: string) {
    this.isSharedCategory =
      selectedType !== AuditingDisplayStringsProvider.auditing.privateType;
    this.addingCategoryError = null;
  }

  private async getSaveErrorMessage(response: HttpErrorResponse) {
    const defaultErrorMessage = this.labels.editCategoryError;
    let errorMessage = defaultErrorMessage;
    try {
      let responseBody: any = response.error;
      let error = responseBody.error;
      if (error.code === '409' || error.code === 'Conflict') {
        errorMessage = this.labels.addCategoryDuplicateError;
      } else if (error.code === 'InvalidInput') {
        if (error.details[0].code === 'ContainsInvalidCharacters') {
          errorMessage = await this.localeStrings
            .string$('auditing.pages.savedSearches.categoryInvalidName').pipe(
            take(1))
            .toPromise();
        }
      }
      return errorMessage;
    } catch (e) {
      return defaultErrorMessage;
    }
  }

  private setSharedSelectionAllowed() {
    let categoryPermission = this.searchPermissionsService.getManageSearchPermission();

    // make category to be private by default.
    this.isSharedCategory = false;

    switch (categoryPermission) {
      case SEARCH_PERMISSION.PRIVATE_AND_SHARED:
        this.isPrivateSelectionAllowed = true;
        this.isSharedSelectionAllowed = true;
        break;
      case SEARCH_PERMISSION.PRIVATE:
        this.isPrivateSelectionAllowed = true;
        this.isSharedSelectionAllowed = false;
        break;
      case SEARCH_PERMISSION.SHARED:
        this.isPrivateSelectionAllowed = false;
        this.isSharedSelectionAllowed = true;

        // the user doesn't have permission to create private category
        this.isSharedCategory = true;
        break;
      default:
        // SEARCH_PERMISSION.NONE
        this.isPrivateSelectionAllowed = false;
        this.isSharedSelectionAllowed = false;
        break;
    }
  }
}
