export class EventField {
  id: string;
  displayName: string;
  dataType: 'predefined' | 'string' | 'datetime' | 'int32' | 'int64' | 'double' | 'boolean';
  semanticType: string;
  description: string;
  searchable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  facetable?: boolean;
  predefinedValuesOnly?: boolean;
  typeaheadEnabled?: boolean;
  retrievable?: boolean;

  constructor(params?: any) {
    Object.assign(this, params);
  }

  // Implement our own property since "searchable" and "filterable" have the same meaning
  // but are defined based on the data type of the field
  get isSearchable(): boolean {
    return this.searchable || this.filterable;
  }

}
