/**
 * Model class to create new query
 */

import { QueryBody } from './query-body';

export class CreateQuery {
  name: string;
  q: QueryBody;
  categoryId: string;
  starred = false;
  isShared?: boolean;
  hideCharts?: boolean;
  hideEvents?: boolean;

  constructor(fullQueryObj?: any) {
    if (fullQueryObj !== null && fullQueryObj !== undefined) {
      this.name = fullQueryObj.name;
      this.starred = fullQueryObj.starred;
      this.q = fullQueryObj.q;
      this.categoryId = fullQueryObj.categoryId;
      this.isShared = fullQueryObj.isShared;
      this.hideCharts = fullQueryObj.hideCharts;
      this.hideEvents = fullQueryObj.hideEvents;

      if (this.name === undefined || this.name === null) {
        this.name = `New Search - ${(new Date()).toDateString()}`;
      }

      if (this.q) {
        this.q = new QueryBody(this.q);
      } else if (this.q === undefined || this.q === null) {
        this.q = new QueryBody();
      }
    }
  }
}
