﻿// © Copyright 2018 Quest Software Inc.
// ALL RIGHTS RESERVED.

/**
 * Bootstraps the application and makes the ROUTER_PROVIDERS and the APP_BASE_HREF available to it.
 *
 * @see https://angular.io/docs/ts/latest/api/platform-browser-dynamic/index/bootstrap-function.html
 */
import { enableProdMode } from '@angular/core';
// The browser platform with a compiler
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// Load i18n providers
// import { TranslationProviders } from './i18n.providers';

import { KeycloakService, Util } from '@ondemand/core';

// The app module
import { AppModule } from './app.module';

const configs: any = (window as any).GLOBAL_CONFIGS;

// if (String('<%= ENV %>') === 'prod') { enableProdMode(); }
// enableProdMode();

checkFlags(configs);

// Compile and launch the module with i18n providers
// let TP = new TranslationProviders();
// TP.getTranslationFile().then((providers: any) => {
// const options: any = { providers };
const bootstrapPromise =
  Util.IS_GOV_SITE  || sessionStorage.getItem('useMs') === 'true'
  ? Promise.resolve(true)
  : KeycloakService.init();
bootstrapPromise
  .then((_) => platformBrowserDynamic().bootstrapModule(AppModule))
  .catch(console.error);

function checkFlags(globalConfigs: any) {
  if (globalConfigs.FLAGS.auditing) {
    // console.info('Setting the `auditing` dev flag');
    setDevFlag();
  }

  if (globalConfigs.FLAGS.prodMode) {
    // console.info('enabling prod mode');
    enableProdMode();
  }
}

function setDevFlag() {
  const auditingDevFlag = localStorage.getItem('auditing');
  if (auditingDevFlag === null || auditingDevFlag !== 'true') {
    localStorage.setItem('auditing', 'true');
    window.location.reload();
  }
}
