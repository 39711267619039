import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdateWidgetConfiguration } from '../../../../../../shared/models/update-widget-configuration';
import { WidgetID } from '../../../models/widget-info';
import { SigninsWidgetResponse } from '../models/signins.models';
import { WidgetService } from '../../../../../../shared/services/widget-service';

@Injectable()
export class SigninsDataService {
  constructor(private widgetService: WidgetService) {}

  getSignins(): Observable<SigninsWidgetResponse> {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.Signins).pipe(
      map((response: HttpResponse<SigninsWidgetResponse>) => response.body));
  }

  updateSelectedSignins(
    selectedTypeNames: string[]
  ): Observable<HttpResponse<any>> {
    let updateObj = {
      signins: {
        selectedSigninTypes: selectedTypeNames
      }
    } as UpdateWidgetConfiguration;

    return this.widgetService.putWidgetSettingsWithHttpInfo(
      WidgetID.Signins,
      updateObj
    );
  }
}
