import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UpdateWidgetConfiguration } from '../../../../../../../shared/models/update-widget-configuration';
import { WidgetService } from '../../../../../../../shared/services/widget-service';
import { WidgetID } from '../../../../models/widget-info';

@Injectable()
export class UpdateFavoriteSearchService {
  constructor(private widgetService: WidgetService) {}

  updateSettings(favoriteQueryIds: string[]): Observable<HttpResponse<any>> {
    let updateObj = {
      myFavoriteSearches: {
        selectedSearches: favoriteQueryIds
      }
    } as UpdateWidgetConfiguration;

    return this.widgetService.putWidgetSettingsWithHttpInfo(
      WidgetID.MyFavoriteSearches,
      updateObj
    );
  }
}
