import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { Query } from '../../../../auditing/models/query.model';
import { Router } from '@angular/router';
import { DismissModalComponent } from './dismiss-modal/dismiss-modal.component';
import { SavedQueriesService } from '../../../../auditing/services/saved-queries.service';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { cloneAndUnlockObject } from '../../../utils/object.tools';
import { ActiveQueryService } from '../../../../auditing/services/active-query.service';
import { getSupportedChartType, getDonutChartId } from '../utils/chart-utils';
import {
  IntervalCategory,
  OdaChartType
} from '../models/visualization-constants';
import { Subject, throwError } from 'rxjs';
import { InvokeWithErrorHandling } from '../../../utils/error.handling.wrapper';
import {
  TopXGroupOptions,
  ChartPosition,
  ChartQueryIntervals,
  TimeSeriesChartOptions
} from '../../../../auditing/models/chart-options/chart-models';
import { chartCanvasBox, UserColumnID } from '../models/chart-constants';
import {
  getMaxDataPointLimit,
  updateTimeZoneOffsetOfChart
} from '../services/chart-query-converter';
import { BreakpointObserver } from '@angular/cdk/layout';

export const ChartResizingWidthBreakpoint = '(max-width: 1600px)';

@Component({
  selector: 'flyout-chart',
  templateUrl: './flyout-chart.component.html',
  styleUrls: ['./flyout-chart.component.scss']
})
export class FlyoutChartComponent
  extends BaseComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() query: Query;
  @Input() queryId: string;
  @Output() errorLoadingChart: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() removeFlyoutChart: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(DismissModalComponent) dismissModal: DismissModalComponent;
  @ViewChild('flyoutChartContainer') flyoutChartContainer: ElementRef;

  showDonutFlyoutChart = false;
  showTimeseriesFlyoutChart = false;
  errorRenderingChart = false;
  contentReady = false;
  loadingChart = true;
  queryName = '';
  queryDescription = '';
  chartPosition: ChartPosition = chartCanvasBox.positionOne;
  ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private savedQueryService: SavedQueriesService,
    private activeQueryService: ActiveQueryService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  ngOnInit(): void {
    this.chartResizeSubscription();
    this.initializeDataAndRenderChart();
  }

  ngOnDestory(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initializeDataAndRenderChart(): void {
    this.errorRenderingChart = false;
    this.contentReady = false;
    this.loadingChart = true;

    if (this.queryId) {
      this.savedQueryService
        .getSavedQuery(this.queryId)
        .pipe(
          take(1),
          catchError(err => throwError(err))
        )
        .subscribe(
          result => {
            InvokeWithErrorHandling(
              () => {
                this.query = result;
                this.renderFlyoutChart();
              },
              err => {
                this.handleErrorFromChart(err);
              }
            );
          },
          err => {
            this.handleErrorFromChart(err);
          }
        );
    } else if (this.query) {
      this.renderFlyoutChart();
    }
  }

  chartResizeSubscription() {
    this.breakpointObserver
      .observe(ChartResizingWidthBreakpoint)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result.breakpoints[ChartResizingWidthBreakpoint])
          this.chartPosition = chartCanvasBox.positionThree;
        else {
          this.chartPosition = chartCanvasBox.positionOne;
        }
      });
  }

  renderFlyoutChart(): void {
    this.queryName = this.query.name;
    this.queryDescription = this.query.description;
    this.setShowFlyoutChartType(this.query);
  }

  onClickViewAllEventsButton(): void {
    const mutableQuery = cloneAndUnlockObject<Query>(this.query);

    // Check if the query is of type anomaly detection
    if (
      getSupportedChartType(mutableQuery, 0) ===
      OdaChartType.TimeSeriesAnomalyDetection
    ) {
      mutableQuery.q.charts[0].timeSeries = {
        id: this.query.q.charts[0].timeSeriesAnomalyDetection.id,
        filterOptions: {
          topXFilter: 10,
          topXFilterColumn: UserColumnID // Design docs mentioned User(Actor) is to be used for grouping by column.
        },
        seriesInterval: ChartQueryIntervals.Day,
        timeZoneOffset: updateTimeZoneOffsetOfChart(),
        maxBins: getMaxDataPointLimit(IntervalCategory.Day)
      } as TimeSeriesChartOptions;

      // Anomaly time series chart is not required in result page as timer series chart is set in the previos block.
      mutableQuery.q.charts[0].timeSeriesAnomalyDetection = null;
    }

    let adhocQuery = new Query({
      name: mutableQuery.name,
      description: mutableQuery.description,
      q: mutableQuery.q,
      isShared: false,
      hideCharts: false
    });

    this.activeQueryService.setQuery(adhocQuery, adhocQuery);
    this.router.navigate(['/auditing/auditing/queries/results']);
  }

  onClickDismissActivityButton(): void {
    this.dismissModal.openModal();
  }

  onClearFlyoutChart(): void {
    this.clearContent();
    this.removeFlyoutChart.emit();
  }

  handleErrorFromChart(events: any): void {
    this.errorRenderingChart = true;
    this.contentReady = false;
    this.loadingChart = false;
    this.errorLoadingChart.emit(events);
  }

  handleDismissEvent(hideItem: boolean): void {
    this.onClearFlyoutChart();
    this.hideItem.emit(hideItem);
  }

  handleErrorRetryButtonClick(): void {
    this.clearContent();
    setTimeout(() => {
      this.initializeDataAndRenderChart();
    }, 50);
  }

  handleChartRenderingCompletion(chartRenderCompleted: boolean): void {
    this.loadingChart = false;
    this.contentReady = chartRenderCompleted;
  }

  private clearContent(): void {
    this.showDonutFlyoutChart = false;
    this.showTimeseriesFlyoutChart = false;
    this.errorRenderingChart = false;
    this.contentReady = false;
    this.loadingChart = false;
  }

  private setShowFlyoutChartType(query: Query): void {
    if (getSupportedChartType(query, 0) === OdaChartType.Donut) {
      setTimeout(() => {
        this.query.id = getDonutChartId(this.query);
        this.query.q.charts[0].donut.chartQueryOptions = null;
        if (!this.query.q.charts[0].donut.topXGroupOptions) {
          this.query.q.charts[0].donut.topXGroupOptions = {
            topXGroupCount: 10, // Sets top 10 items in result set.
            topXGroupName: this.query.q.charts[0].donut.labelColumnName,
            addOthers: true
          } as TopXGroupOptions;
          this.activeQueryService.setQuery(this.query, this.query);
        }

        this.showDonutFlyoutChart = true;
        this.showTimeseriesFlyoutChart = false;
      }, 200);
    } else if (
      getSupportedChartType(query, 0) ===
      OdaChartType.TimeSeriesAnomalyDetection
    ) {
      this.showDonutFlyoutChart = false;
      this.showTimeseriesFlyoutChart = true;
    }
  }
}
