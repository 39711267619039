import { createSelector, MemoizedSelector } from '@ngrx/store';
import { WidgetInformation } from '../../models/widget-info';
import * as fromFeature from '../../reducers/index';

const getWidgetListState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.widgetList
);

export const selectWidgetList = createSelector(
  getWidgetListState,
  state => state.data
);

export const selectWidgetListLoading = createSelector(
  getWidgetListState,
  state => state.loading
);

export const selectWidgetListError = createSelector(
  getWidgetListState,
  state => state.error
);
