<table class="categories">
  <thead>
    <tr>
      <th></th>
      <th></th>
      <th class="category-name-header">
        <span class="title">{{ labels.categoriesListHeader }}</span>
      </th>
      <th>
        <a
          class="add-link"
          (click)="onAddCategoryClick()"
          *odaIfHasPermissions="[
              fromPermissions.canManagePrivateSearch,
              fromPermissions.canManageSharedSearches
            ]"
          >{{ labels.categoriesListAddAction }}</a
        >
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let category of categories"
      (click)="onCategoryClick(category)"
      [ngClass]="{ active: category.expanded }"
    >
      <td class="category-type-cell">
        <ng-container *odaIfHasPermissions="[
            fromPermissions.canRunPrivateSearch,
            fromPermissions.canManagePrivateSearch,
            fromPermissions.canViewSharedSearches
          ]">
          <span class="search-type-icon-container"
                title="{{ labels.privateSearchCategory }}" *ngIf="!category.isShared">
            <edc-icon name="user">
            </edc-icon>
          </span>
          <span class="search-type-icon-container"
                title="{{ labels.sharedSearchCategory }}" *ngIf="category.isShared">
            <edc-icon name="users">
            </edc-icon>
          </span>
        </ng-container>       
      </td>

      <td class="edit-category-cell">
        <ng-container
        *ngIf="canUserManageCategory(category)"
        >
          <span
            *ngIf="category.createdBy === 'user'"
            data-test="edit-category-button"
            (click)="onCategoryEditClick(category); $event.stopPropagation()"
          >
            <edc-icon
              name="pencil"
              class="tooltipped edit-category-button"
              materialize="tooltip"
              id="edit-category-{{ category.id }}"
              data-position="top"
              [attr.data-tooltip]="labels.editCategory"
            ></edc-icon>
          </span>
        </ng-container>
      </td>

      <td class="category-name-cell">
        <span class="category-name" title="{{ category.name }}">{{
          category.name | truncate: 30
        }}</span>
      </td>

      <td>
        <div class="category-query-count">{{ category.queries.length }}</div>
      </td>
    </tr>
  </tbody>
</table>

<modal-window #addModal [params]="addCategoryModalParams">
  <div modal-content>
    <div class="add-category-modal">
      <form #addCategoryForm="ngForm">
        <div class="main-section">
          <p>{{ labels.addCategoryPrompt }}</p>
          <div class="input-field">
            <input
              (input)="onNewCategoryNameChange()"
              #newCategoryNameField
              [(ngModel)]="newCategoryName"
              type="text"
              maxlength="250"
              name="newCategoryName"
              data-test="newCategoryName"
              required
              autocomplete="off"
            />
          </div>
          <div class="add-new-category-type-container">
            <private-shared-type-selector [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
                                          [isSharedSelectionAllowed]="isSharedSelectionAllowed"
                                          [isSharedItemSelected]="isSharedCategory"
                                          (buttonSelected)="onCategoryTypeToggleButtonsChanged($event)"></private-shared-type-selector>
          </div>
        </div>

        <error-message *ngIf="addingCategoryError">{{
          addingCategoryError
        }}</error-message>

        <div class="action-button-container-outer">
          <div class="action-buttons right">
            <button
              class="btn submit-add-category-button"
              data-test="submit-add-category"
              [disabled]="addingCategory || !addForm.valid || !newCategoryName"
              (click)="addCategory()"
            >
              <span *ngIf="addingCategory">{{
                labels.submitAddCategoryButtonInProgress
              }}</span>
              <span *ngIf="!addingCategory">{{
                labels.submitAddCategoryButton
              }}</span>
            </button>
            <button
              class="btn-flat cancel-add-category-button"
              data-test="cancel-add-category"
              [disabled]="addingCategory"
              (click)="closeAddCategoryModal()"
            >
              {{ labels.cancelAddCategoryButton }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</modal-window>

<modal-window #editModal [params]="editCategoryModalParams">
  <div modal-content>
    <div class="edit-category-modal">
      <form #editCategoryForm="ngForm">
        <div class="main-section">
          <div>
            <label for="editCategoryName">{{
              labels.editCategoryPrompt
            }}</label>
            <input
              (input)="onEditNameChange()"
              #editCategoryNameField
              [(ngModel)]="editCategoryName"
              type="text"
              maxlength="250"
              name="editCategoryName"
              id="editCategoryName"
              data-test="editCategoryName"
              required
              autocomplete="off"
            />
          </div>

          <div class="edit-category-type-container">
            <private-shared-type-selector [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
                                          [isSharedSelectionAllowed]="isSharedSelectionAllowed"
                                          [isSharedItemSelected]="isSharedCategory"></private-shared-type-selector>
          </div>

        </div>

        <error-message *ngIf="editingCategoryError">{{
          editingCategoryError
        }}</error-message>
        <error-message *ngIf="cannotDelete">{{
          labels.cannotDelete
        }}</error-message>
        <error-message *ngIf="deleteError">{{ deleteError }}</error-message>

        <div class="action-button-container-outer">
          <div class="action-buttons left">
            <a
              class="btn-flat delete-category-button"
              data-test="delete-category"
              [ngClass]="{ disabled: editingCategory }"
              *ngIf="!deletePendingConfirmation"
              (click)="onDeleteCategoryClick()"
              >{{ labels.deleteCategoryButton }}</a
            >
          </div>
          <div class="action-buttons right">
            <div *ngIf="deletePendingConfirmation">
              <span>{{ labels.deleteConfirmation }}</span>
              <button
                [disabled]="deleteSubmitting || deleteError"
                class="btn-flat delete-confirm-button"
                (click)="onDeleteConfirmClick()"
                data-test="confirm-delete"
              >
                <span *ngIf="deleteSubmitting">{{
                  labels.deleteInProgress
                }}</span>
                <span *ngIf="!deleteSubmitting">{{
                  labels.deleteConfirmButton
                }}</span>
              </button>
              <button
                [disabled]="deleteSubmitting"
                class="btn"
                (click)="onCancelDeleteClick()"
                data-test="cancel-delete"
              >
                {{ labels.cancelDelete }}
              </button>
            </div>

            <div *ngIf="!deletePendingConfirmation">
              <button
                class="btn submit-edit-category-button"
                data-test="submit-edit-category"
                [disabled]="
                  editingCategory ||
                  !editForm.valid ||
                  !editForm.dirty ||
                  !editCategoryName 
                "
                (click)="onSubmitEditCategory()"
              >
                <span *ngIf="editingCategory">{{
                  labels.submitEditCategoryButtonInProgress
                }}</span>
                <span *ngIf="!editingCategory">{{
                  labels.submitEditCategoryButton
                }}</span>
              </button>
              <button
                class="btn-flat cancel-edit-category-button"
                data-test="cancel-edit"
                [disabled]="editingCategory"
                (click)="closeEditModal()"
              >
                {{ labels.cancelAddCategoryButton }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</modal-window>
