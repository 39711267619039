import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditingDashboardComponent } from './auditing-dashboard.component';
import { SharedComponentsModule, NotificationPlansModule } from '@ondemand/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../../shared/shared.module';
import { WidgetListEffects } from './widgets/state/widget-list.effects';
import { signinsFiles } from './widgets/sign-ins/index';
import { favoriteSearchFiles } from './widgets/favorite-search/index';
import { criticalActivities } from './widgets/critical-activity/index';
import { auditHealthFiles } from './widgets/audit-health/index';
import { staticIndicatorFiles } from './widgets/static-indicator/index';
import { topActiveUsers } from './widgets/top-active-users/index';
import { DragulaModule } from 'ng2-dragula';
import { AuditConstants } from '../../../shared/models/ui-constants';

export const effectsForFeature: ModuleWithProviders<any> =
  EffectsModule.forFeature([
    WidgetListEffects,
    ...staticIndicatorFiles.effects,
    ...favoriteSearchFiles.effects,
    ...signinsFiles.effects,
    ...auditHealthFiles.effects,
    ...criticalActivities.effects,
    ...topActiveUsers.effects
  ]);

export const storeForFeature: ModuleWithProviders<any> = StoreModule.forFeature(
  'auditingDashboard',
  reducers
);

export const dragulaModule: ModuleWithProviders<any> = DragulaModule.forRoot();

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    effectsForFeature,
    storeForFeature,
    SharedModule,
    dragulaModule,
    NotificationPlansModule.withConfiguration(AuditConstants.ModuleName)
  ],
  declarations: [
    AuditingDashboardComponent,
    ...staticIndicatorFiles.components,
    ...favoriteSearchFiles.components,
    ...signinsFiles.components,
    ...auditHealthFiles.components,
    ...criticalActivities.components,
    ...topActiveUsers.components
  ],
  providers: [
    ...staticIndicatorFiles.services,
    ...favoriteSearchFiles.services,
    ...signinsFiles.services,
    ...criticalActivities.services,
    ...auditHealthFiles.services,
    ...topActiveUsers.services
  ]
})
export class AuditingDashboardModule {}
