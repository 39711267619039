import { Component, Input, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ModalConfig } from '@ondemand/ui-components';
import { BHEStringsEN } from '../../../../../shared/application-strings/bhe-strings-EN';
import { SvgIconName } from '../../../../../shared/models/ui-constants';
import { ODColor } from '../../../../../shared/models/oda-colors';

@Component({
  selector: 'remove-bhe-modal',
  templateUrl: 'bhe-remove-modal.html',
  styleUrls: ['bhe-remove-modal.scss']
})

export class BHERemoveConfirmationModalComponent implements OnInit  {
  @Input() modalConfig: ModalConfig = {
    headerText: BHEStringsEN.RemoveModalTile,
    headerIcon: SvgIconName.Warning,
    headerIconColor: ODColor.Warning_Yellow,
    customWidth: 720,
    customHeight: 278
  };

  removeModalWarning: string;
  removeModalConfirmation: string;

  primaryButtonText: string;
  secondaryButtonText: string;

  //TODO: Use new dialog ref when core UI component upgrades
  constructor(public dialogRef: MatLegacyDialogRef<BHERemoveConfirmationModalComponent>) {}

  ngOnInit(): void {
    this.setupText();
  }
  closeModalHandler(confirm: boolean) {
    this.dialogRef.close(confirm);
  }

  private setupText(): void {
    this.removeModalConfirmation = BHEStringsEN.RemoveModalConfirmation;
    this.removeModalWarning = BHEStringsEN.RemoveModalWarning;
    this.primaryButtonText = BHEStringsEN.RemoveModalPrimaryButton;
    this.secondaryButtonText = BHEStringsEN.RemoveModalSecondaryButton;
  }
}
