import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import { FavoriteSearchConfiguration, FavoriteSearchResponse } from '../models/favorite-search.models';

export const LOAD_FAVORITE_SEARCH = '[Auditing Dashboard] Load Favorite Search';
export const LOAD_FAVORITE_SEARCH_SUCCESS = '[Auditing Dashboard] Load Favorite Search Success';
export const LOAD_FAVORITE_SEARCH_FAIL = '[Auditing Dashboard] Load Favorite Search Fail';

export class LoadFavoriteSearch {
  readonly type = LOAD_FAVORITE_SEARCH;
}

// eslint-disable-next-line max-classes-per-file
export class LoadFavoriteSearchSuccess {
  readonly type = LOAD_FAVORITE_SEARCH_SUCCESS;
  constructor(public payload: AuditingDashboardResponse<FavoriteSearchResponse, FavoriteSearchConfiguration>) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadFavoriteSearchFail {
  readonly type = LOAD_FAVORITE_SEARCH_FAIL;
  constructor(public payload: any) {}
}

export type FavoriteSearchAction = LoadFavoriteSearch | LoadFavoriteSearchSuccess | LoadFavoriteSearchFail;
