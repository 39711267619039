import { DataType, IPropertyConfig, IInputConfig, ValueComponentType } from '@ondemand/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringOperators } from '../../../../../../../shared/models/query-clause-operator-groups';
import { EventColumnSet } from './event-column-set';

export class TypeAheadConfigurationService {
  private readonly columnFilterFunction: string = 'columnFilterFunction';
  private isColumnListAlreadySet = false;
  private columnsAvailableForDropDown: EventColumnSet = new EventColumnSet([]);
  private columnPickerFilterPropertyConfig: IPropertyConfig = {
    fieldConfig: {
      name: '',
      displayName: '',
      type: DataType.String
    },
    operatorsConfig: {
      customizedOperators: StringOperators
    },
    valueConfig: {
      valueComponentType: ValueComponentType.DynamicOptionalValues,
      optionalValuesConfig: {
        dynamicalValuesConfig: {
          enableAutoCompleteOfAllOperators: true,
          sourceFunctionName: this.columnFilterFunction,
          sourceContextIndex: 0
        }
      },
      limitConfig: {
        required: true,
        maxlength: 64
      }
    }
  };

  /* This filter that filters out entries in field dropdown and invoked when user types letters. */
  private fieldDisplayNameListContext: any = {
    columnFilterFunction: (value: string) => {
      if (value) {
        value = value.trim().toLowerCase();
        const filteredFields = of(this.columnsAvailableForDropDown.getItems())
           .pipe(map( m => m.filter( x => {
                                          if (x) {
                                          x = x.toLowerCase();
                                          return (value.trim() === '' ||
                                          x.startsWith(value) ||
                                          x.endsWith(value) ||
                                          x.indexOf(value) > -1);
}
                                          return false;
                                        }
                                    )
                    )
                );
        return filteredFields;
      }
      return of(this.columnsAvailableForDropDown.getItems());
    }
  };

setColumnList(displayNames: string[]): void {
  if ( displayNames && !this.isColumnListAlreadySet) {
    this.columnsAvailableForDropDown.addItems(displayNames);
    this.isColumnListAlreadySet = true;
  }
}

removeAnItemFromFieldNameList(value: string): boolean {
    return this.columnsAvailableForDropDown.remove(value);
  }

addAnItemIntoFieldNameList(value: string): void {
   this.columnsAvailableForDropDown.add(value);
  }

getColumnPickerFilterInputConfig(): IInputConfig {
    const inputConfig: IInputConfig = {
      sourceContexts: [this.fieldDisplayNameListContext],
      optionalPropertiesConfig: [this.columnPickerFilterPropertyConfig]
    };
    return inputConfig;
  }
}
