export type AuditWorkloadName =
  | 'AzureADAuditLogs'
  | 'AzureADSignIns'
  | 'ExchangeOnlineAdminActivity'
  | 'ExchangeOnlineMailboxActivity'
  | 'SharepointOnline'
  | 'OneDriveForBusiness'
  | 'CAAD'
  | 'CAGroupPolicy'
  | 'CALogonActivity'
  | 'CAWindowsFileServer'
  | 'MicrosoftTeams'
  | 'CAADFS'
  | 'AzureADRiskDetections';

export enum AuditSerivceName {
  ActiveDirectory = 'Active Directory',
  ADFS = 'Active Directory Federation Services',
  AzureActiveDirectory = 'Azure Active Directory',
  Exchange = 'Exchange',
  GroupPolicy = 'Group Policy',
  LogonActivity = 'Logon Activity',
  OnDemandAudit = 'On Demand Audit',
  OneDrive = 'OneDrive',
  SharePoint = 'SharePoint',
  Teams = 'Teams',
  ChangeAuditorFileSystem = 'File System',
  ActiveDirectoryDatabase = 'Active Directory Database',
}
