import { Injectable } from '@angular/core';
import { CriticalActivityComponentInterface } from '../critical-activity.component.interface';
import {
  CriticalActivityDataSet,
  CriticalActivityResponse
} from '../../../../../../shared/models/critical-activity.shared.models';
import { CriticalActivityDataRow } from '../models/critical-activity-widget.models';
import { isCriticalActivityDatasetValid } from '../../../../../../shared/services/critical-activity/critical-activity-lookup';
import { cloneAndUnlockObject } from '../../../../../../shared/utils/object.tools';

@Injectable()
export class DataTransformerService {
  transformDataFromWidgetResponse(
    response: CriticalActivityResponse,
    widget: CriticalActivityComponentInterface
  ): CriticalActivityDataRow[] {
    let resultData: CriticalActivityDataRow[] = [];

    if (widget) {
      this.preTransformationInitialization(widget);

      if (isCriticalActivityDatasetValid(response)) {
        // only proceed if all the required properties are defined

        widget.moreResultsAvailable =
          !!response.data.criticalActivity.moreResultsAvailable;

        const datasets = cloneAndUnlockObject<CriticalActivityDataSet[]>(
          response.data.criticalActivity.datasets
        );

        widget.hasCriticalActivity = datasets.length > 0;

        if (widget.hasCriticalActivity) {
          resultData = datasets.map(dataset =>
            this.transformDatasetToActivityRow(dataset, widget)
          );

          widget.canSeeViewActionButton = !!resultData[0].actionNames.find(
            name => name === widget.viewActionLabel
          );

          widget.canSeeDetailsActionButton = !!resultData[0].actionNames.find(
            name => name === widget.detailsActionLabel
          );
        }
      }

      widget.dataloaded = true;
    }

    return resultData;
  }

  private preTransformationInitialization(
    widget: CriticalActivityComponentInterface
  ): void {
    widget.moreResultsAvailable = false;
    widget.dataloaded = false;
    widget.canSeeViewActionButton = false;
    widget.canSeeDetailsActionButton = false;
    widget.hasCriticalActivity = false;
  }

  private transformDatasetToActivityRow(
    dataset: CriticalActivityDataSet,
    widget: CriticalActivityComponentInterface
  ): CriticalActivityDataRow {
    const queryAvailable = dataset && (dataset.queryId || dataset.query);
    const actionLabels: string[] = [];

    if (queryAvailable) {
      actionLabels.push(widget.viewActionLabel);

      if (widget.hasPermissionsForFullPage) {
        actionLabels.push(widget.detailsActionLabel);
      }
    }

    return {
      ...dataset,
      actionNames: actionLabels
    };
  }
}
