/* eslint-disable max-len */
export class BHEStringsEN {
  static LoadingMessage = 'Loading ...';
  static DeletingMessage = 'Deleting ...';
  static AddConfigCardTitle = 'Add SpecterOps BloodHound Enterprise';
  static AddConfigCardDescription =
    'Learn more about pairing SpecterOps BloodHound Enterprise with On Demand Audit for a comprehensive risk assessment and threat monitoring solution.';
  static AddConfigCardLearnMoreLinkText = 'Learn more';
  static AddConfigCardAddButtonText = 'ADD BLOODHOUND ENTERPRISE';
  static ConfigEditorHeading = 'SpecterOps BloodHound Configuration';
  static ConfigEditorSubHeading =
    'Enter the SpecterOps BloodHound URL and the Permanent Authorization Token (PAT) Token ID and Key pair.';
  static ConfigEditorLearnMoreDescription =
    'Learn how to locate the PAT Token and Key pair.';
  static ConfigEditorLearnMoreCollapsedLabel = 'View more';
  static ConfigEditorLearnMoreExpandedLabel = 'View less';
  static ConfigEditorSaveBtnLabel = 'SAVE';
  static ConfigEditorCancelBtnLabel = 'CANCEL';
  static ConfigEditorValidateBtnLabel = 'VALIDATE';
  static ConfigEditorHowToStepsTitle =
    'How to locate the PAT Token and Key Pair:';
  static ConfigEditorHowToStep1 = 'Log in to SpecterOps BloodHound Enterprise.';
  static ConfigEditorHowToStep2 = 'Open Settings | Administration.';
  static ConfigEditorHowToStep3 = 'Click Manage Users.';
  static ConfigEditorHowToStep4 = 'Locate a read-only or administrator user.';
  static ConfigEditorHowToStep5 = 'Open the Settings menu.';
  static ConfigEditorHowToStep6 = 'Click Generate / Revoke API Tokens.';
  static ConfigEditorHowToStep7 = 'Click Create Token.';
  static ConfigEditorUrlFieldLabel = 'SpecterOps BloodHound URL';
  static ConfigEditorUrlFieldPlaceHolder = 'URL name';
  static ConfigEditorUrlFieldHint =
    'Hint: https://[domain].bloodhoundenterprise.io';
  static ConfigEditorTokenIdFieldLabel = 'Token ID';
  static ConfigEditorTokenIdFieldPlaceHolder = 'Token ID';
  static ConfigEditorTokenIdFieldHint =
    'Example: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  static ConfigEditorKeyFieldLabel = 'Key';
  static ConfigEditorKeyFieldPlaceHolder = 'Key ID';
  static ConfigEditorNotificationValidationSuccess =
    'Token ID, Key, and URL validation is successful.';
  static ConfigEditorNotificationValidationFailure =
    'Invalid Token ID, Key, or URL entered.';
  static ConfigEditorNotificationApiSaveFailure =
    'An error has occurred while saving the configuration data.';
  static ConfigEditorSuccessSaveToast = 'SpecterOps BloodHound Enterprise configuration saved successfully.';
  static ConfigEditorSavingWaitMessage = 'Saving configuration ...';
  static ServiceConnectedModalTitle =
    'SpecterOps BloodHound Enterprise connected';
  static ServiceConnectedModalMessage =
    'Your SpecterOps BloodHound Enterprise configuration has been successfully saved.';
  static ServiceConnectedModalActionLabel = 'Click here';
  static ServiceConnectedModalActionDescription =
    'to edit the Tier Zero alert plan and configure who will be notified when an alert is triggered.';
  static ServiceConnectedModalConfirmButtonLabel = 'OK';
  static StatusCardTitle = 'BloodHound Enterprise';
  static StatusCardEditConfigButtonLabel = 'EDIT CONFIGURATION';
  static StatusCardRemoveConfigButtonLabel = 'REMOVE';
  static StatucCardBannerSuccessMessage = 'Successfully configured';
  static StatucCardBannerErrorMessage = 'Connection failed. Unable to connect to SpecterOps BloodHound Enterprise server.';
  static RemoveModalWarning =
    'When you remove a configuration, BloodHound information will no longer be added to events in On Demand Audit.';
  static RemoveModalConfirmation =
    'Do you still want to remove this SpecterOps Bloodhound Enterprise configuration?';
  static RemoveModalTile =
    'Remove SpecterOps BloodHound Enterprise configuration?';
  static RemoveSuccessToast = 'SpecterOps BloodHound Enterprise configuration removed successfully.';
  static RemoveFailToast = 'SpecterOps BloodHound Enterprise configuration removal failed.';
  static RemoveModalPrimaryButton = 'YES';
  static RemoveModalSecondaryButton = 'NO';
  static DropdownMenuItemRefresh = 'Refresh';
  static DropdownMenuItemEditAlertPlan = 'Edit Notification Plan';
  static DropdownMenuItemAbout = 'About';
  static StatusConnectionLabel = 'Status';
  static LastConfigurationReceivedLabel = 'Last Configuration Received';
  static NextConfigurationSyncConnectionLabel = 'Next Configuration Synchronization';
  static UpdatingStatusProgressTitle = 'Updating connection status';
  static ConfigurationReceivedTimeSuffix = 'ago';
  static ConfigurationSnycTimePrefix = 'in';
}
