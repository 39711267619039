/* eslint-disable no-empty, @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OdaDropdownItem } from './oda-dropdown-item';

@Component({
  selector: 'oda-dropdown-select',
  templateUrl: './oda-dropdown-select.component.html',
  styleUrls: ['./oda-dropdown-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OdaDropdownSelectComponent),
      multi: true
    }
  ]
})
export class OdaDropdownSelectComponent
  implements AfterViewInit, ControlValueAccessor {
  @Input() noSelectionPrompt: string;
  @Input() noItemsPrompt: string;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  selectedId: string;
  selectedIdBackup: string;
  disabled = false;
  inputNotValid = false;
  inputTitle: string;
  dropdownOpen = false;
  dropdownElem: HTMLElement;
  inputElem: HTMLInputElement;
  selectableElems: HTMLElement[];
  keySelectionStarted = false;
  keyPressBuffer = '';
  keySelectIndex: number;
  lastKeyTime: number;
  numFormatter = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
    useGrouping: false
  });

  private _items: OdaDropdownItem[];
  private _defaultContainerHeight = 2.143;
  private _defaultDropdownHeight = 9.0;
  private _defaultFontSizeRem = 0.95;

  @Input() set dropdownItems(value: OdaDropdownItem[]) {
    this._items = value;
    this.initInputSelection();
  }

  get dropdownItems(): OdaDropdownItem[] {
    return this._items;
  }

  @Input() set containerHeightRem(value: number) {
    if (value) {
      this._defaultContainerHeight = value;
    }
  }

  @Input() set dropdownHeightRem(value: number) {
    if (value) {
      this._defaultDropdownHeight = value;
    }
  }

  @Input() set fontSizeRem(value: number) {
    if (value) {
      this._defaultFontSizeRem = value;
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalMouseClick(event: MouseEvent): void {}

  @HostListener('document:keyup', ['$event'])
  onGlobalKeyboardPress(event: KeyboardEvent): void {}

  get controlContainerStyle(): any {
    return {
      height: `${this._defaultContainerHeight}rem`
    };
  }

  get dropdownBoxStyle(): any {
    return {
      'max-height': `${this._defaultDropdownHeight}rem`
    };
  }

  get itemTexAreaStyle(): any {
    let h = this._defaultContainerHeight * 0.746616;
    let formattedHeight = this.numFormatter.format(h);

    return {
      'font-size': `${this._defaultFontSizeRem}rem`,
      'line-height': `${formattedHeight}rem`
    };
  }

  get itemBoxStyle(): any {
    let h = this._defaultContainerHeight * 0.839944;
    let formattedHeight = this.numFormatter.format(h);

    return {
      'min-height': `${formattedHeight}rem`,
      'line-height': `${formattedHeight}rem`
    };
  }

  get inputStyle(): any {
    let h = this._defaultContainerHeight * 0.933271;
    let formattedHeight = this.numFormatter.format(h);

    return {
      height: `${formattedHeight}rem`,
      'line-height': `${formattedHeight}rem`,
      'font-size': `${this._defaultFontSizeRem}rem`
    };
  }

  propagateChange = (_: any) => {};

  constructor(private elemRef: ElementRef) {}

  // Implementation for ControlValueAccessor
  writeValue(id: string): void {
    this.selectedId = id;
    this.initInputSelection();
  }

  // Implementation for ControlValueAccessor
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Implementation for ControlValueAccessor
  registerOnTouched(fn: any): void {
    // no need to handle touched event
  }

  // Implementation for ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit() {
    this.dropdownElem = this.elemRef.nativeElement.querySelector(
      '.oda-dropdown-container'
    );
    this.inputElem = this.elemRef.nativeElement.querySelector(
      '#oda-dropdown-input'
    );
  }

  initInputSelection() {
    if (this.inputElem) {
      if (!this.dropdownItems || this.dropdownItems.length === 0) {
        if (this.noItemsPrompt) {
          this.inputElem.value = this.noItemsPrompt;
        }
        this.inputNotValid = true;
      } else {
        const matchedSelection = this.dropdownItems.find(
          item => item.id === this.selectedId
        );

        const inputDisplayValue = matchedSelection
          ? matchedSelection.name
          : this.noSelectionPrompt;

        if (inputDisplayValue) {
          this.inputElem.value = inputDisplayValue;
        }

        this.inputNotValid =
          !this.selectedId || inputDisplayValue === this.noSelectionPrompt;
      }

      this.inputTitle =
        this.inputNotValid === true ? undefined : this.inputElem.value;
    }
  }

  openDropdown() {
    if (!this.dropdownOpen) {
      this.dropdownElem.style.display = 'block';
      this.dropdownElem.style.opacity = '1';
      this.dropdownOpen = true;

      if (!this.selectableElems) {
        this.selectableElems = Array.from(
          this.elemRef.nativeElement.querySelectorAll(
            'li.selectable-item'
          ) as NodeListOf<HTMLElement>
        );
      }

      // setup handler to outside click detector
      this.onGlobalMouseClick = (event: MouseEvent): void => {
        if (
          this.dropdownOpen &&
          !this.elemRef.nativeElement.contains(event.target)
        ) {
          // clicked outside => close dropdown list
          this.hideDropdown();
        }
      };

      this.keyPressBuffer = '';
      this.lastKeyTime = 0;
      this.selectedIdBackup = undefined;
      this.keySelectionStarted = false;
      // setup handler for key press
      this.onGlobalKeyboardPress = this.keyPressHandler;

      // scroll to the selected item
      const selectedElem: HTMLLIElement = this.elemRef.nativeElement.querySelector(
        'li.item-selected'
      );

      if (selectedElem) {
        selectedElem.scrollIntoView();
      }
    }
  }

  keyPressHandler(event: KeyboardEvent): void {
    if (this.keySelectionStarted === false) {
      // backup the current selected id
      // unpon cancelling, we can restore to previously selected id
      this.keySelectionStarted = true;
      this.selectedIdBackup = this.selectedId;
      this.keySelectIndex = this._items.findIndex(
        item => item.id === this.selectedId
      );
    }

    if (event.key && event.key.length === 1) {
      // regular keyboard input
      this.processKeyboradCharacterInput(event.key);
    } else {
      // special key for actions
      this.processKeyboardAction(event.key);
    }
  }

  hideDropdown() {
    this.dropdownElem.style.display = 'none';
    this.dropdownElem.style.opacity = '0';
    this.dropdownOpen = false;

    // remove handler to outside click detector
    this.onGlobalMouseClick = (): void => {};

    // remove the handler for key stroke detector
    this.onGlobalKeyboardPress = (): void => {};

    if (this.keySelectionStarted) {
      this.keySelectionStarted = false;
      this.selectedId = this.selectedIdBackup;
      this.selectedIdBackup = undefined;
    }
  }

  onItemClick(item: OdaDropdownItem): void {
    const changeOccurred: boolean =
      (!this.keySelectionStarted && this.selectedId !== item.id) ||
      (this.keySelectionStarted && this.selectedIdBackup !== item.id);

    if (changeOccurred) {
      // only acts on selection changes
      this.slectionChanged(item);
    }
    this.hideDropdown();
  }

  private slectionChanged(item: OdaDropdownItem): void {
    this.inputElem.value = item.name;
    this.inputTitle = item.name;
    this.selectedId = item.id;
    this.propagateChange(this.selectedId);
    this.change.emit(this.selectedId);
  }

  private enterKeyboardSelection(): void {
    const selectedItem = this._items[this.keySelectIndex];
    if (selectedItem) {
      if (selectedItem.id !== this.selectedIdBackup) {
        // the selection was actually changed
        this.slectionChanged(selectedItem);
      }
      this.selectedIdBackup = undefined;
    }
    this.hideDropdown();
  }

  private processKeyboardAction(actionKey: string): void {
    switch (actionKey) {
      case 'ArrowUp': {
        if (this.keySelectIndex > 0) {
          this.keySelectIndex--;
          this.keyboardSelectionMove(this.keySelectIndex);
        }
        break;
      }
      case 'ArrowDown': {
        if (this.keySelectIndex < this._items.length) {
          this.keySelectIndex++;
          this.keyboardSelectionMove(this.keySelectIndex);
        }
        break;
      }
      case 'Escape': {
        this.hideDropdown();
        break;
      }
      case 'Enter': {
        this.enterKeyboardSelection();
        break;
      }
      default: {
        break;
      }
    }
  }

  private processKeyboradCharacterInput(eventKey: string): void {
    const currentKey = eventKey.toLocaleLowerCase().charAt(0);
    const currentTime = Date.now();
    const lastKeyInterval = currentTime - this.lastKeyTime;
    this.lastKeyTime = currentTime;
    if (this.keyPressBuffer.length > 5 || lastKeyInterval > 1000) {
      // keep traking key input if the interval since
      // last key is less than 1000 milli-seconds
      this.keyPressBuffer = '';
    }
    this.keyPressBuffer += currentKey;
    let midx = this._items.findIndex(item =>
      item.name.toLocaleLowerCase().startsWith(this.keyPressBuffer)
    );

    if (midx < 0 && this.keyPressBuffer.length > 1) {
      // if multi-key comob not found
      // reset buffer to current key, try one more time
      this.keyPressBuffer = currentKey;
      midx = this._items.findIndex(item =>
        item.name.toLocaleLowerCase().startsWith(this.keyPressBuffer)
      );
    }

    if (midx >= 0) {
      this.keyboardSelectionMove(midx);
    } else {
      // no matches found
      this.keyPressBuffer = '';
    }
  }

  private keyboardSelectionMove(index: number): void {
    if (this.selectableElems && this.selectableElems.length > index) {
      this.selectedId = this._items[index].id;
      this.keySelectIndex = index;
      this.selectableElems[index].scrollIntoView();
    }
  }
}
