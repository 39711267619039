import { ODChartColor, ODColor } from './oda-colors';

export enum NotificationMode {
  Error = 'error',
  Success = 'success',
}

// common icon names found in the core project:
// and https://icons.dev.xcloud.ninja/#/browse
export enum SvgIconName {
  Error = 'status-error',
  Success = 'status-done',
  ChevronUp = 'chevron-up',
  ChevronDown = 'chevron-down',
  Info = 'info',
  Warning = 'warning',
}

export interface OdaIcon {
  name: SvgIconName;
  defaultColor: ODColor | ODChartColor;
}

// add more default icons here
export class ODADefaultIcons {
  static Info: OdaIcon = {
    name: SvgIconName.Info,
    defaultColor: ODColor.Info_Blue
  };
}

export class AuditConstants {
  static readonly BloodhoundAlertPlanId = '55d3608b-2beb-4a7c-9f56-d627c93a5932';
  static readonly TierZeroAlertPlanName = 'Tier Zero';
  static readonly ModuleName = 'auditing';
}
