import { takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';

import { IModalWindow, BaseComponent } from '@ondemand/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { Query } from '../../../models/query.model';
import { SavedQueryCategory } from '../saved-queries/saved-query-category.model';
import { SavedQueriesService } from '../../../services/saved-queries.service';
import { substituteTemplateValues } from '../../../util/template-substituter';
import { LocaleStringsService } from '../../../services/locale-strings.service';

import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import {
  SEARCH_PERMISSION,
  SearchPermissionsService
} from '../../../services/search-permissions.service';
import { getAllowedQueryTypeAsPerPermissions } from '../allowed-query-type';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';


@Component({
  selector: 'copy-query-modal',
  templateUrl: './copy-query-modal.component.html',
  styleUrls: ['./copy-query-modal.component.scss']
})
export class CopyQueryModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() categories: SavedQueryCategory[];
  @Output() save: EventEmitter<any> = new EventEmitter();

  modalParams: IModalWindow;
  errorMessage: string;
  saving = false;
  formData: any = {};
  auditStrings = AuditingDisplayStringsProvider.auditing;
  isShared = false;
  allowedCategories: SavedQueryCategory[];
  isPrivateSelectionAllowed = true;
  isSharedSelectionAllowed = true;
  defaultSearchTypes: string =
    AuditingDisplayStringsProvider.auditing.privateType;

  searchPermission: string = SEARCH_PERMISSION.NONE;

  @ViewChild('copyForm', {static: true}) form: NgForm;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private savedQueriesService: SavedQueriesService,
    private localeStringsService: LocaleStringsService,
    private searchPermissionsService: SearchPermissionsService,
    private toastService: ODToastService
  ) {
    super();
    // Set up modal for editing columns
    this.localeStringsService.strings$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.modalParams = {
          showModal: false,
          dialogParams: {
            title: this.localeStringsService.get(
              'auditing.pages.savedSearches.copyQueryModalTitle'
            ),
            hideCancel: true,
            cancelButtonAction: () => {
              this.cancelCopy();
            }
          }
        };
      });
  }

  ngOnInit() {
    this.setPermission();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Open modal window
   */
  openModal(query: Query) {
    const copiedQueryName = substituteTemplateValues(
      this.localeStringsService.get(
        'auditing.pages.savedSearches.copySearchTemplate'
      ),
      { searchName: query.name }
    );
    this.formData.queryId = query.id;
    this.formData.name = copiedQueryName;
    this.formData.categoryId = query.categoryId;
    query.isShared = getAllowedQueryTypeAsPerPermissions(
      query.isShared,
      this.searchPermission
    );
    this.formData.isShared = query.isShared;
    this.isShared = query.isShared;
    this.allowedCategories = this.filterCategories(this.categories);
    this.setSharedSelectionAllowed();
    this.errorMessage = null;
    this.modalParams.showModal = true;
    this.modalParams = { ...this.modalParams };
  }

  /**
   * Close modal window
   */
  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = { ...this.modalParams };
  }

  cancelCopy() {
    this.closeModal();
  }

  submitCopy() {
    this.errorMessage = null;

    this.saving = true;
    this.savedQueriesService
      .copySavedQueryWithHttpInfo(
        this.formData.queryId,
        this.formData.name,
        this.formData.categoryId,
        this.isShared
      )
      .toPromise()
      .then(
        response => {
          this.saving = false;
          if (response.status === 201 || response.status === 200) {
            let query = new Query(response.body);
            this.save.emit(query);
            this.closeModal();
            toastLowerLeft(
              this.toastService,
              this.localeStringsService.get('auditing.pages.savedSearches.copySuccessToast'),
              ToastType.Success);
          } else {
            console.error('Bad status code:', response);
          }
        },
        (response: any) => {
          this.saving = false;
          if (response.status === 409) {
            this.errorMessage = this.localeStringsService.get(
              'auditing.pages.newSearches.errorDuplicateName'
            );
          } else {
            this.errorMessage = this.localeStringsService.get(
              'auditing.saveQueryError'
            );
          }
        }
      );
  }

  onToggleButtonsChanged(button: string) {
    this.isShared = button !== this.defaultSearchTypes;
  }

  onValueChange() {
    this.setSharedSelectionAllowed();
    // Set query type to private, when selected category is private
    if (!this.isSharedSelectionAllowed) {
      this.isShared = false;
    }
  }

  private setSharedSelectionAllowed() {
    if (this.searchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED) {
      let matchedCategories = this.categories.filter(
        category => category.id === this.formData.categoryId
      );
      this.isSharedSelectionAllowed =
        matchedCategories !== null ? matchedCategories[0].isShared : false;
    } else if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      this.isPrivateSelectionAllowed = false;
    } else {
      // consider private category
      this.isSharedSelectionAllowed = false;
    }
  }

  private filterCategories(categories: SavedQueryCategory[]) {
    let filteredCategories: SavedQueryCategory[];
    if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      filteredCategories = categories.filter(
        category => category.isShared === true
      );
      return filteredCategories;
    }
    return categories;
  }

  private setPermission() {
    this.searchPermission = this.searchPermissionsService.getManageSearchPermission();
  }
}
