import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { OnDemandTokenService, OnDemandToken } from '@ondemand/core';
import * as auditPermissions from '../models/audit-permissions.model';
import { filter, takeUntil } from 'rxjs/operators';

export const AllCoreContextSensitivePermissions = 'core.*';
export const CoreExportData = 'core.exportData';

@Injectable()
export class AuditModulePermissionsService implements OnDestroy {
  ngUnsubscribe: Subject<any> = new Subject<any>();
  token: OnDemandToken;
  usersPermissions: auditPermissions.AuditPermission[] = [];
  private featureFlagGuardedPermissions: auditPermissions.AuditPermission[] = [];

  constructor(
    tokenService: OnDemandTokenService) {
    tokenService.token$
    .pipe(
      takeUntil(this.ngUnsubscribe),
      filter(x => x !== null && x !== undefined)
    ).subscribe(token => {
        this.token = token;
        this.usersPermissions = token.effectivePermissions as auditPermissions.AuditPermission[];
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Takes an array of permissions to check against. If a user has any
   * permissions included in this array, the method will return true.
   *
   * @param requiredPermissions The permissions to check against.
   */
  hasAnyOfPermissions(
    requiredPermissions: auditPermissions.AuditPermission[]
  ): boolean {
    requiredPermissions = this.ensureSingularPermissionIsArray(
      requiredPermissions
    );

    if (this.isArrayEmpty(requiredPermissions)) {
      return false;
    }

    const mergedRequiredPermissions = [
      ...requiredPermissions,
      ...auditPermissions.auditAdminPermissions
    ];

    return this.usersPermissions.some(
      (permission: auditPermissions.AuditPermission) =>
        mergedRequiredPermissions.includes(permission)
    );
  }

  /**
   * Takes an array of permissions to check against. If a user has all
   * permissions included in this array, the method will return true.
   *
   * @param requiredPermissions The permissions to check against.
   */
  hasAllOfPermissions(
    requiredPermissions: auditPermissions.AuditPermission[]
  ): boolean {
    requiredPermissions = this.ensureSingularPermissionIsArray(
      requiredPermissions
    );

    if (this.isArrayEmpty(requiredPermissions)) {
      return false;
    }

    return requiredPermissions.every(
      (permission: auditPermissions.AuditPermission) =>
      this.usersPermissions.includes(permission)
    ) || auditPermissions.auditAdminPermissions.some(
      (permission: auditPermissions.AuditPermission) =>
        this.usersPermissions.includes(permission)
    );
  }

  /**
   * Takes an permission to check against. If a user has
   * permissions included in this request, the method will return true.
   *
   * @param permissionName The permission to check against. default value is 'core.exportData'.
   */
  hasAuditForCoreContextSensitivePermission(
    permissionName: string = CoreExportData
  ) {
    const contextSensitivePermission = this.usersPermissions.find(
      (permission) => permission.startsWith(permissionName)
    );

    if (contextSensitivePermission !== undefined) {
      return (
      // Should find (for example)
      //    `core.exportData[auditing]`
      //    `core.exportData[auditing, rmaz]`
      //    `core.exportData[auditing, rmaz, gm]`
      contextSensitivePermission.indexOf('auditing') > 0 ||
      // Should find
      //   `core.exportData[*]`
      contextSensitivePermission.indexOf('*') > 0
      );
    } else {
      // Should find
      //   `core.*`
      return this.usersPermissions.some(
        (permission: auditPermissions.AuditPermission) =>
        AllCoreContextSensitivePermissions === permission
      );
    }
  }

  // This check seems like it should not be necessary. However, the
  // templates that use the directive can get away with passing in a
  // single permission instead of the expected array. The tooling does
  // not stop it.
  private ensureSingularPermissionIsArray(
    requiredPermissions: auditPermissions.AuditPermission[]
  ) {
    if (
      typeof requiredPermissions === 'string' &&
      (requiredPermissions as string).length > 0
    ) {
      requiredPermissions = [
        requiredPermissions as auditPermissions.AuditPermission
      ];
    }
    return requiredPermissions;
  }

  private isArrayEmpty(value: any[]) {
    return value === null || value === undefined || value.length === 0;
  }
}
