<general-qod-component
  [loading]="dataLoading$ | async"
  [error]="dataError$ | async"
  [data]="data$ | async"
  [height]="cardHeight"
  [card]="true"
>
  <div *ngIf="dataloaded" component-header class="status-info">
    <div component-header>
      <div class="tooltip">
        <div class="infoPosition">
          <edc-icon size="small" name="info"></edc-icon>
        </div>
        <div class="tooltiptext">
          {{
            'auditing.pages.auditingDashboard.auditHealth.tooltipMessage'
              | localize
          }}
          <br />
          <br />
          <a
            href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide"
            target="_blank"
          >
            Click Here
          </a>
          {{
            'auditing.pages.auditingDashboard.auditHealth.toLearnMore' | localize
          }}
        </div>
      </div>
    </div>
  </div>

  <h4 component-header>
    {{ 'auditing.pages.auditingDashboard.auditHealth.title' | localize }}
  </h4>

  <skeleton-block component-loading type="TitleWithLineGraph"></skeleton-block>

  <div component-error>
    <error-card
      error="{{ 'auditing.pages.auditingDashboard.error' | localize }}"
      button="{{ 'auditing.pages.auditingDashboard.tryAgain' | localize }}"
      (buttonClick)="loadAuditHealth()"
    >
    </error-card>
  </div>

  <div component-data>
    <div *ngIf="!(totalDataLength > 0)">
      <div class="status-done-success icon-container">
        <edc-icon size="5x" name="status-done"></edc-icon>
      </div>
      <h2 class="no-data">
        {{
          'auditing.pages.auditingDashboard.auditHealth.noIssuesTitle'
            | localize
        }}
      </h2>
      <h4 class="no-data-detail">
        {{
          'auditing.pages.auditingDashboard.auditHealth.noIssuesSubtitle'
            | localize
        }}
      </h4>
    </div>

    <ng-container *ngFor="let row of currentPageCardData">
      <div class="icon-action-container">
        <status-icon
          [listID]="row.notificationId"
          [description]="row.text"
          [state]="getIconState(row.type)"
          [multiLineDescription]="true"
          [additionalActions]="row.actionNames"
          (action)="row.triggerSpecificAction($event)"
        >
        </status-icon>
      </div>
    </ng-container>
  </div>

  <div component-footer *ngIf="dataloaded">
    <div class="hidden-label" *ngIf="!(this.hiddenItemsLength === 0)">
      <div class="reset-items-click">
        <span (click)="resetHiddenItems()">{{
          'auditing.pages.auditingDashboard.auditHealth.resetHiddenItems'
            | localize
        }}</span>
      </div>
      <div class="filter-count">
        <span>
          {{ this.hiddenItemsLength }}
        </span>
      </div>
    </div>
    <result-pagination
      class="right pages"
      *ngIf="
        totalDataLength > resultsWithoutPagination ||
        (totalDataLength === resultsWithoutPagination &&
          this.hiddenItemsLength > 0)
      "
      [includeCountView]="false"
      [totalRows]="totalDataLength || 0"
      [currentPage]="currentPage"
      [resultsPerPage]="resultsPerPage"
      [showButtons]="true"
      [pageOptionsShown]="2"
      (pageChange)="onPageChange($event)"
    >
    </result-pagination>
  </div>
</general-qod-component>

<od-notification-plan-edit *ngIf="alertPlanEditorEnabled"
  [notificationPlanId]="alertPlanEditorPlanId"
  [ownerModule]="alertPlanEditorOwnerModule"
  [isOpen]="alertPlanEditorIsOpen"
  [displayPlanType]="alertPlanEditorShowPlanType"
  (whenClosed)="alertPlanEditorClosed()"
  ></od-notification-plan-edit>
