/**
 * Component for specifying a true/false value
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgForm, Validator, UntypedFormControl } from '@angular/forms';
import { Component, Input, EventEmitter, Output, forwardRef, ViewChild } from '@angular/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { BaseComponent } from '@ondemand/core';
import { takeUntil } from 'rxjs/operators';

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BooleanFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => BooleanFieldComponent),
    multi: true
  }
  ],
  selector: 'boolean-field',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.scss']
})
export class BooleanFieldComponent extends BaseComponent implements ControlValueAccessor, Validator {
  @Input() index: number;
  @Input() operator: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f') form: NgForm;
  onChange: any;
  value: boolean = null;
  menuOptions: any[] = [];
  selectedOption: any;

  constructor(private localeStringsService: LocaleStringsService) {
    super();
    this.localeStringsService.strings$.pipe(takeUntil(this.destructionSubject)).subscribe(() => {
      this.menuOptions = [
        {
          label: this.localeStringsService.get('auditing.pages.newSearches.booleanFalse'),
          value: false
        }, {
          label: this.localeStringsService.get('auditing.pages.newSearches.booleanTrue'),
          value: true
        }
      ];
      this.setSelection();
    });
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: boolean): void {
    if ([true, false].includes(value)) {
      this.value = value;
      this.setSelection();
      this.onChange();
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      if (this.selectedOption) {
        this.value = this.selectedOption.value;
      } else {
        this.value =  null;
      }
      fn(this.value);
      this.change.emit();
    };
    this.onChange();
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Validate whether any values have been entered
   *
   * @param _control Reference to this control
   */
  validate(_control: UntypedFormControl): any {
    if ([true, false].includes(this.value)) {
      return null;
    } else {
      return {
        error: 'Invalid value selection'
      };
    }
  }

  setSelection() {
    if (this.value !== null && this.menuOptions.length > 0) {
      this.selectedOption = this.menuOptions.find(option => option.value === this.value);
      this.onChange();
    }
  }

}
