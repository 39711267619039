/*
 * widget FavoriteSearches
 */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ondemand/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuditingDashboardResponse } from '../../models/auditing-dashboard.models';
import { FavoriteSearchEditorComponent } from './editor/favorite-search-editor.component';
import {
  FavoriteSearchConfiguration,
  FavoriteSearchResponse,
  FavoriteSearchesCategory,
  FavoriteSearchesQuery
} from './models/favorite-search.models';
import { LoadFavoriteSearch } from './state/favorite-search.actions';
import { FavoriteSearchState } from './state/favorite-search.reducer';
import {
  selectFavoriteSearch,
  selectFavoriteSearchError,
  selectFavoriteSearchLoading
} from './state/favorite-search.selectors';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import * as SearchPermissions from '../../../../models/audit-permissions.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'favorite-search-widget',
  templateUrl: './favorite-search.component.html',
  styleUrls: ['./favorite-search.component.scss']
})
export class FavoriteSearchComponent extends BaseComponent implements OnInit {
  @Input() refreshObservable$: Observable<void>;

  @ViewChild(FavoriteSearchEditorComponent)
  editorModal: FavoriteSearchEditorComponent;

  dataloaded = false;
  dataLoading$ = this.store.select(selectFavoriteSearchLoading);
  dataError$ = this.store.select(selectFavoriteSearchError);
  data$ = this.store.select(selectFavoriteSearch);
  cardData$ = this.data$.pipe(
    filter(response => !!response),
    map(({ data: myFavoriteSearches }) => myFavoriteSearches),
    map(({ myFavoriteSearches: { datasets } }) => datasets),
    map(datasets => {
      this.dataloaded = true;
      return datasets.map(dataset => ({
        ...dataset,
        action: () => this.viewSearchResults(dataset.queryId)
      }));
    })
  );

  selectedSearches$ = this.data$.pipe(
    filter(x => !!x),
    map(x => x.configuration.myFavoriteSearches.selectedSearches)
  );

  availableCategories$ = this.data$.pipe(
    filter(x => !!x),
    map(x => x.configuration.myFavoriteSearches.availableCategories)
  );

  readonly cardHeight = '21rem';

  constructor(
    private store: Store<FavoriteSearchState>,
    private router: Router,
    private permissionsService: AuditModulePermissionsService
  ) {
    super();
  }

  ngOnInit() {
    this.loadFavoriteSearches();
    this.listenToRefresh();
  }

  loadFavoriteSearches() {
    this.store.dispatch(new LoadFavoriteSearch());
  }

  viewSearchResults(queryId: string) {
    let route = `auditing/auditing/queries/results/${queryId}`;
    this.router.navigate([route]);
  }

  handleEditClick() {
    this.editorModal.openModal();
  }

  onEditorSave() {
    this.loadFavoriteSearches();
  }

  disableEditSearchesButton(): boolean {
    let hasPermissionToEdit: boolean =
      this.canRunAndViewPrivateSearches() || this.canRunAndViewSharedSearches();

    // need to return null to remove the disabled attribute from the element
    return hasPermissionToEdit && this.dataloaded ? null : true;
  }

  private listenToRefresh() {
    this.refreshObservable$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe((_) => this.loadFavoriteSearches());
  }

  private canRunAndViewPrivateSearches(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunPrivateSearch
    ]);
  }

  private canRunAndViewSharedSearches(): boolean {
    let canRunSharedSearch = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunSharedSearches
    ]);
    let canViewSharedSearch = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canViewSharedSearches
    ]);
    return canRunSharedSearch && canViewSharedSearch;
  }
}
