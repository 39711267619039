<modal-window [params]="modalParams" *ngIf="modalParams">
  <div modal-content class="editor-content-box">
    <p class="editor-description">{{ 'auditing.pages.criticalActivity.hiddenItemsEditor.description' | localize }}</p>
    <form #editHiddenItemsForm="ngForm">
      <div class="hidden-list-name">{{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.listNamePrefix' | localize }} {{hiddenItemsCountLabel}}</div>
      <div class="fixed-size-shell">
        <div class="hidden-list-header-schema hidden-list-item">
          <label class="custom-checkbox-container">
            <input type="checkbox" #selectAll [checked]="selectAll" (change)="onSelectAllChanged($event)">
            <span class="checkbox-custom"></span>
          </label>
          <div class="hidden-list-description">{{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.header.name' | localize }}</div>
          <div class="hidden-list-value" #eventCountHeader>{{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.header.valueLabel' | localize }}</div>
        </div>

        <ul class="hidden-items-box">
          <li *ngIf="hiddenItemCount < 1">
            <p class="no-items-message">{{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.noItemsMessage' | localize }}</p>
          </li>

          <li *ngFor="let hiddenItem of hiddenItemsRows">
            <div class="hidden-list-item">
                  <label class="custom-checkbox-container">
                    <input type="checkbox" [checked]="hiddenItem.selected" (change)="onCheckChanged($event, hiddenItem)">
                    <span class="checkbox-custom"></span>
                  </label>
                  <div class="hidden-list-description">{{hiddenItem.label}}</div>
                  <div class="hidden-list-value">{{hiddenItem.data}}</div>
            </div>
          </li>
        </ul>

        <div class="remove-items-btn-row">
          <button id="hidden-item-editor-remove-btn" class="btn" type="button" [disabled]="selectedItemCount < 1" (click)="onRemoveClicked()">
            {{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.removeButtonLabelPrefix' | localize }}
            {{selectedItemsCountLabel}}
          </button>
        </div>
      </div>

      <div class="action-buttons-row">
        <button id="hidden-item-editor-cancel-btn" class="btn-secondary" type="button" (click)="closeModal()">
          {{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.cancelButtonLabel' | localize }}
        </button>
        <button id="hidden-item-editor-save-btn" class="btn" type="button" [disabled]="!editHiddenItemsForm.dirty" (click)="onSaveClicked()">
          {{ 'auditing.pages.criticalActivity.hiddenItemsEditor.hiddenList.saveButtonLabel' | localize }}
        </button>
      </div>
    </form>
  </div>
</modal-window>
