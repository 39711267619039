/**
 * Audit Subscription Service for On Demand Auditing.
 */
import { switchMap, take,  map, shareReplay, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnDemandTokenHttp, IModuleSubscription, HttpRequestMethod } from '@ondemand/core';
import { ServiceDiscoveryService } from '../service-discovery.service';
import { defaultHttpRequestTimeout } from '../../util/constants';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class AuditSubscriptionService {
  private static cacheTimeToLive = 5000;

  private _auditConfigCallCount = 0;
  private cachedSubscription$: Observable<IModuleSubscription>;
  private defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = { withCredentials: false };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {}

  set cacheTimeToLive(value: number) {
    AuditSubscriptionService.cacheTimeToLive = value;
  }

  get auditConfigCallCount(): number {
    return this._auditConfigCallCount;
  }

  /**
   * Get the subscription from audit hub serivce
   * if a cached copy is alive, use it
   * otherwise, make a new request to audit hub
   */
  public getSubscription$(): Observable<IModuleSubscription> {
    if (!this.cachedSubscription$) {
      // no previous access or cache expired
      this.cachedSubscription$ = this.getSubscriptionFromAuditConfig();

      // remove the cached copy after cache expiry
      setTimeout(() => {
        this.cachedSubscription$ = null;
      }, AuditSubscriptionService.cacheTimeToLive);
    }

    return this.cachedSubscription$;
  }

  /**
   * getSubscriptionFromAuditHub
   * get the ModuleSubscription from audit hub service
   * share this subscription among multiple callers
   */
  private getSubscriptionFromAuditConfig(): Observable<IModuleSubscription> {
    return this.getSubscriptiontWithHttpInfo().pipe(
      map((response) => {
        if (response.status !== 200) {
          return undefined;
        } else {
          return response.body as IModuleSubscription;
        }
      }),
      shareReplay(1, AuditSubscriptionService.cacheTimeToLive)
    );
  }

  /**
   * Get subscription from audit hub
   */
  private getSubscriptiontWithHttpInfo(): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService
      .getAuditConfigUrl$().pipe(
      take(1),
      switchMap(baseUri => {
        const requestUrl = `${baseUri}/subscriptions`;
        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        this._auditConfigCallCount++;

        return this.http
          .requestHttp(HttpRequestMethod.GET, requestUrl, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      }));
  }

  private getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return headers;
  }
}
