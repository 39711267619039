import {
  DataType,
  HEADER_HEIGHT,
  IInputConfig,
  IPropertyConfig,
  OperatorType,
  QueryClause,
  QueryFilterModel,
  QueryRuleGroup,
  ROW_HEIGHT,
  ValueComponentType
} from '@ondemand/core';

import { ColDef, GridOptions } from 'ag-grid-community';
import { CrtiticalLevelOperators, NumberOperators, StringOperators } from '../../../../../shared/models/query-clause-operator-groups';
import { CriticalLevels } from '../../../../../shared/models/critical-activity.shared.models';

export const CriticalActivityDataTimeOut = 300000;

export const ColumnHeaders = {
  priorityName: 'Priority',
  criticalActivityName: 'Critical Activity',
  eventsName: 'Events'
};

//
// AG-Grid Config
//

// Priority column & Critical Activity Description column
// the width is 644 pixels
// 159 + 485 = 644
// In most cases, 1 rem = 14 pixels
// 644 pixel is 46rem, which corresponds to the sytles in the .scss
// We intend to make these 2 columns fixed size, so the .scss stylesheet
// has to adjust to ensure the flyout column fits nicely.

const priorityLevelColumnDefition: ColDef = {
  headerName: ColumnHeaders.priorityName,
  field: 'priorityLevel',
  comparator: (valueA, valueB, nodeA, nodeB, isInverted) =>
    valueB.severity - valueA.severity,
  cellRenderer: ({ value }: any) => `<div style="display:flex;">
        <img class="icon-img" style="width:1.5rem;" src="${value.iconPath}" alt="icon">
        <div style="margin-left:1rem;">${value.displayLabel}</div>
        </div>`,
  sortable: true,
  width: 159,
  suppressSizeToFit: true,
  cellStyle: { cursor: 'pointer' }
};

const criticalActivityDescriptionColumnDefinition: ColDef = {
  headerName: ColumnHeaders.criticalActivityName,
  field: 'description',
  sortable: true,
  width: 485,
  minWidth: 485,
  maxWidth: 485,
  cellStyle: { cursor: 'pointer' },
  resizable: true
};

export const eventCountColumnDefinition: ColDef = {
  headerName: ColumnHeaders.eventsName,
  field: 'eventCount',
  comparator: (valueA, valueB, nodeA, nodeB, isInverted) =>
    +valueA.replace(',', '') - +valueB.replace(',', ''),
  sortable: true,
  minWidth: 120,
  cellStyle: { cursor: 'pointer' }
};

export const CRITICAL_ACTIVITY_LIST_GRID_OPTIONS: GridOptions = {
  headerHeight: HEADER_HEIGHT - 5,
  rowHeight: ROW_HEIGHT - 5,
  suppressRowTransform: true,
  suppressHorizontalScroll: true,
  rowSelection: 'single'
};

export const CRITICAL_ACTIVITY_LIST_DEFAULT_COLUMN_DEFINITION: ColDef = {
  resizable: true
};

export const CRITICAL_ACTIVITY_LIST_COLUMN_DEFINITIONS: ColDef[] = [
  priorityLevelColumnDefition,
  criticalActivityDescriptionColumnDefinition,
  eventCountColumnDefinition
];

//
// Filter Editor Config
//

export const criticalLevelFilterPropertyConfig: IPropertyConfig = {
  fieldConfig: {
    name: ColumnHeaders.priorityName,
    displayName: ColumnHeaders.priorityName,
    type: DataType.String
  },
  operatorsConfig: {
    customizedOperators: CrtiticalLevelOperators
  },
  valueConfig: {
    valueComponentType: ValueComponentType.FixedOptionalValues,
    optionalValuesConfig: {
      fixedValues: [
        {
          value: CriticalLevels.Critical,
          displayName: CriticalLevels.Critical
        },
        { value: CriticalLevels.High, displayName: CriticalLevels.High },
        { value: CriticalLevels.Medium, displayName: CriticalLevels.Medium }
      ]
    }
  }
};

export const criticalActivityFilterPropertyConfig: IPropertyConfig = {
  fieldConfig: {
    name: ColumnHeaders.criticalActivityName,
    displayName: ColumnHeaders.criticalActivityName,
    type: DataType.String
  },
  operatorsConfig: {
    customizedOperators: StringOperators
  },
  valueConfig: {
    valueComponentType: ValueComponentType.PlainText
  }
};

export const eventCountFilterPropertyConfig: IPropertyConfig = {
  fieldConfig: {
    name: ColumnHeaders.eventsName,
    displayName: 'Event Count',
    type: DataType.String
  },
  operatorsConfig: {
    customizedOperators: NumberOperators
  },
  valueConfig: {
    valueComponentType: ValueComponentType.PlainText
  }
};

function createDefaultFilterModel(
  clauseValue: string = null
): QueryFilterModel {
  if (!clauseValue) {
    return null;
  }

  const queryClause = new QueryClause();
  queryClause.availableOperators = StringOperators;
  queryClause.propertyConfig = criticalActivityFilterPropertyConfig;
  queryClause.field = ColumnHeaders.criticalActivityName;
  queryClause.operator = OperatorType.Equals;
  queryClause.value = { value: clauseValue };
  queryClause.valueComponentState = clauseValue;
  queryClause.hasError = false;

  const ruleGroup = new QueryRuleGroup();
  ruleGroup.clauses = [queryClause];

  const filterModel = new QueryFilterModel();
  filterModel.ruleGroups = [ruleGroup];

  return filterModel;
}

export function getFilterInputConfig(routedParam: string = null): IInputConfig {
  return {
    enableRuleGroup: false,
    enableQueryLogicForRuleGroup: false,
    enableRuleOperatorOr: false,
    defaultFiltersModel: createDefaultFilterModel(routedParam),
    defaultFiltersDelayTimeToTriggerEvent: 0,
    optionalPropertiesConfig: [
      criticalLevelFilterPropertyConfig,
      criticalActivityFilterPropertyConfig,
      eventCountFilterPropertyConfig
    ]
  };
}
