/**
 * Service for managing charts
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout, take } from 'rxjs/operators';

import { OnDemandTokenHttp, HttpRequestMethod } from '@ondemand/core';
import { ServiceDiscoveryService } from '../../../../auditing/services/service-discovery.service';
import { defaultHttpRequestTimeout } from '../../../../auditing/util/constants';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Results } from '../../../../auditing/components/queries/results/results.model';
import { Query } from '../../../../auditing/models/query.model';

@Injectable()
export class ChartQueryService {
  protected basePath: string;

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.serviceDiscoveryService
      .getChartUrl$()
      .pipe(take(1))
      .subscribe(baseUrl => {
        this.basePath = baseUrl;
      });
  }

  runSavedChartQuery(
    queryId: string,
    chartId: string
  ): Observable<HttpResponse<Results>> {
    const path = `${this.basePath}/${queryId}/${chartId}`;
    const headers = new HttpHeaders();
    const requestOptions = {
      headers,
      withCredentials: false,
      observe: 'response'
    };

    return this.http
      .requestHttp(HttpRequestMethod.POST, path, requestOptions)
      .pipe(timeout(defaultHttpRequestTimeout));
  }

  runAdhocChartQuery(
    query: Query,
    chartId: string
  ): Observable<HttpResponse<Results>> {
    const path = `${this.basePath}/${chartId}`;
    const headers = new HttpHeaders();

    const requestOptions = {
      headers,
      body: query.q,
      withCredentials: false,
      observe: 'response'
    };

    return this.http
      .requestHttp(HttpRequestMethod.POST, path, requestOptions)
      .pipe(timeout(defaultHttpRequestTimeout));
  }
}
