export type AuditPermission = string;

// Admin permissions
export const auditAdminPermission: AuditPermission = 'auditing.*';

export const auditAdminPermissions: AuditPermission[] = [
  auditAdminPermission
];

// Search permissions
export const canViewSharedSearches: AuditPermission =
  'auditing.viewSharedSearch';
export const canRunSharedSearches: AuditPermission = 'auditing.runSharedSearch';
export const canRunPrivateSearch: AuditPermission = 'auditing.runPrivateSearch';
export const canManagePrivateSearch: AuditPermission =
  'auditing.managePrivateSearch';
export const runQuickSearches: AuditPermission = 'auditing.runQuickSearch';
export const canManageSharedSearches: AuditPermission =
  'auditing.manageSharedSearches';

export const canViewEventRetentionSettings: AuditPermission =
  'auditing.viewEventRetention';
export const canViewEventDetails: AuditPermission = 'auditing.viewEventDetails';

// Manage config permissions
export const canManageAzureADTenants: AuditPermission =
  'auditing.manageTenantConfig';
export const canManageChangeAuditor: AuditPermission =
  'auditing.manageCAConfig';

// Manage Alerts and AlertPlans
export const canManageSharedAlertsAndAlertPlans: AuditPermission = 'auditing.manageSharedAlertsAndAlertPlans';
export const canManagePrivateAlertsAndAlertPlans: AuditPermission = 'auditing.managePrivateAlertsAndAlertPlans';

// Visualization permissions
export const canViewDashboard: AuditPermission = 'auditing.viewDashboard';
export const canRunSearchVisual: AuditPermission = 'auditing.runSearchVisual';

// Threat permission
export const canManageThreat: AuditPermission = 'auditing.configureThreat';

// BloodHound Permissions
export const canManageSpectorOpsBloodHoundConfiguration: AuditPermission = 'auditing.manageBHEConfig';
