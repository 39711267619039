<modal-window #saveModal [params]="modalParams" *ngIf="formData && query && categories">
  <div modal-content class="save-modal">

    <error-message *ngIf="errorMessage">{{errorMessage}}</error-message>

    <form #saveAsForm="ngForm" (ngSubmit)="submitSave()">

      <div class="input-field">
        <input id="saveName" type="text" name="saveName"
          [(ngModel)]="formData.name" autocomplete="off" required #queryName="ngModel">
          <label for="saveName">{{pageLabels.saveSearchNamePrompt}}</label>
      </div>

      <error-message
        class="empty-name-error"
        *ngIf="queryName.invalid">{{pageLabels.errorEmptyName}}</error-message>

      <div class="input-field">
          <select
            class="browser-default"
            name="saveCategory"
            id="saveCategory"
            (change)="onValueChange()"
            [(ngModel)]="formData.categoryId"
            required
            #categoryMenu="ngModel"
          >
            <option *ngIf="categories.length === 0" disabled selected value="">{{ auditStrings.loading }}</option>
            <option [value]="category.id" *ngFor="let category of allowedCategories">{{ category.name }}</option>
          </select>
        <label for="saveCategory">{{pageLabels.saveSearchCategoryPrompt}}</label>
      </div>

      <private-shared-type-selector
        [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
        [isSharedSelectionAllowed]="isSharedSelectionAllowed"
        [isSharedItemSelected]="isShared"
        (buttonSelected)="onToggleButtonsChanged($event)"
      ></private-shared-type-selector>

      <div class="action-button-container-outer">
        <div class="action-buttons right">          
          <button
            type="button"
            class="btn submit-save-button"
            [disabled]="!saveAsForm.valid || saving"
            (click)="submitSave()"
          >
            {{pageLabels.submitSaveAsButton}}
          </button>
          <button
          type="button"
          class="btn-flat cancel-save-button"
          data-test="submit-save"
          (click)="cancelSave()"
        >{{pageLabels.cancelSaveAsButton}}</button>
        </div>
      </div>
    </form>
  </div>
</modal-window>
