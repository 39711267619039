import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuditModulePermissionsService } from '../services/audit-module-permissions.service';
import * as fromPermissions from '../models/audit-permissions.model';

const auditingBaseUrlSegments = ['auditing', 'auditing'];
const savedQueriesUrlSegments = ['queries', 'saved'];
const dashboardUrlSegments = ['dashboard'];
const criticalActivityUrlSegments = ['criticalActivity'];
const alertsUrlSegments = ['alerts'];
const retentionUrlSegments = ['retention'];
const configUrlSegments = ['config'];
const quickSearchUrlSegments = ['queries', 'quickresults'];

const savedQueriesUrl = [
  ...auditingBaseUrlSegments,
  ...savedQueriesUrlSegments
];

interface PathPermissions {
  path: string;
  requiredPermissions: fromPermissions.AuditPermission[];
}

interface UrlMap {
  [key: string]: PathPermissions;
}

const urlMap: UrlMap = {
  alerts: {
    path: '/alerts',
    requiredPermissions: [
      fromPermissions.canManageSharedAlertsAndAlertPlans,
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]
  },
  alertPlans: {
    path: '/alerts/plans',
    requiredPermissions: [
      fromPermissions.canManageSharedAlertsAndAlertPlans,
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]
  },
  retention: {
    path: '/retention',
    requiredPermissions: [fromPermissions.canViewEventRetentionSettings]
  },
  searchResults: {
    path: '/queries/results',
    requiredPermissions: [
      fromPermissions.canRunPrivateSearch,
      fromPermissions.canRunSharedSearches
    ]
  },
  config: {
    path: 'auditing/config',
    requiredPermissions: [
      fromPermissions.canManageAzureADTenants,
      fromPermissions.canManageChangeAuditor
    ]
  },
  dashboard: {
    path: '/dashboard',
    requiredPermissions: [fromPermissions.canViewDashboard]
  },
  criticalActivity: {
    path: '/criticalActivity',
    requiredPermissions: [
      fromPermissions.canRunSearchVisual,
      fromPermissions.canRunSharedSearches
    ]
  },
  eventDetails: {
    path: '/auditing/events',
    requiredPermissions: [fromPermissions.canViewEventDetails]
  },
  caSetup: {
    path: '/auditing/ca-setup',
    requiredPermissions: [fromPermissions.canManageChangeAuditor]
  },
  provision: {
    path: '/auditing/provision',
    requiredPermissions: [
      fromPermissions.canManageChangeAuditor,
      fromPermissions.canManageAzureADTenants
    ]
  },
  searchEditor: {
    path: '/auditing/queries/editor',
    requiredPermissions: [
      fromPermissions.canManagePrivateSearch,
      fromPermissions.canManageSharedSearches
    ]
  },
  savedSearches: {
    path: '/auditing/queries/saved',
    requiredPermissions: [
      fromPermissions.canManagePrivateSearch,
      fromPermissions.canManageSharedSearches,
      fromPermissions.canRunPrivateSearch,
      fromPermissions.canViewSharedSearches
    ]
  },
  quickSearch: {
    path: 'queries/quickresults',
    requiredPermissions: [fromPermissions.runQuickSearches]
  }
};

@Injectable()
export class HasPermissionsGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionsService: AuditModulePermissionsService,
    private router: Router
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.validateRoute(state);
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.validateRoute(state);
  }

  private validateRoute(
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (state.url.endsWith(urlMap.alerts.path)) {
      return this.validateAlertsAccess();
    }

    if (state.url.endsWith(urlMap.alertPlans.path)) {
      return this.validateAlertPlansAccess();
    }

    if (state.url.endsWith(urlMap.retention.path)) {
      return this.basicValidation(urlMap.retention);
    }

    if (state.url.indexOf(urlMap.config.path) > 0) {
      return this.basicValidation(urlMap.config);
    }

    if (state.url.endsWith(urlMap.criticalActivity.path)) {
      return this.validateCriticalActivityTabAccess();
    }

    if (state.url.endsWith(urlMap.dashboard.path)) {
      return this.basicValidation(urlMap.dashboard);
    }

    if (state.url.indexOf(urlMap.eventDetails.path) > 0) {
      return this.basicValidation(urlMap.eventDetails);
    }

    if (state.url.indexOf(urlMap.caSetup.path) > 0) {
      return this.validateCASetUpAccess();
    }

    if (state.url.indexOf(urlMap.provision.path) > 0) {
      return this.validateProvisionAccess();
    }

    if (state.url.indexOf(urlMap.searchEditor.path) > 0) {
      return this.basicValidation(urlMap.searchEditor);
    }

    if (state.url.endsWith(urlMap.savedSearches.path)) {
      return this.validateSavedSearchesAccess();
    }

    if (state.url.indexOf(urlMap.quickSearch.path) > 0) {
      return this.basicValidation(urlMap.quickSearch);
    }

    return true;
  }

  /**
   * Checks if a user has access to Saved Searches. If they user does not
   * have access, this method will determine where the user will go.
   * Other pages will redirect to saved searches, so this method will
   * make the final determination of where a user can go.
   */
  private async validateSavedSearchesAccess() {
    const hasPermissions = this.permissionsService.hasAnyOfPermissions(
      urlMap.savedSearches.requiredPermissions
    );

    if (hasPermissions) {
      return hasPermissions;
    }

    const hasDashboardAccess = this.permissionsService.hasAnyOfPermissions(
      urlMap.dashboard.requiredPermissions
    );

    if (hasDashboardAccess) {
      this.navigateToDashboard();
      return hasPermissions;
    }

    const hasCriticalActivityTabAccess =
      this.permissionsService.hasAllOfPermissions(
        urlMap.criticalActivity.requiredPermissions
      );

    if (hasCriticalActivityTabAccess) {
      this.navigateToCriticalActivity();
      return hasPermissions;
    } else if (hasDashboardAccess) {
      this.navigateToDashboard();
      return hasPermissions;
    }

    const hasAlertsAccess = this.permissionsService.hasAnyOfPermissions(
      urlMap.alerts.requiredPermissions
    );
    if (hasAlertsAccess) {
      this.navigateToAlerts();
      return hasPermissions;
    }

    const hasConfigurationAccess = this.permissionsService.hasAnyOfPermissions(
      urlMap.config.requiredPermissions
    );
    if (hasConfigurationAccess) {
      this.navigateToConfig();
      return hasPermissions;
    }

    const hasRetentionAccess = this.permissionsService.hasAnyOfPermissions(
      urlMap.retention.requiredPermissions
    );
    if (hasRetentionAccess) {
      this.navigateToRetention();
      return hasPermissions;
    }

    const hasQuickSearchAccess = this.permissionsService.hasAnyOfPermissions([
      fromPermissions.runQuickSearches
    ]);
    if (hasQuickSearchAccess) {
      this.navigateToQuickSearch();
      return hasPermissions;
    }

    this.navigateToAccessDeniedPage();
    return hasPermissions;
  }

  private navigateToAccessDeniedPage() {
    this.router.navigate(['accessDenied']);
  }

  private navigateToQuickSearch() {
    this.router.navigate([
      ...auditingBaseUrlSegments,
      ...quickSearchUrlSegments
    ]);
  }

  private navigateToRetention() {
    this.router.navigate([...auditingBaseUrlSegments, ...retentionUrlSegments]);
  }

  private navigateToConfig() {
    this.router.navigate([...auditingBaseUrlSegments, ...configUrlSegments]);
  }

  private navigateToAlerts() {
    this.router.navigate([...auditingBaseUrlSegments, ...alertsUrlSegments]);
  }

  private navigateToDashboard() {
    this.router.navigate([...auditingBaseUrlSegments, ...dashboardUrlSegments]);
  }

  private navigateToCriticalActivity() {
    this.router.navigate([
      ...auditingBaseUrlSegments,
      ...criticalActivityUrlSegments
    ]);
  }

  private basicValidation(pathPermissions: PathPermissions): boolean {
    const hasPermission = this.permissionsService.hasAnyOfPermissions(
      pathPermissions.requiredPermissions
    );

    if (!hasPermission) {
      this.navigateToSavedQueriesPage();
    }

    return hasPermission;
  }

  private validateCriticalActivityTabAccess(): boolean {
    const hasPermission = this.permissionsService.hasAllOfPermissions(
      urlMap.criticalActivity.requiredPermissions
    );

    if (!hasPermission) {
      this.navigateToSavedQueriesPage();
    }

    return hasPermission;
  }

  private validateCASetUpAccess() {
    const hasPermission = this.permissionsService.hasAnyOfPermissions(
      urlMap.caSetup.requiredPermissions
    );

    if (!hasPermission) {
      this.router.navigate(['accessDenied']);
    }

    return hasPermission;
  }

  private validateProvisionAccess() {
    const hasPermission = this.permissionsService.hasAnyOfPermissions(
      urlMap.provision.requiredPermissions
    );
    if (!hasPermission) {
      this.router.navigate(['accessDenied']);
    }
    return hasPermission;
  }

  private validateAlertsAccess(): boolean {
    const hasPermission = this.permissionsService.hasAnyOfPermissions(
      urlMap.alerts.requiredPermissions
    );

    if (!hasPermission) {
      const hasAlertPlansPermission =
        this.permissionsService.hasAnyOfPermissions(
          urlMap.alertPlans.requiredPermissions
        );

      // When a user clicks the **Alerts** tab, he may only have access
      // to Alert Plans.
      if (hasAlertPlansPermission) {
        this.navigateToAlertPlans();
      } else {
        this.navigateToSavedQueriesPage();
      }
    }

    return hasPermission;
  }

  private validateAlertPlansAccess(): boolean {
    const hasPermission = this.permissionsService.hasAnyOfPermissions(
      urlMap.alertPlans.requiredPermissions
    );
    if (!hasPermission) {
      this.router.navigate([...auditingBaseUrlSegments, 'alerts']);
    }
    return hasPermission;
  }

  private navigateToSavedQueriesPage() {
    this.router.navigate(savedQueriesUrl);
  }

  private navigateToAlertPlans() {
    this.router.navigate([...auditingBaseUrlSegments, 'alerts', 'plans']);
  }
}
