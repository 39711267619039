<qod-summary-small-card
  [cardHeader]="displayTitle"
  [includeColoredRectangle]="true"
  [color]="trendColor$ | async"
  [icon]="iconName"
  [iconColor]="iconColor"
  [ngStyle]="getPointerStyle()"
  (click)="runQuery()"
>
  <ng-container information>
    <oda-small-summary-card-information
      [loading]="dataLoading$ | async"
      [error]="dataError$ | async"
      [figureValue]="indicatorValue"
      [figureUnit]="indicatorTimeFrame"
      [change]="trendValue"
      [includeChange]="true"
      [changeTimeFrame]="trendTimeFrame"
      [negativeTrend]="true"
      [noChangeText]="noChangeText"
      [enablePercentage]="enablePercentage"
      (errorActionClicked) = "handleErrorRetry()"
    ></oda-small-summary-card-information>
  </ng-container>
</qod-summary-small-card>
