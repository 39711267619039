<modal-window #copyQueryModal [params]="modalParams" *ngIf="formData.name && categories">
  <div modal-content class="copy-modal">

    <error-message *ngIf="errorMessage">{{errorMessage}}</error-message>

    <form #copyForm="ngForm" (ngSubmit)="submitCopy()">

      <div class="input-field">
        <input id="copyName" type="text" name="copyName" [(ngModel)]="formData.name" autocomplete="off">
        <label for="copyName">{{ 'auditing.pages.savedSearches.copyNamePrompt' | localize }}</label>
      </div>

      <div class="input-field">
        <select
          class="browser-default"
          name="copyCategory"
          id="copyCategory"
          (change)="onValueChange()"
          [(ngModel)]="formData.categoryId"
        >
          <option [value]="category.id" *ngFor="let category of allowedCategories">{{ category.name }}</option>
        </select>
        <label for="copyCategory">{{ 'auditing.pages.savedSearches.copyCategoryPrompt' | localize }}</label>
      </div>

      <private-shared-type-selector
        [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
        [isSharedSelectionAllowed]="isSharedSelectionAllowed"
        [isSharedItemSelected]="isShared"
        (buttonSelected)="onToggleButtonsChanged($event)"
      ></private-shared-type-selector>

      <div class="action-button-container-outer">
        <div class="action-buttons right">          
          <button
            type="button"
            class="btn submit-copy-button"
            [disabled]="!copyForm.valid || saving"
            (click)="submitCopy()"
          >
            {{ 'auditing.pages.savedSearches.copyButton' | localize }}
          </button>
          <button
            type="button"
            class="btn-flat cancel-copy-button"
            data-test="submit-copy"
            (click)="cancelCopy()"
          >{{ 'auditing.pages.savedSearches.cancelCopyButton' | localize }}
          </button>    
        </div>
      </div>
    </form>
  </div>
</modal-window>
