import { SEARCH_PERMISSION } from '../../services/search-permissions.service';

export const getAllowedQueryTypeAsPerPermissions = (
  isShared: boolean,
  searchPermission: string
) => {
  if (searchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED) {
    return isShared; // User has all required permissions
  } else if (searchPermission === SEARCH_PERMISSION.SHARED) {
    return true;
  } else {
    // Only private query allowed
    return false;
  }
};
