// Date
// User (the actor of the activity)
// Activity (as defined in the database)
// Target (should display target of the activity - URL, or mailbox, or item in a mailbox,
// etc - similar to O365 Security and Compliance center)
// Origin (would display an IP address of the origin)
// Service (SharePoint Online, Exchange Online, OneDrive for Business)
// Tenant (only display one column Initial Domain or Display name of the tenant)
export const defaultColumns = [
  'Date',
  'User',
  'Activity',
  'Target',
  'Origin',
  'Service',
  'TenantName'
];

// Date
// User (the actor of the activity)
// Activity (as defined in the database)
// Target (should display target of the activity - URL, or mailbox, or item in a mailbox,
// etc - similar to O365 Security and Compliance center)
// TargetObjectId (ObjectId of the Target)
// Origin (would display an IP address of the origin)
// OriginName (The origin name/FQDN)
// Subject (Would display the subject of the activity )
// Service (SharePoint Online, Exchange Online, OneDrive for Business)
// Tenant (only display one column Initial Domain or Display name of the tenant)
export const defaultQuickSearchColumns = [
  'Date',
  'User',
  'Activity',
  'Target',
  'TargetObjectId',
  'Origin',
  'OriginName',
  'Subject',
  'Service',
  'TenantName'
];
