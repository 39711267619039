<div class="input-field">
  <div
    class="private-shared-buttons"
    id="private-shared-buttons-id"
    *ngIf="isPrivateSelectionAllowed && isSharedSelectionAllowed"
  >
    <label for="saveType">{{ auditStrings.labelType }}</label>
    <qod-segmented-control
      [tabs]="toggleButtons"
      [selectedTab]="getSelectedSearchType()"
      (tabSelected)="onToggleButtonsChanged($event)"
    ></qod-segmented-control>
  </div>
  <div
    class="private-type"
    id="private-type-id"
    *ngIf="isPrivateSelectionAllowed && !isSharedSelectionAllowed"
  >
    <label for="saveType">{{ auditStrings.labelType }}</label>
    {{ 'auditing.privateType' | localize }}
  </div>
  <div
    class="shared-type"
    id="shared-type-id"
    *ngIf="!isPrivateSelectionAllowed && isSharedSelectionAllowed"
  >
    <label for="saveType">{{ auditStrings.labelType }}</label>
    {{ 'auditing.sharedType' | localize }}
  </div>
</div>
