<error-message *ngIf="locatingAlertPlanError">{{
  locatingAlertPlanError | async
}}</error-message>

<form #planEditorForm="ngForm">
  <div class="editor-container" *ngIf="plan && !locatingAlertPlanError">
    <div class="top-section">
      <h3
        *ngIf="!userCanEditThisAlertPlan()"
        class="plan-name"
      >
        {{ plan.name }}
      </h3>
      <div
        *ngIf="userCanEditThisAlertPlan()"
        class="plan-name-container input-field inline"
      >
        <input
          id="plan-name"
          name="plan-name"
          type="text"
          (keyup)="resetError()"
          (change)="onNameChange()"
          (input)="onNameChange()"
          [(ngModel)]="plan.name"
          class="plan-name"
          maxlength="250"
          materialize="tooltip"
          data-delay="50"
          data-position="top"
          attr.data-tooltip="{{ 'auditing.alertPlanName' | localize }}"
          autocomplete="off"
          required
          [disabled]="plan.isBuiltIn"
        />
      </div>

      <div
        class="action-container"
        *ngIf="userCanEditThisAlertPlan()"
      >
        <button
          (click)="onClickTest()"
          id="test-alert-plan"
          class="btn"
          [ngClass]="{ disabled: !planEditorForm.valid || saving || !plan.id || !doesAlertRecipientListExist() }"
        >
          <edc-icon name="task-run"></edc-icon>
          {{ 'auditing.testAlertPlan' | localize }}
        </button>

        <button
          (click)="onClickSave()"
          id="save-alert-plan"
          class="btn"
          [ngClass]="{ disabled: !planEditorForm.valid || saving || !isAlertPlanUIUpdated }"
          *ngIf="userCanEditThisAlertPlan()"
        >
          <edc-icon name="save"></edc-icon>
          {{ 'auditing.alertPlanSave' | localize }}
        </button>
      </div>
    </div>

    <error-message *ngIf="saveError">
      {{ saveError | async }}
    </error-message>

    <div class="last-saved-plan-section">
      <last-saved-info-field
        [showLastSavedInfo]="showSharedPlanLastSavedInfo()"
        [lastSavedLabelInfo]= "lastSavedLabelInfo | async"
        [lastSavedToolTipRefreshProp]="lastSavedToolTipRefreshProp"
        [lastSavedToolTipInfo]="lastSavedToolTipInfo | async"
      ></last-saved-info-field>
    </div>

    <div class="alert-type-container">
      <private-shared-type-selector [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
                                    [isSharedSelectionAllowed]="isSharedSelectionAllowed"
                                    [isSharedItemSelected]="isShared"
                                    (buttonSelected)="onToggleButtonsChanged($event)"></private-shared-type-selector>
    </div>

    <p class="intro">{{ 'auditing.alertPlanEditorIntro' | localize }}</p>

    <div class="alert-action-list">
      <div class="action-row" *ngFor="let action of plan.actions; index as i">
        <div class="alert-action-outer-container">
          <alert-action
            name="alert-action-{{ action.id }}"
            [(ngModel)]="plan.actions[i]"
            [canEdit]="userCanEditThisAlertPlan()"
          ></alert-action>
        </div>
        <a
          *ngIf="plan.actions.length > 1 && userCanEditThisAlertPlan()"
          (click)="onClickRemoveAction(plan.actions[i])"
          class="remove-action-button"
        >
          <edc-icon name="cross"></edc-icon>
        </a>
      </div>
    </div>

    <button
      class="btn add-action-button"
      (click)="onClickAddAction()"
      *ngIf="userCanEditThisAlertPlan()"
    >
      <edc-icon name="plus"></edc-icon>
      {{ 'auditing.alertActionAddStepButton' | localize }}
    </button>
  </div>
</form>
