import { createSelector } from '@ngrx/store';
import * as fromFeature from '../../../reducers/index';

const TopActiveUsersState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.topActiveUsers
);

export const selectTopActiveUsersData = createSelector(
  TopActiveUsersState,
  state => state.data
);

export const selectTopActiveUsersLoading = createSelector(
  TopActiveUsersState,
  state => state.loading
);

export const selectTopActiveUsersUpdating = createSelector(
  TopActiveUsersState,
  state => state.updating
);

export const selectTopActiveUsersExpired = createSelector(
  TopActiveUsersState,
  state => state.expired
);

export const selectTopActiveUsersError = createSelector(
  TopActiveUsersState,
  state => state.error
);
