<div
  class="donut-chart-component-container"
>
<div *ngIf="error" class="error-box  one-column-flex-child">
  <error-card
    [error]="errorText"
    [button]="tryAgainText"
    (buttonClick)="retryOnError()">
  </error-card>
</div>

  <div *ngIf="eventCount === 0" class="no-results-message-box">
    <qod-attention-image-with-text
      [imageUrl]="noResultsImagePath"
      [altImageUrl]="applicationStrings.altImageUrlLabel"
      [mainTitle]="applicationStrings.noResultFound"
      [subTitle]="applicationStrings.adjustYourFilter"
    ></qod-attention-image-with-text>
  </div>
  <div class="donut-canvas-viewport" >
    <div class="donut-canvas-container" *ngIf="chartDataReady">
    <div
      *ngIf="showEventCountTotal"
      class="event-count-container"
    >
      <p class="event-count">{{ totalEventsString }}</p>
      <p>{{ totalEventCountLabel }}</p>
    </div>
      <base-generic-chart
        [displayLegend]="false"
        [chartLabels]="categoryLabels"
        [options]="chartOptions"
        [chartType]="chartType"
        [containerStyle]="containerStyle"
        [chartPosition]="chartPosition"
        [datasets]="chartDataSets"
        [chartColors]="chartColors"
      >
      </base-generic-chart>
    </div>
    <div class="legend-container-donut" *ngIf="chartDataReady">
    <div class="legend-main">
      <div class="legend-title">
      <span>{{ legendTitle }} </span>
    </div>
    <div class="legend-body">
      <oda-chart-legend
      [dataItems] ="chartLegendItems"
      [legendTitle] = "legendTitle"
      (legendItemSelectionChange)="legendItemSelectionChange($event)"
      >
      </oda-chart-legend>
    </div>
    </div>
    </div>
  </div>
 </div>
