
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  FavoriteSearchConfiguration,
  FavoriteSearchResponse
} from '../models/favorite-search.models';
import { WidgetService } from '../../../../../../shared/services/widget-service';
import { WidgetID } from '../../../models/widget-info';

@Injectable()
export class FavoriteSearchService {
  constructor(private widgetService: WidgetService) {}

  getFavoriteSearches(): Observable<
    AuditingDashboardResponse<
      FavoriteSearchResponse,
      FavoriteSearchConfiguration
    >
  > {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.MyFavoriteSearches).pipe(
      map(response => response.body));
  }
}
