import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StaticIndicatorDataService } from '../services/static-indicator-data.service';
import {
  LoadAdAccountLockouts,
  LoadAdAccountLockoutsFail,
  LoadAdAccountLockoutsSuccess,
  LoadAllFailedSignins,
  LoadAllFailedSigninsFail,
  LoadAllFailedSigninsSuccess,
  LoadAzureADCriticalRoleChanges,
  LoadAzureADCriticalRoleChangesFail,
  LoadAzureADCriticalRoleChangesSuccess,
  LoadAzureADFailedSignins,
  LoadAzureADFailedSigninsFail,
  LoadAzureADFailedSigninsSuccess,
  LoadAzureADRiskEvents,
  LoadAzureADRiskEventsFail,
  LoadAzureADRiskEventsSuccess,
  LoadCloudOnlyAzureADUsersCreated,
  LoadCloudOnlyAzureADUsersCreatedFail,
  LoadCloudOnlyAzureADUsersCreatedSuccess,
  LoadO365ExternalUserAction,
  LoadO365ExternalUserActionFail,
  LoadO365ExternalUserActionSuccess,
  LOAD_AD_ACCOUNT_LOCKOUTS,
  LOAD_ALL_FAILED_SIGNINS,
  LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES,
  LOAD_AZURE_AD_FAILED_SIGNINS,
  LOAD_AZURE_AD_RISK_EVENTS,
  LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED,
  LOAD_O365_EXTERNAL_USER_ACTIONS
} from './static-indicator.actions';

@Injectable()
export class StaticIndicatorEffects {
  loadAdAccountLockouts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAdAccountLockouts>(LOAD_AD_ACCOUNT_LOCKOUTS),
      switchMap(action => this.dataService.getAdAccountLockouts().pipe(
          map(response => new LoadAdAccountLockoutsSuccess(response)),
          catchError(error => of(new LoadAdAccountLockoutsFail(error)))
        ))
    ));

  loadAllFailedSignins$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAllFailedSignins>(LOAD_ALL_FAILED_SIGNINS),
    switchMap(action => this.dataService.getAllFailedSignins().pipe(
        map(response => new LoadAllFailedSigninsSuccess(response)),
        catchError(error => of(new LoadAllFailedSigninsFail(error)))
      ))
  ));

  loadAzureADCriticalRoleChanges$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAzureADCriticalRoleChanges>(LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES),
    switchMap(action => this.dataService.getAzureADCriticalRoleChanges().pipe(
        map(response => new LoadAzureADCriticalRoleChangesSuccess(response)),
        catchError(error => of(new LoadAzureADCriticalRoleChangesFail(error)))
      ))
  ));

  loadAzureADFailedSignins$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAzureADFailedSignins>(LOAD_AZURE_AD_FAILED_SIGNINS),
    switchMap(action => this.dataService.getAzureADFailedSignins().pipe(
        map(response => new LoadAzureADFailedSigninsSuccess(response)),
        catchError(error => of(new LoadAzureADFailedSigninsFail(error)))
      ))
  ));

  loadAzureADRiskEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadAzureADRiskEvents>(LOAD_AZURE_AD_RISK_EVENTS),
    switchMap(action => this.dataService.getAzureADRiskEvents().pipe(
        map(response => new LoadAzureADRiskEventsSuccess(response)),
        catchError(error => of(new LoadAzureADRiskEventsFail(error)))
      ))
  ));

  loadCloudOnlyAzureADUsersCreated$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadCloudOnlyAzureADUsersCreated>(LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED),
    switchMap(action => this.dataService.getCloudOnlyAzureADUsersCreated().pipe(
        map(
          response => new LoadCloudOnlyAzureADUsersCreatedSuccess(response)
        ),
        catchError(error =>
          of(new LoadCloudOnlyAzureADUsersCreatedFail(error))
        )
      ))
  ));

  loadO365ExternalUserAction$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadO365ExternalUserAction>(LOAD_O365_EXTERNAL_USER_ACTIONS),
    switchMap(action => this.dataService.getO365ExternalUserActions().pipe(
        map(response => new LoadO365ExternalUserActionSuccess(response)),
        catchError(error => of(new LoadO365ExternalUserActionFail(error)))
      ))
  ));

  constructor(
    private dataService: StaticIndicatorDataService,
    private actions$: Actions
  ) {}
}
