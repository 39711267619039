// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  throwError,
  of,
  zip,
  timer,
  Subject,
  ConnectableObservable,
  ReplaySubject
} from 'rxjs';
import {
  debounce,
  catchError,
  mergeMap,
  publish,
  map,
  take,
  takeUntil
} from 'rxjs/operators';

import {
  saveNewQueryAction,
  updateSavedQueryAction,
  saveQueryAsAction,
  runQueryAction,
  resetQueryAction,
  QueryEditorActionsComponent,
  deleteQueryAction
} from './query-editor-actions/query-editor-actions.component';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent, DisplayStringsProvider } from '@ondemand/core';

import { ActiveQueryService } from '../../../services/active-query.service';
import { EventField } from '../../../models/event-field.model';
import { EventFieldsService } from '../../../services/event-fields.service';
import { QueryBody } from '../../../models/query-body';
import { QueryClause } from '../../../models/query-clause';
import { SavedQueriesService } from '../../../services/saved-queries.service';
import { ColumnEditorFlyoutComponent } from '../editor/column-editor-flyout/column-editor-flyout.component';
import { Query } from '../../../models/query.model';
import { QueryService } from '../../../services/query/query.service';
import { Results } from '../results/results.model';
import {
  ResultsPreviewComponent,
  CellClickEvent
} from '../results/results-preview/results-preview.component';
import { SavedQueryCategory } from '../saved-queries/saved-query-category.model';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';

import { SaveAsModalComponent } from './save-as-modal/save-as-modal.component';
import { SaveModalComponent } from './save-modal/save-modal.component';
import { defaultColumns } from '../default-query-columns';
import { maxTitleLength } from '../breadcrumb-config';
import { ErrorMessageService } from '../../../services/error-message.service';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  defaultCategoryId,
  defaultSharedCategoryId
} from '../default-query-category';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import {
  SEARCH_PERMISSION,
  SearchPermissionsService
} from '../../../services/search-permissions.service';
import {
  getLastSavedToolTipInfo,
  getLastSavedLabelText
} from '../../../util/last-saved-info';
import { cloneAndUnlockObject } from '../../../../shared/utils/object.tools';
import * as queryConverter from '../../../../shared/components/chart/services/chart-query-converter';
import {
  OdaChartType,
  RenderType
} from '../../../../shared/components/chart/models/visualization-constants';
import {
  ColumnEditorOptions,
  VisualizeParameters
} from './column-editor-flyout/models/editor-parameters';
import {
  getBarChartId,
  getDonutChartId,
  getTimeseriesChartId,
  isChartQuery
} from '../../../../shared/components/chart/utils/chart-utils';
import { getIntervalCategory } from '../../../../shared/components/chart/services/chart-query-converter';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';

enum HttpStatusCode {
  Conflict = 409,
}

const baseUrl = '/auditing/auditing/queries/saved';
const defaultCategory: SavedQueryCategory = {
  name: 'My Searches',
  id: defaultCategoryId,
  isShared: false
} as SavedQueryCategory;

@Component({
  selector: 'ca-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild(QueryEditorActionsComponent)
  actionsPanel: QueryEditorActionsComponent;
  @ViewChild(ResultsPreviewComponent) resultsTable: ResultsPreviewComponent;
  @ViewChild('queryBuilderForm') form: NgForm;
  @ViewChild(ColumnEditorFlyoutComponent)
  columnEditor: ColumnEditorFlyoutComponent;
  @ViewChild(SaveAsModalComponent) saveAsModal: SaveAsModalComponent;
  @ViewChild(SaveModalComponent) saveModal: SaveModalComponent;

  applicationStrings = AuditingDisplayStringsProvider.auditing;
  categories: SavedQueryCategory[] = [defaultCategory];
  allowedCategories: SavedQueryCategory[];
  fields: EventField[];
  loadingFields = true;
  loadingCategories = false;
  loadingCategoriesError = false;
  loadingQuery = true;
  loadingQueryError = false;
  loadingQueryErrorMessage: string;
  loadingQueryPreview = false;
  loadingQueryPreviewError: string = null;
  loadingFieldsError = false;
  results: Results;
  savingQuery = false;
  previewedQuery: QueryBody;
  previewIsOn = true;
  query: Query;
  originalQuery: Query;
  queryIsDirty = false;
  lastSavedToolTipInfoSub: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastSavedToolTipRefreshProp: string[] = [];
  lastSavedLabelInfoSub: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastSavedLabelInfo: string;
  lastSavedToolTipInfo: string;

  enableTimeSeriesChart = false;
  enableDonutChart = false;
  enableHBarChart = false;
  hideCharts = true;
  hideEvents = false;
  visualizationLoaded = true;

  coreStrings = DisplayStringsProvider;
  isDuplicateQueryError = false;
  actionsErrorMessage: string;
  auditStrings = AuditingDisplayStringsProvider.auditing;

  manageSearchPermission: string = SEARCH_PERMISSION.NONE;
  viewSearchPermission: string = SEARCH_PERMISSION.NONE;

  saveAsFormData: any = {
    name: null,
    categoryId: null,
    isShared: null
  };

  saveFormData: any = {
    name: null,
    categoryId: null,
    isShared: null
  };

  visualizeParams: VisualizeParameters;

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private savedQueriesService: SavedQueriesService,
    private activeQueryService: ActiveQueryService,
    private eventFieldsService: EventFieldsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private queryService: QueryService,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private errorService: ErrorMessageService,
    private localeStrings: LocaleStringsService,
    private searchPermissionsService: SearchPermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.setPermission();
        this.setupEditor();
      });

    this.lastSavedLabelInfoSub.subscribe(value => {
      this.lastSavedLabelInfo = value;
    });

    this.lastSavedToolTipInfoSub.subscribe(value => {
      this.lastSavedToolTipInfo = value;
    });
  }

  setupEditor() {
    this.updateBreadcrumb();

    // Make requests
    const loadQuery$ = this.loadQuery();
    const loadFields$ = this.loadAvailableFields();

    // Handle errors getting query
    loadQuery$.subscribe(null, (error: any) => {
      this.handleRetrieveQueryError(error);
    });

    zip(loadQuery$, loadFields$).subscribe(([loadedQuery, fields]) => {
      this.fields = fields;
      let query = loadedQuery || this.getDefaultQuery();

      // Populate query category if passed in through route
      if (this.activatedRoute.snapshot.params.categoryId) {
        query.categoryId = this.activatedRoute.snapshot.params.categoryId;
      }

      // If editing existing query, set it as the original version
      if (loadedQuery && !this.originalQuery) {
        this.originalQuery = query.deepClone();
      }

      this.setQuery(query);
      this.loadingQuery = false;
      this.updateLastSavedInfo();

      this.loadCategories();

      const hideChart = query.hideCharts;
      const hideEvent = query.hideEvents;

      setTimeout(() => {
        // `setTimeout` allows Angular to update the view with data retrieved
        // in these observables
        this.afterDataLoad(hideChart, hideEvent);
      }, 100);
    });

    loadQuery$.connect();
    loadFields$.connect();
  }

  /**
   * Subscribes to form changes to determine when to re-run the query preview.
   *
   */
  afterDataLoad(hidechart: boolean, hideEvent: boolean) {
    this.updateBreadcrumb();
    if (this.shouldRerunPreview()) {
      // this is a patch
      // hideCharts / hideEvents may get modified somehow -- I have not able to figure out yet.
      this.query.hideCharts = hidechart;
      this.query.hideEvents = hideEvent;
      this.hideCharts = hidechart;
      this.hideEvents = hideEvent;
      this.updateVisualizeParams();
      this.renderResultsAndChart(
        this.query.q.columns,
        this.visualizeParams,
        this.originalQuery
      );
    }
    this.subscribeToFormChanges();
  }

  async updateBreadcrumb() {
    let queryTitle;
    let queryURL;
    if (this.query) {
      queryTitle = this.query.name;
      if (this.query.id) {
        queryURL = `auditing/auditing/queries/results/${this.query.id}`;
      } else {
        queryURL = 'auditing/auditing/queries/editor';
      }
    } else {
      // Set a temporary breadcrumb while the query metadata is loaded
      queryURL = '';
      queryTitle = await this.localeStrings
        .string$('auditing.querySingular')
        .pipe(take(1))
        .toPromise();
    }

    const relativeURL = false;
    this.breadcrumbsService.set(
      [
        {
          title: await this.localeStrings
            .string$('auditing.queries')
            .pipe(take(1))
            .toPromise(),
          url: 'auditing/auditing/queries'
        },
        {
          title: queryTitle,
          url: queryURL
        },
        {
          title: await this.localeStrings
            .string$('auditing.editorBreadcrumb')
            .pipe(take(1))
            .toPromise(),
          url: ''
        }
      ],
      relativeURL,
      maxTitleLength
    );
  }

  getDefaultQuery(): Query {
    if (!this.fields) {
      return null;
    }

    let query = new Query();
    // Add default category
    query.categoryId =
      this.viewSearchPermission === SEARCH_PERMISSION.SHARED
        ? defaultSharedCategoryId
        : defaultCategoryId;

    const defaultField = 'Date';
    const defaultOperator = 'during_last';
    const defaultValue = '7_days';

    let clause = new QueryClause();
    if (this.fields.find(field => field.id === defaultField)) {
      clause.field = defaultField;
      clause.operator = defaultOperator;
      clause.value = defaultValue;
    } else {
      throw Error(
        `Could not find default field "${defaultField}" in the list of available fields.`
      );
    }
    query.q.clauses.push(clause);
    query.q.columns = defaultColumns;
    query.isShared = this.manageSearchPermission === SEARCH_PERMISSION.SHARED;
    return query;
  }

  handleAction($event: string) {
    switch ($event) {
      case saveNewQueryAction:
        this.showSavePrompt();
        break;
      case updateSavedQueryAction:
        this.updateQuery();
        break;
      case saveQueryAsAction:
        this.showSaveAsPrompt();
        break;
      case runQueryAction:
        this.runQuery();
        break;
      case resetQueryAction:
        this.resetQuery();
        break;
      case deleteQueryAction:
        this.deleteQuery();
        break;
      default:
        return;
    }
  }

  handleCellClick(_event: CellClickEvent) {
    return;
  }

  handlePreviewToggle($event: boolean) {
    this.previewIsOn = $event;
  }

  handlePreviewVisible() {
    if (this.previewIsOn) {
      this.renderResultsAndChart(
        this.query.q.columns,
        this.visualizeParams,
        this.originalQuery
      );
    }
  }

  loadCategories() {
    this.loadingCategories = true;
    this.loadingCategoriesError = false;

    this.savedQueriesService
      .getCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (categories: SavedQueryCategory[]) => {
          this.categories = categories;
          this.allowedCategories = this.filterCategories(categories);
        },
        (_error: any) => {
          this.loadingCategories = false;
          this.loadingCategoriesError = true;
        }
      );
  }

  showSaveAsPrompt() {
    this.saveAsFormData.name = this.query.name;
    this.saveAsFormData.categoryId = this.query.categoryId;
    this.saveAsFormData.isShared = this.query.isShared;
    this.saveAsModal.openModal();
  }

  showSavePrompt() {
    this.saveFormData.name = this.query.name;
    this.saveFormData.categoryId = this.query.categoryId;
    this.saveFormData.isShared = this.query.isShared;
    this.saveModal.openModal();
  }

  async handleSavedQuerySuccess(query: Query) {
    let message = await this.localeStrings
      .string$('auditing.pages.newSearches.saveAsSuccessfulToast')
      .pipe(take(1))
      .toPromise();
    toastLowerLeft(this.toastService, message, ToastType.Success);
    this.originalQuery = query.deepClone();
    this.endSave();
    this.setQuery(query);
    this.resetForms();
    this.setUrl(query);
    this.updateBreadcrumb();
    this.updateLastSavedInfo();
  }

  async handleSaveAsQuerySuccess(queryFromSaveAsModal: Query) {
    // Update real query object with new values
    let message = await this.localeStrings
      .string$('auditing.pages.newSearches.saveAsSuccessfulToast')
      .pipe(take(1))
      .toPromise();
    toastLowerLeft(this.toastService, message, ToastType.Success);
    this.originalQuery = cloneAndUnlockObject<Query>(queryFromSaveAsModal);
    this.endSave();
    this.setQuery(queryFromSaveAsModal);
    this.updateBreadcrumb();
    this.setUrl(queryFromSaveAsModal);
    this.updateLastSavedInfo();
  }

  updateQuery() {
    this.beginSave();
    this.savedQueriesService.updateSavedQueryWithHttpInfo(this.query).subscribe(
      response => {
        let updatedQuery: Query = new Query(response.body);
        this.handleSavedQuerySuccess(updatedQuery);
      },
      (error: any) => this.saveQueryError(error)
    );
  }

  /**
   * Returns true if the query preview needs to be rerun.
   *
   */
  shouldRerunPreview(): boolean {
    const currentQuery = cloneAndUnlockObject<QueryBody>(this.query.q);
    const previewedQuery = this.previewedQuery
      ? cloneAndUnlockObject<QueryBody>(this.previewedQuery)
      : null;

    this.previewedQuery = currentQuery;

    if (previewedQuery === null || previewedQuery === undefined) {
      return true;
    }

    if (
      currentQuery.clauses.length !== previewedQuery.clauses.length ||
      currentQuery.columns.length !== previewedQuery.columns.length
    ) {
      // Always run if a clause has been added, removed, or if a column has been
      // added/removed.
      return true;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < previewedQuery.clauses.length; i++) {
      const previewedClause = previewedQuery.clauses[i];
      const newClause = currentQuery.clauses[i];

      if (JSON.stringify(previewedClause) !== JSON.stringify(newClause)) {
        return true;
      }
    }

    return false;
  }

  runQuery() {
    this.activeQueryService.setQuery(this.originalQuery, this.query);
    let resultsUrl = '/auditing/auditing/queries/results';

    // Use query ID in URL path if the query is unchanged from the previously saved version
    if (this.query.id && this.activeQueryService.isSaved()) {
      resultsUrl += `/${this.query.id}`;
    }
    this.router.navigate([resultsUrl]);
  }

  runQueryPreview() {
    if (
      !this.form ||
      Object.keys(this.form.controls).length === 0 ||
      this.form.invalid
    ) {
      return;
    }

    this.loadingQueryPreview = true;
    this.loadingQueryPreviewError = null;
    const params = this.queryService.getDefaultRequestParameters();
    params.limit = 15;
    const query = cloneAndUnlockObject<Query>(this.query);

    // To run the query ad-hoc, remove the id from this clone
    delete query.id;

    this.queryService
      .runQueryWithHttpInfo(query, params, true)
      .toPromise()
      .then(
        response => {
          this.results = new Results(response.body);
          this.loadingQueryPreview = false;
        },
        async (response: HttpErrorResponse) => {
          this.results = null;
          this.loadingQueryPreview = false;
          this.loadingQueryPreviewError =
            await this.errorService.getErrorMessage(response);
        }
      );
  }

  resetQuery() {
    console.warn('not implemented');
  }

  loadAvailableFields(): ConnectableObservable<EventField[]> {
    this.loadingFields = true;
    const fields$ = this.eventFieldsService.getFields().pipe(
      map((response: any) => {
        const fields: EventField[] = response.availableFields.map(
          (field: any) => new EventField(field)
        );

        return fields;
      }),
      publish()
    ) as ConnectableObservable<EventField[]>;

    fields$.subscribe(
      _fields => {
        this.loadingFields = false;
      },
      () => {
        this.loadingFields = false;
        this.loadingFieldsError = true;
      }
    );

    return fields$;
  }

  addClause() {
    const defaultOperator = 'contains';
    const defaultValue: any = '';
    let newClause = new QueryClause({
      operator: defaultOperator,
      value: defaultValue
    });
    this.query.q.clauses.push(newClause);
    setTimeout(() => this.scrollAddClauseIntoView(), 0);
  }

  removeClause(index: number) {
    if (this.query.q.clauses.length > 1) {
      this.query.q.clauses = [
        ...this.query.q.clauses.slice(0, index),
        ...this.query.q.clauses.slice(index + 1)
      ];

      this.markQueryAsDirty();
    }
  }

  openEditColumnsFlyout() {
    if (
      !this.loadingQuery &&
      !this.loadingQueryError &&
      this.query &&
      this.query.q &&
      this.query.q.columns &&
      this.query.q.columns.length > 0 &&
      this.fields &&
      this.fields.length > 0
    ) {
      this.updateVisualizeParams();
      this.columnEditor.openEditColumnsFlyout();
    }
  }

  onColumnChange(options: ColumnEditorOptions) {
    const originalQuery = cloneAndUnlockObject<Query>(this.query);

    this.visualizeParams = options.visualizeParameters;
    this.query.q.columns = options.newColumns;
    if (this.query.userId === 'system') {
      this.query.userId = undefined;
      this.query.id = undefined;
      // when the user start editing built-in search,
      // we want to force it to be a "NEW" user query.

      // for system built-in queries, some have  donut chart queries predfined
      // we need to wipe it out, after editing, if visualize parameters is {}
      if (Object.keys(this.visualizeParams).length === 0) {
        // visualize parameters is {}, the user didn't add visualize parameter
        this.query.q.charts = [];
      }
    }

    this.renderResultsAndChart(
      options.newColumns,
      options.visualizeParameters,
      originalQuery
    );

    this.previewedQuery = this.query.q;
    this.form.form.markAsDirty();
    // we have received the changes, close the flyout
    this.columnEditor.closeEditColumnsFlyout();
  }

  onVisualizationLoaded(loaded: boolean) {
    this.visualizationLoaded = loaded;
  }

  /**
   * Handle reorder of a datatable column
   *
   * @param event Data from "reorder" event from ngx-datatable
   */
  onReorder(event: any) {
    let oldIndex = event.prevValue;
    let newIndex = event.newValue;
    let temp = this.query.q.columns[newIndex];
    this.query.q.columns[newIndex] = this.query.q.columns[oldIndex];
    this.query.q.columns[oldIndex] = temp;
  }

  /*
   * Track identity of element for change detection
   * @param index Index of element in loop
   * @param _item
   * note: This is not working as expected yet causing unknown side effect. This methos is not in use for now.
   */
  trackByIndex(index: number, _item: any) {
    return index;
  }

  /**
   * Update query params for sorting using event data from
   * ngx-datatable instance in results viewer
   *
   * @param event Sort event data from ngx-datatable instance
   */
  onSort(event: any) {
    this.query.q.sortDir = event.newValue;
    this.query.q.sortBy = event.column.prop;
    this.markQueryAsDirty();
    // changingn the sorting direction, no need to update the chart
    this.runQueryPreview();
  }

  filterCategories(categories: SavedQueryCategory[]) {
    // If it is a new search, allow only shared or private categories according to permissions
    // If editing an existing search and the search is a private search, allow only shared or private categories according to permissions.
    // If editing an existing search and the search is a shared search, allow only shared categories according to permissions.

    let filteredCategories: SavedQueryCategory[];
    if (this.isNewSearch() || !this.query.isShared) {
      if (this.viewSearchPermission === SEARCH_PERMISSION.SHARED) {
        filteredCategories = categories.filter(
          category => category.isShared === true
        );
      } else {
        if (this.viewSearchPermission === SEARCH_PERMISSION.PRIVATE) {
          filteredCategories = categories.filter(
            category => category.isShared === false
          );
        } else {
          if (
            this.viewSearchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED
          ) {
            filteredCategories = categories;
          }
        }
      }
    } else {
      if (
        this.viewSearchPermission === SEARCH_PERMISSION.SHARED ||
        this.viewSearchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED
      ) {
        filteredCategories = categories.filter(
          category => category.isShared === true
        );
      }
    }

    return filteredCategories;
  }

  shouldShowSearchTypeField(): boolean {
    return !(this.isNewSearch() || this.query.isSystemDefined);
  }

  shouldShowLastSavedInfo(): boolean {
    return (
      this.query &&
      this.query.id &&
      this.query.isShared &&
      !this.query.isSystemDefined
    );
  }

  onChartCategoryClicked(updatedQuery: Query) {
    this.query = updatedQuery;
  }

  onChartElementClicked(query: Query) {
    this.query = query;
    this.visualizeParams.summarizeBy = getIntervalCategory(
      query.q.charts[0].timeSeries.seriesInterval
    );
    this.renderResultsAndChart(
      this.query.q.columns,
      this.visualizeParams,
      this.query
    );
  }

  private renderResultsAndChart(
    updatedColumns: string[],
    visualParams: VisualizeParameters,
    origQuery: Query
  ): void {
    // Taken from sign-ins.component.ts:
    // this will let ngIf to destroy the chart
    // allow some time for angular to do the change detection
    // that will remove the old chart with previous data
    // and then re-render a new chart
    this.enableTimeSeriesChart = false;
    this.enableDonutChart = false;
    this.enableHBarChart = false;
    this.updateRenderingOptions(updatedColumns, visualParams, origQuery);
    this.runQueryPreview();
  }

  private updateRenderingOptions(
    updatedColumns: string[],
    visualParams: VisualizeParameters,
    origQuery: Query
  ): void {
    setTimeout(() => {
      this.query.q.columns = updatedColumns;

      if (visualParams.visualizeAs) {
        if (visualParams.visualizeAs === RenderType.Chart) {
          this.query.hideCharts = false;
          this.query.hideEvents = true;
          this.hideCharts = false;
          this.hideEvents = true;
        } else if (visualParams.visualizeAs === RenderType.ChartAndGrid) {
          this.query.hideCharts = false;
          this.query.hideEvents = false;
          this.hideCharts = false;
          this.hideEvents = false;
        } else if (visualParams.visualizeAs === RenderType.Grid) {
          this.query.hideCharts = true;
          this.query.hideEvents = false;
          this.hideCharts = true;
          this.hideEvents = false;
        }
      }

      let existingChartId: string;
      switch (visualParams.chartType) {
        case OdaChartType.H_BAR:
          existingChartId = getBarChartId(origQuery);
          break;
        case OdaChartType.TimeSeries:
          existingChartId = getTimeseriesChartId(origQuery);
          break;
        case OdaChartType.Donut:
          existingChartId = getDonutChartId(origQuery);
          break;
        default:
          // don't do anything for now
          return;
      }

      const chartOption = queryConverter.buildChartQuery(
        visualParams,
        existingChartId
      );
      if (chartOption) {
        this.query.q.charts = [chartOption];
        this.activeQueryService.setQuery(origQuery, this.query);

        if (!this.hideCharts) {
          // enable corresponding chart only at the last step
          this.enableHBarChart = visualParams.chartType === OdaChartType.H_BAR;
          this.enableDonutChart = visualParams.chartType === OdaChartType.Donut;
          this.enableTimeSeriesChart =
            visualParams.chartType === OdaChartType.TimeSeries;
          this.visualizationLoaded = false;
        }
      }
    }, 200);
  }

  private updateVisualizeParams(): void {
    if (isChartQuery(this.query)) {
      this.visualizeParams = queryConverter.buildVisualizeParams(
        this.fields,
        this.query
      );
    }

    if (!this.visualizeParams) {
      this.visualizeParams = {};
    }
  }

  private async updateLastSavedInfo() {
    const lastSavedLabel = await this.localeStrings
      .string$('auditing.lastSavedLabel')
      .pipe(take(1))
      .toPromise();
    const lastSavedTooltip = await this.localeStrings
      .string$('auditing.lastSavedTooltip')
      .pipe(take(1))
      .toPromise();

    setTimeout(() => {
      this.lastSavedToolTipRefreshProp = [];
    }, 200);

    this.lastSavedToolTipInfoSub.next(
      getLastSavedToolTipInfo(
        lastSavedTooltip,
        this.query.createdDate,
        this.query.userId,
        this.query.lastUpdated,
        this.query.lastUpdatedBy
      )
    );

    this.lastSavedLabelInfoSub.next(
      getLastSavedLabelText(
        lastSavedLabel,
        this.query.lastUpdatedBy,
        this.query.lastUpdated
      )
    );
  }

  private isNewSearch() {
    return this.query.id === undefined || this.query.id === null;
  }

  private setPermission() {
    this.manageSearchPermission =
      this.searchPermissionsService.getManageSearchPermission();
    this.viewSearchPermission =
      this.searchPermissionsService.getViewSearchPermission();
  }

  /**
   * Get existing query based on the current URL/app state or
   * create a new default one
   */
  private loadQuery(): ConnectableObservable<Query> {
    this.loadingQuery = true;
    const loadQuery$ = this.activatedRoute.params.pipe(
      map((params: any) => params.queryId),
      mergeMap((queryId: string) => {
        if (queryId) {
          return this.savedQueriesService.getSavedQuery(queryId);
        } else {
          let query: Query = null;
          const activeQueryAndOriginal = this.activeQueryService.getQuery();
          if (activeQueryAndOriginal.activeQuery) {
            this.originalQuery = activeQueryAndOriginal.originalQuery;
            query = activeQueryAndOriginal.activeQuery;
            this.setUrl(query);
          }

          this.queryIsDirty = true;
          return of(query);
        }
      }),
      catchError((error: any) => throwError(error)),
      publish()
    ) as ConnectableObservable<Query>;

    return loadQuery$;
  }

  private async handleRetrieveQueryError(error: any) {
    if (error.status === 401 || error.status === 403) {
      this.loadingQueryErrorMessage = await this.localeStrings
        .string$('auditing.queryAccessDenied')
        .pipe(take(1))
        .toPromise();
    } else {
      this.loadingQueryErrorMessage = await this.localeStrings
        .string$('auditing.queryLoadError')
        .pipe(take(1))
        .toPromise();
    }

    this.loadingQueryError = true;
    this.loadingQuery = false;
  }

  private setUrl(query: Query) {
    if (query.id === null || query.id === undefined) {
      this.location.replaceState(baseUrl);
      return;
    }

    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if (!params.queryId || params.queryId !== query.id) {
        this.location.go(`/auditing/auditing/queries/editor/${query.id}`);
      }
    });
  }

  private resetForms() {
    try {
      this.form.form.markAsPristine();
      this.actionsPanel.form.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  private async saveQueryError(response: HttpErrorResponse) {
    this.endSave();
    console.error('Error saving query', response);
    let responseBody = response.error;
    if (response.status === HttpStatusCode.Conflict) {
      this.isDuplicateQueryError = true;
    } else if (responseBody && responseBody.error) {
      this.actionsErrorMessage = await this.errorService.getErrorMessage(
        response
      );
    }
  }

  private beginSave() {
    this.isDuplicateQueryError = false;
    this.actionsErrorMessage = null;
    this.savingQuery = true;
  }

  private endSave() {
    this.queryIsDirty = false;
    this.savingQuery = false;
    this.loadingQueryError = false;
  }

  private setQuery(query: Query) {
    this.query = query;
    if (
      this.query.q.columns === null ||
      this.query.q.columns === undefined ||
      this.query.q.columns.length === 0
    ) {
      this.query.q.columns = [...defaultColumns];
    }

    this.activeQueryService.setQuery(this.originalQuery, this.query);
  }

  /**
   * Subscribes to changes in the form to determine when the query preview needs
   * to be re-run
   *
   */
  private subscribeToFormChanges() {
    try {
      this.form.valueChanges
        .pipe(
          debounce(() => timer(1000)),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((_changes: any) => {
          if (this.shouldRerunPreview()) {
            this.renderResultsAndChart(
              this.query.q.columns,
              this.visualizeParams,
              this.originalQuery
            );
          }
        });
    } catch (e) {
      return;
    }
  }

  private markQueryAsDirty() {
    this.queryIsDirty = true;
  }

  private scrollAddClauseIntoView() {
    try {
      const addClause = document.querySelector('.add-clause-container');
      addClause.scrollIntoView(false);
    } catch (e) {
      return;
    }
  }

  private deleteQuery() {
    this.savedQueriesService
      .deleteSavedQueryWithHttpInfo(this.query.id)
      .subscribe(
        async response => {
          if (response.status === 200) {
            const deleteSuccessMessage = await this.localeStrings
              .string$('auditing.pages.savedSearches.deleteSuccessful')
              .pipe(take(1))
              .toPromise();
            toastLowerLeft(this.toastService, deleteSuccessMessage, ToastType.Success);
            this.activeQueryService.clearQuery();

            // Go back to saved searches page
            this.router.navigate(['/auditing/auditing/queries/saved']);
          } else {
            console.error('Unexpected response:', response);
            this.onDeleteError();
          }
        },
        response => {
          console.error('Failed to delete:', response);
          this.onDeleteError();
        }
      );
  }

  private async onDeleteError() {
    const deleteErrorMessage = await this.localeStrings
      .string$('auditing.pages.savedSearches.deleteSearchError')
      .pipe(take(1))
      .toPromise();
    toastLowerLeft(this.toastService, deleteErrorMessage, ToastType.Error);
  }
}
