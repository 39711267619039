import {
  CriticalLevelIconMapping,
  CriticalLevelAnomalyIconMapping,
  CriticalLevels,
  CriticalActivityAlertType
} from '../models/critical-activity.shared.models';

export function getCriticalLevel(priority: number): CriticalLevels {
  if (priority < 10 || priority > 400) {
    return CriticalLevels.Unknown;
  }

  if (priority <= 400 && priority > 300) {
    return CriticalLevels.Low;
  } else if (priority <= 300 && priority > 200) {
    return CriticalLevels.Medium;
  } else if (priority <= 200 && priority > 100) {
    return CriticalLevels.High;
  } else {
    return CriticalLevels.Critical;
  }
}

export function getCriticalLevelIconPath(
  priority: number,
  alertType: CriticalActivityAlertType
): string {
  const criticalLevel = getCriticalLevel(priority);
  if (alertType === CriticalActivityAlertType.AnomalyDetection) {
    return CriticalLevelAnomalyIconMapping.get(criticalLevel);
  } else {
    return CriticalLevelIconMapping.get(criticalLevel);
  }
}
