<div class="configuration-card-v2">
  <ng-container>

    <img class="add-icon-img" [src]="addIconPath" (click)="onClickAdd()">

    <h4 class="ca-title-text">{{ 'auditing.addChangeAuditorModalTitle' | localize }}</h4>

    <p class="ca-description-text">
      {{'auditing.addChangeAuditorCardText' | localize}}
      <a id="learn-more-ca-link" href="https://www.quest.com/video/hybrid-ad-auditing-with-change-auditor-and-on-demand-audit8139466/" target="_blank">{{'auditing.addChangeAuditorLearnMore' | localize}}
        <span class="learn-more-icon-box">
          <mat-icon class="learn-more-ca-link" svgIcon="popout"></mat-icon>
        </span>
      </a>
    </p>

    <div class="card-action-buttons-box">
      <od-caption text="ADD CHANGE AUDITOR" (captionClicked)="onClickAdd()"></od-caption>
    </div>
  </ng-container>

  <ng-container *ngIf="instructionsModalParams && instructionsModalParams.showModal">
    <modal-window [params]="instructionsModalParams">
      <div modal-content>
        <h3>{{ 'auditing.addChangeAuditorModalTitle' | localize }}</h3>
        <div class="instructions">
          <div innerHTML="{{ 'auditing.addChangeAuditorInstructions' | localize }}"></div>
        </div>
      </div>
    </modal-window>
  </ng-container>

