/**
 * Component for specifying a number or number range
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, UntypedFormControl, NgModel } from '@angular/forms';
import { Component, Input, EventEmitter, Output, forwardRef, ViewChild } from '@angular/core';

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PlainTextFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PlainTextFieldComponent),
    multi: true
  }
  ],
  selector: 'plain-text-field',
  templateUrl: './plain-text-field.component.html',
  styleUrls: ['./plain-text-field.component.scss']
})
export class PlainTextFieldComponent implements ControlValueAccessor, Validator {
  @ViewChild('fieldValue', {static: true}) control: NgModel;
  @Input() index: number;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  onChange: any;
  onValidationChange: any;
  value = '';

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: any): void {
    if (value !== null) {
      this.value = value;
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.value);
      this.change.emit();
    };
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  registerOnValidatorChange(fn: any): void {
    this.onValidationChange = () => {
      fn();
    };
  }

  validate(_control: UntypedFormControl): any {
    if (!this.control) {
      return {
        error: 'Not loaded yet'
      };
    } else if (this.control.valid) {
      return null;
    } else {
      return this.control.errors;
    }
  }

  onValueChange() {
    if (this.onChange) {
      this.onChange();
    }
  }

  onStatusChange() {
    this.onValidationChange();
  }
}
