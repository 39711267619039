import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';

import { IModalWindow, BaseComponent } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { SavedQueryCategory } from '../../saved-queries/saved-query-category.model';
import { SavedQueriesService } from '../../../../services/saved-queries.service';
import { Query } from '../../../../models/query.model';
import { ErrorMessageService } from '../../../../services/error-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  SEARCH_PERMISSION,
  SearchPermissionsService
} from '../../../../services/search-permissions.service';

enum HttpStatusCode {
  Conflict = 409,
}

@Component({
  selector: 'save-modal',
  templateUrl: './save-modal.component.html',
  styleUrls: ['./save-modal.component.scss']
})
export class SaveModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() categories: SavedQueryCategory[];
  @Input() formData: any;
  @Input() query: Query;
  @Output() save: EventEmitter<any> = new EventEmitter();

  auditStrings = AuditingDisplayStringsProvider.auditing;
  pageLabels = AuditingDisplayStringsProvider.auditing.pages.newSearches;
  modalParams: IModalWindow;
  errorMessage: string;
  saving = false;
  isShared = false;
  allowedCategories: SavedQueryCategory[];
  isPrivateSelectionAllowed = true;
  isSharedSelectionAllowed = true;

  searchPermission: string = SEARCH_PERMISSION.NONE;
  defaultSearchTypes: string =
    AuditingDisplayStringsProvider.auditing.privateType;

  @ViewChild('saveForm') form: NgForm;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private savedQueriesService: SavedQueriesService,
    private errorService: ErrorMessageService,
    private searchPermissionsService: SearchPermissionsService
  ) {
    super();
  }

  ngOnInit() {
    this.modalParams = {
      showModal: false,
      dialogParams: {
        title: this.pageLabels.saveModalTitle,
        hideCancel: true,
        cancelButtonAction: () => {
          this.cancelSave();
        }
      }
    };
    this.setPermission();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Open modal window
   */
  openModal() {
    this.modalParams.showModal = true;
    this.modalParams = { ...this.modalParams };
    this.isShared = this.formData.isShared || this.isShared;
    this.allowedCategories = this.filterCategories(this.categories);
    this.setSharedSelectionAllowed();
  }

  /**
   * Close modal window
   */
  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = { ...this.modalParams };
  }

  cancelSave() {
    this.closeModal();
  }

  submitSave() {
    this.errorMessage = null;
    this.saving = true;
    this.UpdateMetadata(this.query);
    if (
      this.allowedCategories.findIndex(x => x.id === this.query.categoryId) < 0
    ) {
      this.errorMessage = this.pageLabels.errorInvalidCategory;
      this.saving = false;
      return;
    }
    if (!this.query.id) {
      this.savedQueriesService
        .createSavedQueryWithHttpInfo(this.query)
        .subscribe(
          response => {
            this.saving = false;
            let newQuery = new Query(response.body);
            this.save.emit(newQuery);
            this.closeModal();
          },
          (error: any) => this.saveQueryError(error)
        );
    } else {
      this.savedQueriesService
        .updateSavedQueryWithHttpInfo(this.query)
        .subscribe(
          response => {
            this.saving = false;
            let updatedQuery: Query = new Query(response.body);
            this.save.emit(updatedQuery);
            this.closeModal();
          },
          (error: any) => this.saveQueryError(error)
        );
    }
  }

  onToggleButtonsChanged(button: string) {
    this.isShared = button !== this.defaultSearchTypes;
  }

  onValueChange() {
    this.setSharedSelectionAllowed();
    // Set query type to private, when selected category is private
    if (!this.isSharedSelectionAllowed) {
      this.isShared = false;
    }
  }

  private setSharedSelectionAllowed() {
    if (this.searchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED) {
      let matchedCategories = this.categories.filter(
        category => category.id === this.formData.categoryId
      );
      this.isSharedSelectionAllowed =
        matchedCategories !== null ? matchedCategories[0].isShared : false;
    } else if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      this.isPrivateSelectionAllowed = false;
    } else {
      // consider private category
      this.isSharedSelectionAllowed = false;
    }
  }

  private setPermission() {
    this.searchPermission = this.searchPermissionsService.getManageSearchPermission();
  }

  private filterCategories(categories: SavedQueryCategory[]) {
    let filteredCategories: SavedQueryCategory[];
    if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      filteredCategories = categories.filter(
        category => category.isShared === true
      );
      return filteredCategories;
    }
    return categories;
  }

  private async saveQueryError(response: HttpErrorResponse) {
    console.error('Error saving query', response);
    this.saving = false;
    let responseBody = response.error;
    if (response.status === HttpStatusCode.Conflict) {
      this.errorMessage = this.pageLabels.errorDuplicateName;
    } else if (responseBody && responseBody.error) {
      this.errorMessage = await this.errorService.getErrorMessage(response);
    }
  }

  private UpdateMetadata(query: Query) {
    query.name = this.formData.name;
    query.categoryId = this.formData.categoryId;
    query.createdBy = 'user';
    query.isShared = this.isShared;
  }
}
