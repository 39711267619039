import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent, Util } from '@ondemand/core';
import { Results } from '../results.model';
import { Cell } from '../results.cell.model';

const fieldIdMapping = {
  timeDetected: 'Date',
  userDisplayName: 'UserDisplayName',
  userEmailAddress: 'User',
  status: 'Status',
  service: 'Service',
  activity: 'Activity',
  target: 'Target',
  originIP: 'Origin',
  city: 'City',
  state: 'State',
  country: 'Country',
  modifiedPropertyName: 'ModifiedPropertyName',
  modifiedPropertyBeforeValue: 'From',
  modifiedPropertyAfterValue: 'To',
  additionalEventSummary: 'AdditionalEventSummary'
};

@Component({

  selector: 'event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.scss']
})
export class EventSummaryComponent extends BaseComponent implements OnChanges {
  @Input() event: Results;
  timeDetected: string;
  timeDetectedFormatted: string;
  userDisplayName: string;
  userEmailAddress: string;
  activity: string;
  successful: boolean;
  service: string;
  target: string;
  origin: string;
  geographicLocation: string;
  modifiedProperty: {
    name: string;
    before: string;
    after: string;
  };
  eventSummary: string;

  private filteredCells: Cell[];

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      if (this.event) {
        this.setupTable();
      }
    }
  }

  private setupTable() {
    const cells = this.event.rows[0].cells;
    const selectedFields = Object.values(fieldIdMapping);
    this.filteredCells = cells.filter((cell) => selectedFields.includes(cell.columnId));

    const timeDetectedCell = this.getCellByColumn(fieldIdMapping.timeDetected);
    const dateValue = timeDetectedCell.value;
    this.timeDetected = dateValue;
    this.timeDetectedFormatted = Util.getFormattedDateTime(new Date(dateValue));

    const userEmailAddressCell = this.getCellByColumn(fieldIdMapping.userEmailAddress);
    this.userEmailAddress = userEmailAddressCell.label;

    const statusCell = this.getCellByColumn(fieldIdMapping.status);
    this.successful = statusCell.value;

    const serviceCell = this.getCellByColumn(fieldIdMapping.service);
    this.service = serviceCell.label;

    const activityCell = this.getCellByColumn(fieldIdMapping.activity);
    this.activity = activityCell.label;

    const userDisplayNameCell = this.getCellByColumn(fieldIdMapping.userDisplayName);
    if (userDisplayNameCell) {
      this.userDisplayName = userDisplayNameCell.label;
    } else {
      this.userDisplayName = null;
    }

    const targetCell = this.getCellByColumn(fieldIdMapping.target);
    this.target = targetCell.label;

    this.setWhereInfo();
    this.setModifiedPropertyInfo();
    this.setAdditionalEventSummaryInfo();
  }

  private getCellByColumn(columnId: string) {
    return this.filteredCells.find((cell) => cell.columnId === columnId);
  }

  private setWhereInfo() {
    const originIPCell = this.getCellByColumn(fieldIdMapping.originIP);
    this.origin = originIPCell.label;

    const cityCell = this.getCellByColumn(fieldIdMapping.city);
    const stateCell = this.getCellByColumn(fieldIdMapping.state);
    const countryCell = this.getCellByColumn(fieldIdMapping.country);

    if (cityCell.label && stateCell.label && countryCell.label) {
      this.geographicLocation = `${cityCell.label}, ${stateCell.label}, ${countryCell.label}`;
    } else {
      this.geographicLocation = null;
    }
  }

  private setModifiedPropertyInfo() {
    const modifiedPropertyNameCell = this.getCellByColumn(fieldIdMapping.modifiedPropertyName);
    const modifiedPropertyBeforeValueCell = this.getCellByColumn(fieldIdMapping.modifiedPropertyBeforeValue);
    const modifiedPropertyAfterValueCell = this.getCellByColumn(fieldIdMapping.modifiedPropertyAfterValue);

    if (modifiedPropertyNameCell && modifiedPropertyNameCell.value) {
      this.modifiedProperty = {
        name: modifiedPropertyNameCell.value,
        before: modifiedPropertyBeforeValueCell
          ? modifiedPropertyBeforeValueCell.value
          : undefined,
        after: modifiedPropertyAfterValueCell
          ? modifiedPropertyAfterValueCell.value
          : undefined
      };
    }
  }

  private setAdditionalEventSummaryInfo() {
    const eventSummaryCell = this.getCellByColumn(fieldIdMapping.additionalEventSummary);
    this.eventSummary = eventSummaryCell ? eventSummaryCell.value : undefined;
  }
}
