/**
 * Component for quick search input field
 */

import { Component, ViewChild } from '@angular/core';

import { AuditingDisplayStringsProvider } from '../../../application-strings-EN';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { fixedEncodeURIComponent } from '../../util/url-encode';

@Component({

  selector: 'quick-search',
  templateUrl: './quick-search-field.component.html',
  styleUrls: ['./quick-search-field.component.scss']
})
export class QuickSearchFieldComponent {
  @ViewChild('f') form: NgForm;

  auditingDisplayStringsProvider = AuditingDisplayStringsProvider;
  labels = this.auditingDisplayStringsProvider.auditing;
  query = '';

  constructor(private router: Router) { }

  onSubmit() {
    let queryString = fixedEncodeURIComponent(this.query.trim());
    this.router.navigate(['auditing', 'auditing', 'queries', 'quickresults', queryString]);
    this.query = '';
  }
}
