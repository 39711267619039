import { AlertAction } from './alert-action.model';
import { ReplaySubject } from 'rxjs';

export class AlertPlan {
  id: string;
  name: string;
  description?: string;
  actions: AlertAction[];
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  isShared: boolean;
  numAlertRules: number;
  lastSavedToolTipInfo: ReplaySubject<string>;
  lastSavedLabelInfo: ReplaySubject<string>;
  isBuiltIn: boolean;

  constructor(params?: any) {
    Object.assign(this, params);
  }
}
