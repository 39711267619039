import { AuditHealthWidgetResponse } from '../models/audit-health.models';
import * as fromAuditHealth from './audit-health.actions';

export interface AuditHealthState {
  data: AuditHealthWidgetResponse;
  loading: boolean;
  error: any;
  updating: boolean;
  expired: boolean;
}

export const initialState: AuditHealthState = {
  data: null,
  loading: false,
  error: undefined,
  updating: false,
  expired: false
};

export function reducer(
  state: AuditHealthState = initialState,
  action: fromAuditHealth.AuditHealthAction
): AuditHealthState {
  switch (action.type) {
    case fromAuditHealth.LOAD_AUDIT_HEALTH:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromAuditHealth.LOAD_AUDIT_HEALTH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        expired: false,
        error: undefined
      };
    case fromAuditHealth.LOAD_AUDIT_HEALTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case fromAuditHealth.UPDATE_AUDIT_HEALTH_SUCCESS:
      return {
        ...state,
        updating: false,
        expired: true,
        error: null
      };
    case fromAuditHealth.UPDATE_AUDIT_HEALTH_FAIL:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case fromAuditHealth.UPDATE_AUDIT_HEALTH:
      return {
        ...state,
        updating: true
      };

    default:
      return state;
  }
}
