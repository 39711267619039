import { QueryClause } from './query-clause';
import { QueryChart as QueryChart } from './chart-options/query-chart';

const optionalProperties: string[] = ['quickSearchText', 'sortBy', 'sortDir', 'limit', 'skip'];

export class QueryBody {
  clauses: QueryClause[] = [];
  grouping: string;
  columns: string[] = [];
  quickSearchText?: string;
  sortBy?: string;
  sortDir?: 'asc' | 'desc';
  summarizeOptions: any;
  charts: QueryChart[] = [];
  [index: string]: any;

  constructor(params?: any) {
    Object.assign(this, params);

    this.clauses = this.clauses.map((clause) => new QueryClause(clause));
    this.charts = this.charts.map((chart) => new QueryChart(chart));
  }

  toJSON() {
    let output: any = {
      clauses: this.clauses.map(clause => clause.toJSON()),
      grouping: this.getGrouping(),
      columns: this.columns ? [...this.columns] : [],
      charts: this.charts ? [...this.charts] : []
    };
    this.copyOptionalProperties(output);

    return output;
  }

  deepClone(): QueryBody {
    const query = new QueryBody();
    query.grouping = this.getGrouping();
    query.clauses = this.cloneClauses();
    query.charts = this.cloneCharts();
    this.copyOptionalProperties(query);
    Object.assign(query.columns, this.columns);
    query.columns = [...this.columns];

    return query;
  }

  equals(query: QueryBody): boolean {
    return JSON.stringify(this) === JSON.stringify(query);
  }

  /**
   * To keep the JSON simplified, only set optional properties if they
   * have a value set
   *
   * @param target Object to modify
   */
  private copyOptionalProperties(target: any) {
    optionalProperties.forEach((property) => {
      if (this.hasOwnProperty(property)) {
        target[property] = this[property];
      }
    });
  }

  private cloneClauses(): QueryClause[] {
    return this.clauses.map(clause => new QueryClause(clause));
  }

  private cloneCharts(): QueryChart[] {
    return this.charts.map(chart => new QueryChart(chart));
  }

  private getGrouping() {
    return this.clauses.map((_clause, i) => i + 1).join(' AND ');
  }
}
