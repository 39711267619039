<form #f="ngForm">
  <div class="input-field">

    <!-- Between numbers -->
    <ng-container *ngIf="operator === 'between_number'; else singleValueField">
      <input
        placeholder="{{labels.pages.newSearches.minimum}}"
        #number1="ngModel"
        type="number"
        name="value-min-{{index}}"
        [(ngModel)]="rangeMin"
        (change)="onFieldChange()"
        id="value-min-{{index}}"
        class="number-field number-range-1"
        autocomplete="off"
        required
      >

      <div class="field-separator">-</div>

      <input
        placeholder="{{labels.pages.newSearches.maximum}}"
        #number2="ngModel"
        type="number"
        name="value-max-{{index}}"
        [(ngModel)]="rangeMax"
        (change)="onFieldChange()"
        id="value-max-{{index}}"
        class="number-field number-range-2"
        autocomplete="off"
        required
      >
    </ng-container>

    <!-- Single value -->
    <ng-template #singleValueField>
      <input
        placeholder="{{labels.pages.newSearches.value}}"
        #fieldValue="ngModel"
        type="number"
        name="value-{{index}}"
        [(ngModel)]="value"
        (change)="onFieldChange()"
        id="value-{{index}}"
        class="number-field single-number"
        autocomplete="off"
        required
      >
    </ng-template>

  </div>
</form>
