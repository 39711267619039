import {
  ActionReducerMap,
  createFeatureSelector,
  MemoizedSelector
} from '@ngrx/store';
import * as fromWidgetList from '../widgets/state/widget-list.reducer';
import * as fromFavoriteSearchEditor from '../widgets/favorite-search/editor/state/favorite-search-editor.reducer';
import * as fromFavoriteSearch from '../widgets/favorite-search/state/favorite-search.reducer';
import * as fromSignins from '../widgets/sign-ins/state/sign-ins.reducer';
import * as fromCriticalActivity from '../widgets/critical-activity/state/critical-activity.reducer';
import * as fromAuditHealth from '../widgets/audit-health/state/audit-health.reducer';
import * as fromStaticIndicaotr from '../widgets/static-indicator/state/static-indicator.reducer';
import * as fromTopActiveUsers from '../widgets/top-active-users/state/top-active-users.reducer';

export interface AuditingDashboardState {
  widgetList: fromWidgetList.WidgetListState;
  favoriteSearch: fromFavoriteSearch.FavoriteSearchState;
  favoriteSearchEditor: fromFavoriteSearchEditor.FavoriteSearchEditorState;
  signins: fromSignins.SigninsState;
  criticalActivity: fromCriticalActivity.CriticalActivityState;
  auditHealth: fromAuditHealth.AuditHealthState;
  staticIndicator: fromStaticIndicaotr.StaticIndicatorState;
  topActiveUsers: fromTopActiveUsers.TopActiveUsersState;
}

export const reducers: ActionReducerMap<AuditingDashboardState> = {
  widgetList: fromWidgetList.reducer,
  favoriteSearch: fromFavoriteSearch.reducer,
  favoriteSearchEditor: fromFavoriteSearchEditor.reducer,
  signins: fromSignins.reducer,
  criticalActivity: fromCriticalActivity.reducer,
  auditHealth: fromAuditHealth.reducer,
  staticIndicator: fromStaticIndicaotr.reducer,
  topActiveUsers: fromTopActiveUsers.reducer
};

export const selectAuditingDashboardFeatureState = createFeatureSelector<AuditingDashboardState>(
  'auditingDashboard'
);
