import { StaticIndicatorID } from '../../../models/widget-info';
import {
  LoadAdAccountLockouts,
  LoadAllFailedSignins,
  LoadAzureADCriticalRoleChanges,
  LoadAzureADFailedSignins,
  LoadAzureADRiskEvents,
  LoadCloudOnlyAzureADUsersCreated,
  LoadO365ExternalUserAction,
  StaticIndicatorAction,
  UpdateTrendColorAdAccountLockouts,
  UpdateTrendColorAllFailedSignins,
  UpdateTrendColorAzureADCriticalRoleChanges,
  UpdateTrendColorAzureADFailedSignins,
  UpdateTrendColorAzureADRiskEvents,
  UpdateTrendColorCloudOnlyAzureADUsersCreated,
  UpdateTrendColorO365ExternalUserAction
} from './static-indicator.actions';

export const createStaticIndicatorAction = (
  key: StaticIndicatorID
): StaticIndicatorAction => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return new LoadAdAccountLockouts();

    case StaticIndicatorID.AllFailedSignins:
      return new LoadAllFailedSignins();

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return new LoadAzureADCriticalRoleChanges();

    case StaticIndicatorID.AzureADFailedSignins:
      return new LoadAzureADFailedSignins();

    case StaticIndicatorID.AzureADRiskEvents:
      return new LoadAzureADRiskEvents();

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return new LoadCloudOnlyAzureADUsersCreated();

    case StaticIndicatorID.O365ExternalUserActions:
      return new LoadO365ExternalUserAction();

    default:
      throw new Error('Unrecognized ID');
  }
};

export const createUpdateTrendColorAction = (
  key: StaticIndicatorID,
  trendColor: string
): StaticIndicatorAction => {
  switch (key) {
    case StaticIndicatorID.AdAccountLockouts:
      return new UpdateTrendColorAdAccountLockouts(trendColor);

    case StaticIndicatorID.AllFailedSignins:
      return new UpdateTrendColorAllFailedSignins(trendColor);

    case StaticIndicatorID.AzureADCriticalRoleChanges:
      return new UpdateTrendColorAzureADCriticalRoleChanges(trendColor);

    case StaticIndicatorID.AzureADFailedSignins:
      return new UpdateTrendColorAzureADFailedSignins(trendColor);

    case StaticIndicatorID.AzureADRiskEvents:
      return new UpdateTrendColorAzureADRiskEvents(trendColor);

    case StaticIndicatorID.CloudOnlyAzureADUsersCreated:
      return new UpdateTrendColorCloudOnlyAzureADUsersCreated(trendColor);

    case StaticIndicatorID.O365ExternalUserActions:
      return new UpdateTrendColorO365ExternalUserAction(trendColor);

    default:
      throw new Error('Unrecognized ID');
  }
};
