/**
 * Service for getting information about event retention settings
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, timeout } from 'rxjs/operators';
import { HttpRequestMethod, OnDemandTokenHttp, Util } from '@ondemand/core';
import { ServiceDiscoveryService } from '../service-discovery.service';
import { defaultHttpRequestTimeout } from '../../util/constants';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

export interface EventRetentionTableEntry {
  serviceName: string;
  eventTypeName: string;
  eventTypeDescription?: string;
  subscriptionTypeLabel: string;
  retentionPeriodLabel: string;
}

@Injectable()
export class RetentionInfoService {

  private configuration = {
    withCredentials: false
  };

  constructor(private http: OnDemandTokenHttp,
              private serviceDiscoveryService: ServiceDiscoveryService) {
  }

  getRetentionTableEntries(): Observable<EventRetentionTableEntry[]> {
    return this.serviceDiscoveryService.getAuditConfigUrl$().pipe(take(1), switchMap((basePath) => {
      const path = `${basePath}/eventRetentionInfo`;
      let headers = new HttpHeaders();
      headers = headers.set('On-Demand-User-Locale', Util.userLocale);
      let requestOptions = {
        headers,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(
          timeout(defaultHttpRequestTimeout),
          map((response: HttpResponse<any>) => {
            let tableEntries = response.body;
            return tableEntries;
          })
        );
    }));
  }

  getRetentionInfoByService(): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService.getAuditConfigUrl$().pipe(take(1), switchMap((basePath) => {
      const path = `${basePath}/workloads`;
      let requestOptions = {
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions).pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

}
