<span class="content-sized-container qod-tooltip qod-tooltip-bottom">
  <label 
    [ngClass]="{'noClickAllowed': this.legendData.disable }"
    [for]="checkboxId" 
    class="legend-item-container">
    
    {{legendData.label}}
    
    <input
      [disabled]="legendData.disable" 
      [id]="checkboxId" 
      type="checkbox" 
      [value]="legendData.dataKey" 
      [name]="checkboxId" 
      [(ngModel)]="legendData.checked" 
      (change)="onCheckChange()" 
      />

    <span class="checkmark" [style.border-color]="legendData.borderColor" [style.background-color]="checkboxColor" ></span>

  </label>
  
  <span class="qod-tooltiptext">{{legendData.tooltip}}</span>

</span>

