<div class="alert-container" *ngIf="!enableNotificationPlansFlag">
  <div class="alert-navigation-container">
    <ul class="links">
      <li
        id="alert-rule-list-link"
        class="alert-rule-list-link"
        [routerLink]="['/auditing/auditing/alerts']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        *ngIf="canViewAlertRules()"
      >
        <edc-icon name="bell"></edc-icon>
        {{ 'auditing.alertRuleListNavTitle' | localize }}
      </li>

      <li
        id="alert-plan-list-link"
        class="alert-plan-list-link"
        [routerLink]="['/auditing/auditing/alerts/plans']"
        routerLinkActive="active"
        *ngIf="canViewAlertPlans()"
      >
        <edc-icon name="queue"></edc-icon>
        {{ 'auditing.alertPlanListNavTitle' | localize }}
      </li>
    </ul>
  </div>

  <div class="alert-content">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="alert-rule-container" *ngIf="enableNotificationPlansFlag">
  <div class="alert-rule-content">
    <router-outlet></router-outlet>
  </div>
</div>
