import { filter, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ElementRef , ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Results } from '../results.model';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import { ExportQueryResultsService } from '../../../../services/export-query-results.service';
import { exportRowLimit } from '../../../../util/constants';
import { Query } from '../../../../models/query.model';
import { ExportQueryParams } from '../../../../models/export-query-params';
import { QueryBody } from '../../../../models/query-body';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../../shared/utils/toast.wrapper';

import {
  AppState,
  BaseComponent,
  CreateExportJobRequest,
  EModuleName,
  State,
  ExportJob,
  getActiveExportJob,
  IModalWindow,
  EDialogType,
  ExportFileType,
  AppFacadeService
} from '@ondemand/core';

const emptyGuid = '00000000-0000-0000-0000-000000000000';

@Component({
  selector: 'create-export',
  templateUrl: './create-export.component.html',
  styleUrls: ['./create-export.component.scss']
})
export class CreateExportComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() results: Results;
  @Input() currentQuery: Query;
  @Input() adHocMode: boolean;
  @Output() exportStarted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('exportingMessage') loadingContainer: ElementRef;
  activeExportJob: ExportJob;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  exportLimitModalParams: IModalWindow = { showModal: false };
  exportCancelModalParams: IModalWindow = { showModal: false };
  exportRowLimitDescription: string;
  changePageExplanation: string;
  exportCancelDescription: string;
  exportingLabel: string;
  isExporting = false;
  exportHeight: string;

  private _userExportSearchResultsPermission = false;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<State>,
    private permissionsService: AuditModulePermissionsService,
    private exportQueryResultsService: ExportQueryResultsService,
    private toastService: ODToastService
  ) {
    super();
  }

  get userHasExportSearchResultsPermissions() {
    return this._userExportSearchResultsPermission;
  }

  get searchResultsCount() {
    const totalRowsIsDefined =
      this.results !== undefined &&
      this.results !== null &&
      this.results.totalRows !== undefined &&
      this.results.totalRows !== null;

    if (totalRowsIsDefined) {
      return this.results.totalRows;
    } else {
      return 0;
    }
  }

  ngOnDestroy() {
    this.onExportCancel();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.setPermissions();
    this.beginSubscribeToStore();
  }

  handleExportAction(action: string) {
    if (this.results.totalRows > exportRowLimit) {
      this.showExportLimitWarning(action);
    } else {
      this.createExportRequest(action);
    }
  }

  /**
   * Display modal dialog warning users that they have clicked the cancel button of export operation.
   */
  showExportCancelWarning() {
    this.exportCancelDescription =  this.applicationStrings.exports.cancelConfirmationDescription;
    this.exportCancelModalParams = {
      showModal: true,

      dialogParams: {
        title: this.applicationStrings.exports.cancelConfirmationTitle,
        type: EDialogType.WARNING,
        actions: [
          {
            name: this.applicationStrings.yes,
            action: () => {
              this.exportLimitModalParams.showModal = false;
              this.onExportCancel();
            }
          }
        ],
        cancelText: this.applicationStrings.no,
        cancelButtonAction: () => {
          this.exportLimitModalParams.showModal = false;
        }
      }
    };
  }

  private beginSubscribeToStore() {
    this.store
      .select(getActiveExportJob).pipe(
        takeUntil(this.destructionSubject),
        filter((activeJob) => !!activeJob)
      ).subscribe((exportJob: ExportJob) => {
        this.activeExportJob = exportJob;
      });
  }

  private beginPolling() {
    this.store
      .select(getActiveExportJob).pipe(
        filter((activeExport) => !!activeExport),
        take(1)
      ).subscribe((activeExport) => {
        this.store.dispatch(
          new AppState.Actions.Exports.PollExportJobStatus({
            moduleName: activeExport.moduleName,
            exportId: activeExport.exportId
          })
        );
      });
  }

  private clearExportQueryResults() {
    this.stopPolling();
    this.updateExportingStatus(false);
  }

  private stopPolling() {
    this.store.dispatch(
      new AppState.Actions.Exports.StopPollingForExportStatus()
    );
  }

  private registerDownloadReadyAction() {
    this.store
      .select(getActiveExportJob).pipe(
        takeUntil(this.destructionSubject),
        filter((activeExport) => !!activeExport),
        filter((activeExport) => activeExport.status === 'complete'),
        take(1)
      ).subscribe((activeExport) => {
        this.clearExportQueryResults();
        toastLowerLeft(this.toastService, this.applicationStrings.exports.downloadStartedMessage, ToastType.Success);
        const blobElement = document.createElement('a');
        blobElement.href = activeExport.downloadLink;
        document.body.appendChild(blobElement); // Required for firefox
        blobElement.click();
        document.body.removeChild(blobElement);
        blobElement.remove();
      });
  }

  private registerExportErrorAction() {
    this.store
      .select(getActiveExportJob).pipe(
        takeUntil(this.destructionSubject),
        filter((activeExport) => !!activeExport),
        filter((activeExport) => activeExport.status === 'error'),
        take(1)
      ).subscribe(() => {
        toastLowerLeft(this.toastService, this.applicationStrings.exports.errorMessage, ToastType.Error);
        this.clearExportQueryResults();
      });
  }

  private createExportWithParams(_fileType: 'csv' | 'zip', _parameters: object) {
    const exportJobRequest: CreateExportJobRequest = {
      fileType: _fileType,
      moduleName: EModuleName.AUDITING,
      parameters: _parameters
    };

    this.store.dispatch(
      new AppState.Actions.Exports.CreateExportJob(exportJobRequest)
    );

    // Begin polling the status of export request
    this.beginPolling();
    this.registerDownloadReadyAction();
    this.registerExportErrorAction();
    this.exportQueryResultsService.setExportQueryResultsStatus(true);
  }

  private createExportRequest(fileType: string) {
    let queryId = '';
    if (this.adHocMode === true) {
      queryId = emptyGuid;
    } else {
      queryId = this.currentQuery.id;
    }
    let exportQueryParams: ExportQueryParams = {
      // Export Request parameters
      QueryId: queryId,
      Name: this.currentQuery.name,
      Q: new QueryBody(this.currentQuery.q),
      BaseUrl: window.location.origin
    };

    // Update the query limit and skip values.
    let skip = 0;
    let extraParams: any = {
      skip,
      limit: (this.results.totalRows > exportRowLimit) ? exportRowLimit : this.results.totalRows
    };
    let validParameters = ['limit', 'skip'];
    Object.keys(extraParams).forEach((paramName: string) => {
      if (validParameters.includes(paramName)) {
        exportQueryParams.Q[paramName] = extraParams[paramName];
      }
    });

    let type: ExportFileType = (fileType === 'csv') ? 'csv' : 'zip';
    this.createExportWithParams(type, exportQueryParams);
    toastLowerLeft(this.toastService, this.applicationStrings.exports.startedMessage, ToastType.Success);
    this.changePageExplanation = this.applicationStrings.exports.spinnerDescription;
    this.exportingLabel = this.applicationStrings.exports.spinnerLabel;
    this.updateExportingStatus(true);
  }

  /**
   * Display modal dialog warning users that they have hit the limit on the number
   * of events that can be exported
   */
  private showExportLimitWarning(action: string) {
    this.exportRowLimitDescription =  this.applicationStrings.exports.rowLimitDescription;
    this.exportLimitModalParams = {
      showModal: true,

      dialogParams: {
        title: this.applicationStrings.exports.rowLimitTitle,
        type: EDialogType.WARNING,
        actions: [
          {
            name: this.applicationStrings.exports.okLabel,
            action: () => {
              this.exportLimitModalParams.showModal = false;
              this.createExportRequest(action);
            }
          }
        ],
        cancelText: this.applicationStrings.exports.cancelLabel,
        cancelButtonAction: () => {
          this.exportLimitModalParams.showModal = false;
        }
      }
    };
  }

  private onExportCancel() {
    if (this.isExporting === true) {
      this.clearExportQueryResults();
      toastLowerLeft(this.toastService, this.applicationStrings.exports.exportCancelled, ToastType.Success);
    }
  }

  private updateExportingStatus(status: boolean) {
    this.isExporting = status;
    this.exportStarted.emit(status);
    this.exportQueryResultsService.setExportQueryResultsStatus(status);
  }

  private setPermissions(): void {
    this._userExportSearchResultsPermission = this.permissionsService.hasAuditForCoreContextSensitivePermission('core.exportData');
  }
}
