export const QueryClauseNumberDataTypes: string[] = [
  'int32',
  'int64',
  'double',
  'boolean'
];

export enum QueryClauseOperator {
  IN = 'in',
  NOT_IN = 'not_in',
  EQUALS = 'equals',
  EQUALS_NUMBER = 'equals_number',
  BETWEEN_DATE = 'between_date',
  DURING_LAST = 'during_last'
}
