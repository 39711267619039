import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CriticalActivityDataService } from '../../../../../shared/services/critical-activity/critical-activity.data.service';
import {
  LoadCriticalActivityList,
  LoadCriticalActivityListFail,
  LoadCriticalActivityListSuccess,
  LOAD_CRITICAL_ACTIVITY_LIST,
  UPDATE_CRITICAL_ACTIVITY_LIST,
  UpdateCriticalActivityList,
  UpdateCriticalActivityListFail,
  UpdateCriticalActivityListSuccess
} from './critical-activity-list.actions';

@Injectable()
export class CriticalActivityListEffects {
  loadCriticalActivity$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadCriticalActivityList>(LOAD_CRITICAL_ACTIVITY_LIST),
    switchMap(action =>
      this.criticalActivityService.getFullPageCriticalActivity().pipe(
        map(response => new LoadCriticalActivityListSuccess(response)),
        catchError(error => of(new LoadCriticalActivityListFail(error)))
      )
    )
  ));

    updateCriticalActivity$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType<UpdateCriticalActivityList>(UPDATE_CRITICAL_ACTIVITY_LIST),
      switchMap(action =>
        this.criticalActivityService.UpdateCriticalActivityConfig(action.payload).pipe(
          map(response => response.status === 200
              ? new UpdateCriticalActivityListSuccess()
              : new UpdateCriticalActivityListFail(
                  `Unexpected response code received: ${response.status}`
                )),
          catchError(error => of(new UpdateCriticalActivityListFail(error)))
        )
      )
    ));

    constructor(
        private criticalActivityService: CriticalActivityDataService,
        private actions$: Actions
      ) {}
}
