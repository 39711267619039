import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ODChartColor } from '../../../../models/oda-colors';
import { CheckLegendData } from '../../models/legend-data';

@Component({
  selector: 'oda-check-legend-item-v1',
  templateUrl: './check-legend-item.v1.component.html',
  styleUrls: ['./check-legend-item.v1.component.scss']
})
export class CheckLegendItemV1Component implements OnInit {
  @Input() legendData: CheckLegendData;
  @Output() checkChanged: EventEmitter<CheckLegendData>;

  checkboxColor: ODChartColor;

  get checkboxId(): string {
    return `label-${this.legendData.dataKey}`;
  }

  constructor() {
    this.checkChanged = new EventEmitter<CheckLegendData>();
  }

  ngOnInit(): void {
    this.setCheckboxColor();
  }

  onCheckChange(): void {
    this.setCheckboxColor();
    this.checkChanged.emit(this.legendData);
  }

  private setCheckboxColor(): void {
    this.checkboxColor = this.legendData.checked
      ? this.legendData.backColor
      : ODChartColor.White;
  }
}
