import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  CriticalActivityConfiguration,
  CriticalActivityDataResponse
} from '../../../../../../shared/models/critical-activity.shared.models';
import * as fromFeature from '../../../reducers/index';
const getCriticalActivityState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.criticalActivity
);

export const selectCriticalActivity = createSelector(
  getCriticalActivityState,
  state => state.data
);

export const selectCriticalActivityLoading = createSelector(
  getCriticalActivityState,
  state => state.loading
);

export const selectCriticalActivityError = createSelector(
  getCriticalActivityState,
  state => state.error
);
