<form #f="ngForm">
  <div class="input-field">
    <ng-container *ngIf="multiple">
      <!-- IMPORTANT: Keep the HTML for "multiple" and single selection menus
           in sync when making changes -->
      <select
        materialize="material_select"
        [materializeSelectOptions]="availableValues"
        [(ngModel)]="selectedOptions"
        [disabled]="valuesLoading"
        (change)="onValueChange()"
        name="value-{{index}}"
        id="value-{{index}}"
        multiple
      >
        <option [ngValue]="null" disabled>{{ labels.pages.newSearches.multiValuePlaceholder }}</option>
        <ng-container *ngFor="let item of availableValues">
          <option [ngValue]="item">{{ item.label }}</option>
        </ng-container>
      </select>
    </ng-container>
    <ng-container *ngIf="!multiple">
      <select
        materialize="material_select"
        [materializeSelectOptions]="availableValues"
        [(ngModel)]="selectedOption"
        [disabled]="valuesLoading"
        (change)="onValueChange()"
        name="value-{{index}}"
        id="value-{{index}}"
      >
        <option [ngValue]="null" disabled>{{ labels.pages.newSearches.predefinedPlaceholder}}</option>
        <ng-container *ngFor="let item of availableValues">
          <option [ngValue]="item">{{ item.label }}</option>
        </ng-container>
        </select>
    </ng-container>

  </div>
</form>
<error-message *ngIf="error">{{ error }}</error-message>
<error-message *ngIf="invalidSelections">{{ labels.pages.newSearches.invalidPredefinedValuesError }} {{ invalidSelections }}</error-message>
