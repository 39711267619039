/**
 * Component for quick search results
 */
import { debounce, map, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { ResultsComponent } from '../results.component';
import { QueryService } from '../../../../services/query/query.service';
import { ActiveQueryService } from '../../../../services/active-query.service';
import { SavedQueriesService } from '../../../../services/saved-queries.service';
import { AuditingBreadcrumbsService } from '../../../../services/auditing-breadcrumbs.service';
import { Query } from '../../../../models/query.model';
import { QueryBody } from '../../../../models/query-body';
import { Results } from '../results.model';
import { defaultQuickSearchColumns } from '../../default-query-columns';
import { timer } from 'rxjs';
import { Location } from '@angular/common';
import { EventFieldsService } from '../../../../services/event-fields.service';
import { fixedEncodeURIComponent } from '../../../../util/url-encode';
import { ErrorMessageService } from '../../../../services/error-message.service';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import { ODToastService } from '@ondemand/ui-components';
import { EventField } from '../../../../models/event-field.model';
import { LocalizationPipe } from '../../../../../shared/pipes/localization/localization.pipe';

@Component({
  selector: 'quick-search-results',
  templateUrl: './quick-search-results.component.html',
  styleUrls: ['./quick-search-results.component.scss']
})
export class QuickSearchResultsComponent
  extends ResultsComponent
  implements OnInit {
  @ViewChild('f', { static: true }) form: NgForm;
  loadingQuery = true;
  auditingDisplayStringsProvider = AuditingDisplayStringsProvider;
  labels = this.auditingDisplayStringsProvider.auditing;
  queryString = '';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _queryService: QueryService,
    _savedQueryService: SavedQueriesService,
    activeQueryService: ActiveQueryService,
    _router: Router,
    private _breadcrumbsService: AuditingBreadcrumbsService,
    _eventFieldsService: EventFieldsService,
    private locationService: Location,
    private renderer2: Renderer2,
    private _errorService: ErrorMessageService,
    permissionsService: AuditModulePermissionsService,
    toastService: ODToastService
  ) {
    super(
      _activatedRoute,
      _queryService,
      _savedQueryService,
      activeQueryService,
      _router,
      _breadcrumbsService,
      _eventFieldsService,
      null,
      null,
      null,
      _errorService,
      permissionsService,
      toastService
    );
  }

  async ngOnInit() {
    this.updateBreadcrumbs();
    this.subscribeToQueryChanges();

    this._activatedRoute.url
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(urlPieces => {
        const queryInputElement = this.renderer2.selectRootElement(
          '#large-quick-search-query'
        );
        queryInputElement.focus();

        if (urlPieces[1] === null || urlPieces[1] === undefined) {
          this.currentQuery = this.createQuery();
          return;
        }

        const queryString = decodeURIComponent(urlPieces[1].path);
        this.queryString = queryString;

        const quickQuery = this.createQuery(queryString);
        this.currentQuery = quickQuery;

        this.activeQueryService.setQuery(this.currentQuery, this.currentQuery);
        this.loadingQuery = false;


      });

    this.eventFields = await this.eventFieldsService
      .getFieldsWithHttpInfo()
      .pipe(
        take(1),
        map(response => {
          const dataFields = response.body.availableFields as object[];
          const mappedFields = dataFields.map(data => new EventField(data));
          return mappedFields;
        })
      )
      .toPromise();

      super.setPermissions();
  }

  updateBreadcrumbs() {
    const title = this.applicationStrings.quickSearchBreadcrumb;
    this._breadcrumbsService.set([
      {
        title: this.applicationStrings.queries,
        url: 'auditing/auditing/queries'
      },
      {
        title,
        url: ''
      }
    ]);
  }

  /**
   * Run query and load results to component
   *
   */
  getResults(): Promise<any> {
    this.loadingResults = true;

    // Can't run a query with quick search text AND  clauses
    // Enforcing that here for now until refactoring can be done
    this.currentQuery.q.clauses = [];

    this.activeQueryService.setQuery(
      this.currentQuery.deepClone(),
      this.currentQuery
    );

    let promise: Promise<any> = new Promise<any>((resolve, reject) => {
      this.loadingResults = true;
      this.queryFailedErrorMessage = null;
      let skip = this.resultsPerPage * this.currentPage;
      let params: any = {
        skip,
        limit: this.resultsPerPage
      };

      this._queryService
        .runQueryWithHttpInfo(this.currentQuery, params, this.adHocMode)
        .subscribe(
          response => {
            this.loadingResults = false;
            this.results = new Results(response.body);

            resolve(this.results);
          },
          async response => {
            // Set default error message
            this.queryFailedErrorMessage =
              await this._errorService.getErrorMessage(response);
            this.loadingResults = false;
            this.results = null;
            reject();
          }
        );
    });

    return promise;
  }

  onSubmit() {
    this.runQuery();
  }

  /**
   * Handle export started event
   *
   * @param event status from "exportStarted" event from create-export component
   */
  onExportStarted(exportStarted: boolean) {
    this.isExporting = exportStarted;
  }

  // Currently not supported for quick search results
  onChartCategoryClicked(updatedQuery: Query): void {
    return;
  }

  // Currently not supported for quick search results
  onChartElementClicked(updatedQuery: Query): void {
    return;
  }

  private createQuery(queryString: string = '') {
    return new Query({
      name: `Quick Search - ${new Date().toDateString()}`,
      q: new QueryBody({
        quickSearchText: queryString,
        columns: defaultQuickSearchColumns
      }),
      id: null
    });
  }

  /**
   * Subscribes to changes in the form to determine when the search needs to be re-run
   *
   */
  private subscribeToQueryChanges() {
    this.form.valueChanges
      .pipe(
        debounce(() => timer(500)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((_changes: any) => {
        this.runQuery();
      });
  }

  private runQuery() {
    if (this.queryString && this.queryString.trim()) {
      const trimmedQueryString = this.queryString.trim();
      let encodedQueryString = fixedEncodeURIComponent(trimmedQueryString);
      this.locationService.replaceState(
        `auditing/auditing/queries/quickresults/${encodedQueryString}`
      );
      this.currentQuery.q.quickSearchText = trimmedQueryString;

      // Trigger change detection
      this.currentQuery = this.currentQuery.deepClone();

      this.getResults().catch(() => {
        return;
      });
    } else {
      this.loadingResults = false;
    }
  }
}
