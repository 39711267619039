/**
 * Service for managing creation of donut charts and creating their associated tooltips
 */
import {
  EventEmitter,
  Injectable,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { Router } from '@angular/router';
import { cloneAndUnlockObject } from '../../../../utils/object.tools';
import { QueryClause } from '../../../../../auditing/models/query-clause';
import { Query } from '../../../../../auditing/models/query.model';
import { ActiveQueryService } from '../../../../../auditing/services/active-query.service';
import { ChartPermissionsService } from '../../services/chart-permissions.service';
import { OthersColumnID } from '../../models/chart-constants';
import { EventField } from '../../../../../auditing/models/event-field.model';
import {
  QueryClauseNumberDataTypes,
  QueryClauseOperator
} from '../../../../models/query-constants';
import { ChartData, ChartTooltipItem } from 'chart.js';
import { ODColor } from '../../../../models/oda-colors';

@Injectable()
export class DonutConfigurationService {
  renderer: Renderer2;
  hasPermission: boolean;
  constructor(
    rendererFactory: RendererFactory2,
    private chartPermisionService: ChartPermissionsService,
    private activeQueryService: ActiveQueryService,
    private router: Router
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  generateDonutOptions(
    query: Query,
    selectedDatalabels: string[],
    selectedDataValues: string[],
    groupByField: EventField,
    chartAreaClick: EventEmitter<Query>
  ) {
    return {
      responsive: true,
      scaleShowVerticalLines: true,
      maintainAspectRatio: false,
      title: {
        fontFamily: 'Open Sans',
        fontColor: '#888888',
        fontStyle: 'bold'
      },
      legend: {
        display: false
      },
      animation: {
        duration: 0
      },
      cutoutPercentage: 70,
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        animation: false,
        mode: 'nearest',
        backgroundColor: ODColor.GS_Dark_Gray, // matching core UI tolltip background color
        titleFontSize: 14, // 14 is 1 rem
        titleFontStyle: 'normal',
        bodyFontSize: 14,
        events: ['mousemove', 'mouseout'],
        callbacks: {
          label: (item: ChartTooltipItem, data: ChartData): string => getFormattedLabel(item, data, selectedDatalabels)
        }
      },
      events: ['click', 'mousemove', 'mouseout'],
      onClick: (event: any, chartElements: any[]) => {
        // check drilldown permission.
        if (
          !this.chartPermisionService.userHasChartDrillDownPermission() ||
          chartElements.length < 1
        ) {
          return;
        }
        this.clickHandlerForDonutchart(
                                        selectedDatalabels,
                                        selectedDataValues,
                                        chartElements,
                                        query,
                                        groupByField,
                                        chartAreaClick
                                      );
      },
      onHover: (event: any, chartElements: any[]) => {
        this.mouseHoverEventHandler(event, chartElements, selectedDatalabels);
      }
    };
  }

  // Handles click event for a chart area.
  clickHandlerForDonutchart(
    selectedDataLabels: string[],
    selectedDataValues: string[],
    chartElement: any[],
    query: Query,
    groupByField: EventField,
    chartAreaClick: EventEmitter<Query>
  ): void {
    let drilldownClause: QueryClause;

    if (chartElement && chartElement.length > 0) {
      const selectedDataIndex = chartElement[0]._index;
      const selectedFieldText = selectedDataLabels[selectedDataIndex];
      const selectedFieldValue: string = selectedDataValues[selectedDataIndex];
      let selectedElement: string | boolean = selectedFieldValue;

      const exclusionFieldValues: string[] = selectedDataValues.filter(
        label => label !== OthersColumnID
      );

      let isOthersCategorySelected: boolean = (selectedElement === OthersColumnID);

      const groupingColumn = groupByField.id;
      if (isOthersCategorySelected && selectedDataValues.length === 1) {
        // From design doc:
        // When "Other" is the only item shown on the legend (edge-case),
        // the search will simply be re-run since there are no other items
        // available to exclude in the search filters.
        chartAreaClick.emit(query);
        return;
      } else if (!isOthersCategorySelected && selectedDataValues.length === 1) {
        // prevent further processing if there is only 1 category (non-others) left
        return;
      }

      const tooltipEl = document.getElementById('chartjs-tooltip');
      if (tooltipEl) {
        // Tooltip element was staying on-screen after navigation, needed to clear it.
        tooltipEl.remove();
      }

      const mutableQuery = cloneAndUnlockObject(query);
      let clauseIndex = -1;

      if (selectedElement) {
        if (isOthersCategorySelected) {
          clauseIndex = mutableQuery.q.clauses.findIndex(
            clause =>
              clause.field === groupingColumn &&
              clause.operator === QueryClauseOperator.NOT_IN
          );

          if (clauseIndex < 0) {
            drilldownClause = new QueryClause({
              field: groupingColumn,
              operator: QueryClauseOperator.NOT_IN,
              value: null,
              values: exclusionFieldValues
            });
          } else {
            drilldownClause = mutableQuery.q.clauses[clauseIndex];
            drilldownClause.values = [
              ...drilldownClause.values,
              ...exclusionFieldValues
            ];
          }
        } else {
          let equalOperator = QueryClauseOperator.EQUALS;
          if (groupByField.predefinedValuesOnly) {
            if (QueryClauseNumberDataTypes.includes(groupByField.dataType)) {
              equalOperator = QueryClauseOperator.EQUALS_NUMBER;
              // boolean from back end was converted to "True" / "False" strings
              if (groupByField.dataType === 'boolean') {
                selectedElement = (selectedFieldValue.toLowerCase() === 'true');
              }
            }
          }
          // Determine if filter clause already exist.
          clauseIndex = mutableQuery.q.clauses.findIndex(
            clause =>
              clause.field === groupingColumn &&
              clause.operator === QueryClauseOperator.EQUALS
          );

          if (clauseIndex < 0) {
            drilldownClause = new QueryClause({
              field: groupingColumn,
              operator: equalOperator,
              value: selectedElement
            });
          }
        }

        mutableQuery.name = `${query.name} - ${groupByField.displayName} : ${selectedFieldText}`;
      }

      // An observer is set on search result view to perform drilldown operation.
      if (chartAreaClick.observers.length) {
        if (drilldownClause) {
          if (clauseIndex < 0) {
            mutableQuery.q.clauses.push(drilldownClause);
          } else {
            mutableQuery.q.clauses[clauseIndex] = drilldownClause;
          }
          chartAreaClick.emit(mutableQuery);
        }
      } else {
        // When donut area is clicked on critical activity tab.
        if (exclusionFieldValues.length > 0) {
          mutableQuery.q.clauses.push(drilldownClause);
        }
        mutableQuery.q.summarizeOptions = null;
        const adhocQuery = new Query({
          name: mutableQuery.name,
          q: mutableQuery.q,
          isShared: false,
          hideCharts: false
        });
        const resultsUrl = '/auditing/auditing/queries/results';
        this.activeQueryService.setQuery(adhocQuery, adhocQuery);
        this.router.navigate([resultsUrl]);
      }
    }
  }

  customTitleCallback(context: any) {
    return '%l %p';
  }

  private mouseHoverEventHandler(
          event: any,
          chartElements: any[],
          labels: string[]
        ): void {
    let cursorStyle = 'default';

    if (chartElements && chartElements.length > 0 ) {
      const currentDataIndex = chartElements[0]._index;
      const isOthers = labels[currentDataIndex] === OthersColumnID;
      const hasPermission: boolean =
      this.chartPermisionService.userHasChartDrillDownPermission();

      if (hasPermission) {
        if (labels.length > 1 || isOthers) {
          cursorStyle = 'pointer';
        } else if (labels.length === 1) {
          cursorStyle = 'not-allowed';
        }
      }
    }
    event.target.style.cursor = cursorStyle;
  }
}

function getFormattedLabel(item: ChartTooltipItem,
                           data: ChartData,
                           fullLabels: string[]
                            ): string {
  const sourceData: number[] = data.datasets[0].data as number[];
  const totalCount = sourceData.reduce(
            (first: number, second: number) => first + second
          );
  const eventCount: number = sourceData[item.index];
  const dataDistributionInPercent = (eventCount / totalCount) * 100;
  const percentageString = Number.isInteger(dataDistributionInPercent)
                          ? dataDistributionInPercent
                          : dataDistributionInPercent.toFixed(2);
  const unitLabel: string = eventCount === 1 ? 'Event' : 'Events';
  return `${fullLabels[item.index]} - ${eventCount.toLocaleString('en-US')} ${unitLabel} (${percentageString}%)`;
}
