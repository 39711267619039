export interface IProvisionDetails {
  organizationId: string;
  status: EProvisioningStatus;
  provisionDate: string;
  provisionStartDate: string;
}

export enum EProvisioningStatus {
  None = 'None',
  NotProvisioned = 'NotProvisioned',
  ProvisionInProgress = 'ProvisionInProgress',
  Provisioned = 'Provisioned',
  DeprovisionInProgress = 'DeprovisionInProgress',
  Deprovisioned = 'Deprovisioned',
}

export enum EProvisionedState {
  Error,
  Provisionable,
  Provisioned,
  Loading,
}

export enum EProvisioningSource {
  ODA,
  ChangeAuditor,
}
