import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[multiEmail]',
  providers: [{provide: NG_VALIDATORS, useExisting: MultiEmailValidatorDirective, multi: true}]
})
export class MultiEmailValidatorDirective implements Validator {
  @Input() forbiddenName: string;

  validate(control: AbstractControl): {[key: string]: any} {
    if (control.value) {
      let addresses: string[] = control.value.trim().split(/[\s;,]+/).filter((address: string) => address.length > 0);
      /* eslint-disable-next-line max-len */
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (addresses.every((address: string) => re.test(address))) {
        return null;
      } else {
        return {
          multiEmail: {
            value: control.value
          }
        };
      }
    } else {
      return null;
    }
  }
}
