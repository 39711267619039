import { take, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgForm, Validator } from '@angular/forms';
import { QueryClause } from '../../../../models/query-clause';
import { Input, Component, EventEmitter, Output, forwardRef, ViewChild, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { EventField } from '../../../../models/event-field.model';
import { substituteTemplateValues } from '../../../../util/template-substituter';
import { operatorMap, numericDataTypes, betweenNumberOperator, getSearchOperators } from '../search-operators';
import { BaseComponent } from '@ondemand/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { isIntegerFalsy } from '../../../../util/integer-utils';

export const multiValueOperators = ['in', 'not_in'];

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EditorClauseComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EditorClauseComponent),
    multi: true
  }],
  selector: 'editor-clause',
  templateUrl: './editor-clause.component.html',
  styleUrls: ['./editor-clause.component.scss']
})
export class EditorClauseComponent extends BaseComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() fields: EventField[];
  @Input() i: number;
  @Input() removable: boolean;
  @Output() remove: EventEmitter<any>;
  @ViewChild('clauseForm') clauseForm: NgForm;

  onChange: any;
  clause: QueryClause;
  selectedField: EventField;
  predefinedValuesOnly: boolean;
  invalidFieldSelection = false;
  invalidFieldSelectionMessage: string;
  labelsLoaded = false;

  constructor(private localeStringsService: LocaleStringsService) {
    super();
    this.remove = new EventEmitter<any>();
  }

  ngOnInit() {
    this.localeStringsService.strings$.pipe(takeUntil(this.destructionSubject)).subscribe(() => {
      this.labelsLoaded = true;
      if (this.clause) {
        this.setFieldType();
      }
    });

    return;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields) {
      // Force fields to unsearchable status if they have an unrecognized data type
      changes.fields.currentValue.forEach((field: EventField) => {
        if (!Object.keys(operatorMap).includes(field.dataType)) {
          console.error(`Unrecognized event field data type: "${field.dataType}"`);
          field.searchable = false;
          field.filterable = false;
        }
      });
      this.validateField();
    }

  }

  writeValue(value: any): void {
    if (value) {
      this.clause = value;

      this.validateField();
      this.setFieldType();
      this.setOperators();
    }
  }

  registerOnChange(fn: any): void {
    // This emits before the sub component gets re-validated and shown to be invalid
    this.onChange = () => {

      // Hack to ensure sub-components can run their own validation and have
      // it propagate up to this level before emitting a change event
      setTimeout(() => {
        fn(this.clause);
      }, 1);
    };
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  validateField() {
    if (!this.clause || !this.clause.field) {
      return;
    }
    let matchingField = this.fields.find((field: EventField) => field.id === this.clause.field);
    this.invalidFieldSelection = !matchingField.isSearchable;
    if (this.invalidFieldSelection) {
      this.localeStringsService.strings$.pipe(take(1)).subscribe(() => {
        this.invalidFieldSelectionMessage = substituteTemplateValues(
          this.localeStringsService.get('auditing.unsearchableFieldError'), {
          field: matchingField.displayName
        });
      });
    }
  }

  onFieldChange() {
    this.setFieldType();
    this.setOperators();
    this.resetValue();
    this.onChange();
  }

  onOperatorChange() {
    this.clearInvalidValues();
    this.onChange();
  }

  onValueChange() {
    this.onChange();
  }

  resetValue() {
    if (this.isMultiValueOperator(this.clause.operator)) {
      this.clause.value = null;
      this.clause.values = [];
    } else if (this.isDateField()) {
      this.clause.values = null;
      this.clause.value = '';
    } else if (this.predefinedValuesOnly) {
      this.clause.value = null;
      this.clause.values = null;
    } else {
      this.clause.values = null;
      this.clause.value = '';
    }
    this.markValuePristine();
  }

  setFieldType() {
    if (!this.fields) {
      return;
    }

    // Set default value
    if (!this.clause.field) {
      this.clause.field = this.fields[0].id;
    }

    this.selectedField = this.fields.find((field: any) => this.clause.field === field.id);
    if (this.selectedField) {
      this.predefinedValuesOnly = this.selectedField.predefinedValuesOnly || false;
    }
  }

  setOperators() {
    if (!this.selectedField) {
      return;
    }

    this.localeStringsService.strings$.pipe(take(1)).subscribe((_labels) => {
      let operators = getSearchOperators(this.selectedField).map((operator: string) => ({
          id: operator,
          label: this.localeStringsService.get('auditing.pages.newSearches.searchOperators.' + operator)
        }));

      // Reset operator and value if the existing operator is not in the new list or,
      // the operator list has changed(different default operator).
      if (this.clause.operator &&
        (!operators.find((operator: any) => operator.id === this.clause.operator) ||
          (this.clause.availableOperators.length && this.clause.availableOperators.length !== operators.length))) {
        // Reset to first available operator
        this.clause.operator = operators[0].id;
        this.resetValue();
      }

      this.clause.availableOperators = operators;

      if (!this.clause.operator) {
        this.clause.operator = operators[0].id;
      }
    });

  }

  removeClause() {
    this.remove.emit();
  }

  validate(_control: UntypedFormControl): any {
    if (!this.clause) {
      return null;
    }
    let valid = true;

    // Since Angular upgrade, saved queries have issues with clauses being invalid in the form despite having a valid value.
    if (!this.clauseForm.valid &&
      this.clause.field &&
      this.clause.operator &&
      !this.isDateField()) {
       for (const key of Object.keys(this.clauseForm.controls)) {
         let value = this.clauseForm.controls[key];
         if (value.status === 'INVALID' && (value.value === null || value.value.length === 0)) {
           valid = false;
           break;
         }
       }
     } else if (!this.clauseForm.valid ||
        !this.clause.field ||
        !this.clause.operator
    ) {
      valid = false;
    }

    // For betweenNumberOperator, consider only values and expect exactly 2 values
    if (this.clause.operator === betweenNumberOperator) {
      if (!this.clause.values ||
          this.clause.values.length !== 2 ||
          isIntegerFalsy(this.clause.values?.[0]) ||
          isIntegerFalsy(this.clause.values?.[1])) {
          valid = false;
      }
    }

    // In multi-select mode, a selection must be made
    if (this.isMultiValueOperator(this.clause.operator) &&
      (this.clause.values === undefined || this.clause.values === null)) {
      valid = false;
    }

    // In single-select mode, a selection must be made
    if (!this.isMultiValueOperator(this.clause.operator) && this.clause.operator !== betweenNumberOperator &&
      (this.clause.value === undefined || this.clause.value === null || this.clause.value === '')) {
      valid = false;
    }

    if (valid) {
      return null;
    } else {
      return {
        message: 'Something invalid in editor clause'
      };
    }
  }

  isMultiValueOperator(operator: string) {
    return multiValueOperators.includes(operator);
  }

  isBetweenNumberOperator(operator: string) {
    return operator === betweenNumberOperator;
  }

  isNumericField() {
    let selectedField: EventField = this.fields.find((field) => field.id === this.clause.field);
    return numericDataTypes.includes(selectedField.dataType);
  }

  markValuePristine() {
    let valueField = this.clauseForm.controls[`value-${this.i}`];
    if (valueField) {
      valueField.markAsPristine();
    }
  }

  private isDateField() {
    if (this.selectedField) {
      return this.selectedField.dataType === 'datetime';
    } else {
      return false;
    }
  }

  private clearInvalidValues() {
    // Prevent inadvertently setting data for both "value" and "values" properties
    if (this.isNumericField()) {
      if (this.isBetweenNumberOperator(this.clause.operator)) {
        this.clause.value = null;
      } else {
        this.clause.values = null;
      }
    } else if (this.isMultiValueOperator(this.clause.operator)) {
      this.clause.value = null;
    } else {
      this.clause.values = null;
    }
  }

}
