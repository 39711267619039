<div class="dashboard-wrapper">
  <ng-container *ngIf="!(loadWidgetListError$ | async); else errorScreen">
    <div class="refresh">
      <div class="qod-tooltip qod-tooltip-bottom">
        <edc-icon
          size="1x"
          name="refresh"
          state="reserve-blue"
          (click)="triggerRefresh()"
        >
        </edc-icon>
        <span class="qod-tooltiptext">
          {{ refreshText }}
        </span>
      </div>
    </div>

    <div class="oda-static-indicators-container row">
      <div class="oda-static-indicators-card-size col l3"
        *ngFor="let indicatorSetting of staticIndicatorsSettings" >
        <static-indicator
          id="{{indicatorSetting.id + '-widget'}}"
          [IndicatorId]="indicatorSetting.id"
          [displayTitle]="indicatorSetting.displayTitle"
          [iconName]="indicatorSetting.iconName"
          [iconColor]="'danube'"
          [refreshObservable$]="refreshObservable$"></static-indicator>
      </div>
    </div>

    <div class="oda-widgets-container row justify-content-md-center">
      <div class="oda-card-size"  *ngIf="enableMyFavoriteSearch" >
        <favorite-search-widget id="favorite-search-widget" [refreshObservable$]="refreshObservable$"></favorite-search-widget>
      </div>
      <div class="oda-card-size" *ngIf="enableAuditHealth">
        <audit-health-widget id="audit-health-widget" [refreshObservable$]="refreshObservable$"></audit-health-widget>
      </div>
      <div class="oda-card-size" *ngIf="enableCriticalActivity" >
        <critical-activity-widget id="critical-activity-widget" [refreshObservable$]="refreshObservable$"></critical-activity-widget>
      </div>
      <div class="oda-card-size" *ngIf="enableSignins">
        <sign-ins-widget id="sign-ins-widget" [refreshObservable$]="refreshObservable$"></sign-ins-widget>
      </div>
      <div class="oda-card-size" *ngIf="enableTopActiveUsers">
        <top-active-users-widget id="top-active-users-widget" [refreshObservable$]="refreshObservable$"></top-active-users-widget>
      </div>
    </div>
  </ng-container>
  <ng-template #errorScreen>
    <!-- error occurred while loading widget lists -->
    <error-message>{{ 'auditing.pages.auditingDashboard.loadDashboardError' | localize }}</error-message>
  </ng-template>
</div>
