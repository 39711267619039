<div class="top-section">
  <h4>{{ labels.quickSearchTitle }}</h4>
  <form #f="ngForm" (ngSubmit)="onSubmit()">
    <div class="quick-search-field-container">
      <edc-icon
        name="search"
        id="quick-search-icon"
        (click)="onSubmit()"
      ></edc-icon>
      <input
        [(ngModel)]="queryString"
        type="text"
        name="q"
        id="large-quick-search-query"
        placeholder="{{ labels.quickSearchPlaceholder }}"
        autocomplete="off"
      />
    </div>
  </form>
</div>

<column-editor-flyout
  *ngIf="!loadingQuery && currentQuery && !isExporting"
  [columns]="currentQuery.q.columns"
  [fields]="eventFields"
  [visualizeParams]="visualizeParams"
  (columnChange)="onColumnChange($event)"
></column-editor-flyout>

<div class="results-main col s12">
  <ng-container *ngIf="!isExporting">
    <internal-loading
    [information]="coreStrings.loadingPleaseWait"
    [isLoading]="loadingResults"
  ></internal-loading>
    <div class="visualization-internal-loading">
      <internal-loading
        information="{{ 'auditing.pages.criticalActivity.flyout.loadingVisualizationMessage' | localize }}"
        [isLoading]="!visualizationLoaded && !loadingResults"
      ></internal-loading>
    </div>
  </ng-container>


  <div class="row edit-cols-container">
    <div
      class="btn-flat edit-columns-button"
      *ngIf="!isExporting"
      (click)="openEditColumnsFlyout()"
    >
      <edc-icon name="cog"></edc-icon>
      {{ applicationStrings.pages.newSearches.editColumnsButton }}
    </div>
    <!-- Button Export, with option "CSV" and "CSV as ZIP"-->
    <create-export
      [results]="results"
      [currentQuery]="currentQuery"
      [adHocMode]="true"
      (exportStarted)="onExportStarted($event)"
    ></create-export>
  </div>
  <error-message *ngIf="!loadingResults && queryFailedErrorMessage">{{
    queryFailedErrorMessage
  }}</error-message>
  <div #container class="outer-container">
    <ng-container *ngIf="!isExporting && !loadingQueryError">
      <div
      *ngIf="userSearchVisualizationPermission && !hideCharts && !isExporting"
      class="row chartContainer"
      >
        <donut-chart
          [query]="currentQuery"
          (chartAreaClick)="onChartCategoryClicked($event)"
          (renderCompleted)="onVisualizationLoaded($event === false)"
          *ngIf="enableDonutChart"
        ></donut-chart>
        <timeseries-chart
          [query]="currentQuery"
          (visualizationLoaded)="onVisualizationLoaded($event)"
          (chartElementClicked)="onChartElementClicked($event)"
          *ngIf="enableTimeSeriesChart"
        ></timeseries-chart>
        <hbar-chart
          [inputQuery]="currentQuery"
          [autoDrilldown]="false"
          (loading)="onVisualizationLoaded($event === false)"
          (barClicked)="onChartCategoryClicked($event)"
          *ngIf="enableHBarChart"
        ></hbar-chart>
      </div>
      <section #main class="top-panel">
        <div *ngIf="!hideEvents && !isExporting" class="results-main col s12">
          <results-viewer
            [results]="results"
            [loading]="loadingResults"
            (pageChange)="onPageChange($event)"
            (seek)="onSeek($event)"
            (reorder)="onReorder($event)"
            (sort)="onSort($event)"
            (filter)="onFilter($event)"
          ></results-viewer>
        </div>
      </section>
    </ng-container>
  </div>
</div>

<modal-window [params]="visualizationLimitModalParams">
  <div modal-content>
    <strong>{{ rowLimitDescription }}</strong>
  </div>
</modal-window>
