<div id="column-editor-container" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">

  <div class="main-section"> <!-- begin of main-section -->

    <div class="notification-container">
      <qod-dismissible-notification
        class ="notification-container"
        *ngIf="displayBanner"
        [type]="'warning'"
        [text]="displayBannerLabel"
        [dismissible]="false" >
      </qod-dismissible-notification>
    </div>

    <p class="sub-heading">{{flyoutColumnSubHeader}}</p>

    <p class="column-info">{{ flyoutColumnLabel }}</p>

    <ul class="column-selections" [dragula]="'column-selections'" [(dragulaModel)]='temporaryColumnOrder'>
      
      <li class="column" *ngFor="let columnOption of temporaryColumnOrder; index as i; trackBy: trackByDragulaId">
        
        <error-message *ngIf="columnOption.deprecated">{{ getInvalidColumnErrorMessage(columnOption.name) }}</error-message>
        
        <div>
          
          <div class="grabber-container tooltipped"
            id="grabber-{{i}}"
            materialize="tooltip"
            data-delay="150"
            data-position="top"
            attr.data-tooltip="{{ reorderTooltip }}" >
            
            <div class="column-order-grabber"></div>
          
          </div>
          
          <div class="input-field column-list-container">
            <div [attr.id] ="columnOption.dragulaId" >
                <oda-type-ahead (click)="onClickRaisedOnChild(columnOption.dragulaId)"
                  [requestToValidate]="requestToValidate"
                  [clauseKey]="columnOption.dragulaId"
                  [clause]="columnOption.clause"
                  [config]="columnOption.inputConfig"
                  [fieldValid] = "columnOption.fieldValid"
                  [placeholder]="placeholder"
                  (valueChange)="onValueChange($event)" >
                </oda-type-ahead>
            </div>
          </div>

          <div class="remove-column-button tooltipped"
            id="remove-columns-selection-id-{{i}}"
            *ngIf="temporaryColumnOrder.length > 1"
            materialize="tooltip"
            data-delay="50"
            data-position="top"
            attr.data-tooltip="{{ removeTooltip }}"
            (click)="onClickRemoveColumn(i)" >
            
            <edc-icon name="remove" size="small" state="reserve-blue"></edc-icon>
          
          </div>
        
        </div>
        
        <div *ngIf="!columnOption.fieldValid" class='validation-error-message'>
          <span>{{errorEmptyFieldValue}}</span>
        </div>

      </li>

    </ul>

  </div> <!-- end of main-section -->

  <button *ngIf="temporaryColumnOrder.length > 0" class="btn-secondary add-column-button" data-test="add-column-button" (click)="onClickAddColumn()">
    <edc-icon size="small" state="reserve-blue" name="plus"></edc-icon> {{ addButton }}
  </button>

</div>
