import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { LegendDataItem, LegendEventArgs } from './legend-model';
import { timer } from 'rxjs';

@Component({
  selector: 'oda-chart-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent extends BaseComponent implements OnChanges {
  @Input() dataItems: LegendDataItem[];
  @Input() legendTitle: string;
  @Output() legendItemSelectionChange: EventEmitter<LegendEventArgs> =
    new EventEmitter<LegendEventArgs>();
  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataItems && changes.dataItems.currentValue !== changes.dataItems.previousValue) {
      this.dataItems = null;
      timer(15).subscribe(() => {
        this.dataItems = changes.dataItems.currentValue as  LegendDataItem[];
        this.ensureOneCheckedMinimum();
      });
    }
  }

  onChange( legendEvent: any ): void {
      this.ensureOneCheckedMinimum();
      this.legendItemSelectionChange.emit(
                                          {
                                           index: legendEvent.target.value,
                                           show: legendEvent.target.checked,
                                           currentLegendItems: this.dataItems
                                          } as LegendEventArgs
                                         );

  }

  private ensureOneCheckedMinimum(): void {
    // this is to prevent the last checked item been unchecked.
    const checkedLegends = this.dataItems.filter(data => data.checked);
    if (checkedLegends.length === 1) {
      const idx = this.dataItems.findIndex(data => data.checked);
      this.dataItems[idx].disable = true;
    } else if (checkedLegends.length > 1) {
      // we should clean the disable flag set previously
      const disabledIdx = this.dataItems.findIndex(data => data.disable);
      if (disabledIdx >= 0) {
        this.dataItems[disabledIdx].disable = false;
      }
    }
  }
}
