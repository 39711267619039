import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent, IModalWindow } from '@ondemand/core';
import { LocaleStringsService } from '../../../services/locale-strings.service';

@Component({

  selector: 'trial-limits-modal',
  templateUrl: './trial-limits-modal.component.html',
  styleUrls: ['./trial-limits-modal.component.scss']
})
export class TrialLimitsModalComponent extends BaseComponent implements OnInit {
  modalParams: IModalWindow;

  constructor(private localeStringsService: LocaleStringsService) {
    super();
  }

  ngOnInit() {
    return;
  }

  async display() {
    const modalTitle = await this.localeStringsService
      .string$('auditing.trialLimits.title').pipe(
      take(1))
      .toPromise();
    const okayLabel = await this.localeStringsService
      .string$('auditing.okay').pipe(
      take(1))
      .toPromise();
    const purchaseSubscriptionLabel = await this.localeStringsService
      .string$('auditing.trialLimits.purchaseSubscriptionAction').pipe(
      take(1))
      .toPromise();
    const trialLimitDetails = await this.localeStringsService
      .string$('auditing.trialLimits.details').pipe(
      take(1))
      .toPromise();

    this.modalParams = {
      showModal: true,
      dialogParams: {
        title: modalTitle,
        content: trialLimitDetails,
        actions: [
          {
            name: purchaseSubscriptionLabel,
            action: () => {
              this.openBuyPage();
              this.closeModal();
            }
          },
          {
            name: okayLabel,
            action: () => {
              this.closeModal();
            },
            buttonType: 'secondary'
          }
        ],
        hideCancel: true
      }
    };

    this.modalParams = { ...this.modalParams };
  }

  openBuyPage() {
    window.open('https://quest.com/buyondemandaudit/');
  }

  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = { ...this.modalParams };
  }
}
