import { StaticIndicatorResponse } from '../models/static-indicator.models';

// *********************************************************
// AdAccountLockouts
// *********************************************************
export const LOAD_AD_ACCOUNT_LOCKOUTS =
  '[Auditing Dashboard] Load-AdAccountLockouts';
export const LOAD_AD_ACCOUNT_LOCKOUTS_SUCCESS =
  '[Auditing Dashboard] Load-AdAccountLockouts-Success';
export const LOAD_AD_ACCOUNT_LOCKOUTS_FAIL =
  '[Auditing Dashboard] Load-AdAccountLockouts-Fail';
export const UPDATE_TREND_COLOR_AD_ACCOUNT_LOCKOUTS =
  '[Auditing Dashboard] Update-Trend-Color-AdAccountLockouts';

// eslint-disable-next-line max-classes-per-file
export class LoadAdAccountLockouts {
  readonly type = LOAD_AD_ACCOUNT_LOCKOUTS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAdAccountLockoutsSuccess {
  readonly type = LOAD_AD_ACCOUNT_LOCKOUTS_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAdAccountLockoutsFail {
  readonly type = LOAD_AD_ACCOUNT_LOCKOUTS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorAdAccountLockouts {
  readonly type = UPDATE_TREND_COLOR_AD_ACCOUNT_LOCKOUTS;
  constructor(public payload: string) {}
}

// *********************************************************
// AllFailedSignins
// *********************************************************
export const LOAD_ALL_FAILED_SIGNINS =
  '[Auditing Dashboard] Load-AllFailedSignins';
export const LOAD_ALL_FAILED_SIGNINS_SUCCESS =
  '[Auditing Dashboard] Load-AllFailedSignins-Success';
export const LOAD_ALL_FAILED_SIGNINS_FAIL =
  '[Auditing Dashboard] Load-AllFailedSignins-Fail';
export const UPDATE_TREND_COLOR_ALL_FAILED_SIGNINS =
  '[Auditing Dashboard] Update-Trend-Color-AllFailedSignins';

// eslint-disable-next-line max-classes-per-file
export class LoadAllFailedSignins {
  readonly type = LOAD_ALL_FAILED_SIGNINS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAllFailedSigninsSuccess {
  readonly type = LOAD_ALL_FAILED_SIGNINS_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAllFailedSigninsFail {
  readonly type = LOAD_ALL_FAILED_SIGNINS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorAllFailedSignins {
  readonly type = UPDATE_TREND_COLOR_ALL_FAILED_SIGNINS;
  constructor(public payload: string) {}
}

// *********************************************************
// AzureADCriticalRoleChanges
// *********************************************************
export const LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES =
  '[Auditing Dashboard] Load-AzureADCriticalRoleChanges';
export const LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_SUCCESS =
  '[Auditing Dashboard] Load-AzureADCriticalRoleChanges-Success';
export const LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_FAIL =
  '[Auditing Dashboard] Load-AzureADCriticalRoleChanges-Fail';
export const UPDATE_TREND_COLOR_AZURE_AD_CRITICAL_ROLE_CHANGES =
  '[Auditing Dashboard] Update-Trend-Color-AzureADCriticalRoleChanges';

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADCriticalRoleChanges {
  readonly type = LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADCriticalRoleChangesSuccess {
  readonly type = LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADCriticalRoleChangesFail {
  readonly type = LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorAzureADCriticalRoleChanges {
  readonly type = UPDATE_TREND_COLOR_AZURE_AD_CRITICAL_ROLE_CHANGES;
  constructor(public payload: string) {}
}

// *********************************************************
// AzureADFailedSignins
// *********************************************************
export const LOAD_AZURE_AD_FAILED_SIGNINS =
  '[Auditing Dashboard] Load-AzureADFailedSignins';
export const LOAD_AZURE_AD_FAILED_SIGNINS_SUCCESS =
  '[Auditing Dashboard] Load-AzureADFailedSignins-Success';
export const LOAD_AZURE_AD_FAILED_SIGNINS_FAIL =
  '[Auditing Dashboard] Load-AzureADFailedSignins-Fail';
export const UPDATE_TREND_COLOR_AZURE_AD_FAILED_SIGNINS =
  '[Auditing Dashboard] Update-Trend-Color-AzureADFailedSignins';

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADFailedSignins {
  readonly type = LOAD_AZURE_AD_FAILED_SIGNINS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADFailedSigninsSuccess {
  readonly type = LOAD_AZURE_AD_FAILED_SIGNINS_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADFailedSigninsFail {
  readonly type = LOAD_AZURE_AD_FAILED_SIGNINS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorAzureADFailedSignins {
  readonly type = UPDATE_TREND_COLOR_AZURE_AD_FAILED_SIGNINS;
  constructor(public payload: string) {}
}

// *********************************************************
// AzureADRiskEvents
// *********************************************************
export const LOAD_AZURE_AD_RISK_EVENTS =
  '[Auditing Dashboard] Load-AzureADRiskEvents';
export const LOAD_AZURE_AD_RISK_EVENTS_SUCCESS =
  '[Auditing Dashboard] Load-AzureADRiskEvents-Success';
export const LOAD_AZURE_AD_RISK_EVENTS_FAIL =
  '[Auditing Dashboard] Load-AzureADRiskEvents-Fail';
export const UPDATE_TREND_COLOR_AZURE_AD_RISK_EVENTS =
  '[Auditing Dashboard] Update-Trend-Color-AzureADRiskEvents';

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADRiskEvents {
  readonly type = LOAD_AZURE_AD_RISK_EVENTS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADRiskEventsSuccess {
  readonly type = LOAD_AZURE_AD_RISK_EVENTS_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAzureADRiskEventsFail {
  readonly type = LOAD_AZURE_AD_RISK_EVENTS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorAzureADRiskEvents {
  readonly type = UPDATE_TREND_COLOR_AZURE_AD_RISK_EVENTS;
  constructor(public payload: string) {}
}

// *********************************************************
// CloudOnlyAzureADUsersCreated
// *********************************************************
export const LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED =
  '[Auditing Dashboard] Load-CloudOnlyAzureADUsersCreated';
export const LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_SUCCESS =
  '[Auditing Dashboard] Load-CloudOnlyAzureADUsersCreated-Success';
export const LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_FAIL =
  '[Auditing Dashboard] Load-CloudOnlyAzureADUsersCreated-Fail';
export const UPDATE_TREND_COLOR_CLOUD_ONLY_AZURE_AD_USERS_CREATED =
  '[Auditing Dashboard] Update-Trend-Color-CloudOnlyAzureADUsersCreated';

// eslint-disable-next-line max-classes-per-file
export class LoadCloudOnlyAzureADUsersCreated {
  readonly type = LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED;
}

// eslint-disable-next-line max-classes-per-file
export class LoadCloudOnlyAzureADUsersCreatedSuccess {
  readonly type = LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadCloudOnlyAzureADUsersCreatedFail {
  readonly type = LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorCloudOnlyAzureADUsersCreated {
  readonly type = UPDATE_TREND_COLOR_CLOUD_ONLY_AZURE_AD_USERS_CREATED;
  constructor(public payload: string) {}
}

// *********************************************************
// O365ExternalUserAction
// *********************************************************
export const LOAD_O365_EXTERNAL_USER_ACTIONS =
  '[Auditing Dashboard] Load-O365ExternalUserActions';
export const LOAD_O365_EXTERNAL_USER_ACTIONS_SUCCESS =
  '[Auditing Dashboard] Load-O365ExternalUserActions-Success';
export const LOAD_O365_EXTERNAL_USER_ACTIONS_FAIL =
  '[Auditing Dashboard] Load-O365ExternalUserActions-Fail';
export const UPDATE_TREND_COLOR_O365_EXTERNAL_USER_ACTIONS =
  '[Auditing Dashboard] Update-Trend-Color-O365ExternalUserActions';

// eslint-disable-next-line max-classes-per-file
export class LoadO365ExternalUserAction {
  readonly type = LOAD_O365_EXTERNAL_USER_ACTIONS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadO365ExternalUserActionSuccess {
  readonly type = LOAD_O365_EXTERNAL_USER_ACTIONS_SUCCESS;
  constructor(public payload: StaticIndicatorResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadO365ExternalUserActionFail {
  readonly type = LOAD_O365_EXTERNAL_USER_ACTIONS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTrendColorO365ExternalUserAction {
  readonly type = UPDATE_TREND_COLOR_O365_EXTERNAL_USER_ACTIONS;
  constructor(public payload: string) {}
}

export type StaticIndicatorAction =
  | LoadAdAccountLockouts
  | LoadAdAccountLockoutsSuccess
  | LoadAdAccountLockoutsFail
  | UpdateTrendColorAdAccountLockouts
  | LoadAllFailedSignins
  | LoadAllFailedSigninsSuccess
  | LoadAllFailedSigninsFail
  | UpdateTrendColorAllFailedSignins
  | LoadAzureADCriticalRoleChanges
  | LoadAzureADCriticalRoleChangesSuccess
  | LoadAzureADCriticalRoleChangesFail
  | UpdateTrendColorAzureADCriticalRoleChanges
  | LoadAzureADFailedSignins
  | LoadAzureADFailedSigninsSuccess
  | LoadAzureADFailedSigninsFail
  | UpdateTrendColorAzureADFailedSignins
  | LoadAzureADRiskEvents
  | LoadAzureADRiskEventsSuccess
  | LoadAzureADRiskEventsFail
  | UpdateTrendColorAzureADRiskEvents
  | LoadCloudOnlyAzureADUsersCreated
  | LoadCloudOnlyAzureADUsersCreatedSuccess
  | LoadCloudOnlyAzureADUsersCreatedFail
  | UpdateTrendColorCloudOnlyAzureADUsersCreated
  | LoadO365ExternalUserAction
  | LoadO365ExternalUserActionFail
  | LoadO365ExternalUserActionSuccess
  | UpdateTrendColorO365ExternalUserAction;
