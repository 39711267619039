import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetService } from '../../../../../../shared/services/widget-service';
import { AuditHealthWidgetResponse } from '../models/audit-health.models';
import { UpdateAuditHealthSettings, UpdateWidgetConfiguration } from '../../../../../../shared/models/update-widget-configuration';
import { WidgetID } from '../../../models/widget-info';

@Injectable()
export class AuditHealthDataService {
  constructor(private widgetService: WidgetService) {}

  getAuditHealth(): Observable<AuditHealthWidgetResponse> {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.AuditHealth)
      .pipe(
        map((response: HttpResponse<AuditHealthWidgetResponse>) => response.body)
      )
      ;
  }

  updateSelectedAuditHealth(
    updateSetting: UpdateAuditHealthSettings
  ): Observable<HttpResponse<any>> {
    let updateObj = {
      auditHealth: updateSetting
    } as UpdateWidgetConfiguration;

    return this.widgetService.putWidgetSettingsWithHttpInfo(
      WidgetID.AuditHealth,
      updateObj
    );
  }
}
