import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import { FavoriteSearchConfiguration, FavoriteSearchResponse } from '../models/favorite-search.models';
import * as fromFavoriteSearch from './favorite-search.actions';

export interface FavoriteSearchState {
  data: AuditingDashboardResponse<FavoriteSearchResponse, FavoriteSearchConfiguration>;
  loading: boolean;
  error: any;
}

export const initialState: FavoriteSearchState = {
  data: null,
  loading: false,
  error: undefined
};

export function reducer(
  state: FavoriteSearchState = initialState,
  action: fromFavoriteSearch.FavoriteSearchAction
): FavoriteSearchState {
  switch (action.type) {
    case fromFavoriteSearch.LOAD_FAVORITE_SEARCH:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromFavoriteSearch.LOAD_FAVORITE_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: undefined
      };
    case fromFavoriteSearch.LOAD_FAVORITE_SEARCH_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
