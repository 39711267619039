<div class="card-container">
  <div class="loading-container" *ngIf="deleting">
    <internal-loading
      information="{{ 'auditing.caDeleting' | localize }}"
      [isLoading]="true"
    ></internal-loading>
  </div>

  <div class="loading-container" *ngIf="pausing">
    <internal-loading
      information="{{ 'auditing.caPausing' | localize }}"
      [isLoading]="true"
    ></internal-loading>
  </div>

  <div class="loading-container" *ngIf="resuming">
    <internal-loading
      information="{{ 'auditing.caResuming' | localize }}"
      [isLoading]="true"
    ></internal-loading>
  </div>

  <div id="installation-{{ installation.id }}" class="card qloud-card">
    <div class="qloud-tenant-card-header">
      <img
        class="tenant-logo ca-logo"
        id="change-auditor-logo"
        title="{{ 'auditing.changeAuditorProductName' | localize }}"
        src="/assets/auditing/img/change-auditor-logo.svg"
      />
      <h5
        class="qloud-tenant-name ca-install-name"
        title="{{ installation.installationDisplayName }}"
      >
        {{ installation.installationDisplayName }}
      </h5>

      <div class="loading-indicator-container">
        <ng-container
          *ngIf="updating && !deleting; then loadingIndicator"
        ></ng-container>
      </div>

      <div class="settings-menu-container">
        <ng-template [ngTemplateOutlet]="settingsMenu"></ng-template>
      </div>
    </div>

    <div class="card-content qloud-card-content">
      <div class="connection-status">
        <div class="status-section paused" *ngIf="installation.paused">
          <div class="status-icon-container">
            <edc-icon name="pause"></edc-icon>
          </div>
          <div class="status-text">
            {{ 'auditing.changeAuditorPaused' | localize }}
          </div>
        </div>

        <ng-container *ngIf="!installation.paused">
          <div class="status-section connected" *ngIf="installation.connected">
            <div class="status-icon-container">
              <edc-icon name="status-done" state="success-green"></edc-icon>
            </div>
            <div class="status-text">
              {{ 'auditing.changeAuditorConnectionOkay' | localize }}
            </div>
          </div>

          <div
            class="status-section disconnected"
            *ngIf="!installation.connected"
          >
            <div class="status-icon-container">
              <edc-icon name="status-warning" state="warning-orange"></edc-icon>
            </div>
            <div
              class="status-text"
              innerHTML="{{ 'auditing.changeAuditorDisconnected' | localize }}"
            ></div>
          </div>
        </ng-container>

        <div class="ca-upgrade-message-container">
          <ca-upgrade-message
            [upgradeInfo]="installation.upgradeMessage"
          ></ca-upgrade-message>
        </div>
      </div>

      <div class="status-title">{{ 'auditing.statusTitle' | localize }}</div>
      <div class="metadata-list">
        <div class="ca-last-activity">
          <span class="metadata-title">{{
            'auditing.changeAuditorLastUpdateReceived' | localize
          }}</span>
          <span
            class="metadata-detail last-activity-text"
            title="{{ installation.lastActivityDate | date: 'medium' }}"
          >
            <edc-icon name="clock"></edc-icon>
            <ng-container
              *ngIf="installation.lastActivityDate; else awaitingEvents"
            >
              {{ installation.lastActivityDate | dateFromNow }}
            </ng-container>
            <ng-template #awaitingEvents>{{
              'auditing.changeAuditorAwaitingEvents' | localize
            }}</ng-template>
          </span>
        </div>

        <div class="coordinator-info">
          <span class="coordinator-title metadata-title">{{
            'auditing.coordinatorTitlePlural' | localize
          }}</span>
          <span class="metadata-detail"
            >{{ numCoordinatorsConnected }}/{{
              installation.coordinators.length
            }}</span
          >
        </div>

        <div class="ca-version">
          <span class="metadata-title">{{
            'auditing.changeAuditorVersion' | localize
          }}</span>

          <!-- TODO: Use only displayVersion -->
          <ng-container
            *ngIf="installation.displayVersion; else deprecatedVersion"
          >
            <span class="metadata-detail">{{
              installation.displayVersion
            }}</span>
          </ng-container>
          <ng-template #deprecatedVersion>
            <span class="metadata-detail">{{ installation.version }}</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loadingIndicator>
    <div
      class="updating-indicator"
      title="{{ 'auditing.updatingCAInstallationStatus' | localize }}"
    >
      <materialize-spinner></materialize-spinner>
    </div>
  </ng-template>

  <ng-template #settingsMenu>
    <div
      class="more-options-button-container tooltipped"
      materialize="tooltip"
      data-delay="50"
      data-position="right"
      attr.data-tooltip="{{ 'auditing.caInstallationMenu' | localize }}"
    >
      <span
        class="menu-button"
        materialize="dropdown"
        attr.data-activates="dropdown-{{ installation.id }}"
        [materializeParams]="[dropdownOptions]"
        ><edc-icon name="more"></edc-icon
      ></span>
    </div>

    <ul
      id="dropdown-{{ installation.id }}"
      class="installation-settings-dropdown dropdown-content"
    >
      <li
        *ngIf="installation.paused"
        [ngClass]="{ disabled: !installation.connected }"
      >
        <span
          class="resume-installation-button"
          (click)="installation.connected && onResumeClick()"
          ><edc-icon name="play"></edc-icon>
          {{ 'auditing.caInstallationResume' | localize }}</span
        >
      </li>
      <li
        *ngIf="!installation.paused"
        [ngClass]="{ disabled: !installation.connected }"
      >
        <span
          class="pause-installation-button"
          (click)="installation.connected && onPauseClick()"
          ><edc-icon name="pause"></edc-icon>
          {{ 'auditing.caInstallationPause' | localize }}</span
        >
      </li>
      <li>
        <span class="remove-installation-button" (click)="onRemoveClick()"
          ><edc-icon name="trash"></edc-icon>
          {{ 'auditing.caInstallationRemove' | localize }}</span
        >
      </li>
    </ul>
  </ng-template>
</div>
