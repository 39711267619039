  <ng-container *ngFor="let item of dataItems; let i = index">
  <label
  for="label-{{i}}"
  class="legend-item-container legend-item-tooltip"
  [ngClass]="{'noClickAllowed': item.disable === true}"
  >{{item.label}}
    <span class="legend-item-tooltip-text">{{item.tooltip}}</span>
    <input
    id="label-{{i}}"
    type="checkbox"
    [disabled]="item.disable"
    [value]="i"
    name="label-{{i}}"
    [(ngModel)]="item.checked"
    (change)="onChange($event)"
    >
    <span class="checkmark"
    [style.border-color]="item.borderColor"
    [style.background-color]="item.backColor"
    >
    </span>
  </label>
  </ng-container>
