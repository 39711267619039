import { Query } from '../../auditing/models/query.model';
import { AuditingDashboardResponse } from '../../auditing/modules/auditing-dashboard/models/auditing-dashboard.models';
import {
  CRITICAL_LEVEL_ICON_PATH,
  HIGH_LEVEL_ICON_PATH,
  LOW_LEVEL_ICON_PATH,
  MEDIUM_LEVEL_ICON_PATH,
  ANOMALY_CRITICAL_LEVEL_ICON_PATH,
  ANOMALY_HIGH_LEVEL_ICON_PATH,
  ANOMALY_LOW_LEVEL_ICON_PATH,
  ANOMALY_MEDIUM_LEVEL_ICON_PATH
} from '../critical-activity/configuration/critical-activity.config';

export type CriticalActivityResponse = AuditingDashboardResponse<
  CriticalActivityDataResponse,
  CriticalActivityConfiguration
>;

export interface CriticalActivityDismissedOrHideItem {
  dismissalTimestamp?: string;
  parameter: string;
  queryId: string;
}

export interface CriticalActivitiesSettings {
  dismissedItems: CriticalActivityDismissedOrHideItem[];
  hiddenItems: CriticalActivityDismissedOrHideItem[];
}

export interface CriticalActivityConfiguration {
  timestamp: string;
  criticalActivity: CriticalActivitiesSettings;
}

export enum CriticalActivityAlertType {
  Search = 'search',
  AnomalyDetection = 'anomalyDetection',
}

export interface CriticalActivityDataSet {
  label: string;
  data: string;
  priority: number;
  alertType: CriticalActivityAlertType;
  queryId: string;
  query: Query;
  dismissOrHideData: CriticalActivityDismissedOrHideItem;
}

export interface CriticalActivitiesDataSet {
  moreResultsAvailable: boolean;
  datasets: CriticalActivityDataSet[];
}

export interface CriticalActivityDataResponse {
  timestamp: string;
  criticalActivity: CriticalActivitiesDataSet;
}

export interface UpdateCriticalActivitySettings {
  resetHiddenItems?: boolean;
  hiddenItems: CriticalActivityDismissedOrHideItem[];
  dismissedItems: CriticalActivityDismissedOrHideItem[];
}

export enum CriticalLevels {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Unknown = '',
}

export let CriticalLevelIconMapping: Map<CriticalLevels, string> = new Map([
  [CriticalLevels.Low, LOW_LEVEL_ICON_PATH],
  [CriticalLevels.Medium, MEDIUM_LEVEL_ICON_PATH],
  [CriticalLevels.High, HIGH_LEVEL_ICON_PATH],
  [CriticalLevels.Critical, CRITICAL_LEVEL_ICON_PATH],
  [CriticalLevels.Unknown, '']
]);

export let CriticalLevelAnomalyIconMapping: Map<CriticalLevels, string> =
  new Map([
    [CriticalLevels.Low, ANOMALY_LOW_LEVEL_ICON_PATH],
    [CriticalLevels.Medium, ANOMALY_MEDIUM_LEVEL_ICON_PATH],
    [CriticalLevels.High, ANOMALY_HIGH_LEVEL_ICON_PATH],
    [CriticalLevels.Critical, ANOMALY_CRITICAL_LEVEL_ICON_PATH],
    [CriticalLevels.Unknown, '']
  ]);
