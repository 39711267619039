import { FavoriteSearchesQuery } from '../../models/favorite-search.models';

export class SelectionEntry {
  id: number;
  queryId: string;
  queries: FavoriteSearchesQuery[];
  categoryId: string;
  previousCategoryId: string;
  trackNum: number;

  constructor() {
    this.id = Date.now() + Math.random();
  }

  equal(rhs: SelectionEntry): boolean {
    return this.id === rhs.id;
  }
}
