// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { Results } from '../results.model';
import { IModalWindow, DisplayStringsProvider } from '@ondemand/core';

import { Subject } from 'rxjs';
import { MaterializeAction } from 'angular2-materialize';
import { formatResultsForDataTable } from '../results-preview/formatResultsForDataTable';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { FilterEvent } from '../event-details.table.model';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import * as fromPermissions from '../../../../models/audit-permissions.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Rect } from '../../../../../shared/models/html.models';
import {
  COLUMN_WIDTH_RRESERVE,
  MIN_RESULT_COLUMN_WIDTH
} from '../results-constants';
import $ from 'jquery';

declare let jQuery: JQueryStatic;

@Component({
  selector: 'results-viewer',
  templateUrl: './results-viewer.component.html',
  styleUrls: ['./results-viewer.component.scss']
})
export class ResultsViewerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loading: boolean;
  @Input() displayAddFilter = true;
  @Input() results: Results;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() seek: EventEmitter<any> = new EventEmitter();
  @Output() reorder: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<any> = new EventEmitter();
  @Output() filter = new EventEmitter<FilterEvent>();

  columnMode: ColumnMode;
  selectedRow: any;
  selectedRowIndex: number;
  selectedEventNumber: number;
  datatableSelectedRows: any[] = [];
  detailsOpened = false;
  visualizationLimitModalParams: IModalWindow = { showModal: false };
  resultsPerPage = 100;
  currentPage = 0;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  coreStrings = DisplayStringsProvider;
  dataReady: boolean;
  datatableRows: any;
  datatableColumns: any;
  detailPaneEvents: EventEmitter<string | MaterializeAction> = new EventEmitter<
    string | MaterializeAction
  >();
  detailPaneParams: any;
  rowLimitDescription: string;

  canViewEventDetails: boolean;

  fromPermissions = fromPermissions;

  @ViewChild('datatableContainer', { static: true })
  datatableContainer: ElementRef;

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private permissionsService: AuditModulePermissionsService) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.detailPaneEvents.emit({ action: 'sideNav', params: ['destroy'] });
  }

  ngOnInit() {
    this.columnMode = ColumnMode.standard;
    this.canViewEventDetails = this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canViewEventDetails
    ]);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Sometimes materialize does not update the params correctly, and the flyout is not visible.
    let componentInstance = this;
    this.detailPaneParams = {
      edge: 'right',
      closeOnClick: true,
      onClose() {
        componentInstance.deselectRow();
      }
    };

    if (changes.loading && changes.loading.currentValue === true) {
      this.detailsOpened = false;
    }

    if (changes.results && changes.results.currentValue) {
      this.setupDatatable(this.results);
    }
  }

  onPageChange(pageNum: number) {
    this.closeDetails();
    this.currentPage = pageNum;
    this.pageChange.emit(pageNum);
  }

  openDetails(row: any) {
    if (!this.canViewEventDetails) {
      return;
    }

    const rowIndex = this.results.rows.findIndex(
      (item: any) => item.id === row.eventId
    );
    this.selectRowAtIndex(rowIndex);
    this.detailsOpened = true;
    this.detailPaneEvents.emit({ action: 'sideNav', params: ['show'] });
  }

  closeDetails() {
    this.detailsOpened = false;
    this.deselectRow();
    this.detailPaneEvents.emit({ action: 'sideNav', params: ['hide'] });
    return;
  }

  selectRowAtIndex(rowIndex: number) {
    this.deselectRow();
    this.selectedRow = this.results.rows[rowIndex];
    this.selectedRow.selected = true;
    this.selectedRowIndex = rowIndex;
    this.selectedEventNumber = this.getEventNum(
      this.currentPage,
      this.resultsPerPage,
      this.selectedRowIndex
    );

    // Update selection at datatable level
    this.datatableSelectedRows = [this.datatableRows[rowIndex]];
    this.scrollToRow(rowIndex);
  }

  /**
   * Scroll in browser so active row element is visible
   */
  scrollToRow(rowIndex: number) {
    let activeRowElement =
      this.datatableContainer.nativeElement.querySelectorAll(
        '.datatable-body-row'
      )[rowIndex];

    if (!activeRowElement) {
      return;
    }
    // Scroll first to get full datatable into view if possible
    let datatable =
      this.datatableContainer.nativeElement.querySelector('.ngx-datatable');
    let mainContentSection = window.document.querySelector(
      '.qloud-tabs-wrapper'
    );
    if (mainContentSection) {
      let datatablePosition =
        jQuery(datatable).offset().top + mainContentSection.scrollTop;
      let mainContentPosition = jQuery(mainContentSection).offset().top;
      mainContentSection.scrollTop = datatablePosition - mainContentPosition;
    }

    // Scroll selected row into view
    let tableHeight = jQuery('.datatable-body').height();
    let rowHeight = jQuery('.datatable-row-wrapper').first().height();
    let activeRowWrapper = activeRowElement.parentElement;
    let rowPosition = jQuery(activeRowWrapper).position().top;
    let firstRowPosition = jQuery(
      document.querySelector('.datatable-row-wrapper')
    ).position().top;
    if (activeRowElement) {
      let tableBody =
        this.datatableContainer.nativeElement.querySelector('.datatable-body');
      let scrollPosition = rowPosition - firstRowPosition;
      // Try to position selected row in middle of table, vertically
      scrollPosition -= tableHeight / 2;
      scrollPosition += rowHeight * 2;

      tableBody.scrollTop = scrollPosition;
    }
  }

  deselectRow() {
    // Reset selected status of old row
    if (this.selectedRow) {
      this.selectedRow.selected = false;
      this.selectedRow = null;
      this.datatableSelectedRows = [];
    }
  }

  getEventNum(pageNum: number, resultsPerPage: number, rowIndex: number) {
    return pageNum * resultsPerPage + rowIndex + 1;
  }

  selectPreviousEvent() {
    if (this.loading) {
      return;
    } else if (this.selectedRowIndex === 0) {
      if (this.currentPage === 0) {
        // Do nothing if there are no previous results to show.
        // This should never get called since the action is
        // disabled elsewhere in the UI.
        return;
      } else {
        // Retrieve previous page of results and select the last row in
        // that set
        this.deselectRow();
        this.currentPage--;
        this.seek.emit({
          pageNum: this.currentPage,
          eventIndex: this.results.rows.length - 1
        });
      }
    } else {
      this.selectRowAtIndex(this.selectedRowIndex - 1);
    }
  }

  selectNextEvent() {
    // If this is the last event in the currently visible list of events,
    // trigger pagination
    if (this.loading) {
      return;
    } else if (this.selectedRowIndex === this.results.rows.length - 1) {
      // If we are already at the end of all available events,
      // do nothing. This should never run since this ability
      // will be disabled elsewhere in the UI.
      if (this.selectedRowIndex === this.results.totalRows) {
        return;
      } else {
        this.deselectRow();
        this.currentPage++;

        this.seek.emit({
          pageNum: this.currentPage,
          eventIndex: 0
        });
      }
    } else {
      this.selectRowAtIndex(this.selectedRowIndex + 1);
    }
  }

  /**
   * Handle selection of a cell in the results table
   *
   * @param event ngx-datatable selection event
   */
  onSelect(event: any) {
    this.datatableSelectedRows = event.selected;
    this.openDetails(event.selected[0]);
  }

  /**
   * Propagate "reorder" event data from datatable
   *
   * @param event Event data from ngx-datatable
   */
  onReorder(event: any) {
    this.reorder.emit(event);
  }

  /**
   * Propagate "sort" event
   *
   * @param event Sort event data
   */
  onSort(event: any) {
    this.sort.emit(event);
  }

  /**
   * Forward the filter event to the host component
   *
   */
  onFilterDetails(event: FilterEvent) {
    this.filter.emit(event);
  }

  onSizeChange(): void {
    if (this.datatableColumns && this.datatableColumns.length) {
      this.updateDataTable(this.datatableColumns, this.datatableRows);
    }
  }

  /**
   * Format query result data for use by datatable component
   *
   * @param results Results for query request
   */
  private setupDatatable(results: Results) {
    const formattedResults = formatResultsForDataTable(results);
    this.updateDataTable(
      formattedResults.datatableColumns,
      formattedResults.datatableRows
    );
  }

  private getWidths(
    numOfCols: number,
    numOfRows: number
  ): [number, number, number] {
    let verticalScrollBarWidth = 20;
    const containerDiv = this.datatableContainer
      .nativeElement as HTMLDivElement;
    const dataTables = containerDiv.getElementsByTagName('datatable-body');

    if (dataTables && dataTables.length > 0) {
      const dataTable = dataTables[0] as HTMLElement;
      verticalScrollBarWidth = dataTable.offsetWidth - dataTable.clientWidth;
    }
    const rect = containerDiv.getBoundingClientRect() as Rect;
    const containerWidth = rect.width;
    const widthReserved =
      numOfRows >= 15 ? verticalScrollBarWidth : COLUMN_WIDTH_RRESERVE;
    // if greater or equal to 15 rows, vertical scroll bar is needed

    let colWidth = (containerWidth - widthReserved) / numOfCols;

    colWidth =
      colWidth < MIN_RESULT_COLUMN_WIDTH ? MIN_RESULT_COLUMN_WIDTH : colWidth;

    return [containerWidth, colWidth, widthReserved];
  }

  private updateDataTable(columns: any[], rows: any[]): void {
    const [containerWidth, colWidth, widthReserved] = this.getWidths(
      columns.length,
      rows.length
    );
    const colWidthInt = Math.floor(colWidth);

    columns.forEach(column => (column.width = colWidthInt));

    if (colWidth > MIN_RESULT_COLUMN_WIDTH) {
      const lastColumnWidth =
        containerWidth - ((columns.length - 1) * colWidthInt + widthReserved);
      columns[columns.length - 1].width = lastColumnWidth;
    }

    this.datatableColumns = [...columns];
    this.datatableRows = [...rows];
  }
}
