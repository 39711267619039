/**
 * Component used for overall Saved Queries list feature
 */
import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  EDialogType,
  BaseComponent,
  DisplayStringsProvider,
  IModalDialog,
  AppFacadeService
} from '@ondemand/core';

import { SavedQueryCategory } from './saved-query-category.model';
import { SavedQueriesService } from '../../../services/saved-queries.service';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { ActiveQueryService } from '../../../services/active-query.service';
import { Query } from '../../../models/query.model';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { CopyQueryModalComponent } from '../copy-query-modal/copy-query-modal.component';
import { QueryService } from '../../../services/query/query.service';
import { substituteTemplateValues } from '../../../util/template-substituter';
import { AlertService } from '../../../services/alerts.service';
import { AlertRule } from '../../../models/alert-rule.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { visualizationRowLimit } from '../../../util/constants';

import * as fromPermissions from '../../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';

@Component({

  selector: 'saved-queries',
  templateUrl: './saved-queries.component.html',
  styleUrls: ['./saved-queries.component.scss']
})
export class SavedQueriesComponent extends BaseComponent implements OnInit {
  errorDeletingSearch = false;
  error = false;
  errorMessage: string;
  categories: SavedQueryCategory[];
  selectedCategory: SavedQueryCategory;
  loading = true;
  categoryNameMap: {
    [categoryId: string]: string;
  } = {};
  filterText = '';
  editingQuery: Query;
  filteredCategories: SavedQueryCategory[];
  allPrivateCategoryId = 'all-private-searches';
  allCategoryId = '';

  searchesMarkedForDeletion: string[] = [];

  auditingStrings = AuditingDisplayStringsProvider;
  coreStrings = DisplayStringsProvider;
  visualizationLimitModalParams: any = {};
  rowLimitDescription: string;

  searches: Query[] = [];
  alertEnabledSearchIds: string[] = [];
  hoveredSearchId: string;

  fromPermissions = fromPermissions;

  @ViewChild(CopyQueryModalComponent) copyModal: CopyQueryModalComponent;

  constructor(
    private savedQueryService: SavedQueriesService,
    private activeQueryService: ActiveQueryService,
    private router: Router,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private queryService: QueryService,
    private alertService: AlertService,
    private facade: AppFacadeService,
    private localeStrings: LocaleStringsService,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  /**
   * Tracks labels used in this component
   *
   */
  get labels() {
    return {
      modifyQuery: this.auditingStrings.auditing.editQueryLabel,
      runSearch: this.auditingStrings.auditing.runSearch,
      pageTitle: this.auditingStrings.auditing.pages.savedSearches.title,
      pageHeader: this.auditingStrings.auditing.pages.savedSearches.header,
      newSearch: this.auditingStrings.auditing.pages.savedSearches.newSearch,
      searchTableName: this.auditingStrings.auditing.pages.savedSearches.tableNameColumnHeader,
      loadingSearchList: this.auditingStrings.auditing.loadingSearchList,
      noSearches: this.auditingStrings.auditing.pages.savedSearches.noSearches,
      deleteQuery: this.auditingStrings.auditing.deleteQueryLabel,
      categoryNameColumnHeader: this.auditingStrings.auditing.pages.savedSearches.categoryNameColumnHeader,
      deleteSearchHeader: this.auditingStrings.auditing.pages.savedSearches.deleteSearchHeader,
      deleteSearchWarning: this.auditingStrings.auditing.pages.savedSearches.deleteSearchWarning,
      deleteButton: this.auditingStrings.auditing.deleteQueryLabel,
      deleteSearchError: this.auditingStrings.auditing.pages.savedSearches.deleteSearchError,
      cancel: this.coreStrings.cancel,
      privateSearch: this.auditingStrings.auditing.pages.savedSearches.privateSearch,
      sharedSearch: this.auditingStrings.auditing.pages.savedSearches.sharedSearch,
      privateSearchCategory: this.auditingStrings.auditing.pages.savedSearches.privateSearchCategory,
      sharedSearchCategory: this.auditingStrings.auditing.pages.savedSearches.sharedSearchCategory,
      userDefinedSearch: this.auditingStrings.auditing.pages.savedSearches.userDefinedSearch,
      allPrivateSearchesCategoryName: this.auditingStrings.auditing.pages.savedSearches.allPrivateSearchesCategoryName,
      allSearchesCategoryName: this.auditingStrings.auditing.pages.savedSearches.allSearchesCategoryName,
      deleteSuccessful: this.auditingStrings.auditing.pages.savedSearches.deleteSuccessful,
      copyQuery: this.auditingStrings.auditing.pages.savedSearches.copyQuery,
      getSearchesError: this.auditingStrings.auditing.pages.savedSearches.getSearchesError,
      visualize: this.auditingStrings.auditing.visualizeQueryLabel
    };
  }

  ngOnInit() {
    this.breadcrumbsService.set([{
      url: 'queries',
      title: this.auditingStrings.auditing.queries
    }]);

    // Load saved query data from service
    this.loadQueries();

    this.savedQueryService.updates.pipe(takeUntil(this.destructionSubject)).subscribe((update: any) => {
      if (update.type === 'delete') {
        this.removeQuery(update.id);
        toastLowerLeft(this.toastService, this.labels.deleteSuccessful, ToastType.Success);
      } else if (update.type === 'add') {
        this.addQuery(update.payload);
      } else if (update.type === 'star') {
        // Add to Starred category
        let starredQueries = this.categories.find(category => category.id === 'starred').queries;
        starredQueries.push(update.payload.query);
      } else if (update.type === 'unstar') {
        // Remove from starred category
        let starredCategory = this.categories.find(category => category.id === 'starred');
        starredCategory.queries = starredCategory.queries.filter(query => query.id !== update.id);
      }
    });

    this.fetchAlertRules();
  }

  /**
   * Retrieve full list of queries from database
   */
  loadQueries() {
    this.loading = true;
    this.savedQueryService.getSavedQueries().pipe(takeUntil(this.destructionSubject)).subscribe(
      (categories: SavedQueryCategory[]) => {
      this.loading = false;

      // Prepend categories with special "All Searches" category which contains everything
      this.filteredCategories = [...categories];
      let allQueriesCategory = this.getAllQueriesCategory(categories);
      // Prepend categories with special "All Private Searches" category which contains all private queries
      if (this.isPrivateQueriesAllowed()) {
        let allPrivateQueriesCategory = this.getAllPrivateQueriesCategory(allQueriesCategory);
        this.categories = [allPrivateQueriesCategory, allQueriesCategory, ...categories];
      } else {
        this.categories = [allQueriesCategory, ...categories];
      }

      if (this.selectedCategory) {
        this.selectedCategory = this.categories.find((category) => category.id === this.selectedCategory.id);
        this.onCategorySelect(this.selectedCategory);
      } else {
        this.onCategorySelect(this.categories[0]);
      }

      // Generate map of category names so we can populate category names for starred queries
      categories.forEach((category) => {
        this.categoryNameMap[category.id] = category.name;
      });
    }, (error: any) => {
      this.loading = false;
      this.error = true;
      this.errorMessage = error;
    });
  }

  /**
   * Change currently selected category
   *
   * @param category Newly selected category
   */
  onCategorySelect(selectedCategory: SavedQueryCategory) {
    this.hoveredSearchId = null;
    this.categories.forEach((category) => {
      category.expanded = category.id === selectedCategory.id;
    });
    this.selectedCategory = selectedCategory;
    this.searches = selectedCategory.queries.filter(search => !this.searchesMarkedForDeletion.includes(search.id));
  }

  /**
   * Handle addition of category
   */
  onCategoryAdd(_category: SavedQueryCategory) {
    this.loadQueries();
  }

  /**
   * Handle editing of category
   */
  onCategoryEdit() {
    this.loadQueries();
  }

  /**
   * Remove a query from all saved query categories
   *
   * @param queryId ID of query to remove
   */
  removeQuery(queryId: string) {
    for (let category of this.categories) {
      category.queries = category.queries.filter(query => query.id !== queryId);
    }
  }

  /**
   * Add query to the list of saved queries
   *
   * @param query Saved Query to add
   */
  addQuery(query: Query) {
    for (let category of this.categories) {
      if (category.id === query.categoryId) {
        category.queries.push(query);
      }
    }
  }

  /**
   * Handle clicking on "New Query" button
   */
  onNewQueryClick() {
    this.activeQueryService.clearQuery();
    if (this.selectedCategory.id !== this.allCategoryId && this.selectedCategory.id !== this.allPrivateCategoryId) {
      this.router.navigate(['/auditing/auditing/queries/editor', {categoryId: this.selectedCategory.id}]);
    } else {
      this.router.navigate(['/auditing/auditing/queries/editor']);
    }
  }

  /**
   * Delete a saved query from the database
   */
  deleteQuery(searchId: string) {
    this.markSearchForDeletion(searchId);
    this.savedQueryService.deleteSavedQuery(searchId)
      .subscribe(
        _response => this.endDeleteSearch(searchId),
        _error => this.queryDeleteError(_error, searchId)
      );
  }

  /**
   * Handle clicking the delete button for a saved query
   *
   * @param searchId ID of saved query
   */
  async handleDeleteClick(query: Query) {
    this.errorDeletingSearch = false;
    let confirmTitle = await this.localeStrings.string$(
      'auditing.pages.savedSearches.deleteSearchHeader').pipe(take(1)).toPromise();
    let confirmMessage = await this.localeStrings.string$(
      'auditing.pages.savedSearches.deleteSearchWarning', { name: query.name }).pipe(take(1)).toPromise();

    if (this.alertEnabledSearchIds.includes(query.id)) {
      confirmMessage += await this.localeStrings.string$(
        'auditing.alertEnabledSearchDeletionWarning').pipe(take(1)).toPromise();
    }

    let settings: IModalDialog = {
      type: EDialogType.WARNING
    };
    if (await this.facade.confirm(confirmTitle, confirmMessage, settings)) {
      this.deleteQuery(query.id);
    } else {
      this.endDeleteSearch(query.id);
    }
  }

  handleCopyClick(query: Query) {
    this.copyModal.openModal(query);
  }

  handleSearchCopied(query: Query) {
    let category = this.categories.find((cat) => cat.id === query.categoryId);
    this.onCategorySelect(category);
    this.loadQueries();
  }

  handleVisualizeClick(query: Query) {
    let params: any = {
      limit: 0
    };
    this.queryService.runQueryWithHttpInfo(query, params, false).subscribe((response) => {
      let results = response.body;
      if (results.totalRows > visualizationRowLimit) {
        this.showVisualizeLimitWarning(query, results.totalRows);
      } else {
        this.goToVisualization(query);
      }
    });

  }

  /**
   * Display modal dialog warning users that they have hit the limit on the number
   * of events that can be effectively visualized
   */
  showVisualizeLimitWarning(query: Query, totalRows: number) {
    let replacements: any = {
      limit: visualizationRowLimit.toLocaleString(),
      totalRows: totalRows.toLocaleString()
    };
    this.rowLimitDescription = substituteTemplateValues(this.auditingStrings.auditing.visualizationRowLimitDescription, replacements);
    this.visualizationLimitModalParams = {
      showModal: true,

      dialogParams: {
        title: this.auditingStrings.auditing.visualizationRowLimitTitle,
        type: EDialogType.WARNING,
        actions: [{
          name: this.auditingStrings.auditing.yes,
          action: () => {
            this.goToVisualization(query);
          }
        }],
        cancelText: this.auditingStrings.auditing.no,
        cancelButtonAction: () => {
          this.visualizationLimitModalParams.showModal = false;
        }
      }
    };
  }

  /**
   * Navigate to visualization for this search
   */
  goToVisualization(query: Query) {
    this.router.navigate(['/auditing/auditing/queries/visual', query.id]);
  }

  setHoveredSearch(searchId: string) {
    this.hoveredSearchId = searchId;
  }

  /**
   * Determines whether the user has permission to run the search.
   * Shared search require a different permission then Private search.
   * System-defined searches are shared searches.
   *
   * @param search The search the user has highlighted.
   */
  canUserRunSearch(search: Query): boolean {
    if (search.isShared) {
      return this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canRunSharedSearches
      ]);
    }

    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canRunPrivateSearch
    ]);
  }

  /**
   * Determines whether the user has permission to manage the searches.
   * User defined shared search require a different permission then Private search.
   * System-defined searches are shared searches.
   *
   * @param search The search the user has highlighted.
   */
  canUserManageSearch(search: Query): boolean {
    if (search.isShared) {
      return this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageSharedSearches
      ]);
    }

    return this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManagePrivateSearch
      ]);
  }

  canUserCopySearch(search: Query): boolean {
      return this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageSharedSearches,
        fromPermissions.canManagePrivateSearch
      ]);
  }

  private isPrivateQueriesAllowed() {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManagePrivateSearch,
      fromPermissions.canRunPrivateSearch
    ]);
  }

  /**
   * Mark a search to be deleted
   */
  private markSearchForDeletion(searchId: string) {
    this.searchesMarkedForDeletion = [
      ...this.searchesMarkedForDeletion,
      searchId
    ];
  }

  /**
   * Remove saved query from list of those marked for deletion
   *
   * @param searchId ID of saved query
   */
  private removeSearchFromMarkedForDeletion(searchId: string) {
    const index = this.searchesMarkedForDeletion.indexOf(searchId);
    this.searchesMarkedForDeletion = [
      ...this.searchesMarkedForDeletion.slice(0, index),
      ...this.searchesMarkedForDeletion.slice(index + 1)
    ];
  }

  private queryDeleteError(error: any, searchId: string) {
    const searchAlreadyDeleted = 404;
    if (error.status && error.status === searchAlreadyDeleted) {
      this.removeQueryFromLocal(searchId);
    } else {
      this.errorDeletingSearch = true;
      console.error(error);
    }

    this.endDeleteSearch(searchId);
  }

  /**
   * Get a new saved query category containing all queries from other categories
   *
   * @param categories List of existing categories
   */
  private getAllQueriesCategory(categories: SavedQueryCategory[]) {
    let allQueries = categories
    .map(category => category.queries)
    .reduce((accumulator, queryList) => accumulator.concat(queryList), []);

    let allQueriesCategory = new SavedQueryCategory({
      id: this.allCategoryId,
      name: this.labels.allSearchesCategoryName,
      createdBy: 'system',
      queries: allQueries,
      isProtected: true,
      isShared: true
    });
    return allQueriesCategory;
  }

  /**
   * Get a new saved query category containing all private queries from the user
   *
   * @param allCategory the 'All' category containing all searches
   */
  private getAllPrivateQueriesCategory(allCategory: SavedQueryCategory) {
    let allQueries = allCategory.queries;

    let allPrivateQueries = allQueries
      .filter((query) => !query.isShared);

    let allPrivateQueriesCategory = new SavedQueryCategory({
      id: this.allPrivateCategoryId,
      name: this.labels.allPrivateSearchesCategoryName,
      createdBy: 'system',
      queries: allPrivateQueries
    });
    return allPrivateQueriesCategory;
  }

  private removeQueryFromLocal(searchId: string) {
    const queryNotFound = -1;
    this.categories.forEach(category => {
      const queryIndex = category.queries.findIndex(query => query.id === searchId);
      if (queryIndex === queryNotFound) {
        return;
      }

      category.queries = [
        ...category.queries.slice(0, queryIndex),
        ...category.queries.slice(queryIndex + 1)
      ];
    });
  }

  private endDeleteSearch(searchId: string) {
    this.onCategorySelect(this.selectedCategory);
    this.removeSearchFromMarkedForDeletion(searchId);
  }

  private fetchAlertRules() {
    // Prevents the alerts service from being called when a user does not
    // have the required permissions.
    if (
      !this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManagePrivateAlertsAndAlertPlans,
        fromPermissions.canManageSharedAlertsAndAlertPlans
      ])
    ) {
      return;
    }

    this.alertService.getAlertRules().subscribe(
      response => {
        if (response.status === 200) {
          const data: AlertRule[] = response.body;
          this.alertEnabledSearchIds = data
            .filter(rule => rule.enabled)
            .map(rule => rule.queryId);
        } else {
          console.error(
            'Unexpected response when looking up alert rules:',
            response
          );
        }
      },
      error => {
        console.error('Failed to load alert rules:', error);
      }
    );
  }
}
