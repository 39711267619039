import { Component } from '@angular/core';

@Component({
  selector: 'error-message',
  template: `
    <div class="error-message card-panel">
      <div class="icon-container">
        <edc-icon name="status-error"></edc-icon>
      </div>
      <div class="text-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .error-message {
      color: red;
      overflow: hidden;
      display: flex
    }

    edc-icon {
      vertical-align: middle;
      margin-right: 0.5rem;
    }

    .icon-container {
      float: left;
      width: 30px;
    }

    .text-container {
      align-self: center;
      float: left;
      width: calc(100% - 30px);
    }
  `]
})

export class ErrorMessageComponent {
}
