import {
  isTopActiveUserConfigValid,
  isTopActiveUserDataValid,
  UserDetails
} from '../../models/top-active-user.domain.model';
import {
  TopActiveUsersByTargetService,
  TopActiveUsersData,
  TopActiveUsersWidgetResponse
} from '../../models/top-active-users.server.response';
import { AuditSerivceName } from '../../../../../../../shared/models/audit-workload.models';
import { cloneAndUnlockObject } from '../../../../../../../shared/utils/object.tools';

export const MAX_EXCLUDED_USER_ROWS = 100;
export const MAX_TOP_ACTIVE_USER_ROWS = 5;

export interface UserDetailsRow extends UserDetails {
  isSelected: boolean;
}

export interface ExcludedUserRow {
  fullUsername: string;
  isSelected: boolean;
}

export class ExcludeTopActiveUserEditorData {
  // the top 'x' active users and total event count for all services
  public users: UserDetailsRow[];

  public excludedUsers: ExcludedUserRow[];

  public selectedServices: AuditSerivceName[];

  constructor(private _serverResponse: TopActiveUsersWidgetResponse = null) {
    // necessary!! need to make the serverresponse mutable
    this._serverResponse = cloneAndUnlockObject(this._serverResponse);
    this.initialize();
  }

  private initialize(): void {
    this.selectedServices = [];
    this.excludedUsers = [];

    if (isTopActiveUserConfigValid(this._serverResponse)) {
      const config = this._serverResponse.configuration.topActiveUsers;
      this.selectedServices = config.selectedServices;

      // the excluded users should be sorted alphabetically.
      config.excludedUsers.sort();

      this.excludedUsers = config.excludedUsers.map(username => ({
          fullUsername: username,
          isSelected: false
        } as ExcludedUserRow));

      // limit to the max number of rows allowed in UI
      this.excludedUsers = this.excludedUsers.slice(0, MAX_EXCLUDED_USER_ROWS);
    }

    this.users = [];

    if (isTopActiveUserDataValid(this._serverResponse)) {
      const data: TopActiveUsersData = this._serverResponse.data.topActiveUsers;
      const usernames = data.labels;
      const serviceInfos: TopActiveUsersByTargetService[] = data.datasets;
      this.users = composeUsersForAllServices(usernames, serviceInfos);

      // order the result by event count descendingly
      this.users.sort((a, b) => b.eventCount - a.eventCount);

      // limit to the max number of rows allowed in UI
      this.users = this.users.slice(0, MAX_TOP_ACTIVE_USER_ROWS);
    }
  }
}

function composeUsersForAllServices(
  usernames: string[],
  serviceInfos: TopActiveUsersByTargetService[]
): UserDetailsRow[] {
  let result: UserDetailsRow[] = [];
  for (let i = 0; i < usernames.length; i++) {
    const totalEventCount = serviceInfos
      .map(serviceInfo => serviceInfo.data[i])
      .reduce((a, b) => a + b, 0);
    const user = {
      fullUsername: usernames[i],
      eventCount: totalEventCount,
      isSelected: false
    } as UserDetailsRow;

    result.push(user);
  }

  return result;
}
