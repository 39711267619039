import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BaseComponent,
  IInputConfig,
  QueryClause,
  QueryValueChanges
} from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../../application-strings-EN';
import { EventField } from '../../../../../models/event-field.model';
import * as QueryClauseGenerator from './config/query-config-generators';
import {
  IntervalCategory,
  OdaChartType,
  RenderType
} from '../../../../../../shared/components/chart/models/visualization-constants';
import {
  EditorStatus,
  validateVisualParams,
  VisualizeParameters
} from '../models/editor-parameters';
import {
  GENERIC_PREVIEW_PATH,
  GRID_PREVIEW_PATH,
  HBAR_CHART_PREVIEW_PATH,
  PIE_CHART_PREVIEW_PATH,
  TIMESERIES_CHART_PREVIEW_PATH
} from './config/visualization-image-paths';

@Component({
  selector: 'visualization-editor',
  templateUrl: './visualization-editor.component.html',
  styleUrls: ['./visualization-editor.component.scss']
})
export class VisualizationEditorComponent
  extends BaseComponent
  implements OnInit {
  @Input() projectionFields: EventField[];
  @Input() visualizeParams: VisualizeParameters;
  @Output() paramsUpdate: EventEmitter<VisualizeParameters>;
  @Output() statusUpdate: EventEmitter<EditorStatus>;

  // Image paths for chart previews
  hbarChartPreviewPath = HBAR_CHART_PREVIEW_PATH;
  pieChartPreviewPath = PIE_CHART_PREVIEW_PATH;
  timeseriesChartPreviewPath = TIMESERIES_CHART_PREVIEW_PATH;
  gridPreviewPath = GRID_PREVIEW_PATH;
  genericPreviewPath = GENERIC_PREVIEW_PATH;

  // Config variables for dropdown boxes
  clauseKey: string;
  inputConfig: IInputConfig;
  visualizeAsConfig: QueryClause = QueryClauseGenerator.getVisualizeAsConfig();
  chartTypeConfig: QueryClause = QueryClauseGenerator.getChartTypeConfig();
  summarizeByConfig: QueryClause = QueryClauseGenerator.getSummarizeByConfig();
  groupByConfig: QueryClause;

  // Labels
  visualizeAsLabel: string;
  visualizationSettingsLabel: string;
  visualizationSettingsDescriptionLabel: string;
  chartSettingsLabel: string;
  chartSettingsDescriptionLabel: string;
  groupByLabel: string;
  summarizeByLabel: string;
  chartPreviewLabel: string;
  chartPreviewDescriptionLabel: string;
  chartTypeLabel: string;
  chartTypeErrorLabel: string;
  groupByErrorLabel: string;
  summarizeByErrorLabel: string;
  visualizeAsErrorLabel: string;

  // Logic for showing drop downs and chart previews
  showChartPreview = false;
  showChartOptions = false;
  showGenericPreview = true;
  showPieChartPreview = false;
  showGridPreview = false;
  showTimeseriesChartPreview = false;
  showDonutChartPreview = false;
  showHbarChartPreview = false;
  showGroupByOptions = false;

  // a flag indicating that we should display validation errors
  // if the user attempted to navigate away.
  // This flag should be reset to false upon any user inputs
  displayErrors = false;

  // a flag indicating the group by field is deleted by the column editor
  groupByDeleted = false;

  // editor status
  private editorStatus: EditorStatus;
  private editorParameters: VisualizeParameters;
  private previousEmittedJson: string;
  private userReactedToThisComponent = false;

  get showSummarizeByOptions(): boolean {
    // this getter avoided the following runtime error:
    // ExpressionChangedAfterItHasBeenCheckedError: Expression
    // has changed after it was checked. Previous value: 'false'. Current value: 'true'.
    return !!this.editorParameters &&
           !!this.editorParameters.groupByField &&
           !(this.showDonutChartPreview || this.showHbarChartPreview);
  }

  constructor() {
    super();
    this.paramsUpdate = new EventEmitter<VisualizeParameters>();
    this.statusUpdate = new EventEmitter<EditorStatus>();
  }

  ngOnInit(): void {
    this.userReactedToThisComponent = false;
    this.setupTemplateText();
    this.editorParameters = this.visualizeParams;
    this.previousEmittedJson = JSON.stringify(this.editorParameters);
    this.editorStatus = EditorStatus.Initialized;
    this.displayErrors = false; // reset at the begining, can be changed after value setup
    this.setupValueComponentStates();
    this.validateGroupByConfig();
  }

  onVisualizeAsOptionChange(queryValueChange: QueryValueChanges): void {
    const displayType = queryValueChange.value.value as RenderType;
    this.editorParameters.visualizeAs = displayType;
    const chartTypeOption = this.chartTypeConfig.valueComponentState;

    switch (displayType) {
      case RenderType.Grid:
        this.showChartOptions = false;
        this.showGridPreview = true;
        this.showTimeseriesChartPreview = false;
        this.showDonutChartPreview = false;
        this.showHbarChartPreview = false;
        this.showGenericPreview = false;
        break;

      case RenderType.Chart:
        this.showChartOptions = true;
        this.showGridPreview = false;
        this.showGenericPreview = !chartTypeOption;
        this.showTimeseriesChartPreview =
          chartTypeOption === OdaChartType.TimeSeries;
        this.showDonutChartPreview =
          chartTypeOption === OdaChartType.Donut;
        this.showHbarChartPreview =
          chartTypeOption === OdaChartType.H_BAR;
        break;

      case RenderType.ChartAndGrid:
        this.showChartOptions = true;
        this.showGridPreview = true;
        this.showGenericPreview = !chartTypeOption;
        this.showTimeseriesChartPreview =
          chartTypeOption === OdaChartType.TimeSeries;
        this.showDonutChartPreview =
          chartTypeOption === OdaChartType.Donut;
        this.showHbarChartPreview =
          chartTypeOption === OdaChartType.H_BAR;
        break;
    }

    this.validateAndEmit();
  }

  onChartTypeValueChange(queryValueChange: QueryValueChanges): void {
    const chartType = queryValueChange.value.value as OdaChartType;
    this.editorParameters.chartType = chartType;
    this.showGenericPreview = !chartType;
    this.showGroupByOptions = !!chartType;
    this.showTimeseriesChartPreview =
      chartType === OdaChartType.TimeSeries;
    this.showDonutChartPreview =
      chartType === OdaChartType.Donut;
    this.showHbarChartPreview =
      chartType === OdaChartType.H_BAR;
    this.validateAndEmit();
  }

  onGroupByChange(queryValueChange: QueryValueChanges): void {
    // we are using the display name in drop down
    const displayName = queryValueChange.value.value as string;
    const groupByField = this.projectionFields.find(
      field => field.displayName === displayName
    );
    this.editorParameters.groupByField = groupByField;
    this.groupByDeleted = !groupByField;
    this.validateAndEmit();
  }

  onSummarizeByChange(queryValueChange: QueryValueChanges): void {
    const summarizeBy = queryValueChange.value.value as IntervalCategory;
    this.editorParameters.summarizeBy = summarizeBy;
    this.validateAndEmit();
  }

  onMouseEnter(): void {
    // This method will help us to determine if we
    // should start firing update events to the parent.
    if (!this.userReactedToThisComponent) {
      this.userReactedToThisComponent = true;
    }
  }

  onMouseLeave(): void {
    if (
      // the user has not touched the first input
      !this.visualizeAsConfig.valueComponentState ||
      // no more validations needed for grid type
      this.visualizeAsConfig.valueComponentState === RenderType.Grid
    ) {
      return;
    }

    if (!this.displayErrors) {
      this.displayErrors =
        !this.chartTypeConfig.valueComponentState ||
        !this.groupByConfig.valueComponentState ||
        !this.summarizeByConfig.valueComponentState;
    }
  }

  private ensureEditingInProgressStatus(): void {
    // reaching this point, we know the visualization parameters are not valid/ready.
    if (this.editorStatus !== EditorStatus.EditingInProgress) {
      this.editorStatus = EditorStatus.EditingInProgress;
      this.statusUpdate.emit(this.editorStatus);
    }
  }

  private updateAndEmitEvent(): void {
    if (!this.editorParameters || !this.editorParameters.visualizeAs) {
      return;
    }

    // reaching this point, you have to guarantee all the inputs are 100% valid
    if (this.editorStatus !== EditorStatus.OK) {
      this.editorStatus = EditorStatus.OK;
      this.statusUpdate.emit(this.editorStatus);
    }

    // check against previous emitted value, emit again only if there are changes
    const currentParameterJson = JSON.stringify(this.editorParameters);
    if (this.previousEmittedJson !== currentParameterJson) {
      this.paramsUpdate.emit(this.editorParameters);
      this.previousEmittedJson = currentParameterJson;
    }
  }

  private validateAndEmit(): void {
    // we only proceed iff user focused on this UI component
    // or column for group by is deleted
    if (this.userReactedToThisComponent || this.groupByDeleted) {
      if (validateVisualParams(this.editorParameters)) {
        this.updateAndEmitEvent();
      } else {
        this.ensureEditingInProgressStatus();
      }
      this.displayErrors = false;
    }
  }

  private validateGroupByConfig(): void {
    if (this.editorParameters.groupByField) {
      const fieldId = this.editorParameters.groupByField.id;
      const groupByField = this.projectionFields.find(
        field => field.id === fieldId
      );

      if (!groupByField) {
        // the groupby column was deleted from outside
        this.groupByConfig.valueComponentState = undefined;
        this.editorParameters.groupByField = undefined;
        this.groupByDeleted = true;
        this.validateAndEmit();
      }
    }
  }

  private setupValueComponentStates(): void {
    if (this.editorParameters.summarizeBy) {
      this.summarizeByConfig.valueComponentState =
        this.editorParameters.summarizeBy;
    }

    this.groupByConfig = QueryClauseGenerator.getNonDatetimeGroupByConfig(
      this.projectionFields
    );

    if (this.editorParameters.groupByField) {
      this.groupByConfig.valueComponentState =
        this.editorParameters.groupByField.displayName;
    } else {
      this.groupByConfig.valueComponentState = undefined;
    }

    if (this.editorParameters.chartType) {
      this.chartTypeConfig.valueComponentState =
        this.editorParameters.chartType;
    }

    if (this.editorParameters.visualizeAs) {
      this.visualizeAsConfig.valueComponentState =
        this.editorParameters.visualizeAs;
    }
  }

  private setupTemplateText(): void {
    const stringProvider: any =
      AuditingDisplayStringsProvider.auditing.pages.newSearches;

    this.visualizeAsLabel = stringProvider.flyoutVisualizationVisualizeAs;
    this.visualizationSettingsLabel =
      stringProvider.flyoutVisualizationVisualization;
    this.visualizationSettingsDescriptionLabel =
      stringProvider.flyoutVisualizationVisualizationSettingsDescription;
    this.chartSettingsLabel = stringProvider.flyoutVisualizationChartSettings;
    this.chartSettingsDescriptionLabel =
      stringProvider.flyoutVisualizationChartSettingsDescription;
    this.groupByLabel = stringProvider.flyoutVisualizationGroupBy;
    this.summarizeByLabel = stringProvider.flyoutVisualizationSummarizeBy;
    this.chartPreviewLabel = stringProvider.flyoutVisualizationChartPreview;
    this.chartPreviewDescriptionLabel =
      stringProvider.flyoutVisualizationChartPreviewDescription;
    this.chartTypeLabel = stringProvider.flyoutVisualizationChartType;
    this.chartTypeErrorLabel = stringProvider.flyoutChartTypeRequiredLabel;
    this.groupByErrorLabel = stringProvider.flyoutGroupByRequiredLabel;
    this.summarizeByErrorLabel = stringProvider.flyoutSummarizeByRequiredLabel;
    this.visualizeAsErrorLabel = stringProvider.flyoutVisualizeAsRequiredLabel;
  }
}
