import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckLegendData } from '../../models/legend-data';

@Component({
  selector: 'oda-check-legend-hbox',
  templateUrl: './horizontal-legend-box.component.html',
  styleUrls: ['./horizontal-legend-box.component.scss']
})
export class HorizontalLegendBoxComponent implements OnInit {
  @Input() legendTitle: string;
  @Input() legendItems: CheckLegendData[];
  @Output() changedLegend: EventEmitter<CheckLegendData>;

  legendDatas: CheckLegendData[];

  constructor() {
    this.changedLegend = new EventEmitter<CheckLegendData>();
  }

  ngOnInit(): void {
    this.legendDatas = this.legendItems;
    this.ensureOneCheckedMinimum();
  }

  onCheckChange(changedData: CheckLegendData): void {
    this.ensureOneCheckedMinimum();
    this.changedLegend.emit(changedData);
  }

  private ensureOneCheckedMinimum(): void {
    // this is to prevent the last checked item been unchecked.
    const checkedLegends = this.legendDatas.filter(data => data.checked);
    if (checkedLegends.length === 1) {
      const idx = this.legendDatas.findIndex(data => data.checked);
      this.legendDatas[idx].disable = true;
    } else if (checkedLegends.length > 1) {
      // we should clean the disable flag set previously
      const disabledIdx = this.legendDatas.findIndex(data => data.disable);
      if (disabledIdx >= 0) {
        this.legendDatas[disabledIdx].disable = false;
      }
    }
  }
}
