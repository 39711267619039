<div class="flyout-chart-container" #flyoutChartContainer>
  <div class="header-row">
    <div class="cross">
      <edc-icon [name]="'cross'" [state]="'reserve-blue'" (click)="onClearFlyoutChart()">
      </edc-icon>
    </div>
  </div>
  <div class="title-message-row">
    <h5>{{queryName}}</h5>
    <div class="truncate" title="{{queryDescription}}">{{queryDescription}}</div>
  </div>

  <div class="chart-row">
    <donut-chart
      *ngIf="showDonutFlyoutChart && !errorRenderingChart"
      [ngClass]="{ 'visualization-not-ready': !contentReady, 'visualization-ready': contentReady}"
      [query]="query"
      [chartPosition] = "chartPosition"
      (errorLoadingChart)="handleErrorFromChart($event)"
      (renderCompleted)="handleChartRenderingCompletion($event===false)">
    </donut-chart>
    <anomaly-timeseries-chart *ngIf="showTimeseriesFlyoutChart && !errorRenderingChart"
      [ngClass]="{ 'visualization-not-ready': !contentReady, 'visualization-ready': contentReady}" [query]="query"
      (errorLoadingChart)="handleErrorFromChart($event)" (renderCompleted)="handleChartRenderingCompletion($event)">
    </anomaly-timeseries-chart>
    <error-card *ngIf="errorRenderingChart"
      error="{{ 'auditing.pages.criticalActivity.flyout.errorLoadingLabel' | localize }}"
      button="{{ 'auditing.pages.criticalActivity.flyout.errorLoadingActionLabel' | localize }}"
      (buttonClick)="handleErrorRetryButtonClick()"></error-card>
    <internal-loading *ngIf="loadingChart"
      information="{{ 'auditing.pages.criticalActivity.flyout.loadingVisualizationMessage' | localize }}">
    </internal-loading>
  </div>

  <div class="action-buttons-row">
    <div class="action-button-left-column">
      <button class="btn-flat" type="button" id="view-all-events-btn-id" (click)="onClickViewAllEventsButton()"
        [disabled]="errorRenderingChart || !contentReady">
        {{ 'auditing.pages.criticalActivity.flyout.viewAllEventsButtonLabel' | localize }}
      </button>
    </div>
    <div class="action-button-right-column">
      <button class="btn-flat" type="button" id="dismiss-activity-btn-id" (click)="onClickDismissActivityButton()"
        [disabled]="errorRenderingChart || !contentReady">
        {{ 'auditing.pages.criticalActivity.flyout.dismissActivityButtonLabel' | localize }}
      </button>
    </div>
  </div>
</div>

<dismiss-modal (hideItem)="handleDismissEvent($event)"></dismiss-modal>
