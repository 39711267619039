<modal-window #saveAsModal [params]="modalParams" *ngIf="formData && query && categories">
  <div modal-content class="save-as-modal">

    <error-message *ngIf="errorMessage">{{errorMessage}}</error-message>

    <form #saveAsForm="ngForm" (ngSubmit)="submitSaveAs()">

      <div class="input-field">
        <input id="saveAsName" type="text" name="saveAsName"
          [(ngModel)]="formData.name" autocomplete="off" required #queryName="ngModel">
        <label for="saveAsName">{{pageLabels.saveAsSearchPrompt}}</label>
      </div>

      <error-message
        class="empty-name-error"
        *ngIf="queryName.invalid">{{pageLabels.errorEmptyName}}</error-message>

      <div class="input-field">
        <select
          class="browser-default"
          name="saveAsCategory"
          id="saveAsCategory"
          (change)="onValueChange()"
          [(ngModel)]="formData.categoryId"
          required
          #categoryMenu="ngModel"
        >
          <option *ngIf="categories.length === 0" disabled selected value="">{{ auditStrings.loading }}</option>
          <option [value]="category.id" *ngFor="let category of allowedCategories">{{ category.name }}</option>
        </select>
        <label for="saveAsName">{{pageLabels.saveAsSearchCategoryPrompt}}</label>
      </div>

      <private-shared-type-selector
        [isPrivateSelectionAllowed]="isPrivateSelectionAllowed"
        [isSharedSelectionAllowed]="isSharedSelectionAllowed"
        [isSharedItemSelected]="isShared"
        (buttonSelected)="onToggleButtonsChanged($event)"
      ></private-shared-type-selector>

      <div class="action-button-container-outer">
        <div class="action-buttons right">
          <button
            type="button"
            id="save-button"
            class="btn submit-save-as-button"
            [disabled]="!saveAsForm.valid || saving"
            (click)="submitSaveAs()"
          >
            {{pageLabels.submitSaveAsButton}}
          </button>
          <button
            type="button"
            class="btn-flat cancel-save-as-button"
            data-test="submit-save-as"
            (click)="cancelSaveAs()"
          >{{pageLabels.cancelSaveAsButton}}</button>         
        </div>
      </div>
    </form>
  </div>
</modal-window>
