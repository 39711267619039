<div class="auditing-wrapper">
  <view-wrapper
    class="provisioning-view-wrapper"
    *ngIf="!provisioned"
    [params]="{
      showBreadcrumb: false,
      relativePath: true,
      showHelp: true
    }"
  >
    <router-outlet></router-outlet>
  </view-wrapper>

  <view-wrapper
    *ngIf="provisioned"
    [params]="{
      showBreadcrumb: true,
      breadcrumbs: breadcrumbs,
      relativePath: relativePath,
      showHelp: true
    }"
  >
    <div class="card-panel">
      <div class="row tabs-header">
        <auditing-tabs></auditing-tabs>
      </div>

      <div class="trial-exceeded-banner" *ngIf="isTrialLimitReached">
        <qod-notification type="warning">
          <span
            [innerHtml]="'auditing.trialLimits.trialExceeded' | localize"
          ></span>
          <a
            (click)="onClickLearnMore()"
            [innerHtml]="'auditing.trialLimits.learnMore' | localize"
            id="trial-limits-learn-more"
          ></a>
          <span
            [innerHtml]="'auditing.trialLimits.purchaseSubscription' | localize"
          ></span>
        </qod-notification>
      </div>

      <div class="row tabs-content" [style.background-color]="bgColor">
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </view-wrapper>
</div>

<trial-limits-modal></trial-limits-modal>
