import { CriticalActivityResponse } from '../../../../../../shared/models/critical-activity.shared.models';
import * as fromCriticalActivity from './critical-activity.actions';

export interface CriticalActivityState {
  data: CriticalActivityResponse;
  loading: boolean;
  error: any;
}

export const initialState: CriticalActivityState = {
  data: null,
  loading: false,
  error: undefined
};

export function reducer(
  state: CriticalActivityState = initialState,
  action: fromCriticalActivity.CriticalActivityAction
): CriticalActivityState {
  switch (action.type) {
    case fromCriticalActivity.RELOAD_CRITICAL_ACTIVITY:
    case fromCriticalActivity.LOAD_CRITICAL_ACTIVITY:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromCriticalActivity.RELOAD_CRITICAL_ACTIVITY_SUCCESS:
    case fromCriticalActivity.LOAD_CRITICAL_ACTIVITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: undefined
      };
    case fromCriticalActivity.RELOAD_CRITICAL_ACTIVITY_FAIL:
    case fromCriticalActivity.LOAD_CRITICAL_ACTIVITY_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
