import { EServiceID } from '../configuration/tenant-list/service-ids';

export interface EventTypeMetadata {
  serviceID: EServiceID | string;
  serviceNameReference: string;
  eventTypeNameReference: string;
}

export const limitedRetentionEventTypes: EventTypeMetadata[] = [
  {
    serviceID: EServiceID.AZURE_AD,
    serviceNameReference: 'auditing.azureADName',
    eventTypeNameReference: 'auditing.retentionTableAccountProvisioningName'
  },
  {
    serviceID: EServiceID.AZURE_AD_SIGN_INS,
    serviceNameReference: 'auditing.azureADName',
    eventTypeNameReference: 'auditing.retentionTableAzureSuccessfulSigninName'
  },
  {
    serviceID: EServiceID.ONEDRIVE,
    serviceNameReference: 'auditing.onedriveName',
    eventTypeNameReference: 'auditing.retentionTableOneDriveFileSyncName'
  },
  {
    serviceID: EServiceID.ONEDRIVE,
    serviceNameReference: 'auditing.onedriveName',
    eventTypeNameReference: 'auditing.retentionTableOneDriveFileAccessName'
  },
  {
    serviceID: EServiceID.SHAREPOINT,
    serviceNameReference: 'auditing.sharepointName',
    eventTypeNameReference: 'auditing.retentionTableSharePointFileAccessName'
  },
  {
    serviceID: EServiceID.SHAREPOINT,
    serviceNameReference: 'auditing.sharepointName',
    eventTypeNameReference: 'auditing.retentionTableSharePointFileSyncName'
  },
  {
    serviceID: EServiceID.SHAREPOINT,
    serviceNameReference: 'auditing.sharepointName',
    eventTypeNameReference: 'auditing.retentionTableSharePointPageViewName'
  },
  {
    serviceID: EServiceID.TEAMS,
    serviceNameReference: 'auditing.teamsName',
    eventTypeNameReference: 'auditing.retentionTableTeamsSigninName'
  }
];
