<qod-sheet 
  variant="large"
  [isOpen]="isOpen"
  [heading]="headingText"
  [subHeading]="subHeadingText"
  [ignoreBackdropClick]="true"
  (close)="handleCloseEvent()"
  > 
<div sheet-content>
  <form #edtiorForm="ngForm">
    <ng-container *ngIf="errorOccurred">
      <error-card
        [error]="errorMessage"
        [button]="tryAgainButtonLabel"
        (buttonClick)="loadEditorData()"
      ></error-card>
    </ng-container>
    <ng-container *ngIf="!errorOccurred">
      <div class="top-active-user-selector-box">
        <div class="listbox-header-schema listbox-item">
          <div class="checkbox-container">
            <input 
              id="select-all-top-active-users" 
              type="checkbox" 
              class="filled-in" 
              name="selectAllTopActiveUsersCheck" 
              [disabled]="(topActiveUserCount < 1) || (topActiveUserCount + excludedUserCount > MaxExcludedUserCountAllowed) || isLoadingData || isUpdatingData"
              [(ngModel)]="selectAllTopActiveUsers" 
              (change)="handleSelectAllTopActiveUsersCheckChange()"/> 
            <label for="select-all-top-active-users"></label>
          </div>
          <p class="listbox-description">{{ this.activeUsernameColumnHeader }}</p>
          <p class="listbox-value">{{ this.eventCountColumnHeader }}</p>
        </div>
        <ul class="listbox listbox-data-row-schema top-active-user-selector-height">

          <ng-container *ngIf="isLoadingData || isUpdatingData">
            <li>
              <p class="item-message">{{ this.loadingLabel }}</p>
            </li>
          </ng-container>

          <ng-container *ngIf="!isLoadingData && !isUpdatingData">
            <li *ngIf="topActiveUserCount < 1">
              <p class="item-message">{{ this.noUsersMessage }}</p>
            </li>
            <li *ngFor="let user of editorData.users; index as i;">
              <div class="listbox-item">
                <div class="checkbox-container">
                  <input 
                    id="select-top-active-user-{{i}}" 
                    type="checkbox" class="filled-in" 
                    name="select-top-active-username-{{i}}" 
                    [disabled]="!user.isSelected && (selectedActiveUserCount + excludedUserCount >= MaxExcludedUserCountAllowed)"
                    [(ngModel)]="user.isSelected" 
                    (change)="updateSelectedTopActiveUserStatuses()"/> 
                  <label for="select-top-active-user-{{i}}"></label>
                </div>
                <p class="listbox-description">{{user.fullUsername}}</p>
                <p class="listbox-value">{{user.eventCount | number:'1.0':'en-US'}}</p>
              </div>
            </li>
          </ng-container>

        </ul>
        
        <button type="button" class="btn-secondary" [disabled]="selectedActiveUserCount < 1 || isLoadingData || isUpdatingData" (click)="handleAddExcludedUsers()">{{this.addSelectedTopActiveUserTextWithCount}}</button>

      </div>

      <div class="warning-box" *ngIf="maxExcludeUserWarning">
        <qod-dismissible-notification type="warning" [text]="maxExcludeUserWarningText" [dismissible]="true" (crossClicked)="handleDismissWarningClick()"></qod-dismissible-notification>
      </div>

      <div class="excluded-users-selector-box">
        
        <h5>{{this.excludedUsersHeadingTextWithCount}}</h5>

        <div class="listbox-header-schema listbox-item">
          <div class="checkbox-container">
            <input 
              id="select-all-excluded-users" 
              type="checkbox" 
              class="filled-in" 
              name="selectAllExcludedUsersCheck"
              [disabled]="excludedUserCount < 1 || isLoadingData || isUpdatingData" 
              [(ngModel)]="selectAllExcludedUsers" 
              (change)="handleSelectAllExcludedUsersCheckChange()"/> 
            <label for="select-all-excluded-users"></label>
          </div>
          <p class="listbox-description">{{ this.excludedUsernameColumnHeader }}</p>
          <p 
            class="listbox-value"
            [ngClass]="{'action-header-right-padding-lo': excludedUserCount < 5, 'action-header-right-padding-hi': excludedUserCount >= 5}"
            >{{ this.excludedUserActionColumnHeader }}</p>
        </div>

        <ul class="listbox listbox-data-row-schema excluded-user-selector-height">

          <ng-container *ngIf="isLoadingData || isUpdatingData">
            <li>
              <p class="item-message">{{ this.loadingLabel }}</p>
            </li>
          </ng-container>

          <ng-container *ngIf="!isLoadingData && !isUpdatingData">
            <li *ngIf="excludedUserCount < 1">
              <p class="item-message">{{ this.noUsersMessage }}</p>
            </li>
            <li *ngFor="let excludedUser of editorData.excludedUsers; index as i;">
              <div class="listbox-item">
                <div class="checkbox-container">
                  <input 
                    id="select-excluded-user-{{i}}" 
                    type="checkbox" 
                    class="filled-in" 
                    name="select-excluded-users-{{i}}" 
                    [(ngModel)]="excludedUser.isSelected" 
                    (change)="updateExcludedUsersStatuses()"/> 
                  <label for="select-excluded-user-{{i}}"></label>
                </div>
                <p class="listbox-description">{{excludedUser.fullUsername}}</p>
                <p 
                  class="listbox-value"
                  [ngClass]="{'action-icon-right-padding-lo': excludedUserCount < 5, 'action-icon-right-padding-hi': excludedUserCount >= 5}"
                  >
                  <edc-icon
                    name="remove"
                    size="small"
                    state="reserve-blue"
                    (click)="handleRemoveSingleExcludedUser(excludedUser.fullUsername)"
                  ></edc-icon>
                </p>
              </div>
            </li>
          </ng-container>
          
        </ul>

        <button type="button" class="btn-secondary" [disabled]="selectedToExcludeUserCount  < 1 || isLoadingData || isUpdatingData" (click)="handleRemoveExcludedUsers()">{{this.removeExcludedUsersButtonTextWithCount}}</button>

      </div>
    </ng-container>
  </form>
</div>

<div sheet-footer-left>
  <button type="button" class="btn " (click)="handleCloseEvent()"
  >{{this.closeButtonLabel}}</button>
</div>

</qod-sheet>
