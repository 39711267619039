import { DismissModalComponent } from './flyout-chart/dismiss-modal/dismiss-modal.component';
import { FlyoutChartComponent } from './flyout-chart/flyout-chart.component';
import { DonutComponent } from './oda-donut-chart/donut.component';
import {
  AnomalyTimeseriesChartBuilderService
} from './oda-anomaly-timeseries-chart/services/anomaly-timeseries-chart-builder.service';
import {
  AnomalyTimeseriesChartDataExtractorService
} from './oda-anomaly-timeseries-chart/services/anomaly-timeseries-chart-data-extractor.service';
import {
  AnomalyTimeseriesChartQueryMouseEventService
} from './oda-anomaly-timeseries-chart/services/anomaly-timeseries-chart-query-mouse-event.service';
import {
  AnomalyTimeseriesChartTooltipService
} from './oda-anomaly-timeseries-chart/services/anomaly-timeseries-chart-tooltip.service';
import { AnomalyTimeseriesComponent } from './oda-anomaly-timeseries-chart/anomaly-timeseries.component';
import { LegendComponent } from './oda-donut-chart/legend/legend.component';
import { DonutChartDataExtractorService } from './oda-donut-chart/services/donut-chart-data-extractor.service';
import { DonutConfigurationService } from './oda-donut-chart/services/donut-config.generator';
import { TimeseriesComponent } from './oda-timeseries-chart/timeseries.component';
import { ChartQueryService } from './services/chart-query.service';
import {
  TimeseriesChartBuilderService
} from './oda-timeseries-chart/service/timeseries-chart-builder.service';
import {
  TimeseriesChartDataExtractorService
} from './oda-timeseries-chart/service/timeseries-chart-data-extractor.service';
import {
  TimeseriesChartQueryMouseEventService
} from './oda-timeseries-chart/service/timeseries-chart-query-mouse-event.service';
import {
  TimeseriesChartTooltipService
} from './oda-timeseries-chart/service/timeseries-chart-tooltip.service';
import { HorizontalBarChartComponent } from './oda-hbar-chart/horizontal-bar-chart.component';
import { CheckLegendItemV1Component } from './legends/legend-item/check-legend-item.v1.component';
import { HorizontalLegendBoxComponent } from './legends/horizontal-legend-box/horizontal-legend-box.component';
import { AvailableValuesService } from '../../../auditing/services/available-values/available-values.service';
import { HBarConfigurationService } from './oda-hbar-chart/services/hbar-config.generator';
import { ChartPermissionsService } from './services/chart-permissions.service';

export const chartFiles = {
  components: [
    DonutComponent,
    AnomalyTimeseriesComponent,
    LegendComponent,
    TimeseriesComponent,
    FlyoutChartComponent,
    DismissModalComponent,
    HorizontalBarChartComponent,
    CheckLegendItemV1Component,
    HorizontalLegendBoxComponent
  ],
  services: [
    ChartQueryService,
    DonutChartDataExtractorService,
    DonutConfigurationService,
    ChartPermissionsService,
    TimeseriesChartBuilderService,
    TimeseriesChartDataExtractorService,
    TimeseriesChartQueryMouseEventService,
    TimeseriesChartTooltipService,
    AnomalyTimeseriesChartBuilderService,
    AnomalyTimeseriesChartDataExtractorService,
    AnomalyTimeseriesChartQueryMouseEventService,
    AnomalyTimeseriesChartTooltipService,
    AvailableValuesService,
    HBarConfigurationService,
    ChartPermissionsService
  ]
};
