<span class="alert-plan-list-title" *ngIf="notificationPlans.length > 0" >
  {{ 'auditing.notificationTemplateListTitle' | localize }}
</span>

<ul class="alert-plan-list browser-default">
  <li class="alert-plan" *ngFor="let plan of notificationPlans">
    <a
      *ngIf="hasManageAlertPlansPermission; else alertPlanName"
      (click)="notificationPlanClicked(plan.id)"
      >{{ plan.name }}</a
    >
    <ng-template #alertPlanName
      ><span class="alert-plan__name">{{
        plan.name
      }}</span></ng-template
    >
  </li>
</ul>

<od-notification-plan-edit *ngIf="editorIsOpen"
  [notificationPlanId]="selectedPlanId"
  [ownerModule]="ownerModule"
  [isOpen]="editorIsOpen"
  [displayPlanType]="editorShowPLanType"
  (whenClosed)="editorClosed()"
  ></od-notification-plan-edit>
