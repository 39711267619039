<ng-container *ngIf="modalParams">
  <modal-window [params]="modalParams">

    <div modal-content>
      <div class="tenant-status-modal">
        <ul>
          <li class="status-message card-panel" *ngFor="let message of messages">
            <div class="message-type-indicator" [ngClass]="message.severity">
              <edc-icon name="{{ getMessageIcon(message.severity) }}"></edc-icon>
            </div>
            <div class="message-text" [innerHTML]="message.message">
            </div>
          </li>
        </ul>
      </div>
    </div>

  </modal-window>
</ng-container>
