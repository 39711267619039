import {
  BarChartOptions,
  DonutChartOptions,
  TimeSeriesADChartOptions,
  TimeSeriesChartOptions
} from './chart-models';

export class QueryChart {
  bar?: BarChartOptions;
  donut?: DonutChartOptions;
  timeSeriesAnomalyDetection?: TimeSeriesADChartOptions;
  timeSeries?: TimeSeriesChartOptions;

  constructor(params?: any) {
    this.bar = null;
    this.donut = null;
    this.timeSeries = null;
    this.timeSeriesAnomalyDetection = null;
    // assign nulls to all the fields, so as to include them in JSON.stringify()
    // for proper detections in unsaved-editor-changes-guard.ts
    Object.assign(this, params);
  }

  toJSON() {
    return {
      bar: this.bar,
      donut: this.donut,
      timeSeriesAnomalyDetection: this.timeSeriesAnomalyDetection,
      timeSeries: this.timeSeries
    };
  }

  equals(chart: QueryChart) {
    return JSON.stringify(this) === JSON.stringify(chart);
  }
}
