import { finalize, take, map } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Query } from '../../../../models/query.model';
import { AlertRule } from '../../../../models/alert-rule.model';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { BaseComponent, EDialogType, IModalWindow, AppFacadeService } from '@ondemand/core';
import { AlertService } from '../../../../services/alerts.service';
import { AlertRuleEditorModalComponent,
         AlertRuleEditorSavedEvent } from '../../../alerts/alert-rule-editor-modal/alert-rule-editor-modal.component';
import { AlertPlan } from '../../../../models/alert-plan.model';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../../shared/utils/toast.wrapper';

import * as fromPermissions from '../../../../models/audit-permissions.model';

@Component({

    selector: 'alert-button',
    templateUrl: './alert-button.component.html',
    styleUrls: ['./alert-button.component.scss']
})
export class AlertButtonComponent extends BaseComponent implements OnChanges {
  @ViewChild(AlertRuleEditorModalComponent, {static: true}) alertRuleEditorModal: AlertRuleEditorModalComponent;
  @Input() query: Query;
  alertRule: AlertRule;
  alertEnabled: boolean = null;
  successModalParams: IModalWindow;
  successModalContent: {
    linkUrl: string;
    linkTextPath: string;
  };
  activeRequest = false;

  fromPermissions = fromPermissions;

  constructor(private alertService: AlertService,
              private localeStrings: LocaleStringsService,
              private facade: AppFacadeService,
              private toastService: ODToastService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Reset button status after
    if (changes.query &&
        (this.alertEnabled === null ||
         changes.query.currentValue.id !== changes.query.previousValue.id)
       ) {
      this.setButtonStatus();
    }
  }

  /**
   * Handle clicking alert toggle button
   */
  async onAlertClick() {
    if (this.alertEnabled) {
      this.promptToDisableAlert();
    } else {
      this.promptToEnableAlert();
    }
  }

  async onAlertRuleSaved(savedEvent: AlertRuleEditorSavedEvent) {
    this.alertRule = savedEvent.savedAlertRule;
    this.alertEnabled = this.alertRule.enabled;

    const newPlanCreated = savedEvent.createdAlertPlan !== null;

    if (newPlanCreated) {
      this.successModalContent = {
        linkUrl: `/auditing/auditing/alerts/plans/${savedEvent.createdAlertPlan.id}`,
        linkTextPath: 'auditing.alertPlanEditNewLinkText'
      };
    } else {
      const plansSelected: AlertPlan[] = await this.alertService.getAlertPlans(this.alertRule.id).pipe(
        map((response) => response.body))
        .toPromise();
      const isSinglePlanSelected = plansSelected.length === 1;
      if (isSinglePlanSelected) {
        const plan = plansSelected[0];
        this.successModalContent = {
          linkUrl: `/auditing/auditing/alerts/plans/${plan.id}`,
          linkTextPath: 'auditing.alertPlanEditLinkText'
        };
      } else {
        this.successModalContent = {
          linkUrl: `/auditing/auditing/alerts/plans`,
          linkTextPath: 'auditing.alertPlanMultipleEditLinkText'
        };
      }
    }

    this.showSuccessModal();
  }

  async onAlertRuleSaveFailed() {
    toastLowerLeft
    (
      this.toastService,
      await this.localeStrings.string$('auditing.alertRuleSaveFailed').pipe(take(1)).toPromise(),
      ToastType.Error
    );
  }

  private async promptToDisableAlert() {
    // Disable alert rule if it already exists
    const title = await this.localeStrings.string$('auditing.alertRuleDisableConfirmationTitle').pipe(
    take(1)).toPromise();
    const message = await this.localeStrings.string$('auditing.alertRuleDisableConfirmation').pipe(
      take(1)).toPromise();
    if (await this.facade.confirm(title, message, { type: EDialogType.INFO })) {
      this.disableAlertRule();
    }
  }

  private async promptToEnableAlert() {
    if (this.alertRule) {
      this.alertRuleEditorModal.openToEditAlertRule(this.alertRule.id);
    } else {
      this.alertRuleEditorModal.openToCreateAlertRule(this.query);
    }
  }

  private async onAlertRuleDisableFailed() {
    toastLowerLeft(
      this.toastService,
      await this.localeStrings.string$('auditing.alertRuleDisableFailed').pipe(take(1)).toPromise(),
      ToastType.Error
    );
  }

  private setButtonStatus() {
    if (!this.query.id || this.activeRequest) {
      return;
    }

    this.activeRequest = true;
    this.alertService.getAlertRules(this.query.id).pipe(
    finalize(() => this.activeRequest = false))
    .subscribe((response) => {
      if (response.status === 200) {
        let data: AlertRule[] = response.body;
        if (data.length > 0) {
          this.alertRule = data[0];
          this.alertEnabled = this.alertRule.enabled;
        } else {
          this.alertRule = null;
          this.alertEnabled = false;
        }
      } else {
        console.error('Failed to check alert rule status');
      }
    }, (error) => {
      console.error('Failed to check alert rule status:', error);
    });
  }

  private disableAlertRule() {
    // Make copy
    let ruleCopy = new AlertRule();
    Object.assign(ruleCopy, this.alertRule);
    ruleCopy.enabled = false;

    this.activeRequest = true;
    this.alertService.updateAlertRule(ruleCopy).pipe(
    finalize(() => this.activeRequest = false))
    .subscribe(async (response) => {
      if (response.status === 200) {
        const updatedRule = response.body;
        this.alertRule = updatedRule;
        const message = await this.localeStrings.string$('auditing.alertRuleDisableSuccess').pipe(
          take(1)).toPromise();
        this.alertEnabled = false;
        toastLowerLeft(this.toastService, message, ToastType.Success);
      } else {
        console.error('Unexpected response when disabling alert rule:', response);
        this.onAlertRuleDisableFailed();
      }
    }, (error) => {
      console.error('Failed to disable alert rule ' + this.alertRule.id, error);
      this.onAlertRuleDisableFailed();
    });
  }

  private async showSuccessModal() {
    let modalTitle = await this.localeStrings.string$('auditing.alertRuleSaveSuccess').pipe(
      take(1)).toPromise();

    this.successModalParams = {
      showModal: true,
      dialogParams: {
        title: modalTitle,
        type: EDialogType.INFO,
        hideCancel: true,
        actions: [{
          name: await this.localeStrings.string$('auditing.okay').pipe(take(1)).toPromise(),
          action: () => {
            this.closeModal();
          }
        }]
      }
    };
  }

  private closeModal() {
    this.successModalParams.showModal = false;
  }

}
