export enum FeatureFlagType {
  EnableNotificationPlans = 'auditing.enableNotificationPlans',
  EnableDowntimeState = 'auditing.enableDowntimeState',
  AllowAU = 'auditing.allowRegion.au',
  AllowUK =  'auditing.allowRegion.uk',
  AllowCanada = 'auditing.allowRegion.canada',
  Debug = 'auditing.debug',
  TestUI = 'auditing.testUi',
  NewExperience = 'common.consent.newExperience'
}
