import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WidgetService } from '../../../../../shared/services/widget-service';
import {
  LoadWidgetList,
  LoadWidgetListFail,
  LoadWidgetListSuccess,
  LOAD_WIDGET_LIST
} from './widget-list.actions';

@Injectable()
export class WidgetListEffects {
  loadWidgetList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadWidgetList>(LOAD_WIDGET_LIST),
    switchMap(action =>
      this.widgetService.getWidgetListWithHttpInfo().pipe(
        map(response => new LoadWidgetListSuccess(response.body)),
        catchError(error => of(new LoadWidgetListFail(error)))
      )
    )
  ));

  constructor(
    private widgetService: WidgetService,
    private actions$: Actions
  ) {}
}
