export const HBAR_CHART_PREVIEW_PATH =
'/assets/auditing/img/hbar-chart.svg';
export const PIE_CHART_PREVIEW_PATH =
'/assets/auditing/img/donut-chart.svg';
export const TIMESERIES_CHART_PREVIEW_PATH =
'/assets/auditing/img/time-series.svg';
export const GRID_PREVIEW_PATH =
'/assets/auditing/img/Grid.svg';
export const GENERIC_PREVIEW_PATH =
'/assets/auditing/img/Preview.svg';
