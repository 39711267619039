import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';

import { IModalWindow, BaseComponent } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { SavedQueryCategory } from '../../saved-queries/saved-query-category.model';
import { SavedQueriesService } from '../../../../services/saved-queries.service';
import { Query } from '../../../../models/query.model';
import { SEARCH_PERMISSION, SearchPermissionsService } from '../../../../services/search-permissions.service';
import { getAllowedQueryTypeAsPerPermissions } from '../../allowed-query-type';

@Component({
  selector: 'save-as-modal',
  templateUrl: './save-as-modal.component.html',
  styleUrls: ['./save-as-modal.component.scss']
})
export class SaveAsModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() categories: SavedQueryCategory[];
  @Input() formData: any;
  @Input() query: Query;
  @Output() save: EventEmitter<any> = new EventEmitter();

  auditStrings = AuditingDisplayStringsProvider.auditing;
  pageLabels = AuditingDisplayStringsProvider.auditing.pages.newSearches;
  modalParams: IModalWindow;
  errorMessage: string;
  saving = false;
  isShared = false;
  allowedCategories: SavedQueryCategory[];
  isPrivateSelectionAllowed = true;
  isSharedSelectionAllowed = true;

  searchPermission: string = SEARCH_PERMISSION.NONE;
  defaultSearchTypes: string =
    AuditingDisplayStringsProvider.auditing.privateType;

  @ViewChild('saveAsForm') form: NgForm;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private savedQueriesService: SavedQueriesService,
    private searchPermissionsService: SearchPermissionsService
  ) {
    super();
  }

  ngOnInit() {
    // Set up modal for editing columns
    this.modalParams = {
      showModal: false,
      dialogParams: {
        title: this.pageLabels.saveAsModalTitle,
        hideCancel: true,
        cancelButtonAction: () => {
          this.cancelSaveAs();
        }
      }
    };
    this.setPermission();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Open modal window
   */
  openModal() {
    this.modalParams.showModal = true;
    this.modalParams = { ...this.modalParams };
    this.isShared = this.formData.isShared = getAllowedQueryTypeAsPerPermissions(this.formData.isShared, this.searchPermission);
    this.allowedCategories = this.filterCategories(this.categories);
    this.setSharedSelectionAllowed();
  }

  /**
   * Close modal window
   */
  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = { ...this.modalParams };
  }

  cancelSaveAs() {
    this.closeModal();
  }

  submitSaveAs() {
    this.errorMessage = null;
    // Update real query object with new values
    let newQuery = new Query(this.query);
    this.removeStaleData(newQuery);
    this.setNewMetadata(newQuery);

    this.saving = true;
    this.savedQueriesService
      .createSavedQueryWithHttpInfo(newQuery)
      .toPromise()
      .then(
        response => {
          this.saving = false;
          if (response.status === 201) {
            let query = new Query(response.body);
            this.save.emit(query);
            this.closeModal();
          } else {
            console.error('Bad status code:', response);
          }
        },
        (response: any) => {
          this.saving = false;
          console.error('Error saving new search:', response);
          if (response.status === 409) {
            this.errorMessage = this.pageLabels.errorDuplicateName;
          } else {
            this.errorMessage = this.auditStrings.saveQueryError;
          }
        }
      );
  }

  onToggleButtonsChanged(button: string) {
    this.isShared = button !== this.defaultSearchTypes;
  }

  onValueChange() {
    this.setSharedSelectionAllowed();
    // Set query type to private, when selected category is private
    if (!this.isSharedSelectionAllowed) {
    this.isShared = false;
    }
  }

  private setSharedSelectionAllowed() {
    if (this.searchPermission === SEARCH_PERMISSION.PRIVATE_AND_SHARED) {
      let matchedCategories = this.categories.filter(category => category.id === this.formData.categoryId);
      this.isSharedSelectionAllowed = (matchedCategories !== null) ? matchedCategories[0].isShared : false;
    } else if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      this.isPrivateSelectionAllowed = false;
    } else { // consider private category
      this.isSharedSelectionAllowed = false;
    }
  }

  private setPermission() {
    this.searchPermission = this.searchPermissionsService.getManageSearchPermission();
  }

  private filterCategories(categories: SavedQueryCategory[]) {
    let filteredCategories: SavedQueryCategory[];
    if (this.searchPermission === SEARCH_PERMISSION.SHARED) {
      filteredCategories = categories.filter(category => category.isShared === true);
      return filteredCategories;
    }
    return categories;
  }

  private removeStaleData(query: Query) {
    delete query.id;
    delete query.createdDate;
    delete query.lastUpdated;
    delete query.organizationId;
    delete query.userId;
  }

  private setNewMetadata(query: Query) {
    query.name = this.formData.name;
    query.categoryId = this.formData.categoryId;
    query.createdBy = 'user';
    query.isShared = this.isShared;
  }
}
