import {
  UpdateFavoriteSearchAction,
  UPDATE_FAVORITE_SEARCH,
  UPDATE_FAVORITE_SEARCH_FAIL,
  UPDATE_FAVORITE_SEARCH_SUCCESS
} from './favorite-search-editor.actions';

export interface FavoriteSearchEditorState {
  updateCompleted: boolean;
  error: any;
}

export const initialState: FavoriteSearchEditorState = {
  updateCompleted: undefined,
  error: undefined
};

export function reducer(
  state: FavoriteSearchEditorState = initialState,
  action: UpdateFavoriteSearchAction
): FavoriteSearchEditorState {
  switch (action.type) {
    case UPDATE_FAVORITE_SEARCH:
      return {
        ...state,
        updateCompleted: false,
        error: null
      };
    case UPDATE_FAVORITE_SEARCH_SUCCESS:
      return {
        ...state,
        updateCompleted: true
      };
    case UPDATE_FAVORITE_SEARCH_FAIL:
      return {
        updateCompleted: false,
        error: action.payload
      };
    default:
      return state;
  }
}
