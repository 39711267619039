import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { SharedComponentsModule } from '@ondemand/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../../shared/shared.module';
import { CriticalActivityTabComponent } from './critical-activity-tab.component';
import { criticalActivityListFiles } from './critical-activity-list/index';

export const effectsForFeature: ModuleWithProviders<any> =
  EffectsModule.forFeature([...criticalActivityListFiles.effects]);

export const storeForFeature: ModuleWithProviders<any> = StoreModule.forFeature(
  'criticalActivityTab',
  reducers
);

export const gridWithComponents: ModuleWithProviders<any> =
  AgGridModule.withComponents([CriticalActivityTabComponent]);

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    effectsForFeature,
    storeForFeature,
    SharedModule,
    gridWithComponents
  ],
  declarations: [
    CriticalActivityTabComponent,
    ...criticalActivityListFiles.components
  ],
  providers: []
})
export class CriticalActivityTabModule {}
