<div #editor class="editor">
  <internal-loading
    [information]="coreStrings.loadingPleaseWait"
    [isLoading]="loadingQuery && !loadingFieldsError"
  ></internal-loading>
  <div class="visualization-internal-loading">
    <internal-loading
      information="{{ 'auditing.pages.criticalActivity.flyout.loadingVisualizationMessage' | localize }}"
      [isLoading]="!visualizationLoaded"
    ></internal-loading>
  </div>
  <error-message *ngIf="loadingQueryError">
    {{ loadingQueryErrorMessage }}
    <a [routerLink]="['/auditing/auditing/queries/saved']">{{
      'auditing.queryBuilderQueryLoadError2' | localize
    }}</a>
  </error-message>
  <error-message *ngIf="loadingFieldsError">
    {{ 'auditing.queryBuilderFieldsLoadError' | localize }}
  </error-message>
  <error-message *ngIf="loadingCategoriesError">{{
    'auditing.pages.newSearches.errorLoadCategories' | localize
  }}</error-message>
  <div
    class="row"
    *ngIf="!loadingQuery && !loadingFieldsError && !loadingQueryError"
  >
    <ca-query-editor-actions
      (actionEmitter)="handleAction($event)"
      (queryPreviewEmitter)="handlePreviewToggle($event)"
      [formValid]="queryBuilderForm.valid && !loadingQuery && !loadingFields"
      [isPristine]="queryBuilderForm.pristine && !queryIsDirty"
      [query]="query"
      [isDuplicateQuery]="isDuplicateQueryError"
      [errorMessage]="actionsErrorMessage"
      [isSaving]="savingQuery"
      [categories]="categories"
    ></ca-query-editor-actions>
    <div class="row last-saved-query-container">
      <last-saved-info-field
        [showLastSavedInfo]="shouldShowLastSavedInfo()"
        [lastSavedLabelInfo]= "lastSavedLabelInfo"
        [lastSavedToolTipRefreshProp]="lastSavedToolTipRefreshProp"
        [lastSavedToolTipInfo]="lastSavedToolTipInfo"
      ></last-saved-info-field>
    </div>
    <div class="row search-type-container" *ngIf="shouldShowSearchTypeField()">
      <div class="search-type-label-container left" *ngIf="manageSearchPermission !== 'None'">
          {{ 'auditing.querySearchType' | localize }}
      </div>
      <div class="search-type-label-container left" *ngIf="manageSearchPermission === 'Private'">
        {{ 'auditing.privateType' | localize }}
      </div>
      <div class="search-type-label-container left" *ngIf="manageSearchPermission === 'Shared'">
          {{ 'auditing.sharedType' | localize }}
      </div>
      <div class="search-type-label-container left" *ngIf="manageSearchPermission === 'PrivateAndShared' && query.isShared">
        {{ 'auditing.sharedType' | localize }}
      </div>
      <div class="search-type-label-container left" *ngIf="manageSearchPermission === 'PrivateAndShared' && !query.isShared">
        {{ 'auditing.privateType' | localize }}
      </div>
    </div>

    <div class="row search-category-container">
      <div class="category-label-container left">
          {{ 'auditing.querySearchCategory' | localize }}
      </div>
      <!-- Search category menu -->
      <div class="category-menu-container left">
        <select
          [(ngModel)]="query.categoryId"
          name="search-category"
          id="search-category"
          materialize="material_select"
          [materializeSelectOptions]="allowedCategories"
        >
          <option value="" disabled>{{
            'auditing.pages.newSearches.chooseCategory' | localize
          }}</option>
          <option
            *ngFor="let category of allowedCategories"
            [value]="category.id"
            >{{ category.name }}</option
          >
        </select>
      </div>
    </div>

    <div class="editor__builder">
      <form #queryBuilderForm="ngForm" materialize>
        <div class="row clause-list-container">
          <p class="intro-text">
            {{ 'auditing.queryBuilderDescription' | localize }}
          </p>
          <div class="editor__builder__body" *ngIf="fields && !loadingFields">
            <editor-clause
              *ngFor="
                let clause of query.q.clauses;
                index as i;
              "
              [(ngModel)]="query.q.clauses[i]"
              [fields]="fields"
              [i]="i"
              [removable]="query.q.clauses.length > 1"
              (remove)="removeClause(i)"
              name="query-clause-{{ i }}"
              class="editor-clause"
            >
            </editor-clause>
          </div>

          <div class="add-clause-container">
            <button
              class="btn-flat waves-effect waves-ripple"
              (click)="addClause()"
              id="add-query-clause-button"
              title="{{
                'auditing.pages.newSearches.addClauseTooltip' | localize
              }}"
            >
              <edc-icon name="plus"></edc-icon>
              {{ 'auditing.pages.newSearches.addClause' | localize }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col s12">
    <div class="row edit-cols-container">
      <div
        class="btn-flat edit-columns-button"
        (click)="openEditColumnsFlyout()"
      >
        <edc-icon name="cog"></edc-icon>
        {{ applicationStrings.pages.newSearches.editColumnsButton }}
      </div>
    </div>
    <div *ngIf="!hideCharts && queryBuilderForm.valid" class="row chartContainer">
      <donut-chart
      [query]="query"
      (chartAreaClick)="onChartCategoryClicked($event)"
      (renderCompleted)="onVisualizationLoaded($event===false)"
      *ngIf="enableDonutChart">
    </donut-chart>
      <timeseries-chart
        [query]="query"
        (visualizationLoaded)="onVisualizationLoaded($event)"
        (chartElementClicked)="onChartElementClicked($event)"
        *ngIf="enableTimeSeriesChart"
      ></timeseries-chart>
      <hbar-chart
        [inputQuery]="query"
        [autoDrilldown]="false"
        (loading)="onVisualizationLoaded($event===false)"
        (barClicked)="onChartCategoryClicked($event)"
        *ngIf="enableHBarChart"
      ></hbar-chart>
    </div>
    <div *ngIf="!hideEvents" class="row eventsContainer">
      <results-preview
        [results]="results"
        [loadingResults]="loadingQueryPreview"
        [loadingError]="loadingQueryPreviewError"
        (cellClick)="handleCellClick($event)"
        (reorder)="onReorder($event)"
        (sort)="onSort($event)"
      ></results-preview>
    </div>
  </div>
  </div>
</div>

<column-editor-flyout
  *ngIf="!loadingQuery && query"
  [columns]="query.q.columns"
  [fields]="fields"
  [visualizeParams]="visualizeParams"
  (columnChange)="onColumnChange($event)"
></column-editor-flyout>

<save-as-modal
  [query]="query"
  [categories]="categories"
  [formData]="saveAsFormData"
  (save)="handleSaveAsQuerySuccess($event)"
></save-as-modal>

<save-modal
  [query]="query"
  [categories]="categories"
  [formData]="saveFormData"
  (save)="handleSavedQuerySuccess($event)"
></save-modal>
