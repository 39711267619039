import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  SigninsWidgetResponse,
  SigninsConfigurationResponse,
  SigninsDataResponse
} from '../models/signins.models';
import * as fromSignins from './sign-ins.actions';

export interface SigninsState {
  data: SigninsWidgetResponse;
  loading: boolean;
  updating: boolean;
  expired: boolean;
  error: any;
}

export const initialState: SigninsState = {
  data: null,
  loading: false,
  updating: false,
  expired: false,
  error: null
};

export function reducer(
  state: SigninsState = initialState,
  action: fromSignins.SigninsAction
): SigninsState {
  switch (action.type) {
    case fromSignins.LOAD_SIGN_INS:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromSignins.UPDATE_SIGN_INS:
      return {
        ...state,
        updating: true
      };
    case fromSignins.LOAD_SIGN_INS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        expired: false,
        error: null
      };
    case fromSignins.UPDATE_SIGN_INS_SUCCESS:
      return {
        ...state,
        updating: false,
        expired: true,
        error: null
      };
    case fromSignins.LOAD_SIGN_INS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case fromSignins.UPDATE_SIGN_INS_FAIL:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    default:
      return state;
  }
}
