/**
 * Service for managing charts' data
 */

import { Injectable } from '@angular/core';
import { Results } from '../../../../../auditing/components/queries/results/results.model';
import { OthersColumnID } from '../../models/chart-constants';

@Injectable()
export class DonutChartDataExtractorService {
  extractData(chartQueryResults: Results, groupByColumn: string) {
    // Sort rows in descending order.
    chartQueryResults.rows.sort(
      (first , second) =>
      second.cells[1].value - first.cells[1].value
      );

    let extractedTextValuePair = this.getRowLabels(chartQueryResults);
    let extractedEventCounts = this.getRowData(chartQueryResults);
    let extractedValues = extractedTextValuePair.map((item) =>
       item.value
    );

    let extractedTexts = extractedTextValuePair.map((item) =>
       item.text
    );

    // Moves 'others' category at the end of the  list.
    const indexOfOthers = extractedTexts.indexOf(OthersColumnID);
    if (indexOfOthers !== -1) {
      extractedTexts = this.positionOthersCategory(extractedTexts, indexOfOthers);
      extractedValues = this.positionOthersCategory(extractedValues, indexOfOthers);
      extractedEventCounts = this.positionOthersCategoryEventCount(extractedEventCounts, indexOfOthers);
    }

    let chartData: [string[], number[], string[]] = [
                                                     extractedValues,
                                                     extractedEventCounts,
                                                     extractedTexts
                                                    ];
    return chartData;
  }

  /**
   * Maps the first element in each row of the results, which should correspond to the actor(user) or aggregate/grouping name.
   *
   * @returns list of labels for each row that will be used for the graph.
   */
  getRowLabels(chartQueryResults: Results) {
    return chartQueryResults.rows.map(row => {
      let value = row.cells[0].value;
      if (value.length > 30) {
        value = value.substring(0, 30);
        value.concat('...');
      }
      return {
              value : row.cells[0].value,
              text: row.cells[0].label
      };
    });
  }

  /**
   * Maps the second element in each row of the results, which should correspond to the count of events for a given actor(user).
   *
   * @returns list of data values from the aggregated/grouped query rows.
   */
  getRowData(chartQueryResults: Results): number[] {
    return chartQueryResults.rows.map(row => this.isNumber(row.cells[1].value) ? row.cells[1].value : 0);
  }

  /**
   * calculates the total number of events for the data contained in the donut graph. This will be shown in the middle of the graph.
   *
   * @returns integer value holding the total number of events.
   */
  countTotalEvents(chartQueryResults: Results) {
    let total = 0;
    let rows = this.getRowData(chartQueryResults);
    rows.forEach(row => {
      if (this.isNumber(row)) {
        total += row;
      }
    });

    return total;
  }

// Moves others category at the end of the list.
private positionOthersCategory(items: any[], indexOfOthers: number ): any[] {
  if (indexOfOthers !== -1) {
    items.splice(indexOfOthers, 1);
    items.push(OthersColumnID);
  }
  return items;
}

// Moves others category count at the end of the list.
private positionOthersCategoryEventCount(items: number[], indexOfOthers: number ): number[] {
  if (indexOfOthers !== -1) {
    const resultWithOthersCount = items.splice(indexOfOthers, 1);
    items.push(resultWithOthersCount[0]);
  }
  return items;
}

private isNumber(val: any): boolean {
  return typeof val === 'number';
}
}
