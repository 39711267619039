/**
 * Component for displaying auditing event details
 */

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { EventService } from '../../../../services/event.service';
import { ActiveQueryService } from '../../../../services/active-query.service';
import { BaseComponent } from '@ondemand/core';

import { Row } from '../results.row.model';
import { SingleEventQueryRequestBody } from '../../../../models/single-event-query.model';
import { FilterEvent, KEY_CODES, LEGACY_KEY_CODES } from '../event-details.table.model';

declare let window: any;

const dateColumnId = 'Date';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent extends BaseComponent implements OnChanges {
  @Input() eventId: string;
  @Input() row: Row;
  @Input() totalEvents: number;
  @Input() eventNumber: number;
  @Input() displayAddFilter = true;
  @Output() prev = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() filter = new EventEmitter<FilterEvent>();

  loadingEventDetails = false;
  loadingEventError = false;
  event: any;

  constructor(
    private eventService: EventService,
    private activeQueryService: ActiveQueryService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventId && changes.eventId.currentValue === null) {
      // Set status to loading if the currently-selected
      // event has been cleared
      this.loadingEventDetails = true;
      this.event = null;
    } else {
      this.loadEventData();
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeypress(event: KeyboardEvent) {
    // TODO: Remove when we start using Materialize > v0.100
    // Workaround to only handle keystrokes while the sidenav is open
    // This is required because this widget does not trigger an event when
    // the sidenav is closed, so we don't have an easy way of tracking its state
    const isUnitTest = window.isKeyPressTest;
    const sidenavOpened = document.querySelector('#sidenav-overlay');
    if (isUnitTest || sidenavOpened) {
      if (
        event.code === KEY_CODES.RIGHT_ARROW ||
        event.keyCode === LEGACY_KEY_CODES.RIGHT_ARROW
      ) {
        this.viewNext();
      } else if (
        event.code === KEY_CODES.LEFT_ARROW ||
        event.keyCode === LEGACY_KEY_CODES.LEFT_ARROW
      ) {
        this.viewPrevious();
      }
    }
  }

  /**
   * Load event data for this event from the event service
   *
   */
  loadEventData() {
    this.event = null;
    this.loadingEventDetails = true;
    let queries = this.activeQueryService.getQuery();
    let query = queries.activeQuery;

    this.eventService
      .getEvent(this.eventId, {
        body: query.q,
        date: this.getDateFromRow()
      } as SingleEventQueryRequestBody)
      .toPromise()
      .then(
        async data => {
          this.event = data;
          this.loadingEventError = false;
        },
        _data => {
          this.loadingEventError = true;
        }
      )
      .then(() => {
        this.loadingEventDetails = false;
      });
  }

  closeComponent() {
    this.close.emit();
  }

  /**
   * Display previous event from search results
   *
   */
  viewPrevious() {
    if (this.eventNumber > 1) {
      this.prev.emit();
    }
  }

  /**
   * Display next event in the search results
   *
   */
  viewNext() {
    if (this.eventNumber < this.totalEvents) {
      this.next.emit();
    }
  }

  /**
   * Propagate "filter" event
   *
   * @param event Filter event
   */
  onFilter(event: FilterEvent) {
    this.filter.emit(event);
  }

  private getDateFromRow(): string {
    const noResult = '';

    if (!this.row || !this.row.cells) {
      return noResult;
    }

    const dateCell = this.row.cells.find(
      cell => cell.columnId === dateColumnId
    );

    return !!dateCell ? dateCell.value : noResult;
  }
}
