<ng-container *ngIf="modalParams">
    <modal-window [params]="modalParams">
  
      <div modal-content>
        <div class="trial-limits-modal">
          
        </div>
      </div>
  
    </modal-window>
  </ng-container>
  