import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';

export interface StaticIndicatorSettings {
  title: string;
  widgetQuery?: any;
  userQuery: any;
}

export interface StaticIndicatorDataset {
  label: string;
  data: number;
  details?: any[];
}

export interface StaticIndicatorData {
  label: string;
  datasets: StaticIndicatorDataset[];
}

export interface StaticIndicatorConfigurationResponse {
  timestamp: string;
  adAccountLockouts?: StaticIndicatorSettings;
  allFailedSignins?: StaticIndicatorSettings;
  azureADCriticalRoleChanges?: StaticIndicatorSettings;
  azureADFailedSignins?: StaticIndicatorSettings;
  azureADRiskEvents?: StaticIndicatorSettings;
  cloudOnlyAzureADUsersCreated?: StaticIndicatorSettings;
  o365ExternalUserActions?: StaticIndicatorSettings;
}

export interface StaticIndicatorDataResponse {
  timestamp: string;
  adAccountLockouts?: StaticIndicatorData;
  allFailedSignins?: StaticIndicatorData;
  azureADCriticalRoleChanges?: StaticIndicatorData;
  azureADFailedSignins?: StaticIndicatorData;
  azureADRiskEvents?: StaticIndicatorData;
  cloudOnlyAzureADUsersCreated?: StaticIndicatorData;
  o365ExternalUserActions?: StaticIndicatorData;
}

export type StaticIndicatorResponse = AuditingDashboardResponse<
  StaticIndicatorDataResponse,
  StaticIndicatorConfigurationResponse
>;

export class TrendColor {
  public static readonly positive: string = 'success-green';
  public static readonly negative: string = 'danger-red';
}
