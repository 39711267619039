<form #f="ngForm">
  <input
    #field
    class="email-field"
    multiEmail
    type="text"
    name="email-value"
    [(ngModel)]="emailAddresses"
    (change)="onEmailChange()"
    placeholder="{{ 'auditing.alertActionEmailValuePlaceholder' | localize }}"
    [disabled]="!canEdit"
  />
</form>
