/**
 * Service for managing timeseries charts' data
 */

import { Injectable } from '@angular/core';
import { Results } from '../../../../../auditing/components/queries/results/results.model';

@Injectable()
export class TimeseriesChartDataExtractorService {

  getTimeseriesRowData(chartQueryResults: Results) {
    let dataset = new Map<string, number[]>();
    let uniqueTimestamps = Array.from(
      new Set(
        chartQueryResults.rows.map(row => row.cells[2].value)));

    let uniqueGroupings = Array.from(
      new Set(
        chartQueryResults.rows.map(row => row.cells[0].label)));

    uniqueGroupings.forEach(group => {
      let vals: number[] = [];
      uniqueTimestamps.forEach( () => {
        vals.push(0);
      });
      dataset.set(group, vals);
    });

    chartQueryResults.rows.forEach(row => {
      let index = uniqueTimestamps.indexOf(row.cells[2].value);
      let arr = dataset.get(row.cells[0].label);
      arr[index] = row.cells[1].value;
    });

    return dataset;
  }

  getTimestamps(chartQueryResults: Results): string[] {
    let timeStamps = Array.from(
      new Set(
        chartQueryResults.rows.map(row => row.cells[2].value)
      )
    );

    return timeStamps.reverse();
  }
}
