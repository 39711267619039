/**
 * Component for editing an alert plan
 */
import { takeUntil, take, finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertPlan } from '../../../models/alert-plan.model';
import { AlertAction } from '../../../models/alert-action.model';
import { AlertService } from '../../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { Observable ,  Subscription, ReplaySubject } from 'rxjs';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import {
  BaseComponent,
  IModalDialog,
  EDialogType,
  AppFacadeService,
  Util
} from '@ondemand/core';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { UnsavedChangesService } from '../../../services/unsaved-changes.service';
import { NgForm } from '@angular/forms';

import * as fromPermissions from '../../../models/audit-permissions.model';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  getLastSavedToolTipInfo,
  getLastSavedLabelText
} from '../../../util/last-saved-info';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';

export const defaultPlanId = 'default';
export const newPlanId = 'new';
enum ValidationError {
  AlertPlanAlreadyExists = 'alertPlanAlreadyExists',
  AlertPlanNameTooLong = 'alertPlanNameTooLong',
  EmailAddressTooLong = 'emailAddressTooLong',
  TooManyActions = 'tooManyActions',
}
@Component({
  templateUrl: './alert-plan-editor.component.html',
  styleUrls: ['./alert-plan-editor.component.scss']
})
export class AlertPlanEditorComponent extends BaseComponent implements OnInit {
  @ViewChild('planEditorForm', {static: true}) form: NgForm;
  plan: AlertPlan;
  locatingAlertPlanError: Observable<string>;
  saveError: Observable<string>;
  saving = false;
  isAlertPlanUIUpdated = false;
  formTrackingSubscription: Subscription;

  actionTypes: any[] = [];

  privateAlertPlanType: string =
    AuditingDisplayStringsProvider.auditing.privateType;
  sharedAlertPlanType: string =
    AuditingDisplayStringsProvider.auditing.sharedType;
  isShared = false;
  isPrivateSelectionAllowed = true;
  isSharedSelectionAllowed = true;
  lastSavedToolTipInfo: ReplaySubject<string> = new ReplaySubject<string>(1);
  lastSavedToolTipRefreshProp: string[] = [];
  lastSavedLabelInfo: ReplaySubject<string> = new ReplaySubject<string>(1);

  fromPermissions = fromPermissions;

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private localeStrings: LocaleStringsService,
    private breadcrumbs: AuditingBreadcrumbsService,
    private unsavedChangesService: UnsavedChangesService,
    private facade: AppFacadeService,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  async ngOnInit() {
    this.updateBreadcrumbs();
    try {
      await this.loadPlan();
    } catch (error) {
      return;
    }
    this.setupChangeTracking(this.plan);
  }

  showSharedPlanLastSavedInfo(): boolean {
    return this.plan.id && this.plan.isShared;
  }

  userCanEditThisAlertPlan(): boolean {
    return (
      (this.isShared && this.userCanManageSharedAlertPlans()) ||
      (!this.isShared && this.userCanManagePrivateAlertPlans())
    );
  }

  userCanManageSharedAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageSharedAlertsAndAlertPlans
    ]);
  }

  userCanManagePrivateAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]);
  }

  onClickAddAction() {
    this.plan = {
      ...this.plan,
      actions: [...this.plan.actions, this.getDefaultAction()]
    };
  }

  onClickRemoveAction(i: AlertAction) {
    this.plan.actions = this.plan.actions.filter(action => action.id !== i.id);
  }

  onClickSave() {
    this.resetError();
    this.saving = true;
    let request: Observable<HttpResponse<any>>;
    if (this.plan.id) {
      // Update existing plan
      request = this.alertService.updateAlertPlan(this.plan);
    } else {
      // Create new plan
      request = this.alertService.createAlertPlan(this.plan);
    }
    request.pipe(
      finalize(() => (this.saving = false)))
      .subscribe(
        async response => {
          if (response.status === 200 || response.status === 201) {
            const toastMessage = await this.localeStrings
              .string$('auditing.savedPlanSuccess').pipe(take(1)).toPromise();

            toastLowerLeft(this.toastService, toastMessage, ToastType.Success);
            const data = response.body;
            this.plan = this.getActionsWithIds(data as AlertPlan);
            this.unsavedChangesService.setOriginalValue(this.plan);
            this.unsavedChangesService.setCurrentValue(this.plan);
            this.determineTypeSelectorState(true);
            this.updateLastSavedInfo();
            this.isAlertPlanUIUpdated = false;
          } else {
            console.error('Unexpected response:', response);
            const errorMessage = await this.localeStrings
              .string$('auditing.savePlanFailed').pipe(take(1)).toPromise();

            toastLowerLeft(this.toastService, errorMessage, ToastType.Error);
          }
        },
        async (errorResponse: HttpErrorResponse) => {
          console.error('Bad response:', errorResponse);
          if (errorResponse.status === 409 || errorResponse.status === 400) {
            this.identifyAndEmitSaveError(errorResponse.error);
          }
          const errorMessage = await this.localeStrings
              .string$('auditing.savePlanFailed').pipe(take(1)).toPromise();

          toastLowerLeft(this.toastService, errorMessage, ToastType.Error);
        }
      );
  }

  onToggleButtonsChanged(button: string) {
    this.isShared = button === this.sharedAlertPlanType;
    this.plan.isShared = this.isShared;
  }

  onNameChange() {
    this.isAlertPlanUIUpdated = true;
    return;
  }

  resetError() {
    this.saveError = null;
  }

  async onClickTest() {
    const promptTitle = await this.localeStrings
      .string$('auditing.testAlertPlanPromptTitle').pipe(
      take(1))
      .toPromise();
    const promptMessage = await this.localeStrings
      .string$('auditing.testAlertPlanPromptMessage', {
        numActions: this.plan.actions.length
      }).pipe(
      take(1))
      .toPromise();
    const promptSettings: IModalDialog = {
      type: EDialogType.INFO
    };
    if (await this.facade.confirm(promptTitle, promptMessage, promptSettings)) {
      this.alertService.testAlertPlan(this.plan.id).subscribe(
        async () => {
          const successMessage = await this.localeStrings
            .string$('auditing.testAlertPlanSuccess').pipe(take(1)).toPromise();

          toastLowerLeft(this.toastService, successMessage, ToastType.Success);
        },
        async error => {
          console.error('Request to trigger test failed:', error);
          const errorMessage = await this.localeStrings
            .string$('auditing.testAlertPlanError').pipe(take(1)).toPromise();

          toastLowerLeft(this.toastService, errorMessage, ToastType.Error);
        }
      );
    }
  }

  doesAlertRecipientListExist(): boolean {
    let isRecipientListValid = true;
    if (this.plan.actions && this.plan.actions.length > 0) {
      this.plan.actions.forEach( action => {
        if (action.emailSettings.recipients && action.emailSettings.recipients.length > 0) {
           action.emailSettings.recipients.forEach( recipient =>  isRecipientListValid = isRecipientListValid && recipient !== '');
        } else {
          isRecipientListValid = false;
        }
      });
    } else {
      isRecipientListValid = false;
    }
    // Set UI change flag
    this.isAlertPlanUIUpdated = this.unsavedChangesService.isChanged();
    return isRecipientListValid;
  }

  private async identifyAndEmitSaveError(responseBody: any) {
    let mulptipleErrorsLength = 0;
    if (responseBody && responseBody.error && responseBody.error.code) {
      if (responseBody.error.details) {
        mulptipleErrorsLength = Object.keys(responseBody.error.details).length;
      }
    }
    const saveError =
      mulptipleErrorsLength > 0
        ? responseBody.error.details[0].code
        : responseBody.error.code;

    let errorIdentifier: ValidationError = null;
    switch (saveError) {
      case 'AlertPlanAlreadyExists':
        errorIdentifier = ValidationError.AlertPlanAlreadyExists;
        break;
      case 'AlertPlanNameTooLong':
        errorIdentifier = ValidationError.AlertPlanNameTooLong;
        break;
      case 'EmailAddressTooLong':
        errorIdentifier = ValidationError.EmailAddressTooLong;
        break;
      case 'TooManyActions':
        errorIdentifier = ValidationError.TooManyActions;
        break;
    }
    this.saveError = errorIdentifier
      ? await this.localeStrings.string$('auditing.' + errorIdentifier)
      : null;
  }

  private async setDefaultPlan() {
    const newPlanName = await this.localeStrings
      .string$('auditing.newPlanName').pipe(
      take(1))
      .toPromise();
    this.plan = new AlertPlan({
      id: null,
      name: newPlanName,
      actions: [this.getDefaultAction()],
      isShared: !this.userCanManagePrivateAlertPlans()
    });
  }

  private async updateLastSavedInfo() {
    const lastSavedLabel = await this.localeStrings
      .string$('auditing.lastSavedLabel').pipe(
      take(1))
      .toPromise();
    const lastSavedTooltip = await this.localeStrings
      .string$('auditing.lastSavedTooltip').pipe(
      take(1))
      .toPromise();

    setTimeout(() => {
      this.lastSavedToolTipRefreshProp = [];
    }, 200);

    this.lastSavedToolTipInfo.next(
      getLastSavedToolTipInfo(
        lastSavedTooltip,
        this.plan.createdDate,
        this.plan.createdBy,
        this.plan.modifiedDate,
        this.plan.modifiedBy
      )
    );

    this.lastSavedLabelInfo.next(
      getLastSavedLabelText(
        lastSavedLabel,
        this.plan.modifiedBy,
        this.plan.modifiedDate
      )
    );
  }

  /**
   * Get a default alert action for use when creating a new alert plan
   * or adding a new
   */
  private getDefaultAction(): AlertAction {
    let action: AlertAction = {
      actionType: 'email',
      emailSettings: {
        recipients: []
      },
      id: Util.GUID()
    };
    return action;
  }

  private async updateBreadcrumbs() {
    let crumbs = [
      {
        title: await this.localeStrings
          .string$('auditing.alertPlanListNavTitle').pipe(
          take(1))
          .toPromise(),
        // TODO: Update URL when we allow viewing plan list
        url: 'auditing/auditing/alerts/plans/default'
      }
    ];

    if (this.plan) {
      if (this.plan.id) {
        crumbs.push({
          title: this.plan.name,
          url: `auditing/auditing/alerts/plans/${this.plan.id}`
        });
      } else {
        crumbs.push({
          title: this.plan.name,
          // TODO: Update URL when we allow viewing plan list
          url: `auditing/auditing/alerts/plans/default`
        });
      }
    }

    this.breadcrumbs.set(crumbs);
  }

  /**
   * Load existing alert plan from service, or set a default one if this is a
   * new alert plan
   */
  private async loadPlan() {
    return new Promise<void>(async (resolve, reject) => {
      const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
      const planId = params.planId;
      if (!planId) {
        this.locatingAlertPlanError = this.localeStrings.string$(
          'auditing.planNotFound'
        );
      }

      // If this is new, set a default plan
      if (planId === newPlanId) {
        await this.setDefaultPlan();
        this.determineTypeSelectorState(false);
        this.setupChangeTracking(null);
        this.isAlertPlanUIUpdated = true;
      } else {
        this.alertService.getAlertPlan(planId).subscribe(
          response => {
            if (response.status === 200) {
              const data: AlertPlan = response.body;
              this.plan = this.getActionsWithIds(data);
              this.determineTypeSelectorState(true);

              this.setupChangeTracking(this.plan);
              this.updateBreadcrumbs();
              this.updateLastSavedInfo();
              resolve();
            } else {
              console.error('Got unexpected response from service:', response);
              this.locatingAlertPlanError = this.localeStrings.string$(
                'auditing.errorLoadingAlertPlan'
              );
              reject();
            }
          },
          error => {
            if (error.status === 403) {
              console.error('Failed to load plan:', error);
              this.locatingAlertPlanError = this.localeStrings.string$(
                'auditing.errorLoadingAlertPlanNoPermission'
              );
            } else {
              console.error('Failed to load plan:', error);
              this.locatingAlertPlanError = this.localeStrings.string$(
                'auditing.errorLoadingAlertPlan'
              );
            }
            reject();
          }
        );
      }
    });
  }

  private getActionsWithIds(data: AlertPlan) {
    data.actions = data.actions.map(
      (action: AlertAction) =>
        ({ ...action, id: Util.GUID() } as AlertAction)
    );
    return data;
  }

  /**
   * Keep track of form changes for the purposes of "unsaved changes" handling
   */
  private setupChangeTracking(originalValue: any) {
    if (this.formTrackingSubscription) {
      this.formTrackingSubscription.unsubscribe();
    }
    this.unsavedChangesService.setOriginalValue(originalValue);
    this.formTrackingSubscription = this.form.valueChanges.pipe(
      takeUntil(this.destructionSubject))
      .subscribe(() => {
        // Keep our record of the current value updated
        this.unsavedChangesService.setCurrentValue(this.plan);
      });
  }

  private determineTypeSelectorState(readonly: boolean) {
    this.isShared = this.plan.isShared;
    if (readonly) {
      this.isPrivateSelectionAllowed = !this.isShared;
      this.isSharedSelectionAllowed = this.isShared;
    } else {
      this.isPrivateSelectionAllowed = this.userCanManagePrivateAlertPlans();
      this.isSharedSelectionAllowed = this.userCanManageSharedAlertPlans();
    }
  }
}
