/*
 * widget CriticalActivity
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ondemand/core';
import { catchError, delay, filter, map, takeUntil } from 'rxjs/operators';
import { LoadCriticalActivity, ReloadCriticalActivity } from './state/critical-activity.actions';
import { CriticalActivityState } from './state/critical-activity.reducer';
import {
  selectCriticalActivity,
  selectCriticalActivityError,
  selectCriticalActivityLoading
} from './state/critical-activity.selectors';
import { AuditingDashboardResponse } from '../../models/auditing-dashboard.models';
import {
  CriticalActivityDataResponse,
  CriticalActivityConfiguration,
  CriticalActivityResponse,
  CriticalActivityDataSet,
  CriticalActivityAlertType
} from '../../../../../shared/models/critical-activity.shared.models';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import * as SearchPermissions from '../../../../models/audit-permissions.model';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { CriticalActivityComponentInterface } from './critical-activity.component.interface';
import { DataTransformerService } from './services/data-transformer.service';
import { Observable } from 'rxjs';
import { getCriticalLevelIconPath } from '../../../../../shared/critical-activity/critical-level-translator';
import {
  CriticalActivityDataRow,
  MoreResultContainerStyle
} from './models/critical-activity-widget.models';
import { WIDGET_HEIGHT } from './configuration/critical-activity-widget.config';
import { Rect } from '../../../../../shared/models/html.models';
import { Query } from '../../../../models/query.model';
import { ActiveQueryService } from '../../../../services/active-query.service';

@Component({
  selector: 'critical-activity-widget',
  templateUrl: './critical-activity.component.html',
  styleUrls: ['./critical-activity.component.scss']
})
export class CriticalActivityComponent
  extends BaseComponent
  implements OnInit, CriticalActivityComponentInterface {
  @Input() refreshObservable$: Observable<void>;

  viewActionLabel: string;
  detailsActionLabel: string;
  canSeeViewActionButton: boolean;
  canSeeDetailsActionButton: boolean;

  dataloaded = false;
  hasCriticalActivity = false;
  moreResultsAvailable = false;
  hasPermissionsForFullPage = false;
  errorOccurred = false;
  dataLoading$: Observable<boolean>;
  dataError$: Observable<any>;
  data$: Observable<CriticalActivityResponse>;
  cardData$: Observable<CriticalActivityDataRow[]>;

  readonly cardHeight = WIDGET_HEIGHT;

  get canSeeMoreResultsAvailableButton(): boolean {
    return (
      this.hasCriticalActivity &&
      this.hasPermissionsForFullPage &&
      this.moreResultsAvailable
    );
  }

  constructor(
    private store: Store<CriticalActivityState>,
    private router: Router,
    private activeQueryService: ActiveQueryService,
    private permissionsService: AuditModulePermissionsService,
    private localeStringsService: LocaleStringsService,
    private dataTransformer: DataTransformerService
  ) {
    super();
  }

  ngOnInit() {
    this.localeStringsService.strings$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.viewActionLabel =
          labels.auditing.pages.auditingDashboard.criticalActivity.activityRowActions.viewActionLabel;
        this.detailsActionLabel =
          labels.auditing.pages.auditingDashboard.criticalActivity.activityRowActions.detailsActionLabel;
      });

    this.initializePermissions();
    this.initializeDataSubscription();
    this.loadCriticalActivity();
    this.listenToRefresh();
  }

  initializeDataSubscription() {
    this.dataLoading$ = this.store.select(selectCriticalActivityLoading);
    this.dataError$ = this.store.select(selectCriticalActivityError);
    this.data$ = this.store.select(selectCriticalActivity);
    this.cardData$ = this.data$.pipe(
      takeUntil(this.destructionSubject),
      filter(response => !!response),
      delay(0), // the delay is for fixing some change detection issues.
      map(response =>
        this.dataTransformer.transformDataFromWidgetResponse(response, this)
      ),
      catchError(err => {
        this.errorOccurred = true;
        return [];
      })
    );

    this.dataError$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(error => {
        this.errorOccurred = !!error;
      });
  }

  initializePermissions() {
    const sharedSearchPermission = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunSharedSearches
    ]);
    const searchVisualPermission = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunSearchVisual
    ]);

    this.hasPermissionsForFullPage =
      sharedSearchPermission && searchVisualPermission;
  }

  loadCriticalActivity() {
    this.store.dispatch(new LoadCriticalActivity());
  }

  reloadCriticalActivity() {
    this.store.dispatch(new ReloadCriticalActivity());
  }

  getCriticalLevelImageIconPath(
    priority: number,
    alertType: CriticalActivityAlertType
  ): string {
    return getCriticalLevelIconPath(priority, alertType);
  }

  actionEventHandler(
    actionName: string,
    queryId: string,
    query: Query,
    label: string
  ): void {
    switch (actionName) {
      case this.viewActionLabel: {
        if (this.canSeeViewActionButton) {
          if (queryId) {
            this.router.navigate([
              '/auditing/auditing/queries/results',
              queryId
            ]);
          } else {
            this.activeQueryService.setQuery(query, query);
            this.router.navigate(['/auditing/auditing/queries/results']);
          }
        }
        break;
      }
      case this.detailsActionLabel: {
        if (this.canSeeDetailsActionButton) {
          this.router.navigate(['/auditing/auditing/criticalActivity', label]);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  viewMoreResults() {
    if (this.canSeeMoreResultsAvailableButton) {
      this.router.navigate(['/auditing/auditing/criticalActivity']);
    }
  }

  getMoreResultContainerStyle(
    moreResultActionContainer: HTMLDivElement
  ): MoreResultContainerStyle {
    const cardContainer = document.getElementById(
      'critical-activity-widget-card'
    );
    const moreResultsBtn = document.getElementById(
      'more-results-available-button'
    );
    const cardRect = cardContainer.getBoundingClientRect() as Rect;
    const actionContainerRect =
      moreResultActionContainer.getBoundingClientRect() as Rect;

    let containerStyle: MoreResultContainerStyle = {};

    const containerHeight = cardRect.bottom - actionContainerRect.y;
    containerStyle.height = `${containerHeight}px`;

    if (moreResultsBtn) {
      const moreResultsBtnRect = moreResultsBtn.getBoundingClientRect() as Rect;
      const padding = (containerHeight - moreResultsBtnRect.height) / 2;
      containerStyle.paddingTop = `${padding}px`;
      containerStyle.paddingBottom = containerStyle.paddingTop;
    }

    return containerStyle;
  }

  private listenToRefresh() {
    this.refreshObservable$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(_ => this.reloadCriticalActivity());
  }
}
