<div class="main-visualization-container row" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" >
  <div class="notification-container">
  </div>

  <p class="sub-heading">{{ this.visualizationSettingsLabel }}</p>
  <p>{{ this.visualizationSettingsDescriptionLabel }}</p>

  <div class="visualization-section row">
    <div class="section-text">
      <p>{{ this.visualizeAsLabel }}</p>
    </div>
    <div [ngClass]="{'validation-error': this.displayErrors && visualizeAsConfig.valueComponentState === undefined}" class="visualize-as-dropdown">
      <qod-clause-value-editor-optional-values
        [clauseKey]="clauseKey"
        [clause]="visualizeAsConfig"
        (valueChange)="onVisualizeAsOptionChange($event)" >
      </qod-clause-value-editor-optional-values>
    </div>
  </div>

  <div class="row error-text" *ngIf="this.displayErrors && visualizeAsConfig.valueComponentState === undefined">
    <div class="col s6">
      <p>{{ this.visualizeAsErrorLabel }}</p>
    </div>
  </div>


  <div *ngIf="showChartOptions" class="chart-options-container">

    <p class="sub-heading">{{ this.chartSettingsLabel }}</p>
    <p class="options-text">{{ this.chartSettingsDescriptionLabel }}</p>

    <div class="row">
      <div class="col s6">
        <div class="section-text">
          <p>{{ this.chartTypeLabel }}</p>
        </div>
        <div [ngClass]="{'validation-error': this.displayErrors && chartTypeConfig.valueComponentState === undefined}" class="chart-type-dropdown">
          <qod-clause-value-editor-optional-values
            [clauseKey]="clauseKey"
            [clause]="chartTypeConfig"
            (valueChange)="onChartTypeValueChange($event)" >
          </qod-clause-value-editor-optional-values>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s6">
        <div *ngIf="showGroupByOptions">
          <div class="section-text">
            <p>{{ this.groupByLabel }}</p>
          </div>
          <div [ngClass]="{'validation-error': (this.displayErrors || this.groupByDeleted) && groupByConfig.valueComponentState === undefined}" class="group-by-dropdown">
            <qod-clause-value-editor-optional-values
              [clauseKey]="clauseKey"
              [clause]="groupByConfig"
              (valueChange)="onGroupByChange($event)" >
            </qod-clause-value-editor-optional-values>
          </div>
        </div>
      </div>
    </div>

    <div class="row error-text" *ngIf="this.displayErrors || this.groupByDeleted">
      <div class="col s6">
        <div>
          <p *ngIf="chartTypeConfig.valueComponentState === undefined">{{ this.chartTypeErrorLabel }}</p>
        </div>
      </div>
      <div class="col s6">
        <p *ngIf="groupByConfig.valueComponentState === undefined && showGroupByOptions">{{ this.groupByErrorLabel }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col s6" *ngIf="showSummarizeByOptions">
        <div class="section-text">
          <p>{{ this.summarizeByLabel }}</p>
        </div>
        <div [ngClass]="{'validation-error': this.displayErrors && summarizeByConfig.valueComponentState === undefined}" class="summarize-by-dropdown">
          <qod-clause-value-editor-optional-values
            [clauseKey]="clauseKey"
            [clause]="summarizeByConfig"
            (valueChange)="onSummarizeByChange($event)">
          </qod-clause-value-editor-optional-values>
        </div>
      </div>
    </div>

    <div class="row error-text" *ngIf="this.displayErrors && showSummarizeByOptions">
      <div class="col s6">
        <p *ngIf="summarizeByConfig.valueComponentState === undefined">{{ this.summarizeByErrorLabel }}</p>
      </div>
    </div>

  </div>


  <div class="chart-preview-container">

    <div class="row">
      <div class="col s12">
        <div class="section-text">
          <p>{{ this.chartPreviewLabel }}</p>
        </div>
        <p>{{ this.chartPreviewDescriptionLabel }}</p>
      </div>
    </div>

    <div class="row">
      <div *ngIf="showHbarChartPreview" >
        <div class="col s10 habr-preview">
          <qod-attention-image-with-text
            [imageUrl]="hbarChartPreviewPath"
          ></qod-attention-image-with-text>
        </div>
      </div>
      <div *ngIf="showDonutChartPreview" >
        <div class="col s6 offset-s3">
          <qod-attention-image-with-text
            [imageUrl]="pieChartPreviewPath"
          ></qod-attention-image-with-text>
        </div>
      </div>
      <div *ngIf="showTimeseriesChartPreview" >
        <div class="col s11 offset-chart">
          <qod-attention-image-with-text
            [imageUrl]="timeseriesChartPreviewPath"
          ></qod-attention-image-with-text>
        </div>
      </div>
      <div *ngIf="showGenericPreview" >
        <div class="col s6 offset-s3">
          <qod-attention-image-with-text
            [imageUrl]="genericPreviewPath"
          ></qod-attention-image-with-text>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="showGridPreview" >
        <div class="col s11 offset-chart">
          <qod-attention-image-with-text
            [imageUrl]="gridPreviewPath"
          ></qod-attention-image-with-text>
        </div>
      </div>
    </div>

  </div>

</div>
