/**
 * Service for getting data for a specific event
 */
import { switchMap, take, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { ServiceDiscoveryService } from './service-discovery.service';
import { QueryBody } from '../models/query-body';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  SingleEventQueryRequestBody
} from '../models/single-event-query.model';

@Injectable()
export class EventService {
  public defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = {
    withCredentials: false
  };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {}

  getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  /**
   * Get event data
   *
   * @summary Get event data
   */
  public getEvent(
    eventId: string,
    requestBody?: SingleEventQueryRequestBody,
    extraHttpRequestParams?: any
  ): Observable<any> {
    return this.getEventWithHttpInfo(
      eventId,
      requestBody,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status !== 200) {
        return undefined;
      } else {
        return response.body || {};
      }
    }));
  }

  /**
   * Get event data
   */
  public getEventWithHttpInfo(
    eventId: string,
    requestBody?: SingleEventQueryRequestBody,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService
      .getEventUrl$().pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/${eventId}`;
        let headers = this.defaultHeaders;

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: {},
          observe: 'response'
        };

        if (requestBody) {
          requestOptions.body = requestBody;
        }

        if (extraHttpRequestParams) {
          requestOptions = Object.assign(
            requestOptions,
            extraHttpRequestParams
          );
        }

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions).pipe(
            timeout(defaultHttpRequestTimeout)
          );
      }));
  }
}
