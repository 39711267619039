<save-as-modal
  #saveAsModal
  [query]="currentQuery"
  [categories]="categories"
  [formData]="saveAsFormData"
  (save)="handleSaveAs($event)"
></save-as-modal>

<save-modal
  #saveModal
  [query]="currentQuery"
  [categories]="categories"
  [formData]="saveFormData"
  (save)="handleSavedQuerySuccess($event)"
></save-modal>

<div #container class="outer-container" tabindex="-1">
  <internal-loading
    [information]="coreStrings.loadingPleaseWait"
    [isLoading]="loadingQuery"
  ></internal-loading>
  <div class="visualization-internal-loading">
    <internal-loading
      information="{{ 'auditing.pages.criticalActivity.flyout.loadingVisualizationMessage' | localize }}"
      [isLoading]="!visualizationLoaded && !loadingQuery"
    ></internal-loading>
  </div>
  <error-message *ngIf="loadingQueryError">
    {{ loadQueryFailedErrorMessage }}
    <a [routerLink]="['/auditing/auditing/queries/saved']">{{
      applicationStrings.queryBuilderQueryLoadError2
    }}</a>
  </error-message>

  <section #main class="top-panel" *ngIf="!loadingQuery && !loadingQueryError">
    <div *ngIf="currentQuery">
      <div class="">
        <div class="header-section row" *ngIf="!isExporting">
          <div class="col s12 l7 search-info-container">
            <div class="search-name-container">
              <h3>{{ currentQuery.name }}</h3>
            </div>
            <div class="search-description-container">
              {{ currentQuery.description }}
            </div>
          </div>

          <div class="controls col s12 l5">
            <div class="right">
              <ng-container *ngIf="userCanManageThisSearchAlert()">
                <alert-button
                  class="left"
                  *ngIf="currentQuery"
                  [query]="currentQuery"
                ></alert-button>
              </ng-container>

              <!-- Button for system-defined queries, only allowing "Save As" -->
              <button
                *ngIf="
                  currentQuery?.isSystemDefined && userHasSearchSaveAsPermission
                "
                type="button"
                class="waves-effect waves-light btn js-save-as"
                id="save-as-button"
                (click)="saveAs()"
              >
                <edc-icon class="save-btn" name="save"></edc-icon
                >{{ applicationStrings.saveAsButtonLabel }}
              </button>

              <!-- Button for user-created queries, with "Save" and "Save As" -->
              <button
                *ngIf="
                  !currentQuery?.isSystemDefined &&
                  currentQuery?.id &&
                  userHasSearchSaveAsPermission &&
                  !userHasManageSearchPermissions
                "
                type="button"
                class="waves-effect waves-light btn js-save-as"
                id="save-as-button"
                (click)="saveAs()"
              >
                <edc-icon class="save-btn" name="save"></edc-icon
                >{{ applicationStrings.saveAsButtonLabel }}
              </button>
              <dropdown-button
                *ngIf="
                  !currentQuery?.isSystemDefined &&
                  currentQuery?.id &&
                  userHasManageSearchPermissions
                "
                class="save-button-menu"
                [primaryButtonOptions]="primarySaveButtonOptions"
                [menuOptions]="saveMenuOptions"
                (action)="handleAction($event)"
              ></dropdown-button>
              <button
                *ngIf="
                  !currentQuery?.isSystemDefined &&
                  !currentQuery?.id &&
                  userHasManageSearchPermissions
                "
                type="button"
                class="waves-effect waves-light btn js-save"
                id="save-button"
                (click)="showSavePrompt()"
              >
                <edc-icon class="save-btn" name="save"></edc-icon
                >{{ 'auditing.saveButtonLabel' | localize }}
              </button>
              <a
                class="waves-effect waves-light btn edit-query-button"
                (click)="editQuery()"
                *ngIf="userHasManageSearchPermissions"
                ><edc-icon name="pencil"></edc-icon
                ><span class="label">{{
                  applicationStrings.editQueryLabel
                }}</span></a
              >
              <a
                class="waves-effect waves-light btn refresh-results-button"
                (click)="refresh()"
                ><edc-icon name="refresh"></edc-icon
                ><span class="label">{{
                  applicationStrings.refreshQueryLabel
                }}</span></a
              >
            </div>
          </div>
        </div>
        <div class="col s12 l5 last-saved-query-section" *ngIf="!isExporting">
          <last-saved-info-field
            [showLastSavedInfo]="shouldShowLastSavedInfo()"
            [lastSavedLabelInfo]="lastSavedLabelInfo"
            [lastSavedToolTipRefreshProp]="lastSavedToolTipRefreshProp"
            [lastSavedToolTipInfo]="lastSavedToolTipInfo"
          ></last-saved-info-field>
        </div>
        <error-message *ngIf="saveError">{{ saveError }}</error-message>

        <column-editor-flyout
          *ngIf="!loadingQuery && currentQuery && !isExporting"
          [columns]="currentQuery.q.columns"
          [fields]="eventFields"
          [visualizeParams]="visualizeParams"
          (columnChange)="onColumnChange($event)"
        ></column-editor-flyout>

        <div class="results-main col s12">

          <error-message *ngIf="!loadingResults && queryFailedErrorMessage">{{
            queryFailedErrorMessage
          }}</error-message>

          <div class="row edit-cols-container">
            <div
              class="btn-flat edit-columns-button"
              *ngIf="!isExporting"
              (click)="openEditColumnsFlyout()"
            >
              <edc-icon name="cog"></edc-icon>
              {{ applicationStrings.pages.newSearches.editColumnsButton }}
            </div>
            <!-- Button Export, with option "CSV" and "CSV as ZIP"-->
            <create-export
              [results]="results"
              [currentQuery]="currentQuery"
              [adHocMode]="adHocMode"
              (exportStarted)="onExportStarted($event)"
            ></create-export>
          </div>
          <div *ngIf="userSearchVisualizationPermission && !hideCharts" class="row chartContainer">
            <donut-chart
              [query]="currentQuery"
              (chartAreaClick)="onChartCategoryClicked($event)"
              (renderCompleted)="onVisualizationLoaded($event===false)"
              *ngIf="enableDonutChart"
            ></donut-chart>
            <timeseries-chart
              [query]="currentQuery"
              (visualizationLoaded)="onVisualizationLoaded($event)"
              (chartElementClicked)="onChartElementClicked($event)"
              *ngIf="enableTimeSeriesChart"
            ></timeseries-chart>
            <hbar-chart
              [inputQuery]="currentQuery"
              (loading)="onVisualizationLoaded($event===false)"
              (barClicked)="onChartCategoryClicked($event)"
              *ngIf="enableHBarChart"
            ></hbar-chart>
          </div>
          <div #container class="outer-container"></div>
          <section #main class="top-panel" *ngIf="!isExporting">
            <div *ngIf="!hideEvents" class="results-main col s12">
              <results-viewer
                [results]="results"
                [loading]="loadingResults"
                (pageChange)="onPageChange($event)"
                (seek)="onSeek($event)"
                (reorder)="onReorder($event)"
                (sort)="onSort($event)"
                (filter)="onFilter($event)"
              ></results-viewer>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</div>

<modal-window [params]="visualizationLimitModalParams">
  <div modal-content>
    <strong>{{ rowLimitDescription }}</strong>
  </div>
</modal-window>
