<div class="autocomplete-value">
  <div class="input-wrapper">
    <input
      type="text"
      [ngClass]="{'validation-error': isValid === false || fieldValid === false}"
      name="value-{{clauseKey}}"
      [(ngModel)]="value"
      id="value-{{clauseKey}}"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onInputChanged()"
      autocomplete="off"
      [placeholder]="placeholder" required>
    <ul #dropdownList
        class="dropdown-content"
        *ngIf="showDropDown">
      <ng-container *ngIf="optionalValues && optionalValues.length > 0">
        <li #dropdownItem *ngFor="let item of optionalValues; let i = index"
            [ngClass]="{ 'active': focusedItem === i }">
          <a (click)="onValueSelected(item)" [title]="item" [innerText]="item"></a>
        </li>
      </ng-container>
      <li
        class="no-data"
        *ngIf="loadingError !== true && isLoading !== true && (!optionalValues || optionalValues.length === 0)">
        {{this.displayStrings.sharedQueryFilter.dynamic_values_no_data}}
      </li>
      <li class="more-data" *ngIf="optionalValues && optionalValues.length >= maxOptionNumber">
        {{this.displayStrings.sharedQueryFilter.dynamic_values_more_data}}
      </li>
      <li class="loading-error" *ngIf="isLoading !== true && loadingError === true">
        {{this.displayStrings.sharedQueryFilter.dynamic_values_loading_error}}
      </li>
    </ul>
  </div>
  <div
    *ngIf="isLoading"
    class="small-loader">
    <div class="preloader-wrapper small active">
      <div class="spinner-layer quest-orange">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>
