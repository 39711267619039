<internal-loading
  [information]="labels.loadingSearchList"
  [isLoading]="loading"
></internal-loading>

<div class="outer-container">
  <div class="error-row" *ngIf="error">
    <error-message>
      {{ labels.getSearchesError }}
    </error-message>
  </div>

  <ng-container *ngIf="categories">
    <div class="row page-header">
      <div class="col s6">
        <ca-page-header>{{ labels.pageHeader }}</ca-page-header>
      </div>
      <div class="col s6">
        <a
          class="waves-effect waves-light btn new-search-btn right"
          (click)="onNewQueryClick()"
          *odaIfHasPermissions="[
            fromPermissions.canManagePrivateSearch,
            fromPermissions.canManageSharedSearches
          ]"
          ><edc-icon name="plus"></edc-icon> {{ labels.newSearch }}</a
        >
      </div>
    </div>

    <div class="row saved-queries-container">
      <div class="query-categories-container">
        <query-categories
          class=""
          [categories]="categories"
          (categorySelect)="onCategorySelect($event)"
          (categoryAdd)="onCategoryAdd($event)"
          (categoryEdit)="onCategoryEdit()"
        ></query-categories>
      </div>

      <div class="row searches-list">
        <div class="row no-searches-message" *ngIf="searches.length === 0">
          <span>{{ labels.noSearches }}</span>
        </div>
        <error-message *ngIf="errorDeletingSearch">{{
          labels.deleteSearchError
        }}</error-message>
        <div class="searches-list__body">
          <div *ngIf="searches.length > 0" class="">
            <table class="striped saved-search-table">
              <thead>
                <tr>
                  <th class="search-type-header"></th>
                  <th class="search-name">{{ labels.searchTableName }}</th>
                  <th class="search-category-name">
                    {{ labels.categoryNameColumnHeader }}
                  </th>
                  <th class="search-actions"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let search of searches">
                  <tr
                    class="search-row"
                    [ngClass]="{
                      'system-search': search.isSystemDefined,
                      'user-search': !search.isSystemDefined
                    }"
                    [attr.data-search-id]="search.id"
                    (mouseover)="setHoveredSearch(search.id)"
                  >
                    <td class="search-type-cell">
                      <ng-container
                        *odaIfHasPermissions="[
                          fromPermissions.canRunPrivateSearch,
                          fromPermissions.canManagePrivateSearch,
                          fromPermissions.canViewSharedSearches
                        ]"
                      >
                        <span
                          class="search-type-icon-container"
                          title="{{ labels.privateSearch }}"
                          *ngIf="!search.isShared"
                          ><edc-icon
                            name="user"
                          ></edc-icon
                        ></span>
                        <span
                        class="search-type-icon-container"
                        title="{{ labels.sharedSearch }}"
                        ><edc-icon
                          name="users"
                          *ngIf="search.isShared"
                        ></edc-icon
                      ></span>
                      </ng-container>                     
                      <span
                        class="alert-icon-container"
                        title="{{ 'auditing.alertIconTitle' | localize }}"
                        ><edc-icon
                          name="bell"
                          *ngIf="alertEnabledSearchIds.includes(search.id)"
                        ></edc-icon
                      ></span>
                    </td>

                    <td class="search-name">
                      <div class="search-name-inner" title="{{ search.name }}">
                        <a
                          *ngIf="
                            canUserRunSearch(search);
                            else searchNameCannotRun
                          "
                          [routerLink]="[
                            '/auditing/auditing/queries/results',
                            search.id
                          ]"
                          >{{ search.name }}</a
                        >
                        <ng-template #searchNameCannotRun>
                          <span>{{ search.name }}</span>
                        </ng-template>
                      </div>
                    </td>

                    <td class="search-category-name">
                      <div
                        class="category-name-inner"
                        title="{{ search.categoryName }}"
                      >
                        {{ search.categoryName }}
                      </div>
                    </td>
                    <td class="search-actions">
                      <ng-container *ngIf="hoveredSearchId === search.id">
                        <div class="right">
                          <a
                            [routerLink]="[
                              '/auditing/auditing/queries/editor',
                              search.id
                            ]"
                            class="tooltipped modify"
                            id="modify-{{ search.id }}"
                            data-position="top"
                            [attr.data-tooltip]="labels.modifyQuery"
                            materialize="tooltip"
                            *ngIf="canUserManageSearch(search)"
                          >
                            <edc-icon name="pencil"></edc-icon>
                          </a>

                          <a
                            class="tooltipped copy-query-button"
                            id="copy-{{ search.id }}"
                            data-position="top"
                            [attr.data-tooltip]="labels.copyQuery"
                            materialize="tooltip"
                            (click)="handleCopyClick(search)"
                            *ngIf="canUserCopySearch(search)"
                          >
                            <edc-icon name="copy"></edc-icon>
                          </a>

                          <ng-container *ngIf="canUserManageSearch(search)">
                            <a
                              *ngIf="!search.isSystemDefined"
                              class="tooltipped delete"
                              id="delete-{{ search.id }}"
                              data-position="top"
                              [attr.data-tooltip]="labels.deleteButton"
                              materialize="tooltip"
                              (click)="handleDeleteClick(search)"
                            >
                              <edc-icon name="cross"></edc-icon>
                            </a>
                          </ng-container>

                          <a
                            [routerLink]="[
                              '/auditing/auditing/queries/results',
                              search.id
                            ]"
                            id="run-{{ search.id }}"
                            class="tooltipped run-search-button"
                            data-position="top"
                            [attr.data-tooltip]="labels.runSearch"
                            materialize="tooltip"
                            *ngIf="canUserRunSearch(search)"
                          >
                            <edc-icon name="task-run"></edc-icon>
                          </a>
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- This element is only for testing feature flags. We will remove this
           when we have a real example of feature flag use -->
    <div id="feature-flags-test new" *ifAuditFlag="'auditing.testUi'">
      {{ auditingStrings.auditing.testUi }}
    </div>
  </ng-container>

  <copy-query-modal
    [categories]="filteredCategories"
    (save)="handleSearchCopied($event)"
  ></copy-query-modal>

  <modal-window [params]="visualizationLimitModalParams">
    <div modal-content>
      <strong>{{ rowLimitDescription }}</strong>
    </div>
  </modal-window>
</div>
