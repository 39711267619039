import {
  DataType,
  IFixedValue,
  IPropertyConfig,
  QueryClause,
  ValueComponentType
} from '@ondemand/core';
import {
  OdaChartType,
  RenderType,
  IntervalCategory
} from '../../../../../../../shared/components/chart/models/visualization-constants';
import { StringOperators } from '../../../../../../../shared/models/query-clause-operator-groups';
import { EventField } from '../../../../../../models/event-field.model';

export function getVisualizeAsConfig(): QueryClause {
  const config: IPropertyConfig = {
    fieldConfig: {
      name: '',
      displayName: '',
      type: DataType.String
    },
    operatorsConfig: {
      customizedOperators: StringOperators
    },
    valueConfig: {
      valueComponentType: ValueComponentType.FixedOptionalValues,
      optionalValuesConfig: {
        fixedValues: [
          {
            displayName: RenderType.ChartAndGrid,
            value: RenderType.ChartAndGrid
          },
          {
            displayName: RenderType.Grid,
            value: RenderType.Grid
          },
          {
            displayName: RenderType.Chart,
            value: RenderType.Chart
          }
        ]
      },
      limitConfig: {
        required: true,
        maxlength: 64
      }
    }
  };

  return new QueryClause(config);
}

export function getSummarizeByConfig(): QueryClause {
  const config: IPropertyConfig = {
    fieldConfig: {
      name: '',
      displayName: '',
      type: DataType.String
    },
    operatorsConfig: {
      customizedOperators: StringOperators
    },
    valueConfig: {
      valueComponentType: ValueComponentType.FixedOptionalValues,
      optionalValuesConfig: {
        fixedValues: [
          {
            displayName: IntervalCategory.Hour,
            value: IntervalCategory.Hour
          },
          {
            displayName: IntervalCategory.Day,
            value: IntervalCategory.Day
          },
          {
            displayName: IntervalCategory.Week,
            value: IntervalCategory.Week
          },
          {
            displayName: IntervalCategory.Month,
            value: IntervalCategory.Month
          },
          {
            displayName: IntervalCategory.Year,
            value: IntervalCategory.Year
          }
        ]
      },
      limitConfig: {
        required: true,
        maxlength: 64
      }
    }
  };

  return new QueryClause(config);
}

export function getNonDatetimeGroupByConfig(fields: EventField[]): QueryClause {
  // generate non-datetime field config
  const nonDateTimeFixedValues: IFixedValue[] = fields
    .filter( field => field.dataType !== 'datetime')
    .map(field => ({ displayName: field.displayName, value: field.displayName }));

  const nonDateTimeFieldsConfig: IPropertyConfig = {
    fieldConfig: {
      name: '',
      displayName: '',
      type: DataType.String
    },
    operatorsConfig: {
      customizedOperators: StringOperators
    },
    valueConfig: {
      valueComponentType: ValueComponentType.FixedOptionalValues,
      optionalValuesConfig: { fixedValues: nonDateTimeFixedValues },
      limitConfig: { required: true, maxlength: 64 }
    }
  };

  return new QueryClause(nonDateTimeFieldsConfig);
}

export function getChartTypeConfig(): QueryClause {
  const config: IPropertyConfig = {
    fieldConfig: {
      name: '',
      displayName: '',
      type: DataType.String
    },
    operatorsConfig: {
      customizedOperators: StringOperators
    },
    valueConfig: {
      valueComponentType: ValueComponentType.FixedOptionalValues,
      optionalValuesConfig: {
        fixedValues: [
          {
            displayName: OdaChartType.TimeSeries,
            value: OdaChartType.TimeSeries
          },
          {
            displayName: OdaChartType.Donut,
            value: OdaChartType.Donut
          },
          {
            displayName: OdaChartType.H_BAR,
            value: OdaChartType.H_BAR
          }
        ]
      },
      limitConfig: {
        required: true,
        maxlength: 64
      }
    }
  };

  return new QueryClause(config);
}
