import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { BaseChartType, BaseComponent } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { Query } from '../../../../auditing/models/query.model';
import { ChartQueryService } from '../services/chart-query.service';
import { Results } from '../../../../auditing/components/queries/results/results.model';
import { catchError, take } from 'rxjs/operators';
import { ChartColors, isTimeseriesChartQuery } from '../utils/chart-utils';
import { ChartDataSets } from 'chart.js';
import { cloneAndUnlockObject } from '../../../utils/object.tools';
import { CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH } from '../../../critical-activity/configuration/critical-activity.config';
import { InvokeWithErrorHandling } from '../../../utils/error.handling.wrapper';
import { throwError } from 'rxjs';
import { TimeseriesChartDataExtractorService } from './service/timeseries-chart-data-extractor.service';
import { TimeseriesChartBuilderService } from './service/timeseries-chart-builder.service';
import { substituteTemplateValues } from '../../../../auditing/util/template-substituter';
import {
  formatLegendItemLabel,
  LegendDataItem,
  LegendEventArgs
} from '../oda-donut-chart/legend/legend-model';
import { VisualizeParameters } from '../../../../auditing/components/queries/editor/column-editor-flyout/models/editor-parameters';
import { TimeseriesChartTooltipService } from './service/timeseries-chart-tooltip.service';
import { AvailableValuesService } from '../../../../auditing/services/available-values/available-values.service';

@Component({
  selector: 'timeseries-chart',
  templateUrl: './timeseries.component.html',
  styleUrls: ['./timeseries.component.scss']
})
export class TimeseriesComponent extends BaseComponent implements OnChanges {
  results: Results;
  @Input() query: Query;
  @Input() visualizationOptions: VisualizeParameters;
  @Output() visualizationLoaded: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() errorLoadingChart: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() chartElementClicked: EventEmitter<Query> =
    new EventEmitter<Query>();

  noEvents: boolean;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  noResultsImagePath = CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH;
  chartLabels: string[];
  options: any;
  datasets: ChartDataSets[];
  originalDataSets: ChartDataSets[];
  timeSeriesInitialized = false;
  chartColors: string[] = ChartColors;
  pointsExceededLabel = '';
  displayTimeseriesWarning = false;
  chartLegendItems: LegendDataItem[];
  deletedLegendItems: string[] = [];
  availableValues: any;

  readonly chartType = BaseChartType.Line;
  readonly containerStyle = {
    width: '100%'
  };
  readonly chartPosition = { width: '100%', height: '35rem' };

  constructor(
    private chartService: ChartQueryService,
    private chartDataExtractorService: TimeseriesChartDataExtractorService,
    private chartBuilderService: TimeseriesChartBuilderService,
    private tooltipService: TimeseriesChartTooltipService,
    private availableValuesService: AvailableValuesService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      this.visualizationLoaded.emit(false);
      InvokeWithErrorHandling(
        () => {
          this.runChartQuery();
        },
        err => {
          this.handleError(err);
        }
      );
    }
  }

  runChartQuery() {
    if (isTimeseriesChartQuery(this.query, 0)) {
      let cloneQuery = cloneAndUnlockObject<Query>(this.query);
      this.chartService
        .runAdhocChartQuery(cloneQuery, cloneQuery.q.charts[0].timeSeries.id)
        .pipe(
          take(1),
          catchError(err => throwError(err))
        )
        .subscribe(
          response =>
            InvokeWithErrorHandling(
              () => {
                this.renderTimeseriesChart(response.body);
              },
              err => {
                this.handleError(err);
              }
            ),
          err => {
            this.handleError(err);
          }
        );
    }
  }

  renderTimeseriesChart(results: Results) {
    this.noEvents = results.totalRows < 1;
    const chartData =
      this.chartDataExtractorService.getTimeseriesRowData(results);

    // This variable acts as a sort of mutex for displaying the chart. We need the information
    // initialized before allowing the ngif condition to be true, otherwise it'll crash.
    this.timeSeriesInitialized = false;
    if (!this.timeSeriesInitialized && !this.noEvents) {
      const extendableQuery = cloneAndUnlockObject<Query>(this.query);
      this.datasets = this.chartBuilderService.generateChartDataSets(chartData);
      this.originalDataSets = this.datasets;
      this.chartLabels = this.chartDataExtractorService.getTimestamps(results);
      this.options = this.tooltipService.generateChartOptions();
      this.options.onClick = this.thisAsThat(this.handleOnClickMouseEvent);
      this.chartLegendItems = this.datasets.map(
        (dataset: ChartDataSets, index: number) => ({
          label: formatLegendItemLabel(dataset.label),
          value: dataset.label,
          tooltip: dataset.label,
          borderColor: dataset.backgroundColor.toString(),
          backColor: dataset.backgroundColor.toString(),
          order: index,
          checked: true,
          disable: false
        })
      );

      if (results.limit < results.totalRows) {
        this.setPointsExceededTemplateLabel(results);
        this.displayTimeseriesWarning = true;
      }

      this.tooltipService.setCallbackVariables(
        extendableQuery,
        this.chartLabels
      );
      this.timeSeriesInitialized = true;
    }

    setTimeout(() => {
      // Some predefined values such as booleans require us to get a list of available values for proper column translation.
      this.availableValuesService
        .getAvailableValues(
          this.query.q.charts[0].timeSeries.filterOptions.topXFilterColumn,
          null,
          0,
          1000
        )
        .subscribe(
          result => {
            this.availableValues = result;
          },
          error => {
            if (
              error.message.includes(
                'is not supported for typeahead or predefined values'
              )
            ) {
              this.availableValues = null;
            } else {
              this.handleError(error);
            }
          }
        );

      this.visualizationLoaded.emit(true);
    }, 50);
  }

  setPointsExceededTemplateLabel(results: Results) {
    let replacements: any = {
      limit: results.limit.toLocaleString(),
      totalRows: results.totalRows.toLocaleString()
    };
    this.pointsExceededLabel = substituteTemplateValues(
      this.applicationStrings.visualizationBinsLimitDescription,
      replacements
    );
  }

  // Event listener for legend component every time user checks/unchecks an item.
  legendItemSelectionChange(legendEvent: LegendEventArgs): void {
    // We don't allow the user to uncheck every item.
    const remainingChartLegendItems = this.chartLegendItems.filter(
      item => !this.deletedLegendItems.includes(item.label)
    );

    if (
      (this.deletedLegendItems.length === 10 ||
        remainingChartLegendItems.length === 1) &&
      !legendEvent.show
    ) {
      legendEvent.show = true;
      this.chartLegendItems[legendEvent.index].checked = true;
      this.chartLegendItems = Array.from(this.chartLegendItems);
    } else {
      const legendItemString: string =
        legendEvent.currentLegendItems[legendEvent.index].value;
      legendEvent.show
        ? this.addDatasetToChart(legendItemString)
        : this.removeDatasetFromChart(legendItemString);
    }
  }

  // We need to keep a list of legend items in the tooltip service for the click events, so we update during add/delete events.
  removeDatasetFromChart(item: string): void {
    this.datasets = this.datasets.filter(data => data.label !== item);
    this.deletedLegendItems.push(item);
  }

  addDatasetToChart(item: string): void {
    const itemToAdd = this.originalDataSets.find(data => data.label === item);
    this.datasets.push(itemToAdd);
    this.deletedLegendItems = this.deletedLegendItems.filter(
      legendItem => legendItem !== item
    );
  }

  handleError(error: any): void {
    this.visualizationLoaded.emit(true);
    this.errorLoadingChart.emit(error);
  }

  handleDismissWarningClick() {
    this.displayTimeseriesWarning = false;
  }

  // If user selects a valid point/x-axis, we modify the query and notify the results component to get new results.
  handleOnClickMouseEvent(
    chart: any,
    event: PointerEvent,
    chartElement: any[]
  ) {
    const clickResult = this.tooltipService.mouseClickEventHandler(
      chart,
      this.chartLegendItems,
      this.deletedLegendItems,
      event,
      this.query,
      chartElement,
      this.availableValues
    );

    if (clickResult) {
      this.query = clickResult;
      this.chartElementClicked.emit(clickResult);
    }
  }

  private thisAsThat(callBack: any) {
    const self = this;
    return function() {
      return callBack.apply(
        self,
        [this].concat(Array.prototype.slice.call(arguments))
      );
    };
  }
}
