export const UPDATE_FAVORITE_SEARCH =
  '[Auditing Dashboard] Update Favorite Search';
export const UPDATE_FAVORITE_SEARCH_SUCCESS =
  '[Auditing Dashboard] Update Favorite Search Success';
export const UPDATE_FAVORITE_SEARCH_FAIL =
  '[Auditing Dashboard] Update Favorite Search Fail';

export class UpdateFavoriteSearch {
  readonly type = UPDATE_FAVORITE_SEARCH;
  constructor(public payload: string[]) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateFavoriteSearchSuccess {
  readonly type = UPDATE_FAVORITE_SEARCH_SUCCESS;
}

// eslint-disable-next-line max-classes-per-file
export class UpdateFavoriteSearchFail {
  readonly type = UPDATE_FAVORITE_SEARCH_FAIL;
  constructor(public payload: any) {}
}

export type UpdateFavoriteSearchAction =
  | UpdateFavoriteSearch
  | UpdateFavoriteSearchSuccess
  | UpdateFavoriteSearchFail;
