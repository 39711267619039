import {
  getCriticalLevel,
  getCriticalLevelIconPath
} from '../../../../../shared/critical-activity/critical-level-translator';
import { CriticalActivityAlertType, CriticalActivityDataSet } from '../../../../../shared/models/critical-activity.shared.models';
import {
  CriticalActivityListDataRow,
  CriticalActivityListPriorityCell
} from '../models/critical-activity-list.models';

function createPriorityLevelCell(
  severityValue: number,
  alertType: CriticalActivityAlertType
): CriticalActivityListPriorityCell {
  const levelLabel: string = getCriticalLevel(severityValue);
  const icon: string = getCriticalLevelIconPath(severityValue, alertType);

  return {
    severity: severityValue,
    iconPath: icon,
    displayLabel: levelLabel
  } as CriticalActivityListPriorityCell;
}

export function transformCriticalActivityListData(
  sourceDatas: CriticalActivityDataSet[]
): CriticalActivityListDataRow[] {
  return sourceDatas.map(sourceData => {
    const severityValue: number = sourceData.priority;
    return {
      priorityLevel: createPriorityLevelCell(
        severityValue,
        sourceData.alertType
      ),
      description: sourceData.label,
      eventCount: sourceData.data,
      queryId: sourceData.queryId,
      query: sourceData.query
    };
  });
}
