/**
 * Service for managing timeseries charts' data
 */

import { Injectable } from '@angular/core';
import { Results } from '../../../../../auditing/components/queries/results/results.model';
import { TimeseriesData } from '../../models/anomaly-timeseries-models';

@Injectable()
export class AnomalyTimeseriesChartDataExtractorService {
  /**
   * Timeseries charts use 4 sets of data: Timestamps, total events, baseline events, and anomalies. This corresponds to the first 4
   * columns in each row. We return a dictionary of values used by the results component to pass into the chart creation function.
   */
  getTimeseriesRowData(chartQueryResults: Results): TimeseriesData {
    let labels = chartQueryResults.rows.map(row => row.cells[0].value);
    let total = chartQueryResults.rows.map(row => {
      let val = row.cells[1].value as number;
      return Math.round(val);
    });
    let baseline = chartQueryResults.rows.map(row => {
      let val = row.cells[2].value as number;
      return Math.round(val);
    });
    let anomalies = chartQueryResults.rows.map(row => row.cells[3].value);
    labels = labels.reverse();
    total = total.reverse();
    baseline = baseline.reverse();
    anomalies = anomalies.reverse();
    return {
      labels,
      baseline,
      total,
      anomalies
    };
  }
}
