import { Injectable } from '@angular/core';

import * as fromPermissions from '../models/audit-permissions.model';
import { AuditModulePermissionsService } from './audit-module-permissions.service';

export const SEARCH_PERMISSION: any = {
  NONE: 'None',
  PRIVATE: 'Private',
  SHARED: 'Shared',
  PRIVATE_AND_SHARED: 'PrivateAndShared'
};

@Injectable()
export class SearchPermissionsService {
  fromPermissions = fromPermissions;

  constructor(
    private permissionsService: AuditModulePermissionsService) {
  }

  getManageSearchPermission() {
    if (this.userCanManageBothSharedAndPrivateSearches()) {
      return SEARCH_PERMISSION.PRIVATE_AND_SHARED;
    }

    if (this.userCanManageOnlySharedSearches()) {
      return SEARCH_PERMISSION.SHARED;
    }

    if (this.userCanManagePrivateSearches()) {
      return SEARCH_PERMISSION.PRIVATE;
    }

    return SEARCH_PERMISSION.NONE;
  }

  getViewSearchPermission() {
    if (this.userCanViewBothSharedAndPrivateSearches()) {
      return SEARCH_PERMISSION.PRIVATE_AND_SHARED;
    }

    if (this.userCanViewOnlySharedSearches()) {
      return SEARCH_PERMISSION.SHARED;
    }

    if (this.userCanManagePrivateSearches()) {
      return SEARCH_PERMISSION.PRIVATE;
    }

    return SEARCH_PERMISSION.NONE;
  }

  private userCanManageOnlySharedSearches(): boolean {
    return (
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageSharedSearches
      ]) &&
      !this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManagePrivateSearch
      ])
    );
  }

  private userCanManagePrivateSearches(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManagePrivateSearch
    ]);
  }

  private userCanManageBothSharedAndPrivateSearches(): boolean {
    return (
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageSharedSearches
      ]) &&
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManagePrivateSearch
      ])
    );
  }

  private userCanViewOnlySharedSearches(): boolean {
    return (
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canViewSharedSearches
      ]) &&
      !this.userCanManagePrivateSearches()
    );
  }

  private userCanViewBothSharedAndPrivateSearches(): boolean {
    return (
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canViewSharedSearches
      ]) &&
      this.userCanManagePrivateSearches()
    );
  }
}
