import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Query } from '../../../../../models/query.model';
import { ActiveQueryService } from '../../../../../services/active-query.service';
import { getCanvasElement } from './dom-helper';

@Injectable()
export class TopActiveUsersMouseEventService {
  constructor(
    private activeQueryService: ActiveQueryService,
    private router: Router
  ) {}

  clickHandler(
    event: any,
    chartElement: any[],
    queries: Query[],
    userHasRunSearchPermissions: boolean
  ): any {
    if (userHasRunSearchPermissions && chartElement.length > 0) {
      const eventX = event.offsetX;
      const chartElemClicked = chartElement.find(elem => {
        const lowX = elem._view.base;
        const hiX = elem._view.x;
        return eventX >= lowX && eventX <= hiX;
      });
      if (!chartElemClicked) return;

      const rowIndex = chartElemClicked._index;

      const query = queries[rowIndex];
      if (query) {
        if (query.isShared === undefined) {
          query.isShared = false;
        }
        query.q.charts = [];
        query.hideCharts = true;
        const activeQuery = new Query(query);
        const resultsUrl = '/auditing/auditing/queries/results';
        this.activeQueryService.setQuery(activeQuery, activeQuery);
        this.router.navigate([resultsUrl]);
      }
    }
  }

  hoverHandler(chartElement: any[], userHasRunSearchPermissions: boolean): any {
    const chartCanvasTag = getCanvasElement();
    chartCanvasTag.style.cursor = 'default';

    if (userHasRunSearchPermissions && chartElement.length > 0) {
      chartCanvasTag.style.cursor = 'pointer';
    }
  }
}
