import { Results } from '../results.model';
import { Util } from '@ondemand/core';
import { Column } from '../results.column.model';
import { Cell, Highlight } from '../results.cell.model';

const defaultReturn = {
  datatableColumns: null as any,
  datatableRows: null as any
};

/**
 * Get user-facing formatted version of event cell value
 *
 * @param cell Original value from the service response
 * @param column Column metadata for the cell's column
 * @param isPrettierJson true, when json value will be in prettier format
 */
export const formatCellValue = (cell: Cell, column: Column, isPrettierJson: boolean) => {
  let formattedValue: string;

  if (column.semanticType === 'json') {
    if (isPrettierJson) {
      formattedValue = formatJSON(cell.label);
    } else {
      formattedValue = cell.label;
    }
  } else if (column.dataType === 'datetime') {
    if (cell.value) {
      let date = new Date(cell.value);
      formattedValue = Util.getFormattedDateTime(date);
    } else {
      formattedValue = '';
    }
  } else if (cell.highlights && cell.highlights.length > 0) {
    formattedValue = applyHighlightsAndEncodeHTMLEntities(cell.label, cell.highlights);
  } else {
    formattedValue = cell.label;
  }

  return formattedValue;
};

function replaceAll(target: string, search: string, replacement: string) {
  return target.replace(new RegExp(search, 'g'), replacement);
}

/**
 * Display json objects in prettified manner
 *
 * @param unformattedJSON Stringified json
 */
export function formatJSON(unformattedJSON: string) {
  // Check if the incoming json object is valid
  // If valid format string and enclose it in pre tag otherwise return json string
  try {
    const jsonObject = JSON.parse(unformattedJSON);
    const prettyJson = JSON.stringify(jsonObject, undefined, 1);
    return `<div class="json-pretty"><pre><code>${prettyJson}</code></pre></div>`;
  } catch (e) {
    return unformattedJSON;
  }
}

/**
 * Add highlight annotations and HTML-encode text
 *
 * @param text Original text
 * @param highlights List of highlighted sections of text
 */
export function applyHighlightsAndEncodeHTMLEntities(text: string, highlights: Highlight[]) {
  let outputText = text;
  // Append temporary annotations (which do not themselves contain HTML entity characters)
  const tempStartMarker = '@@@BEGIN-HIGHLIGHT@@@';
  const tempEndMarker = '@@@END-HIGHLIGHT@@@';
  const startMarker = '<mark>';
  const endMarker = '</mark>';
  const offset = (tempStartMarker + tempEndMarker).length;
  let offsetMultiplier = 0;

  highlights.forEach((highlight) => {
    let [start, end] = highlight;
    // Move cursor index ahead to account for space taken up
    // by "start" and "end" markers
    start += (offset * offsetMultiplier);
    end += (offset * offsetMultiplier);
    let preText = outputText.substring(0, start);
    // Skip if this highlight is out of range for the string length
    if (preText.length === text.length) {
      return;
    }
    let postText = outputText.substring(end);
    let highlightedText = outputText.substring(start, end);
    outputText = preText + tempStartMarker + highlightedText + tempEndMarker + postText;
    // Keep track of original position in string since we are adding to its length
    offsetMultiplier++;
  });

  // Replace temporary annotations with permanent HTML elements
  outputText = replaceAll(outputText, tempStartMarker, startMarker);
  outputText = replaceAll(outputText, tempEndMarker, endMarker);

  return outputText;
}

export const formatResultsForDataTable = (results: Results) => {
  if ((results === null || results === undefined)
      || (results.headers === null || results.headers === undefined)) {
    return defaultReturn;
  }

  const datatableColumns = results.headers.map((col) => ({
      name: col.label,
      prop: col.id
    }));

  if (results.rows === null || results.rows === undefined) {
    return defaultReturn;
  }

  const datatableRows = results.rows.map((row) => {
    let newRow: {[key: string]: string} = {
      eventId: row.id
    };
    row.cells.forEach((cell, i) => {
      let columnId = datatableColumns[i].prop;
      let columnMetadata = results.columnMetadata.find(col => columnId === col.id);
      let cellText = formatCellValue(cell, columnMetadata, false);
      newRow[columnId] = cellText;
    });
    return newRow;
  });

  return {
    datatableColumns,
    datatableRows
  };
};
