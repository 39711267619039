import { Component, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'last-saved-info-field',
  templateUrl: './last-saved-info-field.component.html'
})
export class LastSavedInfoFieldComponent extends BaseComponent implements OnDestroy {
  @Input() showLastSavedInfo: boolean;
  @Input() lastSavedLabelInfo: string;
  @Input() lastSavedToolTipRefreshProp: string[];
  @Input() lastSavedToolTipInfo: string;

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor() {
    super();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
