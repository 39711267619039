/*
 * widget auditHealth
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseComponent, IconState } from '@ondemand/core';
import { takeUntil, filter } from 'rxjs/operators';
import {
  AuditHealthWidgetResponse,
  AuditHealthAction,
  AuditHealthStatus,
  AuditHealthDataset,
  AuditHealthConfigurationResponse,
  AuditHealthDataResponse,
  AuditHealthRow
} from './models/audit-health.models';

import {
  LoadAuditHealth,
  UpdateAuditHealth
} from './state/audit-health.actions';
import { AuditHealthState } from './state/audit-health.reducer';
import {
  selectAuditHealthData,
  selectAuditHealthError,
  selectAuditHealthExpired,
  selectAuditHealthLoading,
  selectAuditHealthUpdating
} from './state/audit-health.selectors';
import { ActiveQueryService } from '../../../../services/active-query.service';
import { Query } from '../../../../models/query.model';
import { AuditingDashboardResponse } from '../../models/auditing-dashboard.models';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { cloneAndUnlockObject } from '../../../../../shared/utils/object.tools';
import { AuditHealthDismissedItem, UpdateAuditHealthSettings } from '../../../../../shared/models/update-widget-configuration';
import { AuditingFeatureFlagsService } from '../../../../services/auditing-feature-flags.service';
import { FeatureFlagType } from '../../../../../auditing/components/shared/feature-flag.enum';
import { AuditConstants } from '../../../../../shared/models/ui-constants';
const auditingBaseUrlSegments = ['auditing', 'auditing'];
const configUrlSegments = ['config'];

@Component({
  selector: 'audit-health-widget',
  templateUrl: './audit-health.component.html',
  styleUrls: ['./audit-health.component.scss']
})
export class AuditHealthComponent extends BaseComponent implements OnInit {
  @Input() refreshObservable$: Observable<void>;
  dataLoading$ = this.store.select(selectAuditHealthLoading);
  dataError$ = this.store.select(selectAuditHealthError);
  data$ = this.store.select(selectAuditHealthData);
  dataExpired$ = this.store.select(selectAuditHealthExpired);
  dataUpdating$ = this.store.select(selectAuditHealthUpdating);

  resultsPerPage = 4;
  resultsWithoutPagination = 5;
  currentPage = 0;
  dataloaded = false;
  totalDataLength = 0;
  hiddenItemsLength = 0;
  pages: AuditHealthRow[][] = [];
  currentPageCardData: AuditHealthRow[];

  GRANTCONSENT: string;
  VIEWSUBSCRIPTIONS: string;
  VIEWRESULTS: string;
  VIEWCONFIG: string;
  VIEWPLAN: string;
  LEARNMORE: string;
  HIDE: string;
  DISMISS: string;

  buttonOrder: string[];


  alertPlanEditorEnabled = false;
  alertPlanEditorShowPlanType = true;
  alertPlanEditorIsOpen = false;
  alertPlanEditorOwnerModule = AuditConstants.ModuleName;
  alertPlanEditorPlanId: string;
  alertPlanEditorPrevPage = 0;
  alertPlanEditorNotificationId = '';

  readonly cardHeight = '21rem';

  constructor(
    private store: Store<AuditHealthState>,
    private localeStringsService: LocaleStringsService,
    private router: Router,
    private activeQueryService: ActiveQueryService,
    private featureFlagService: AuditingFeatureFlagsService
  ) {
    super();
  }

  ngOnInit() {
    this.featureFlagService
      .getFlag(FeatureFlagType.EnableNotificationPlans)
      .pipe(takeUntil(this.destructionSubject))
      .subscribe( isEnabled => {
        this.alertPlanEditorEnabled = isEnabled;
      });

    this.localeStringsService.strings$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.GRANTCONSENT =
          labels.auditing.pages.auditingDashboard.auditHealth.grantConsent;
        this.VIEWSUBSCRIPTIONS =
          labels.auditing.pages.auditingDashboard.auditHealth.viewSubscriptions;
        this.VIEWRESULTS =
          labels.auditing.pages.auditingDashboard.auditHealth.viewResults;
        this.VIEWCONFIG =
          labels.auditing.pages.auditingDashboard.auditHealth.viewConfiguration;
        this.VIEWPLAN =
          labels.auditing.pages.auditingDashboard.auditHealth.viewPlan;
        this.LEARNMORE =
          labels.auditing.pages.auditingDashboard.auditHealth.learnMore;
        this.HIDE = labels.auditing.pages.auditingDashboard.auditHealth.hide;
        this.DISMISS =
          labels.auditing.pages.auditingDashboard.auditHealth.dismiss;
      });

    this.setButtonOrder();

    this.data$
      .pipe(
        takeUntil(this.destructionSubject),
        filter(response => !!response)
      )
      .subscribe(data => this.parsePages(data));

    this.dataExpired$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(expired => {
        if (expired) {
          this.loadAuditHealth();
        }
      });
    this.loadAuditHealth();
    this.listenToRefresh();
  }

  loadAuditHealth() {
    this.store.dispatch(new LoadAuditHealth());
    this.dataloaded = false;
  }

  getIconState(status: string): IconState {
    switch (status) {
      case 'Warning':
        return this.iconState.Warning;
      case 'Error':
        return this.iconState.Danger;
      case 'Informational':
        return this.iconState.Info;
      default:
        return this.iconState.Info;
    }
  }

  onPageChange(pageNum: number) {
    this.currentPage = pageNum;
    this.currentPageCardData = this.pages[this.currentPage];
  }

  sortButtonOrder(buttonLabels: string[]): string[] {
    let finalOrder: string[] = [];
    for (let button of this.buttonOrder) {
      if (buttonLabels.indexOf(button) !== -1) {
        finalOrder.push(button);
      }
    }
    return finalOrder;
  }

  deployAction(actionName: string, dataset: AuditHealthDataset) {
    switch (actionName) {
      case this.VIEWRESULTS:
        this.viewSearchResults(
          (dataset as any).actions.filter(
            (u: { actionName: string }) => u.actionName === this.VIEWRESULTS
          )[0].query
        );
        break;
      case this.GRANTCONSENT:
        this.viewGrantConsent(
          (dataset as any).actions.filter(
            (u: { actionName: string }) => u.actionName === this.GRANTCONSENT
          )[0].questTenantId
        );
        break;
      case this.DISMISS:
        this.dismiss(dataset.notificationId);
        break;
      case this.HIDE:
        this.hide(dataset.notificationId);
        break;
      case this.VIEWCONFIG:
        this.viewConfiguration();
        break;
      case this.VIEWSUBSCRIPTIONS:
        this.viewSubscriptions();
        break;
      case this.VIEWPLAN:
        this.viewPlan(dataset);
        break;
        case this.LEARNMORE:
          this.learnMore();
          break;
    }
  }
  dismiss(notificationId: string) {
    const dismissedItem: AuditHealthDismissedItem = {
      notificationId,
      dismissalTimestamp: new Date().toUTCString()
    };

    const updateSettings = {
      hiddenItems: null,
      resetHiddenItems: false,
      dismissedItems: [dismissedItem]
    } as UpdateAuditHealthSettings;

    this.store.dispatch(new UpdateAuditHealth(updateSettings));
  }

  viewSearchResults(datasetQuery: any) {
    const loadedQuery = new Query(datasetQuery);
    this.activeQueryService.setQuery(loadedQuery, loadedQuery);
    this.router.navigate(['/auditing/auditing/queries/results']);
  }

  viewGrantConsent(questTenantID: string) {
    this.router.navigate(['/tenantmanagement', questTenantID]);
  }

  viewSubscriptions() {
    this.router.navigate(['/settings/subscriptions']);
  }

  viewConfiguration() {
    this.router.navigate([...auditingBaseUrlSegments, ...configUrlSegments]);
  }

  viewPlan(dataset: AuditHealthDataset) {
    const alertPlanId = (dataset as any).actions.filter(
      (u: { actionName: string }) => u.actionName === this.VIEWPLAN
    )[0].data;

    if(this.alertPlanEditorEnabled) {
      this.alertPlanEditorNotificationId = dataset.notificationId;
      this.alertPlanEditorPlanId = alertPlanId;
      this.alertPlanEditorIsOpen = true;
    } else {
      this.router.navigate(['/auditing/auditing/alerts/plans', alertPlanId]);
    }
  }

  learnMore() {
    window.open('https://www.quest.com/products/attack-path-management-software/', '_blank');
  }

  hide(notificationId: string) {
    const updateSettings = {
      hiddenItems: [notificationId],
      resetHiddenItems: false,
      dismissedItems: null
    } as UpdateAuditHealthSettings;

    this.store.dispatch(new UpdateAuditHealth(updateSettings));
  }

  resetHiddenItems() {
    const updateSettings = {
      hiddenItems: null,
      resetHiddenItems: true,
      dismissedItems: null
    } as UpdateAuditHealthSettings;

    this.store.dispatch(new UpdateAuditHealth(updateSettings));
  }

  alertPlanEditorClosed() {
    this.alertPlanEditorIsOpen = false;
    this.alertPlanEditorPlanId = '';
    this.alertPlanEditorPrevPage = this.currentPage;
    this.loadAuditHealth();
  }

  private parsePages(dataFromService: AuditHealthWidgetResponse): void {
    const data = cloneAndUnlockObject<AuditHealthWidgetResponse>(dataFromService);
    const datasets = data.data.auditHealth.datasets;
    this.totalDataLength = datasets.length;

    if (data.configuration.auditHealth) {
      this.hiddenItemsLength =
        data.configuration.auditHealth.hiddenItems.length;
    }
    this.pages = [];
    for (
      let i = 0;
      i < Math.ceil(this.totalDataLength / this.resultsPerPage);
      i++
    ) {
      this.pages.push(
        datasets
          .slice(
            i * this.resultsPerPage,
            this.totalDataLength <= this.resultsWithoutPagination &&
              !(this.hiddenItemsLength > 0)
              ? this.resultsWithoutPagination
              : (i + 1) * this.resultsPerPage
          )
          .map(
            (dataset: AuditHealthDataset): AuditHealthRow => ({
              ...dataset,
              actionNames: this.sortButtonOrder(
                dataset.actions.map((it: AuditHealthAction) => it.actionName)
              ),
              triggerSpecificAction: (name: string) => {
                this.deployAction(name, dataset);
              }
            })
          )
      );
    }

    let pageNumber = this.findPageNumberForViewPlan();
    this.onPageChange(pageNumber);
    this.dataloaded = true;
  }

  private findPageNumberForViewPlan(): number {

    if(this.alertPlanEditorNotificationId) {

      this.alertPlanEditorNotificationId = '';

      const totalPages = Math.ceil(this.totalDataLength / this.resultsPerPage);

      for( let i = 0; i < totalPages; i++) {

        const rows = this.pages[i];
        const matchedRows = rows.filter(row => row.notificationId === this.alertPlanEditorNotificationId);

        if(matchedRows.length > 0) {
          return i;
        }
      }

      if(this.alertPlanEditorPrevPage < totalPages) {
        return this.alertPlanEditorPrevPage;
      }

    }

    return 0;
  }

  private listenToRefresh() {
    this.refreshObservable$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(() => this.loadAuditHealth());
  }

  private setButtonOrder() {
    this.buttonOrder = [
      this.GRANTCONSENT,
      this.VIEWSUBSCRIPTIONS,
      this.VIEWRESULTS,
      this.VIEWCONFIG,
      this.VIEWPLAN,
      this.LEARNMORE,
      this.HIDE,
      this.DISMISS
    ];
  }
}
