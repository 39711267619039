/**
 * Service for managing alerts
 */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take, timeout } from 'rxjs/operators';
import { OnDemandTokenHttp, HttpRequestMethod } from '@ondemand/core';
import { ServiceDiscoveryService } from './service-discovery.service';
import { AlertPlan } from '../models/alert-plan.model';
import { AlertRule } from '../models/alert-rule.model';
import { UpdateAlertRule } from '../models/update-alert-rule.model';
import { defaultHttpRequestTimeout } from '../util/constants';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

@Injectable()
export class AlertService {
  protected basePath$: Observable<string>;
  public defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = {
    withCredentials: false
  };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = this.serviceDiscoveryService.getAlertsUrl$();
  }

  getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  getAlertRules(
    queryId?: string,
    alertPlanId?: string
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules`;
        let headers = new HttpHeaders();

        let queryParams = new HttpParams();
        // Look up by query ID if requested
        if (queryId) {
          queryParams = queryParams.set('queryId', queryId);
        }

        if (alertPlanId) {
          queryParams = queryParams.set('alertPlanId', alertPlanId);
        }

        let requestOptions = {
          headers,
          params: queryParams,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Get a single alert rule
   */
  getAlertRule(alertRuleId: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRuleId}`;
        let headers = this.defaultHeaders;

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Add a new alert rule
   */
  createAlertRule(ruleParams: AlertRuleInput): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules`;
        let headers = this.defaultHeaders;

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: ruleParams,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  updateAlertRule(alertRule: AlertRule): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRule.id}`;
        let headers = this.defaultHeaders;

        const updateAlertRule: UpdateAlertRule = {
          name: alertRule.name,
          description: alertRule.description,
          runFrequency: alertRule.runFrequency,
          countThreshold: alertRule.countThreshold,
          enabled: alertRule.enabled,
          ownerModule: alertRule.ownerModule,
          dataTags: alertRule.dataTags
        };

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: updateAlertRule,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Delete an new alert rule
   */
  deleteAlertRule(alertRuleId: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRuleId}`;
        let headers = this.defaultHeaders;

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Get alert plans
   *
   */
  getAlertPlans(alertRuleId?: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        let path;
        if (alertRuleId) {
          path = `${basePath}/alertRules/${alertRuleId}/plans`;
        } else {
          path = `${basePath}/alertPlans`;
        }

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Get alert rules for a plan
   *
   */
  getAlertRulesForPlan(alertPlanId?: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        let path = `${basePath}/alertPlans/${alertPlanId}/rules`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Get an alert plan by ID
   *
   */
  getAlertPlan(alertPlanId: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertPlans/${alertPlanId}`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };
        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Create new alert plan
   *
   */
  createAlertPlan(alertPlan: AlertPlan): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertPlans`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          body: alertPlan,
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };
        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  updateAlertPlan(alertPlan: AlertPlan): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertPlans/${alertPlan.id}`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          body: alertPlan,
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Delete alert plan
   *
   */
  deleteAlertPlan(alertPlanId: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertPlans/${alertPlanId}`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Add an alert plan to an alert rule
   *
   */
  addAlertPlanToAlertRule(
    alertPlanId: string,
    alertRuleId: string
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRuleId}/plans/${alertPlanId}`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  setAlertPlansForAlertRule(
    alertPlanIds: string[],
    alertRuleId: string
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRuleId}/plans`;
        let body = alertPlanIds;
        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Remove alert plan from alert rule
   *
   */
  removeAlertPlanFromAlertRule(
    alertPlanId: string,
    alertRuleId: string
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertRules/${alertRuleId}/plans/${alertPlanId}`;

        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  /**
   * Run test of alert plan, sending test messages
   *
   * @param alertPlanId ID of alert plan to test
   */
  testAlertPlan(alertPlanId: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/alertPlans/${alertPlanId}/test`;
        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }
}

export interface AlertRuleInput {
  name: string;
  queryId: string;
  enabled?: boolean;
  description?: string;
  runFrequency?: string;
  countThreshold?: number;
}
