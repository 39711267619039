import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { Query } from '../../../../auditing/models/query.model';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { CheckLegendData } from '../models/legend-data';
import { HorizontalBarChartDomainModel } from './models/hbar.domain.model';
import { ChartQueryService } from '../services/chart-query.service';
import { InvokeWithErrorHandling } from '../../../utils/error.handling.wrapper';
import { getBarChartId } from '../utils/chart-utils';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Results } from '../../../../auditing/components/queries/results/results.model';
import { ActiveQueryService } from '../../../../auditing/services/active-query.service';
import { HBarConfigurationService } from './services/hbar-config.generator';
import { EventFieldsService } from '../../../../auditing/services/event-fields.service';
import { EventField } from '../../../../auditing/models/event-field.model';
import { CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH } from '../../../critical-activity/configuration/critical-activity.config';

@Component({
  selector: 'hbar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent extends BaseComponent implements OnInit, OnChanges {
  // autoDrilldown enable the chart to rerender
  // itself with updated query from mouse click event.
  // it will be faster since the chart element
  // is not removed then recreated.
  // On the Editor component, we have to disable it.
  // Otherwise, the editor will trigger remove/recreate chart regardless.
  @Input() inputQuery: Query = null;
  @Input() autoDrilldown = true;
  @Output() loading: EventEmitter<boolean>;
  @Output() barClicked: EventEmitter<Query>;

  // parameters for chart
  // USE plain field, NO GETTERs, please
  // I used getter for the chart parameters
  // it caused thousands of times per second of change detections for chartjs
  // the result is not rendering the chart.
  readonly chartType = 'horizontalBar'; // undefined in core, but it works.
  readonly containerStyle = {}; // undefined, Core require exact number of rem's for it to work.
  readonly canvasStyleObj = { width: '100%', height: '31rem' }; // assigned to canvas' style in Core UI
  chartOptions: ChartOptions;
  chartColors: Color[];
  chartDatasets: ChartDataSets[];
  chartLabels: string[];
  legendItems: CheckLegendData[];
  // ***********************************

  // text used in the html template
  noResultsImagePath: string;
  noResultImgAltText: string;
  noResultFoundTitle: string;
  adjustFilterTitle: string;
  errorText: string;
  tryAgainText: string;
  legendTitle: string;

  // boolean flags used in the html template
  error: boolean;
  dataReady: boolean;

  // domain model
  private domainModel: HorizontalBarChartDomainModel;
  // ***********************************

  // query required
  private query: Query;
  private groupByField: EventField;

  private _loadingData: boolean;
  get loadingData(): boolean {
    return this._loadingData;
  }
  set loadingData(val: boolean) {
    if (this._loadingData !== val) {
      this._loadingData = val;
      this.loading.emit(this._loadingData);
    }
  }

  get noResults(): boolean {
    if (!this.domainModel) {
      return false;
    }
    return this.domainModel.categoryCount <= 0;
  }

  constructor(
    private chartService: ChartQueryService,
    private activeQueryServicde: ActiveQueryService,
    private configService: HBarConfigurationService,
    private eventFieldsService: EventFieldsService
  ) {
    super();
    this.loading = new EventEmitter<boolean>();
    this.barClicked = new EventEmitter<Query>();
  }

  async ngOnInit(): Promise<void> {
    this.setupTemplateText();

    await this.initializeGroupByField();

    if (this.autoDrilldown) {
      this.barClicked
        .pipe(takeUntil(this.destructionSubject))
        .subscribe((updatedQuery: Query) => {
          this.query = updatedQuery;
          this.renderChart();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputQuery || this.query === null) {
      this.query =
      this.inputQuery ?? this.activeQueryServicde.getQuery().activeQuery;
      InvokeWithErrorHandling(
        () => {
          this.renderChart();
        },
        err => {
          this.handleError();
        }
      );
    }
  }

  retryOnError(): void {
    this.renderChart();
  }

  onCheckLegendChanged(checkLegend: CheckLegendData): void {
    this.domainModel.setCategoryEnable(checkLegend.label, checkLegend.checked);
    this.updateParameters();
  }

  private renderChart(): void {
    const chartId = getBarChartId(this.query);
    if (chartId) {
      this.error = false;
      this.dataReady = false;
      this.loadingData = true;

      this.chartService
        .runAdhocChartQuery(this.query, chartId)
        .pipe(
          take(1),
          catchError(err => throwError(err))
        )
        .subscribe(
          response =>
            InvokeWithErrorHandling(
              () => {
                this.initializeData(response.body);
              },
              err => {
                this.handleError();
              }
            ),
          err => {
            this.handleError();
          }
        );
    } else {
      throw new Error('not bar chart query');
    }
  }

  private async initializeGroupByField(): Promise<void> {
    this.groupByField = await this.eventFieldsService
      .getFields()
      .pipe(
        take(1),
        map(response => {
          const groupById =
            this.query.q.charts[0].bar.topXGroupOptions.topXGroupName;
          const dataFields = response.availableFields as object[];
          const eventFields = dataFields.map(data => new EventField(data));
          const groupByField = eventFields.find(
            field => field.id === groupById
          );
          return groupByField;
        })
      )
      .toPromise();

    if (this.groupByField) {
      this.legendTitle = this.groupByField.displayName;
    }
  }

  private initializeData(queryResults: Results): void {
    this.domainModel = new HorizontalBarChartDomainModel(queryResults);
    this.updateParameters();
  }

  private handleError(): void {
    this.error = true;
    this.dataReady = false;
    this.loadingData = false;
  }

  private updateParameters(): void {
    this.dataReady = false;
    setTimeout(() => {
      this.chartOptions = this.configService.generateHBarConfig(
        this.domainModel.dataPoints,
        this.groupByField,
        this.barClicked
      );
      this.chartColors = this.domainModel.chartColors;
      this.chartDatasets = this.domainModel.chartDataSets;
      this.chartLabels = this.domainModel.categoryLabels;
      this.legendItems = this.domainModel.legendItems;
      this.loadingData = false;
      this.dataReady = true;
    }, 0);
  }

  private setupTemplateText(): void {
    const provider = AuditingDisplayStringsProvider.auditing;
    this.noResultsImagePath = CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH;
    this.noResultImgAltText = provider.altImageUrlLabel;
    this.noResultFoundTitle = provider.noResultFound;
    this.adjustFilterTitle = provider.adjustYourFilter;
    this.errorText = provider.errorLoadingVisualization;
    this.tryAgainText = provider.pages.auditingDashboard.tryAgain;
  }
}
