/**
 * Component allowing users to input a date interval, such as "7 days"
 */
import { takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgForm, Validator } from '@angular/forms';
import { Component, EventEmitter, Output, forwardRef, ViewChild } from '@angular/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { BaseComponent } from '@ondemand/core';

const NUMERIC_STRING = /^\d+$/;
const VALUE_FORMAT = /^\d+_\w+$/;
export const DEFAULT_COUNT = '7';
export const DEFAULT_UNIT = 'days';

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateIntervalFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateIntervalFieldComponent),
    multi: true
  }
  ],
  selector: 'datetime-interval',
  templateUrl: './date-interval-field.component.html',
  styleUrls: ['./date-interval-field.component.scss']
})
export class DateIntervalFieldComponent extends BaseComponent implements ControlValueAccessor, Validator {
  @Output() change: EventEmitter<any>;
  @ViewChild('form') form: NgForm;
  outputString: string;
  rangeCount: string;
  rangeUnit: string;
  unitOptions: any[];
  valid = true;

  onChange = () => {
 return;
};

  constructor(private localeStringsService: LocaleStringsService) {
    super();
    this.change = new EventEmitter<any>();

    this.localeStringsService.strings$.pipe(takeUntil(this.destructionSubject)).subscribe(() => {
      this.unitOptions = [
        {
          value: 'hours',
          label: this.localeStringsService.get('auditing.pages.newSearches.unitHours')
        },
        {
          value: 'days',
          label: this.localeStringsService.get('auditing.pages.newSearches.unitDays')
        }
      ];
    });

  }

  /**
   * Load value from parent form into this component instance
   *
   * @param value ngModel input
   */
  writeValue(value: any): void {
    if (value !== null) {
      if (VALUE_FORMAT.test(value)) {
        [this.rangeCount, this.rangeUnit] = value.split('_');
        this.outputString = value;
      } else {
        // Fall back to default values if, for example, a date string is passed in
        [this.rangeCount, this.rangeUnit] = [DEFAULT_COUNT, DEFAULT_UNIT];
        this.onDateChange();
      }
    }
  }

  /**
   * Set up change propagation function
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.outputString);
      this.change.emit();
    };
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Update date object when a date input is modified
   */
  onDateChange() {
    this.outputString = `${this.rangeCount}_${this.rangeUnit}`;
    this.onChange();
  }

  /**
   * Validate user input
   *
   * @param _control Reference to this form control
   */
  validate(_control: UntypedFormControl): any {
    let valid = true;
    let error = {
      invalidValue: {
        rangeCount: this.rangeCount,
        rangeUnit: this.rangeUnit
      }
    };
    if (!(NUMERIC_STRING.test(this.rangeCount) && parseInt(this.rangeCount, 10) > 0)) {
      valid = false;
    }
    if (!this.rangeUnit) {
      valid = false;
    }
    this.valid = valid;
    return this.valid ? null : error;
  }

}
