export * from './unsaved-editor-changes.guard';
export * from './unsaved-changes.guard';
export * from './has-permissions.guard';
export * from './export-query-results.guard';

import { UnsavedEditorChangesGuard } from './unsaved-editor-changes.guard';
import { UnsavedChangesGuard } from './unsaved-changes.guard';
import { HasPermissionsGuard } from './has-permissions.guard';
import { ExportQueryResultsGuard } from './export-query-results.guard';
import { ProvisioningGuard } from './provisioning.guard';

export const guards = [
  ProvisioningGuard,
  UnsavedEditorChangesGuard,
  UnsavedChangesGuard,
  HasPermissionsGuard,
  ExportQueryResultsGuard
];
