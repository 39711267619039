/**
 * Component for displaying table view of auditing event details
 */
import { take, debounceTime } from 'rxjs/operators';
import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { BaseComponent, AppFacadeService } from '@ondemand/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { EventFieldsService } from '../../../../services/event-fields.service';
import { Cell } from '../results.cell.model';
import { formatCellValue } from '../results-preview/formatResultsForDataTable';
import { Row } from '../results.row.model';
import { EventField } from '../../../../models/event-field.model';
import { operatorMap, numericDataTypes } from '../../editor/search-operators';
import { Results } from '../results.model';
import { Subject } from 'rxjs';
import { QueryClause } from '../../../../models/query-clause';
import { QueryBody } from '../../../../models/query-body';
import { Query } from '../../../../models/query.model';
import { ActiveQueryService } from '../../../../services/active-query.service';
import { Router } from '@angular/router';
import {
  defaultCategoryId,
  defaultSharedCategoryId
} from '../../default-query-category';
import {
  SEARCH_PERMISSION,
  SearchPermissionsService
} from '../../../../services/search-permissions.service';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../../shared/utils/toast.wrapper';
import { FilterEvent } from '../event-details.table.model';

const blacklistedDataTypes = ['datetime'];
const blacklistedSemanticTypes = ['json'];
const maxAllowedValueLength = 250;

@Component({
  selector: 'event-details-table',
  templateUrl: './event-details-table.component.html',
  styleUrls: ['./event-details-table.component.scss']
})
export class EventDetailsTableComponent
  extends BaseComponent
  implements OnChanges {
  @Input() event: Results;
  @Input() displayAddFilter = true;
  @Output() filter = new EventEmitter<FilterEvent>();
  @ViewChild('clipboardArea') clipboardAreaElement: ElementRef;
  @ViewChild('eventDetailsTable') eventDetailsTableElement: ElementRef;

  eventFields: any;
  labels = AuditingDisplayStringsProvider.auditing;
  loadingEventDetails = false;
  showEmptyFields = false;
  columns: any[];
  formattedCells: any[];
  dropdownActions = new EventEmitter();
  dropdownOptions: any = {
    constrainWidth: false,
    belowOrigin: true
  };
  selectedDetail: any;
  textToCopy: string;
  hoveredRowNum: number;
  showActionButtons = false;
  searchPermission: string = SEARCH_PERMISSION.NONE;

  private hoveredRowSubject = new Subject<any>();

  constructor(
    private localeStrings: LocaleStringsService,
    private eventFieldsService: EventFieldsService,
    private activeQueryService: ActiveQueryService,
    private router: Router,
    private facade: AppFacadeService,
    private searchPermissionsService: SearchPermissionsService,
    private toastService: ODToastService
  ) {
    super();

    this.hoveredRowSubject.pipe(debounceTime(250)).subscribe(() => {
      const fieldHasContent = this.formattedCells[this.hoveredRowNum] !== '';
      this.showActionButtons = fieldHasContent;
    });
    this.setPermission();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      if (this.event) {
        this.loadingEventDetails = true;
        this.formattedCells = this.event.rows[0].cells.map(
          (cell: Cell, i: number) => formatCellValue(cell, this.event.columnMetadata[i], true)
        );
        await this.setDrilldownFlag(this.event);
      } else {
        this.formattedCells = null;
      }
      this.loadingEventDetails = false;
    }
  }

  /**
   * Handle clicking "copy to clipboard" for a specific value in the details
   *
   */
  onClickCopy(header: any) {
    let data = this.event.rows[0].cells.find(
      (cell: any) => cell.columnId === header.id
    );
    this.copyToClipboard(data.label);
  }

  /**
   * Copy arbitrary plain text to the clipboard
   *
   */
  copyToClipboard(text: string) {
    this.textToCopy = text;
    let detailsPanel = document.querySelector('.details-panel');
    if (detailsPanel) {
      let yPosition = detailsPanel.scrollTop;
      this.clipboardAreaElement.nativeElement.style.top = `${yPosition}px`;
    }
    this.clipboardAreaElement.nativeElement.setAttribute('readonly', '');
    this.clipboardAreaElement.nativeElement.value = text;
    this.clipboardAreaElement.nativeElement.select();

    document.execCommand('copy');
    this.textToCopy = null;
    this.localeStrings
      .string$('auditing.copiedToClipboard').pipe(
      take(1))
      .subscribe(message => {
        toastLowerLeft(this.toastService, message, ToastType.Success);
      });
  }

  /**
   * Handle click to copy all event details into clipboard
   *
   */
  async onClickCopyFullEvent() {
    const selection = document.getSelection();
    const range = document.createRange();
    const successCopyMsg = await this.localeStrings
      .string$('auditing.copiedToClipboard').pipe(take(1)).toPromise();

    range.selectNode(this.eventDetailsTableElement.nativeElement);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    toastLowerLeft(this.toastService, successCopyMsg, ToastType.Success);
    selection.removeAllRanges();
  }

  /**
   * Handle clicking on the "more options" menu
   *
   */
  onClickMore(header: any) {
    let data = this.event.rows[0].cells.find(
      (cell: any) => cell.columnId === header.id
    );
    this.selectedDetail = {
      field: header.id,
      value: data.value
    };
  }

  /**
   * Handle clicking button to add a new clause filtering
   * on the selected value
   *
   */
  onClickAddClause() {
    this.filter.emit({
      ...this.selectedDetail,
      type: 'add'
    });
  }

  /**
   * Handle clicking button to create a new search
   * based on the selected value
   *
   */
  async onClickNewSearch() {
    const unsavedSearchChanges = !this.activeQueryService.isSaved();
    if (unsavedSearchChanges) {
      const discardChanges = await this.promptToDiscardChanges();
      if (!discardChanges) {
        return;
      }
    }

    let currentQuery: Query = this.activeQueryService.getQuery().activeQuery;
    let newClause = await this.getNewClause(
      this.selectedDetail.field,
      this.selectedDetail.value
    );
    let queryBody = new QueryBody({
      clauses: [newClause]
    });

    let categoryId: string;
    let isSharedQuery = false;
    if (currentQuery && currentQuery.categoryId) {
      categoryId = currentQuery.categoryId;
      isSharedQuery = currentQuery.isShared;
    } else {
      categoryId =
        this.searchPermission === SEARCH_PERMISSION.SHARED
          ? defaultSharedCategoryId
          : defaultCategoryId;
    }

    // Go to editor for new search
    let newQuery = new Query({
      categoryId,
      isShared: isSharedQuery,
      isProtected: false,
      q: queryBody
    });

    this.activeQueryService.setQuery(currentQuery, newQuery);
    this.router.navigate(['auditing', 'auditing', 'queries', 'editor']);
  }

  onRowHover(rowNum: number) {
    this.hoveredRowNum = rowNum;
    this.hoveredRowSubject.next();
  }

  onRowLeave() {
    this.hoveredRowNum = null;
    this.showActionButtons = false;
  }

  /**
   * Set "drilldownAllowed" flag on headers of an event,
   * based on the metadata for the related event field
   *
   */
  async setDrilldownFlag(event: any) {
    if (!this.eventFields) {
      let response = await this.eventFieldsService.getFields().toPromise();
      this.eventFields = response.availableFields;
    }

    const cells = (event.rows[0] as Row).cells;

    // Populate event details with flag for whether drilldown is allowed
    event.headers.forEach((header: any, index: number) => {
      let fieldMeta: EventField = this.eventFields.find(
        (field: EventField) => field.id === header.id
      );
      if (!fieldMeta) {
        throw Error(`Could not find "${header.id}" in metadata`);
      }
      header.drilldownAllowed =
        !blacklistedDataTypes.includes(fieldMeta.dataType) &&
        !blacklistedSemanticTypes.includes(fieldMeta.semanticType) &&
        Object.keys(operatorMap).includes(fieldMeta.dataType) &&
        !(fieldMeta.predefinedValuesOnly && cells[index].value === null) &&
        !(
          cells[index].value !== null &&
          cells[index].value.length > maxAllowedValueLength
        );
    });
  }

  private setPermission() {
    this.searchPermission = this.searchPermissionsService.getManageSearchPermission();
  }

  private async promptToDiscardChanges(): Promise<boolean> {
    let title = await this.localeStrings
      .string$('auditing.discardUnsavedChangesTitle').pipe(
      take(1))
      .toPromise();
    let message = await this.localeStrings
      .string$('auditing.discardUnsavedSearchChanges').pipe(
      take(1))
      .toPromise();
    return this.facade.confirm(title, message);
  }

  private async getNewClause(field: string, value: any) {
    let data;
    if (this.eventFields) {
      data = this.eventFields;
    } else {
      let response = await this.eventFieldsService.getFields().toPromise();
      data = response.availableFields;
    }
    const fieldMetadata = data.find((item: any) => item.id === field);
    const dataType = fieldMetadata.dataType;

    // Build query
    let operator;
    if (numericDataTypes.includes(dataType) || dataType === 'boolean') {
      operator = 'equals_number';
    } else if (dataType === 'string') {
      operator = 'equals';
    } else {
      console.error('Invalid data type for doing new search:', dataType);
    }
    return new QueryClause({
      field,
      value,
      operator
    });
  }
}
