import { TenantStatus } from './tenant-status.model';

/**
 * Model for the configuration of a tenant
 */

export class TenantConfiguration {
    directoryTenantId?: string;
    questTenantId?: string;
    [serviceName: string]: any;
    organizationId: string;
    disabled: boolean;
    'last-status-update-check': Date;
    statusDict: Map<string, TenantStatus>;
    status: TenantStatus;
}
