export interface BHEAPICredential {
  tokenId: string;
  tokenKey: string;
}

export interface BHEAPIConfigurationInput {
  apiEndpoint: string;
  credential: BHEAPICredential;
}

export interface BHEAPIConfigurationOutput {
  organizationId: string;
  id: string;
  apiEndpoint: string;
  createdDate: string;
  updatedDate: string;
  lastSuccessfulAPI: string;
  lastSuccessfulAPICallDate: string;
  lastFailedAPI: string;
  lastFailedAPICallDate: string;
  lastFailedAPIErrorCode: string;
  lastFailedErrorResponseBody: string;
  nextUpdateDate: string;
}

export class BHEConfiguration {
  organizationId: string;
  id: string;
  apiEndpoint: string;
  createdDate: Date;
  updatedDate: Date;
  lastSuccessfulAPI: string;
  lastSuccessfulAPICallDate: Date;
  lastFailedAPI: string;
  lastFailedAPICallDate: Date;
  lastFailedAPIErrorCode: string;
  lastFailedErrorResponseBody: string;
  nextUpdateDate: Date;

  constructor(apiOutput: BHEAPIConfigurationOutput) {
    this.id = apiOutput.id;
    this.organizationId = apiOutput.organizationId;
    this.apiEndpoint = apiOutput.apiEndpoint;
    this.lastSuccessfulAPI = apiOutput.lastSuccessfulAPI;
    this.lastFailedAPI = apiOutput.lastFailedAPI;
    this.lastFailedAPIErrorCode = apiOutput.lastFailedAPIErrorCode;
    this.lastFailedErrorResponseBody = apiOutput.lastFailedErrorResponseBody;

    this.createdDate = new Date(apiOutput.createdDate);
    this.updatedDate = new Date(apiOutput.updatedDate);
    this.lastSuccessfulAPICallDate = new Date(apiOutput.lastSuccessfulAPICallDate);
    this.lastFailedAPICallDate = new Date(apiOutput.lastFailedAPICallDate);

    this.nextUpdateDate = new Date(apiOutput.nextUpdateDate);
  }
}
