import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import { FavoriteSearchConfiguration, FavoriteSearchResponse } from '../models/favorite-search.models';
import * as fromFeature from '../../../reducers/index';
const getFavoriteSearchState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.favoriteSearch
);

export const selectFavoriteSearch = createSelector(
  getFavoriteSearchState,
  state => state.data
);

export const selectFavoriteSearchLoading = createSelector(
  getFavoriteSearchState,
  state => state.loading
);

export const selectFavoriteSearchError = createSelector(
  getFavoriteSearchState,
  state => state.error
);
