import { map, take } from 'rxjs/operators';
import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent, IModalWindow } from '@ondemand/core';
import { AlertRuleInput, AlertService } from '../../../services/alerts.service';
import { AlertPlan } from '../../../models/alert-plan.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { AlertRule } from '../../../models/alert-rule.model';
import { Query } from '../../../models/query.model';
import { forkJoin, Observable, of } from 'rxjs';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';

export interface AlertPlanListItem {
  alertPlan: AlertPlan;
  selected: boolean;
}

export interface NewAlertPlanSettings {
  selected: boolean;
  name: string;
}

export interface AlertRuleEditorSavedEvent {
  savedAlertRule: AlertRule;
  createdAlertPlan: AlertPlan;
}

@Component({

  selector: 'alert-rule-editor-modal',
  templateUrl: './alert-rule-editor-modal.component.html',
  styleUrls: ['./alert-rule-editor-modal.component.scss']
})
export class AlertRuleEditorModalComponent extends BaseComponent {
  @Output() alertRuleSaved = new EventEmitter<AlertRuleEditorSavedEvent>();
  @Output() alertRuleSaveError = new EventEmitter<any>();
  alertRule: AlertRule;
  error: string;
  errorLoadingData = false;
  saving = false;
  loadingData = false;
  alertPlanListItems: AlertPlanListItem[];
  modalParams: IModalWindow;
  numPlansSelected: number;
  newPlanOptions: NewAlertPlanSettings = {
    selected: false,
    name: ''
  };

  fromPermissions = fromPermissions;

  private alertRuleId: string;
  private alertRuleLinkedQuery: Query;
  private mode: 'create' | 'edit';
  private createdAlertPlan: AlertPlan = null;

  constructor(
    private alertService: AlertService,
    private localeStrings: LocaleStringsService,
    private permissionsService: AuditModulePermissionsService
  ) {
    super();
  }

  userCanCreateNewAlertPlan(): boolean {
    return this.userCanManagePrivateAlertPlans() || this.userCanManageSharedAlertPlans();
  }

  userCanManageSharedAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageSharedAlertsAndAlertPlans
    ]);
  }

  userCanManagePrivateAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]);
  }

  async onSubmit() {
    this.saving = true;

    if (this.mode === 'create') {
      try {
        this.alertRule = await this.createAlertRule(this.alertRuleLinkedQuery);
        this.alertRuleId = this.alertRule.id;
      } catch (error) {
        this.onFailedToSave();
        this.saving = false;
        return;
      }
    }

    const creatingNewAlertPlan: boolean = this.newPlanOptions.name.trim() !== '';

    if (creatingNewAlertPlan) {
      try {
        this.createdAlertPlan = await this.createAlertPlan(this.newPlanOptions.name);
      } catch (errorMessage) {
        this.error = errorMessage;
        this.saving = false;
        return;
      }
    }

    this.saveSelectedAlertPlans().then(() => {
      this.saving = false;
      this.onSaveSuccess();
    }, (_error) => {
      this.saving = false;
      this.onFailedToSave();
    });
  }

  onClickCancel() {
    this.close();
  }

  async openToCreateAlertRule(query: Query): Promise<void> {
    this.mode = 'create';
    this.alertRuleLinkedQuery = query;

    await this.setupModal();
    this.openModal();
  }

  async openToEditAlertRule(alertRuleId: string): Promise<void> {
    this.mode = 'edit';
    if (alertRuleId !== this.alertRuleId) {
      this.alertRuleId = alertRuleId;
    }

    try {
      await this.setupModal();
    } catch (e) {
      return;
    }

    this.openModal();
  }

  close() {
    this.modalParams = {
      ...this.modalParams,
      showModal: false
    };
  }

  onFormChange() {
    this.setNumberOfPlansSelected();
  }

  handleNewPlanName(newPlanValue: string) {
    this.newPlanOptions.selected =
      newPlanValue !== null &&
      newPlanValue !== undefined &&
      newPlanValue.trim() !== '';
  }

  isSharedAlertRuleType(): boolean {
    if ((this.alertRule && this.alertRule.isShared) ||
      (this.alertRuleLinkedQuery && this.alertRuleLinkedQuery.isShared)) {
      return true;
    }

    return false;
  }

  private async setupModal() {
    this.clearErrors();
    await this.loadData();

    let modalTitle = '';
    if (this.isSharedAlertRuleType()) {
      const modalTitleSharedPrefix = await this.localeStrings.string$('auditing.editSharedAlertRuleTitle').pipe(
        take(1)).toPromise();
      modalTitle += modalTitleSharedPrefix;
    } else {
      const modalTitlePrivatePrefix = await this.localeStrings.string$('auditing.editPrivateAlertRuleTitle').pipe(
        take(1)).toPromise();
      modalTitle += modalTitlePrivatePrefix;
    }
    if (this.alertRule) {
      modalTitle += ` - ${this.alertRule.name}`;
    }
    this.modalParams = {
      showModal: false,
      dialogParams: {
        title: modalTitle,
        actions: [],
        hideCancel: true
      }
    };
  }

  private openModal() {
    this.modalParams = {
      ... this.modalParams,
      showModal: true
    };
  }

  private createAlertRule(query: Query): Promise<AlertRule> {
    return new Promise((resolve, reject) => {
      const ruleParams: AlertRuleInput = {
        name: query.name,
        queryId: query.id,
        enabled: true
      };
      this.alertService.createAlertRule(ruleParams).subscribe((response) => {
        resolve(response.body);
      }, (error) => {
        console.error('Failed to create alert rule:', error);
        reject(error);
      });
    });
  }

  private async onFailedToSave() {
    this.error = await this.localeStrings.string$('auditing.failedToSaveAlertRuleInAlertEditor').pipe(
          take(1)).toPromise();
    this.alertRuleSaveError.emit();
  }

  private async onSaveSuccess() {
    this.clearErrors();
    this.close();
    this.alertRuleSaved.emit({
      savedAlertRule: this.alertRule,
      createdAlertPlan: this.createdAlertPlan
    });

    this.createdAlertPlan = null;
    this.newPlanOptions = {
      selected: false,
      name: ''
    };
  }

  private createAlertPlan(planName: string): Promise<AlertPlan> {
    return new Promise((resolve, reject) => {
      let plan = new AlertPlan({
        name: planName,
        actions: [
          {
            actionType: 'email',
            emailSettings:
            {
              recipients: []
            }
          }
        ],
        isShared: this.isSharedAlertRuleType()
      });
      this.alertService.createAlertPlan(plan).subscribe((response) => {
        let createdPlan: AlertPlan = response.body;
        resolve(createdPlan);
      }, async (errorResponse: HttpErrorResponse) => {
        console.error('Error creating alert plan:', errorResponse);
        const isDuplicateName = errorResponse.status === 409;
        let errorMessage: string;
        if (isDuplicateName) {
          errorMessage = 'auditing.duplicateNameError';
        } else {
          errorMessage = 'auditing.alertPlanCreateFailed';
        }
        reject(await this.localeStrings.string$(errorMessage).pipe(take(1)).toPromise());
      });
    });
  }

  private clearErrors() {
    this.error = null;
    this.errorLoadingData = false;
  }

  private loadData(): Promise<void> {
    this.clearOldData();
    this.loadingData = true;
    return new Promise((resolve, reject) => {
      const alertRule$ = this.getAlertRule$();
      const selectedPlans$ = this.getSelectedPlans$();
      const availablePlans$ = this.getAvailablePlans$();
      forkJoin(alertRule$, selectedPlans$, availablePlans$)
      .subscribe(([alertRule, selectedPlans, availablePlans]) => {
        this.alertRule = alertRule;
        const selectedPlanIds: string[] = selectedPlans.map((plan) => plan.id);
        this.setAlertPlanListAsPerQueryType(availablePlans, selectedPlanIds);
        const creatingNewRule = alertRule === null;
        const onlyOnePlanExists = this.alertPlanListItems.length === 1;
        if (creatingNewRule && onlyOnePlanExists) {
          this.alertPlanListItems[0].selected = true;
        }

        this.setNumberOfPlansSelected();
        this.loadingData = false;
        resolve();
      }, async (_error) => {
        console.error('Failed to load alert plans');
        this.error = await this.localeStrings.string$('auditing.failedToLoadAlertPlansInAlertEditor').pipe(take(1)).toPromise();
        this.loadingData = false;
        this.errorLoadingData = true;
        reject();
      });
    });
  }

  private setAlertPlanListAsPerQueryType(availablePlans: AlertPlan[], selectedPlanIds: string[]) {
    const isSharedRuleType = this.isSharedAlertRuleType();
    this.alertPlanListItems = availablePlans.filter(x => x.isShared === isSharedRuleType).map((plan) => ({
        alertPlan: plan,
        selected: selectedPlanIds.includes(plan.id)
      }));
  }

  private getAlertRule$(): Observable<AlertRule> {
    if (this.alertRuleId) {
      return this.alertService.getAlertRule(this.alertRuleId).pipe(map((response) => response.body));
    } else {
      return of(null);
    }
  }

  private getSelectedPlans$(): Observable<AlertPlan[]> {
    if (this.alertRuleId) {
      return this.alertService.getAlertPlans(this.alertRuleId).pipe(map((response) => response.body));
    } else {
      return of([]);
    }
  }

  private getAvailablePlans$(): Observable<AlertPlan[]> {
    return this.alertService.getAlertPlans().pipe(map((response) => response.body));
  }

  private clearOldData() {
    this.alertRule = null;
    this.alertPlanListItems = null;
  }

  private setNumberOfPlansSelected() {
    this.numPlansSelected = this.alertPlanListItems.filter((item) => item.selected).length;
    if (this.newPlanOptions.selected && this.newPlanOptions.name) {
      this.numPlansSelected++;
    }
  }

  private saveSelectedAlertPlans(): Promise<void> {
    let selectedPlanIds: string[] = this.alertPlanListItems
      .filter((item) => item.selected)
      .map((item) => item.alertPlan.id);

    if (this.createdAlertPlan && this.newPlanOptions.selected) {
      selectedPlanIds.push(this.createdAlertPlan.id);
    }

    return new Promise((resolve, reject) => {
      this.alertService.setAlertPlansForAlertRule(selectedPlanIds, this.alertRuleId)
        .subscribe((_response) => {
          resolve();
        }, (errorResponse: HttpErrorResponse) => {
          console.error('Got error response:', errorResponse);
          reject(errorResponse);
        });
    });
  }

}
