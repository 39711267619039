export const KEY_CODES: any = {
  RIGHT_ARROW: 'ArrowRight',
  LEFT_ARROW: 'ArrowLeft',
  UP_ARROW: 'ArrowUp',
  DOWN_ARROW: 'ArrowDown'
};

// Deprecated keyCodes required for compatibility
export const LEGACY_KEY_CODES: any = {
  RIGHT_ARROW: 39,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  DOWN_ARROW: 40
};

export interface FilterEvent {
  type: 'add';
  field: string;
  value: any;
}
