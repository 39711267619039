import {
  isToolTipDataValid,
  UserToolTipInfo
} from '../models/top-active-user.domain.model';

export function topActiveUsersChartTooltipHandler(
  tooltipModel: any,
  userToolTips: UserToolTipInfo[]
): void {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  // Set custom contents and layout
  if (tooltipModel.body) {
    const userIndex = tooltipModel.dataPoints[0].index;
    const tooltipData = userToolTips[userIndex];

    if (isToolTipDataValid(tooltipData) === false) {
      return;
    }

    const headerCellTextContentStyle =
      '"padding: 0; margin-bottom: 2px; color: #ffffff; vertical-align: middle; text-align: left;word-break: break-all"';
    const tHeader = `<thead>
    <tr>
      <th colspan="2" style=${headerCellTextContentStyle}>${tooltipData.username}</th>
    </tr>
  </thead>`;

    let innerHtml = tHeader + '<tbody>';
    const tableRowStyle = '"line-height: 0px;"';
    const tableCellColorSquareStyle = `"width: 1.428rem; padding: 0.1rem 0 0 0.214rem; margin: 0;"`;

    // allow only 4 rows max
    const rowCount = tooltipData.serviceCounts.length;
    for (let i = 0; i < rowCount; i++) {
      const auditServiceInfo = tooltipData.serviceCounts[i];
      const displayTextLine = `${
        auditServiceInfo.serviceName
      }: ${auditServiceInfo.eventCount.toLocaleString('en')}`;
      const spanStyle =
        `"vertical-align: middle;border: 1px solid white; display: inline-block; background-color:${auditServiceInfo.auditServiceColor}; ` +
        'height: 0.714rem; width: 0.714rem;"';
      const tableCellTextContentStyle =
        '"color: #ffffff; vertical-align: top; text-align: left;"';
      const span = `<span style=${spanStyle}></span>`;

      const tableRow =
        `<tr style=${tableRowStyle}><td style=${tableCellColorSquareStyle}>${span}</td>` +
        `<td style=${tableCellTextContentStyle}>${displayTextLine}</td></tr>`;

      innerHtml += tableRow;
    }
    innerHtml += `</tbody>`;

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
    tableRoot.style.padding = '0';
    tableRoot.style.margin = '0';
  }

  const widgetTag = document.getElementsByTagName('top-active-users-widget')[0];
  const chartCanvasTag = widgetTag.getElementsByTagName('canvas')[0];
  const widgetCard = widgetTag.getElementsByClassName('general-card')[0];
  const tooltipRect = tooltipEl.getBoundingClientRect();
  const widgetRect = widgetCard.getBoundingClientRect();
  const chartCanvasRect = chartCanvasTag.getBoundingClientRect();

  const tooltipWidth = Math.floor(widgetRect.width * 0.38);
  const offsetAdjustment = 5;

  // default location to the right of data point
  let tooltipLeft =
    chartCanvasRect.left +
    window.pageXOffset +
    tooltipModel.caretX +
    offsetAdjustment;
  if (chartCanvasRect.width - tooltipModel.caretX < tooltipWidth) {
    tooltipLeft -= tooltipWidth + 2 * offsetAdjustment;
    if (tooltipLeft < widgetRect.left) {
      tooltipLeft = widgetRect.left;
    }
  }

  // default location to be below the data point
  let tooltipTop =
    chartCanvasRect.top +
    window.pageYOffset +
    tooltipModel.caretY +
    offsetAdjustment;
  if (chartCanvasRect.height - tooltipModel.caretY < tooltipRect.height) {
    tooltipTop -= tooltipRect.height + 2 * offsetAdjustment;
  }

  tooltipEl.style.opacity = '0.95';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = `${tooltipLeft}px`;
  tooltipEl.style.top = `${tooltipTop}px`;
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = '#4a4946';
  tooltipEl.style.borderRadius = '10px';
  tooltipEl.style.width = `${tooltipWidth}px`;
  tooltipEl.style.zIndex = '995';
}
