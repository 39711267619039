<div class="row export-button-container" *ngIf="
userHasExportSearchResultsPermissions && searchResultsCount && !isExporting
">
  <!-- The Export Button -->
  <a
    materialize="dropdown"
    class="dropdown-button btn-flat"
    href="javascript:void(0);"
    data-activates="dropdown1"
    [materializeParams]="[{ belowOrigin: true }]"
    ><span class="label bold_text">{{
      applicationStrings.exports.buttonLabel
    }}</span>
    <edc-icon class="material-icons right" name="chevron-down" size="small" style="padding-left: 2px;"></edc-icon>
    </a
  >
  
  <!-- The Export Options -->
  <ul id="dropdown1" class="dropdown-content">
    <li> <span class="export-csv-button" (click)="handleExportAction('csv')"> {{ applicationStrings.exports.csv }} </span></li>
    <li> <span class="export-zip-button" (click)="handleExportAction('zip')"> {{ applicationStrings.exports.csvAsZIP }}</span></li>
  </ul>
</div>

<ng-container *ngIf="isExporting">
  <div #exportingMessage class="exporting-wrapper valign-wrapper" [style.height]="exportHeight">
    <div class="row">
      <div class="col s12 center-align">
        <h2 class="center-align"><materialize-spinner [size]="'big'"></materialize-spinner></h2>
        <h5 class="bold_text">{{ exportingLabel }}</h5>
        <p class="light">
          {{ changePageExplanation }}
        </p>
        <a
          class="cancel-export-button"
          (click)="showExportCancelWarning()"
          ><span class="label">{{
            applicationStrings.exports.cancelSpinnerButtonLabel
          }}</span></a
        >
      </div>
    </div>
  </div>
</ng-container>

<modal-window [params]="exportLimitModalParams">
  <div modal-content>
    <strong>{{ exportRowLimitDescription }}</strong>
  </div>
</modal-window>

<modal-window [params]="exportCancelModalParams">
  <div modal-content>
    <strong>{{ exportCancelDescription }}</strong>
  </div>
</modal-window>
