/**
 * Component for button to link Change Auditor installation to the Audit module
 */
import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent, EDialogType, IModalDialog, AppFacadeService, Util } from '@ondemand/core';
import { FeatureSubscriptionService, EAuditFeatureName } from '../../../services/feature-subscription/feature-subscription.service';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { ADD_ICON_BLUE_V2_PATH } from '../../../../shared/shared.variables';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';

const o365SubscriptionURL = 'https://www.quest.com/BuyOnDemandAudit';

@Component({
  selector: 'add-tenant-card',
  templateUrl: './add-tenant-card.component.html',
  styleUrls: ['./add-tenant-card.component.scss']
})
export class AddTenantCardComponent extends BaseComponent implements OnInit {
  addTenantCardText: string;
  addTenantCardLearnMoreText: string;
  addTenantCardLearnMoreLinkText: string;
  addIconPath: string;
  addButtonText: string;

  constructor(
    private facade: AppFacadeService,
    private featureSubscriptionService: FeatureSubscriptionService,
    private localeStrings: LocaleStringsService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.setUpText();
  }

  async onClickAdd() {
    let activeSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.O365)
      .pipe(take(1))
      .toPromise();
    let expiredSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.O365_EXPIRED)
      .pipe(take(1))
      .toPromise();
    let offboardingSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.O365_OFFBOARDING)
      .pipe(take(1))
      .toPromise();
    let offboardedSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.O365_OFFBOARDED)
      .pipe(take(1))
      .toPromise();

    if (activeSubscription) {
      this.promptToContinue();
    } else if (expiredSubscription) {
      let messageReference = 'auditing.subscriptionExpiredMessage';
      let messageReplacements = {
        daysRemaining: Util.daysUntil(
          expiredSubscription.expiryDate,
          expiredSubscription.daysNoticeOffboarding
        ),
        daysToDeletion: Util.daysUntil(
          expiredSubscription.expiryDate,
          expiredSubscription.daysNoticeOffboarded
        )
      };
      this.promptToRenew(messageReference, messageReplacements);
    } else if (offboardingSubscription) {
      let messageReference = 'auditing.subscriptionOffboardingMessage';
      let messageReplacements = {
        daysToDeletion: Util.daysUntil(
          offboardingSubscription.expiryDate,
          offboardingSubscription.daysNoticeOffboarded
        )
      };
      this.promptToRenew(messageReference, messageReplacements);
    } else if (offboardedSubscription) {
      const offboarded = true;
      this.promptToBuy(offboarded);
    } else {
      this.promptToBuy();
    }
  }

  private async promptToRenew(
    messageReference: string,
    messageReplacements: any
  ) {
    const title = await this.localeStrings
      .string$('auditing.renewSubscriptionTitle')
      .pipe(take(1))
      .toPromise();
    let message = await this.localeStrings
      .string$(messageReference, messageReplacements)
      .pipe(take(1))
      .toPromise();
    const settings = {
      type: EDialogType.ERROR,
      okText: await this.localeStrings
        .string$('auditing.renewPromptButtonLabel')
        .pipe(take(1))
        .toPromise()
    };
    if (await this.facade.confirm(title, message, settings)) {
      this.facade.openInNewWindow(o365SubscriptionURL);
    }
  }

  private async promptToContinue() {
    const title = await this.facade
      .string$('addTenantDialogTitle')
      .pipe(take(1))
      .toPromise();
    const message = (
      await this.facade
        .string$<string[]>('addingTenantInfo')
        .pipe(take(1))
        .toPromise()
    ).join('<br>');

    this.facade.confirm(title, message).then(response => {
      if (response === true) {
        this.facade.addTenant();
      }
    });
  }

  private async promptToBuy(offboarded: boolean = false) {
    let message: string;
    let dialogType: EDialogType;
    if (offboarded) {
      message = 'auditing.subscriptionOffboardedMessage';
      dialogType = EDialogType.ERROR;
    } else {
      message = 'auditing.buyO365Message';
      dialogType = EDialogType.INFO;
    }

    let confirmTitle = await this.localeStrings
      .string$('auditing.buySubscriptionTitle')
      .pipe(take(1))
      .toPromise();
    let confirmMessage = await this.localeStrings
      .string$(message)
      .pipe(take(1))
      .toPromise();
    let modalSettings: IModalDialog = {
      type: dialogType,
      okText: await this.localeStrings
        .string$('auditing.buyPromptButtonLabel')
        .pipe(take(1))
        .toPromise()
    };
    if (
      await this.facade.confirm(confirmTitle, confirmMessage, modalSettings)
    ) {
      this.facade.openInNewWindow(o365SubscriptionURL);
    }
  }

  private setUpText(): void {
    this.addTenantCardText = AuditingDisplayStringsProvider.addTenantCardText;
    this.addIconPath = ADD_ICON_BLUE_V2_PATH;
    this.addTenantCardLearnMoreText =
      AuditingDisplayStringsProvider.addTenantCardLearnMoreText;
    this.addTenantCardLearnMoreLinkText =
      AuditingDisplayStringsProvider.addTenantCardLearnMoreLinkText;

    this.addButtonText = AuditingDisplayStringsProvider.addTenantButtonText;
  }
}
