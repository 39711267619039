import { Injectable } from '@angular/core';

import { Query } from '../models/query.model';

@Injectable()
export class ActiveQueryService {
  originalQuery: Query;
  activeQuery: Query;

  /**
   * Get current active query and original version of query
   *
   */
  getQuery() {
    return {
      activeQuery: this.activeQuery,
      originalQuery: this.originalQuery
    };
  }

  /**
   * Set original query and current query
   *
   * @param QueryBody originalQuery
   * @param Query query
   */
  setQuery(originalQuery: Query, activeQuery: Query) {
    this.originalQuery = originalQuery;
    this.activeQuery = activeQuery;
  }

  /**
   * Reset original and active query
   *
   */
  clearQuery() {
    this.originalQuery = null;
    this.activeQuery = null;
  }

  /**
   * Determine if the active query has unsaved changes
   *
   */
  isSaved() {
    if (!this.activeQuery) {
      return true;
    }

    const originalQueryJSON = JSON.stringify(this.originalQuery);
    const activeQueryJSON = JSON.stringify(this.activeQuery);
    return originalQueryJSON === activeQueryJSON;
  }
}
