import moment from 'moment';

export enum BheConfigStates {
  Available,
  UnAvailable,
  None
}

export interface UpcomingConfigurationSync {
  nextSyncTime: Date;
  nextSyncRelativeTimeStr: string;
}

  // Calculates the next configuration synchronization date time.
export function  getNextConfigurationSyncInfo(nextUpdateTime: Date): UpcomingConfigurationSync {
  setRelativeTimesThreshold();
  return {
          nextSyncTime: nextUpdateTime,
          nextSyncRelativeTimeStr: moment(nextUpdateTime).fromNow()
          };
  }

export function getLastConfigurationReceivedStr(lastUpdatedDate: Date): string {
  setRelativeTimesThreshold();
  return moment(lastUpdatedDate).fromNow();
  }

// Sets time threshold for moment object in the current scope.
function setRelativeTimesThreshold() {
  moment.relativeTimeThreshold('ss', 59);
  moment.relativeTimeThreshold('s', 59);
  moment.relativeTimeThreshold('m', 59);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 7);
}
