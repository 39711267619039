import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout, map, take, switchMap } from 'rxjs/operators';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { defaultHttpRequestTimeout } from '../../../auditing/util/constants';
import { ServiceDiscoveryService } from '../../../auditing/services/service-discovery.service';
import { CriticalActivityResponse, UpdateCriticalActivitySettings } from '../../models/critical-activity.shared.models';
import { WidgetID } from '../../../auditing/modules/auditing-dashboard/models/widget-info';
import { MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET } from '../../critical-activity/configuration/critical-activity.config';
import { WidgetService } from '../widget-service';
import { getCriticalActivityVisibleDatasets } from './critical-activity-lookup';
import { UpdateWidgetConfiguration } from '../../models/update-widget-configuration';

@Injectable()
export class CriticalActivityDataService {
  private basePath$: Observable<string>;

  constructor(
    private http: OnDemandTokenHttp,
    serviceDiscoveryService: ServiceDiscoveryService,
    private widgetService: WidgetService
  ) {
    this.basePath$ = serviceDiscoveryService.getDashboardUrl$();
  }

  getWidgetCriticalActivities(): Observable<CriticalActivityResponse> {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.CriticalActivities)
      .pipe(
        map(resp => {
          const response = resp.body as CriticalActivityResponse;
          const timestring = response.data.timestamp;
          const maxAvailableData = getCriticalActivityVisibleDatasets(response);
          const moreResults: boolean =
            response.data.criticalActivity.moreResultsAvailable;

          maxAvailableData.sort((ds1, ds2) => ds1.priority - ds2.priority);
          const dataCount = moreResults
            ? MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET - 1
            : MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET;
          const resultDatasets = maxAvailableData.slice(0, dataCount);

          return {
            ...response,
            data: {
              timestamp: timestring,
              criticalActivity: {
                moreResultsAvailable: moreResults,
                datasets: resultDatasets
              }
            }
          };
        }),
        timeout(defaultHttpRequestTimeout * 4)
      );
  }

  refreshWidgetCriticalActivities(): Observable<CriticalActivityResponse> {
    return this.widgetService
      .getWidgetDataWithHttpInfo(WidgetID.CriticalActivities, true)
      .pipe(
        map(resp => {
          const response = resp.body as CriticalActivityResponse;
          const timestring = response.data.timestamp;
          const maxAvailableData = getCriticalActivityVisibleDatasets(response);
          const moreResults: boolean =
            response.data.criticalActivity.moreResultsAvailable;

          maxAvailableData.sort((ds1, ds2) => ds1.priority - ds2.priority);
          const dataCount = moreResults
            ? MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET - 1
            : MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET;
          const resultDatasets = maxAvailableData.slice(0, dataCount);

          return {
            ...response,
            data: {
              timestamp: timestring,
              criticalActivity: {
                moreResultsAvailable: moreResults,
                datasets: resultDatasets
              }
            }
          };
        }),
        timeout(defaultHttpRequestTimeout * 4)
      );
  }

  getFullPageCriticalActivity(): Observable<CriticalActivityResponse> {
    return this.basePath$.pipe(
      take(1),
      switchMap((basePath: string) => {
        const path = `${basePath}/widgets/criticalActivity?fullpage=true`;

        // Counterintuitively, the `getTimezoneOffset` function returns a positive
        // number for time zones *behind* UTC and negative number for time zones
        // *ahead* of UTC, so we flip the sign for our ISO string purposes
        const queryParameters = new HttpParams().set(
          'timeZoneOffset',
          String(new Date().getTimezoneOffset() * -1)
        );
        const requestOptions = {
          headers: this.getDefaultHeaders(),
          params: queryParameters,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(
            map(response => response.body),
            timeout(defaultHttpRequestTimeout * 4)
          );
      })
    );
  }

  UpdateCriticalActivityConfig(
    updateSettings: UpdateCriticalActivitySettings
  ): Observable<HttpResponse<any>> {

    let updateObj: UpdateWidgetConfiguration = {
      criticalActivity: updateSettings
    };

    return this.widgetService.putWidgetSettingsWithHttpInfo(
      WidgetID.CriticalActivities,
      updateObj
    );
  }

  private getDefaultHeaders() {
    const headers = new HttpHeaders();
    return headers.set('Content-Type', 'application/json');
  }
}
