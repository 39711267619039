import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { Observable } from 'rxjs';
import { switchMap, take, timeout } from 'rxjs/operators';
import { ServiceDiscoveryService } from '../service-discovery.service';
import {
  BHEAPIConfigurationInput,
  BHEAPIConfigurationOutput
} from './bhe-api.data';
import { defaultHttpRequestTimeout } from '../../util/constants';

@Injectable()
export class BloodHoundConfigurationService {
  protected basePath$: Observable<string>;
  protected basePathWorkers$: Observable<string>;
  public defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = {
    withCredentials: false
  };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = this.serviceDiscoveryService.getBloodHoundUrl$();
    this.basePathWorkers$ =
      this.serviceDiscoveryService.getBloodHoundWorkersUrl$();
  }

  getConfiguration(): Observable<HttpResponse<BHEAPIConfigurationOutput[]>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/BHEConfiguration`;
        let headers = new HttpHeaders();

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  validateConfig(
    configInput: BHEAPIConfigurationInput
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/validateBHEConfiguration`;
        const headers = this.defaultHeaders;

        const requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: configInput,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  addNewConfig(
    configInput: BHEAPIConfigurationInput
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/BHEConfiguration`;
        const headers = this.defaultHeaders;

        const requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: configInput,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  updateConfig(
    id: string,
    configInput: BHEAPIConfigurationInput
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/BHEConfiguration/${id}`;
        const headers = this.defaultHeaders;

        const requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          body: configInput,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  refreshConfig(): Observable<HttpResponse<any>> {
    return this.basePathWorkers$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/configuration/update`;
        const headers = this.defaultHeaders;

        const requestOptions = {
          headers,
          withCredentials: false,
          body: { updateTierZeroList: true },
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  removeConfig(bheID: string): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/BHEConfiguration/${bheID}`;
        const headers = this.defaultHeaders;

        const requestOptions = {
          headers,
          withCredentials: false,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  private getDefaultHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }
}
