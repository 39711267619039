import { TopActiveUsersDataService } from './services/top-active-users.data.service';
import { TopActiveUsersMouseEventService } from './services/top-active-users.mouse-event.services';
import { TopActiveUsersComponent } from './top-active-users.component';
import { TopActiveUsersEffects } from './state/top-active-users.effects';
import { ExcludedUsersEditorComponent } from './editor/excluded-users-editor.component';

const TopActiveUsersServices = [
  TopActiveUsersMouseEventService,
  TopActiveUsersDataService
];

export const topActiveUsers = {
  components: [
    TopActiveUsersComponent,
    ExcludedUsersEditorComponent
  ],
  effects: [TopActiveUsersEffects],
  services: TopActiveUsersServices
};
