/**
 * Top-level component to host alert features
 */

import { Component } from '@angular/core';
import { AppFacadeService, BaseComponent } from '@ondemand/core';

import * as fromPermissions from '../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../services/audit-module-permissions.service';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagType } from '../shared/feature-flag.enum';

@Component({

    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent extends BaseComponent {

  fromPermissions: any = fromPermissions;
  enableNotificationPlansFlag = false;

  constructor(
    private permissionsService: AuditModulePermissionsService,
    private facadeService: AppFacadeService
  ) {
    super();
    this.facadeService
      .featureFlag$(FeatureFlagType.EnableNotificationPlans)
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(value => (this.enableNotificationPlansFlag = value));
  }

  canViewAlertRules(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageSharedAlertsAndAlertPlans,
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]);
  }

  canViewAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageSharedAlertsAndAlertPlans,
        fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]);
  }
}
