<internal-loading
  information="{{ 'auditing.loadingEventDetails' | localize }}"
  [isLoading]="loadingEventDetails && !loadingEventError"
></internal-loading>

<div class="results-detail">
  <div class="close-button">
    <span
      class="material-icons close-details"
      title="Close"
      (click)="closeComponent()"
      >close</span
    >
  </div>

  <div class="navigation">
    <span
      class="nav-button prev-button"
      [ngClass]="{ disabled: eventNumber === 1 }"
      (click)="viewPrevious()"
      title="{{ 'auditing.prevEvent' | localize }}"
    >
      <edc-icon name="chevron-left"></edc-icon>
    </span>

    <!-- TODO: Localize fully -->
    <span class="position-text"
      >{{ 'auditing.viewingEvent' | localize }} {{ eventNumber | number }}
      {{ 'auditing.of' | localize }} {{ totalEvents | number }}</span
    >

    <span
      class="nav-button next-button"
      [ngClass]="{ disabled: eventNumber === totalEvents }"
      (click)="viewNext()"
      title="{{ 'auditing.nextEvent' | localize }}"
    >
      <edc-icon name="chevron-right"></edc-icon>
    </span>
  </div>

  <div class="details-table-section">
    <div class="top-section">
      <div class="event-permalink right">
        <a
          *ngIf="event && !loadingEventError"
          routerLink="/auditing/auditing/events/{{ event.rows[0].id }}"
          ><edc-icon name="link"></edc-icon
          >{{ 'auditing.eventPermalink' | localize }}</a
        >
      </div>

      <div class="event-summary-container">
        <event-summary *ngIf="event" [event]="event"></event-summary>
      </div>
    </div>

    <error-message *ngIf="loadingEventError">{{
      'auditing.eventLoadError' | localize
    }}</error-message>

    <ng-container *ngIf="!loadingEventError">
      <event-details-table
        [event]="event"
        [displayAddFilter]="displayAddFilter"
        (filter)="onFilter($event)"
      ></event-details-table>
    </ng-container>
  </div>
</div>
