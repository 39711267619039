import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { CAUpgradeMessage } from '../../../models/ca-upgrade-message.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';

@Component({
  selector: 'ca-upgrade-message',
  templateUrl: 'ca-upgrade-message.component.html',
  styleUrls: ['ca-upgrade-message.component.scss']
})
export class CAUpgradeMessageComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  @Input() upgradeInfo: CAUpgradeMessage;

  upgradeMessage: {
    text: string;
    icon: string;
    iconColour: string;
  };

  constructor(private localeStrings: LocaleStringsService) {
    super();
  }

  ngOnInit() {
    this.setUpgradeMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.upgradeInfo) {
      this.setUpgradeMessage();
    }
  }

  private setUpgradeMessage() {
    if (!this.upgradeInfo) {
      this.upgradeMessage = null;
      return;
    }

    let icon = 'status-info';
    let iconColour = 'info-blue';
    let text = this.localeStrings.get(
      'auditing.changeAuditorUpgradeAvailable',
      {
        url: this.upgradeInfo.url
      }
    );

    if (this.upgradeInfo.severity.toUpperCase() === 'warning'.toUpperCase()) {
      icon = 'status-warning';
      iconColour = 'warning-orange';
      text = this.localeStrings.get(
        'auditing.changeAuditorCriticalUpgradeAvailable',
        {
          url: this.upgradeInfo.url
        }
      );
    }

    this.upgradeMessage = {
      icon,
      iconColour,
      text
    };
  }
}
