/**
 * Component for button to link Change Auditor installation to the Audit module
 */
import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  BaseComponent,
  IModalWindow,
  EDialogType,
  IModalDialog,
  AppFacadeService,
  Util
} from '@ondemand/core';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import {
  FeatureSubscriptionService,
  EAuditFeatureName
} from '../../../services/feature-subscription/feature-subscription.service';
import { changeAuditorSubscriptionURL } from '../../../util/constants';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { ADD_ICON_BLUE_V2_PATH } from '../../../../shared/shared.variables';
@Component({
  selector: 'add-ca-card',
  templateUrl: './add-ca-card.component.html',
  styleUrls: ['./add-ca-card.component.scss']
})
export class AddChangeAuditorCardComponent extends BaseComponent implements OnInit {

  instructionsModalParams: IModalWindow;

  addButtonText: string;
  addIconPath: string;
  addTitle: string;
  description: string;
  learnMoreLinkText: string;
  constructor(
    private localeStrings: LocaleStringsService,
    private featureSubscriptionService: FeatureSubscriptionService,
    private facade: AppFacadeService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.setUpText();
  }

  async onClickAdd() {
    let activeSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR)
      .pipe(take(1))
      .toPromise();
    let expiredSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR_EXPIRED)
      .pipe(take(1))
      .toPromise();
    let offboardingSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR_OFFBOARDING)
      .pipe(take(1))
      .toPromise();
    let offboardedSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR_OFFBOARDED)
      .pipe(take(1))
      .toPromise();

    if (activeSubscription) {
      this.openInstructionsModal();
    } else if (expiredSubscription) {
      let messageReference = 'auditing.subscriptionExpiredMessage';
      let messageReplacements = {
        daysRemaining: Util.daysUntil(
          expiredSubscription.expiryDate,
          expiredSubscription.daysNoticeOffboarding
        ),
        daysToDeletion: Util.daysUntil(
          expiredSubscription.expiryDate,
          expiredSubscription.daysNoticeOffboarded
        )
      };
      this.promptToRenew(messageReference, messageReplacements);
    } else if (offboardingSubscription) {
      let messageReference = 'auditing.subscriptionOffboardingMessage';
      let messageReplacements = {
        daysToDeletion: Util.daysUntil(
          offboardingSubscription.expiryDate,
          offboardingSubscription.daysNoticeOffboarded
        )
      };
      this.promptToRenew(messageReference, messageReplacements);
    } else if (offboardedSubscription) {
      const offboarded = true;
      this.promptToBuy(offboarded);
    } else {
      this.promptToBuy();
    }
  }

  private setUpText(): void {
    this.addIconPath = ADD_ICON_BLUE_V2_PATH;
    this.addTitle = AuditingDisplayStringsProvider.addChangeAuditorModalTitle;
    this.description = AuditingDisplayStringsProvider.addChangeAuditorCardText;
    this.learnMoreLinkText =
      AuditingDisplayStringsProvider.addChangeAuditorLearnMore;
    this.addButtonText = AuditingDisplayStringsProvider.addChangeAuditorButton;
  }

  private async openInstructionsModal() {
    const okayLabel = await this.localeStrings
      .string$('auditing.okay')
      .pipe(take(1))
      .toPromise();
    this.instructionsModalParams = {
      showModal: true,
      dialogParams: {
        actions: [
          {
            name: okayLabel,
            action: () => {
              this.closeModal();
            }
          }
        ],
        hideCancel: true
      }
    };
    this.instructionsModalParams = { ...this.instructionsModalParams };
  }

  private async promptToBuy(offboarded: boolean = false) {
    let message: string;
    let dialogType: EDialogType;
    if (offboarded) {
      message = 'auditing.subscriptionOffboardedMessage';
      dialogType = EDialogType.ERROR;
    } else {
      message = 'auditing.buyChangeAuditorMessage';
      dialogType = EDialogType.INFO;
    }

    let confirmTitle = await this.localeStrings
      .string$('auditing.buySubscriptionTitle')
      .pipe(take(1))
      .toPromise();
    let confirmMessage = await this.localeStrings
      .string$(message)
      .pipe(take(1))
      .toPromise();
    let modalSettings: IModalDialog = {
      type: dialogType,
      okText: await this.localeStrings
        .string$('auditing.buyPromptButtonLabel')
        .pipe(take(1))
        .toPromise()
    };
    if (
      await this.facade.confirm(confirmTitle, confirmMessage, modalSettings)
    ) {
      this.facade.openInNewWindow(changeAuditorSubscriptionURL);
    }
  }

  private async promptToRenew(
    messageReference: string,
    messageReplacements: any
  ) {
    const title = await this.localeStrings
      .string$('auditing.renewSubscriptionTitle')
      .pipe(take(1))
      .toPromise();
    let message = await this.localeStrings
      .string$(messageReference, messageReplacements)
      .pipe(take(1))
      .toPromise();
    const settings = {
      type: EDialogType.ERROR,
      okText: await this.localeStrings
        .string$('auditing.renewPromptButtonLabel')
        .pipe(take(1))
        .toPromise()
    };
    if (await this.facade.confirm(title, message, settings)) {
      this.facade.openInNewWindow(changeAuditorSubscriptionURL);
    }
  }

  private closeModal() {
    this.instructionsModalParams.showModal = false;
    this.instructionsModalParams = { ...this.instructionsModalParams };
  }
}
