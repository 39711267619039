/**
 * Component for Audit module single event view
 */

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { EventService } from '../../services/event.service';
import { Results } from '../queries/results/results.model';
import { AuditingBreadcrumbsService } from '../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../services/locale-strings.service';

@Component({
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent extends BaseComponent implements OnInit {
  event: Results;
  queryId: string;
  errorMessagePath: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private breadcrumbs: AuditingBreadcrumbsService,
    private localeStrings: LocaleStringsService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      takeUntil(this.destructionSubject))
      .subscribe(async params => {
        this.loadEvent(params.eventId);
        this.queryId = params.query;

        let crumbs = [
          {
            title: await this.localeStrings
              .string$('auditing.eventDetailsBreadcrumbTitle').pipe(
              take(1))
              .toPromise(),
            url: ''
          }
        ];

        if (this.queryId) {
          crumbs = [
            {
              title: await this.localeStrings
                .string$('auditing.eventDetailsSearchResultBreadcrumbTitle').pipe(
                take(1))
                .toPromise(),
              url: `auditing/auditing/queries/results/${this.queryId}`
            },
            ...crumbs
          ];
        }

        this.breadcrumbs.set(crumbs);
      });
  }

  loadEvent(eventId: string) {
    this.event = null;
    this.errorMessagePath = null;
    this.eventService
      .getEventWithHttpInfo(eventId)
      .subscribe(
        response => {
          const data: Results = response.body;
          if (data.rows.length > 0) {
            this.event = response.body;
          } else {
            this.errorMessagePath = 'auditing.eventNotFound';
          }
        },
        response => {
          this.errorMessagePath = 'auditing.loadEventError';
          console.error('Failed to load event:', response);
        }
      );
  }
}
