/**
 * Service for managing creation of timeseries charts and creating their associated options
 */

import { Injectable } from '@angular/core';
import { ONDEMAND_CHART_COLORS } from '@ondemand/core';
import { ChartDataSets } from 'chart.js';
import { Query } from '../../../../../auditing/models/query.model';
import {
  TimeseriesData,
  generateTimeseriesChartDataTemplate
} from '../../models/anomaly-timeseries-models';
import { AnomalyTimeseriesChartTooltipService } from './anomaly-timeseries-chart-tooltip.service';

@Injectable()
export class AnomalyTimeseriesChartBuilderService {

  constructor(private tooltipService: AnomalyTimeseriesChartTooltipService) {}

  generateChartOptions(
    timeSlots: string[],
    anomalies: boolean[],
    query: Query
  ) {
    return this.tooltipService.generateChartOptions(
      timeSlots,
      anomalies,
      query
    );
  }

  // rfc2822 requires a specific format to be used by the creation of a new date object.
  formatTime(utcDateTimeOffset: string) {
    // the server will always give UTC time, +00:00
    // e.g., 2020-06-09T04:00:00+00:00
    const isoTimeString = utcDateTimeOffset.replace('+00:00', 'Z');
    const convertedTime = new Date(isoTimeString);
    const convertedTimeLabel = convertedTime.toISOString();
    return convertedTimeLabel;
  }

  generateTimeseriesDatasets(
    anomaliesQueryData: TimeseriesData
  ): ChartDataSets[] {
    let anomaliesData = new Array<number>();
    let pointStyles = new Array<Chart.PointStyle>();
    let pointRadius = new Array<number>();
    let pointBackgroundColors = new Array<string>();

    for (let i = 0; i < anomaliesQueryData.labels.length; i++) {
      if (anomaliesQueryData.anomalies[i]) {
        anomaliesData.push(anomaliesQueryData.total[i]);
        pointStyles.push('triangle');
        pointRadius.push(7);
        pointBackgroundColors.push('#d32f2f');
      } else {
        anomaliesData.push(null);
        pointStyles.push('circle');
        pointRadius.push(3);
        pointBackgroundColors.push(ONDEMAND_CHART_COLORS.indigo);
      }
    }

    return generateTimeseriesChartDataTemplate(
      pointBackgroundColors,
      pointRadius,
      pointStyles,
      anomaliesQueryData
    );
  }
}
