<div class="row pagination-container">
  <div class="col s6" *ngIf="includeCountView">
    <p class="pagination-text" *ngIf="totalRows > 0">
      Showing {{ currentPage * resultsPerPage + 1 | number }}-{{
        getLastPaginationNum() | number
      }}
      of {{ totalRows | number }} result{{ totalRows !== 1 ? 's' : '' }}.
    </p>
  </div>

  <div class="col" [ngClass]="{ s6: includeCountView }">
    <ul class="pagination right" *ngIf="showButtons">
      <li class="waves-effect" [ngClass]="{ disabled: currentPage === 0 }">
        <a (click)="onPrevPageClick()"
          ><i class="material-icons">chevron_left</i></a
        >
      </li>
      <li
        class="waves-effect"
        *ngFor="let pageNum of paginationOptions"
        [ngClass]="{ active: pageNum === currentPage }"
      >
        <a (click)="onPageNumClick(pageNum)">{{ pageNum + 1 }}</a>
      </li>
      <li
        [ngClass]="{ disabled: currentPage === pagesAvailable - 1 }"
        class="waves-effect"
      >
        <a (click)="onNextPageClick()"
          ><i class="material-icons">chevron_right</i></a
        >
      </li>
    </ul>
  </div>
</div>
