import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import * as fromFeature from '../../../reducers/index';
import {
  AuditHealthConfigurationResponse,
  AuditHealthDataResponse
} from '../models/audit-health.models';

const auditHealthState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.auditHealth
);

export const selectAuditHealthData = createSelector(
  auditHealthState,
  state => state.data
);

export const selectAuditHealthLoading = createSelector(
  auditHealthState,
  state => state.loading
);

export const selectAuditHealthError = createSelector(
  auditHealthState,
  state => state.error
);

export const selectAuditHealthUpdating = createSelector(
  auditHealthState,
  state => state.updating
);

export const selectAuditHealthExpired = createSelector(
  auditHealthState,
  state => state.expired
);
