export function cloneAndUnlockObject<T>(lockedObject: T): T {
  // data from redux store actually called Object.preventExtensions()
  // to mark an object as no longer extensible. I have repeatedly seen
  // this causing exceptions where components trying to add properties
  // to existing objects. Use this simple method to unlock the object
  // before using it in a components when objects are going to be extended.
  const dataStr = JSON.stringify(lockedObject);
  const unlockedData = JSON.parse(dataStr) as T;
  return unlockedData;
}
