/**
 * Saved Queries Service
 * Service for managing saved queries
 *
 */
import { throwError, Observable, Subject } from 'rxjs';
import { catchError, switchMap, take, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { SavedQueryCategory } from '../components/queries/saved-queries/saved-query-category.model';
import { UpsertCategory } from '../components/queries/saved-queries/upsert-category.model';
import { ServiceDiscoveryService } from './service-discovery.service';
import { QueryBody } from '../models/query-body';
import { QueryClause } from '../models/query-clause';
import { Query } from '../models/query.model';
import { UpdateQuery } from '../models/update-query.model';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CreateQuery } from '../models/create-query.model';

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

@Injectable()
export class SavedQueriesService {
  protected basePath$: Observable<string>;
  public updates = new Subject<any>();

  constructor(
    protected http: OnDemandTokenHttp,
    serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = serviceDiscoveryService.getQueryUrl$();
  }

  getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  /**
   * Delete category
   *
   */
  public deleteCategory(
    categoryId: string,
    extraHttpRequestParams?: any
  ): Observable<{}> {
    return this.deleteCategoryWithHttpInfo(
      categoryId,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status === 200 || response.status === 204) {
        return undefined;
      } else {
        return throwError(
          `Failed to delete category: HTTP ${response.status}`
        );
      }
    }));
  }

  /**
   * Partially update query category
   * Partially update an existing, user-defined query category
   */
  public patchCategory(
    categoryId: string,
    body?: SavedQueryCategory,
    extraHttpRequestParams?: any
  ): Observable<SavedQueryCategory> {
    return this.patchCategoryWithHttpInfo(
      categoryId,
      body,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status === 204) {
        return undefined;
      } else {
        return response.body;
      }
    }));
  }

  /**
   * Update query category
   * Update an existing, user-defined query category
   */
  public updateCategory(
    categoryId: string,
    body?: UpsertCategory,
    extraHttpRequestParams?: any
  ): Observable<SavedQueryCategory> {
    return this.updateCategoryWithHttpInfo(
      categoryId,
      body,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status === 204) {
        return undefined;
      } else {
        return response.body;
      }
    }));
  }

  /**
   * Get list of all categories
   * This includes only the categories the user has permission to view
   */
  public getCategories(extraHttpRequestParams?: any): Observable<any> {
    return this.getCategoriesWithHttpInfo(extraHttpRequestParams).pipe(map(
      response => {
        if (response.status === 204) {
          return undefined;
        } else {
          return response.body;
        }
      }
    ));
  }

  /**
   * Create a category
   * Create a new, user-defined saved query category
   */
  public createCategory(
    body?: UpsertCategory,
    extraHttpRequestParams?: any
  ): Observable<SavedQueryCategory> {
    return this.createCategoryWithHttpInfo(body, extraHttpRequestParams).pipe(map(
      response => {
        if (response.status === 201) {
          let category = new SavedQueryCategory();
          Object.assign(category, response.body);
          return category;
        } else {
          return response.body;
        }
      }
    ));
  }

  /**
   * Delete category
   *
   */
  public deleteCategoryWithHttpInfo(
    categoryId: string,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/categories/${categoryId}`;

      let queryParameters = new HttpParams();
      // verify required parameter 'categoryId' is not null or undefined
      if (categoryId === null || categoryId === undefined) {
        throw new Error(
          'Required parameter categoryId was null or undefined when calling deleteCategory.'
        );
      }
      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Partially update query category
   * Partially update an existing, user-defined query category
   */
  public patchCategoryWithHttpInfo(
    categoryId: string,
    category?: SavedQueryCategory,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/categories/${categoryId}`;

      let queryParameters = new HttpParams();
      // verify required parameter 'categoryId' is not null or undefined
      if (categoryId === null || categoryId === undefined) {
        throw new Error(
          'Required parameter categoryId was null or undefined when calling patchCategory.'
        );
      }
      let headers = this.getDefaultHeaders();
      let requestOptions = {
        headers,
        body: category === null ? {} : category,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.PATCH, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Update query category
   * Update an existing, user-defined query category
   */
  public updateCategoryWithHttpInfo(
    categoryId: string,
    body?: UpsertCategory,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/categories/${categoryId}`;

      let queryParameters = new HttpParams();
      // verify required parameter 'categoryId' is not null or undefined
      if (categoryId === null || categoryId === undefined) {
        throw new Error(
          'Required parameter categoryId was null or undefined when calling updateCategory.'
        );
      }
      let headers = this.getDefaultHeaders();
      let requestOptions = {
        headers,
        body: body === null ? {} : body,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Get list of all categories
   * This includes only the categories the user has permission to view
   */
  public getCategoriesWithHttpInfo(
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/categories`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Create a category
   * Create a new, user-defined saved query category
   */
  public createCategoryWithHttpInfo(
    body?: UpsertCategory,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/categories`;

      let queryParameters = new HttpParams();

      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        body: body === null ? {} : body,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.POST, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Get list of all saved queries
   * This includes only the queries the user has permission to view
   */
  public getSavedQueries(extraHttpRequestParams?: any): Observable<any> {
    return this.getSavedQueriesWithHttpInfo(extraHttpRequestParams).pipe(
      map(response => {
        if (response.status === 204) {
          return undefined;
        } else if (response.status === 200) {
          let data = response.body;
          // Create actual SavedQuery instances
          let queryCategories: SavedQueryCategory[] = data.categories.map(
            (item: SavedQueryCategory) => {
              let category = new SavedQueryCategory(item);
              category.queries = category.queries.map((query: Query) => {
                let fullQuery = new Query(query);
                fullQuery.categoryName = category.name;
                return fullQuery;
              });
              return category;
            }
          );

          return queryCategories;
        } else {
          return response.body;
        }
      }),
      catchError(error => {
        let errorMessage = `Could not load saved queries: "${error}"`;
        return throwError(errorMessage);
      }));
  }

  /**
   * Save a new query
   *
   * @param body JSON object representing query
   */
  public createSavedQuery(
    query: Query,
    extraHttpRequestParams?: any
  ): Observable<any> {
    return this.createSavedQueryWithHttpInfo(query, extraHttpRequestParams).pipe(map(
      response => {
        if (response.status === 201) {
          let createdQuery = new Query(response.body);
          this.updates.next({
            type: 'add',
            payload: createdQuery
          });
          return this.convertResponseToSavedQuery(response);
        } else {
          return throwError(`Failed to save: HTTP ${response.status}`);
        }
      }
    ));
  }

  /**
   * Delete a saved query
   *
   * @param queryId Saved query ID to delete
   */
  public deleteSavedQuery(
    queryId: string,
    extraHttpRequestParams?: any
  ): Observable<{}> {
    return this.deleteSavedQueryWithHttpInfo(
      queryId,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status === 200 || response.status === 204) {
        this.updates.next({ type: 'delete', id: queryId });
        return undefined;
      } else {
        return throwError(`Failed to delete: HTTP ${response.status}`);
      }
    }));
  }

  /**
   * Get saved query by ID
   * Returns a single query
   *
   * @param queryId ID of saved query
   */
  public getSavedQuery(
    queryId: string,
    extraHttpRequestParams?: any
  ): Observable<Query> {
    return this.getSavedQueryWithHttpInfo(queryId, extraHttpRequestParams).pipe(map(
      response => {
        if (response.status === 204) {
          return undefined;
        } else {
          return this.convertResponseToSavedQuery(response);
        }
      }
    ));
  }

  /**
   * Partially update a saved query
   *
   * @param queryId ID of saved query to update
   */
  public patchSavedQuery(
    queryId: string,
    query?: Query,
    extraHttpRequestParams?: any
  ): Observable<{}> {
    return this.patchSavedQueryWithHttpInfo(
      queryId,
      query,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status === 204) {
        return undefined;
      } else {
        return response.body;
      }
    }));
  }

  /**
   * Updates a saved query
   *
   */
  public updateSavedQuery(
    query: Query,
    extraHttpRequestParams?: any
  ): Observable<any> {
    return this.updateSavedQueryWithHttpInfo(query, extraHttpRequestParams).pipe(map(
      response => {
        if (response.status === 200) {
          return this.convertResponseToSavedQuery(response);
        } else {
          return new Error('Failed to update query');
        }
      }
    ));
  }

  /**
   * Get list of all saved queries
   * This includes only the queries the user has permission to view
   */
  public getSavedQueriesWithHttpInfo(
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Save a new query
   *
   * @param query JSON object representing query
   */
  public createSavedQueryWithHttpInfo(
    query: Query,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries`;

      // the create query API does not require properties set by server
      let createQuery = new CreateQuery(query);

      // verify required parameter 'query' is not null or undefined
      if (createQuery === null || createQuery === undefined) {
        throw new Error(
          'Required parameter query was null or undefined when calling createSavedQuery.'
        );
      }

      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        body: createQuery === null ? '' : createQuery,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }
      return this.http
        .requestHttp(HttpRequestMethod.POST, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Delete a saved query
   *
   * @param queryId Saved query ID to delete
   */
  public deleteSavedQueryWithHttpInfo(
    queryId: string,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/${queryId}`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();

      // verify required parameter 'queryId' is not null or undefined
      if (queryId === null || queryId === undefined) {
        throw new Error(
          'Required parameter queryId was null or undefined when calling deleteSavedQuery.'
        );
      }

      let requestOptions = {
        headers,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Get saved query by ID
   * Returns a single query
   *
   * @param queryId ID of saved query
   */
  public getSavedQueryWithHttpInfo(
    queryId: string,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/${queryId}`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();
      // verify required parameter 'queryId' is not null or undefined
      if (queryId === null || queryId === undefined) {
        throw new Error(
          'Required parameter queryId was null or undefined when calling getSavedQuery.'
        );
      }

      let requestOptions = {
        headers,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }
      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Partially update a saved query
   *
   * @param queryId ID of saved query to update
   */
  public patchSavedQueryWithHttpInfo(
    queryId: string,
    query?: Query,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/${queryId}`;

      let queryParameters = new HttpParams();
      // verify required parameter 'queryId' is not null or undefined
      if (queryId === null || queryId === undefined) {
        throw new Error(
          'Required parameter queryId was null or undefined when calling patchSavedQuery.'
        );
      }
      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        body: query === null ? {} : query,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http
        .requestHttp(HttpRequestMethod.PATCH, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Updates a saved query
   *
   * @param queryId ID of saved query to update
   */
  public updateSavedQueryWithHttpInfo(
    query: Query,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/savedQueries/${query.id}`;

      let queryParameters = new HttpParams();
      // verify required parameter 'queryId' is not null or undefined
      if (query.id === null || query.id === undefined) {
        throw new Error(
          'Required parameter query was null or undefined when calling updateSavedQuery.'
        );
      }
      let headers = this.getDefaultHeaders();

      // the update query API does not require properties set by server
      let updatableQuery = new UpdateQuery(query);

      let requestOptions = {
        headers,
        body: updatableQuery,
        params: queryParameters,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }
      return this.http
        .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Duplicate a saved query
   *
   * @param queryId ID of saved query to copy
   */
  public copySavedQueryWithHttpInfo(
    queryId: string,
    queryName: string,
    categoryId: string,
    isShared: boolean,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = basePath + `/copySavedQuery`;
      let headers = this.getDefaultHeaders();
      let body = {
        queryId,
        queryName,
        categoryId,
        isShared
      };
      let requestOptions = {
        headers,
        body,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }
      return this.http
        .requestHttp(HttpRequestMethod.POST, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  private convertResponseToSavedQuery(response: HttpResponse<any>): Query {
    const savedQueryNoClasses = response.body as any;
    const savedQuery: Query = Object.assign(new Query(), savedQueryNoClasses);
    savedQuery.q = Object.assign(new QueryBody(), savedQueryNoClasses.q);
    savedQuery.q.clauses = savedQuery.q.clauses.map(clause => Object.assign(new QueryClause(), clause));
    return savedQuery;
  }
}
