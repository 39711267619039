import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ONDEMAND_CHART_COLORS } from '@ondemand/core';
import { Chart } from 'chart.js';
import { Query } from '../../../../../auditing/models/query.model';
import { AnomalyTimeseriesChartQueryMouseEventService } from './anomaly-timeseries-chart-query-mouse-event.service';

@Injectable()
export class AnomalyTimeseriesChartTooltipService {
  renderer: Renderer2;
  anomalies: boolean[];
  constructor(
    rendererFactory: RendererFactory2,
    private mouseEventService: AnomalyTimeseriesChartQueryMouseEventService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  customXaxisTickCallback(value: number, index: any, values: any) {
    return new Date(value)
      .toLocaleString('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
      .replace(',', ' ');
  }

  customTickCallback(value: number, index: any, values: any) {
    if (Math.floor(value) === value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
    } else {
      return Math.floor(value).toLocaleString(undefined, {
        maximumFractionDigits: 0
      });
    }
  }

  customTitleCallback(tooltipItems: Chart.ChartTooltipItem[]) {
    const seletedTimestamp = tooltipItems[0].xLabel;
    return new Date(seletedTimestamp)
      .toLocaleString('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
      .replace(',', ' ');
  }

  customLabelCallback(
    tooltipItems: Chart.ChartTooltipItem,
    data: Chart.ChartData,
    baselineDatasetIndex: number = 2
  ) {
    let label = new Array<string>();
    const value = data.datasets[tooltipItems.datasetIndex].data[
      tooltipItems.index
    ] as number;

    if (tooltipItems.datasetIndex === 1) {
      // Check if the label is for the totals dataset
      const baseline = data.datasets[baselineDatasetIndex].data[tooltipItems.index] as number;
      if (this.anomalies[tooltipItems.index]) {
        // Check if associated anomalies entry is true
        label.push(`Anomaly Events: ${[value.toLocaleString()]}`);
      } else {
        label.push(`Total Events: ${[value.toLocaleString()]}`);
      }

      // If the baseline is greater than the total value, we omit the deviation.
      if (value > baseline) {
        let deviation: number;
        if (baseline === 0) {
          deviation = value  * 100;
        } else {
          deviation = ((value - baseline) / baseline) * 100;
        }
        label.push(`Baseline Deviation: ${deviation.toFixed(0)}%`);
      }
    } else {
      label.push(`Baseline Events: ${[value.toLocaleString()]}`);
    }

    return label;
  }

  customTooltipCallback(that: any, tooltipModel: any) {
    // Tooltip Element
    const tooltipEl = this.getOrCreateToolTipElement();
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      this.renderer.setStyle(tooltipEl, 'opacity', 0);
      return;
    }
    const chartPosition = that._chart.canvas.getBoundingClientRect();
    let { left, top } = chartPosition;
    left = left + window.pageXOffset + tooltipModel.caretX;
    // if the end of the tooltip is within 20px of striking distance of the viewport, bring it back a bit to the
    // left
    if (left + tooltipModel.width > window.innerWidth - 20) {
      left -= tooltipModel.width;
    }
    left = `${left}px`;
    top = `${top + window.pageYOffset + tooltipModel.caretY}px`;
    this.configureTooltip(tooltipEl, tooltipModel, { top, left });
    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(
        (bodyItem: any) => bodyItem.lines
      );
      const titleDiv = this.constructTitle(titleLines);
      const bodyDiv = this.constructBody(bodyLines, tooltipModel);
      Array.from(tooltipEl.children).forEach(child => {
        this.renderer.removeChild(tooltipEl, child);
      });
      this.renderer.appendChild(tooltipEl, titleDiv);
      this.renderer.appendChild(tooltipEl, bodyDiv);
    }
  }

  generateChartOptions(
    timeSlots: string[],
    anomalies: boolean[],
    query: Query
  ) {
    this.anomalies = anomalies;
    return {
      layout: {
        padding: {
          top: 5
        }
      },
      animation: {
        duration: 0
      },
      elements: {
        line: {
          tension: 0.1
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        fontColor: '#fff000',
        fontFamily: 'Open Sans',
        fontWeight: 'bold'
      },
      legend: {
        onClick: (e: any) => e.stopPropagation(),

        display: true,
        labels: {
          generateLabels: (chart: any) => {
            const colors = [
              '#d32f2f',
              ONDEMAND_CHART_COLORS.indigo,
              ONDEMAND_CHART_COLORS.rawSienna
            ];
            let labels =
              Chart.defaults.global.legend.labels.generateLabels(chart);
            let index = 0;
            for (let key in labels) {
              if (labels[key]) {
                labels[key].fillStyle = colors[index];
                labels[key].strokeStyle = colors[index];
                index++;
              }
            }
            return labels;
          },
          usePointStyle: true,
          padding: 40
        }
      },
      hover: {
        onHover(e: any) {
          const point = this.getElementAtEvent(e);
          if (point.length && point[0]._datasetIndex === 1)
            e.target.style.cursor = 'pointer';
          else e.target.style.cursor = 'default';
        }
      },
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        custom: this.thisAsThat(this.customTooltipCallback),

        callbacks: {
          title: this.customTitleCallback.bind(this),
          label: this.customLabelCallback.bind(this)
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback: this.customXaxisTickCallback.bind(this)
            },
            offset: true
          }
        ],
        yAxes: [
          {
            offset: true,
            afterDataLimits(scale: any) {
              // add 10% to both ends of range
              const range = scale.max - scale.min;
              const grace = range * 0.1;
              scale.max += grace;
            },
            gridLines: {
              drawBorder: false
            },
            ticks: {
              suggestedMax: 10,
              precision: 0,
              beginAtZero: true,
              callback: this.customTickCallback.bind(this)
            }
          }
        ]
      },
      events: ['click', 'mousemove', 'mouseout'],
      onClick: (event: any, chartElement: any[]) =>
        this.mouseEventService.clickHandlerForTimeseriesChart(
          event,
          chartElement,
          timeSlots,
          query
        )
    };
  }

  private getOrCreateToolTipElement() {
    if (this.getToolTipElement()) {
      return this.getToolTipElement();
    } else {
      this.createToolTipElement();
      return this.getToolTipElement();
    }
  }

  private getToolTipElement() {
    return document.getElementById('chartjs-tooltip');
  }

  private constructTitle(titleLines: any[]): any {
    const titleDiv = this.renderer.createElement('div');

    titleLines.forEach((title: string) => {
      const text = this.renderer.createText(title + ' ');
      this.renderer.appendChild(titleDiv, text);
    });

    return titleDiv;
  }

  private constructBody(bodyLines: any[], tooltipModel: any): any {
    const bodyDiv = this.renderer.createElement('div');
    bodyLines.forEach((body: any, i: number) => {
      let multiLine = false;
      let bodyText: any;
      const colors = tooltipModel.labelColors[i];
      const bodyLine = this.renderer.createElement('div');
      const paintChipSpan = this.renderer.createElement('span');

      // Handles multiline tooltips for timeseries charts. Allows us to manually set the box colors for each element.
      if (body[1] !== null && body[1] !== undefined) {
        multiLine = true;
        bodyText = this.renderer.createText(body[0]);
      } else {
        bodyText = this.renderer.createText(body);
      }

      this.renderer.addClass(paintChipSpan, 'tooltip-paint-chip');
      this.renderer.setStyle(
        paintChipSpan,
        'background',
        colors.backgroundColor
      );
      this.renderer.setStyle(paintChipSpan, 'border-color', colors.borderColor);
      this.renderer.appendChild(bodyLine, paintChipSpan);
      this.renderer.appendChild(bodyLine, bodyText);
      this.renderer.appendChild(bodyDiv, bodyLine);

      // The second timeseries entry will always be a baseline comparison, so we can hardcode the color.
      if (multiLine) {
        const bodyLine2 = this.renderer.createElement('div');
        const paintChipSpan2 = this.renderer.createElement('span');
        let bodyText2 = this.renderer.createText(body[1]);
        this.renderer.addClass(paintChipSpan2, 'tooltip-paint-chip');
        this.renderer.setStyle(
          paintChipSpan2,
          'background',
          ONDEMAND_CHART_COLORS.rawSienna
        );
        this.renderer.setStyle(
          paintChipSpan2,
          'border-color',
          ONDEMAND_CHART_COLORS.rawSienna
        );
        this.renderer.appendChild(bodyLine2, paintChipSpan2);
        this.renderer.appendChild(bodyLine2, bodyText2);
        this.renderer.appendChild(bodyDiv, bodyLine2);
      }
    });

    return bodyDiv;
  }

  private createToolTipElement() {
    const tooltipEl = this.renderer.createElement('div');
    this.renderer.setAttribute(tooltipEl, 'id', 'chartjs-tooltip');
    this.renderer.addClass(tooltipEl, 'tooltip');
    this.renderer.addClass(tooltipEl, 'qod-tooltiptext');
    this.renderer.appendChild(document.body, tooltipEl);
  }

  private configureTooltip(
    tooltipEl: any,
    tooltipModel: any,
    chartPosition: { left: string; top: string }
  ): void {
    this.renderer.setStyle(tooltipEl, 'opacity', 1);
    this.renderer.setStyle(tooltipEl, 'position', 'absolute');
    const { left, top } = chartPosition;
    this.renderer.setStyle(tooltipEl, 'left', left);
    this.renderer.setStyle(tooltipEl, 'top', top);
    this.renderer.setStyle(
      tooltipEl,
      'padding',
      tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
    );
    this.renderer.setStyle(tooltipEl, 'pointer-events', 'none');

    // Set caret Position
    this.renderer.removeClass(tooltipEl, 'above');
    this.renderer.removeClass(tooltipEl, 'below');
    this.renderer.removeClass(tooltipEl, 'center');
    this.renderer.removeClass(tooltipEl, 'top');
    this.renderer.removeClass(tooltipEl, 'bottom');

    if (tooltipModel.yAlign) {
      this.renderer.addClass(tooltipEl, 'center');
    } else {
      this.renderer.addClass(tooltipEl, 'no-transform');
    }
  }

  private thisAsThat(callBack: any) {
    const self = this;
    return function() {
      return callBack.apply(
        self,
        [this].concat(Array.prototype.slice.call(arguments))
      );
    };
  }
}
