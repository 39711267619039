/**
 * Component for specifying a list of values
 */
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgForm, Validator } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, forwardRef,
  ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { take } from 'rxjs/operators';

const objectModeOn = true;
const objectModeOff = false;

@Component({

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MultiValueFieldComponent,
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MultiValueFieldComponent),
    multi: true
  }
  ],
  selector: 'multi-value-field',
  templateUrl: './multi-value-field.component.html',
  styleUrls: ['./multi-value-field.component.scss']
})
export class MultiValueFieldComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() index: number;
  @Input() operator: string;
  @Input() field: string;
  @Input() predefinedOnly = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f') form: NgForm;
  onChange: any;
  valid = true;
  values: string[] = [];
  valueLabels: string[] = [];
  selectedMenuOptions: any[];

  chipsParams: any;
  chipsActions = new EventEmitter<string | MaterializeAction>();

  error: string;

  private availableValues: any[] = [];

  constructor(private renderer: Renderer2, private elementRef: ElementRef,
              private localeStrings: LocaleStringsService) {
    this.initChips();
  }

  ngOnInit() {
    this.renderer.listen(this.elementRef.nativeElement, 'focusout', () => {
      this.onFocusout();
    });
  }

  /**
   * Handle changes to inputs to this component
   *
   * @param changes Set of changes
   */
  ngOnChanges(_changes: SimpleChanges) {
    return;
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: string[]): void {
    if (value) {
      setTimeout(() => {
        this.setValues(value);
        this.selectedMenuOptions = value;
        this.reset.emit();
      }, 100);
    }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.values);
      this.change.emit();
    };

    this.reset.emit();
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Validate whether any values have been entered
   *
   * @param _control Reference to this control
   */
  validate(_control: UntypedFormControl): any {
    if (this.values.length > 0) {
      return null;
    } else {
      return {
        errorType: 'empty'
      };
    }
  }

  /**
   * Handle addition of a chip
   *
   * @param event Event emitted from chip component
   */
  onAdd(event: any) {
    let value = event.tag.trim();
    if (value && !this.values.includes(value)) {
      this.values.push(value);
      this.onChange();
    } else {
      // Trigger removal of empty chip in UI
      this.setValues(this.values);
    }
  }

  /**
   * Handle deletion of a chip
   *
   * @param event Event emitted from chip component
   */
  onDelete(event: any) {
    let removalIndex;
    if (this.predefinedOnly) {
      removalIndex = this.selectedMenuOptions.findIndex(item => item.label === event.tag);
      // materialize chips is triggering delete event two times. The second trigger is redundant.
      if (removalIndex < 0) {
        return;
      }
      // De-select from menu
      this.selectedMenuOptions.splice(removalIndex, 1);
      this.selectedMenuOptions = [...this.selectedMenuOptions];
    } else {
      removalIndex = this.values.indexOf(event.tag);
    }

    if (removalIndex === -1) {
      console.error('Could not find the chip to delete');
    } else {
      this.values.splice(removalIndex, 1);
    }

    this.onChange();
  }

  setValues(values: any[], objectMode = objectModeOff) {
    if (objectMode) {
      this.values = values.map((item) => item.value);
    } else {
      this.values = values;
    }

    let data = values.map((item) => ({
        tag: objectMode ? item.label : item
      }));

    let params = {
      ...this.chipsParams,
      data
    };

    this.chipsActions.emit({
      action: 'material_chip',
      params: [params]
    });

    if (this.onChange) {
      this.onChange();
    }
  }

  /**
   * Clean up input content
   */
  onFocusout() {
    // Clear left-over text input
    let inputField = this.elementRef.nativeElement.querySelector('.chips input');
    let inputText = inputField.value.trim();

    if (inputText && !this.values.includes(inputText)) {
      this.addChip(inputText);
    }
    inputField.value = '';
  }

  addChip(value: string) {
    if (!this.values.includes(value)) {
      let newValues = this.values.concat();
      newValues.push(value);
      this.setValues(newValues);
    }
  }

  onPredefinedMenuChange(_selections: any) {
    // Copy values from predefined menu into the chip list
    if (this.selectedMenuOptions !== undefined) {
      this.setValues(this.selectedMenuOptions, objectModeOn);
    }
  }

  updateLabels() {
    this.valueLabels = this.values.map((originalString) => this.availableValues.find((option) => option === originalString));
  }

  private async initChips() {
    this.chipsParams = {
      data: [],
      limit: 500,
      placeholder: await this.localeStrings.string$('auditing.pages.newSearches.chipPlaceholder').pipe(
        take(1)).toPromise(),
      secondaryPlaceholder: await this.localeStrings.string$('auditing.pages.newSearches.chipSecondaryPlaceholder').pipe(
      take(1)).toPromise()
    };
  }
}
