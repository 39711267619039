<!-- Disabled button for unsaved queries -->
<button
  *ngIf="!query.id"
  type="button"
  class="disabled left btn-flat search-alert-button tooltipped"
  materialize="tooltip"
  data-delay="50"
  data-position="bottom"
  attr.data-tooltip="{{ 'auditing.unsavedSearchAlertInfo' | localize }}"
>
  <edc-icon name="bell"></edc-icon> {{ 'auditing.alertButtonLabel' | localize }}
</button>

<!-- Button for saved queries -->
<button
  *ngIf="query.id"
  type="button"
  class="left btn-flat search-alert-button"
  (click)="onAlertClick()"
  [ngClass]="{ disabled: alertEnabled === null || activeRequest === true }"
>
  <edc-icon name="bell-check-marked" *ngIf="alertEnabled"></edc-icon>
  <edc-icon name="bell" *ngIf="!alertEnabled"></edc-icon>
  {{ 'auditing.alertButtonLabel' | localize }}
</button>

<ng-container *ngIf="successModalParams && successModalParams.showModal">
  <modal-window [params]="successModalParams">
    <div modal-content class="success-modal-content">
      <p>{{ 'auditing.alertEnableSuccessDescription1' | localize }}</p>
      <br>
      <p *odaIfHasPermissions="[
            fromPermissions.canManageSharedAlertsAndAlertPlans,
            fromPermissions.canManagePrivateAlertsAndAlertPlans
          ]"
      >
        <a routerLink="{{ successModalContent.linkUrl }}">{{
          successModalContent.linkTextPath | localize
        }}</a>
        {{ 'auditing.alertEnableSuccessDescription2' | localize }}
      </p>
    </div>
  </modal-window>
</ng-container>

<alert-rule-editor-modal
  (alertRuleSaveError)="onAlertRuleSaveFailed()"
  (alertRuleSaved)="onAlertRuleSaved($event)"
></alert-rule-editor-modal>
