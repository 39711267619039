import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface DropdownButtonMenuOption {
  label: string;
  action: string;
  icon?: string;
  disabled?: boolean;
  id?: string;
}

@Component({

    selector: 'dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  @Input() label: string;
  @Input() primaryButtonOptions: DropdownButtonMenuOption;
  @Input() menuOptions: DropdownButtonMenuOption[];
  @Output() action = new EventEmitter();
  dropdownActions = new EventEmitter();
  dropdownOpen = false;
  dropdownOptions: any = {
    belowOrigin: true
  };

  ngOnInit() {
    return;
  }

  triggerAction(action: string) {
    this.action.emit(action);
  }

  toggleDropdown() {
    let action = 'open';
    this.dropdownActions.emit({
      action: 'dropdown',
      params: [action]
    });
    this.dropdownOpen = !this.dropdownOpen;
  }

}
