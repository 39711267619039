<div class="alert-rule-list-container">
  <internal-loading [isLoading]="loading"></internal-loading>

  <error-message *ngIf="error">{{ error | async }}</error-message>

  <!-- TODO: Add loading message -->

  <table
    class="striped"
    *ngIf="alertRuleRows && alertRuleRows.length > 0 && !error"
  >
    <thead>
      <tr>
        <th>{{ 'auditing.alertRuleSearchNameHeader' | localize }}</th>
        <th>{{ 'auditing.alertRuleTypeHeader' | localize }}</th>
        <th>{{ 'auditing.alertRulesTimesRunHeader' | localize }}</th>
        <th>{{ 'auditing.alertRulesNumPlansHeader' | localize }}</th>
        <th>{{ 'auditing.alertRuleEnabled' | localize }}</th>
        <th><!-- Spacer for action buttons in table body --></th>
      </tr>
    </thead>

    <tbody>
      <tr class="alert-rule-row" *ngFor="let row of alertRuleRows">
        <td class="search-name-cell">
          <a
            (click)="toggleExpandedStatus(row)"
            class="expand-link"
            attr.aria-controls="rule-{{ row.alertRule.id }}-plan-list"
            attr.aria-expanded="{{ row.expanded }}"
          >
            <div class="alert-rule-name-container">
              <span class="alert-rule-name">
                {{ row.alertRule.name }}
              </span>

              <span class="expand-button" *ngIf="!row.expanded"
                ><edc-icon name="chevron-right"></edc-icon
              ></span>
              <span class="collapse-button" *ngIf="row.expanded"
                ><edc-icon name="chevron-down"></edc-icon
              ></span>
            </div>
          </a>

          <div
            class="alert-plan-list-container"
            id="rule-{{ row.alertRule.id }}-plan-list"
            *ngIf="row.expanded"
          >
            <div class="loading-message" *ngIf="row.loadingPlans">
              <indeterminate-loader information=""></indeterminate-loader>
            </div>

            <ng-container *ngIf="!row.loadingPlans">
              <last-saved-info-field
                [showLastSavedInfo]="row.alertRule.isShared"
                [lastSavedLabelInfo]= "row.alertRule.lastSavedLabelInfo | async"
                [lastSavedToolTipRefreshProp]="lastSavedToolTipRefreshProp"
                [lastSavedToolTipInfo]="row.alertRule.lastSavedToolTipInfo | async"
              ></last-saved-info-field>

              <div>
                <button
                  class="edit-plans-button btn"
                  type="button"
                  (click)="onClickEditPlans(row)"
                >
                  {{ 'auditing.editAlertPlansButton' | localize }}
                </button>
                <button
                  class="view-search-button btn"
                  type="button"
                  [routerLink]="[
                    '/auditing/auditing/queries/results',
                    row.alertRule.queryId
                  ]"
                  *ngIf="hasViewSearchPermission(row.alertRule)"
                >
                  {{ 'auditing.viewSearchButton' | localize }}
                </button>
              </div>

              <span
                class="alert-plan-list-title"
                *ngIf="row.alertPlans.length === 0"
                >{{ 'auditing.noAlertPlansAdded' | localize }}</span
              >

              <notification-template-list
                *ngIf="alertPlanMigrationEnabled; else legacyAlertPlanList"
                [notificationPlans]="row.alertPlans">
              </notification-template-list>

              <ng-template #legacyAlertPlanList>

                <span class="alert-plan-list-title" *ngIf="row.alertPlans.length > 0" >
                  {{ 'auditing.alertPlanListTitle' | localize }}
                </span>

                <ul class="alert-plan-list browser-default">
                  <li class="alert-plan" *ngFor="let plan of row.alertPlans">
                    <a
                      *ngIf="hasManageAlertPlansPermission; else alertPlanName"
                      [routerLink]="['/auditing/auditing/alerts/plans', plan.id]"
                      >{{ plan.name }}</a
                    >
                    <ng-template #alertPlanName
                      ><span class="alert-plan__name">{{
                        plan.name
                      }}</span></ng-template
                    >
                  </li>
                </ul>

              </ng-template>

            </ng-container>
          </div>
        </td>

        <td class="alert-type-cell">
          <span
           class="alert-type-shared"
           id="alert-type-shared-id"
           *ngIf="row.alertRule.isShared">
           {{ 'auditing.sharedAlertRuleType' | localize }}
          </span>
          <span
           class="alert-type-private"
           id="alert-type-private-id"
           *ngIf="!row.alertRule.isShared">
           {{ 'auditing.privateAlertRuleType' | localize }}
          </span>
        </td>

        <td class="times-triggered-cell">
          <span class="times-triggered">{{
            row.alertRule.timesTriggeredPastDay
          }}</span>
        </td>

        <td class="num-alert-plans-cell">
          {{ row.alertRule.numAlertPlans }}
        </td>

        <td class="rule-enabled-cell">
          <ng-container *ngIf="!row.hasAlertPlans">
            <span
              class="tooltipped rule-enable-info"
              materialize="tooltip"
              data-position="top"
              attr.data-tooltip="{{
                'auditing.tooltipAddAnAlertPlanToRule' | localize
              }}"
            >
              <edc-icon name="status-info"></edc-icon>
            </span>
          </ng-container>
          <div class="switch rule-enabled-switch">
            <label [ngClass]="{ disabled: !row.hasAlertPlans }">
              <input
                [(ngModel)]="row.alertRule.enabled"
                type="checkbox"
                (change)="onEnabledChange(row.alertRule)"
                [disabled]="!row.hasAlertPlans"
              />
              <span class="lever"></span>
            </label>
          </div>
        </td>

        <td>
          <a
            *ngIf="!row.alertRule.isBuiltIn"
            (click)="onDeleteRuleClick(row.alertRule)"
            class="delete-rule-link tooltipped"
            materialize="tooltip"
            data-position="top"
            data-delay="50"
            attr.data-tooltip="{{
              'auditing.deleteAlertRuleTooltip' | localize
            }}"
            ><edc-icon name="cross"></edc-icon
            ></a>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="no-rules-container"
    *ngIf="alertRuleRows && alertRuleRows.length === 0"
  >
    <img src="/assets/auditing/img/add-an-alert.svg" />
    <div
      class="no-rules-message"
      [innerHTML]="'auditing.noAlertRules' | localize"
    ></div>
  </div>

  <alert-rule-editor-modal
    (alertRuleSaved)="onAlertRuleEditSave($event)"
  ></alert-rule-editor-modal>
</div>

<ng-container *ngIf="successModalParams && successModalParams.showModal">
  <modal-window [params]="successModalParams">
    <div modal-content class="success-modal-content">
      <p>{{ 'auditing.alertEnableSuccessDescription1' | localize }}</p>
      <br>
      <p>
        <a routerLink="{{ successModalContent.linkUrl }}">{{
          successModalContent.linkTextPath | localize
        }}</a>
        {{ 'auditing.alertEnableSuccessDescription2' | localize }}
      </p>
    </div>
  </modal-window>
</ng-container>
