<!-- <ng-template #headerCellTemplate let-column="column">
  <div class="column-name" [attr.data-column-id]="column.prop" title="{{column.name}}">{{column.name}}</div>
</ng-template> -->

<div class="results-preview-container" #datatableContainer (resized)="onSizeChange()">
  <internal-loading
    [information]="coreStrings.loadingPleaseWait"
    [isLoading]="loading"
  ></internal-loading>
  <div
    class="no-results-message"
    *ngIf="!loading && results && results.rows.length === 0"
  >
    {{ applicationStrings.noSearchResults }}
  </div>
  <div
    *ngIf="results && results.rows.length > 0"
    [ngClass]="{ 'on-demand--disable-pointer': !canViewEventDetails }"
  >
    <ngx-datatable
      id="results-preview"
      class="on-demand"
      [rows]="datatableRows"
      [columns]="datatableColumns"
      [columnMode]="columnMode"
      [footerHeight]="0"
      [scrollbarH]="true"
      [scrollbarV]="true"
      [selected]="datatableSelectedRows"
      [selectionType]="'single'"
      [virtualization]="false"
      [externalSorting]="true"
      [sorts]="results.sorts"
      (select)="onSelect($event)"
      (sort)="onSort($event)"
      (reorder)="onReorder($event)"
    >
    </ngx-datatable>
  </div>
</div>

<result-pagination
  *ngIf="results"
  [includeCountView]="true"
  [totalRows]="results.totalRows || 0"
  [currentPage]="currentPage"
  [resultsPerPage]="resultsPerPage"
  (pageChange)="onPageChange($event)"
  [showButtons]="true"
  [pageOptionsShown]="5"
></result-pagination>

<ng-container *odaIfHasPermissions="[fromPermissions.canViewEventDetails]">
  <div
    id="details-activator"
    data-activates="details-panel"
    materialize="sideNav"
    [materializeParams]="[detailPaneParams]"
    [materializeActions]="detailPaneEvents"
  ></div>
  <section #detailsPanel id="details-panel" class="details-panel side-nav">
    <event-details
      *ngIf="detailsOpened"
      [eventId]="selectedRow?.id"
      [row]="selectedRow"
      [totalEvents]="results.totalRows"
      [eventNumber]="selectedEventNumber"
      [displayAddFilter]="displayAddFilter"
      (close)="closeDetails()"
      (next)="selectNextEvent()"
      (prev)="selectPreviousEvent()"
      (filter)="onFilterDetails($event)"
    ></event-details>
  </section>
</ng-container>
