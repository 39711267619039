import { takeUntil } from 'rxjs/operators';
import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppFacadeService } from '@ondemand/core';
import { Subject } from 'rxjs';
import { LocaleStringsService, pseudoLocale } from '../../../auditing/services/locale-strings.service';
import { getPseudoValue } from '../../../auditing/util/pseudo-locale';

@Pipe({
  name: 'localize',
  pure: false
})
export class LocalizationPipe implements PipeTransform, OnDestroy {
  // Use these for caching
  private latestPath: string;
  private latestOutput: string;
  private latestLanguage: string;
  private latestSubstitutions: string;

  private language: string;
  private dict: any;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private facade: AppFacadeService, private ref: ChangeDetectorRef,
              private localeStringsService: LocaleStringsService) {
    // This is intentionally left commented out here to be used for debugging localization
    // console.log('Lang observable=', this.facade.language$);
    this.facade.language$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((language) => {
      this.language = language;
    });
    this.localeStringsService.strings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dict) => {
      this.dict = dict;
      // Empty the cached output string so we trigger fresh dictionary lookup
      this.latestOutput = null;

      // Tell Angular to re-do the lookup since language changed
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  transform(path: string, substitutions?: any): string {
    if ((this.latestOutput || this.latestOutput === '')
        && this.latestPath === path
        && this.latestLanguage === this.language
        && (this.latestSubstitutions === JSON.stringify(substitutions))) {
      return this.latestOutput;
    } else {
      const output = this.getValue(path, substitutions);
      this.latestOutput = output;
      this.latestLanguage = this.language;
      this.latestPath = path;
      this.latestSubstitutions = JSON.stringify(substitutions);
      return output;
    }
  }

  /**
   * Get value for a string in the current language from the mapping dictionary
   *
   * @param path Dot-delimited string mapping the path in the string dictionary
   */
  getValue(path: string, substitutions?: any): string {
    if (!this.dict) {
      return '';
    }

    if (!path || typeof path !== 'string') {
      throw Error(`No path provided for localization: ${path}`);
    }

    let pathPieces = path.split('.');

    let currentNode = this.dict;
    for (let key of pathPieces) {
      if (!(key in currentNode)) {
        console.error(`Invalid path for string: "${path}"`);
        return '';
      }
      currentNode = currentNode[key];
    }

    if (substitutions) {
      Object.keys(substitutions).forEach(key => {
        const pattern = new RegExp(`{${key}}`, 'g');
        currentNode = currentNode.replace(pattern, substitutions[key]);
      });

    }

    if (this.language === pseudoLocale) {
      return getPseudoValue(currentNode);
    } else {
      return currentNode;
    }
  }

}
