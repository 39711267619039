/**
 * Service for getting data for a specific event
 */
import { switchMap, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { ServiceDiscoveryService } from './service-discovery.service';
import { CAInstallationInfo } from '../models/ca-setup.model';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class ChangeAuditorInstallationService {
  protected basePath$: Observable<string>;
  public defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = {
    withCredentials: false
  };

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = this.serviceDiscoveryService.getCAConfigUrl$();
  }

  getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  /**
   * Get list of all Change Auditor installations
   */
  public getInstallations(): Observable<HttpResponse<any>> {
    const requestOptions = this.getInstallationRequestOptions();
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = `${basePath}/installations`;
      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Get a single Change Auditor installation
   */
  public getInstallation(
    installationId: string
  ): Observable<HttpResponse<any>> {
    const requestOptions = this.getInstallationRequestOptions();
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = `${basePath}/installations/${installationId}`;
      return this.http
        .requestHttp(HttpRequestMethod.GET, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  public registerCoordinator(
    registrationData: CAInstallationInfo
  ): Observable<HttpResponse<any>> {
    let headers = this.defaultHeaders;
    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = `${basePath}/register`;

      let requestOptions = {
        headers,
        withCredentials: this.configuration.withCredentials,
        body: registrationData,
        observe: 'response'
      };

      return this.http
        .requestHttp(HttpRequestMethod.POST, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Remove a Change Auditor installation
   */
  public deleteInstallation(
    installationId: string,
    force: boolean = false
  ): Observable<HttpResponse<any>> {
    let queryParams = new HttpParams();
    if (force) {
      queryParams = queryParams.set('force', 'true');
    }
    let headers = this.defaultHeaders;
    let requestOptions = {
      headers,
      params: queryParams,
      withCredentials: this.configuration.withCredentials,
      observe: 'response'
    };

    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = `${basePath}/installations/${installationId}`;
      return this.http
        .requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Pause a Change Auditor installation connection
   */
  public pauseInstallation(
    installationId: string
  ): Observable<HttpResponse<any>> {
    return this.setPausedStatus(installationId, true);
  }

  /**
   * Resume a Change Auditor installation connection
   */
  public resumeInstallation(
    installationId: string
  ): Observable<HttpResponse<any>> {
    return this.setPausedStatus(installationId, false);
  }

  private setPausedStatus(
    installationId: string,
    status: boolean
  ): Observable<HttpResponse<any>> {
    let headers = this.defaultHeaders;
    let requestOptions = {
      headers,
      body: {
        paused: status
      },
      withCredentials: this.configuration.withCredentials,
      observe: 'response'
    };

    return this.basePath$.pipe(take(1), switchMap(basePath => {
      const path = `${basePath}/installations/${installationId}`;
      return this.http
        .requestHttp(HttpRequestMethod.PATCH, path, requestOptions)
        .pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  private getInstallationRequestOptions() {
    let headers = this.defaultHeaders;

    let requestOptions = {
      headers,
      withCredentials: this.configuration.withCredentials,
      observe: 'response'
    };

    return requestOptions;
  }
}
