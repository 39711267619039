/**
 * Convert string to pseudo-localized version
 *
 * @param value Original string label
 */
export const getPseudoValue = (value: string) => {
  let output = '';

  for (let i = 0; i < value.length; i++) {
    let c = value.charAt(i);
    let out = '';
    switch (c) {
      case 'a': out = 'á'; break;
      case 'b': out = 'β'; break;
      case 'c': out = 'ç'; break;
      case 'd': out = 'δ'; break;
      case 'e': out = 'è'; break;
      case 'f': out = 'ƒ'; break;
      case 'g': out = 'ϱ'; break;
      case 'h': out = 'λ'; break;
      case 'i': out = 'ï'; break;
      case 'j': out = 'J'; break;
      case 'k': out = 'ƙ'; break;
      case 'l': out = 'ℓ'; break;
      case 'm': out = '₥'; break;
      case 'n': out = 'ñ'; break;
      case 'o': out = 'ô'; break;
      case 'p': out = 'ƥ'; break;
      case 'q': out = '9'; break;
      case 'r': out = 'ř'; break;
      case 's': out = 'ƨ'; break;
      case 't': out = 'ƭ'; break;
      case 'u': out = 'ú'; break;
      case 'v': out = 'Ʋ'; break;
      case 'w': out = 'ω'; break;
      case 'x': out = 'ж'; break;
      case 'y': out = '¥'; break;
      case 'z': out = 'ƺ'; break;
      case 'A': out = 'Â'; break;
      case 'B': out = 'ß'; break;
      case 'C': out = 'Ç'; break;
      case 'D': out = 'Ð'; break;
      case 'E': out = 'É'; break;
      case 'F': out = 'Ϝ'; break;
      case 'G': out = 'б'; break;
      case 'H': out = 'Η'; break;
      case 'I': out = 'Ì'; break;
      case 'J': out = 'Ј'; break;
      case 'K': out = 'К'; break;
      case 'L': out = '£'; break;
      case 'M': out = 'ϻ'; break;
      case 'N': out = 'Π'; break;
      case 'O': out = 'Ó'; break;
      case 'P': out = 'Þ'; break;
      case 'Q': out = 'Ϙ'; break;
      case 'R': out = 'Я'; break;
      case 'S': out = '§'; break;
      case 'T': out = 'Т'; break;
      case 'U': out = 'Û'; break;
      case 'V': out = 'Ѵ'; break;
      case 'W': out = 'Ш'; break;
      case 'X': out = 'х'; break;
      case 'Y': out = 'Ý'; break;
      case 'Z': out = 'ж'; break;
      default: out = c; break;
    }
    output += out;
  }

  return output;
};
