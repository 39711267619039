/**
 * Provisioning Subscription Service.
 */
import { map, switchMap, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnDemandTokenHttp, HttpRequestMethod } from '@ondemand/core';
import { ServiceDiscoveryService } from '../service-discovery.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  EProvisioningStatus,
  IProvisionDetails,
  EProvisionedState
} from '../../../shared/models/provision.model';

@Injectable()
export class ProvisioningService {
  protected basePath$: Observable<string>;
  private defaultHeaders: HttpHeaders = this.getDefaultHeaders();
  private configuration = { withCredentials: false };
  private provisionCacheTimeToLive = 10000;
  private cachedProvisionStatus$: Promise<EProvisionedState> = null;
  private provisionStartDate: string = null;

  // This timeout is a temporary fix which will change the client to wait up to 3 minutes
  // when determining if ODCS(ODA) is provisioned and in requesting the provisioning.
  // Both calls need to wait(due to the cold start problems).Doing this fix will keep
  // private environments(including E2E tests) working and to ensure PRODUCTION does not encounter this issue.
  // This will buy time for ODCS team to fix the deeper problems in ODA / ODCS with cold start / scale out
  // impacting multiple areas of the product.
  private defaultProvisioningRequestTimeout = 180000;

  constructor(
    private http: OnDemandTokenHttp,
    serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = serviceDiscoveryService.getAuditHubUrl$().pipe(take(1));
  }

  public getProvisioningStatus(): Promise<EProvisionedState> {
    let state: EProvisionedState = EProvisionedState.Error;

    if (this.cachedProvisionStatus$) {
      return this.cachedProvisionStatus$;
    }

    this.cachedProvisionStatus$ = new Promise<EProvisionedState>(resolve => this.getProvisionStatusWithHttpInfo().subscribe(
        response => {
          if (response.status === 200) {
            let provisionDetails = response.body as IProvisionDetails;
            this.provisionStartDate = provisionDetails.provisionStartDate;
            if (provisionDetails.status === EProvisioningStatus.Provisioned) {
              state = EProvisionedState.Provisioned;
            } else if (
              provisionDetails.status ===
              EProvisioningStatus.ProvisionInProgress
            ) {
              state = EProvisionedState.Loading;
            } else {
              state = EProvisionedState.Provisionable;
            }
            resolve(state);
          } else {
            console.error('getProvisioningStatus: Error response:', response);
            resolve(state);
          }
        },
        error => {
          console.error('getProvisioningStatus : Unexpected response:', error);
          resolve(state);
        }
      ));

    setTimeout(() => {
      this.cachedProvisionStatus$ = null;
    }, this.provisionCacheTimeToLive);

    return this.cachedProvisionStatus$;
  }

  /**
   * Provision the current client context's organization
   */
  public provisionOrg(): Observable<EProvisionedState> {
    return this.provisionOrganizationWithHttpInfo().pipe(
      map(response => {
        let state: EProvisionedState = EProvisionedState.Error;
        if (response.status === 200) {
          let provisionDetails = response.body as IProvisionDetails;
          this.provisionStartDate = provisionDetails.provisionStartDate;
          if (provisionDetails.status === EProvisioningStatus.Provisioned) {
            state = EProvisionedState.Provisioned;
          } else if (
            provisionDetails.status === EProvisioningStatus.ProvisionInProgress
          ) {
            state = EProvisionedState.Loading;
          } else {
            // Unexpected State. Status should be in progress or provisioned
            console.error('provisionOrg: Unexpected provisioning state.');
            state = EProvisionedState.Error;
          }
        } else {
          console.error('provisionOrg: Error response:', response);
          state = EProvisionedState.Error;
        }
        return state;
      })
    );
  }

  public getProvisionStartDate(): string {
    return this.provisionStartDate;
  }

  private getProvisionStatusWithHttpInfo(): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const requestUrl = `${basePath}/provisioningStatus`;
        let headers = this.defaultHeaders;
        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, requestUrl, requestOptions)
          .pipe(timeout(this.defaultProvisioningRequestTimeout));
      })
    );
  }

  private provisionOrganizationWithHttpInfo(): Observable<HttpResponse<any>> {
    let headers = this.defaultHeaders;
    return this.basePath$.pipe(
      take(1),

      switchMap(basePath => {
        const path = `${basePath}/odaProvisionOrganization`;

        let requestOptions = {
          headers,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(this.defaultProvisioningRequestTimeout));
      })
    );
  }

  private getDefaultHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }
}
