import { TopActiveUsersWidgetResponse } from '../models/top-active-users.server.response';
import * as fromTopActiveUser from './top-active-users.actions';

export interface TopActiveUsersState {
  data: TopActiveUsersWidgetResponse;
  loading: boolean;
  updating: boolean;
  expired: boolean;
  error: boolean;
}

export const initialState: TopActiveUsersState = {
  data: null,
  loading: false,
  updating: false,
  expired: false,
  error: false
};

export function reducer(
  state: TopActiveUsersState = initialState,
  action: fromTopActiveUser.TopActiveUsersAction
): TopActiveUsersState {
  switch (action.type) {
    case fromTopActiveUser.LOAD_TOP_ACTIVE_USER:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromTopActiveUser.UPDATE_TOP_ACTIVE_USERS_CONFIG:
      return {
        ...state,
        updating: true
      };
    case fromTopActiveUser.LOAD_TOP_ACTIVE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        expired: false,
        error: false
      };
    case fromTopActiveUser.UPDATE_TOP_ACTIVE_USERS_CONFIG_SUCCESS:
      return {
        ...state,
        updating: false,
        expired: true,
        error: false
      };
    case fromTopActiveUser.LOAD_TOP_ACTIVE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    case fromTopActiveUser.UPDATE_TOP_ACTIVE_USERS_CONFIG_FAIL:
      return {
        ...state,
        updating: false,
        error: true
      };
    default:
      return state;
  }
}
