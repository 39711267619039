import { OperatorType } from '@ondemand/core';

export const StringOperators: OperatorType[] = [
  OperatorType.Equals,
  OperatorType.NotEquals,
  OperatorType.Contains,
  OperatorType.NotContains,
  OperatorType.StartsWith,
  OperatorType.DoesNotStartWith,
  OperatorType.EndsWith,
  OperatorType.DoesNotEndWith
];

export const NumberOperators: OperatorType[] = [
  OperatorType.Equals,
  OperatorType.NotEquals,
  OperatorType.GreaterThan,
  OperatorType.GreaterThanOrEqualTo,
  OperatorType.LessThan,
  OperatorType.LessThanOrEqualTo
];

export const CrtiticalLevelOperators: OperatorType[] = [
  OperatorType.Equals,
  OperatorType.NotEquals
];
