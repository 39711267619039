/**
 * Query Builder Metadata Service
 * Service for getting metadata about events, supporting query builder functionality
 */
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { switchMap, take, map, timeout } from 'rxjs/operators';
import { HttpRequestMethod, OnDemandTokenHttp, Util } from '@ondemand/core';
import { ServiceDiscoveryService } from './service-discovery.service';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class EventFieldsService {

  public defaultHeaders: HttpHeaders = new HttpHeaders();
  private configuration = {
    withCredentials: false
  };
  private cachedFields: any;

  constructor(private http: OnDemandTokenHttp,
              private serviceDiscoveryService: ServiceDiscoveryService) {
  }

  /**
   * List event fields that are available for querying
   *
   * @summary Get field list
   */
  public getFields(extraHttpRequestParams?: any): Observable<any> {
    if (this.cachedFields) {
      return of(this.cachedFields);
    } else {
      return this.getFieldsWithHttpInfo(extraHttpRequestParams).pipe(
        map((response) => {
          if (response.status !== 200) {
            throw new Error('Invalid response from fields service');
          } else {
            let fields =  response.body;
            this.cachedFields = fields;
            return fields;
          }
        })
      );
    }
  }

  /**
   * Get field list
   * List event fields that are available for querying
   */
  public getFieldsWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService.getQueryUrl$().pipe(
      take(1),
      switchMap((basePath: string) => {
        const path = basePath + '/columns';
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('locale', Util.userLocale);
        let headers = this.defaultHeaders;

        let requestOptions = {
          headers,
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        if (extraHttpRequestParams) {
          requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
        }

        return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

}
