import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  SigninsWidgetResponse,
  SigninsConfigurationResponse,
  SigninsDataResponse
} from '../models/signins.models';
import * as fromFeature from '../../../reducers/index';

const signinsState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.signins
);

export const selectSigninsData = createSelector(
  signinsState,
  state => state.data
);

export const selectSigninsLoading = createSelector(
  signinsState,
  state => state.loading
);

export const selectSigninsUpdating = createSelector(
  signinsState,
  state => state.updating
);

export const selectSigninsExpired = createSelector(
  signinsState,
  state => state.expired
);

export const selectSigninsError = createSelector(
  signinsState,
  state => state.error
);
