
import { filter, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseChartType, BaseComponent } from '@ondemand/core';
import { Color } from 'ng2-charts';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import * as SearchPermissions from '../../../../models/audit-permissions.model';
import { SigninsChartTicksFormatService } from './services/sign-ins.chart.ticks-format.service';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import { AuditingDashboardResponse } from '../../models/auditing-dashboard.models';
import {
  SigninsWidgetResponse,
  SigninsConfigurationResponse,
  SigninsDataResponse,
  SigninsDataset
} from './models/signins.models';
import { SigninsState } from './state/sign-ins.reducer';
import {
  selectSigninsData,
  selectSigninsError,
  selectSigninsExpired,
  selectSigninsLoading,
  selectSigninsUpdating
} from './state/sign-ins.selectors';
import { LoadSignins, UpdateSignins } from './state/sign-ins.actions';
import { SigninsMouseEventService } from './services/sign-ins.mouse-event.services';
import { SigninsChartCustomTooltipService } from './services/sign-ins.chart.custom-tooltip.service';
import { ChartDataSets } from 'chart.js';
import { Observable } from 'rxjs';
import { cloneAndUnlockObject } from '../../../../../shared/utils/object.tools';

export interface SigninsComponentInterface {
  dataReady?: boolean;
  chartColors?: Color[];
  xAxisLabels?: string[];
  chartOptions?: any;
  chartDatasets?: any[];
  cardHeader?: string;
  dateHeader?: string;
  colorFailed?: string;
  colorSuccess?: string;
  legendItems?: any[];
  workloadLabels?: string[];
  selectedWorkloads?: string[];
  successDatasetSource?: SigninsDataset;
  failedDatasetSource?: SigninsDataset;
  userHasRunSearchPermissions?: boolean;
  getDataset(datasetIndex: number, dataPointIndex: number): SigninsDataset;
  getDataColor(datasetIndex: number, dataPointIndex: number): string;
}

@Component({
  selector: 'sign-ins-widget',
  templateUrl: './sign-ins.component.html',
  styleUrls: ['./sign-ins.component.scss']
})
export class SigninsComponent
  extends BaseComponent
  implements SigninsComponentInterface, OnInit {
  readonly cardHeight = '21rem';
  readonly chartType = BaseChartType.Line;
  readonly containerStyle = { width: '100%' };
  readonly chartPosition = { width: '100%', height: '14rem' };

  @Input() refreshObservable$: Observable<void>;

  data$ = this.store.select(selectSigninsData);
  dataLoading$ = this.store.select(selectSigninsLoading);
  dataUpdating$ = this.store.select(selectSigninsUpdating);
  dataExpired$ = this.store.select(selectSigninsExpired);
  dataError$ = this.store.select(selectSigninsError);

  dataReady: boolean;
  chartColors: Color[];
  xAxisLabels: string[];
  chartOptions: any;
  chartDatasets: ChartDataSets[];
  cardHeader: string;
  dateHeader: string;
  colorFailed: string;
  colorSuccess: string;
  legendItems: any[];
  workloadLabels: string[];
  selectedWorkloads: string[];
  successDatasetSource: SigninsDataset;
  failedDatasetSource: SigninsDataset;

  userHasRunSearchPermissions = true;

  constructor(
    private permissionsService: AuditModulePermissionsService,
    private localeStringsService: LocaleStringsService,
    private tickFormatService: SigninsChartTicksFormatService,
    private store: Store<SigninsState>,
    private mouseEventService: SigninsMouseEventService,
    private tooltipService: SigninsChartCustomTooltipService
  ) {
    super();
  }

  ngOnInit(): void {
    this.localeStringsService.strings$.pipe(
      takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.cardHeader = labels.auditing.pages.auditingDashboard.signins.title;
        this.dateHeader =
          labels.auditing.pages.auditingDashboard.signins.timeRangeDisplay;
        this.colorSuccess =
          labels.auditing.pages.auditingDashboard.signins.colorSuccess;
        this.colorFailed =
          labels.auditing.pages.auditingDashboard.signins.colorFailed;
      });

    this.data$.pipe(
      takeUntil(this.destructionSubject),
      filter(response => !!response)
    ).subscribe(data => this.initializeChartData(data));

    this.dataExpired$.pipe(takeUntil(this.destructionSubject)).subscribe(expired => {
      if (expired) {
        this.loadSignins();
      }
    });

    this.checkUserPermissions();

    this.loadSignins();
    this.listenToRefresh();
  }

  loadSignins(): void {
    // dispatch the action to get all config / data again
    this.store.dispatch(new LoadSignins());
  }

  onSelectedChanged(selectedTypeNames: string[]) {
    // dispatch the action for updating the selected types.
    this.store.dispatch(new UpdateSignins(selectedTypeNames));
  }

  checkUserPermissions(): void {
    this.userHasRunSearchPermissions =
      this.permissionsService.hasAnyOfPermissions([
        SearchPermissions.canRunPrivateSearch,
        SearchPermissions.canRunSharedSearches
      ]);
  }

  getDataset(datasetIndex: number, dataPointIndex: number): SigninsDataset {
    let selectedDataset: SigninsDataset;

    if (this.successDatasetSource && this.failedDatasetSource) {
      if (this.dataPointsOverlapping(dataPointIndex)) {
        selectedDataset = this.successDatasetSource;
      } else {
        selectedDataset =
          datasetIndex === 0
            ? this.successDatasetSource
            : this.failedDatasetSource;
      }
    } else if (this.successDatasetSource && datasetIndex === 0) {
      selectedDataset = this.successDatasetSource;
    } else if (this.failedDatasetSource && datasetIndex === 0) {
      selectedDataset = this.failedDatasetSource;
    }

    return selectedDataset;
  }

  getDataColor(datasetIndex: number, dataPointIndex: number): string {
    let selectedColor: string;

    if (this.successDatasetSource && this.failedDatasetSource) {
      if (this.dataPointsOverlapping(dataPointIndex)) {
        selectedColor = this.colorSuccess;
      } else {
        selectedColor =
          datasetIndex === 0 ? this.colorSuccess : this.colorFailed;
      }
    } else if (this.successDatasetSource && datasetIndex === 0) {
      selectedColor = this.colorSuccess;
    } else if (this.failedDatasetSource && datasetIndex === 0) {
      selectedColor = this.colorFailed;
    }

    return selectedColor;
  }

  private dataPointsOverlapping(index: number): boolean {
    if (this.successDatasetSource && this.failedDatasetSource) {
      const successValue = this.successDatasetSource.data[index];
      const failedValue = this.failedDatasetSource.data[index];
      return successValue === failedValue;
    }
    return false;
  }

  private listenToRefresh() {
    this.refreshObservable$.pipe(takeUntil(this.destructionSubject))
      .subscribe(_ => this.loadSignins());
  }

  private initializeChartData(dataFromRedux: SigninsWidgetResponse): void {
    // I believe the data from redux store actually called
    // Object.preventExtensions() to mark an object as no longer extensible.
    // This will cause errors in ChartJS, as it will attempt to extend the object.
    // The following line will remove the preventExtensions flag
    // and ChartJS will stop spitting out errors in Console.
    const data = cloneAndUnlockObject<SigninsWidgetResponse>(dataFromRedux);

    // this will let ngIf to destroy the chart
    this.dataReady = false;

    // allow some time for angular to do the change detection
    // that will remove the old chart with previous data
    // and then re-render a new chart
    setTimeout(() => {
      const datasets = data.data.signins.datasets;

      this.successDatasetSource = datasets.find(
        dataset => dataset.label === 'Successful'
      );

      this.failedDatasetSource = datasets.find(
        dataset => dataset.label !== 'Successful'
      );

      this.chartDatasets = [];
      this.chartColors = [];
      this.legendItems = [];
      let dataValues: number[] = [];

      if (this.successDatasetSource) {
        this.chartDatasets.push(
          this.generateDataSeries(this.successDatasetSource)
        );
        this.legendItems.push({
          label: this.successDatasetSource.label,
          color: this.colorSuccess
        });
        this.chartColors.push(
          this.generateColor(this.successDatasetSource.label)
        );
        dataValues.push(...this.successDatasetSource.data);
      }

      if (this.failedDatasetSource) {
        this.chartDatasets.push(
          this.generateDataSeries(this.failedDatasetSource)
        );
        this.legendItems.push({
          label: this.failedDatasetSource.label,
          color: this.colorFailed
        });
        this.chartColors.push(
          this.generateColor(this.failedDatasetSource.label)
        );
        dataValues.push(...this.failedDatasetSource.data);
      }

      if (this.chartDatasets.length > 0) {
        this.xAxisLabels = data.data.signins.labels;

        this.workloadLabels =
          data.configuration.signins.availableSigninTypes.map(
            siginInType => siginInType.name
          );

        this.selectedWorkloads = data.configuration.signins.selectedSigninTypes;

        this.chartOptions = this.generateOptions(
          Math.min(...dataValues),
          Math.max(...dataValues)
        );

        this.dataReady = true;
      }
    }, 50);
  }

  private generateDataSeries(sourceDataset: SigninsDataset): ChartDataSets {
    return {
      label: sourceDataset.label,
      data: sourceDataset.data,
      fill: false,
      lineTension: 0,
      showLine: true
    };
  }

  private generateColor(dataCategory: string): Color {
    const dataColor =
      dataCategory === 'Successful' ? this.colorSuccess : this.colorFailed;

    return {
      borderColor: dataColor,
      borderWidth: 2,
      pointStyle: 'circle',
      pointBackgroundColor: '#ffffff',
      pointBorderColor: dataColor,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2
    };
  }

  private generateOptions(minValue: number, maxValue: number): any {
    const maxNumTicks = 5;

    if (minValue === 0 && maxValue === 0) {
      // override the value, so we can display the data
      maxValue = 5;
    } else if (minValue === maxValue) {
      let quarter = Math.floor(maxValue / 4);
      minValue = maxValue - quarter;
      maxValue = maxValue + quarter;
    }

    const [niceStepSize, niceMin, niceMax] =
      this.tickFormatService.calculateTicks(maxNumTicks, minValue, maxValue);

    let showZero = true;
    if (niceMin > 0) {
      showZero = false;
    }

    return {
      responsive: true,
      scaleShowVerticalLines: false,
      maintainAspectRatio: false,
      title: {},
      hover: {
        mode: 'point'
      },
      legend: { display: false },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: true
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false
            },
            ticks: {
              suggestedMax: niceMax,
              suggestedMin: niceMin,
              precision: 0,
              beginAtZero: showZero,
              stepSize: niceStepSize
            }
          }
        ]
      },
      tooltips: {
        enabled: false,
        mode: 'nearest',
        custom: (tooltipModel: any) =>
          this.tooltipService.customTooltipHandler(tooltipModel, this)
      },
      events: ['click', 'mousemove', 'mouseout'],
      onClick: (event: any, chartElement: any[]) =>
        this.mouseEventService.clickHandler(event, chartElement, this),
      onHover: (event: any, chartElement: any[]) =>
        this.mouseEventService.hoverHandler(event, chartElement, this)
    };
  }
}
