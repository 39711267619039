/**
 * Route guard to help users from cancelling export search result.
 */
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AppFacadeService } from '@ondemand/core';
import { LocaleStringsService } from '../services/locale-strings.service';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { ExportQueryResultsService } from '../services/export-query-results.service';

@Injectable()
export class ExportQueryResultsGuard implements CanDeactivate<any> {
  constructor(
    private facade: AppFacadeService,
    private localeStrings: LocaleStringsService,
    private exportQueryResultsService: ExportQueryResultsService
  ) {}

  async canDeactivate(
    _component: any,
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    _nextState: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (this.exportQueryResultsService.getExportQueryResultsStatus()) {
        this.facade
          .confirm(
            await this.localeStrings
              .string$('auditing.exports.cancelConfirmationTitle').pipe(
              take(1))
              .toPromise(),
            await this.localeStrings
              .string$('auditing.exports.cancelConfirmationDescription').pipe(
              take(1))
              .toPromise()
          )
          .then(
            selection => {
              if (selection === true) {
                this.exportQueryResultsService.setExportQueryResultsStatus(
                  false
                );
              }
              resolve(selection);
            },
            () => {
              resolve(true);
            }
          );
      } else {
        this.exportQueryResultsService.setExportQueryResultsStatus(false);
        resolve(true);
      }
    });
  }
}
