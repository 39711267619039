import { switchMap, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ServiceDiscoveryService } from '../../auditing/services/service-discovery.service';
import { StaticIndicatorID, WidgetID, WidgetInformation } from '../../auditing/modules/auditing-dashboard/models/widget-info';
import { defaultHttpRequestTimeout } from '../../auditing/util/constants';
import { UpdateWidgetConfiguration } from '../models/update-widget-configuration';

@Injectable()
export class WidgetService {
  private basePath$: Observable<string>;

  constructor(
    private http: OnDemandTokenHttp,
    serviceDiscoveryService: ServiceDiscoveryService
  ) {
    this.basePath$ = serviceDiscoveryService.getDashboardUrl$();
  }

  public getWidgetListWithHttpInfo(): Observable<
    HttpResponse<WidgetInformation[]>
  > {
    return this.basePath$.pipe(
      take(1),
      switchMap((basePath: string) => {
        const path = `${basePath}/widgets`;
        const requestOptions = {
          headers: this.getDefaultHeaders(),
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  public getWidgetDataWithHttpInfo(
    widgetId: WidgetID | StaticIndicatorID, refresh: boolean = false
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap((basePath: string) => {
        const path = `${basePath}/widgets/${widgetId}`;

        // Counterintuitively, the `getTimezoneOffset` function returns a positive
        // number for time zones *behind* UTC and negative number for time zones
        // *ahead* of UTC, so we flip the sign for our ISO string purposes
        const queryParameters = new HttpParams()
          .set('timeZoneOffset', String(new Date().getTimezoneOffset() * -1))
          .set('refresh', `${refresh}`);

        const requestOptions = {
          headers: this.getDefaultHeaders(),
          params: queryParameters,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.GET, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  public putWidgetSettingsWithHttpInfo(
    widgetId: WidgetID,
    widgetConfig: UpdateWidgetConfiguration
  ): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(
      take(1),
      switchMap(basePath => {
        const path = `${basePath}/widgets/${widgetId}/configuration`;
        const requestOptions = {
          headers: this.getDefaultHeaders(),
          body: widgetConfig === null ? '' : widgetConfig,
          observe: 'response'
        };

        return this.http
          .requestHttp(HttpRequestMethod.PUT, path, requestOptions)
          .pipe(timeout(defaultHttpRequestTimeout));
      })
    );
  }

  private getDefaultHeaders() {
    const headers = new HttpHeaders();
    return headers.set('Content-Type', 'application/json');
  }
}
