<div class="horizontal-box">
  <div class="legend-title-box">
    <span>{{legendTitle}}</span>
  </div>
  <div class="legends-box">
    <ng-container *ngFor="let item of legendDatas">
      <oda-check-legend-item-v1
        [legendData]="item"
        (checkChanged)="onCheckChange($event)"
      ></oda-check-legend-item-v1>
    </ng-container>
  </div>
</div>

