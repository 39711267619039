<div class="results-preview-container" #datatableContainer (resized)="onSizeChange()">

  <div class="results-summary">
    <span *ngIf="loadingResults" class="loading-results">
      <materialize-spinner [size]="'small'"></materialize-spinner>
      <span>{{applicationStrings.loadingSearchResults}}</span>
    </span>
    <ng-container *ngIf="!loadingResults">

      <ng-container *ngIf="!loadingError">
        <span *ngIf="!hasResults">{{applicationStrings.noSearchResults}}</span>

        <div class="left num-results-text">
          <span *ngIf="hasResults">{{getResultsMessage()}}</span>
        </div>
      </ng-container>

    </ng-container>
  </div>

  <error-message class="query-failed-message" *ngIf="!loadingResults && loadingError">{{loadingError}}</error-message>

  <div *ngIf="hasResults && !loadingError">
    <ngx-datatable id="results-preview"
      class="on-demand"
      [rows]="datatableRows"
      [columns]="datatableColumns"
      [columnMode]="columnMode"
      [footerHeight]="0"
      [scrollbarH]="true"
      [externalSorting]="true"
      [sorts]="results.sorts"
      (activate)="onCellEvent($event)"
      (reorder)="onReorder($event)"
      (sort)="onSort($event)"
    >
    </ngx-datatable>

  </div>


</div>

<!-- <ng-template #headerCellTemplate let-column="column">
  <div class="column-name" [attr.data-column-id]="column.prop" title="{{column.name}}">{{column.name}}</div>
</ng-template> -->
