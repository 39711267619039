<internal-loading [isLoading]="loading"></internal-loading>

<div class="retention-container">

  <error-message *ngIf="errorMessageReference">{{ errorMessageReference | localize }}</error-message>

  <div *ngIf="eventRetentionTableEntries">

    <div *ngIf="eventRetentionTableEntries.length > 0; else noTypes">
      <p class="retention-detail-info">{{ 'auditing.retentionIntro' | localize }}</p>

      <table class="retention-detail-table">
        <thead>
          <tr>
            <th>{{ 'auditing.retentionTableHeaderService' | localize }}</th>
            <th>{{ 'auditing.retentionTableHeaderEventType' | localize }}</th>
            <th>{{ 'auditing.retentionTableHeaderSubscriptionTier' | localize }}</th>
            <th>{{ 'auditing.retentionTableHeaderRetentionPeriod' | localize }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let eventType of eventRetentionTableEntries">
            <td>{{ eventType.serviceName }} </td>
            <td>
              {{ eventType.eventTypeName }}
              <ng-container *ngIf="eventType.eventTypeDescription">
                <span class="tooltipped event-description" materialize="tooltip" attr.data-tooltip="{{ eventType.eventTypeDescription }}">
                  <edc-icon name="status-info"></edc-icon>
                </span>
              </ng-container>
            </td>
            <td>
              <span *ngIf="eventType.subscriptionTypeLabel">{{ eventType.subscriptionTypeLabel }}</span>
            </td>
            <td>
              {{ eventType.retentionPeriodLabel }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <ng-template #noTypes>
      <p>{{ 'auditing.noEventTypes' | localize }}</p>
    </ng-template>

  </div>

</div>
