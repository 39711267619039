import { Injectable } from '@angular/core';
import { AuditModulePermissionsService } from '../../../../auditing/services/audit-module-permissions.service';
import * as ODApermissions from '../../../../auditing/models/audit-permissions.model';
import { ActiveQueryService } from '../../../../auditing/services/active-query.service';

@Injectable()
export class ChartPermissionsService {
  constructor(
    private permissionsService: AuditModulePermissionsService,
    private activeQueryService: ActiveQueryService
  ) {}

  // this should be common to all charts
  // so, I made it a reusable component for other charts
  // it can be useful when refactoring
  userHasChartDrillDownPermission(): boolean {
    const query = this.activeQueryService.getQuery().activeQuery;
    if (!query.id || (!query.isShared && query.createdBy === 'user')) {
      // an adhoc search or a private search
      return this.permissionsService.hasAnyOfPermissions([
        ODApermissions.canRunPrivateSearch
      ]);
    } else if (query.isShared || query.createdBy === 'system') {
      return this.permissionsService.hasAllOfPermissions([
        ODApermissions.canRunPrivateSearch,
        ODApermissions.canRunSharedSearches
      ]);
    }
    return false;
  }

  userHasChartEditingPermissions(): boolean {
    // according to design doc
    // this is currently the same set of permissions
    // used for drilldown
    return this.userHasChartDrillDownPermission();
  }
}
