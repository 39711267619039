<qod-sheet
  variant="large"
  [isOpen]="isOpen"
  [heading]="flyoutHeader"
  [subHeading]="flyoutDescription"
  [ignoreBackdropClick]="true"
  (close)="cancelEditColumns()">

  <div class="content-container" sheet-content>
    <ul class="tabs">

      <li id="editColumnTab" class="tab">
        <a  [ngClass]="{'noClickAllowed': this.visualizeEditInProgress }"
            (click) = "onTabClick(this.flyoutColumnTabLabel)"
            [class.active] = "columnTabSelected">
            {{flyoutColumnTabLabel}}
        </a>
      </li>

      <li id="editVisualizationTab" class="tab">
        <a  [ngClass]="{'noClickAllowed': this.columnEditInProgress }"
            (click) = "onTabClick(this.flyoutVisualizationTabLabel)"
            [class.active] = "visualizationTabSelected">
            {{flyoutVisualizationTabLabel}}
        </a>
      </li>

    </ul>

      <hr/>

    <ng-container *ngIf="isOpen && columns && columns.length &gt; 0 && fields && fields.length &gt; 0">
      <ng-container *ngIf="columnTabSelected">
        <column-editor
          [projectionFields]="projectionFields"
          [allFields]="fields"
          [groupByFieldDisplayName] ="groupByDisplayName"
          (columnsUpdate)="onColumnsUpdated($event)"
          (statusUpdate)="onColumnEditorStatusChange($event)" >
        </column-editor>
      </ng-container>
      <ng-container *ngIf="visualizationTabSelected && hasVisualizationTabPermission">
        <visualization-editor
          [projectionFields]="projectionFields"
          [visualizeParams]="visualizeParams"
          (paramsUpdate) = "onVisualizeParamsUpdated($event)"
          (statusUpdate) = "onVisualizeEditorStatusUpdated($event)">
        </visualization-editor>
      </ng-container>
    </ng-container>
    <div class="footer-content">
      <div class="divider"></div>
      <div class="action-button-container-outer">
      <div class="action-buttons">
        <button
          type="button"
          class="btn submit-save-columns-button"
          [disabled]="disableApplyButton"
          (click)="saveEditColumns()"
        >{{ applyButtonLabel }}</button>
        <button
          type="button"
          class="btn-secondary cancel-save-columns-button"
          data-test="submit-save-columns"
          (click)="cancelEditColumns()"
        >{{ cancelButtonLabel }}</button>
      </div>
    </div>
    </div>
  </div>
</qod-sheet>
