import { UpdateTopActiveUsersSettings } from '../../../../../../shared/models/update-widget-configuration';
import { TopActiveUsersWidgetResponse } from '../models/top-active-users.server.response';

export const LOAD_TOP_ACTIVE_USER = '[Auditing Dashboard] Load top-active-user';
export const UPDATE_TOP_ACTIVE_USERS_CONFIG = '[Auditing Dashboard] Update top-active-user';

export const LOAD_TOP_ACTIVE_USER_FAIL = '[Auditing Dashboard] Load top-active-user Fail';
export const UPDATE_TOP_ACTIVE_USERS_CONFIG_FAIL = '[Auditing Dashboard] Update top-active-user Fail';

export const LOAD_TOP_ACTIVE_USER_SUCCESS =
  '[Auditing Dashboard] Load top-active-user Success';
export const UPDATE_TOP_ACTIVE_USERS_CONFIG_SUCCESS =
  '[Auditing Dashboard] Update top-active-user Success';

export class LoadTopActiveUsers {
  readonly type = LOAD_TOP_ACTIVE_USER;
}

// eslint-disable-next-line max-classes-per-file
export class LoadTopActiveUsersSuccess {
  readonly type = LOAD_TOP_ACTIVE_USER_SUCCESS;
  constructor(public payload: TopActiveUsersWidgetResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadTopActiveUsersFail {
  readonly type = LOAD_TOP_ACTIVE_USER_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTopActiveUsersConfig {
  readonly type = UPDATE_TOP_ACTIVE_USERS_CONFIG;
  constructor(public payload: UpdateTopActiveUsersSettings) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTopActiveUsersConfigSuccess {
  readonly type = UPDATE_TOP_ACTIVE_USERS_CONFIG_SUCCESS;
}

// eslint-disable-next-line max-classes-per-file
export class UpdateTopActiveUsersConfigFail {
  readonly type = UPDATE_TOP_ACTIVE_USERS_CONFIG_FAIL;
  constructor(public payload: any) {}
}

export type TopActiveUsersAction =
  | LoadTopActiveUsers
  | LoadTopActiveUsersSuccess
  | LoadTopActiveUsersFail
  | UpdateTopActiveUsersConfig
  | UpdateTopActiveUsersConfigSuccess
  | UpdateTopActiveUsersConfigFail;
