export enum ChartQueryIntervals {
  Hour = '1h',
  Day = '1d',
  Week = '7d',
  Month = '30d',
  Year = '365d',
}

export interface TopXGroupOptions {
  /** The max number of group */
  topXGroupCount?: number;
  /** The column name used for filtering top group */
  topXGroupName?: string;
  /** If true, add others column */
  addOthers?: boolean;
}

export interface SummarizeOptionsBinGrouping {
  /** Value for bin */
  value?: string;
  /** Value of bin size */
  roundTo?: string;
  /** Alias value for bin result column */
  alias?: string;
}

export interface SummarizeOptionsBinAtGrouping {
  /** Expression for bin_at */
  expression?: string;
  /** Value of bin_at size */
  binSize?: string;
  /** FixedPoint datetime value for bin_at */
  fixedPoint?: string;
  /** Alias value for bin_at result column */
  alias?: string;
}

export interface SummarizeOptionsColumnGrouping {
  /** Column name to be grouped by */
  name?: string;
  /** If true, convert the column result to lowercase column */
  toLower?: boolean;
}

export interface SummarizeOptionsGroupingItem {
  /** values for bin grouping */
  bin?: SummarizeOptionsBinGrouping;
  /** values for bin_at grouping */
  binAt?: SummarizeOptionsBinAtGrouping;
  /** value for column grouping */
  column?: SummarizeOptionsColumnGrouping;
}

export interface SummarizeOptionsCountAggregation {
  /** Alias value for aggregation result column */
  alias?: string;
}

export interface SummarizeOptionsAggregationItem {
  count?: SummarizeOptionsCountAggregation;
}

export interface SummarizeOptions {
  groupings?: SummarizeOptionsGroupingItem[];
  aggregations?: SummarizeOptionsAggregationItem[];
}

export interface FilterOptions {
  /** Number of top x results to filter. */
  topXFilter?: number;
  /** The top x results to be filtered based on this column name. */
  topXFilterColumn?: string;
}

export interface BarChartOptions {
  /** Chart ID for this chart */
  id: string;
  chartQueryOptions?: SummarizeOptions;
  /** Parameters for Top X column grouping */
  topXGroupOptions?: TopXGroupOptions;
}

export interface DonutChartOptions {
  /** Chart ID for this chart */
  id: string;
  /** Column name containing the chart labels */
  labelColumnName: string;
  /** Column name containing the chart data */
  dataColumnName: string;
  /** Whether the donut chart should show a total count in the middle */
  showTotal?: boolean;
  /** Column name containing the chart data */
  totalTextFormat?: string;
  chartQueryOptions?: SummarizeOptions;
  /** Parameters for Top X column grouping */
  topXGroupOptions?: TopXGroupOptions;
}

export interface TimeSeriesADChartOptions {
  /** Chart ID of time series anomaly detection option */
  id: string;
  /** Column to detect anomalies on different time lines */
  timestampColumn: string;
  /** The time period in hours to use to group all the events */
  detectionTimeFrame: number;
  /** Time zone offset in minutes */
  timeZoneOffset?: number;
  /** Anomaly threshold value */
  threshold?: number;
  /** An integer controlling the seasonal analysis */
  seasonality?: number;
  /** The trend analysis method */
  trendMethod?: string;
}

export interface TimeSeriesChartOptions {
  /** Chart ID of this chart */
  id: string;
  /** Options to filter query results. */
  filterOptions: FilterOptions;
  /** The time period to use as time series interval */
  seriesInterval: ChartQueryIntervals;
  /** Time zone offset in minutes */
  timeZoneOffset?: number;
  /** Max x-axis data point to be reported */
  maxBins?: number;
}

export interface ChartPosition {
  top: string;
  left: string;
  /** width of canvas in rem */
  width: string;
  /** height of canvas in rem */
  height: string;
}
