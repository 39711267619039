<internal-loading [isLoading]="loadingData"></internal-loading>

<modal-window #editorModal [params]="modalParams" *ngIf="modalParams">
  <div modal-content class="alert-rule-editor-modal">
    <p class="disabled-warning" *ngIf="alertRule && !alertRule.enabled">
      {{ 'auditing.alertDisabledWarning' | localize }}
    </p>

    <error-message *ngIf="error">{{ error }}</error-message>

    <form
      #alertRuleForm="ngForm"
      (ngSubmit)="onSubmit()"
      (change)="onFormChange()"
    >
      <p class="select-private-alert-plans-intro" *ngIf="!isSharedAlertRuleType()">
        {{ 'auditing.selectPrivatePlansDescription' | localize }}
      </p>
      <p class="select-shared-alert-plans-intro" *ngIf="isSharedAlertRuleType()">
        {{ 'auditing.selectSharedPlansDescription' | localize }}
      </p>
      <ul class="alert-plan-list">
        <li
          class="alert-plan"
          *ngFor="let listItem of alertPlanListItems; index as i"
        >
          <input
            type="checkbox"
            id="alert-list-{{ i }}"
            name="alert-list-{{ i }}"
            [(ngModel)]="listItem.selected"
          />
          <label for="alert-list-{{ i }}">{{ listItem.alertPlan.name }}</label>
        </li>

        <li
          class="new-alert-plan"
          *ngIf="userCanCreateNewAlertPlan()"
        >
          <input
            type="checkbox"
            id="new-alert-plan-option"
            name="new-alert-plan-option"
            [(ngModel)]="newPlanOptions.selected"
          />
          <label for="new-alert-plan-option" *ngIf="!isSharedAlertRuleType()">
            <input
              type="text"
              name="new-alert-plan-name"
              [(ngModel)]="newPlanOptions.name"
              placeholder="{{ 'auditing.newPrivateAlertPlanOption' | localize }}"
              #newAlertPlanName
              (keyup)="handleNewPlanName(newAlertPlanName.value)"
            />
          </label>
          <label for="new-alert-plan-option" *ngIf="isSharedAlertRuleType()">
            <input
              type="text"
              name="new-alert-plan-name"
              [(ngModel)]="newPlanOptions.name"
              placeholder="{{ 'auditing.newSharedAlertPlanOption' | localize }}"
              #newAlertPlanName
              (keyup)="handleNewPlanName(newAlertPlanName.value)"
            />
          </label>
        </li>
      </ul>
    </form>

    <p>{{ numPlansSelected }} {{ 'auditing.numPlansSelected' | localize }}</p>
  </div>

  <div modal-footer>
    <div class="action-button-container-outer">
      <div class="action-buttons right">
        <button
          type="button"
          class="btn save-button"
          [disabled]="
            !alertRuleForm.valid ||
            saving ||
            errorLoadingData ||
            numPlansSelected === 0
          "
          (click)="onSubmit()"
        >
          {{ 'auditing.saveButtonLabel' | localize }}
        </button>
        <button
          type="button"
          class="btn-flat cancel-button"
          (click)="onClickCancel()"
        >
          {{ 'auditing.cancelButtonLabel' | localize }}
        </button>
      </div>
    </div>
  </div>
</modal-window>
