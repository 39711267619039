import { Injectable } from '@angular/core';
import { Query } from '../../../../../auditing/models/query.model';
import { QueryClause } from '../../../../../auditing/models/query-clause';
import { ActiveQueryService } from '../../../../../auditing/services/active-query.service';
import { ChartQueryIntervals } from '../../../../../auditing/models/chart-options/chart-models';
import { LegendDataItem } from '../../oda-donut-chart/legend/legend-model';
import { TimeInMilliseconds } from '../../../../../auditing/util/date-utils';
import { YAxisBoundsForXAxisLabelClick } from '../timeseries-constant';
import { OthersColumnID } from '../../models/chart-constants';
import { QueryClauseOperator } from '../../../../../shared/models/query-constants';

@Injectable()
export class TimeseriesChartQueryMouseEventService {
  constructor(
    private activeQueryService: ActiveQueryService
  ) {}

  // Entry point for chart's onClick handler. If valid, query is modified and timeseries component emits an event to results.
  mouseClickEventHandler(
    chart: any,
    chartLegendItems: LegendDataItem[],
    deletedLegendItems: string[],
    event: PointerEvent,
    query: Query,
    timeSlots: string[],
    chartElement: any,
    availableValues: string[]) {
    const activeElements = chart.getElementsAtXAxis(event);
    const remainingChartLegendItems = chartLegendItems.filter(item => !deletedLegendItems.includes(item.label));
    if (activeElements && activeElements.length > 0) {
      const startTime = new Date(activeElements[0]._xScale._ticks[activeElements[0]._index].value).toISOString();
      const yOffsetClick = event.offsetY;

      // These are the y-axis bounds where the labels are located (generally speaking).
      if (yOffsetClick <= YAxisBoundsForXAxisLabelClick.upperBound && yOffsetClick > YAxisBoundsForXAxisLabelClick.lowerBound) {
        // If only one time slice is left for hourly display, we can't drill down any further.
        if (!(activeElements[0]._xScale.ticks.length === 1 &&
          query.q.charts[0].timeSeries.seriesInterval === ChartQueryIntervals.Hour) &&
          activeElements[0]._xScale.labelRotation === 0) {
          const newQuery = this.xAxisClickHandlerForTimeseriesChart(query, deletedLegendItems, remainingChartLegendItems, startTime);
          return newQuery;
        }
      } else {
        const clickResult = this.pointClickHandlerForTimeseriesChart(
          event,
          chartElement,
          timeSlots,
          query,
          chart,
          chartLegendItems,
          availableValues
        );

        if (clickResult) {
          return clickResult;
        }
      }
    }
    return null;
  }

  // Triggers an ad-hoc query when user clicks on a specific point on the chart
  pointClickHandlerForTimeseriesChart(
    event: PointerEvent,
    chartElement: any[],
    timeSlots: string[],
    query: Query,
    chart: Chart,
    selectedLegendItems: LegendDataItem[],
    availableValues: any
  ) {
    let newQuery: Query;
    if (chartElement.length > 0) {
      const chartElemClicked = this.getDatasetAtClickedPoint(event, chartElement);

      // In case clicked area of the chart was not a valid point, we just return to avoid indexing errors.
      if (!chartElemClicked ||
        (timeSlots.length === 1 && query.q.charts[0].timeSeries.seriesInterval === ChartQueryIntervals.Hour)
        ) {
        newQuery = null;
      } else {
        const selectedTimestamp = timeSlots[chartElemClicked._index];

        if (selectedTimestamp) {
          // To account for predefined boolean values, we need to get a list of possible values from API
          const selectedDataset = chart.data.datasets[chartElemClicked._datasetIndex];
          const isOthersSelected = selectedDataset.label === OthersColumnID;
          // dataset index 10 is the 'others' category, so we filter everything in the top 10
          if (chartElemClicked._datasetIndex === 10 || isOthersSelected) {
            // This ensures we have all legend items except 'others'
            const allLegendItemsWithOutOthersItems =
                selectedLegendItems.filter(item => item.label !== OthersColumnID);

            // only checked legend items will be filtered out.
            const checkedLegendItemsExceptOthers = allLegendItemsWithOutOthersItems.filter(item => item.checked);

            if (checkedLegendItemsExceptOthers && checkedLegendItemsExceptOthers.length > 0) {
            const checkedLegendItemLabelExceptOthers = checkedLegendItemsExceptOthers.map(item => item.value);
            const groupingColumn = query.q.charts[0].timeSeries.filterOptions.topXFilterColumn;
            let drilldownClause: QueryClause = null;

            // Find out if clause ('not_in') already exist. If yes, the clause is updated with existing and new filter values.
            // Otherwise, new clause is created.
            const clauseIndex = query.q.clauses.findIndex(
              clause =>
                clause.field === groupingColumn &&
                clause.operator === QueryClauseOperator.NOT_IN
            );

            if (clauseIndex < 0) {
              drilldownClause = new QueryClause({
                field: groupingColumn,
                operator: QueryClauseOperator.NOT_IN,
                value: null,
                values: checkedLegendItemLabelExceptOthers
              });
            } else {
              drilldownClause = query.q.clauses[clauseIndex];
              drilldownClause.values = [
                ...drilldownClause.values,
                ...checkedLegendItemLabelExceptOthers
              ];
            }

            if (clauseIndex < 0) {
              query.q.clauses.push(drilldownClause);
            } else {
              query.q.clauses[clauseIndex] = drilldownClause;
            }
          }
            newQuery = this.createQueryWithUserParams(selectedTimestamp, query);
          } else {
            // To account for predefined boolean values, we need to get a list of possible values from API
            if (availableValues) {
              const value = availableValues.filter((val: any) => val.label === selectedDataset.label);

              if (value.length === 1) {
                selectedDataset.label = value[0].value.toString();
              }
            }
            this.addClauseForSingleValue(selectedDataset, query);
            newQuery = this.createQueryWithUserParams(selectedTimestamp, query);
          }
        }
      }
    }
    return newQuery;
  }

  // When users click on x-axis, we scope to next drill-down value and include all columns/legend items that are selected.
  xAxisClickHandlerForTimeseriesChart(
    query: Query,
    deletedLegendItems: string[],
    selectedLegendItems: LegendDataItem[],
    startTime: string
  ): Query {
    // If any legend items are unchecked, we create a clause to remove them.
    if (deletedLegendItems.length > 0) {
      query.q.clauses.push(
        this.createClauseForSelectedLegendItems(
          deletedLegendItems,
          selectedLegendItems,
          query.q.charts[0].timeSeries.filterOptions.topXFilterColumn));
    }

    return this.createQueryWithUserParams(startTime, query);
  }

  private createQueryWithUserParams(selectedTimestamp: string, query: Query): Query {
    let endScopeDate = new Date(selectedTimestamp);
    const adhocQuery = this.modifyAdHocQueryWithUserOptions(query, endScopeDate);
    this.activeQueryService.setQuery(adhocQuery, adhocQuery);
    return adhocQuery;
  }

  // Adds a query clause to scope to a single column. During drilldown, it's possible this clause already exists.
  private addClauseForSingleValue(selectedDatasetLabel: Chart.ChartDataSets, query: Query): void {
    let matchingClause = false;
    const newClause = new QueryClause({
      field: query.q.charts[0].timeSeries.filterOptions.topXFilterColumn,
      operator: (
                  selectedDatasetLabel.label === 'true' || selectedDatasetLabel.label === 'false'
                ) ? QueryClauseOperator.EQUALS_NUMBER : QueryClauseOperator.EQUALS,
      value: selectedDatasetLabel.label
    });
    query.q.clauses.forEach(clause => {
      if (clause.value === newClause.value && clause.operator === newClause.operator) {
        matchingClause = true;
        return;
      }
    });

    if (!matchingClause) {
      query.q.clauses.push(newClause);
    }
  }

  // This may be empty - active chart elements only exist if a point was clicked.
  private getDatasetAtClickedPoint(event: PointerEvent, chartElement: any[]): any {
    const eventY = event.offsetY;

    return chartElement.find(elem => {
      const lowY = elem._view.y - elem._view.radius;
      const hiY = elem._view.y + elem._view.radius;
      return eventY >= lowY && eventY <= hiY;
    });
  }

  private modifyAdHocQueryWithUserOptions(query: Query, startScopeDate: Date): Query {
    // Converts time interval into clause for our desired time span (i.e. hour, day, month etc.)
    const timeframe: number = this.replaceTimeInterval(query.q.charts[0].timeSeries.seriesInterval);
    let endScopeDate = new Date(
      startScopeDate.getTime() + timeframe
    ).toISOString();

    let clause = new QueryClause({
      field: 'Date',
      operator: 'between_date',
      value: startScopeDate.toISOString() + '_' + endScopeDate
    });

    query.q.clauses = this.replaceTimeClause(query.q.clauses, clause);

    // Drill down to the next smallest time-interval (i.e. day to hour, month to week, etc.)
    query.q.charts[0].timeSeries.seriesInterval = this.drillDownChartQueryInterval(query.q.charts[0].timeSeries.seriesInterval);

    // To keep the ad-hoc query relevant, we show the user their selected options as a sub-text of their original query.
    query.name = this.updateQueryNameWithSelectedDate(query.name, startScopeDate);

    return new Query({
      name: query.name,
      id: query.id,
      q: query.q,
      isShared: false,
      hideCharts: false
    });
  }

  // We update the query name with the selected scope. If there's already a date in the title (drill-down), we replace it.
  private updateQueryNameWithSelectedDate(currentQueryName: string, endScopeDate: Date): string {
    const queryNameSplit = currentQueryName.split('(');

    if (queryNameSplit[queryNameSplit.length - 1].includes('AM') || queryNameSplit[queryNameSplit.length - 1].includes('PM')) {
      const potentialDate = queryNameSplit[queryNameSplit.length - 1].substring(0, queryNameSplit[queryNameSplit.length - 1].length - 2);
      const dateString = new Date(potentialDate);

      if (dateString) {
        queryNameSplit.pop();
        currentQueryName = queryNameSplit.join('(');
      }
    }

    return currentQueryName +
    ' (' +
    endScopeDate.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      hour12: true
    }) +
    ')';
  }

  /**If some legend items have been unchecked, this ensures only remaining items are included.
   * Alternatively, if "others" is unchecked, we need to reverse the logic to only include selected items.
   */
  private createClauseForSelectedLegendItems(
    deletedLegendItems: string[],
    selectedLegendItems: LegendDataItem[],
    groupingColumn: string
    ): QueryClause {
    if (deletedLegendItems.includes(OthersColumnID)) {
      let clauseItems: string[] = [];
      selectedLegendItems.forEach(item => {
        if (!deletedLegendItems.includes(item.label) && item.label !== OthersColumnID) {
          clauseItems.push(item.label);
        }
      });

      return new QueryClause({
        field: groupingColumn,
        operator: QueryClauseOperator.IN,
        values: clauseItems
      });
    } else {
      return new QueryClause({
        field: groupingColumn,
        operator: QueryClauseOperator.NOT_IN,
        values: selectedLegendItems.map(item => item.label)
      });
    }
  }

  /**
   * Since we're scoping the ad-hoc search to the single data point selected, we want to remove all other
   * time-related clauses.
   */
  private replaceTimeClause(clauses: QueryClause[], replacement: QueryClause): QueryClause[] {
    let newList: QueryClause[] = [];
    for (let clause of clauses) {
      if (
        clause.operator !== QueryClauseOperator.BETWEEN_DATE &&
        clause.operator !== QueryClauseOperator.DURING_LAST
      ) {
        newList.push(clause);
      }
    }

    newList.push(replacement);
    return newList;
  }

  // Converts the chart query time interval into milliseconds to translate into a datetime clause in the query.
  private replaceTimeInterval(val: ChartQueryIntervals): number {
    switch (val) {
      case ChartQueryIntervals.Hour:
        return TimeInMilliseconds.Hour;
      case ChartQueryIntervals.Day:
        return TimeInMilliseconds.Day;
      case ChartQueryIntervals.Week:
        return TimeInMilliseconds.Week;
      case ChartQueryIntervals.Month:
        return TimeInMilliseconds.Month;
      case ChartQueryIntervals.Year:
        return TimeInMilliseconds.Year;
      default:
        return TimeInMilliseconds.Hour;
    }
  }

  // If a user clicks on a point/x-axis, we drill down to the next smallest time interval (except hour)
  private drillDownChartQueryInterval(val: ChartQueryIntervals): ChartQueryIntervals {
    switch (val) {
      case ChartQueryIntervals.Hour:
        return ChartQueryIntervals.Hour;
      case ChartQueryIntervals.Day:
        return ChartQueryIntervals.Hour;
      case ChartQueryIntervals.Week:
        return ChartQueryIntervals.Day;
      case ChartQueryIntervals.Month:
        return ChartQueryIntervals.Week;
      case ChartQueryIntervals.Year:
        return ChartQueryIntervals.Month;
      default:
        return ChartQueryIntervals.Day;
    }
  }

  // Summarizing by hour only displays time, i.e. 9:00 PM. So we add the day/month/year
  private parseHourTimeSpanForQuery(query: Query, selectedXAxis: string[]): string {
    const queryDateClause = query.q.clauses.find(clause => clause.field === 'Date');
    const hourArgs = selectedXAxis[1].split(' ');
    const hoursAndMinutes = hourArgs[0].split(':');

    if (hourArgs[1] === 'PM') {
      const hours = parseInt(hoursAndMinutes[0], 10);
      hoursAndMinutes[0] = (hours + 12).toString();
    }

    let queryDate: Date;

    // If during last, we know it's the current year/month
    if (queryDateClause.operator === QueryClauseOperator.DURING_LAST) {
      queryDate = new Date();
    } else {
      queryDate = this.getStartTimeClause(query, selectedXAxis[0]);
    }

    queryDate.setHours(parseInt(hoursAndMinutes[0], 10), parseInt(hoursAndMinutes[1], 10), 0, 0);
    return queryDate.toString();
  }

  private getStartTimeClause(query: Query, selectedXAxis: any) {
    const queryDateClause = query.q.clauses.find(clause => clause.field === 'Date');

    if (queryDateClause.operator === QueryClauseOperator.DURING_LAST) {
      return new Date();
    }

    const dateValue = queryDateClause.value.split('_');
    const queryDate = new Date(dateValue[0]);
    const month = queryDate.getMonth();
    const selectedMonth = new Date(selectedXAxis).getMonth();

    // If the month we picked is a lower value than the start time, we assume it's the following year.
    if (selectedMonth < month) {
      queryDate.setFullYear(queryDate.getFullYear() + 1);
    }
    return queryDate;
  }
}
