import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FavoriteSearchService } from '../services/favorite-search.service';
import {
  LoadFavoriteSearch,
  LoadFavoriteSearchFail,
  LoadFavoriteSearchSuccess,
  LOAD_FAVORITE_SEARCH
} from './favorite-search.actions';

@Injectable()
export class FavoriteSearchEffects {
  loadFavoriteSearches$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadFavoriteSearch>(LOAD_FAVORITE_SEARCH),
    switchMap(action =>
      this.favoriteSearchService.getFavoriteSearches().pipe(
        map(response => new LoadFavoriteSearchSuccess(response)),
        catchError(error => of(new LoadFavoriteSearchFail(error)))
      )
    )
  ));

  constructor(
    private favoriteSearchService: FavoriteSearchService,
    private actions$: Actions
  ) {}
}
