import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { IOddeNotificationPlan } from '../../../models/odde.alert-plan.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import * as fromPermissions from '../../../models/audit-permissions.model';

@Component({
  selector: 'notification-template-list',
  templateUrl: './notification-template-list.component.html',
  styleUrls: ['./notification-template-list.component.scss']
})
export class NotificationTemplateListComponent
  extends BaseComponent
  implements OnInit {

    @Input() notificationPlans: IOddeNotificationPlan[] = [];

    hasManageAlertPlansPermission = false;
    editorShowPLanType = true;
    editorIsOpen = false;
    ownerModule = 'auditing';
    selectedPlanId: string;

    constructor(
      private permissionsService: AuditModulePermissionsService
    ) {
      super();
    }

    ngOnInit(): void {
      this.setPermissions();
    }

    notificationPlanClicked(planId: string) {
      this.selectedPlanId = planId;
      this.editorIsOpen = true;
    }

    editorClosed() {
      this.editorIsOpen = false;
      this.selectedPlanId = '';
    }

    private setPermissions() {
      this.hasManageAlertPlansPermission = this.permissionsService.hasAnyOfPermissions(
        [fromPermissions.canManageSharedAlertsAndAlertPlans]
      );
    }

  }
