import {
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';

import { BaseComponent } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import { Subject } from 'rxjs';

@Component({
  selector: 'private-shared-type-selector',
  templateUrl: './private-shared-type-selector.component.html',
  styleUrls: ['./private-shared-type-selector.component.scss']
})

export class PrivateSharedTypeSelectorComponent
  extends BaseComponent
  implements OnDestroy {
  @Input() isPrivateSelectionAllowed: boolean;
  @Input() isSharedSelectionAllowed: boolean;
  @Input() isSharedItemSelected: boolean;
  @Output() buttonSelected: EventEmitter<string> = new EventEmitter();

  auditStrings = AuditingDisplayStringsProvider.auditing;
  pageLabels = AuditingDisplayStringsProvider.auditing.pages.newSearches;

  toggleButtons: string[] = [
    AuditingDisplayStringsProvider.auditing.privateType,
    AuditingDisplayStringsProvider.auditing.sharedType
  ];

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
  ) {
    super();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getSelectedSearchType() {
    return this.isSharedItemSelected
      ? AuditingDisplayStringsProvider.auditing.sharedType
      : AuditingDisplayStringsProvider.auditing.privateType;
  }

  onToggleButtonsChanged(button: string) {
    this.buttonSelected.emit(button);
  }
}
