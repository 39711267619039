import { SigninsChartCustomTooltipService } from './services/sign-ins.chart.custom-tooltip.service';
import { SigninsChartTicksFormatService } from './services/sign-ins.chart.ticks-format.service';
import { SigninsDataService } from './services/sign-ins.data.service';
import { SigninsMouseEventService } from './services/sign-ins.mouse-event.services';
import { SigninsComponent } from './sign-ins.component';
import { SigninsEffects } from './state/sign-ins.effects';

const signinsServices = [
  SigninsMouseEventService,
  SigninsDataService,
  SigninsChartTicksFormatService,
  SigninsChartCustomTooltipService
];

export const signinsFiles = {
  components: [SigninsComponent],
  effects: [SigninsEffects],
  services: signinsServices
};
