export function getTenantStatusIcon(severity: string): string {
  let iconMap: any = {
    error: 'status-error',
    warning: 'status-warning',
    information: 'status-info'
  };

  if (iconMap[severity]) {
    return iconMap[severity];
  } else {
    return null;
  }
}
