/* eslint-disable max-len */
import { IHelp } from '@ondemand/core';

export const AUDITING_HELP_URLS: IHelp[] = [
  {
    route: 'auditing/auditing/dashboard',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/3#TOPIC-907322'
  },
  {
    route: 'auditing/auditing/queries/saved',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/3#TOPIC-907323'
  },
  {
    route: 'auditing/auditing/queries/results',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/4#TOPIC-907324'
  },
  {
    route: 'auditing/auditing/queries/results/:RouteParameter',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/4#TOPIC-907324'
  },
  {
    route: 'auditing/auditing/queries/editor',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/4#TOPIC-907326'
  },
  {
    route: 'auditing/auditing/queries/editor/:RouteParameter',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/5#TOPIC-907330'
  },
  {
    route: 'auditing/auditing/queries/visual/:RouteParameter',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/5#TOPIC-907328'
  },
  {
    route: 'auditing/auditing/config',
    url: 'https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/2#TOPIC-907317'
  }
];
