/**
 * Component for specifying a true/false value
 */
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgForm,
  Validator,
  UntypedFormControl
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Output,
  forwardRef,
  ViewChild,
  Input
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiEmailFieldComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiEmailFieldComponent),
      multi: true
    }
  ],
  selector: 'multi-email-field',
  templateUrl: './multi-email-field.component.html',
  styleUrls: ['./multi-email-field.component.scss']
})
export class MultiEmailFieldComponent
  extends BaseComponent
  implements ControlValueAccessor, Validator {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() canEdit = true;
  @ViewChild('f', {static: true}) form: NgForm;
  onChange: any;
  value: string[] = null;
  emailAddresses: string;

  constructor() {
    super();
  }

  /**
   * Handle value input from ngModel binding
   *
   * @param value Value to load
   */
  writeValue(value: string[]): void {
    if (value !== null) {
      this.value = value;
      this.emailAddresses = value.join(', ');
      this.onChange();
      }
  }

  /**
   * Set up function to propagate changes to parent form
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.value);
      this.change.emit();
    };
    this.onChange();
  }

  registerOnTouched(_fn: any): void {
    return;
  }

  /**
   * Validate whether email addresses are valid
   *
   * @param _control Reference to this control
   */
  validate(_control: UntypedFormControl): any {
    if (this.form && this.form.valid) {
      return null;
    } else {
      return {
        error: 'Invalid email address'
      };
    }
  }

  onEmailChange() {
    // De-serialize from string to array
    this.value = this.emailAddresses.trim().split(/[\s;,]+/);
    this.onChange();
  }
}
