// © Copyright 2016 Quest Software Inc.
// ALL RIGHTS RESERVED.
import { Component } from '@angular/core';

@Component({

    selector: 'ca-queries',
    templateUrl: './queries.component.html',
    styleUrls: ['./queries.component.scss']
})
export class QueriesComponent {
}
