<internal-loading
  [information]="coreStrings.loadingPleaseWait"
  [isLoading]="tenantUpdateInProgress"
></internal-loading>

<div class="error-container" *ngIf="!!errorMessage">
  <error-message>{{ errorMessage }}</error-message>
  <div class="debug-message-container" *ifAuditFlag="'auditing.debug'">
    <code>{{ debugMessage | json }}</code>
  </div>
</div>

<div
  class="gcc-error-banner-container"
  *ngIf="!!displayGCCTenantErrorBannerMessage"
>
  <qod-dismissible-notification
    class="notification-container"
    type="error"
    [text]="displayGCCTenantErrorBannerMessage"
    [dismissible]="true"
    (crossClicked)="onClearGCCErrorMessage()"
  ></qod-dismissible-notification>
</div>

<div *ngIf="!tenantUpdateInProgress && !tenantsHasError">
  <div class="qloud-card-list">
    <!-- List of Azure tenants -->
    <ng-container
      *odaIfHasPermissions="[fromPermissions.canManageAzureADTenants]"
    >
      <div
        class="card qloud-card azure-tenant-card"
        id="{{ tenant.questTenantId }}"
        *ngFor="let tenant of tenantList"
      >
        <div class="qloud-tenant-card-header">
          <edc-icon
            class="azure-ad-logo tenant-logo"
            name="active-directory"
          ></edc-icon>
          <h4 class="qloud-tenant-name" title="{{ tenant.name }}">
            {{ tenant.name }}
          </h4>
          <span
            id="gcc-badge"
            class="badge-container"
            *ngIf="tenant.tenantRegionSubScope === gccText"
          >
            <span class="badge">
              <edc-icon
                name="status-info"
                size="small"
                state="info-blue"
              ></edc-icon>
              <span class="badge-text">
                {{ gccText }}
              </span>
            </span>
          </span>
        </div>

        <div class="card-content qloud-card-content tenant-card-content">
          <consent-link
            [questTenantId]="tenant.questTenantId"
            [consentRegistrationInProgress]="routingInProgress"
            [hasManageAzureADPermission]="hasManageAzureADPermissions"
          ></consent-link>

          <ng-container *ngIf="initialConfigLoadCompleted && !configsError">
            <form
              class="tenant-card-form"
              #servicelist="ngForm"
              [attr.data-tenant-id]="tenant.questTenantId"
              (ngSubmit)="onSubmit(tenant, servicelist)"
            >
              <ul
                class="service-list"
                [ngClass]="{ disabled: !hasManageAzureADPermissions }"
              >
                <li class="all-services">
                  <input
                    [checked]="allServicesSelected(tenant)"
                    [disabled]="
                      configIsSaving(tenant) ||
                      !getTenantLastStatusCheckTimeIsUpdated(tenant) ||
                      !hasManageAzureADPermissions
                    "
                    name="all"
                    type="checkbox"
                    id="auditall-{{ tenant.questTenantId }}"
                  />
                  <label
                    for="auditall-{{ tenant.questTenantId }}"
                    (click)="
                      !configIsSaving(tenant) && selectAll(tenant, servicelist);
                      (false)
                    "
                    >{{ 'auditing.auditAllServices' | localize }}</label
                  >
                  <hr />
                </li>
                <ng-container
                  *ngFor="let service of getTenantConfig(tenant).settings"
                >
                  <li class="service-option">
                    <input
                      [(ngModel)]="service.selected"
                      type="checkbox"
                      name="{{ getServiceIdForTenant(service, tenant) }}"
                      id="{{ getServiceIdForTenant(service, tenant) }}"
                      [disabled]="
                        service.disabled ||
                        getTenantConfig(tenant).saving ||
                        !hasManageAzureADPermissions
                      "
                    />
                    <label
                      for="{{ getServiceIdForTenant(service, tenant) }}"
                      (click)="
                        !configIsSaving(tenant) && onClickService(service)
                      "
                      >{{ service.label }}</label
                    >
                  </li>
                </ng-container>
              </ul>

              <ng-container *ngIf="getTenantConfig(tenant)">
                <div
                  *ngIf="
                    !getTenantLastStatusCheckTimeIsUpdated(tenant);
                    else statusMessage
                  "
                  class="tenant-status-indicator status-info checking-configuration"
                >
                  <edc-icon name="status-inprogress" [spin]="true"></edc-icon>
                  {{ 'auditing.updatingTenantConfigStatus' | localize }}
                </div>

                <ng-template #statusMessage>
                  <div
                    *ngIf="getTenantConfig(tenant).statusIcon"
                    class="tenant-status-indicator"
                    ngClass="{{ getTenantConfig(tenant).statusIcon }}"
                    (click)="onClickStatus(tenant)"
                  >
                    <edc-icon
                      name="{{ getTenantConfig(tenant).statusIcon }}"
                    ></edc-icon>
                    {{ 'auditing.tenantStatusMessageLink' | localize }} ({{
                      getTenantConfig(tenant).messageCount
                    }})
                  </div>
                </ng-template>
              </ng-container>

              <button
                class="save-button waves-effect waves-light btn-flat btn-secondary center full-width"
                type="submit"
                [disabled]="
                  !servicelist.form.dirty ||
                  getTenantConfig(tenant).saving ||
                  !getTenantLastStatusCheckTimeIsUpdated(tenant) ||
                  !hasManageAzureADPermissions
                "
              >
                <span *ngIf="getTenantConfig(tenant).saving; else notsaving">{{
                  'auditing.saving' | localize
                }}</span>
                <ng-template #notsaving>{{
                  'auditing.saveButtonLabel' | localize
                }}</ng-template>
              </button>
            </form>
          </ng-container>

          <ng-container *ngIf="!configsLoaded && !initialConfigLoadCompleted">
            <div class="config-loading">
              {{ 'auditing.loadingTenantConfig' | localize }}
            </div>

            <div class="active-request-indicator">
              <materialize-spinner></materialize-spinner>
            </div>
          </ng-container>

          <p *ngIf="configsError" class="error">
            {{ 'auditing.configLoadError' | localize }}
          </p>
        </div>
      </div>
    </ng-container>

    <!-- Hide CA installations, and "add" cards if viewing a single tenant -->
    <ng-container *ngIf="!singleTenant">
      <!-- List of Change Auditor installations -->
      <ng-container
        *ngIf="changeAuditorInstallations && hasManageCAPermissions"
      >
        <ca-installation-card
          *ngFor="let installation of changeAuditorInstallations"
          [installation]="installation"
          [updating]="activeCARequest"
          (delete)="onInstallationRemoved($event)"
        ></ca-installation-card>
      </ng-container>

      <ng-container
        *odaIfHasPermissions="[fromPermissions.canManageAzureADTenants]"
      >
        <add-tenant-card
          *ngIf="allowedToEditTenants$ | async"
        ></add-tenant-card>
      </ng-container>

      <add-ca-card
        *odaIfHasPermissions="[fromPermissions.canManageChangeAuditor]"
      ></add-ca-card>

      <bhe-card *ngIf="enableBhe"></bhe-card>
    </ng-container>
  </div>
</div>

<tenant-status-modal></tenant-status-modal>
