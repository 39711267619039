export const LOW_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-low.svg';
export const MEDIUM_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-medium.svg';
export const HIGH_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-high.svg';
export const CRITICAL_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-critical.svg';
export const ANOMALY_LOW_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-anomaly-low.svg';
export const ANOMALY_MEDIUM_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-anomaly-medium.svg';
export const ANOMALY_HIGH_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-anomaly-high.svg';
export const ANOMALY_CRITICAL_LEVEL_ICON_PATH =
  '/assets/auditing/img/critical-activity-anomaly-critical.svg';
export const CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH =
  '/assets/auditing/img/no-data.svg';
export const MAX_CRITICAL_ACTIVITY_AMOUNT_FOR_WIDGET = 7;
