<general-qod-component
  id="critical-activity-widget-card"
  [loading]="dataLoading$ | async"
  [error]="errorOccurred"
  [data]="data$ | async"
  [height]="cardHeight"
  [card]="true"
>

  <div
    *ngIf="dataloaded"
    component-header
    class="status-info"
  >
    <div class="tooltip">
      <div class="infoPosition">
        <edc-icon size="small" name="info"></edc-icon>
      </div>
      <div class="tooltiptext">
        {{ 'auditing.pages.auditingDashboard.criticalActivity.nodatahint' | localize }}
          <br />
          <br />
          <a href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide"
          target="_blank">
            Click Here
          </a>
          {{ 'auditing.pages.auditingDashboard.criticalActivity.learnmore' | localize }}
        </div>
    </div>
  </div>

  <h4 component-header>
    {{ 'auditing.pages.auditingDashboard.criticalActivity.htmlTitle' | localize }}
  </h4>

  <skeleton-block component-loading type="TitleWithLineGraph"></skeleton-block>

  <div component-error>
    <error-card error="{{ 'auditing.pages.auditingDashboard.error' | localize }}"
      button="{{ 'auditing.pages.auditingDashboard.tryAgain' | localize }}"
      (buttonClick)="loadCriticalActivity()">
    </error-card>
  </div>

  <div component-data>
      <p *ngIf="hasCriticalActivity" class="event-title" [ngClass]="{ 'event-title-right-padding-single': canSeeViewActionButton && !canSeeDetailsActionButton, 'event-title-right-padding-double': canSeeViewActionButton && canSeeDetailsActionButton }">
        {{ 'auditing.pages.auditingDashboard.criticalActivity.events' | localize }}
      </p>
      <status-icon
        *ngFor="let row of cardData$ | async" [description]="row.label"
        [value]="row.data"
        [imageIcon]="getCriticalLevelImageIconPath(row.priority, row.alertType)"
        [additionalActions]="row.actionNames"
        (action)="actionEventHandler($event, row.queryId, row.query, row.label)">
      </status-icon>
      <div
        #moreResultActionContainerRef
        *ngIf="canSeeMoreResultsAvailableButton"
        class="more-results-action-wrapper"
        [ngStyle]="getMoreResultContainerStyle(moreResultActionContainerRef)" >
        <button id="more-results-available-button" class="btn-more-results" (click)="viewMoreResults()"> {{ 'auditing.pages.auditingDashboard.criticalActivity.moreResultsDescription' | localize }}  </button>
      </div>

    <ng-container *ngIf="!hasCriticalActivity">
      <div class="status-done-success icon-container">
        <edc-icon size="5x" name="status-done"></edc-icon>
      </div>
      <h2 class="no-data">
        {{ 'auditing.pages.auditingDashboard.criticalActivity.nodata' | localize }}
      </h2>
      <h4 class="no-data-detail">
        {{ 'auditing.pages.auditingDashboard.criticalActivity.nodatadetail' | localize }}
      </h4>
    </ng-container>

  </div>
</general-qod-component>
