import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[cannotBeBlank]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CannotBeBlankValidatorDirective,
    multi: true
  }]
})
export class CannotBeBlankValidatorDirective implements Validator {

  validate(control: AbstractControl): {[key: string]: any} | null {
    if (this.isBlank(control.value)) {
      return {
        error: 'CannotBeBlank'
      };
    } else {
      return null;
    }
  }

  private isBlank(value: any): boolean {
    if (typeof value === 'string') {
      return value.trim().length === 0;
    } else if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === null || value === undefined;
    }
  }
}
