/**
 * Component for view to set up Change Auditor integration with On Demand Audit
 */

import { finalize, take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ChangeAuditorInstallationService } from '../../../services/ca-installation.service';
import {
  FeatureSubscriptionService,
  EAuditFeatureName
} from '../../../services/feature-subscription/feature-subscription.service';
import {
  ChangeAuditorDesktopApplication,
  DesktopAppHost,
  CAInstallationInfo,
  AddCAInstallationResult,
  WebView
} from '../../../models/ca-setup.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { AuditingFeatureFlagsService } from '../../../services/auditing-feature-flags.service';
import { CAUpgradeMessage } from '../../../models/ca-upgrade-message.model';
import { ChangeAuditorInstallation } from '../../../models/change-auditor-installation.model';
import {
  EProvisionedState,
  EProvisioningSource
} from '../../../../shared/models/provision.model';
import { ProvisioningService } from '../../../services/organization-provisioning/provisioning.service';
import { FeatureFlagType } from '../../shared/feature-flag.enum';

export interface ChangeAuditorBrowser {
  CefSharp: DesktopAppHost;
  desktopApp: ChangeAuditorDesktopApplication;
}

declare let window: ChangeAuditorBrowser;

declare global {
  namespace chrome {
    const webview: WebView;
  }
}

@Component({
  templateUrl: './ca-setup-wizard.component.html',
  styleUrls: ['./ca-setup-wizard.component.scss']
})
export class ChangeAuditorSetupWizardComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  errorMessage: string;
  changeAuditorInfo: CAInstallationInfo;
  submitting = false;
  registered = false;
  labels: any;
  checkingSubscription = false;
  subscribed: boolean;
  subscriptionExpired: boolean;
  isAuditDownForMaintenance: Observable<boolean>;
  upgradeMessage: CAUpgradeMessage;
  provisioned = false;
  provisioningSourceCA = EProvisioningSource.ChangeAuditor;
  // there are problems when using NodeJS.Timeout type
  // it breaks the core ExtDEV deployment pipeline, so we use "any" here
  provisionTimer: any;
  pollingTimeInterval = 30000;
  isCefSharpCAClient = true;

  @ViewChild('installationNameField') installationNameField: ElementRef;

  constructor(
    private installService: ChangeAuditorInstallationService,
    private featureSubscriptions: FeatureSubscriptionService,
    private localeStrings: LocaleStringsService,
    private featureFlagService: AuditingFeatureFlagsService,
    private provisionService: ProvisioningService
  ) {
    super();
  }

  async ngOnInit() {
    await this.pollToProvisioningStatus();

    this.checkSubscription();

    this.checkDowntime();

    // We need to maintain both cefsharp and webview in client
    // to support customer having older change auditor versions.
    if (typeof window.CefSharp === 'undefined') {
       this.isCefSharpCAClient = false;
    }

    this.getDataFromDesktop().then(
      desktopData => {
        this.changeAuditorInfo = desktopData;

        // Copy internal name as the default display name
        this.changeAuditorInfo.installationDisplayName =
          desktopData.installationName;

        // Focus on text input field, giving a brief timeout to allow page to
        // finish rendering
        setTimeout(() => {
          if (this.installationNameField) {
            this.installationNameField.nativeElement.focus();
          }
        }, 500);
      },
      async error => {
        console.error('Failed to get data from desktop:', error);
        this.errorMessage = await this.localeStrings
          .string$('auditing.setupChangeAuditorDesktopReadError')
          .pipe(take(1))
          .toPromise();
      }
    );
  }

  checkDowntime() {
    this.isAuditDownForMaintenance = this.featureFlagService.getFlag(
      FeatureFlagType.EnableDowntimeState
    );
  }

  OnDestroy() {
    this.stopPollToProvisioningStatus();
  }

  // Poll till this component is alive to keep provisioning status updated
  public async pollToProvisioningStatus() {
    let currentProvisioningStatus =
      await this.provisionService.getProvisioningStatus();

    if (currentProvisioningStatus === EProvisionedState.Provisioned) {
      this.provisioned = true;
      this.provisionTimer = undefined;
    } else {
      this.provisioned = false;
      this.provisionTimer = setTimeout(async () => {
        await this.pollToProvisioningStatus();
      }, this.pollingTimeInterval);
    }
  }

  /**
   * Handle form submission
   *
   */
  async onSubmit() {
    this.submitting = true;

    this.errorMessage = null;

    this.installService
      .registerCoordinator(this.changeAuditorInfo)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        response => {
          let data = response.body as AddCAInstallationResult;
          const dataString = JSON.stringify(data);
          if (this.isCefSharpCAClient) {
            window.desktopApp.sendInstallationRegistrationResult(dataString);
          } else {
            chrome.webview.hostObjects.sync.desktopApp.sendInstallationRegistrationResult(dataString);
          }
          this.CheckUpgradeMessage(data);

          this.registered = true;
        },
        async (response: HttpErrorResponse) => {
          let responseBody = response.error;
          let errorPath = 'auditing.setupChangeAuditorServiceError';
          if (
            responseBody.error &&
            responseBody.error.code &&
            responseBody.error.code === 'InstallationExists'
          ) {
            errorPath = 'auditing.changeAuditorDuplicateInstallationError';
          }
          this.errorMessage = this.localeStrings.get(errorPath);
          console.error('Error from service request:', response);
        }
      );
  }

  /**
   * Send "close" event to desktop application so that user
   * can dismiss this page and continue using Change Auditor
   *
   */
  onClickClose() {
    if (this.isCefSharpCAClient) {
      window.desktopApp.close();
    } else {
      chrome.webview.hostObjects.sync.desktopApp.close();
    }
  }

  onClickCheckSubscription() {
    this.checkSubscription();
  }

  private stopPollToProvisioningStatus() {
    if (this.provisionTimer) {
      clearTimeout(this.provisionTimer);
      this.provisionTimer = undefined;
    }
  }

  private CheckUpgradeMessage(data: AddCAInstallationResult) {
    this.installService.getInstallation(data.id).subscribe(
      response => {
        const caInstallation = response.body as ChangeAuditorInstallation;

        if (!!caInstallation) {
          this.upgradeMessage = caInstallation.upgradeMessage;
        }
      },
      async (response: Response) => {
        console.error(
          'There was an error getting the CA installation:',
          response
        );
      }
    );
  }

  /**
   * Load data from desktop application about Change Auditor
   * installation
   *
   */
  private getDataFromDesktop(): Promise<CAInstallationInfo> {
    return new Promise((resolve, reject) => {
      if (this.isCefSharpCAClient) {
        window.CefSharp.BindObjectAsync('desktopApp').then(
          () => {
            const installation: CAInstallationInfo = JSON.parse(
              window.desktopApp.getInstallation()
            );
            resolve(installation);
          },
          (error: any) => {
            reject(`Failed to load desktop info:` + JSON.stringify(error));
          }
        );
      } else {
        if (typeof chrome.webview.hostObjects === 'undefined') {
          reject(
            `Could not find hostObjects Change Auditor application for connection.`
          );
        } else {
          const installation: CAInstallationInfo = JSON.parse(
            chrome.webview.hostObjects.sync.desktopApp.getInstallation()
          );
          resolve(installation);
        }
      }
      });
  }

  private async checkSubscription() {
    this.checkingSubscription = true;
    this.subscribed = await this.featureSubscriptions
      .hasSubscription$(EAuditFeatureName.CHANGE_AUDITOR)
      .pipe(take(1))
      .toPromise();
    this.subscriptionExpired = await this.featureSubscriptions
      .hasSubscription$(EAuditFeatureName.CHANGE_AUDITOR_EXPIRED)
      .pipe(take(1))
      .toPromise();

    // Delay completion of the subscription check so the UI can have time to indicate
    // that the check was done
    setTimeout(() => {
      this.checkingSubscription = false;
    }, 1500);
  }
}
