/**
 * Component for viewing list of alert rules
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NotificationPlansService,
  AppFacadeService,
  BaseComponent,
  EDialogType,
  IModalWindow,
  OnDemandToken
} from '@ondemand/core';
import { Observable ,  ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AlertRule } from '../../../models/alert-rule.model';
import { AlertService } from '../../../services/alerts.service';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { AlertPlan } from '../../../models/alert-plan.model';
import {
  AlertRuleEditorModalComponent,
  AlertRuleEditorSavedEvent
} from '../alert-rule-editor-modal/alert-rule-editor-modal.component';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import * as fromPermissions from '../../../models/audit-permissions.model';
import {
  getLastSavedToolTipInfo,
  getLastSavedLabelText
} from '../../../util/last-saved-info';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';
import { AuditingFeatureFlagsService } from '../../../services/auditing-feature-flags.service';
import { IOddeNotificationPlan } from '../../../models/odde.alert-plan.model';
import { FeatureFlagType } from '../../shared/feature-flag.enum';
import { AuditConstants } from '../../../../shared/models/ui-constants';

export interface AlertRuleListRow {
  alertRule: AlertRule;
  expanded: boolean;
  alertPlans?: AlertPlan[];
  loadingPlans: boolean;
  error?: string;
  hasAlertPlans: boolean;
}

@Component({
  selector: 'alert-rule-list',
  templateUrl: './alert-rule-list.component.html',
  styleUrls: ['./alert-rule-list.component.scss']
})
export class AlertRuleListComponent extends BaseComponent implements OnInit {
  @ViewChild(AlertRuleEditorModalComponent)
  ruleEditorModal: AlertRuleEditorModalComponent;
  loading = false;
  alertRuleRows: AlertRuleListRow[];
  error: Observable<string>;
  successModalParams: IModalWindow;
  successModalContent: {
    linkUrl: string;
    linkTextPath: string;
  };

  alertPlanMigrationEnabled = true;
  oddeNotificationPlans: IOddeNotificationPlan[];

  hasManageAlertPlansPermission = false;
  userEmailId: string;
  hasViewSharedSearchesPermission = false;
  hasViewPrivateSearchPermission = false;
  lastSavedToolTipRefreshProp: string[] = [];

  constructor(
    private alertService: AlertService,
    private localeStrings: LocaleStringsService,
    private facade: AppFacadeService,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private permissionsService: AuditModulePermissionsService,
    private facadeService: AppFacadeService,
    private toastService: ODToastService,
    private notificationPlanService: NotificationPlansService,
    private featureFlagService: AuditingFeatureFlagsService
  ) {
    super();
  }

  async ngOnInit() {

    this.alertPlanMigrationEnabled = await this.featureFlagService
                                            .getFlag(FeatureFlagType.EnableNotificationPlans)
                                            .pipe(take(1))
                                            .toPromise();

    if(this.alertPlanMigrationEnabled) {
      await this.loadNotificationPlans();
    }

    this.setPermissions();

    this.breadcrumbsService.set([
      {
        title: await this.localeStrings
          .string$('auditing.alertRuleListNavTitle').pipe(
          take(1))
          .toPromise(),
        url: 'auditing/auditing/alerts'
      }
    ]);

    this.loadAlertRules();

    this.facadeService.token$.pipe(take(1)).subscribe(token => {
      this.userEmailId = token.email;
    });
  }

  async onDeleteRuleClick(rule: AlertRule) {
    if (
      await this.facade.confirm(
        await this.localeStrings
          .string$('auditing.confirmDeleteAlertRuleTitle').pipe(
          take(1))
          .toPromise(),
        await this.localeStrings
          .string$('auditing.confirmDeleteAlertRule', { name: rule.name }).pipe(
          take(1))
          .toPromise()
      )
    ) {
      this.alertService.deleteAlertRule(rule.id).subscribe(
        async () => {
          const message = await this.localeStrings
            .string$('auditing.deletedAlertRuleSuccess').pipe(take(1)).toPromise();

          toastLowerLeft(this.toastService, message, ToastType.Success);
          this.loadAlertRules();
        },
        async error => {
          console.error('Got error deleting rule:', error);
          const message = await this.localeStrings
            .string$('auditing.errorDeletingAlertRule').pipe(take(1)).toPromise();

          toastLowerLeft(this.toastService, message, ToastType.Error);
        }
      );
    }
  }

  async onEnabledChange(alertRule: AlertRule) {
    let title: string;
    let message: string;
    if (alertRule.enabled) {
      title = await this.localeStrings
        .string$('auditing.alertRuleCreationConfirmationTitle').pipe(
        take(1))
        .toPromise();
      message = await this.localeStrings
        .string$('auditing.alertRuleEnableConfirmation').pipe(
        take(1))
        .toPromise();
    } else {
      title = await this.localeStrings
        .string$('auditing.alertRuleDisableConfirmationTitle').pipe(
        take(1))
        .toPromise();
      message = await this.localeStrings
        .string$('auditing.alertRuleDisableConfirmation').pipe(
        take(1))
        .toPromise();
    }
    // Disable alert rule if it already exists
    if (await this.facade.confirm(title, message, { type: EDialogType.INFO })) {
      this.alertService.updateAlertRule(alertRule).subscribe(
        async response => {
          const updatedRule: AlertRule = response.body;
          let successMessage;
          if (alertRule.enabled) {
            successMessage = await this.localeStrings
              .string$('auditing.alertRuleEnabledSuccess').pipe(
              take(1))
              .toPromise();
          } else {
            successMessage = await this.localeStrings
              .string$('auditing.alertRuleDisabledSuccess').pipe(
              take(1))
              .toPromise();
          }
          alertRule = updatedRule;

          toastLowerLeft(this.toastService, successMessage, ToastType.Success);
        },
        async error => {
          console.error('Error updating rule:', error);
          let errorMessage = await this.localeStrings
            .string$('auditing.alertRuleFailedUpdate').pipe(
            take(1))
            .toPromise();

          toastLowerLeft(this.toastService, errorMessage, ToastType.Error);

          // Revert value back to original
          alertRule.enabled = !alertRule.enabled;
        }
      );
    } else {
      // Revert value back to original
      alertRule.enabled = !alertRule.enabled;
    }
  }

  toggleExpandedStatus(row: AlertRuleListRow) {
    if (row.expanded) {
      row.expanded = false;
    } else {
      row.expanded = true;
      this.getLinkedAlertPlans(row);
      this.updateLastSavedInfo(row.alertRule);
    }
  }

  onClickEditPlans(row: AlertRuleListRow) {
    this.ruleEditorModal.openToEditAlertRule(row.alertRule.id);
  }

  hasViewSearchPermission(alertRule: AlertRule) {
    if (alertRule.isShared) {
      return this.hasViewSharedSearchesPermission;
    }

    // If private search, then he should be the owner of the search.
    if (alertRule.createdBy === this.userEmailId) {
      return this.hasViewPrivateSearchPermission;
    }

    return false;
  }

  async onAlertRuleEditSave(savedEvent: AlertRuleEditorSavedEvent) {
    const newPlanCreated = savedEvent.createdAlertPlan !== null;

    if (newPlanCreated) {
      this.showSuccessModal(savedEvent.createdAlertPlan);
    } else {
      const message = await this.localeStrings
        .string$('auditing.savedAlertRule').pipe(
        take(1))
        .toPromise();

      toastLowerLeft(this.toastService, message, ToastType.Success);
    }
    this.loadAlertRules();
  }

  private async updateLastSavedInfo(rule: AlertRule) {
    const lastSavedLabel = await this.localeStrings
      .string$('auditing.lastSavedLabel').pipe(
      take(1))
      .toPromise();
    const lastSavedTooltip = await this.localeStrings
      .string$('auditing.lastSavedTooltip').pipe(
      take(1))
      .toPromise();

    setTimeout(() => {
      this.lastSavedToolTipRefreshProp = [];
    }, 200);

    rule.lastSavedToolTipInfo.next(
      getLastSavedToolTipInfo(
        lastSavedTooltip,
        rule.createdDate,
        rule.createdBy,
        rule.modifiedDate,
        rule.modifiedBy
      )
    );

    rule.lastSavedLabelInfo.next(
      getLastSavedLabelText(lastSavedLabel, rule.modifiedBy, rule.modifiedDate)
    );
  }

  private getLinkedAlertPlans(row: AlertRuleListRow) {

    row.loadingPlans = true;

    if(this.alertPlanMigrationEnabled) {

      const selectedRuleId = row.alertRule.id;
      const notificationPlans = this.oddeNotificationPlans.filter(
        notificationPlan => (
          notificationPlan.alertRules.filter(
            alertRuleId => alertRuleId === selectedRuleId)).length > 0) as any[];

      row.alertPlans = notificationPlans;
      row.loadingPlans = false;

    }  else  {

      const rule = row.alertRule;
      this.alertService.getAlertPlans(rule.id).subscribe(
        response => {
          row.loadingPlans = false;
          if (response.status === 200) {
            let plans: AlertPlan[] = response.body;
            row.alertPlans = plans;
          } else {
            console.error('Unexpected response from alert service:', response);
            this.onErrorLoadingPlans();
          }
        },
        errorResponse => {
          row.loadingPlans = false;
          console.error('Unexpected response from alert service:', errorResponse);
          row.expanded = false;
          this.onErrorLoadingPlans();
        }
      );

    }
  }

  private async onErrorLoadingPlans() {
    const message = await this.localeStrings
      .string$('auditing.failedToLoadAlertPlansForRule').pipe(
      take(1))
      .toPromise();

    toastLowerLeft(this.toastService, message, ToastType.Error);
  }

  private loadAlertRules() {
    this.alertRuleRows = null;
    this.error = null;
    this.loading = true;
    this.alertService.getAlertRules().subscribe(
      response => {
        this.loading = false;
        if (response.status === 200) {
          const data: AlertRule[] = response.body;
          this.alertRuleRows = data.map(rule => {
            rule.lastSavedToolTipInfo = new ReplaySubject<string>(1);
            rule.lastSavedLabelInfo = new ReplaySubject<string>(1);
            return {
              alertRule: rule,
              expanded: false,
              loadingPlans: false,
              hasAlertPlans: rule.numAlertPlans > 0
            };
          });
        } else {
          console.error(
            'Got unexpected response from alert service:',
            response
          );
          this.error = this.localeStrings.string$(
            'auditing.errorLoadingAlertRules'
          );
        }
      },
      error => {
        this.loading = false;
        console.error('Error loading alert rules:', error);
        this.error = this.localeStrings.string$(
          'auditing.errorLoadingAlertRules'
        );
      }
    );
  }

  private async showSuccessModal(createdAlertPlan: AlertPlan) {
    let modalTitle = await this.localeStrings
      .string$('auditing.alertRuleSaveSuccess').pipe(
      take(1))
      .toPromise();

    this.successModalContent = {
      linkUrl: `/auditing/auditing/alerts/plans/${createdAlertPlan.id}`,
      linkTextPath: 'auditing.alertPlanEditNewLinkText'
    };

    this.successModalParams = {
      showModal: true,
      dialogParams: {
        title: modalTitle,
        type: EDialogType.INFO,
        hideCancel: true,
        actions: [
          {
            name: await this.localeStrings
              .string$('auditing.okay').pipe(
              take(1))
              .toPromise(),
            action: () => {
              this.closeModal();
            }
          }
        ]
      }
    };
  }

  private closeModal() {
    this.successModalParams.showModal = false;
  }

  private setPermissions() {
    this.hasManageAlertPlansPermission = this.permissionsService.hasAnyOfPermissions(
      [fromPermissions.canManageSharedAlertsAndAlertPlans]
    );

    this.hasViewSharedSearchesPermission = this.permissionsService.hasAnyOfPermissions(
      [fromPermissions.canViewSharedSearches]
    );

    this.hasViewPrivateSearchPermission = this.permissionsService.hasAnyOfPermissions(
      [fromPermissions.canRunPrivateSearch]
    );
  }

  private async loadNotificationPlans() {
    const pageSize = 1000;
    let pageIndex = -1;
    let notificationPlans: IOddeNotificationPlan[] = [];
    this.oddeNotificationPlans = [];
    do {

      pageIndex++;

      notificationPlans = (await this.notificationPlanService
                                  .getAlertPlanNotifications(pageIndex, pageSize, AuditConstants.ModuleName)
                                  .pipe(take(1)).toPromise() ) as IOddeNotificationPlan[] ;

      this.oddeNotificationPlans = [...this.oddeNotificationPlans, ...notificationPlans];

      // retrieve all the notification plans
    } while ( notificationPlans && notificationPlans.length >= pageSize );

  }

}
