import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { IModalWindow, BaseComponent } from '@ondemand/core';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { getTenantStatusIcon } from './tenant-status-icon';
import { TenantStatus, TenantStatusMessage } from '../../../models/tenant-status.model';

@Component({

  selector: 'tenant-status-modal',
  templateUrl: './tenant-status-modal.component.html',
  styleUrls: ['./tenant-status-modal.component.scss']
})
export class TenantStatusModalComponent extends BaseComponent implements OnInit {
  modalParams: IModalWindow;
  messages: TenantStatusMessage[];

  constructor(private localeStringsService: LocaleStringsService) {
    super();
  }

  ngOnInit() {
    return;
  }

  async displayStatus(tenantStatus: Map<string, TenantStatus>) {
    this.messages = [];
    const tenantStatusList = Object.values(tenantStatus);
    this.messages = tenantStatusList.reduce((accumulator: any, value: any) => accumulator.concat(value.messages), []);

    const modalTitle = await this.localeStringsService.string$('auditing.tenantStatusTitle').pipe(
      take(1)).toPromise();
    const okayLabel = await this.localeStringsService.string$('auditing.okay').pipe(take(1)).toPromise();

    this.modalParams = {
      showModal: true,
      dialogParams: {
        title: modalTitle,
        actions: [{
          name: okayLabel,
          action: () => {
            this.closeModal();
          }
        }],
        hideCancel: true
      }
    };

    this.modalParams = {...this.modalParams};
  }

  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = {...this.modalParams};
  }

  getMessageIcon(severity: string) {
    return getTenantStatusIcon(severity);
  }
}
