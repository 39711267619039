<div class="last-saved-info_with_tooltip">
  <ng-container *ngIf="showLastSavedInfo">
    <label class="last-saved-label-info" id="last-saved-label-info-id">
      {{ lastSavedLabelInfo }}
    </label>
    <span
      class="tooltipped last-saved-tooltip"
      id="last-saved-tooltip-id"
      data-html="true"
      materialize="tooltip"
      [materializeParams]="lastSavedToolTipRefreshProp"
      [attr.data-tooltip]="lastSavedToolTipInfo"
    >
      <edc-icon name="status-info" size="small" state="info-blue"></edc-icon>
    </span>
  </ng-container>
</div>
