// © Copyright 2019 Quest Software Inc.
// ALL RIGHTS RESERVED.

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AppComponent,
  AppRoutingModule,
  CoreModule,
  CoreReducers,
  CoreUIEffects,
  CustomRouterSerializer,
  metaReducers,
  Util,
  IAppConfig
} from '@ondemand/core';

import { OdgModule } from '@ondemand/odg';
import { ODESModule } from '@ondemand/odes';
import { DataEgressModule } from '@ondemand/dataegress';

import { HotkeyModule } from 'angular2-hotkeys';
import { MsalModule, MSAL_INSTANCE } from '@azure/msal-angular';

import { AuditingModule } from '../lib/auditing/auditing.module';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';
import { BrowserCacheLocation, PublicClientApplication, IPublicClientApplication, LogLevel } from '@azure/msal-browser';
// Import your custom module and reducers if available here

const Effects = [...CoreUIEffects]; // spread your module's effects after CoreUIEffects
const reducers = {};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  let msalAuth = Util.getGlobalConfiguration<IAppConfig>().MSAL_AUTH;
  msalAuth.redirectUri = msalAuth.redirectUri || window.location.origin;
  msalAuth.postLogoutRedirectUri = msalAuth.postLogoutRedirectUri || window.location.origin;

  return new PublicClientApplication({
    auth: msalAuth,
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    }
  });
}

@NgModule({
  imports: [
    OdgModule,
    ODESModule,
    DataEgressModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    /**
     * StoreModule.provideStore is imported once in the core module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(CoreReducers, { metaReducers }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store and uses
     * the store as the single source of truth for the router's state.
     */
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([...Effects]),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */

    // TODO: Create a global-config setting to handle toggling Redux devtools on and off.
    StoreDevtoolsModule.instrument(),
    HotkeyModule.forRoot(),
    AuditingModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    MsalModule,
    DragulaModule.forRoot()
  ],
  declarations: [],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterSerializer
    },
    {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
