import { EventEmitter, Injectable } from '@angular/core';
import {
  ChartAnimationOptions,
  ChartData,
  ChartLegendOptions,
  ChartOptions,
  ChartScales,
  ChartTitleOptions,
  ChartTooltipItem,
  ChartTooltipOptions,
  ChartXAxe,
  ChartYAxe,
  GridLineOptions,
  LinearTickOptions,
  TickOptions
} from 'chart.js';
import { cloneAndUnlockObject } from '../../../../utils/object.tools';
import { QueryClause } from '../../../../../auditing/models/query-clause';
import { Query } from '../../../../../auditing/models/query.model';
import { ActiveQueryService } from '../../../../../auditing/services/active-query.service';
import { ODColor } from '../../../../models/oda-colors';
import { ChartPermissionsService } from '../../services/chart-permissions.service';
import { OthersColumnID } from '../../models/chart-constants';
import { CategoryCount } from '../models/hbar.domain.model';
import { EventField } from '../../../../../auditing/models/event-field.model';
import {
  QueryClauseNumberDataTypes,
  QueryClauseOperator
} from '../../../../models/query-constants';

@Injectable()
export class HBarConfigurationService {
  constructor(
    private activeQueryService: ActiveQueryService,
    private chartPermissionService: ChartPermissionsService
  ) {}

  generateHBarConfig(
    dataPoints: CategoryCount[],
    groupByField: EventField,
    barClicked: EventEmitter<Query>
  ): ChartOptions {
    const enabledDataPoints = dataPoints.filter(data => data.enable);
    const eventCounts = enabledDataPoints.map(data => data.count);
    const maxEventCount = Math.max(...eventCounts);
    const enabledDataTexts = enabledDataPoints.map(data => data.categoryLabel);
    const enabledDataValues = enabledDataPoints.map(data => data.categoryValue);
    const allDataValues = dataPoints.map(data => data.categoryValue);

    return {
      responsive: true,
      scaleShowVerticalLines: false,
      maintainAspectRatio: false,
      title: {} as ChartTitleOptions,
      legend: {
        display: false
      } as ChartLegendOptions,
      animation: {
        duration: 600,
        easing: 'linear'
      } as ChartAnimationOptions,
      scales: {
        xAxes: [
          {
            ticks: {
              suggestedMax: maxEventCount > 10 ? undefined : 10,
              precision: 0,
              beginAtZero: true,
              padding: 20,
              callback: (
                value: any, // the tick value in the internal data format of the associated scale.
                index: number, // the index in the ticks array.
                ticks: any[] // the array containing all of the tick objects.
              ): string | number =>
                // references:
                // https://www.chartjs.org/docs/latest/axes/labelling.html
                // The call to the method is scoped to the scale.
                // 'this' inside the method is the scale object.
                 this.xAxisTickLabelCustomFormatter(value, ticks[1])

            } as LinearTickOptions,
            gridLines: {
              display: true,
              drawBorder: false,
              drawTicks: false
            } as GridLineOptions
          } as ChartXAxe
        ],
        yAxes: [
          {
            ticks: {
              fontSize: 14
            } as TickOptions,
            gridLines: {
              display: false,
              drawBorder: false
            } as GridLineOptions
          } as ChartYAxe
        ]
      } as ChartScales,
      tooltips: generateTooltipConfig(enabledDataTexts),
      events: ['click', 'mousemove', 'mouseout'],
      onClick: (event: MouseEvent, activeElements: any[]) => {
        this.mouseClickEventHandler(
          activeElements,
          enabledDataValues,
          enabledDataTexts,
          allDataValues,
          groupByField,
          barClicked
        );
      },
      onHover: (event: MouseEvent, chartElements: any[]) => {
        this.mouseHoverEventHandler(chartElements, enabledDataTexts);
      }
    } as ChartOptions;
  }

  private xAxisTickLabelCustomFormatter(
    tickValue: number,
    smallestNonZeroTick: number
  ): string {
    if (tickValue === 0) {
      return '0';
    }

    if (smallestNonZeroTick < 1000) {
      return `${tickValue.toLocaleString('en-US')}`;
    } else if (smallestNonZeroTick < 1000000) {
      const kCount = tickValue / 1000;
      return `${kCount.toLocaleString('en-US')}K`;
    } else {
      // cases for extremely big numbers
      // Millions
      const mCount = tickValue / 1000000;
      return `${mCount.toLocaleString('en-US')}M`;
    }
  }

  private mouseClickEventHandler(
    activeElements: any[],
    enabledDataValues: object[],
    enabledDataTexts: string[],
    allValues: object[], // including disabled labels
    groupByField: EventField,
    barClicked: EventEmitter<Query>
  ): void {
    const hasPermission: boolean =
      this.chartPermissionService.userHasChartDrillDownPermission();

    if (!hasPermission || activeElements.length < 1) {
      return;
    }

    const dataIndex = activeElements[0]._index;
    const clickedText = enabledDataTexts[dataIndex];
    let clickedValue: any = enabledDataValues[dataIndex];
    const clickedValueStr = `${clickedValue}`;
    const othersClicked: boolean = clickedValueStr === OthersColumnID;
    const activeQuery = this.activeQueryService.getQuery().activeQuery;

    if (othersClicked && allValues.length === 1) {
      // From design doc:
      // When "Other" is the only item shown on the legend (edge-case),
      // the search will simply be re-run since there are no other items
      // available to exclude in the search filters.
      barClicked.emit(activeQuery);
      return;
    } else if (!othersClicked && enabledDataValues.length === 1) {
      // prevent further processing if there is only 1 category (non-others) left
      return;
    }

    const newQuery = cloneAndUnlockObject(activeQuery);
    const groupingColumn = groupByField.id;

    let drilldownClause: QueryClause;
    let clauseIndex = -1;
    if (othersClicked) {
      clauseIndex = newQuery.q.clauses.findIndex(
        clause =>
          clause.field === groupingColumn &&
          clause.operator === QueryClauseOperator.NOT_IN
      );

      const excludeValues = enabledDataValues.filter(value => value !== clickedValue);
      // filter clause is only added/updated if the legend items other than 'Others' are in checked state
      if (excludeValues.length > 0) {
        if (clauseIndex < 0) {
          drilldownClause = new QueryClause({
            field: groupingColumn,
            operator: QueryClauseOperator.NOT_IN,
            value: null,
            values: excludeValues
          });
        } else {
          drilldownClause = newQuery.q.clauses[clauseIndex];
          drilldownClause.values = [...drilldownClause.values, ...excludeValues];
        }

        if (clauseIndex < 0) {
          newQuery.q.clauses.push(drilldownClause);
        } else {
          newQuery.q.clauses[clauseIndex] = drilldownClause;
        }
    }
    } else {
      let equalOperator = QueryClauseOperator.EQUALS;

      if (groupByField.predefinedValuesOnly) {
        if (QueryClauseNumberDataTypes.includes(groupByField.dataType)) {
          equalOperator = QueryClauseOperator.EQUALS_NUMBER;

          // boolean from back end was converted to "True" / "False" strings
          if (groupByField.dataType === 'boolean') {
            clickedValue = clickedValueStr.toLowerCase() === 'true';
          }
        }
      }
      // Determine if filter clause already exist.
      clauseIndex = newQuery.q.clauses.findIndex(
        clause =>
          clause.field === groupingColumn &&
          clause.operator === QueryClauseOperator.EQUALS
      );

      if (clauseIndex < 0) {
        drilldownClause = new QueryClause({
          field: groupingColumn,
          operator: equalOperator,
          value: clickedValue
        });
        newQuery.q.clauses.push(drilldownClause);
      }
    }

    newQuery.name = `${newQuery.name} - ${groupingColumn}: ${clickedText}`;

    barClicked.emit(newQuery);
  }

  private mouseHoverEventHandler(
    chartElements: any[],
    fullLabels: string[]
  ): void {
    const barChartTag = document.getElementsByTagName('hbar-chart')[0];
    const chartCanvasTag = barChartTag.getElementsByTagName('canvas')[0];

    if (chartElements.length > 0) {
      const hasPermission: boolean =
        this.chartPermissionService.userHasChartDrillDownPermission();

      let cursorStyle = 'not-allowed';
      if (hasPermission) {
        const dataIndex = chartElements[0]._index;
        const isOthers = fullLabels[dataIndex] === OthersColumnID;
        if (fullLabels.length > 1 || isOthers) {
          cursorStyle = 'pointer';
        }
      }
      chartCanvasTag.style.cursor = cursorStyle;
    } else {
      chartCanvasTag.style.cursor = 'default';
    }
  }
}

function generateTooltipConfig(
  enabledFullLabels: string[]
): ChartTooltipOptions {
  return {
    enabled: true,
    mode: 'nearest',
    backgroundColor: ODColor.GS_Dark_Gray, // matching core UI tolltip background color
    titleFontSize: 14, // 14 is 1 rem
    titleFontStyle: 'normal',
    bodyFontSize: 14,
    callbacks: {
      title: (items: ChartTooltipItem[], data: ChartData): string => getFullLengthTitle(items[0].index, enabledFullLabels),
      label: (item: ChartTooltipItem, data: ChartData): string => getFormattedLabel(item, data)
    },
    custom: (tooltipModel: any) => {
      // this empty call back will block the unwanted custom tooltip
      // and a smater built-in tooltip will show as we set enabled to true.
    }
  } as ChartTooltipOptions;
}

function getFullLengthTitle(index: number, fullLabels: string[]): string {
  return fullLabels[index];
}

function getFormattedLabel(item: ChartTooltipItem, data: ChartData): string {
  const sourceData: number[] = data.datasets[0].data as number[];
  const eventCount: number = sourceData[item.index];
  const unitLabel: string = eventCount === 1 ? 'Event' : 'Events';
  return `  ${eventCount.toLocaleString('en-US')} ${unitLabel}`;
}
