import { CriticalActivityComponent } from './critical-activity.component';
import { DataTransformerService } from './services/data-transformer.service';
import { CriticalActivityEffects } from './state/critical-activity.effects';

export const criticalActivities = {
    components: [CriticalActivityComponent],
    effects: [CriticalActivityEffects],
    services: [
        DataTransformerService
    ]
};
