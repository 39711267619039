<form #form="ngForm">
  <div class="input-field left count-container">
    <input
      type="text"
      name="interval-count"
      [(ngModel)]="rangeCount"
      (change)="onDateChange()"
      #intervalCount
      [class.invalid]="!valid && form.dirty"
      required
      autocomplete="off"
    >
  </div>

  <div class="input-field left unit-container">
    <select
      materialize="material_select"
      name="interval-unit"
      [(ngModel)]="rangeUnit"
      (change)="onDateChange()"
      [class.invalid]="!valid && form.dirty"
    >
      <option *ngFor="let option of unitOptions" [value]="option.value">{{option.label}}</option>
    </select>
  </div>
</form>
