/**
 * Service for managing creation of timeseries charts and creating their associated options
 */

import { Injectable } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { ChartColors } from '../../utils/chart-utils';

@Injectable()
export class TimeseriesChartBuilderService {
  generateChartDataSets(dataset: Map<string, number[]>) {
  let datasets: ChartDataSets[] = [];
  let i = 0;
  let others: ChartDataSets;
  dataset.forEach((value, key) => {
    const chartData = {
      label: key,
      data: value.reverse(),
      fill: false,
      backgroundColor: '',
      borderColor: '',
      pointRadius: 4,
      pointHoverRadius: 6
    };
    if (key === 'Others') {
      others = chartData;
    } else {
      chartData.backgroundColor = ChartColors[i];
      chartData.borderColor = ChartColors[i];
      i += 1;
      datasets.push(chartData);
    }
  });
  if (others) {
    others.backgroundColor = ChartColors[i];
    others.borderColor = ChartColors[i];
    datasets.push(others);
  }
  return datasets;
}

  // rfc2822 requires a specific format to be used by the creation of a new date object.
  formatTime(utcDateTimeOffset: string) {
    // the server will always give UTC time, +00:00
    // e.g., 2020-06-09T04:00:00+00:00
    const isoTimeString = utcDateTimeOffset.replace('+00:00', 'Z');
    const convertedTime = new Date(isoTimeString);
    const convertedTimeLabel = convertedTime.toISOString();
    return convertedTimeLabel;
  }
}
