<od-modal
  [modalConfig]="modalConfig"
  (closeClicked)="closeDialog()"
  >

  <div modalContent>
    <div>
      <span>{{this.modalMessage}}</span>
      <br/>
      <br/>
    </div>
    <div>
      <span>
        <a [href]="alertPlanUrl">
          {{this.actionLabel}}
        </a>
      </span>
      <span>
        {{this.actionDescription}}
      </span>
    </div>
  </div>

  <div modalFooter class="dialog-btns">
    <div>
      <button (click)='closeDialog()' class="btn waves-effect">
        <span class="btn-wrapper">{{this.confirmButtonText}}</span>
      </button>
    </div>
  </div>
</od-modal>
