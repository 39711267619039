<div class="configuration-card-v2">

  <div class="add-tenant-card-container" >
    <img class="add-icon-img" [src]="addIconPath" (click)="onClickAdd()">
    <h4 class="tenant-title-text">{{ 'auditing.addTenantCardText' | localize }}</h4>
    <p class="description-text">
      {{'auditing.addTenantCardLearnMoreText' | localize}}
      <a id="learn-more-tenant-link" href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/2#TOPIC-1028134" target="_blank">{{'auditing.addTenantCardLearnMoreLinkText' | localize}}
        <span class="learn-more-icon-box">
          <mat-icon class="learn-more-icon-style" svgIcon="popout"></mat-icon>
        </span>
      </a>
    </p>

    <div class="card-action-buttons-box">
      <od-caption text="ADD TENANT" (captionClicked)="onClickAdd()"></od-caption>
    </div>

  </div>


</div>
