// color brought in from CoreUI
// we can bring in more as we need them
// please find them in quest_colors.scss from CoreUI respository
export enum ODColor {
  GS_Dark_Gray = '#444444',
  Info_Danger = '#d32f2f',
  Info_Blue = '#2196f3',
  Warning_Yellow = '#F2AF00'
}

export enum ODChartColor {
  Danube = '#579ac9',
  Mulberry = '#cc578d',
  Turbo = '#e7d600',
  Deluge = '#77599d',
  SilverTree = '#79bea4',
  RawSienna = '#dc913e',
  Lilac = '#bc99b9',
  WildWillow = '#b2ce6c',
  Cumin = '#9e4523',
  Sinbad = '#a1d2d8',
  TurkishRose = '#a87387',
  Leather = '#8f7153',
  Indigo = '#4b6ebe',
  DustyGray = '#ab949a',
  PetiteOrchid = '#da9e8f',
  White = '#ffffff',
}

export const ODChartColors: ODChartColor[] = [
  ODChartColor.Danube,
  ODChartColor.Mulberry,
  ODChartColor.Turbo,
  ODChartColor.Deluge,
  ODChartColor.SilverTree,
  ODChartColor.RawSienna,
  ODChartColor.Lilac,
  ODChartColor.WildWillow,
  ODChartColor.Cumin,
  ODChartColor.Sinbad,
  ODChartColor.TurkishRose,
  ODChartColor.Leather,
  ODChartColor.Indigo,
  ODChartColor.DustyGray,
  ODChartColor.PetiteOrchid
];
