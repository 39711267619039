/**
 * Query Service for On Demand Auditing.
 *
 */
import { switchMap, take, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { ServiceDiscoveryService } from '../service-discovery.service';
import { Query } from '../../models/query.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

const queryServiceTimeout = 60000;

export interface RunQueryParameters {
  queryId?: string;
  limit?: number;
  skip?: number;
  resultId?: string;
  columns?: string[];
  sortBy?: string;
  sortDir?: string;
}

@Injectable()
export class QueryService {
  public defaultHeaders: HttpHeaders = new HttpHeaders();

  constructor(
    private http: OnDemandTokenHttp,
    private serviceDiscoveryService: ServiceDiscoveryService
  ) {}

  public get defaultTimeout(): number {
    return queryServiceTimeout;
  }

  /**
   * Run a query for auditing
   *
   * @summary Run a query
   * @param query Query to run
   * @param extraParams Extra parameters to include in request body
   * @param adHocMode Whether this query should be run in ad hoc mode
   * @param extraHttpRequestParams Extra settings to apply to HTTP request
   */
  public runQuery(
    query: Query,
    extraParams: any,
    adHocMode: boolean,
    extraHttpRequestParams?: any
  ): Observable<any> {
    return this.runQueryWithHttpInfo(
      query,
      extraParams,
      adHocMode,
      extraHttpRequestParams
    ).pipe(map(response => {
      if (response.status !== 200) {
        return undefined;
      } else {
        return response.body || {};
      }
    }));
  }

  /**
   * Run a query for auditing
   *
   * @param query Query object, whether saved or not
   * @param extraParams Extra parameters to include in request body
   * @param adHocMode Whether this query should be run in ad hoc mode
   * @param extraHttpRequestParams Extra settings for HTTP request
   */
  public runQueryWithHttpInfo(
    query: Query,
    extraParams: any,
    adHocMode: boolean,
    extraHttpRequestParams?: any
  ): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService
      .getQueryUrl$().pipe(
      take(1),
      switchMap((basePath: string) => {
        let path = basePath + '/query';
        let requestBody: any = {};

        if (query.id && !adHocMode) {
          // Use Id in path for saved queries
          path = `${path}/${query.id}`;
        } else {
          // Embed full query body into request if running ad hoc query
          Object.assign(requestBody, query.q);
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Content-Type', 'application/json');
        let validParameters = ['limit', 'skip', 'columns', 'sortBy', 'sortDir'];
        Object.keys(extraParams).forEach((paramName: string) => {
          if (validParameters.includes(paramName)) {
            requestBody[paramName] = extraParams[paramName];
          }
        });

        let requestOptions = {
          headers,
          body: requestBody,
          observe: 'response'
        };

        if (extraHttpRequestParams) {
          requestOptions = Object.assign(
            requestOptions,
            extraHttpRequestParams
          );
        }

        return this.http
          .requestHttp(HttpRequestMethod.POST, path, requestOptions)
          .pipe(timeout(this.defaultTimeout));
      }));
  }

  public getDefaultRequestParameters(): RunQueryParameters {
    return {
      limit: 50,
      skip: 0
    };
  }
}
