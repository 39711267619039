import { Query } from '../../../../auditing/models/query.model';
import { OdaChartType } from '../models/visualization-constants';
import { ONDEMAND_CHART_COLORS } from '@ondemand/core';

export const ChartColors = [
  ONDEMAND_CHART_COLORS.indigo,
  ONDEMAND_CHART_COLORS.rawSienna,
  ONDEMAND_CHART_COLORS.turbo,
  ONDEMAND_CHART_COLORS.cumin,
  ONDEMAND_CHART_COLORS.deluge,
  ONDEMAND_CHART_COLORS.dustyGray,
  ONDEMAND_CHART_COLORS.sinbad,
  ONDEMAND_CHART_COLORS.wildWillow,
  ONDEMAND_CHART_COLORS.mulberry,
  ONDEMAND_CHART_COLORS.petiteOrchid,
  ONDEMAND_CHART_COLORS.silverTree
];

export function isChartQuery(query: Query): boolean {
  return query && query.q && query.q.charts && query.q.charts.length > 0;
}

export function isBarChartQuery(query: Query, index: number = 0): boolean {
  return (
    isChartQuery(query) &&
    query.q.charts[index].bar &&
    query.q.charts[index].bar.id !== null
  );
}

export function isDonutChartQuery(query: Query, index: number): boolean {
  return (
    isChartQuery(query) &&
    query.q.charts[index].donut &&
    query.q.charts[index].donut.id !== null
  );
}

export function isAnomalyTimeseriesChartQuery(
  query: Query,
  index: number
): boolean {
  return (
    isChartQuery(query) && !!query.q.charts[index].timeSeriesAnomalyDetection
  );
}

export function isTimeseriesChartQuery(query: Query, index: number): boolean {
  return isChartQuery(query) && !!query.q.charts[index].timeSeries;
}

export function getTimeseriesChartId(query: Query, index: number = 0): string {
  if (isTimeseriesChartQuery(query, index)) {
    const chartOption = query.q.charts[index].timeSeries;
    if (chartOption) {
      return chartOption.id;
    }
  }
  return undefined;
}

export function getDonutChartId(query: Query, index: number = 0): string {
  if (isDonutChartQuery(query, index)) {
    const chartOption = query.q.charts[index].donut;
    if (chartOption) {
      return chartOption.id;
    }
  }
  return undefined;
}

export function getBarChartId(query: Query, index: number = 0): string {
  if (isBarChartQuery(query, index)) {
    const chartOption = query.q.charts[index].bar;
    if (chartOption) {
      return chartOption.id;
    }
  }
  return undefined;
}

export function getSupportedChartType(
  query: Query,
  index: number
): OdaChartType {
  if (isDonutChartQuery(query, index)) {
    return OdaChartType.Donut;
  } else if (isTimeseriesChartQuery(query, index)) {
    return OdaChartType.TimeSeries;
  } else if (isAnomalyTimeseriesChartQuery(query, index)) {
    return OdaChartType.TimeSeriesAnomalyDetection;
  } else {
    return null;
  }
}
