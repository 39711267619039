import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { EventField } from '../../../../models/event-field.model';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';
import {
  ColumnEditorOptions,
  EditorStatus,
  IColumnData,
  validateVisualParams,
  VisualizeParameters
} from './models/editor-parameters';
import { RenderType } from '../../../../../shared/components/chart/models/visualization-constants';
import { ChartPermissionsService } from '../../../../../shared/components/chart/services/chart-permissions.service';

@Component({
  selector: 'column-editor-flyout',
  templateUrl: './column-editor-flyout.component.html',
  styleUrls: ['./column-editor-flyout.component.scss']
})
export class ColumnEditorFlyoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() fields: EventField[];
  @Input() columns: string[];
  @Input() visualizeParams: VisualizeParameters;
  @Output() columnChange: EventEmitter<ColumnEditorOptions>;

  flyoutHeader: string;
  flyoutDescription: string;
  flyoutColumnTabLabel: string;
  flyoutVisualizationTabLabel: string;
  applyButtonLabel: string;
  cancelButtonLabel: string;

  isOpen = false;
  hasVisualizationTabPermission = false;
  columnTabSelected = true; // by default columns tab is selected.
  visualizationTabSelected = false;
  visualizeEditInProgress = false;
  columnEditInProgress = false;

  // children editor data
  projectionFields: EventField[] = [];

  private columnEditorStatus: EditorStatus;
  private visualizeEditorStatus: EditorStatus;


  // the group by field required in the column editor is the display name
  get groupByDisplayName(): string {
    if (this.visualizeParams && this.visualizeParams.groupByField) {
      return this.visualizeParams.groupByField.displayName;
    }
    return undefined;
  }

  get disableApplyButton(): boolean {
    return (
      this.columnEditInProgress ||
      this.visualizeEditInProgress ||
      // initial status, the user has not touched the editors yet
      (this.columnEditorStatus === EditorStatus.Initialized &&
        this.visualizeEditorStatus === EditorStatus.Initialized) ||
      // valid visualization parameters but groupby column got deleted
      (this.visualizeEditInProgress === false && this.groupByFieldDeleted)
    );
  }

  get groupByFieldDeleted(): boolean {
    if (
      this.visualizeParams &&
      this.visualizeParams.visualizeAs &&
      this.visualizeParams.visualizeAs !== RenderType.Grid &&
      this.visualizeParams.chartType // may need to match the chart types in the future
    ) {
      if (this.visualizeParams.groupByField) {
        const fieldId = this.visualizeParams.groupByField.id;
        const groupByField = this.projectionFields.find(
          field => field.id === fieldId
        );

        if (groupByField) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  constructor(
    private permissionsService: ChartPermissionsService
  ) {
    super();
    this.columnChange = new EventEmitter<ColumnEditorOptions>();
  }

  ngOnInit() {
    this.hasVisualizationTabPermission =
      this.permissionsService.userHasChartEditingPermissions();
    this.setupTemplateText();
  }

  initialize(): void {
    this.visualizationTabSelected = false;
    this.columnTabSelected = true;
    this.columnEditorStatus = EditorStatus.Initialized;
    this.columnEditInProgress = false;
    this.visualizeEditorStatus = EditorStatus.Initialized;
    this.visualizeEditInProgress = false;
    this.projectionFields = this.columns.map(colName =>
      this.fields.find(field => field.id === colName)
    );
  }

  openEditColumnsFlyout(): void {
    this.initialize();
    this.isOpen = true;
  }

  closeEditColumnsFlyout(): void {
    this.isOpen = false;
  }

  onTabClick(tabName: string): void {
    if (tabName === this.flyoutVisualizationTabLabel) {
      if (!this.columnEditInProgress) {
        this.visualizationTabSelected = true;
        this.columnTabSelected = false;
      }
    } else {
      if (!this.visualizeEditInProgress) {
        this.columnTabSelected = true;
        this.visualizationTabSelected = false;
      }
    }
  }

  cancelEditColumns(): void {
    this.closeEditColumnsFlyout();
  }

  saveEditColumns(): void {
    this.columnChange.emit({
      visualizeParameters: this.visualizeParams,
      newColumns: this.projectionFields.map(field => field.id)
    });

    this.closeEditColumnsFlyout();
  }

  onVisualizeParamsUpdated(params: VisualizeParameters): void {
    this.visualizeParams = params;
  }

  onVisualizeEditorStatusUpdated(statusUpdate: EditorStatus): void {
    this.visualizeEditorStatus = statusUpdate;
    this.visualizeEditInProgress =
      this.visualizeEditorStatus === EditorStatus.EditingInProgress;
  }

  onColumnsUpdated(newColumns: IColumnData[]): void {
    this.projectionFields = newColumns.map(col =>
      this.fields.find(field => field.id === col.name)
    );
  }

  onColumnEditorStatusChange(statusUpdate: EditorStatus): void {
    this.columnEditorStatus = statusUpdate;
    this.columnEditInProgress =
      this.columnEditorStatus === EditorStatus.EditingInProgress;
  }

  private setupTemplateText(): void {
    const strProvider =
      AuditingDisplayStringsProvider.auditing.pages.newSearches;
    this.flyoutHeader = strProvider.flyoutHeader;
    this.flyoutDescription = strProvider.flyoutDescription;
    this.flyoutColumnTabLabel = strProvider.flyoutColumnTabLabel;
    this.flyoutVisualizationTabLabel = strProvider.flyoutVisualizationTabLabel;
    this.applyButtonLabel = strProvider.applyColumnChangesButton;
    this.cancelButtonLabel = strProvider.cancelEditColumnsButton;
  }
}
