<general-qod-component
  [loading]="isLoadingData"
  [error]="errorOccurred"
  [data]="dataExist"
  [height]="cardHeight"
  [card]="true"
>
  <div component-header class="card-header">
    <qod-multiselect-dropdown
      *ngIf="chartDataReady"
      listId="topActiveUser-list"
      [dropdownText]="dropdownText"
      [allSelectionHeaderText]="dropdownSelectAllHeader"
      [allSelectionSubText]="dropdownSelectAllDescription"
      [items]="this.topActiveUserModel.availableServices"
      [selected]="this.topActiveUserModel.selectedServices"
      (selectedChange)="onSelectedServicesChanged($event)"
    ></qod-multiselect-dropdown>
    <card-header-with-date [header]="cardHeader" [date]="dateHeader">
    </card-header-with-date>
  </div>

  <skeleton-block component-loading type="TitleWithLineGraph"></skeleton-block>

  <div component-error>
    <error-card
      [error]="errorLabel"
      [button]="tryAgainLabel"
      (buttonClick)="loadTopActiveUser()"
    ></error-card>
  </div>

  <div component-data>
    <ng-container *ngIf="chartDataReady">
      <base-generic-chart
        [displayLegend]="false"
        [chartLabels]="this.topActiveUserModel.userDisplyLabels"
        [options]="chartOptions"
        [chartType]="chartType"
        [containerStyle]="containerStyle"
        [chartPosition]="chartPosition"
        [datasets]="chartDatasets"
        [chartColors]="chartColors"
      >
      </base-generic-chart>
    </ng-container>
  </div>

  <div component-footer>
    <button
      type="button"
      class="btn-flat top-active-users-widget-edit-button"
      (click)="handleEditButtonClick()"
      [disabled]="!userHasPermissionToEditor || !chartDataReady || editorOpened"
    >
      <edc-icon name="cog"></edc-icon>
      {{ this.editBtnText }}
    </button>
    <span *ngIf="topActiveUserModel.excludedUsersCount > 0" class="filter-count">
      {{ this.topActiveUserModel.excludedUsersCount }}
    </span>

    <excluded-users-editor (closeEvent)="handleEditorClose()"></excluded-users-editor>
  </div>
  
</general-qod-component>
