import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  EConsentStatus,
  EPermissionType,
  AppFacadeService,
  AppState,
  DisplayStringsProvider,
  State
} from '@ondemand/core';

import { ConsentService } from '../../services/consent.service';
import { LocaleStringsService } from '../../services/locale-strings.service';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { auditModuleName } from '../../util/constants';

import * as fromPermissions from '../../models/audit-permissions.model';

export interface ConsentUrlResponse {
  adminConsentUrl: string;
  moduleName: string;
  permissionType: string;
  questTenantId: string;
}

/**
 * Determines whether a tenant has been given consent for the Auditing module.
 * If not, it provides a link to redirect the user to Azure to grant consent.
 *
 * @export
 */
@Component({

  selector: 'consent-link',
  templateUrl: './consent-link.component.html',
  styleUrls: ['./consent-link.component.scss']
})
export class ConsentLinkComponent implements OnInit, OnDestroy {

  /**
   * Tracks an error on this component
   *
   */
  errorMessage: string;

  /**
   * A boolean value indicating whether there was an error loading tenant
   * information
   *
   */
  tenantHasError = false;

  /**
   * Indicates if the component is attempting to determine consent status
   *
   */
  loadingAdminConsent = false;

  /**
   * A predefined list of statuses that Core may send back about the tenant
   *
   */
  consentStatus: EConsentStatus;

  /**
   * Display strings provided by `@ondemand/core`
   *
   */
  // TODO: Localize
  displayStringsProvider = DisplayStringsProvider;

  labels: any;

  /**
   * Core-generated id of the tenant
   *
   */
  @Input() questTenantId: string;

  /**
   * Tracks if consent if still being registered in Core
   *
   */
  @Input() consentRegistrationInProgress = false;

  @Input() hasManageAzureADPermission = false;

  fromPermissions = fromPermissions;

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  get isTenantConfigured() {
    return this.consentStatus === EConsentStatus.Consented
      && !this.tenantHasError
      && !this.consentRegistrationInProgress
      && !this.loadingAdminConsent;
  }

  get isConsentPending() {
    return this.consentStatus === EConsentStatus.Pending;
  }

  get isConsentRevoked() {
    return this.consentStatus === EConsentStatus.Revoked;
  }

  get isLoadingConsent() {
    return this.loadingAdminConsent || this.consentRegistrationInProgress;
  }

  get isNoConsent() {
    return this.consentStatus === EConsentStatus.NotConsented;
  }

  constructor(private consentService: ConsentService,
              private store: Store<State>,
              private facade: AppFacadeService,
              private localeStringsService: LocaleStringsService
             ) {}

  ngOnInit() {
    this.localeStringsService.strings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.labels = data;
      this.loadConsentStatus();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Checks the status of the consent for this tenant
   *
   */
  loadConsentStatus() {
    this.setUpLoadingIndicators();
    this.consentService.hasAdminConsentForStandardPermission(this.questTenantId).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.consentStatus = response;
      this.disableLoadingIndicators();
    }, error => {
      this.setComponentToErrorState(error, this.labels.auditing.errorConsentStatus);
    });
  }

  /**
   * Retrieves the redirect url for this tenant, prepares the app for sending
   * the user to Azure, and then redirects the user.
   *
   */
  handleClickToGrantConsent() {
    this.store.dispatch(new AppState.Actions.Loading.StartLoadingAction(this.displayStringsProvider.redirectingToOffice365Page));
    this.tenantHasError = false;

    const params = {
      questTenantId: this.questTenantId,
      module: auditModuleName,
      permissionType: EPermissionType.STANDARD,
      redirect: true
    };
    this.facade.requestTenantConsent$(params)
      .then(null, (error) => {
        this.handleErrorBuildingConsentUrl(error);
      });
  }

  private handleErrorBuildingConsentUrl(error: any) {
    this.tenantHasError = true;
    this.errorMessage = this.labels.auditing.errorLoadingConsentLink;
    this.store.dispatch(new AppState.Actions.Loading.ErrorLoadingAction());
    this.store.dispatch(new AppState.Actions.Loading.StopLoadingAction());
    console.error(error);
  }

  private setUpLoadingIndicators() {
    this.loadingAdminConsent = true;
  }

  private disableLoadingIndicators() {
    this.loadingAdminConsent = false;
  }

  private setComponentToErrorState(error: any, errorMessage: string) {
    this.disableLoadingIndicators();
    this.tenantHasError = true;
    this.errorMessage = errorMessage;
    if (error) {
      console.error(error);
    }
  }
}
