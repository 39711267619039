import { of, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { AuditingDisplayStringsProvider } from '../../application-strings-EN';
import { AppFacadeService } from '@ondemand/core';
import { getPseudoValue } from '../util/pseudo-locale';

export const pseudoLocale = 'test';
const supportedLocales = [pseudoLocale, 'en'];
const defaultLocale = 'en';

@Injectable()
export class LocaleStringsService implements OnDestroy {
  strings$ = new ReplaySubject<any>(1);
  private dict: any;
  private language: string;
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private facade: AppFacadeService) {
    this.facade.language$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((languageCode) => {
      this.getLocaleStrings(languageCode).pipe(take(1)).subscribe((dict) => {
        this.dict = dict;
        this.language = languageCode;
        this.strings$.next(dict);
      });
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Get Observable for a string with a particular path
   *
   * @param path Dot-delimited path to value in dictionary
   * @param substitutions Replacement values for templated strings
   */
  string$(path: string, substitutions?: any) {
    return this.strings$.pipe(map(() => this.get(path, substitutions)));
  }

  /**
   * Get string synchronously from latest copy of string dictionary
   *
   * @param path Dot-delimited path to value in dictionary
   * @param substitutions Replacement values for templated strings
   */
  get(path: string, substitutions?: any): string {
    if (!this.dict) {
      throw Error('Dictionary not loaded.');
    }

    if (!path) {
      throw Error(`No path provided for localization: ${path}`);
    }
    let pathPieces = path.split('.');

    let currentNode = this.dict;
    for (let key of pathPieces) {
      if (!(key in currentNode)) {
        console.error(`Invalid path for string: "${path}"`);
        return '';
      }
      currentNode = currentNode[key];
    }

    if (substitutions) {
      Object.keys(substitutions).forEach(key => {
        const pattern = new RegExp(`{${key}}`, 'g');
        currentNode = currentNode.replace(pattern, substitutions[key]);
      });

    }

    if (this.language === pseudoLocale) {
      currentNode = getPseudoValue(currentNode);
    }

    return currentNode;
  }

  getLocaleStrings(locale: string): Observable<any> {
    const selectedLocale = this.getMatchingLocale(locale);
    // TODO: Make this load locale data over HTTP
    if (selectedLocale === 'en') {
      return of(AuditingDisplayStringsProvider);
    } else if (selectedLocale === pseudoLocale) {
      return of(AuditingDisplayStringsProvider);
    } else {
      return of(AuditingDisplayStringsProvider);
    }
  }

  /**
   * Find a compatible locale to use, falling back to the default if no match is found
   *
   * @param locale Locale string, e.g., "en-US", "fr-CA"
   */
  private getMatchingLocale(locale: string): string {
    if (supportedLocales.includes(locale)) {
      return locale;
    }

    // Attempt to extract primary language from locale string
    // E.g., get "en" from "en-US"
    if (locale.length > 2 ) {
      let shortCode = locale.substring(0, 2);
      if (supportedLocales.includes(shortCode)) {
        return shortCode;
      }
    }

    console.warn(`No locale data to match locale "${locale}". Defaulting to "${defaultLocale}."`);
    return defaultLocale;
  }

}
