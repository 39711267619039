import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SigninsDataService } from '../services/sign-ins.data.service';
import {
  LoadSignins,
  LoadSigninsFail,
  LoadSigninsSuccess,
  LOAD_SIGN_INS,
  UpdateSignins,
  UpdateSigninsFail,
  UpdateSigninsSuccess,
  UPDATE_SIGN_INS
} from './sign-ins.actions';

@Injectable()
export class SigninsEffects {
  loadSignins$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadSignins>(LOAD_SIGN_INS),
    switchMap(action =>
      this.signinsService.getSignins().pipe(
        map(response => new LoadSigninsSuccess(response)),
        catchError(error => of(new LoadSigninsFail(error)))
      )
    )
  ));

  updateSignins$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateSignins>(UPDATE_SIGN_INS),
    switchMap(action =>
      this.signinsService.updateSelectedSignins(action.payload).pipe(
        map(response => response.status === 200
            ? new UpdateSigninsSuccess()
            : new UpdateSigninsFail(
                `Unexpected response code received: ${response.status}`
              )),
        catchError(error => of(new UpdateSigninsFail(error)))
      )
    )
  ));

  constructor(
    private signinsService: SigninsDataService,
    private actions$: Actions
  ) {}
}
