/**
 * Component for displaying Change Auditor installation info
 */

import { finalize, take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  BaseComponent,
  EDialogType,
  Util,
  AppFacadeService
} from '@ondemand/core';
import { ChangeAuditorInstallation } from '../../../models/change-auditor-installation.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { ChangeAuditorInstallationService } from '../../../services/ca-installation.service';
import { changeAuditorSubscriptionURL } from '../../../util/constants';
import {
  EAuditFeatureName,
  FeatureSubscriptionService
} from '../../../services/feature-subscription/feature-subscription.service';
import { SimpleChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';


@Component({
  selector: 'ca-installation-card',
  templateUrl: './ca-installation-card.component.html',
  styleUrls: ['./ca-installation-card.component.scss']
})
export class ChangeAuditorInstallationCardComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  @Input() installation: ChangeAuditorInstallation;
  @Input() updating: boolean;
  @Output() delete = new EventEmitter();
  showLoadingIndicator = false;
  activeTimeout = false;
  dropdownOptions: any = {
    constrainWidth: false,
    belowOrigin: true,
    alignment: 'right'
  };
  deleting = false;
  pausing = false;
  resuming = false;

  constructor(
    private facade: AppFacadeService,
    private localeStrings: LocaleStringsService,
    private caInstallationService: ChangeAuditorInstallationService,
    private featureSubscriptionService: FeatureSubscriptionService,
    private toastService: ODToastService
  ) {
    super();
  }

  ngOnInit() {
    return;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.installation) {
      this.fixActivityDate();
    }
  }

  get numCoordinatorsConnected(): number {
    if (this.installation) {
      return this.installation.coordinators.filter(
        coordinator => coordinator.connected
      ).length;
    } else {
      return 0;
    }
  }

  /**
   * Handle clicking on "Remove Installation"
   */
  async onRemoveClick() {
    const title = await this.localeStrings
      .string$('auditing.caInstallationRemoveConfirmTitle').pipe(
      take(1))
      .toPromise();
    const message = await this.localeStrings
      .string$('auditing.caInstallationRemoveConfirmMessage').pipe(
      take(1))
      .toPromise();

    if (
      await this.facade.confirm(title, message, { type: EDialogType.WARNING })
    ) {
      this.deleting = true;
      this.caInstallationService
        .deleteInstallation(this.installation.id).pipe(
        finalize(() => (this.deleting = false)))
        .subscribe(
          async response => {
            if (response.status === 200) {
              this.onRemovalSuccess();
            } else {
              console.error('Unexpected response:', response);
              this.showRemovalErrorToast();
            }
          },
          async (response: HttpErrorResponse) => {
            const body = response.error;
            if (
              response.status === 500 &&
              body.error &&
              body.error.code === 'CoordinatorUnreachable'
            ) {
              let forceRemovalTitle = await this.localeStrings
                .string$('auditing.caInstallationUnreachableErrorTitle').pipe(
                take(1))
                .toPromise();
              let forceRemovalMessage = await this.localeStrings
                .string$('auditing.caInstallationUnreachableError').pipe(
                take(1))
                .toPromise();
              if (
                await this.facade.confirm(
                  forceRemovalTitle,
                  forceRemovalMessage
                )
              ) {
                this.forceRemoveInstallation();
              }
            } else {
              console.error('Error deleting:', response);
              this.showRemovalErrorToast();
            }
          }
        );
    }
  }

  async onPauseClick() {
    const title = await this.localeStrings
      .string$('auditing.caInstallationPauseConfirmTitle').pipe(
      take(1))
      .toPromise();
    const message = await this.localeStrings
      .string$('auditing.caInstallationPauseConfirmMessage').pipe(
      take(1))
      .toPromise();

    if (
      await this.facade.confirm(title, message, { type: EDialogType.WARNING })
    ) {
      this.pausing = true;
      this.caInstallationService
        .pauseInstallation(this.installation.id).pipe(
        finalize(() => (this.pausing = false)))
        .subscribe(
          async response => {
            if (response.status === 200) {
              const toastMessage = await this.localeStrings
                .string$('auditing.caInstallationPauseSuccess').pipe(
                take(1))
                .toPromise();
              toastLowerLeft(this.toastService, toastMessage, ToastType.Success);
              this.setInstallation(response.body);
            } else {
              console.error('Unexpected response:', response);
              this.onPauseError();
            }
          },
          async (response: Response) => {
            console.error('Error pausing:', response);
            this.onPauseError();
          }
        );
    }
  }

  async onResumeClick() {
    let validSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR).pipe(
      take(1))
      .toPromise();
    let expiredSubscription = await this.featureSubscriptionService
      .getSubscription$(EAuditFeatureName.CHANGE_AUDITOR_EXPIRED).pipe(
      take(1))
      .toPromise();

    if (validSubscription) {
      this.promptToResume();
    } else if (expiredSubscription) {
      let daysToDeletion = Util.daysUntil(
        expiredSubscription.expiryDate,
        expiredSubscription.daysNoticeOffboarded
      );
      this.promptToRenew(daysToDeletion);
    } else {
      console.error(
        'Cannot resume. Invalid subscription state for Change Auditor. The subscription is neither valid nor expired'
      );
    }
  }

  private fixActivityDate() {
    const dateReported = new Date(this.installation.lastActivityDate);
    const now = new Date();
    const dateIsInTheFuture = dateReported > now;
    if (dateIsInTheFuture) {
      this.installation.lastActivityDate = now.toISOString();
    }
  }

  private setInstallation(installation: ChangeAuditorInstallation) {
    this.installation = installation;
    this.fixActivityDate();
  }

  private async promptToResume() {
    const title = await this.localeStrings
      .string$('auditing.caInstallationResumeConfirmTitle').pipe(
      take(1))
      .toPromise();
    const message = await this.localeStrings
      .string$('auditing.caInstallationResumeConfirmMessage').pipe(
      take(1))
      .toPromise();

    if (
      await this.facade.confirm(title, message, { type: EDialogType.WARNING })
    ) {
      this.resuming = true;
      this.caInstallationService
        .resumeInstallation(this.installation.id).pipe(
        finalize(() => (this.resuming = false)))
        .subscribe(
          async response => {
            if (response.status === 200) {
              const toastMessage = await this.localeStrings
                .string$('auditing.caInstallationResumeSuccess').pipe(
                take(1))
                .toPromise();
              toastLowerLeft(this.toastService, toastMessage, ToastType.Success);
              this.setInstallation(response.body);
            } else {
              console.error('Unexpected response:', response);
              this.onResumeError();
            }
          },
          async (response: Response) => {
            console.error('Error unpausing:', response);
            this.onResumeError();
          }
        );
    }
  }

  private async onPauseError() {
    const toastMessage = await this.localeStrings
      .string$('auditing.caInstallationPauseError').pipe(
      take(1))
      .toPromise();
    toastLowerLeft(this.toastService, toastMessage, ToastType.Error);
  }

  private async onResumeError() {
    const toastMessage = await this.localeStrings
      .string$('auditing.caInstallationResumeError').pipe(
      take(1))
      .toPromise();
    toastLowerLeft(this.toastService, toastMessage, ToastType.Error);
  }

  /**
   * Display message after installation removal succeeds
   */
  private async onRemovalSuccess() {
    let successMessage = await this.localeStrings
      .string$('auditing.caInstallationRemoveSuccess').pipe(
      take(1))
      .toPromise();
    toastLowerLeft(this.toastService, successMessage, ToastType.Success);
    this.delete.emit(this.installation.id);
  }

  /**
   * Send a request to force removal of this installation
   */
  private forceRemoveInstallation() {
    const forceRemoval = true;
    this.deleting = true;
    this.caInstallationService
      .deleteInstallation(this.installation.id, forceRemoval).pipe(
      finalize(() => (this.deleting = false)))
      .subscribe(
        response => {
          if (response.status === 200) {
            this.onRemovalSuccess();
          } else {
            console.error('Unexpected response:', response);
            this.showRemovalErrorToast();
          }
        },
        (response: Response) => {
          console.error('Failed to force removal:', response);
          this.showRemovalErrorToast();
        }
      );
  }

  private async showRemovalErrorToast() {
    const message = await this.localeStrings
      .string$('auditing.caInstallationRemoveError').pipe(
      take(1))
      .toPromise();
    toastLowerLeft(this.toastService, message, ToastType.Error);
  }

  private async promptToRenew(daysToDeletion: number) {
    const title = await this.localeStrings
      .string$('auditing.renewSubscriptionTitle').pipe(
      take(1))
      .toPromise();
    let message = await this.localeStrings
      .string$('auditing.cannotResumeExpiredMessage', { daysToDeletion }).pipe(
      take(1))
      .toPromise();
    const settings = {
      type: EDialogType.ERROR,
      okText: await this.localeStrings
        .string$('auditing.renewPromptButtonLabel').pipe(
        take(1))
        .toPromise()
    };
    if (await this.facade.confirm(title, message, settings)) {
      this.facade.openInNewWindow(changeAuditorSubscriptionURL);
    }
  }
}
