import {
  CriticalActivityResponse,
  UpdateCriticalActivitySettings
} from '../../../../../shared/models/critical-activity.shared.models';

export const LOAD_CRITICAL_ACTIVITY_LIST =
  '[Critical Activity Tab] Load Critical Activity List';
export const LOAD_CRITICAL_ACTIVITY_LIST_SUCCESS =
  '[Critical Activity Tab] Load Critical Activity List Success';
export const LOAD_CRITICAL_ACTIVITY_LIST_FAIL =
  '[Critical Activity Tab] Load Critical Activity List Fail';

export const UPDATE_CRITICAL_ACTIVITY_LIST =
  '[Critical Activity Tab] Update Critical Activity List';
export const UPDATE_CRITICAL_ACTIVITY_LIST_SUCCESS =
  '[Critical Activity Tab] Update Critical Activity List Success';
export const UPDATE_CRITICAL_ACTIVITY_LIST_FAIL =
  '[Critical Activity Tab] Update Critical Activity List Fail';

export class LoadCriticalActivityList {
  readonly type = LOAD_CRITICAL_ACTIVITY_LIST;
}

// eslint-disable-next-line max-classes-per-file
export class LoadCriticalActivityListSuccess {
  readonly type = LOAD_CRITICAL_ACTIVITY_LIST_SUCCESS;
  constructor(public payload: CriticalActivityResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadCriticalActivityListFail {
  readonly type = LOAD_CRITICAL_ACTIVITY_LIST_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateCriticalActivityList {
  readonly type = UPDATE_CRITICAL_ACTIVITY_LIST;
  constructor(public payload: UpdateCriticalActivitySettings) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateCriticalActivityListSuccess {
  readonly type = UPDATE_CRITICAL_ACTIVITY_LIST_SUCCESS;
}

// eslint-disable-next-line max-classes-per-file
export class UpdateCriticalActivityListFail {
  readonly type = UPDATE_CRITICAL_ACTIVITY_LIST_FAIL;
  constructor(public payload: any) {}
}

export type CriticalActivityListAction =
  | LoadCriticalActivityList
  | LoadCriticalActivityListSuccess
  | LoadCriticalActivityListFail
  | UpdateCriticalActivityList
  | UpdateCriticalActivityListSuccess
  | UpdateCriticalActivityListFail;
