import { FavoriteSearchEditorComponent } from './editor/favorite-search-editor.component';
import { UpdateFavoriteSearchService } from './editor/services/update-favorite-search.service';
import { FavoriteSearchEditorEffects } from './editor/state/favorite-search-editor.effects';
import { FavoriteSearchComponent } from './favorite-search.component';
import { FavoriteSearchService } from './services/favorite-search.service';
import { FavoriteSearchEffects } from './state/favorite-search.effects';

export const favoriteSearchFiles = {
  components: [FavoriteSearchComponent, FavoriteSearchEditorComponent],
  effects: [FavoriteSearchEffects, FavoriteSearchEditorEffects],
  services: [FavoriteSearchService, UpdateFavoriteSearchService]
};
