import { AuditSerivceName } from '../../../../../../shared/models/audit-workload.models';
import {
  TopActiveUsersByTargetService,
  TopActiveUsersData,
  TopActiveUsersWidgetResponse
} from './top-active-users.server.response';
import { Query } from '../../../../../models/query.model';
import { ChartColor, ChartDataSets } from 'chart.js';
import { cloneAndUnlockObject } from '../../../../../../shared/utils/object.tools';
import { UpdateTopActiveUsersSettings } from '../../../../../../shared/models/update-widget-configuration';
import { UpdateTopActiveUsersConfig } from '../state/top-active-users.actions';
import { FixedWidthLabelCaculator } from '../../../../../../shared/utils/fixed-width-label.calculator';
import { ODChartColor } from '../../../../../../shared/models/oda-colors';

export interface UserDetails {
  fullUsername: string;
  eventCount: number;
}

export interface UserToolTipInfo {
  username: string;
  serviceCounts: AuditServiceWithCount[];
}

export class TopActiveUserDataModel {
  public availableServices: AuditSerivceName[];

  public selectedServices: AuditSerivceName[];

  public userToolTips: UserToolTipInfo[];

  public queries: Query[];

  // caculated & truncated usernames for fixed-width display labels on chart
  public userDisplyLabels: string[];

  private _excludedUsers: string[];

  private _usernames: string[];

  private _serviceAndUserDetails: AuditServiceWithUserDetails[];

  public get excludedUsersCount(): number {
    return this._excludedUsers.length;
  }

  public get chartDataSets(): ChartDataSets[] {
    return this._serviceAndUserDetails.map(service => ({
        data: service.users.map(user => user.eventCount),
        backgroundColor: service.auditServiceColor,
        hoverBackgroundColor: AuditServiceHoverBackgroundColorMapping.get(service.serviceName),
        label: service.serviceName
      } as ChartDataSets));
  }

  public get maxUserTotalEventCount(): number {
    // the maximum total event count by user
    const eventTotals: number[] = this.userToolTips.map(tooltipInfo => tooltipInfo.serviceCounts
        .map(serviceCountInfo => serviceCountInfo.eventCount)
        .reduce((a, b) => a + b, 0));

    if (eventTotals.length > 0) {
      return Math.max(...eventTotals);
    } else {
      return 0;
    }
  }

  public get createUpdateAction(): UpdateTopActiveUsersConfig {
    const updateSettings = {
      selectedServices: this.selectedServices,
      excludedUsers: this._excludedUsers
    } as UpdateTopActiveUsersSettings;
    return new UpdateTopActiveUsersConfig(updateSettings);
  }

  constructor(private _serverResponse: TopActiveUsersWidgetResponse = null) {
    // necessary!! need to make the serverresponse mutable
    this._serverResponse = cloneAndUnlockObject(this._serverResponse);
    this.initialize();
  }

  private initialize(): void {
    this.resetToDefaultValues();

    if (isTopActiveUserConfigValid(this._serverResponse)) {
      const config = this._serverResponse.configuration.topActiveUsers;
      this._excludedUsers = config.excludedUsers;
      this.availableServices = config.availableServices.map(
        auditService => auditService.serviceName
      );
      this.selectedServices = config.selectedServices;
    }

    if (isTopActiveUserDataValid(this._serverResponse)) {
      this.assignDirectMappingFields();
      this.extractAuditServiceDetails();
      this.extractUserToolTips();
      this.calculateDisplayLabels();
    }
  }

  private resetToDefaultValues() {
    this._excludedUsers = [];
    this._serviceAndUserDetails = [];
    this.userDisplyLabels = [];
    this._usernames = [];
    this.queries = [];
    this.userToolTips = [];
    this.availableServices = [];
    this.selectedServices = [];
  }

  private assignDirectMappingFields(): void {
    const data: TopActiveUsersData = this._serverResponse.data.topActiveUsers;
    this.queries = data.queries;
    this._usernames = data.labels;
  }

  private extractAuditServiceDetails(): void {
    const serviceInfos: TopActiveUsersByTargetService[] =
      this._serverResponse.data.topActiveUsers.datasets;
    const fullUserNames: string[] =
      this._serverResponse.data.topActiveUsers.labels;

    this._serviceAndUserDetails = serviceInfos.map(serviceInfo => ({
        serviceName: serviceInfo.label,
        users: composeUsersPerService(fullUserNames, serviceInfo.data),
        auditServiceColor: getAuditServiceColor(serviceInfo.label)
      } as AuditServiceWithUserDetails));
  }

  private extractUserToolTips(): void {
    const userFullNames: string[] =
      this._serverResponse.data.topActiveUsers.labels;
    const serviceInfos: TopActiveUsersByTargetService[] =
      this._serverResponse.data.topActiveUsers.datasets;

    this.userToolTips = userFullNames.map((fullName, userIndex) => ({
        username: fullName,
        serviceCounts: serviceInfos
          .filter(serviceInfo => serviceInfo.data[userIndex] > 0)
          .map(serviceInfo => ({
              serviceName: serviceInfo.label,
              auditServiceColor: getAuditServiceColor(serviceInfo.label),
              eventCount: serviceInfo.data[userIndex]
            } as AuditServiceWithCount))
      } as UserToolTipInfo));
  }

  private calculateDisplayLabels(): void {
    const labelCaculator = new FixedWidthLabelCaculator();
    this.userDisplyLabels = this._usernames.map(username =>
      labelCaculator.getFixedWidthLabel(username)
    );
  }
}

export function isTopActiveUserDataValid(
  serverResponse: TopActiveUsersWidgetResponse
): boolean {
  const dataExists: boolean =
    !!serverResponse &&
    !!serverResponse.data &&
    !!serverResponse.data.topActiveUsers;

  if (!dataExists) {
    return false;
  }

  const data: TopActiveUsersData = serverResponse.data.topActiveUsers;
  const requiredFiledsValid: boolean =
    !!data.datasets &&
    data.datasets.length > 0 &&
    !!data.labels &&
    !!data.queries;

  return requiredFiledsValid;
}

export function isTopActiveUserConfigValid(
  serverResponse: TopActiveUsersWidgetResponse
): boolean {
  const configExists: boolean =
    !!serverResponse &&
    !!serverResponse.configuration &&
    !!serverResponse.configuration.topActiveUsers;

  if (!configExists) {
    return false;
  }

  const config = serverResponse.configuration.topActiveUsers;

  const requiredFiledsValid: boolean =
    !!config.availableServices &&
    !!config.selectedServices &&
    !!config.excludedUsers;

  return requiredFiledsValid;
}

export function isToolTipDataValid(tooltipData: UserToolTipInfo) {
  return !!(
    tooltipData &&
    tooltipData.username &&
    tooltipData.serviceCounts &&
    tooltipData.serviceCounts.length > 0
  );
}

export const AuditServiceColorMapping = new Map<AuditSerivceName, ODChartColor>([
  [AuditSerivceName.ActiveDirectory, ODChartColor.Lilac],
  [AuditSerivceName.LogonActivity, ODChartColor.WildWillow],
  [AuditSerivceName.Exchange, ODChartColor.Leather],
  [AuditSerivceName.Teams, ODChartColor.DustyGray],
  [AuditSerivceName.SharePoint, ODChartColor.SilverTree],
  [AuditSerivceName.GroupPolicy, ODChartColor.Mulberry],
  [AuditSerivceName.ChangeAuditorFileSystem, ODChartColor.Turbo],
  [AuditSerivceName.OneDrive, ODChartColor.Deluge],
  [AuditSerivceName.OnDemandAudit, ODChartColor.RawSienna],
  [AuditSerivceName.AzureActiveDirectory, ODChartColor.Sinbad],
  [AuditSerivceName.ADFS, ODChartColor.Indigo],
  [AuditSerivceName.ActiveDirectoryDatabase, ODChartColor.Cumin]
]);

export const AuditServiceHoverBackgroundColorMapping = new Map<AuditSerivceName, string>([
  [AuditSerivceName.ActiveDirectory, window.Chart.helpers.getHoverColor(ODChartColor.Lilac)],
  [AuditSerivceName.LogonActivity, window.Chart.helpers.getHoverColor(ODChartColor.WildWillow)],
  [AuditSerivceName.Exchange, window.Chart.helpers.getHoverColor(ODChartColor.Leather)],
  [AuditSerivceName.Teams, window.Chart.helpers.getHoverColor(ODChartColor.DustyGray)],
  [AuditSerivceName.SharePoint, window.Chart.helpers.getHoverColor(ODChartColor.SilverTree)],
  [AuditSerivceName.GroupPolicy, window.Chart.helpers.getHoverColor(ODChartColor.Mulberry)],
  [AuditSerivceName.ChangeAuditorFileSystem, window.Chart.helpers.getHoverColor(ODChartColor.Turbo)],
  [AuditSerivceName.OneDrive, window.Chart.helpers.getHoverColor(ODChartColor.Deluge)],
  [AuditSerivceName.OnDemandAudit, window.Chart.helpers.getHoverColor(ODChartColor.RawSienna)],
  [AuditSerivceName.AzureActiveDirectory, window.Chart.helpers.getHoverColor(ODChartColor.Sinbad)],
  [AuditSerivceName.ADFS, window.Chart.helpers.getHoverColor(ODChartColor.Indigo)],
  [AuditSerivceName.ActiveDirectoryDatabase, window.Chart.helpers.getHoverColor(ODChartColor.Cumin)]
]);

// non-exposed functions and interfaces below

function getAuditServiceColor(serviceName: string): ChartColor {
  return AuditServiceColorMapping.get(serviceName as AuditSerivceName);
}

function composeUsersPerService(
  fullUsernames: string[],
  eventCounts: number[]
): UserDetails[] {
  const results: UserDetails[] = [];
  for (let i = 0; i < fullUsernames.length; i++) {
    const user = {
      fullUsername: fullUsernames[i],
      eventCount: eventCounts[i]
    } as UserDetails;

    results.push(user);
  }
  return results;
}

interface AuditServiceColorInfo {
  serviceName: AuditSerivceName;
  auditServiceColor: ChartColor;
}

interface AuditServiceWithUserDetails extends AuditServiceColorInfo {
  users: UserDetails[];
}

interface AuditServiceWithCount extends AuditServiceColorInfo {
  eventCount: number;
}
