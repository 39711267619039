import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import {
  BaseChartType,
  BaseComponent,
  ONDEMAND_CHART_COLORS
} from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../application-strings-EN';
import { Query } from '../../../../auditing/models/query.model';
import { ChartQueryService } from '../services/chart-query.service';
import { Results } from '../../../../auditing/components/queries/results/results.model';
import { catchError, take } from 'rxjs/operators';
import { isAnomalyTimeseriesChartQuery } from '../utils/chart-utils';
import { AnomalyTimeseriesChartDataExtractorService } from './services/anomaly-timeseries-chart-data-extractor.service';
import { AnomalyTimeseriesChartBuilderService } from './services/anomaly-timeseries-chart-builder.service';
import { ChartDataSets } from 'chart.js';
import { cloneAndUnlockObject } from '../../../utils/object.tools';
import { CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH } from '../../../critical-activity/configuration/critical-activity.config';
import { InvokeWithErrorHandling } from '../../../utils/error.handling.wrapper';
import { throwError } from 'rxjs';

@Component({
  selector: 'anomaly-timeseries-chart',
  templateUrl: './anomaly-timeseries.component.html',
  styleUrls: ['./anomaly-timeseries.component.scss']
})
export class AnomalyTimeseriesComponent
  extends BaseComponent
  implements OnInit {
  results: Results;
  @Input() query: Query;
  @Output() renderCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() errorLoadingChart: EventEmitter<string> =
    new EventEmitter<string>();
  noEvents: boolean;
  applicationStrings = AuditingDisplayStringsProvider.auditing;
  noResultsImagePath = CRITICAL_ACTIVITY_NO_RESULTS_FOUND_IMAGE_PATH;
  displayLegend = false;
  chartLabels: string[];
  options: any;
  chartType = BaseChartType.Line;
  datasets: ChartDataSets[];
  chartColors = [
    ONDEMAND_CHART_COLORS.indigo,
    ONDEMAND_CHART_COLORS.rawSienna,
    ONDEMAND_CHART_COLORS.danube
  ];
  timeSeriesInitialized = false;
  readonly containerStyle = { width: '90%' };
  readonly chartPosition = { width: '100%', height: '35rem' };

  constructor(
    private chartService: ChartQueryService,
    private chartBuilderService: AnomalyTimeseriesChartBuilderService,
    private chartDataExtractorService: AnomalyTimeseriesChartDataExtractorService
  ) {
    super();
  }

  ngOnInit() {
    InvokeWithErrorHandling(
      () => {
        this.runChartQuery();
      },
      err => {
        this.handleError(err);
      }
    );
  }

  runChartQuery() {
    if (isAnomalyTimeseriesChartQuery(this.query, 0)) {
      const cloneQuery = cloneAndUnlockObject<Query>(this.query);
      this.updateTimeZoneOffsetOfChart(cloneQuery);
      this.chartService
        .runAdhocChartQuery(
          cloneQuery,
          cloneQuery.q.charts[0].timeSeriesAnomalyDetection.id
        )
        .pipe(
          take(1),
          catchError(err => throwError(err))
        )
        .subscribe(
          response =>
            InvokeWithErrorHandling(
              () => {
                this.renderTimeseriesChart(response.body);
              },
              err => {
                this.handleError(err);
              }
            ),
          err => {
            this.handleError(err);
          }
        );
    }
  }

  renderTimeseriesChart(results: Results) {
    this.noEvents = results.totalRows < 1;
    const anomaliesData =
      this.chartDataExtractorService.getTimeseriesRowData(results);

    if (!this.timeSeriesInitialized) {
      const extendableQuery = cloneAndUnlockObject<Query>(this.query);
      this.datasets =
        this.chartBuilderService.generateTimeseriesDatasets(anomaliesData);
      this.chartLabels = anomaliesData.labels;
      this.options = this.chartBuilderService.generateChartOptions(
        this.chartLabels,
        anomaliesData.anomalies,
        extendableQuery
      );
      this.timeSeriesInitialized = true;

      setTimeout(() => {
        this.renderCompleted.emit(true);
      }, 50);
    }
  }

  handleError(error: any): void {
    this.errorLoadingChart.emit(error);
  }

  private updateTimeZoneOffsetOfChart(query: Query) {
    query.q.charts[0].timeSeriesAnomalyDetection.timeZoneOffset =
      new Date().getTimezoneOffset() * -1;
  }
}
