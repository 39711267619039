import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@ondemand/core';
import { OdaDropdownSelectComponent } from './components/oda-dropdown-select/oda-dropdown-select.component';
import { ErrorMessageComponent } from './components/error-message.component';
import { LocalizationPipe } from './pipes/localization/localization.pipe';
import { ResultPaginationComponent } from './components/result-pagination/result-pagination.component';
import { CriticalActivityDataService } from './services/critical-activity/critical-activity.data.service';
import { WidgetService } from './services/widget-service';
import { chartFiles } from './components/chart/index';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    LocalizationPipe,
    ErrorMessageComponent,
    OdaDropdownSelectComponent,
    ResultPaginationComponent,
    ...chartFiles.components
  ],
  exports: [
    LocalizationPipe,
    ErrorMessageComponent,
    OdaDropdownSelectComponent,
    ResultPaginationComponent,
    ...chartFiles.components
  ],
  providers: [
    WidgetService,
    CriticalActivityDataService,
    ...chartFiles.services
  ]
})
export class SharedModule {}
