import { Injectable } from '@angular/core';
import { AppFacadeService } from '@ondemand/core';
import { Observable } from 'rxjs';
import { FeatureFlagType } from '../components/shared/feature-flag.enum';

@Injectable()
export class AuditingFeatureFlagsService {
  /**
   * Returns an Observable that continually tracks the value of a specific FeatureFlag
   *
   */
  getFlag(flagName: FeatureFlagType): Observable<boolean> {
    return this.facadeService.featureFlag$(flagName);
  }

  /**
   * Sets the value of the feature flag Observable. Will cause any other
   * components listening for this feature flag to get the new value.
   *
   */
  setFlag(flagName: FeatureFlagType, value: boolean): void {
    this.facadeService.featureFlag$(flagName, value);
  }

  constructor(private facadeService: AppFacadeService) {
  }
}
