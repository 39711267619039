/**
 * AuditingTenantConfig
 */
import { switchMap, take, map, timeout, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject ,  ReplaySubject } from 'rxjs';
import { TenantConfiguration } from '../models/tenant-configuration.model';
import { HttpRequestMethod, OnDemandTokenHttp, Util } from '@ondemand/core';
import { ServiceDiscoveryService } from './service-discovery.service';
import { defaultHttpRequestTimeout } from '../util/constants';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class TenantConfigurationService {
  protected basePath$: Observable<string>;
  public configChange = new Subject();
  public hasConfigs$: ReplaySubject<boolean> = new ReplaySubject(1);
  private configuration: any = {
    withCredentials: false
  };

  constructor(protected http: OnDemandTokenHttp, serviceDiscoveryService: ServiceDiscoveryService) {
    this.basePath$ = serviceDiscoveryService.getAuditingConfigServiceUrl$();

    this.configChange.subscribe(() => {
      this.getAllConfigurations().subscribe((configs) => {
        this.hasConfigs$.next(configs && configs.length > 0);
      }, (error) => {
        console.error('Error getting configs, in hasConfigs subscription:', error);
      });
    });

    // Force initial update
    this.configChange.next();
  }

  /**
   * @summary Gets all O365 Tenant Configuration settings
   */
  public getAllConfigurations(extraHttpRequestParams?: any): Observable<any> {
    return this.getAllConfigurationsWithHttpInfo(extraHttpRequestParams).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status !== 200) {
          return undefined;
        } else {
          return response.body || {};
        }
      }));
  }
  /**
   * Retrieves a single O365 Tenant Configuration object based on its questTenantId
   *
   * @summary Gets a single O365 Tenant Configuration object
   */
  public getConfigurationById(questTenantId: string, extraHttpRequestParams?: any): Observable<any> {
    return this.getConfigurationByIdWithHttpInfo(questTenantId, extraHttpRequestParams).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status !== 200) {
          return undefined;
        } else {
          return response.body || {};
        }
      }));
  }

  /**
   * Updates a single O365 Tenant Configuration object based on QuestTenantId
   *
   * @summary Updates an O365 Tenant Configuration object
   * @param questTenantId Saved tenant configuration ID to update
   */
  public updateConfiguration(questTenantId: string, body: TenantConfiguration, extraHttpRequestParams?: any): Observable<{}> {
    return this.updateConfigurationWithHttpInfo(questTenantId, body, extraHttpRequestParams).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status !== 200) {
          return undefined;
        } else {
          return response.body || {};
        }
      }));
  }

  /**
   *
   * @summary Delete a saved tenant configuration
   * @param questTenantId Saved tenant configuration ID to delete
   */
  public deleteConfiguration(questTenantId: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.deleteConfigurationWithHttpInfo(questTenantId, extraHttpRequestParams).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status !== 200) {
          return undefined;
        } else {
          return response.body || {};
        }
      }));
  }

  /**
   *
   * @summary Adds a new O365 Configuration document
   * @param tenantConfig Tenant Configuration to add
   */
  public createConfiguration(tenantConfig: TenantConfiguration, extraHttpRequestParams?: any): Observable<{}> {
    return this.createConfigurationWithHttpInfo(tenantConfig, extraHttpRequestParams).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status !== 200 && response.status !== 201) {
          return undefined;
        } else {
          return response.body || {};
        }
      }));
  }

  /**
   * Gets a single O365 Tenant Configuration object
   * Retrieves a single O365 Tenant Configuration object based on it&#39;s questTenantId
   */
  public getConfigurationByIdWithHttpInfo(questTenantId: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = basePath + `/configuration/${questTenantId}`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();

      let requestOptions = {
        headers,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions).pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Updates an O365 Tenant Configuration object
   * Updates a single O365 Tenant Configuration object based on QuestTenantId
   *
   * @param questTenantId Saved tenant configuration ID to update
   */
  public updateConfigurationWithHttpInfo(questTenantId: string, body: TenantConfiguration,
                                         extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = basePath + `/configuration/${questTenantId}`;
      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();
      // verify required parameter 'questTenantId' is not null or undefined
      if (questTenantId === null || questTenantId === undefined) {
        throw new Error('Required parameter questTenantId was null or undefined when calling updateConfiguration.');
      }

      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling updateConfiguration.');
      }

      headers = headers.set('Content-Type', 'application/json');
      let requestOptions = {
        headers,
        body: body === null ? '' : body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http.requestHttp(HttpRequestMethod.PUT, path, requestOptions).pipe(
        timeout(defaultHttpRequestTimeout),
        tap(() => {
          this.configChange.next();
        })
      );
    }));
  }

  /**
   * Delete a saved tenant configuration
   *
   * @param questTenantId Saved tenant configuration ID to delete
   */
  public deleteConfigurationWithHttpInfo(questTenantId: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = basePath + `/configuration/${questTenantId}`;

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();
      // verify required parameter 'questTenantId' is not null or undefined
      if (questTenantId === null || questTenantId === undefined) {
        throw new Error('Required parameter questTenantId was null or undefined when calling deleteConfiguration.');
      }

      let requestOptions = {
        headers,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http.requestHttp(HttpRequestMethod.DELETE, path, requestOptions)
      .pipe(
        timeout(defaultHttpRequestTimeout),
        tap(() => {
          this.configChange.next();
        })
      );
    }));
  }

  /**
   * Gets all O365 Tenant Configuration settings
   *
   */
  public getAllConfigurationsWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = basePath + '/configuration';

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();
      let requestOptions = {
        headers,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions).pipe(timeout(defaultHttpRequestTimeout));
    }));
  }

  /**
   * Adds a new O365 Configuration document
   *
   * @param tenantConfig Tenant Configuration to add
   */
  public createConfigurationWithHttpInfo(tenantConfig: TenantConfiguration, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.basePath$.pipe(take(1), switchMap((basePath) => {
      const path = basePath + '/configuration';

      let queryParameters = new HttpParams();
      let headers = this.getDefaultHeaders();

      headers = headers.set('Content-Type', 'application/json');

      // verify required parameter 'tenantConfig' is not null or undefined
      if (tenantConfig === null || tenantConfig === undefined) {
        throw new Error('Required parameter tenantConfig was null or undefined when calling createConfiguration.');
      }

      let requestOptions = {
        headers,
        body: tenantConfig === null ? {} : tenantConfig,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        observe: 'response'
      };

      if (extraHttpRequestParams) {
        requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
      }

      return this.http.requestHttp(HttpRequestMethod.POST, path, requestOptions).pipe(
        timeout(defaultHttpRequestTimeout),
        tap(() => {
        this.configChange.next();
        })
      );
    }));
  }

  private getDefaultHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('On-Demand-User-Locale', Util.userLocale);

    return headers;
  }

}
