<div class="wizard-container">
  <div class="inner-container">
    <internal-loading
      *ngIf="!errorMessage"
      information="{{ 'auditing.checkingSubscriptionMessage' | localize }}"
      [isLoading]="checkingSubscription"
    ></internal-loading>

    <internal-loading
      *ngIf="!errorMessage"
      information="{{ 'auditing.submittingToCoordinator' | localize }}"
      [isLoading]="submitting"
    ></internal-loading>

    <div class="title-container">
      <edc-icon name="auditing" class="audit-icon"></edc-icon>
      <h3>{{ 'auditing.moduleName' | localize }}</h3>
    </div>

    <qod-notification type="warning" *ngIf="isAuditDownForMaintenance | async">
      <div class="maintenance-warning">
        <span>{{ 'auditing.maintenanceWarningTitle' | localize }}</span>
        <div>{{ 'auditing.maintenanceWarningMessage' | localize }}</div>
        <p
          innerHTML="{{ 'auditing.maintenanceWarningStatusLink' | localize }}"
        ></p>
      </div>
    </qod-notification>

    <div *ngIf="errorMessage">
      <error-message>{{ errorMessage }}</error-message>
    </div>

    <ng-container *ngIf="registered">
      <div class="success-message">
        <div class="icon-container">
          <edc-icon name="status-done"></edc-icon>
        </div>
        <div class="success-message-text">
          <p>{{ 'auditing.registeredWithCoordinator' | localize }}</p>

          <div class="upgrade-message-container">
            <ca-upgrade-message
              *ngIf="!!upgradeMessage"
              [upgradeInfo]="upgradeMessage"
            ></ca-upgrade-message>
          </div>
        </div>
      </div>

      <div class="close-button-container">
        <button class="btn close-button" (click)="onClickClose()">
          {{ 'auditing.close' | localize }}
        </button>
      </div>
    </ng-container>

    <div
      *ngIf="
        !provisioned && !errorMessage && !checkingSubscription && !submitting
      "
    >
      <provisioning-sequence
        [provisioningSource]="provisioningSourceCA"
      ></provisioning-sequence>
    </div>

    <div
      *ngIf="
        changeAuditorInfo &&
        !submitting &&
        !registered &&
        !checkingSubscription &&
        provisioned
      "
    >
      <ng-container *ngIf="subscribed">
        <p>{{ 'auditing.setupChangeAuditorDescription' | localize }}</p>

        <form id="ca-setup-form" (submit)="onSubmit()" #f="ngForm">
          <input
            #installationNameField
            class="installation-name-field"
            required
            name="installationName"
            placeholder="{{
              'auditing.setupChangeAuditorInstallationName' | localize
            }}"
            type="text"
            [(ngModel)]="changeAuditorInfo.installationDisplayName"
            [disabled]="isAuditDownForMaintenance | async"
          />

          <div class="card horizontal change-auditor-card">
            <div class="card-image">
              <img
                id="change-auditor-logo"
                src="/assets/auditing/img/change-auditor-logo.svg"
              />
            </div>
            <div class="card-content">
              <span
                class="card-title"
                title="{{ 'auditing.installationName' | localize }}"
                >{{ changeAuditorInfo.installationName }}</span
              >

              <!-- TODO: Remove deprecated version string -->
              <ng-container
                *ngIf="
                  changeAuditorInfo.changeAuditorDisplayVersion;
                  else deprecatedVersion
                "
              >
                <div class="ca-version">
                  {{ 'auditing.changeAuditorProductName' | localize }}
                  {{ changeAuditorInfo.changeAuditorDisplayVersion }}
                </div>
              </ng-container>
              <ng-template #deprecatedVersion>
                <div class="ca-version">
                  {{ 'auditing.changeAuditorProductName' | localize }}
                  {{ changeAuditorInfo.changeAuditorVersion }}
                </div>
              </ng-template>
            </div>
          </div>

          <div class="button-container">
            <button
              type="submit"
              class="btn next-button"
              [ngClass]="{
                disabled: !f.valid || (isAuditDownForMaintenance | async)
              }"
            >
              {{ 'auditing.next' | localize }}
            </button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="subscriptionExpired">
        <warning-message class="not-subscribed-warning">
          <div
            innerHTML="{{ 'auditing.expiredSubscriptionCAWarning' | localize }}"
          ></div>
        </warning-message>

        <p
          class="not-subscribed-text"
          innerHTML="{{ 'auditing.expiredMessage2' | localize }}"
        ></p>

        <button
          type="button"
          class="btn check-subscription-button"
          (click)="onClickCheckSubscription()"
        >
          {{ 'auditing.notSubscribedNextButtonLabel' | localize }}
        </button>
      </ng-container>

      <ng-container *ngIf="!subscribed && !subscriptionExpired">
        <warning-message class="not-subscribed-warning">
          <div
            innerHTML="{{ 'auditing.notSubscribedCAWarning' | localize }}"
          ></div>
        </warning-message>

        <p
          class="not-subscribed-text"
          innerHTML="{{ 'auditing.notSubscribedMessage2' | localize }}"
        ></p>

        <button
          type="button"
          class="btn check-subscription-button"
          (click)="onClickCheckSubscription()"
        >
          {{ 'auditing.notSubscribedNextButtonLabel' | localize }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
