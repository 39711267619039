import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProvisioningService } from '../services/organization-provisioning/provisioning.service';
import { EProvisionedState } from '../../shared/models/provision.model';
import { Subject } from 'rxjs';

@Injectable()
export class ProvisioningGuard implements CanActivate {
  navigationAllowed$ = new Subject<boolean>();
  constructor(
    private router: Router,
    private provisioningService: ProvisioningService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let navigationAllowed = await this.hasProvisionedOrg();

    if (
      navigationAllowed !== undefined &&
      navigationAllowed.valueOf() === false
    ) {
      this.redirectToProvisionPage();
    }

    this.navigationAllowed$.next(navigationAllowed);
    return Promise.resolve(navigationAllowed);
  }

  private async hasProvisionedOrg(): Promise<boolean> {
    let navigationAllowed =
      await this.provisioningService.getProvisioningStatus() ===
      EProvisionedState.Provisioned;
    return navigationAllowed;
  }

  private redirectToProvisionPage() {
    this.router.navigate(['/auditing/auditing/provision']);
  }
}
