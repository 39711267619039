import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import {
  AppFacadeService,
  BaseModule,
  CoreModule,
  IHelp,
  NotificationPlansModule
} from '@ondemand/core';

import { AuditingRoutes } from './auditing.routes';
import { HttpClientModule } from '@angular/common/http';
import { AUDITING_HELP_URLS } from './auditing.help-urls';

import { directives } from './directives/index';
import { services } from './services/index';
import { components } from './components/index';
import { pipes } from './pipes/index';
import { guards } from './route-guards/index';
import { AuditingDashboardModule } from './modules/auditing-dashboard/auditing-dashboard.module';
import { SharedModule } from '../shared/shared.module';
import { DragulaModule } from 'ng2-dragula';
import { CriticalActivityTabModule } from './modules/critical-activity-tab/critical-activity-tab.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuditConstants } from '../shared/models/ui-constants';

export const routerModule: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(AuditingRoutes);

export const dragulaModule: ModuleWithProviders<any> = DragulaModule.forRoot();

@NgModule({
  imports: [
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    AuditingDashboardModule,
    CriticalActivityTabModule,
    CommonModule,
    routerModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxDatatableModule,
    CoreModule,
    SharedModule,
    dragulaModule,
    NotificationPlansModule.withConfiguration(AuditConstants.ModuleName)
  ],
  declarations: [...pipes, ...directives, ...components],
  providers: [...services, ...guards, Location]
})
export class AuditingModule extends BaseModule {
  get Links(): IHelp[] {
    return AUDITING_HELP_URLS;
  }

  constructor(facade: AppFacadeService) {
    super(facade, []);
  }
}
