<div class="top-section">

  <h4 class="col s6">{{ 'auditing.eventDetailsTitle' | localize }}</h4>

  <div class="button-container col s6">
    <button class="btn right query-link" *ngIf="queryId"
      routerLink="/auditing/auditing/queries/results/{{queryId}}">
      <edc-icon name="back"></edc-icon> {{ 'auditing.backToSearchResults' | localize }}
    </button>
  </div>

</div>

<error-message *ngIf="errorMessagePath">{{ errorMessagePath | localize }}</error-message>

<div class="event-table-container" *ngIf="event">
  <event-details-table [event]="event" [displayAddFilter]="false"></event-details-table>
</div>
