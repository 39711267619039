/**
 * Saved query category which contains saved queries
 */

import { Query } from '../../../models/query.model';

export class SavedQueryCategory {
  id: string;
  name: string;
  createdBy: string;
  queries: Query[];
  isProtected?: boolean = false;
  isShared?: boolean = false;
  expanded?: boolean = false; // client-side only

  constructor(params?: SavedQueryCategory) {
    Object.assign(this, params);
  }
}
