import { CriticalActivityResponse } from '../../../../../../shared/models/critical-activity.shared.models';

export const LOAD_CRITICAL_ACTIVITY = '[Auditing Dashboard] Load Critical Activity';
export const RELOAD_CRITICAL_ACTIVITY = '[Auditing Dashboard] Reload Critical Activity';
export const LOAD_CRITICAL_ACTIVITY_SUCCESS = '[Auditing Dashboard] Load Critical Activity Success';
export const RELOAD_CRITICAL_ACTIVITY_SUCCESS = '[Auditing Dashboard] Reload Critical Activity Success';
export const LOAD_CRITICAL_ACTIVITY_FAIL = '[Auditing Dashboard] Load Critical Activity Fail';
export const RELOAD_CRITICAL_ACTIVITY_FAIL = '[Auditing Dashboard] Reload Critical Activity Fail';

export class LoadCriticalActivity {
  readonly type = LOAD_CRITICAL_ACTIVITY;
}

// eslint-disable-next-line max-classes-per-file
export class ReloadCriticalActivity {
  readonly type = RELOAD_CRITICAL_ACTIVITY;
}

// eslint-disable-next-line max-classes-per-file
export class LoadCriticalActivitySuccess {
  readonly type = LOAD_CRITICAL_ACTIVITY_SUCCESS;
  constructor(public payload: CriticalActivityResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class ReloadCriticalActivitySuccess {
  readonly type = RELOAD_CRITICAL_ACTIVITY_SUCCESS;
  constructor(public payload: CriticalActivityResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadCriticalActivityFail {
  readonly type = LOAD_CRITICAL_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class ReloadCriticalActivityFail {
  readonly type = RELOAD_CRITICAL_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

export type CriticalActivityAction =
  | LoadCriticalActivity
  | ReloadCriticalActivity
  | LoadCriticalActivitySuccess
  | ReloadCriticalActivitySuccess
  | LoadCriticalActivityFail
  | ReloadCriticalActivityFail;
