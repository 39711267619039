import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ChartQueryIntervals } from '../../../../../auditing/models/chart-options/chart-models';
import { Query } from '../../../../../auditing/models/query.model';
import { LegendDataItem } from '../../oda-donut-chart/legend/legend-model';
import { YAxisBoundsForXAxisLabelClick } from '../timeseries-constant';
import { TimeseriesChartQueryMouseEventService } from './timeseries-chart-query-mouse-event.service';

@Injectable()
export class TimeseriesChartTooltipService {
  renderer: Renderer2;
  timespan: string;
  timeSlots: string[];
  query: Query;
  constructor(
    rendererFactory: RendererFactory2,
    private mouseEventService: TimeseriesChartQueryMouseEventService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  customXaxisTickCallback(value: number) {
    if (this.timespan === ChartQueryIntervals.Hour) {
      return [
        new Date(value)
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'short'
        }),
        new Date(value)
        .toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
      ];
    } else if (this.timespan === ChartQueryIntervals.Day ||
               this.timespan === ChartQueryIntervals.Week ||
               this.timespan === ChartQueryIntervals.Month) {
      return new Date(value)
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'short'
      })
      .replace(',', ' ');
    } else if (this.timespan === ChartQueryIntervals.Year) {
      return [
        new Date(value)
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'short'
        }),
        new Date(value)
        .toLocaleString('en-US', {
          year: 'numeric'
        })
      ];
    } else {
      return new Date(value)
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
      .replace(',', ' ');
    }
  }

  customTickCallback(value: number) {
    if (Math.floor(value) === value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
    } else {
      return Math.floor(value).toLocaleString(undefined, {
        maximumFractionDigits: 0
      });
    }
  }

  customTitleCallback(tooltipItems: Chart.ChartTooltipItem[]) {
    const seletedTimestamp = tooltipItems[0].xLabel;
    return new Date(seletedTimestamp)
      .toLocaleString('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
      .replace(',', ' ');
  }

  customLabelCallback(
    tooltipItems: Chart.ChartTooltipItem,
    data: Chart.ChartData
  ): string[] {
    let labels = new Array<string>();
    const value = data.datasets[tooltipItems.datasetIndex].data[
      tooltipItems.index
    ] as number;
    const grouping = data.datasets[tooltipItems.datasetIndex].label;
    labels.push(grouping + ' - ' + value.toLocaleString());

    return labels;
  }

  customTooltipCallback(that: any, tooltipModel: any) {
    // Tooltip Element
    const tooltipEl = this.getOrCreateToolTipElement();
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      this.renderer.setStyle(tooltipEl, 'opacity', 0);
      return;
    }
    const chartPosition = that._chart.canvas.getBoundingClientRect();
    let { left, top } = chartPosition;
    left = left + window.pageXOffset + tooltipModel.caretX;
    // if the end of the tooltip is within 20px of striking distance of the viewport, bring it back a bit to the
    // left
    if (left + tooltipModel.width > window.innerWidth - 20) {
      left -= tooltipModel.width;
    }
    left = `${left}px`;
    top = `${top + window.pageYOffset + tooltipModel.caretY}px`;
    this.configureTooltip(tooltipEl, tooltipModel, { top, left });
    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(
        (bodyItem: any) => bodyItem.lines
      );
      const titleDiv = this.constructTitle(titleLines);
      const bodyDiv = this.constructBody(bodyLines, tooltipModel);
      Array.from(tooltipEl.children).forEach(child => {
        this.renderer.removeChild(tooltipEl, child);
      });
      this.renderer.appendChild(tooltipEl, titleDiv);
      this.renderer.appendChild(tooltipEl, bodyDiv);
    }
  }

  setCallbackVariables(query: Query, timeSlots: string[]): void {
    this.query = query;
    this.timespan = query.q.charts[0].timeSeries.seriesInterval;
    this.timeSlots = timeSlots;
  }

  generateChartOptions(
  ) {
    return {
      layout: {
        padding: {
          top: 5
        }
      },
      animation: {
        duration: 0
      },
      elements: {
        line: {
          tension: 0
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        fontColor: '#fff000',
        fontFamily: 'Open Sans',
        fontWeight: 'bold'
      },
      legend: {
        display: false
      },
      hover: {
        mode: 'point',
        onHover: this.thisAsThat(this.handleOnHoverMouseEvent)
      },
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        custom: this.thisAsThat(this.customTooltipCallback),

        callbacks: {
          title: this.customTitleCallback.bind(this),
          label: this.customLabelCallback.bind(this)
        }
      },
      scales: {
        xAxes: [
          {
            autoSkip: true,
            gridLines : {
              display : false
            },
            ticks: {
              callback: this.customXaxisTickCallback.bind(this)
            },
            offset: true
          }
        ],
        yAxes: [
          {
            offset: true,
            afterDataLimits(scale: any) {
              // add 10% to both ends of range
              const range = scale.max - scale.min;
              const grace = range * 0.1;
              scale.max += grace;
            },
            gridLines: {
              drawBorder: false
            },
            ticks: {
              suggestedMax: 10,
              precision: 0,
              beginAtZero: true,
              callback: this.customTickCallback.bind(this)
            }
          }
        ]
      },
      events: ['click', 'mousemove', 'mouseout'],
      onClick: {}
    };
  }

  handleOnHoverMouseEvent(chart: any, event: any) {
    const point = chart.getElementAtEvent(event);
    const drilldownAtLimit = this.chartAtDrilldownLimitCheck(chart);

    if (point.length) {
      // If we're on the last drill-down (time) and we can't go any further. Point not clickable.
      if (drilldownAtLimit) {
        return;
      }
      // Otherwise it's a clickable point, so we change the cursor to a pointer.
      event.target.style.cursor = 'pointer';

    } else {
      // Handles hovering over an x-axis label that's clickable
      if (chart.scales['x-axis-0'].labelRotation === 0 && !drilldownAtLimit) {
        const xLabelList = chart.scales['x-axis-0']._labelItems;
        const xAxisLabelCenterPixels = xLabelList.map((label: any) =>  label.x);
        let found = false;

        xAxisLabelCenterPixels.forEach((element: number) => {
          if (this.eventTargetBoundsCheck(event, element)) {
            found = true;
          }
        });

        event.target.style.cursor = found ? 'pointer' : 'default';
      } else {
        event.target.style.cursor = 'default';
      }
    }
  }

  // Checks whether the chart is at its lowest drill-down level, where items are shown for a single hour.
  chartAtDrilldownLimitCheck(chart: any): boolean {
    const sampleXAxisLabel = chart.scales['x-axis-0'].ticks[0];

    return sampleXAxisLabel.length === 2 &&
      (sampleXAxisLabel[1].includes('AM') || sampleXAxisLabel[1].includes('PM')) &&
      chart.scales['x-axis-0'].ticks.length === 1;
  }

  // Checks whether an event is within an x-axis label's y offset and x-axis range.
  eventTargetBoundsCheck(event: any, element: number) {
    return event.offsetX < element + 22 &&
    event.offsetX > element - 22 &&
    event.offsetY > YAxisBoundsForXAxisLabelClick.lowerBound &&
    event.offsetY <= YAxisBoundsForXAxisLabelClick.upperBound;
  }

  // Entry point for chart's onClick handler.
  mouseClickEventHandler(
    chart: any,
    chartLegendItems: LegendDataItem[],
    deletedLegendItems: string[],
    event: PointerEvent,
    query: Query,
    chartElement: any,
    availableValues: string[]) {
    return this.mouseEventService.mouseClickEventHandler(
      chart,
      chartLegendItems,
      deletedLegendItems,
      event,
      query,
      this.timeSlots,
      chartElement,
      availableValues);
  }

  private getOrCreateToolTipElement() {
    if (this.getToolTipElement()) {
      return this.getToolTipElement();
    } else {
      this.createToolTipElement();
      return this.getToolTipElement();
    }
  }

  private getToolTipElement() {
    return document.getElementById('chartjs-tooltip');
  }

  private constructTitle(titleLines: any[]): any {
    const titleDiv = this.renderer.createElement('div');

    titleLines.forEach((title: string) => {
      const text = this.renderer.createText(title + ' ');
      this.renderer.appendChild(titleDiv, text);
    });

    return titleDiv;
  }

  private constructBody(bodyLines: any[], tooltipModel: any): any {
    const bodyDiv = this.renderer.createElement('div');
    bodyLines.forEach((body: any, i: number) => {
      let bodyText: any;
      const colors = tooltipModel.labelColors[i];
      const bodyLine = this.renderer.createElement('div');
      const paintChipSpan = this.renderer.createElement('span');
      bodyText = this.renderer.createText(body);

      this.renderer.addClass(paintChipSpan, 'tooltip-paint-chip');
      this.renderer.setStyle(
        paintChipSpan,
        'background',
        colors.backgroundColor
      );
      this.renderer.setStyle(paintChipSpan, 'border-color', colors.borderColor);
      this.renderer.appendChild(bodyLine, paintChipSpan);
      this.renderer.appendChild(bodyLine, bodyText);
      this.renderer.appendChild(bodyDiv, bodyLine);
    });

    return bodyDiv;
  }

  private createToolTipElement() {
    const tooltipEl = this.renderer.createElement('div');
    this.renderer.setAttribute(tooltipEl, 'id', 'chartjs-tooltip');
    this.renderer.addClass(tooltipEl, 'tooltip');
    this.renderer.addClass(tooltipEl, 'qod-tooltiptext');
    this.renderer.appendChild(document.body, tooltipEl);
  }

  private configureTooltip(
    tooltipEl: any,
    tooltipModel: any,
    chartPosition: { left: string; top: string }
  ): void {
    this.renderer.setStyle(tooltipEl, 'opacity', 1);
    this.renderer.setStyle(tooltipEl, 'position', 'absolute');
    const { left, top } = chartPosition;
    this.renderer.setStyle(tooltipEl, 'left', left);
    this.renderer.setStyle(tooltipEl, 'top', top);
    this.renderer.setStyle(
      tooltipEl,
      'padding',
      tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
    );
    this.renderer.setStyle(tooltipEl, 'pointer-events', 'none');

    // Set caret Position
    this.renderer.removeClass(tooltipEl, 'above');
    this.renderer.removeClass(tooltipEl, 'below');
    this.renderer.removeClass(tooltipEl, 'center');
    this.renderer.removeClass(tooltipEl, 'top');
    this.renderer.removeClass(tooltipEl, 'bottom');

    if (tooltipModel.yAlign) {
      this.renderer.addClass(tooltipEl, 'center');
    } else {
      this.renderer.addClass(tooltipEl, 'no-transform');
    }
  }

  private thisAsThat(callBack: any) {
    const self = this;
    return function() {
      return callBack.apply(
        self,
        [this].concat(Array.prototype.slice.call(arguments))
      );
    };
  }
}
