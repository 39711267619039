/**
 * Model for saved queries
 */

import { QueryBody } from './query-body';

export class Query {
  name: string;
  id: string;
  q: QueryBody;
  categoryId: string;
  createdDate: string;
  createdBy: 'user' | 'system';
  lastUpdated: string;
  starred = false;
  categoryName?: string;
  visible?: boolean = true;
  organizationId?: string;
  userId?: string;
  isShared?: boolean;
  isProtected?: boolean;
  lastUpdatedBy: string;
  priority?: number;
  description: string;
  hideCharts?: boolean;
  hideEvents?: boolean;

  constructor(params?: any) {
    Object.assign(this, params);
    if (this.name === undefined || this.name === null) {
      this.name = `New Search - ${(new Date()).toDateString()}`;
    }

    if (this.q) {
      this.q = new QueryBody(this.q);
    } else if (this.q === undefined || this.q === null) {
      this.q = new QueryBody();
    }
  }

  get isSystemDefined() {
    return this.createdBy === 'system';
  }

  deepClone() {
    let output = new Query(this);
    return output;
  }
}
