<div class="oda-dropdown-select-container" [ngStyle]="controlContainerStyle">

  <span class="oda-caret">▼</span>

  <input
    id="oda-dropdown-input"
    type="text"
    readonly="true"
    (focus)="openDropdown()"
    (blur)="hideDropdown()"
    (click)="openDropdown()"
    [ngStyle]="inputStyle"
    [ngClass]="{'input-not-valid': inputNotValid}"
    [disabled]="disabled"
    [attr.title]="inputTitle"
    />

  <ul class="oda-dropdown-container" [ngStyle]="dropdownBoxStyle">

    <li *ngIf="noSelectionPrompt && dropdownItems && dropdownItems.length > 0" class="item-disabled" [ngStyle]="itemBoxStyle"><span class="item-disabled" [ngStyle]="itemTexAreaStyle">{{noSelectionPrompt}}</span></li>
    <li *ngIf="noItemsPrompt && ( !dropdownItems || dropdownItems.length === 0 )" class="item-disabled" [ngStyle]="itemBoxStyle"><span class="item-disabled" [ngStyle]="itemTexAreaStyle">{{noItemsPrompt}}</span></li>

    <li
      class="selectable-item"
      *ngFor="let item of dropdownItems"
      (mousedown)="onItemClick(item)"
      [ngClass]="{'item-selected': item.id === selectedId }"
      [attr.title]="item.name"
      [ngStyle]="itemBoxStyle"
      ><span [ngStyle]="itemTexAreaStyle">{{item.name}}</span></li>

  </ul>

</div>
