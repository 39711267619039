/**
 * Pipe for applying Moment JS "fromNow" function,
 * which gives output like "1 hour ago" based on a date string
 */

import moment from 'moment';

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'dateFromNow' })
export class DateFromNowPipe implements PipeTransform {

  transform(dateString: string) {
    return moment(dateString).fromNow();
  }

}
