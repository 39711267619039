import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Query } from '../../../../../auditing/models/query.model';
import { QueryClause } from '../../../../../auditing/models/query-clause';
import { ActiveQueryService } from '../../../../../auditing/services/active-query.service';
import {
  ChartQueryIntervals,
  TimeSeriesChartOptions
} from '../../../../../auditing/models/chart-options/chart-models';
import {
  getMaxDataPointLimit,
  updateTimeZoneOffsetOfChart
} from '../../services/chart-query-converter';
import { IntervalCategory } from '../../models/visualization-constants';
import { UserColumnID } from '../../models/chart-constants';

@Injectable()
export class AnomalyTimeseriesChartQueryMouseEventService {
  constructor(
    private activeQueryService: ActiveQueryService,
    private router: Router
  ) {}

  clickHandlerForTimeseriesChart(
    event: any,
    chartElement: any[],
    timeSlots: string[],
    query: Query
  ): any {
    // This method will be executed inside the context of the ChartElement
    // so, 'this' will be referring to the Chart Element
    if (chartElement.length > 0) {
      const eventY = event.offsetY;

      const chartElemClicked = chartElement.find(elem => {
        const lowY = elem._view.y - elem._view.radius;
        const hiY = elem._view.y + elem._view.radius;
        return eventY >= lowY && eventY <= hiY;
      });

      // In case clicked area of the chart was not a valid point, we just return to avoid indexing errors.
      if (!chartElemClicked || chartElemClicked._datasetIndex !== 1) {
        return;
      }

      const selectedTimestamp = timeSlots[chartElemClicked._index];

      if (selectedTimestamp) {
        let endScopeDate = new Date(selectedTimestamp);
        const timeframe: number =
          query.q.charts[0].timeSeriesAnomalyDetection.detectionTimeFrame;
        let nextDay = new Date(
          endScopeDate.getTime() + timeframe * 60 * 60000 - 1
        ).toISOString();
        let clause = new QueryClause({
          field: 'Date',
          operator: 'between_date',
          value: endScopeDate.toISOString() + '_' + nextDay
        });

        query.name =
          query.name +
          ' (' +
          endScopeDate.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }) +
          ')';

        // set time series chart options to render visualization in result page.
        query.q.charts[0].timeSeries = {
          id: query.q.charts[0].timeSeriesAnomalyDetection.id,
          filterOptions: {
            topXFilter: 10,
            topXFilterColumn: UserColumnID // Design docs mentioned User(Actor) is to use for grouping by data.
          },
          seriesInterval: ChartQueryIntervals.Hour, // Design docs mentioned 'Hour' to plot along x-axis of time-series chart.
          timeZoneOffset: updateTimeZoneOffsetOfChart(),
          maxBins: getMaxDataPointLimit(IntervalCategory.Hour)
        } as TimeSeriesChartOptions;

        query.q.charts[0].timeSeriesAnomalyDetection = null;

        query.q.summarizeOptions = null;
        query.q.clauses = this.replaceTimeClause(query.q.clauses, clause);

        let adhocQuery = new Query({
          name: query.name,
          q: query.q,
          isShared: false,
          hideCharts: false
        });

        const resultsUrl = '/auditing/auditing/queries/results';
        this.activeQueryService.setQuery(adhocQuery, adhocQuery);
        this.router.navigate([resultsUrl]);
      }
    }
  }

  /**
   * Since we're scoping the ad-hoc search to the single data point selected, we want to remove all other
   * time-related clauses.
   */
  replaceTimeClause(clauses: QueryClause[], replacement: QueryClause) {
    let newList: QueryClause[] = [];
    for (let clause of clauses) {
      if (
        clause.operator !== 'between_date' &&
        clause.operator !== 'during_last'
      ) {
        newList.push(clause);
      }
    }
    newList.push(replacement);
    return newList;
  }
}
