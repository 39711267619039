/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CriticalActivityDataService } from '../../../../../../shared/services/critical-activity/critical-activity.data.service';
import {
  LoadCriticalActivity,
  LoadCriticalActivityFail,
  LoadCriticalActivitySuccess,
  LOAD_CRITICAL_ACTIVITY,
  ReloadCriticalActivity,
  ReloadCriticalActivityFail,
  ReloadCriticalActivitySuccess,
  RELOAD_CRITICAL_ACTIVITY
} from './critical-activity.actions';

@Injectable()
export class CriticalActivityEffects {
  loadCriticalActivities$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadCriticalActivity>(LOAD_CRITICAL_ACTIVITY),
    switchMap(action => this.criticalActivityService.getWidgetCriticalActivities().pipe(
      map(response => new LoadCriticalActivitySuccess(response)),
      catchError(error => of(new LoadCriticalActivityFail(error)))
    ))
  ));

  reloadCriticalActivities$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ReloadCriticalActivity>(RELOAD_CRITICAL_ACTIVITY),
    switchMap(action => this.criticalActivityService.refreshWidgetCriticalActivities().pipe(
      map(response => new ReloadCriticalActivitySuccess(response)),
      catchError(error => of(new ReloadCriticalActivityFail(error)))
    ))
  ));

  constructor(
    private criticalActivityService: CriticalActivityDataService,
    private actions$: Actions
  ) { }
}
