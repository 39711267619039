export function InvokeWithErrorHandling(
  funcToExecute: () => void,
  errorHandler: (errorObj: any) => void
): void {
  try {
    funcToExecute();
  } catch (err) {
    errorHandler(err);
  }
}
