import { Injectable } from '@angular/core';
import { SigninsComponentInterface } from '../sign-ins.component';

@Injectable()
export class SigninsChartCustomTooltipService {
  customTooltipHandler(
    tooltipModel: any,
    signinsWidget: SigninsComponentInterface
  ) {
    // This method will be executed inside the context of the ChartElement
    // so, 'this' will be referring to the Chart Element

    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set custom contents and layout
    if (tooltipModel.body) {
      const datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
      const dataIndex = tooltipModel.dataPoints[0].index;
      const currentDataset = signinsWidget.getDataset(datasetIndex, dataIndex);
      const currentDataColor = signinsWidget.getDataColor(
        datasetIndex,
        dataIndex
      );
      const displayTextLines = currentDataset.details[dataIndex].details;
      const displayTextDate = currentDataset.details[dataIndex].title;

      let innerHtml = '<tbody>';
      const tableRowStyle = '"line-height: 16px;"';
      const tableCellColorSquareStyle = `"width: 1.428rem; padding: 0.1rem 0 0 0.214rem; margin: 0;"`;

      // allow only 4 rows max
      const rowCount =
        displayTextLines.length < 4 ? displayTextLines.length : 4;
      for (let i = 0; i < rowCount; i++) {
        const spanStyle =
          `"display: inline-block; background-color:${currentDataColor};` +
          'padding: 0; margin: 0; height: 0.714rem; width:0.714rem;"';

        const span = `<span style=${spanStyle}></span>`;
        const tableCellTextContentStyle =
          '"padding: 0; margin: 0; color: #ffffff; ' +
          'vertical-align: middle; text-align: left;"';

        const tableRow =
          `<tr style=${tableRowStyle}><td style=${tableCellColorSquareStyle}>${span}</td>` +
          `<td style=${tableCellTextContentStyle}>${displayTextLines[i]}</td></tr>`;

        innerHtml += tableRow;
      }

      const tableCellDateStyle =
        '"line-height: 0.8rem; font-size: 0.8rem;' +
        'padding: 0.8rem 0 0 0; margin: 0; color: #ffffff; text-align: right;"';

      const dateRow =
        `<tr><td style=${tableCellColorSquareStyle}></td>` +
        `<td style=${tableCellDateStyle}>${displayTextDate}</td></tr>`;

      innerHtml += `${dateRow} </tbody>`;

      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
      tableRoot.style.padding = '0';
      tableRoot.style.margin = '0';
    }

    const widgetTag = document.getElementsByTagName('sign-ins-widget')[0];
    const chartCanvasTag = widgetTag.getElementsByTagName('canvas')[0];
    const widgetCard = widgetTag.getElementsByClassName('general-card')[0];
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const widgetRect = widgetCard.getBoundingClientRect();
    const chartCanvasRect = chartCanvasTag.getBoundingClientRect();

    const tooltipWidth = Math.floor(widgetRect.width * 0.38);
    const offsetAdjustment = 5;

    // default location to the right of data point
    let tooltipLeft =
      chartCanvasRect.left +
      window.pageXOffset +
      tooltipModel.caretX +
      offsetAdjustment;
    if (chartCanvasRect.width - tooltipModel.caretX < tooltipWidth) {
      tooltipLeft -= tooltipWidth + 2 * offsetAdjustment;
      if (tooltipLeft < widgetRect.left) {
        tooltipLeft = widgetRect.left;
      }
    }

    // default location to be below the data point
    let tooltipTop =
      chartCanvasRect.top +
      window.pageYOffset +
      tooltipModel.caretY +
      offsetAdjustment;
    if (chartCanvasRect.height - tooltipModel.caretY < tooltipRect.height) {
      tooltipTop -= tooltipRect.height + 2 * offsetAdjustment;
    }

    tooltipEl.style.opacity = '0.95';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${tooltipLeft}px`;
    tooltipEl.style.top = `${tooltipTop}px`;
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding =
      tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.backgroundColor = '#4a4946';
    tooltipEl.style.borderRadius = '10px';
    tooltipEl.style.width = `${tooltipWidth}px`;
    tooltipEl.style.zIndex = '995';
  }
}
