<general-qod-component
  [loading]="dataLoading$ | async"
  [error]="dataError$ | async"
  [data]="data$ | async"
  [height]="cardHeight"
  [card]="true"
>
  <h4 component-header>
    {{ 'auditing.pages.auditingDashboard.favoriteSearches.mySearches' | localize }}
  </h4>

  <skeleton-block
    component-loading
    type="TitleWithLineGraph"
  ></skeleton-block>

  <div component-error>
    <error-card
      error="{{ 'auditing.pages.auditingDashboard.error' | localize }}"
      button="{{ 'auditing.pages.auditingDashboard.tryAgain' | localize }}"
      (buttonClick)="loadFavoriteSearches()"
    ></error-card>
  </div>

  <div
    component-data
  >
    <p>{{ 'auditing.pages.auditingDashboard.favoriteSearches.events' | localize }}</p>
    <status-icon
      *ngFor="let row of cardData$ | async"
      [description]="row.label"
      [value]="row.count"
      actionText="{{ 'auditing.pages.auditingDashboard.favoriteSearches.view' | localize }}"
      (action)="row.action()"
    ></status-icon>
  </div>

  <div
    component-footer
  >
    <div
      class="btn-flat favorite-searches-widget-edit-button"
      (click)="handleEditClick()"
      [attr.disabled]="disableEditSearchesButton()"
    >
      <edc-icon name="cog"></edc-icon>
      {{ 'auditing.pages.auditingDashboard.favoriteSearches.editSearches' | localize }}
    </div>

    <favorite-search-editor
      [favoriteQueryIds]="selectedSearches$ | async"
      [availableCategories]="availableCategories$ | async"
      (updated)="onEditorSave()"
    >
    </favorite-search-editor>

  </div>

</general-qod-component>
