/**
 * Query Builder Metadata Service
 * Service for getting metadata about events, supporting query builder functionality
 */

import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { switchMap, take, catchError, map, timeout } from 'rxjs/operators';
import { HttpRequestMethod, OnDemandTokenHttp } from '@ondemand/core';
import { ServiceDiscoveryService } from '../service-discovery.service';
import { defaultHttpRequestTimeout } from '../../util/constants';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class AvailableValuesService {

  protected basePath: string;
  public defaultHeaders: HttpHeaders = new HttpHeaders();
  private configuration = {
    withCredentials: false
  };

  private cachedValues: any = {};

  constructor(private http: OnDemandTokenHttp,
              private serviceDiscoveryService: ServiceDiscoveryService) {
  }

  public getAvailableValues(fieldId: string, searchText?: string, skip?: number, limit?: number): Observable<any> {
    if (this.cachedValues[fieldId]) {
      return of(this.cachedValues[fieldId]);
    } else {
      return this.getValuesWithHttpInfo(fieldId, searchText, skip, limit).pipe(map((response) => {
        if (response.status === 200) {
          let values = response.body;
          this.cachedValues[fieldId] = values;
          return values;
        } else {
          throw new Error('Invalid response from available values service');
        }
      }), catchError((error) => {
        throw new Error('Got error response from available values service: ' + error.error.error.message);
      }));
    }
  }

  /**
   * Get list of values that can be used for
   *
   * @param fieldId ID of event field
   * @param searchText Text for filtering values that should be returned
   * @param skip Number of records to skip, for pagination
   * @param limit Max number of records to return
   */
  public getValuesWithHttpInfo(fieldId: string, searchText?: string, skip?: number, limit?: number,
                               extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    return this.serviceDiscoveryService.getQueryUrl$().pipe(take(1),
      switchMap((basePath: string) => {
        const path = basePath + '/availableValues';
        let headers = this.defaultHeaders;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('field', fieldId);
        if (searchText) {
          queryParameters = queryParameters.set('searchText', searchText);
        }
        if (skip) {
          queryParameters = queryParameters.set('skip', skip.toString());
        }
        if (limit) {
          queryParameters = queryParameters.set('limit', limit.toString());
        }

        let requestOptions = {
          headers,
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          observe: 'response'
        };

        if (extraHttpRequestParams) {
          requestOptions = Object.assign(requestOptions, extraHttpRequestParams);
        }

        return this.http.requestHttp(HttpRequestMethod.GET, path, requestOptions).pipe(timeout(defaultHttpRequestTimeout));
    }));

  }

}
