/**
 * Component for provisioning organizations
 */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import {
  EProvisionedState,
  EProvisioningSource
} from '../../../../shared/models/provision.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { ProvisioningService } from '../../../services/organization-provisioning/provisioning.service';
import { Router } from '@angular/router';

@Component({
  selector: 'provisioning-sequence',
  templateUrl: './provisioning-sequence.component.html',
  styleUrls: ['./provisioning-sequence.component.scss']
})
export class ProvisioningSequenceComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() provisioningSource: EProvisioningSource = EProvisioningSource.ODA;
  public state: EProvisionedState = EProvisionedState.Loading;
  public ProvisionStateEnum = EProvisionedState;
  public provisioned = false;
  public isODAProvisioning = true;
  // there are problems when using NodeJS.Timeout type
  // it breaks the core ExtDEV deployment pipeline, so we use "any" here
  provisionTimer: any;
  showProvisioningMessage = false;

  hasManageAzureADPermissions: boolean;
  hasManageCAPermissions: boolean;

  private pollingTimeInterval = 30000;
  private provisioningMaxTimeout = 300000; // 5 mintues in milliseconds
  constructor(
    private provisionService: ProvisioningService,
    private permissionsService: AuditModulePermissionsService,
    private router: Router
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isODAProvisioning =
      this.provisioningSource === EProvisioningSource.ODA;
    this.getUserPermissions();
    await this.provisionCheck();
  }

  ngOnDestroy() {
    this.stopPollForProvisioningStatus();
  }

  async provisionCheck() {
    this.state = await this.provisionService.getProvisioningStatus();
    switch (this.state) {
      case EProvisionedState.Provisioned:
        this.showProvisioningMessage = false;
        this.redirectToStartingPage();
        break;
      case EProvisionedState.Loading:
        this.showProvisioningMessage = false;
        this.pollUntilProvisionedOrError(this.state);
        break;
      case EProvisionedState.Provisionable:
      case EProvisionedState.Error:
        this.showProvisioningMessage = true;
        break;
      default:
        // This usecase should never happened.
        this.state = EProvisionedState.Error;
        this.showProvisioningMessage = true;
        break;
    }
  }

  pollUntilProvisionedOrError(provisionState: EProvisionedState) {
    if (provisionState === EProvisionedState.Provisioned) {
      this.provisionTimer = undefined;
      this.state = EProvisionedState.Provisioned;
      this.showProvisioningMessage = false;
      this.redirectToStartingPage();
    } else if (provisionState === EProvisionedState.Error) {
      this.provisionTimer = undefined;
      this.state = EProvisionedState.Error;
      this.showProvisioningMessage = true;
    } else {
      this.provisionTimer = setTimeout(async () => {
        if (this.checkIfStuckInProvisioning()) {
          this.provisionTimer = undefined;
          this.state = EProvisionedState.Error;
          this.showProvisioningMessage = true;
        } else {
          let currentProvisionState =
            await this.provisionService.getProvisioningStatus();
          this.pollUntilProvisionedOrError(currentProvisionState);
        }
      }, this.pollingTimeInterval);
    }
  }

  provisionOrganization() {
    this.state = EProvisionedState.Loading;
    this.showProvisioningMessage = false;

    // initiate provisioning
    this.provisionService.provisionOrg().subscribe(
      provisionedState => {
        if (provisionedState === EProvisionedState.Loading) {
          this.state = EProvisionedState.Loading;
        } else if (provisionedState === EProvisionedState.Provisioned) {
          this.state = EProvisionedState.Provisioned;
        } else {
          this.state = EProvisionedState.Error;
        }
        this.pollUntilProvisionedOrError(this.state);
      },
      error => {
        console.error('provisionOrganization: Failure response:', error);
        this.state = EProvisionedState.Error;
        this.showProvisioningMessage = true;
      }
    );
  }

  redirectToStartingPage() {
    if (this.isODAProvisioning) {
      this.router.navigate(['auditing', 'auditing', 'dashboard']);
    } else {
      this.router.navigate(['auditing', 'auditing', 'ca-setup']);
    }
  }

  private getUserPermissions() {
    this.hasManageAzureADPermissions =
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageAzureADTenants
      ]);

    this.hasManageCAPermissions = this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageChangeAuditor
    ]);
  }

  private stopPollForProvisioningStatus() {
    if (this.provisionTimer) {
      clearTimeout(this.provisionTimer);
      this.provisionTimer = undefined;
    }
  }

  private checkIfStuckInProvisioning(): boolean {
    let provisionStartDate = this.provisionService.getProvisionStartDate();
    let timeLapse =
      new Date().getTime() - new Date(provisionStartDate).getTime();
    if (timeLapse > this.provisioningMaxTimeout) {
      // Stuck in provisioning in progress status. Should not take this much time. Show error message.
      console.error('provisionOrganization: Stuck in provisioning in-progress status.');
      return true;
    }
    return false;
  }
}
