/**
 * Take the timezone from the current runtime environment and convert it
 * to a string suitable for use in an ISO 8601 datetime string
 */
export const getCurrentISOTimezoneOffset = () => {
  let originalOffset = (new Date()).getTimezoneOffset();
  let offsetInMinutes = Math.abs(originalOffset);
  let offsetHours: any = (Math.floor((offsetInMinutes / 60)).toString());
  offsetHours = offsetHours.padStart(2, '0');
  let offsetMinutes: any = (offsetInMinutes % 60).toString();
  offsetMinutes = offsetMinutes.padStart(2, '0');
  // Counterintuitively, the `getTimezoneOffset` function returns a positive
  // number for time zones *behind* UTC and negative number for time zones
  // *ahead* of UTC, so we flip the sign for our ISO string purposes
  let plusMinus = (originalOffset * -1) >= 0 ? '+' : '-';
  return `${plusMinus}${offsetHours}:${offsetMinutes}`;
};

export enum TimeInMilliseconds {
  Hour = 3600000,
  Day = 86400000,
  Week = 604800000,
  Month = 2592000000,
  Year = 31536000000,
}
