import { UpdateAuditHealthSettings } from '../../../../../../shared/models/update-widget-configuration';
import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  AuditHealthDataResponse,
  AuditHealthConfigurationResponse
} from '../models/audit-health.models';

export const LOAD_AUDIT_HEALTH = '[Auditing Dashboard] Load Audit Health';
export const LOAD_AUDIT_HEALTH_SUCCESS =
  '[Auditing Dashboard] Load Audit Health Success';
export const LOAD_AUDIT_HEALTH_FAIL =
  '[Auditing Dashboard] Load Audit Health Fail';
export const UPDATE_AUDIT_HEALTH = '[Auditing Dashboard] Update Audit Health';
export const UPDATE_AUDIT_HEALTH_FAIL =
  '[Auditing Dashboard] Update Audit Health Fail';
export const UPDATE_AUDIT_HEALTH_SUCCESS =
  '[Auditing Dashboard] Update Audit Health Success';
export class LoadAuditHealth {
  readonly type = LOAD_AUDIT_HEALTH;
}

// eslint-disable-next-line max-classes-per-file
export class LoadAuditHealthSuccess {
  readonly type = LOAD_AUDIT_HEALTH_SUCCESS;
  constructor(
    public payload: AuditingDashboardResponse<
      AuditHealthDataResponse,
      AuditHealthConfigurationResponse
    >
  ) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadAuditHealthFail {
  readonly type = LOAD_AUDIT_HEALTH_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateAuditHealth {
  readonly type = UPDATE_AUDIT_HEALTH;
  constructor(
    public payload: UpdateAuditHealthSettings
  ) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateAuditHealthSuccess {
  readonly type = UPDATE_AUDIT_HEALTH_SUCCESS;
}

// eslint-disable-next-line max-classes-per-file
export class UpdateAuditHealthFail {
  readonly type = UPDATE_AUDIT_HEALTH_FAIL;
  constructor(public payload: any) {}
}
export type AuditHealthAction =
  | LoadAuditHealth
  | LoadAuditHealthSuccess
  | LoadAuditHealthFail
  | UpdateAuditHealth
  | UpdateAuditHealthSuccess
  | UpdateAuditHealthFail;
