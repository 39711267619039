import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import {
  AppFacadeService,
  BaseComponent,
  NotificationPlansService
} from '@ondemand/core';
import { ODDialog } from '@ondemand/ui-components';
import {
  SvgIconName,
  NotificationMode,
  AuditConstants
} from '../../../../shared/models/ui-constants';
import { BHEStringsEN } from '../../../../shared/application-strings/bhe-strings-EN';
import {
  ADD_ICON_BLUE_V2_PATH,
  BHE_ICON_BLUE_V2_PATH
} from '../../../../shared/shared.variables';
import { BloodHoundEnterpriseConfigEditorComponent } from './bhe-config-editor/bhe-config-editor.component';
import { BHEServiceConnectedModalComponent } from './service-connected-modal/bhe-service-connected.modal.component';
import { BloodHoundConfigurationService } from '../../../services/bhe-service/bhe-config.service';
import {
  BHEAPIConfigurationOutput,
  BHEConfiguration
} from '../../../services/bhe-service/bhe-api.data';
import {
  audit,
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { BHERemoveConfirmationModalComponent } from './bhe-remove-modal/bhe-remove-modal';
import { EMPTY, of, Subscription, timer } from 'rxjs';
import {
  BheConfigStates,
  getNextConfigurationSyncInfo,
  getLastConfigurationReceivedStr
} from './bhe-config-shared';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { ODToastService } from '@ondemand/ui-components';
import {
  toastLowerLeft,
  ToastType
} from '../../../../shared/utils/toast.wrapper';
import { AuditingFeatureFlagsService } from '../../../services/auditing-feature-flags.service';
import { FeatureFlagType } from '../../shared/feature-flag.enum';
import { IOddeNotificationPlan } from '../../../models/odde.alert-plan.model';
import { INotificationPlan } from '@ondemand/core/notification-plans/models/notification-plan.model';

interface MoreDropdown {
  title: string;
}

@Component({
  selector: 'bhe-card',
  templateUrl: './bhe-card.component.html',
  styleUrls: ['./bhe-card.component.scss']
})
export class BloodHoundEnterpriseCardComponent extends BaseComponent implements OnInit {
  // variable section for adding configuration
  @ViewChild(BloodHoundEnterpriseConfigEditorComponent)
  flyoutEditor: BloodHoundEnterpriseConfigEditorComponent;
  connectionCheckInterval = 60000;
  nextConnectionSyncIntervalInHour = 1;

  addIconPath: string;

  addTitle: string;
  description: string;
  learnMoreLinkText: string;
  addButtonText: string;
  // End of variable section for adding configuration

  configExisted: boolean;
  BheConfigStates = BheConfigStates;
  configStates = BheConfigStates.None;
  initialized = false;
  loadingMessage: string;

  // variable section for status
  deletingMessage: string;
  isDeleting: boolean;
  bloodHoundIconPath: string;
  statusCardTitle: string;
  dropdownItems: MoreDropdown[];
  editConfigButtonLabel: string;
  removeConfigButtonLabel: string;

  notificationIconName: SvgIconName;
  notificationMode: NotificationMode;
  notificationMessage: string;

  showConnectionStatus: boolean;
  lastUpdatedDate: Date;
  lastUpdatedRelativeTimeStr: string;
  nextSyncTime: Date;
  nextSyncRelativeTimeStr: string;
  statusUpdating = false;
  isConnectionStatusReady = false;
  statusLabel: string;
  lastConfigurationReceived: string;
  nextConfigurationSync: string;
  updatingStatusTitle: string;
  // variable to hold the bhe connection status timer subscription
  connectionCheckTimer = new Subscription();
  // End of variable section for status

  bheConfig: BHEConfiguration;
  organizationId: string;

  alertMigration = false;
  showNotificationPlanEditor = false;
  tierZeroNotificationPlanID: string;
  readonly moduleName = AuditConstants.ModuleName;

  constructor(
    private router: Router,
    private facadeService: AppFacadeService,
    private dialog: ODDialog,
    private bheAPI: BloodHoundConfigurationService,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService,
    private featureFlagService: AuditingFeatureFlagsService,
    private notificationPlanService: NotificationPlansService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.organizationId = await this.facadeService.selectedOrgId$
      .pipe(take(1))
      .toPromise();

    this.setupText();
    this.setupDropdown();
    await this.checkConfigStatus();
    this.notificationPlanFF();

    this.displayBanner(this.configExisted);
    this.initialized = true;
    this.setConnectionCheckTimer();
    this.setLastConnectionStatus();
  }

  setConnectionCheckTimer() {
    if (this.configStates === BheConfigStates.Available) {
      this.connectionCheckTimer = timer(0, this.connectionCheckInterval)
        .pipe(takeUntil(this.destructionSubject))
        .subscribe(async () => {
          this.statusUpdating = true;
          await this.checkConfigStatus();
          this.setLastConnectionStatus();
          this.statusUpdating = false;
        });
    }
  }

  // Set latest configuration status messages.
  setLastConnectionStatus(): void {
    if (this.bheConfig) {
      // unix epoch date 1969/12/31 which represents the null date object in javascript.
      const unixEpochYear = 1969;
      this.lastUpdatedDate = this.bheConfig.lastSuccessfulAPICallDate;

      // check if BHE objects are retrieved yet
      if (this.lastUpdatedDate.getFullYear() !== unixEpochYear) {
        this.lastUpdatedRelativeTimeStr = getLastConfigurationReceivedStr(
          this.lastUpdatedDate
        );
      }

      const nextConfigSyncInfoObj = getNextConfigurationSyncInfo(
        this.bheConfig.nextUpdateDate
      );
      this.nextSyncTime = nextConfigSyncInfoObj.nextSyncTime;
      this.nextSyncRelativeTimeStr =
        nextConfigSyncInfoObj.nextSyncRelativeTimeStr;

      if (!this.bheConfig.lastFailedAPIErrorCode) {
        this.displayBanner(true);
      } else {
        this.displayBanner(false);
      }
    }
  }

  async checkConfigStatus(): Promise<void> {
    let configOutputs: BHEAPIConfigurationOutput[];

    try {
      const resp = await this.bheAPI.getConfiguration().toPromise();
      if (resp.status === 200) {
        configOutputs = resp.body;
      }
    } catch (ex) {
      configOutputs = [];
    }

    const configObjs: BHEConfiguration[] = configOutputs.map(
      x => new BHEConfiguration(x)
    );

    if (configObjs.length > 0) {
      this.bheConfig = configObjs[0];
      this.configExisted = true;
      this.configStates = BheConfigStates.Available;
    } else {
      this.bheConfig = undefined;
      this.configExisted = false;
      this.configStates = BheConfigStates.UnAvailable;
    }
  }

  addConfig(): void {
    this.flyoutEditor.openEditor();
  }

  editConfig(): void {
    // Edit bhe config action is being performed so we don't require connection check operation.
    this.connectionCheckTimer.unsubscribe();

    this.flyoutEditor.openEditor(this.bheConfig);
  }

  notificationPlanFF(): void {
    this.featureFlagService
      .getFlag(FeatureFlagType.EnableNotificationPlans)
      .pipe(
        catchError(error => {
          return of(false);
        }),
        filter(isEnabled => isEnabled),
        switchMap(() => {
          if (!this.tierZeroNotificationPlanID) {
            return this.notificationPlanService
              .getAlertPlanNotifications(0, 10000, this.moduleName)
              .pipe(
                catchError(error => {
                  this.alertMigration = false;
                  return of(new Array<INotificationPlan>());
                })
              );
          }
          return of(new Array<INotificationPlan>());
        }),
        filter(alertPlans => alertPlans.length > 0),
        takeUntil(this.destructionSubject)
      )
      .subscribe(alertPlans => {
        const tierZeroAlertPlan = (alertPlans as IOddeNotificationPlan[]).find(
          x => x.name === AuditConstants.TierZeroAlertPlanName
        );

        if (tierZeroAlertPlan) {
          this.tierZeroNotificationPlanID = tierZeroAlertPlan.id;
          this.alertMigration = true;
        }
      });
  }

  async removeConfig(): Promise<void> {
    const removeDialog = this.dialog.open(BHERemoveConfirmationModalComponent, {
      autoFocus: false
    });

    // Remove bhe config action is being performed so we don't require connection check operation.
    this.connectionCheckTimer.unsubscribe();

    let confirmed: boolean = await removeDialog.afterClosed().toPromise();

    if (confirmed) {
      this.initialized = false;
      this.isDeleting = true;
      this.bheAPI.removeConfig(this.bheConfig.id).subscribe(
        response => {
          this.isDeleting = false;
          if (response.ok) {
            toastLowerLeft(
              this.toastService,
              BHEStringsEN.RemoveSuccessToast,
              ToastType.Success
            );
            this.bheConfig = undefined;
            this.configExisted = false;
            this.configStates = BheConfigStates.UnAvailable;
            this.lastUpdatedDate = null;
            this.lastUpdatedRelativeTimeStr = null;
          } else {
            toastLowerLeft(
              this.toastService,
              BHEStringsEN.RemoveFailToast,
              ToastType.Error
            );
          }
        },
        error => {
          this.isDeleting = false;
          toastLowerLeft(
            this.toastService,
            BHEStringsEN.RemoveFailToast,
            ToastType.Error
          );
        }
      );
      this.initialized = true;
    } else {
      // Remove action is cancelled so initialize the timer again.
      this.setConnectionCheckTimer();
    }
  }

  handleEditorClose(configSaved: boolean): void {
    if (configSaved) {
      //TODO: Use new dialog ref when core UI component upgrades
      const connectedDialog: MatLegacyDialogRef<BHEServiceConnectedModalComponent> =
        this.dialog.open(BHEServiceConnectedModalComponent);

      connectedDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe(async () => {
          await this.checkConfigStatus();
          this.setConnectionCheckTimer();
          this.setLastConnectionStatus();
          this.displayBanner(this.configExisted);
        });
    } else {
      // BHE edit configuration is cancelled so initialize the timer again.
      this.setConnectionCheckTimer();
    }
  }

  dropdownItemEventHandler(eventData: any) {
    const menuText: string = eventData.currentTarget.innerText;
    switch (menuText) {
      case BHEStringsEN.DropdownMenuItemRefresh:
        this.statusUpdating = true;
        this.bheAPI.refreshConfig().toPromise();
        setTimeout(() => {
          this.statusUpdating = false;
        }, 1000);
        break;

      case BHEStringsEN.DropdownMenuItemEditAlertPlan:
        if (
          this.permissionsService.hasAllOfPermissions([
            fromPermissions.canManageSharedAlertsAndAlertPlans
          ])
        ) {
          if (this.alertMigration) {
            this.showNotificationPlanEditor = true;
          } else {
            this.router.navigate([
              '/auditing/auditing/alerts/plans',
              `AP.${AuditConstants.BloodhoundAlertPlanId}.${this.organizationId}`
            ]);
          }
        }
        break;

      case BHEStringsEN.DropdownMenuItemAbout:
        window.open(
          'https://www.quest.com/products/attack-path-management-software/#overview',
          '_blank'
        );
        break;
    }
  }

  private displayBanner(successStatus: boolean): void {
    this.notificationMessage = successStatus
      ? BHEStringsEN.StatucCardBannerSuccessMessage
      : BHEStringsEN.StatucCardBannerErrorMessage;
    this.notificationMode = successStatus
      ? NotificationMode.Success
      : NotificationMode.Error;
    this.notificationIconName = successStatus
      ? SvgIconName.Success
      : SvgIconName.Error;
    this.showConnectionStatus = true;
  }

  private setupText(): void {
    this.loadingMessage = BHEStringsEN.LoadingMessage;
    this.deletingMessage = BHEStringsEN.DeletingMessage;
    this.addIconPath = ADD_ICON_BLUE_V2_PATH;
    this.addTitle = BHEStringsEN.AddConfigCardTitle;
    this.description = BHEStringsEN.AddConfigCardDescription;
    this.learnMoreLinkText = BHEStringsEN.AddConfigCardLearnMoreLinkText;
    this.addButtonText = BHEStringsEN.AddConfigCardAddButtonText;

    this.bloodHoundIconPath = BHE_ICON_BLUE_V2_PATH;
    this.statusCardTitle = BHEStringsEN.StatusCardTitle;
    this.editConfigButtonLabel = BHEStringsEN.StatusCardEditConfigButtonLabel;
    this.removeConfigButtonLabel =
      BHEStringsEN.StatusCardRemoveConfigButtonLabel;
    this.statusLabel = BHEStringsEN.StatusConnectionLabel;
    this.lastConfigurationReceived =
      BHEStringsEN.LastConfigurationReceivedLabel;
    this.nextConfigurationSync =
      BHEStringsEN.NextConfigurationSyncConnectionLabel;
    this.updatingStatusTitle = BHEStringsEN.UpdatingStatusProgressTitle;
  }

  private setupDropdown(): void {
    let cursor = 0;
    this.dropdownItems = [];
    this.dropdownItems[cursor++] = {
      title: BHEStringsEN.DropdownMenuItemRefresh
    };

    if (
      this.permissionsService.hasAllOfPermissions([
        fromPermissions.canManageSharedAlertsAndAlertPlans
      ])
    ) {
      this.dropdownItems[cursor++] = {
        title: BHEStringsEN.DropdownMenuItemEditAlertPlan
      };
    }

    this.dropdownItems[cursor] = {
      title: BHEStringsEN.DropdownMenuItemAbout
    };
  }

  editorClosed() {
    this.showNotificationPlanEditor = false;
  }
}
