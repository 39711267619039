import { WidgetInformation } from '../../models/widget-info';
import * as fromWidgetList from './widget-list.actions';

export interface WidgetListState {
  data: WidgetInformation[];
  loading: boolean;
  error: boolean;
}

export const initialState: WidgetListState = {
  data: null,
  loading: false,
  error: false
};

export function reducer(
  state: WidgetListState = initialState,
  action: fromWidgetList.WidgetListAction
): WidgetListState {
  switch (action.type) {
    case fromWidgetList.LOAD_WIDGET_LIST:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromWidgetList.LOAD_WIDGET_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };
    case fromWidgetList.LOAD_WIDGET_LIST_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
