import { of, timer, combineLatest, Observable } from 'rxjs';
import {
  catchError,
  retry,
  delayWhen,
  map,
  mergeMap,
  switchMap,
  tap,
  filter,
  finalize,
  take,
  takeUntil,
  takeWhile
} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import {
  ITenant,
  BaseComponent,
  EConsentStatus,
  EPermissionType,
  EDialogType,
  Util,
  IModalDialog,
  EModuleName,
  EEffectivePermissionType,
  AppFacadeService,
  DisplayStringsProvider,
  AppState,
  State,
  ETenantRegionSubScope
} from '@ondemand/core';

import { TenantConfigurationService } from '../../../services/tenant-config.service';
import { TenantConfiguration } from '../../../models/tenant-configuration.model';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { ConsentService } from '../../../services/consent.service';
import { auditModuleName } from '../../../util/constants';
import { ChangeAuditorInstallation } from '../../../models/change-auditor-installation.model';
import { ChangeAuditorInstallationService } from '../../../services/ca-installation.service';
import {
  FeatureSubscriptionService,
  EAuditFeatureName
} from '../../../services/feature-subscription/feature-subscription.service';
import { EServiceID } from './service-ids';
import { limitedRetentionEventTypes } from '../../retention-settings/limited-retention-events';
import { tenantServiceList } from './service-list';
import { TenantStatusModalComponent } from '../tenant-status-modal/tenant-status-modal.component';
import { getTenantStatusIcon } from '../tenant-status-modal/tenant-status-icon';
import { Store } from '@ngrx/store';
import { FeatureFlagType } from '../../shared/feature-flag.enum';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import {
  TenantStatus,
  TenantStatusMessage
} from '../../../models/tenant-status.model';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';


const configUrl = 'auditing/auditing/config';
const configParamsKey = 'auditing.saveConfigParams';
const AddTenantFailedErrorKey = 'AddTenantFailedError';
const completeConsentStatuses = [
  EConsentStatus.Consented,
  EConsentStatus.Pending
];
const configRefreshIntervalInMilliseconds = 5000;
const buySubscriptionURL = 'https://www.quest.com/BuyOnDemandAudit';

export interface AuditService {
  id: EServiceID;
  label: string;
  selected: boolean;
  disabled: boolean;
}

@Component({
  selector: 'configuration-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  routingInProgress: boolean;
  tenantList: ITenant[];
  tenantConfigMap: any = {};
  tenantConsentStatusMap: any = {};
  anyTenantsConfigured = false;
  changeAuditorInstallations: ChangeAuditorInstallation[];
  tenantsHasError = false;
  errorMessage: string;
  tenantUpdateInProgress = true;
  initialConfigLoadCompleted = false;
  configsLoaded = false;
  configsError = false;
  coreStrings = DisplayStringsProvider;
  availableServices: AuditService[];
  activeCARequest = false;
  installationsLoaded = false;
  singleTenant: boolean;
  allowedToEditTenants$: Observable<boolean>;
  auditDebugFlag = false;
  debugMessage: any;
  fromPermissions = fromPermissions;
  hasManageAzureADPermissions: boolean;
  hasManageCAPermissions: boolean;
  allowedAccessGCC: boolean;
  statusMessageLength = 0;
  newConsentExperience: boolean;
  gccText: string;
  gccNonUSRegionError: string;
  displayGCCTenantErrorBannerMessage: string;

  @ViewChild(TenantStatusModalComponent, { static: true })
  private tenantStatusModal: TenantStatusModalComponent;
  private pollingForConfigUpdates = false;

  get enableBhe(): boolean {
    return (
      this.permissionsService.hasAllOfPermissions([
        fromPermissions.canManageSpectorOpsBloodHoundConfiguration
      ])
    );
  }

  constructor(
    private tenantConfigService: TenantConfigurationService,
    private facadeService: AppFacadeService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private localeStringsService: LocaleStringsService,
    private consentService: ConsentService,
    private caInstallService: ChangeAuditorInstallationService,
    private featureSubscriptionService: FeatureSubscriptionService,
    private store: Store<State>,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  async ngOnInit() {
    this.getUserPermissions();

    this.facadeService
      .featureFlag$(FeatureFlagType.Debug)
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(value => (this.auditDebugFlag = value));

    this.facadeService
      .featureFlag$(FeatureFlagType.NewExperience)
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(value => (this.newConsentExperience = value));

    this.gccText = await this.localeStringsService
      .string$('auditing.gccText')
      .pipe(take(1))
      .toPromise();

    this.gccNonUSRegionError = await this.localeStringsService
      .string$('auditing.gccNonUSRegionErrorMessage')
      .pipe(take(1))
      .toPromise();

    await this.setAvailableServices();
    await this.handleRedirectCase();

    this.activatedRoute.params
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(
        async params => {
          this.singleTenant = params.tenantId !== undefined;

          this.loadTenantList(params.tenantId);
          this.breadcrumbsService.set([
            {
              title: await this.localeStringsService
                .string$('auditing.configuration')
                .pipe(take(1))
                .toPromise(),
              url: 'auditing/auditing/config'
            }
          ]);

          combineLatest(
            this.featureSubscriptionService.hasSubscription$(
              EAuditFeatureName.CHANGE_AUDITOR
            ),
            this.featureSubscriptionService.hasSubscription$(
              EAuditFeatureName.CHANGE_AUDITOR_EXPIRED
            )
          )
            .pipe(takeUntil(this.destructionSubject))
            .subscribe(([activeSubscription, expiredSubscription]) => {
              if (activeSubscription || expiredSubscription) {
                this.loadInstallationInfo();
              } else {
                this.installationsLoaded = true;
              }
            });
        },
        () => {
          console.error('Failed to load route parameters');
        }
      );

    this.trackPermissions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (sessionStorage.getItem(AddTenantFailedErrorKey)) {
      sessionStorage.removeItem(AddTenantFailedErrorKey);
    }
  }

  /**
   * Select all services for auditing
   *
   */
  selectAll(tenant: ITenant, form: any) {
    let selected = !this.allServicesSelected(tenant);
    let config = this.getTenantConfig(tenant);
    if (config) {
      for (let service of config.settings) {
        // Allow unchecking disabled services, but not checking them
        if ((selected && !service.disabled) || !selected) {
          service.selected = selected;
        }
      }
    }
    let firstControl = form.controls[Object.keys(form.controls)[0]];
    firstControl.markAsDirty();
  }

  /**
   * Check if all services are currently selected for auditing on this tenant
   */
  allServicesSelected(tenant: ITenant): boolean {
    let config = this.getTenantConfig(tenant);
    if (config) {
      return config.settings.every((service: any) => service.selected);
    } else {
      return false;
    }
  }

  /**
   * Generate ID for a particular service and tenant
   *
   */
  getServiceIdForTenant(service: any, tenant: ITenant) {
    return `${service.id}-${tenant.questTenantId}`;
  }

  getTenantConfig(tenant: ITenant): TenantConfiguration {
    return this.tenantConfigMap[tenant.questTenantId] || null;
  }

  getTenantLastStatusCheckTimeIsUpdated(tenant: ITenant): boolean {
    const config = this.getTenantConfig(tenant);
    if (config.lastStatusUpdateCheck) {
      const statusTimeStamp = new Date(config.lastStatusUpdateCheck);
      return statusTimeStamp.getTime() > 0;
    }
    return false;
  }

  getStatusUpdateTimeInMs(config: TenantConfiguration): boolean {
    if (config['last-status-update-check']) {
      const statusTimeStamp = new Date(config['last-status-update-check']);
      return statusTimeStamp.getTime() > 0;
    }

    return false;
  }

  noTenantsConfigured(): boolean {
    return this.tenantList.length > 0 && !this.anyTenantsConfigured;
  }

  async onSubmit(tenant: ITenant, form: any) {
    this.tenantConfigMap[tenant.questTenantId].saving = true;
    await this.displayRetentionWarning(tenant);

    // If consent is needed, prompt user for it
    const consentStatus = this.tenantConsentStatusMap[tenant.questTenantId];
    if (completeConsentStatuses.includes(consentStatus)) {
      this.saveConfig(tenant, form);
    } else {
      // Prompt for consent if needed
      this.promptForConsent(tenant);
    }
  }

  /**
   * Handle removal of a Change Auditor installation
   *
   * @param installationId Change Auditor installation ID
   */
  onInstallationRemoved(installationId: string) {
    // Delete obsolete installation from our list
    this.changeAuditorInstallations = this.changeAuditorInstallations.filter(
      installation => installation.id !== installationId
    );
  }

  get numCards(): number {
    let count = 0;

    if (this.tenantList) {
      count += this.tenantList.length;
    }

    if (this.changeAuditorInstallations) {
      count += this.changeAuditorInstallations.length;
    }

    return count;
  }

  /**
   * Handle clicking on one of the service checkboxes. This is used to
   * handle clicking on disabled (i.e., not subscribed) services
   * since clicking a disabled checkbox would not normally have any effect
   *
   */
  async onClickService(service: AuditService) {
    if (service.disabled) {
      // Allow unchecking an individual disabled service
      if (service.selected) {
        service.selected = false;
      } else {
        const serviceInfo = tenantServiceList.find(
          item => item.id === service.id
        );

        let expiredSubscription = await this.featureSubscriptionService
          .getSubscription$(serviceInfo.expiredFeature)
          .pipe(take(1))
          .toPromise();
        let offboardingSubscription = await this.featureSubscriptionService
          .getSubscription$(serviceInfo.offboardingFeature)
          .pipe(take(1))
          .toPromise();
        let offboardedSubscription = await this.featureSubscriptionService
          .getSubscription$(serviceInfo.offboardedFeature)
          .pipe(take(1))
          .toPromise();

        if (expiredSubscription) {
          let messageReference = 'auditing.subscriptionExpiredMessage';
          let messageReplacements = {
            daysRemaining: Util.daysUntil(
              expiredSubscription.expiryDate,
              expiredSubscription.daysNoticeOffboarding
            ),
            daysToDeletion: Util.daysUntil(
              expiredSubscription.expiryDate,
              expiredSubscription.daysNoticeOffboarded
            )
          };
          this.promptToRenew(messageReference, messageReplacements);
        } else if (offboardingSubscription) {
          let messageReference = 'auditing.subscriptionOffboardingMessage';
          let messageReplacements = {
            daysToDeletion: Util.daysUntil(
              offboardingSubscription.expiryDate,
              offboardingSubscription.daysNoticeOffboarded
            )
          };
          this.promptToRenew(messageReference, messageReplacements);
        } else if (offboardedSubscription) {
          const offboarded = true;
          this.promptToBuy(offboarded);
        } else {
          this.promptToBuy();
        }
      }
    }
  }

  configIsSaving(tenant: ITenant) {
    if (this.getTenantConfig(tenant).saving) {
      return true;
    } else {
      return false;
    }
  }

  async onClickStatus(tenant: ITenant) {
    let config = this.getTenantConfig(tenant);
    this.tenantStatusModal.displayStatus(config.statusDict);
  }

  /**
   * Populate tenant objects with settings from web service
   */
  loadTenantSettings() {
    if (this.pollingForConfigUpdates) {
      // Prevent creating duplicate subscriptions for polling for updates
      return;
    }

    this.configsLoaded = false;
    this.configsError = false;
    let activeRequest = false;
    let statusIsUpdating: boolean = null;
    this.pollingForConfigUpdates = true;
    timer(0, configRefreshIntervalInMilliseconds)
      .pipe(
        takeUntil(this.destructionSubject),
        takeWhile(() => statusIsUpdating !== false),
        finalize(() => {
          this.pollingForConfigUpdates = false;
        }),
        filter(() => !activeRequest),
        filter(() => statusIsUpdating === true || statusIsUpdating === null),
        tap(() => (activeRequest = true)),
        switchMap(() => this.tenantConfigService.getAllConfigurations()),
        tap(() => (activeRequest = false))
      )
      .subscribe(
        (configRecords: TenantConfiguration[]) => {
          statusIsUpdating = configRecords.some(
            config => !this.getStatusUpdateTimeInMs(config)
          );
          if (configRecords.length > 0) {
            this.anyTenantsConfigured = true;
          }
          configRecords.forEach(config => {
            this.setConfig(config);
          });

          // Set default entries to blank for tenants with no settings saved yet
          this.tenantList.forEach(tenant => {
            if (!this.tenantConfigMap[tenant.questTenantId]) {
              this.tenantConfigMap[tenant.questTenantId] =
                this.getDefaultSettings();
            }

            this.configsLoaded = true;
            this.initialConfigLoadCompleted = true;
          });
        },
        error => {
          console.error('Could not load config data:', error);
          this.configsLoaded = true;
          this.configsError = true;
        }
      );
  }

  onClearGCCErrorMessage() {
    this.displayGCCTenantErrorBannerMessage = null;
  }

  private trackPermissions() {
    const editTenantPermissions = [EEffectivePermissionType.writeTenants];
    this.allowedToEditTenants$ = this.store.pipe(
      AppState.Letters.Core.EffectivePermissions.getIfEffectivePermissionIsOn(
        EModuleName.CORE,
        editTenantPermissions
      )
    );
  }

  private async setAvailableServices() {
    this.availableServices = await Promise.all(
      tenantServiceList.map(async service => ({
          id: service.id,
          label: await this.localeStringsService
            .string$(service.label)
            .pipe(take(1))
            .toPromise(),
          selected: false,
          disabled:
            (await this.featureSubscriptionService
              .hasSubscription$(service.feature)
              .pipe(take(1))
              .toPromise()) === false
        }))
    );
  }

  /**
   * Load list of current tenants from web service
   *
   */
  private loadTenantList(tenantId?: string) {
    this.tenantsHasError = false;
    this.tenantUpdateInProgress = true;
    this.facadeService.allTenants$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(
        async (tenants: ITenant[]) => {
          // Restrict to the single tenant specified if a tenant ID was passed
          if (tenantId) {
            tenants = tenants.filter(
              tenant => tenant.questTenantId === tenantId
            );
          }

          // Filter tenants depends on feature flag
          tenants = this.filterTenants(tenants);

          // Populate consent status
          await this.setConsentStatus(tenants);

          this.tenantList = tenants;
          if (tenants.length > 0) {
            this.loadTenantSettings();
          } else {
            this.configsLoaded = true;
          }
          this.tenantUpdateInProgress = false;
        },
        () => {
          this.tenantUpdateInProgress = false;
          this.tenantsHasError = true;
          this.errorMessage = 'Failed to load tenants: ';
        }
      );
  }

  private async setConsentStatus(tenants: ITenant[]): Promise<void> {
    await Promise.all(
      tenants.map(async tenant => {
        const consentStatus = await this.consentService
          .hasAdminConsentForStandardPermission(tenant.questTenantId)
          .pipe(take(1))
          .toPromise();
        this.tenantConsentStatusMap[tenant.questTenantId] = consentStatus;
      })
    );
  }

  private setConfig(config: TenantConfiguration) {
    if (!this.tenantList) {
      return;
    }

    // Find matching tenant from tenant list
    let tenant = this.tenantList.find(
      item => item.questTenantId === config.questTenantId
    );
    if (!tenant) {
      return;
    }

    let settings = deepClone(this.availableServices);
    settings.forEach((item: any) => {
      // Copy boolean from each recognized service ID
      item.selected = config[item.id] || false;
    });

    // Save settings to map object
    this.tenantConfigMap[tenant.questTenantId] = {
      settings
    };

    if (config.statusDict) {
      this.setStatusInfo(
        tenant,
        config.statusDict,
        config['last-status-update-check']
      );
    }
  }

  private async displayRetentionWarning(tenant: ITenant) {
    let selectedServices = this.getTenantConfig(tenant)
      .settings.filter(
        (serviceSetting: any) => serviceSetting.selected === true
      )
      .map((serviceSetting: any) => serviceSetting.id);

    let affectedEventTypes = limitedRetentionEventTypes.filter(eventType =>
      selectedServices.includes(eventType.serviceID)
    );
    if (affectedEventTypes.length > 0) {
      let listItemsHTML = '';
      for (let eventType of affectedEventTypes) {
        const serviceName = await this.localeStringsService
          .string$(eventType.serviceNameReference)
          .pipe(take(1))
          .toPromise();
        const eventTypeName = await this.localeStringsService
          .string$(eventType.eventTypeNameReference)
          .pipe(take(1))
          .toPromise();
        listItemsHTML += `<li>${serviceName} - ${eventTypeName}</li>`;
      }

      const title = await this.localeStringsService
        .string$('auditing.retentionWarningAlertTitle')
        .pipe(take(1))
        .toPromise();
      const message = await this.localeStringsService
        .string$('auditing.retentionWarningAlert', {
          eventListHTML: `
          <ul class="affected-event-types browser-default">
            ${listItemsHTML}
          </ul>
        `
        })
        .pipe(take(1))
        .toPromise();
      const alertOptions: IModalDialog = {
        type: EDialogType.INFO,
        useContentStyle: false
      };
      await this.facadeService.alert(title, message, alertOptions);
    }
  }

  private async promptToBuy(offboarded: boolean = false) {
    let message: string;
    let dialogType: EDialogType;
    if (offboarded) {
      message = 'auditing.subscriptionOffboardedMessage';
      dialogType = EDialogType.ERROR;
    } else {
      message = 'auditing.buyO365ServicesMessage';
      dialogType = EDialogType.INFO;
    }

    if (
      await this.facadeService.confirm(
        await this.localeStringsService
          .string$('auditing.buySubscriptionTitle')
          .pipe(take(1))
          .toPromise(),
        await this.localeStringsService
          .string$(message)
          .pipe(take(1))
          .toPromise(),
        {
          type: dialogType,
          okText: await this.localeStringsService
            .string$('auditing.buyPromptButtonLabel')
            .pipe(take(1))
            .toPromise()
        }
      )
    ) {
      this.facadeService.openInNewWindow(buySubscriptionURL);
    }
  }

  private async promptToRenew(
    messageReference: string,
    messageReplacements: any
  ) {
    const title = await this.localeStringsService
      .string$('auditing.renewSubscriptionTitle')
      .pipe(take(1))
      .toPromise();
    let message = await this.localeStringsService
      .string$(messageReference, messageReplacements)
      .pipe(take(1))
      .toPromise();
    const settings = {
      type: EDialogType.ERROR,
      okText: await this.localeStringsService
        .string$('auditing.renewPromptButtonLabel')
        .pipe(take(1))
        .toPromise()
    };
    if (await this.facadeService.confirm(title, message, settings)) {
      this.facadeService.openInNewWindow(buySubscriptionURL);
    }
  }

  private async promptForConsent(tenant: ITenant) {
    const title = await this.localeStringsService
      .string$('auditing.auditConsentPromptTitle')
      .pipe(take(1))
      .toPromise();
    const message = (
      await this.facadeService
        .string$<string[]>('addingTenantInfo')
        .pipe(take(1))
        .toPromise()
    ).join('<br>');

    let confirmed = await this.facadeService.confirm(title, message);
    if (confirmed) {
      const serviceSelections = this.getTenantConfig(tenant)
        .settings.filter((service: any) => service.selected)
        .map((service: any) => service.id);
      const params = {
        questTenantId: tenant.questTenantId,
        module: auditModuleName,
        permissionType: EPermissionType.STANDARD,
        redirect: true
      };

      const saveConfigParams = {
        tenantId: tenant.questTenantId,
        services: serviceSelections
      };
      sessionStorage.setItem(configParamsKey, JSON.stringify(saveConfigParams));

      this.facadeService.requestTenantConsent$(params);
    }
  }

  /**
   * Save tenant settings
   *
   */
  private saveConfig(tenant: ITenant, form?: UntypedFormGroup): Promise<any> {
    return new Promise<void>(resolve => {
      let newConfig = new TenantConfiguration();
      newConfig.directoryTenantId = tenant.directoryTenantId;
      newConfig.questTenantId = tenant.questTenantId;
      let tenantConfig = this.getTenantConfig(tenant);
      tenantConfig.settings.forEach((service: any) => {
        newConfig[service.id] = service.selected;
      });

      tenantConfig.saving = true;
      let request;
      if (tenantConfig.isNew) {
        request =
          this.tenantConfigService.createConfigurationWithHttpInfo(newConfig);
      } else {
        request = this.tenantConfigService.updateConfigurationWithHttpInfo(
          tenant.questTenantId,
          newConfig
        );
      }

      request.subscribe(
        async response => {
          const savedConfig: TenantConfiguration = response.body;
          this.setConfig(savedConfig);

          const toastMessage = await this.localeStringsService
            .string$('auditing.successfulSave')
            .pipe(take(1))
            .toPromise();
          toastLowerLeft(this.toastService, toastMessage, ToastType.Success);
          tenantConfig.saving = false;
          tenantConfig.isNew = false;

          // Set form as unmodified
          if (form) {
            Object.keys(form.controls).forEach(controlName => {
              form.controls[controlName].markAsPristine();
            });
          }

          if (!this.getStatusUpdateTimeInMs(savedConfig)) {
            setTimeout(() => {
              this.loadTenantSettings();
            }, configRefreshIntervalInMilliseconds);
          }

          resolve();
        },
        async () => {
          const toastMessage = await this.localeStringsService
            .string$('auditing.failedSaveError')
            .pipe(take(1))
            .toPromise();
          toastLowerLeft(this.toastService, toastMessage, ToastType.Error);
          tenantConfig.saving = false;

          // Reset tenant config
          tenantConfig.settings.forEach(
            (service: any) => (service.selected = false)
          );
          resolve();
        }
      );
    });
  }

  /**
   * When a user grants consent for a tenant on the `Auditing > Config` page,
   * the user will be redirected from Azure back to the config page. This method
   * checks whether this is a redirect case.
   *
   */
  private handleRedirectCase(): Promise<any> {
    return new Promise<void>(resolve => {
      this.routingInProgress = true;
      this.activatedRoute.params
        .pipe(takeUntil(this.destructionSubject), take(1))
        .subscribe(
          async (params: any) => {
            // If no redirect from Microsoft occurred, return early
            if (!this.isAzureRedirect(params)) {
              this.routingInProgress = false;
              resolve();
              return;
            }

            // Handle case of redirecting from Audit module consent process
            if (sessionStorage.getItem(configParamsKey)) {
              if (this.isAzureRedirectSuccess(params)) {
                await this.handleAuditConsentGranted();
              } else if (this.isAzureRedirectError(params)) {
                const errorType = params.error;
                if (errorType === 'access_denied') {
                  this.errorMessage = await this.localeStringsService
                    .string$('auditing.grantAuditConsentDenied')
                    .pipe(take(1))
                    .toPromise();
                } else {
                  this.errorMessage = await this.localeStringsService
                    .string$('auditing.grantAuditConsentFailed')
                    .pipe(take(1))
                    .toPromise();
                }
                sessionStorage.removeItem(configParamsKey);
                this.cleanUrl();
              } else {
                console.error('Invalid state from redirect.');
              }
            } else {
              // Handle case of adding a tenant
              if (this.isAzureRedirectSuccess(params)) {
                try {
                  const tenant = await this.facadeService.registerTenant$(
                    this.activatedRoute
                  );
                  // Special handling Note:-
                  // The tenant - all auditing components gets loaded two times, when it routes back from microsoft webside or,
                  // when refreshed. After adding a new tenant in Ms page, the first instance does receive the error message.
                  // The seconds instance is the one draw the UI and shows the error banner message,
                  // but it does not get error exception message. So, we had to store the error message of first instance and
                  // use it in seconds instance to show the UI. Once the two instance issue is fixed, the catch will handle and
                  // show the error and we will not require to store this extra session storage with exception error message.
                  if (tenant === null) {
                    this.checkAddTenantFailureErrorFromSessionStorage(tenant);
                  }
                } catch (error) {
                  console.error('Registering tenant failed:', error);
                  sessionStorage.setItem(AddTenantFailedErrorKey, error);
                  if (
                    String(error).toUpperCase() ===
                    this.gccNonUSRegionError.toUpperCase()
                  ) {
                    this.displayGCCTenantErrorBannerMessage =
                      await this.localeStringsService
                        .string$('auditing.gccTenantErrorBannerMessage')
                        .pipe(take(1))
                        .toPromise();
                  } else {
                    this.errorMessage = await this.localeStringsService
                      .string$('auditing.addTenantFailed')
                      .pipe(take(1))
                      .toPromise();
                  }
                }
              } else if (this.isAzureRedirectError(params)) {
                const errorType = params.error;

                if (errorType === 'access_denied') {
                  this.errorMessage = await this.localeStringsService
                    .string$('auditing.addTenantDenied')
                    .pipe(take(1))
                    .toPromise();
                } else {
                  this.errorMessage = await this.localeStringsService
                    .string$('auditing.addTenantFailed')
                    .pipe(take(1))
                    .toPromise();
                }
                this.cleanUrl();
              } else {
                console.error('Invalid state from redirect.');
              }
            }
            resolve();
          },
          (error: any) => {
            this.routingInProgress = false;
            console.error('Failed to get route parameters:', error);
            resolve();
          },
          () => {
            this.routingInProgress = false;
          }
        );
    });
  }

  private checkAddTenantFailureErrorFromSessionStorage(tenant: ITenant) {
    setTimeout(async () => {
      const tenantFailedMessage = sessionStorage.getItem(
        AddTenantFailedErrorKey
      );
      if (tenantFailedMessage) {
        if (
          tenantFailedMessage.toUpperCase() ===
          this.gccNonUSRegionError.toUpperCase()
        ) {
          this.displayGCCTenantErrorBannerMessage =
            await this.localeStringsService
              .string$('auditing.gccTenantErrorBannerMessage')
              .pipe(take(1))
              .toPromise();
        } else {
          this.errorMessage = await this.localeStringsService
            .string$('auditing.addTenantFailed')
            .pipe(take(1))
            .toPromise();
        }
        sessionStorage.removeItem(AddTenantFailedErrorKey);
      }
    }, 5000);
  }

  private handleAuditConsentGranted() {
    return new Promise<void>(resolve => {
      this.facadeService.registerTenantConsent$(this.activatedRoute).then(
        _tenant => {
          this.cleanUrl();
          this.saveCachedConfigOptions();
          resolve();
        },
        error => {
          this.errorRegisteringConsent(error);
          this.cleanUrl();
          resolve();
        }
      );
    });
  }

  private async saveCachedConfigOptions() {
    // Restore unsaved config changes, if any
    const saveConfigParams = JSON.parse(
      sessionStorage.getItem(configParamsKey)
    );
    if (saveConfigParams) {
      let tenants = await this.facadeService.allTenants$
        .pipe(take(1))
        .toPromise();
      const tenant = tenants.find(
        i => i.questTenantId === saveConfigParams.tenantId
      );
      if (!tenant) {
        console.error('Could not find tenant ' + saveConfigParams.tenantId);
        return;
      }
      // Filter tenants
      tenants = this.filterTenants(tenants);
      let config = this.tenantConfigMap[tenant.questTenantId];
      if (!config) {
        config = this.getDefaultSettings();
        this.tenantConfigMap[tenant.questTenantId] = config;
      }
      // Copy selections from storage to form
      config.settings.forEach((service: any) => {
        service.selected = saveConfigParams.services.includes(service.id);
      });
      this.saveConfig(tenant);
      sessionStorage.removeItem(configParamsKey);
    } else {
      console.error('Could not find cached config options');
    }
  }

  private async errorRegisteringConsent(error: any) {
    this.errorMessage = await this.localeStringsService
      .string$('auditing.errorRegisteringConsent')
      .pipe(take(1))
      .toPromise();
    this.debugMessage = !this.auditDebugFlag ? null : error;
    sessionStorage.removeItem(configParamsKey);
    console.error(error);
  }

  private isAzureRedirect(params: any) {
    return params.state !== undefined;
  }

  private isAzureRedirectSuccess(params: any): boolean {
    if (this.isAzureRedirectError(params)) {
      return false;
    }

    if (!this.newConsentExperience) {
      return params.code && params.state;
    }

    return !!params.state;
  }

  private isAzureRedirectError(params: any) {
    return params.error || params.error_description;
  }

  private cleanUrl() {
    this.location.go(configUrl);
  }

  private getDefaultSettings() {
    return {
      settings: deepClone(this.availableServices),
      isNew: true,
      lastStatusUpdateCheck: Date.now()
    };
  }

  private loadInstallationInfo() {
    const intervalTime = 5000;
    // Set minimum load time so users can see that it is refreshing
    const minLoadTime = 1500;
    let startTime: number;
    let lastReceivedData: string;

    timer(0, intervalTime)
      .pipe(
        takeUntil(this.destructionSubject),
        // Don't look up if we're viewing a single Azure tenant
        filter(() => !this.singleTenant),
        // Don't make overlapping HTTP requests
        filter(() => !this.activeCARequest),
        tap(() => (this.activeCARequest = true)),
        tap(() => (startTime = Date.now())),
        mergeMap(() => this.caInstallService.getInstallations()),
        map(response => response.body),
        delayWhen(() => timer(minLoadTime + startTime - Date.now())),
        tap(
          (data: ChangeAuditorInstallation[]) => {
            this.installationsLoaded = true;
            this.activeCARequest = false;
            // Prevent needlessly re-rendering on page if nothing has changed
            if (JSON.stringify(data) !== lastReceivedData) {
              this.changeAuditorInstallations = data;
              lastReceivedData = JSON.stringify(data);
            }
          },
          () => {
            this.activeCARequest = false;
          }
        ),
        retry(3),
        catchError(() => {
          console.error('Error loading installations');
          return of('Failed to load CA installations');
        })
      )
      .subscribe();
  }

  private getHighestTenantStatusSeverity(
    status: Map<string, TenantStatus>
  ): [string, number] {
    // The API call returns a generic object instead of an actualy Map, so we use generic object methods.

    const tenantStatusList = Object.values(status);
    const messages: TenantStatusMessage[] = tenantStatusList.reduce(
      (accumulator: any, value: any) => accumulator.concat(value.messages),
      []
    );

    if (messages.length === 0) {
      return [null, 0];
    }

    const severityWeight: any = {
      error: 3,
      warning: 2,
      information: 1
    };

    messages.sort((a: TenantStatusMessage, b: TenantStatusMessage) => severityWeight[b.severity] - severityWeight[a.severity]);

    return [messages[0].severity, messages.length];
  }

  private setStatusInfo(
    tenant: ITenant,
    statusDict: Map<string, TenantStatus>,
    lastStatusUpdateCheck: Date
  ) {
    let statusMessagesSeverity =
      this.getHighestTenantStatusSeverity(statusDict);
    let statusIcon = getTenantStatusIcon(statusMessagesSeverity[0]);
    this.tenantConfigMap[tenant.questTenantId] = {
      ...this.tenantConfigMap[tenant.questTenantId],
      statusDict,
      statusIcon,
      lastStatusUpdateCheck,
      messageCount: statusMessagesSeverity[1]
    };
  }

  private getUserPermissions() {
    this.hasManageAzureADPermissions =
      this.permissionsService.hasAnyOfPermissions([
        fromPermissions.canManageAzureADTenants
      ]);

    this.hasManageCAPermissions = this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageChangeAuditor
    ]);
  }

  private filterTenants(tenants: ITenant[]): ITenant[] {
    // cloudEnvironment value is "ECloudEnvironment.usGov" for HighGCC tenants, which we are not supporting.
    return tenants.filter(
      tenant =>
        !tenant.cloudEnvironment &&
        (!tenant.tenantRegionSubScope ||
          tenant.tenantRegionSubScope === ETenantRegionSubScope.GCC)
    );
  }
}

function deepClone(data: any) {
  return JSON.parse(JSON.stringify(data));
}
