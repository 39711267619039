import { ChartPosition } from '../../../../auditing/models/chart-options/chart-models';
export const CountColumnID = 'EventsCount';
export const OthersColumnID = 'Others';
export const UserColumnID = 'User';

/* These positions and dimensions are defined for container of various sizes.
Examples: flyout on critical activity, search results visualization*/
export const chartCanvasBox = {
positionOne :  { top: '0', left: '0', width: '23.857rem', height: '20.714rem' } as ChartPosition,
positionTwo: { top: '0', left: '0', width: '17.857rem', height: '15.714rem' } as ChartPosition,
positionThree: { top: '0', left: '0', width: '15.857rem', height: '13.714rem' } as ChartPosition
};
