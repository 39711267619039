<div class="outer-provisioning-sequence-container">
  <div *ngIf="this.isODAProvisioning" class="auditlbl">Audit</div>
  <internal-loading
      class="center"
      information="{{
        'auditing.provisions.provisioningSpinnerDescription' | localize
      }}"
      [isLoading]="this.state === ProvisionStateEnum.Loading"
  ></internal-loading>
  <div class="card-panel provisioning_container" *ngIf="showProvisioningMessage">
    <div class="center">
      <div *ngIf="this.state === ProvisionStateEnum.Provisionable">
        <img src="/assets/auditing/img/pre-provisioning.svg" />
        <h2 class="even-space">
          {{ 'auditing.provisions.gettingStarted' | localize }}
        </h2>
        <div class="provision-message" *ngIf="this.isODAProvisioning">
          {{ 'auditing.provisions.LandingPageODAMessage' | localize }}
        </div>
        <div class="provision-message" *ngIf="!this.isODAProvisioning">
          {{ 'auditing.provisions.LandingPageCAMessage' | localize }}
        </div>
        <div class="boldlbl continue-getting-started">
          {{ 'auditing.provisions.continueGetStarted' | localize }}
        </div>
        <div class="center colflex even-space" *ngIf="this.isODAProvisioning">
          <button
            [disabled]="!hasManageAzureADPermissions"
            class="center-button waves-effect waves-light btn right"
            id="provision-button"
            (click)="this.provisionOrganization()"
          >
            {{ 'auditing.provisions.continueLabel' | localize }}
          </button>
        </div>
        <div class="center colflex even-space" *ngIf="!this.isODAProvisioning">
          <button
            [disabled]="!hasManageCAPermissions"
            class="center-button waves-effect waves-light btn right"
            id="provision-button"
            (click)="this.provisionOrganization()"
          >
            {{ 'auditing.provisions.continueLabel' | localize }}
          </button>
        </div>
      </div>
      <div *ngIf="this.state === ProvisionStateEnum.Error">
        <img src="/assets/auditing/img/error-cloud-icon.svg" />
        <h2 class="errorlbl even-space">
          {{ 'auditing.provisions.errorLabel' | localize }}
        </h2>
        <div class="provision-message-retrylabel">
          {{ 'auditing.provisions.retryLabel' | localize }}
        </div>
        <div class="boldlbl retry-bold-label">
          {{ 'auditing.provisions.retryBoldLabel' | localize }}
        </div>
        <div class="center colflex even-space" *ngIf="this.isODAProvisioning">
          <button
            [disabled]="!hasManageAzureADPermissions"
            class="center-button waves-effect waves-light btn right"
            id="provision-button"
            (click)="this.provisionOrganization()"
          >
            {{ 'auditing.provisions.tryAgainLabel' | localize }}
          </button>
        </div>
        <div class="center colflex even-space" *ngIf="!this.isODAProvisioning">
          <button
            [disabled]="!hasManageCAPermissions"
            class="center-button waves-effect waves-light btn right"
            id="provision-button"
            (click)="this.provisionOrganization()"
          >
            {{ 'auditing.provisions.tryAgainLabel' | localize }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
