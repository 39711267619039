import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { TopActiveUsersDataService } from '../services/top-active-users.data.service';
import {
  LoadTopActiveUsers,
  LoadTopActiveUsersFail,
  LoadTopActiveUsersSuccess,
  LOAD_TOP_ACTIVE_USER,
  UpdateTopActiveUsersConfig,
  UpdateTopActiveUsersConfigFail,
  UpdateTopActiveUsersConfigSuccess,
  UPDATE_TOP_ACTIVE_USERS_CONFIG
} from './top-active-users.actions';

@Injectable()
export class TopActiveUsersEffects {
  loadTopActiveUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadTopActiveUsers>(LOAD_TOP_ACTIVE_USER),
    switchMap(action =>
      this.TopActiveUserService.getTopActiveUser().pipe(
        map(response => new LoadTopActiveUsersSuccess(response)),
        catchError(error => of(new LoadTopActiveUsersFail(error)))
      )
    )
  ));

  updateTopActiveUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UpdateTopActiveUsersConfig>(UPDATE_TOP_ACTIVE_USERS_CONFIG),
    switchMap(action =>
      this.TopActiveUserService.UpdateTopActiveUsersConfig(action.payload).pipe(
        map(response => response.status === 200
            ? new UpdateTopActiveUsersConfigSuccess()
            : new UpdateTopActiveUsersConfigFail(
                `Unexpected response code received: ${response.status}`
              )),
        catchError(error => of(new UpdateTopActiveUsersConfigFail(error)))
      )
    )
  ));

  constructor(
    private TopActiveUserService: TopActiveUsersDataService,
    private actions$: Actions
  ) {}
}
