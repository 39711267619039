import { AuditingDashboardResponse } from '../../../models/auditing-dashboard.models';
import {
  StaticIndicatorResponse,
  StaticIndicatorDataResponse,
  StaticIndicatorConfigurationResponse,
  TrendColor
} from '../models/static-indicator.models';
import * as fromStaticIndicator from './static-indicator.actions';

export interface StaticIndicatorState {
  // AdAccountLockouts
  dataAdAccountLockouts: StaticIndicatorResponse;
  trendColorAdAccountLockouts: string;
  loadingAdAccountLockouts: boolean;
  errorAdAccountLockouts: any;

  // AllFailedSignins
  dataAllFailedSignins: StaticIndicatorResponse;
  trendColorAllFailedSignins: string;
  loadingAllFailedSignins: boolean;
  errorAllFailedSignins: any;

  // AzureADCriticalRoleChanges
  dataAzureADCriticalRoleChanges: StaticIndicatorResponse;
  trendColorAzureADCriticalRoleChanges: string;
  loadingAzureADCriticalRoleChanges: boolean;
  errorAzureADCriticalRoleChanges: any;

  // AzureADFailedSignins
  dataAzureADFailedSignins: StaticIndicatorResponse;
  trendColorAzureADFailedSignins: string;
  loadingAzureADFailedSignins: boolean;
  errorAzureADFailedSignins: any;

  // AzureADRiskEvents
  dataAzureADRiskEvents: StaticIndicatorResponse;
  trendColorAzureADRiskEvents: string;
  loadingAzureADRiskEvents: boolean;
  errorAzureADRiskEvents: any;

  // CloudOnlyAzureADUsersCreated
  dataCloudOnlyAzureADUsersCreated: StaticIndicatorResponse;
  trendColorCloudOnlyAzureADUsersCreated: string;
  loadingCloudOnlyAzureADUsersCreated: boolean;
  errorCloudOnlyAzureADUsersCreated: any;

  // O365ExternalUserActions
  dataO365ExternalUserActions: StaticIndicatorResponse;
  trendColorO365ExternalUserActions: string;
  loadingO365ExternalUserActions: boolean;
  errorO365ExternalUserActions: any;
}

export const initialState: StaticIndicatorState = {
  // AdAccountLockouts
  dataAdAccountLockouts: null,
  trendColorAdAccountLockouts: TrendColor.positive,
  loadingAdAccountLockouts: false,
  errorAdAccountLockouts: undefined,

  // AllFailedSignins
  dataAllFailedSignins: null,
  trendColorAllFailedSignins: TrendColor.positive,
  loadingAllFailedSignins: false,
  errorAllFailedSignins: undefined,

  // AzureADCriticalRoleChanges
  dataAzureADCriticalRoleChanges: null,
  trendColorAzureADCriticalRoleChanges: TrendColor.positive,
  loadingAzureADCriticalRoleChanges: false,
  errorAzureADCriticalRoleChanges: undefined,

  // AzureADFailedSignins
  dataAzureADFailedSignins: null,
  trendColorAzureADFailedSignins: TrendColor.positive,
  loadingAzureADFailedSignins: false,
  errorAzureADFailedSignins: undefined,

  // AzureADRiskEvents
  dataAzureADRiskEvents: null,
  trendColorAzureADRiskEvents: TrendColor.positive,
  loadingAzureADRiskEvents: false,
  errorAzureADRiskEvents: undefined,

  // CloudOnlyAzureADUsersCreated
  dataCloudOnlyAzureADUsersCreated: null,
  trendColorCloudOnlyAzureADUsersCreated: TrendColor.positive,
  loadingCloudOnlyAzureADUsersCreated: false,
  errorCloudOnlyAzureADUsersCreated: undefined,

  // O365ExternalUserActions
  dataO365ExternalUserActions: null,
  trendColorO365ExternalUserActions: TrendColor.positive,
  loadingO365ExternalUserActions: false,
  errorO365ExternalUserActions: undefined
};

export function reducer(
  state: StaticIndicatorState = initialState,
  action: fromStaticIndicator.StaticIndicatorAction
): StaticIndicatorState {
  switch (action.type) {
    // AdAccountLockouts
    case fromStaticIndicator.LOAD_AD_ACCOUNT_LOCKOUTS:
      return {
        ...state,
        dataAdAccountLockouts: null,
        loadingAdAccountLockouts: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_AD_ACCOUNT_LOCKOUTS:
      return {
        ...state,
        trendColorAdAccountLockouts: action.payload
      };

    case fromStaticIndicator.LOAD_AD_ACCOUNT_LOCKOUTS_SUCCESS:
      return {
        ...state,
        dataAdAccountLockouts: action.payload,
        loadingAdAccountLockouts: false,
        errorAdAccountLockouts: false
      };

    case fromStaticIndicator.LOAD_AD_ACCOUNT_LOCKOUTS_FAIL:
      return {
        ...state,
        dataAdAccountLockouts: null,
        loadingAdAccountLockouts: false,
        errorAdAccountLockouts: action.payload
      };

    // AllFailedSignins
    case fromStaticIndicator.LOAD_ALL_FAILED_SIGNINS:
      return {
        ...state,
        dataAllFailedSignins: null,
        loadingAllFailedSignins: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_ALL_FAILED_SIGNINS:
      return {
        ...state,
        trendColorAllFailedSignins: action.payload
      };

    case fromStaticIndicator.LOAD_ALL_FAILED_SIGNINS_SUCCESS:
      return {
        ...state,
        dataAllFailedSignins: action.payload,
        loadingAllFailedSignins: false,
        errorAllFailedSignins: false
      };

    case fromStaticIndicator.LOAD_ALL_FAILED_SIGNINS_FAIL:
      return {
        ...state,
        dataAllFailedSignins: null,
        loadingAllFailedSignins: false,
        errorAllFailedSignins: action.payload
      };

    // AzureADCriticalRoleChanges
    case fromStaticIndicator.LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES:
      return {
        ...state,
        dataAzureADCriticalRoleChanges: null,
        loadingAzureADCriticalRoleChanges: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_AZURE_AD_CRITICAL_ROLE_CHANGES:
      return {
        ...state,
        trendColorAzureADCriticalRoleChanges: action.payload
      };

    case fromStaticIndicator.LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_SUCCESS:
      return {
        ...state,
        dataAzureADCriticalRoleChanges: action.payload,
        loadingAzureADCriticalRoleChanges: false,
        errorAzureADCriticalRoleChanges: false
      };

    case fromStaticIndicator.LOAD_AZURE_AD_CRITICAL_ROLE_CHANGES_FAIL:
      return {
        ...state,
        dataAzureADCriticalRoleChanges: null,
        loadingAzureADCriticalRoleChanges: false,
        errorAzureADCriticalRoleChanges: action.payload
      };

    // AzureADFailedSignins
    case fromStaticIndicator.LOAD_AZURE_AD_FAILED_SIGNINS:
      return {
        ...state,
        dataAzureADFailedSignins: null,
        loadingAzureADFailedSignins: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_AZURE_AD_FAILED_SIGNINS:
      return {
        ...state,
        trendColorAzureADFailedSignins: action.payload
      };

    case fromStaticIndicator.LOAD_AZURE_AD_FAILED_SIGNINS_SUCCESS:
      return {
        ...state,
        dataAzureADFailedSignins: action.payload,
        loadingAzureADFailedSignins: false,
        errorAzureADFailedSignins: false
      };

    case fromStaticIndicator.LOAD_AZURE_AD_FAILED_SIGNINS_FAIL:
      return {
        ...state,
        dataAzureADFailedSignins: null,
        loadingAzureADFailedSignins: false,
        errorAzureADFailedSignins: action.payload
      };

    // AzureADRiskEvents
    case fromStaticIndicator.LOAD_AZURE_AD_RISK_EVENTS:
      return {
        ...state,
        dataAzureADRiskEvents: null,
        loadingAzureADRiskEvents: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_AZURE_AD_RISK_EVENTS:
      return {
        ...state,
        trendColorAzureADRiskEvents: action.payload
      };

    case fromStaticIndicator.LOAD_AZURE_AD_RISK_EVENTS_SUCCESS:
      return {
        ...state,
        dataAzureADRiskEvents: action.payload,
        loadingAzureADRiskEvents: false,
        errorAzureADRiskEvents: false
      };

    case fromStaticIndicator.LOAD_AZURE_AD_RISK_EVENTS_FAIL:
      return {
        ...state,
        dataAzureADRiskEvents: null,
        loadingAzureADRiskEvents: false,
        errorAzureADRiskEvents: action.payload
      };

    // CloudOnlyAzureADUsersCreated
    case fromStaticIndicator.LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED:
      return {
        ...state,
        dataCloudOnlyAzureADUsersCreated: null,
        loadingCloudOnlyAzureADUsersCreated: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_CLOUD_ONLY_AZURE_AD_USERS_CREATED:
      return {
        ...state,
        trendColorCloudOnlyAzureADUsersCreated: action.payload
      };

    case fromStaticIndicator.LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_SUCCESS:
      return {
        ...state,
        dataCloudOnlyAzureADUsersCreated: action.payload,
        loadingCloudOnlyAzureADUsersCreated: false,
        errorCloudOnlyAzureADUsersCreated: false
      };

    case fromStaticIndicator.LOAD_CLOUD_ONLY_AZURE_AD_USERS_CREATED_FAIL:
      return {
        ...state,
        dataCloudOnlyAzureADUsersCreated: null,
        loadingCloudOnlyAzureADUsersCreated: false,
        errorCloudOnlyAzureADUsersCreated: action.payload
      };

    // O365ExternalUserActions
    case fromStaticIndicator.LOAD_O365_EXTERNAL_USER_ACTIONS:
      return {
        ...state,
        dataO365ExternalUserActions: null,
        loadingO365ExternalUserActions: true
      };

    case fromStaticIndicator.UPDATE_TREND_COLOR_O365_EXTERNAL_USER_ACTIONS:
      return {
        ...state,
        trendColorO365ExternalUserActions: action.payload
      };

    case fromStaticIndicator.LOAD_O365_EXTERNAL_USER_ACTIONS_SUCCESS:
      return {
        ...state,
        dataO365ExternalUserActions: action.payload,
        loadingO365ExternalUserActions: false,
        errorO365ExternalUserActions: false
      };

    case fromStaticIndicator.LOAD_O365_EXTERNAL_USER_ACTIONS_FAIL:
      return {
        ...state,
        dataO365ExternalUserActions: null,
        loadingO365ExternalUserActions: false,
        errorO365ExternalUserActions: action.payload
      };

    default:
      return state;
  }
}
