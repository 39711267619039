/**
 * Route guard to help users avoid losing unsaved search changes
 */
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AppFacadeService } from '@ondemand/core';
import { LocaleStringsService } from '../services/locale-strings.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UnsavedChangesService } from '../services/unsaved-changes.service';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<any> {
  constructor(
    private facade: AppFacadeService,
    private localeStrings: LocaleStringsService,
    private unsavedChangesService: UnsavedChangesService
    ) { }

  async canDeactivate(_component: any, _route: ActivatedRouteSnapshot,
                      _state: RouterStateSnapshot, _nextState: RouterStateSnapshot
                     ): Promise<boolean> {
    // Always allow navigation within the bounds of the search editing workflow
    return new Promise<boolean>(async (resolve) => {
      if (this.unsavedChangesService.isChanged()) {
        this.facade.confirm(
          await this.localeStrings.string$('auditing.discardUnsavedChangesTitleGeneric').pipe(
            take(1)).toPromise(),
          await this.localeStrings.string$('auditing.discardUnsavedChangesMessageGeneric').pipe(
            take(1)).toPromise()
        ).then((selection) => {
          if (selection === true) {
            this.unsavedChangesService.clear();
          }
          resolve(selection);
        }, () => {
          resolve(true);
        });
      } else {
        this.unsavedChangesService.clear();
        resolve(true);
      }

    });
  }

}
