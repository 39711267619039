/* eslint-disable max-len */

export const AuditingDisplayStringsProvider: any = {
  auditing: {
    duplicateNameError: 'An alert plan name already exists with this name.',
    errorLoadingVisualization: 'Error Loading Visualization',
    provisions: {
      continueLabel: 'CONTINUE',
      tryAgainLabel: 'TRY AGAIN',
      gettingStarted: 'Getting Started',
      LandingPageODAMessage:
        'If you plan to audit critical on-premises activity, the integration must be configured through Change Auditor.',
      continueGetStarted: 'Click continue to get started with Audit',
      errorLabel: 'Uh Oh! Something Went Wrong.',
      retryLabel:
        'There was an issue while attempting to provision your organization.',
      retryBoldLabel: 'Try again or contact your On Demand administrator.',
      provisioningSpinnerDescription:
        'We are preparing the product for you. Please wait...',
      LandingPageCAMessage:
        'To audit Azure Active Directory and Office 365 activity through On Demand, the tenant must be configured through the On Demand Audit web portal.'
    },
    auditingCategory: 'Auditing',
    auditingModuleName: 'Audit',
    dashboard: 'Dashboard',
    queries: 'Searches',
    querySingular: 'Search',
    results: 'Results',
    editor: 'Editor',
    editorBreadcrumb: 'Edit',
    configuration: 'Configuration',
    testUi: 'Feature Flags',
    alerts: 'Alerts',
    visualizations: 'Visualizations',
    duplicateSavedQueryError: 'Could not create a duplicate of this search.',
    deleteSavedQueryError: 'Failed to delete search.',
    saveQueryError: 'Failed to save search.',
    noSearchResults: 'No events were found.',
    noResultsFoundMessage: 'No results found',
    altImageUrlLabel: 'No Search Results',
    editQueryLabel: 'Modify',
    deleteQueryLabel: 'Delete',
    refreshQueryLabel: 'Refresh',
    visualizeQueryLabel: 'Visualize',
    totalEventCountLabel: 'Total Events',
    queryFailedError: 'Failed to load search results.',
    altMagnifyingGlass: 'magnifying glass',
    noResultFound: 'No events found',
    adjustYourFilter:
      'Ensure your search filters are specified correctly to find what you are looking for.',
    loadingSearchResults: 'Loading results…',
    loadingSearchList: 'Loading searches…',
    noConsentGranted: 'Need to grant admin consent',
    consentGranted: 'Admin consent granted.',
    auditAllServices: 'Audit all services',
    saving: 'Saving…',
    loadingTenantConfig: 'Loading tenant configuration…',
    configLoadError: `Error: unable to load this tenant's configuration.`,
    failedSaveError: 'There was an error saving this configuration.',
    successfulSave: 'Saved configuration.',
    gccText: 'GCC',
    gccTenantErrorBannerMessage: 'GCC tenants can only be added to organizations in US region.',
    gccNonUSRegionErrorMessage: 'GCC/GCC High tenants can only be added to Organizations in US region.',
    saveMenuLabel: 'Save…',
    saveAsButtonLabel: 'Save As…',
    saveButtonLabel: 'Save',
    cancelButtonLabel: 'Cancel',
    backButtonLabel: 'Back',
    runButtonLabel: 'Run',
    lastSavedLabel: 'Last saved {lastUpdatedTime} {by} {lastUpdatedBy}',
    lastSavedTooltip:
      'Created: {createdTime} by {createdBy} <br> Last saved: {lastUpdatedTime} {by} {lastUpdatedBy}',
    errorConsentUrl: 'Error retrieving the consent url for tenant',
    errorConsentStatus: 'Error checking consent status',
    pendingConsent: 'Consent pending. Click to retry',
    errorLoadingConsentLink: 'Failed to get consent link.',
    errorLoadingParams: 'Error loading tenant route parameters',
    errorRegisteringConsent:
      'Failed to get register consent for auditing standard',
    pages: {
      auditingDashboard: {
        title: 'Dashboard',
        error: 'Error',
        tryAgain: 'Try Again',
        loadDashboardError: 'Error loading dashboard',
        favoriteSearches: {
          mySearches: 'My Favorite Searches',
          events: 'EVENTS',
          editSearches: 'Edit Searches',
          view: 'VIEW',
          editor: {
            title: 'Edit Searches',
            description: 'Add up to 5 searches to be displayed',
            cancelButtonText: 'Cancel',
            okButtonText: 'OK',
            addButtonText: 'Add search',
            grabberTooltip: 'Drag to re-order the items',
            removeButtonTooltip: 'Remove the entry',
            updateErrorMessage: 'Error: unable to update the favorite searches',
            searchDropDown: {
              selectSearchPrompt: '-- Please select a search --',
              noSearchesAvailablePrompt: '-- No avaliable searches --'
            }
          }
        },
        signins: {
          title: 'Sign-ins',
          timeRangeDisplay: 'Last 7 days',
          dropdownText: 'SELECTED SIGN-IN TYPES',
          dropdownSelectAllHeader: 'All Sign-in types',
          dropdownSelectAllDescription: 'Select all sign-in types',
          colorSuccess: '#00cc00',
          colorFailed: '#cc0000'
        },
        auditHealth: {
          title: 'Audit Health',
          noIssuesTitle: 'No Issues Found',
          noIssuesSubtitle:
            'There are no issues that require your attention at this time.',
          tooltipMessage:
            'Audit Health surfaces configuration related concerns which may impact your ability to audit your environment.',
          resetHiddenItems: 'Reset hidden items',
          grantConsent: 'GRANT CONSENT',
          dismiss: 'DISMISS',
          hide: 'HIDE',
          viewSubscriptions: 'VIEW SUBSCRIPTIONS',
          viewResults: 'VIEW RESULTS',
          viewConfiguration: 'VIEW CONFIG',
          viewPlan: 'VIEW PLAN',
          learnMore: 'LEARN MORE',
          toLearnMore: ' to learn more.'
        },
        criticalActivity: {
          htmlTitle: 'Critical Activity',
          moreResultsDescription: 'MORE RESULTS AVAILABLE',
          events: 'EVENTS',
          nodata: 'No Critical Activity',
          nodatadetail:
            'There is no critical activity that requires your attention at this time.',
          nodatahint: `
          Critical Activity surfaces security concerns that are discovered by
          our best practice security detection as well as anomalous user
          activity which may require further investigation.`,
          learnmore: ' to learn more.',
          activityRowActions: {
            viewActionLabel: 'VIEW',
            detailsActionLabel: 'DETAILS'
          }
        },
        topActiveUsers: {
          title: 'Top Active Users',
          timeRangeDisplay: 'Last 24 hours',
          dropdownText: 'SELECTED SERVICES',
          dropdownSelectAllHeader: 'All services',
          dropdownSelectAllDescription: 'Select all services',
          editBtnText: 'Edit Excluded Users',
          editor: {
            loadingLabel: 'Loading...',
            closeButtonLabel: 'Close',
            noUsersMessage: 'No users to display',
            topActiveUsersSelector: {
              title: 'Exclude Users',
              description:
                'The Top Active Users tile in the dashboard displays the top 5 accounts within the entire organization. If required, you can exclude up to 100 accounts from these results.',
              columnHeader: {
                userLabel: 'User Name',
                eventCountLabel: 'Events'
              },
              addButtonLabel: 'Add Selected Users',
              excludeUserCountLimitMessage:
                'You have reached the maximum number of excluded user accounts'
            },
            excludedUsersSelector: {
              title: 'Excluded Users',
              columnHeader: {
                userLabel: 'User Name',
                actionLabel: 'Action'
              },
              removeButtonLabel: 'Remove Selected Users'
            }
          }
        }
      },
      criticalActivity: {
        flyout: {
          viewAllEventsButtonLabel: 'VIEW ALL EVENTS',
          dismissActivityButtonLabel: 'DISMISS ACTIVITY',
          errorLoadingLabel: 'Unable to load the data',
          errorLoadingActionLabel: 'Try Again',
          loadingVisualizationMessage: 'Loading visualization...'
        },
        dissmissalModal: {
          title: 'Dismiss Critical Activity',
          explanation:
            'Dismissing this critical activity will remove the reported results until the next activity is detected.',
          areYouSure:
            'Are you sure you want to dismiss this critical activity?',
          actionExplanation:
            'Hide all future occurences of this critical activity.',
          cancel: 'Cancel',
          confirm: 'OK'
        },
        title: 'Critical Activity',
        error: 'Error',
        tryAgain: 'Try Again',
        noDataTemplate: {
          message:
            'There is no critical activity that requires your attention at this time.'
        },
        noFilterResultsTemplate: {
          title: 'No Results Found',
          message:
            'Ensure your search filters are specified correctly to find what you are looking for.'
        },
        hiddenItemsEditor: {
          title: 'Hidden Items',
          description:
            'Remove items from the list below to surface future occurrences of the critical activity.',
          hiddenList: {
            listNamePrefix: 'Hidden Items',
            header: {
              name: 'Critical Activity',
              valueLabel: 'Events'
            },
            removeButtonLabelPrefix: 'Remove Selected Items',
            cancelButtonLabel: 'Cancel',
            saveButtonLabel: 'Save',
            noItemsMessage: 'No items to display'
          }
        }
      },
      savedSearches: {
        title: 'Saved searches',
        header: 'Search for events',
        newSearch: 'New Search',
        tableNameColumnHeader: 'Name',
        categoryNameColumnHeader: 'Category',
        categoriesListHeader: 'Categories',
        categoriesListAddAction: '+ Add',
        noSearches: 'This category does not contain any searches.',
        deleteSearchHeader: 'Delete Search',
        deleteSearchWarning:
          'Are you sure you want to delete this search, "{name}"?',
        deleteSearchError: 'Failed to delete search. Try again.',
        addCategoryPrompt: 'Enter a category name',
        submitAddCategoryButton: 'Add',
        submitAddCategoryButtonInProgress: 'Adding…',
        cancelAddCategoryButton: 'Cancel',
        addCategoryDuplicateError: 'A category already exists with this name.',
        categoryInvalidName: 'The category name contains invalid characters.',
        addCategoryHeader: 'Add Category',
        tooManyCategoriesError:
          'You have reached the allowed limit of search categories. Review your current categories to see if any can be removed or if an existing category can be used.',
        userDefinedSearch: 'User-defined search',
        sharedSearchCategory: 'Shared category',
        userDefinedCategory: 'User-defined category',
        privateSearchCategory: 'Private category',
        privateSearch: 'Private search',
        sharedSearch: 'Shared search',
        allSearchesCategoryName: 'All Searches',
        allPrivateSearchesCategoryName: 'All Private Searches',
        editCategory: 'Edit Category',
        editCategoryPrompt: 'Name',
        submitEditCategoryButton: 'Save',
        submitEditCategoryButtonInProgress: 'Saving…',
        editCategoryError: 'There was an error saving this category.',
        deleteCategoryButton: 'Delete',
        deleteInProgress: 'Deleting…',
        deleteConfirmation: 'Are you sure you want to delete this category?',
        deleteConfirmButton: 'Delete',
        deletedFailed:
          'Could not delete this category. This category may still be in use by other users in the organization.',
        cannotDelete:
          'A category that contains searches cannot be deleted. Remove all of the searches from the category and try again.',
        cancelDelete: 'Cancel',
        deleteSuccessful: 'Search deleted.',
        copyQuery: 'Copy',
        deleteCategorySuccessful: 'Category deleted.',
        addCategorySuccessful: 'Category added.',
        updateCategorySuccessful: 'Category updated.',
        copyQueryModalTitle: 'Copy search',
        copySuccessToast: 'Copied search.',
        copyButton: 'Copy',
        cancelCopyButton: 'Cancel',
        copyCategoryPrompt: 'Category',
        copyNamePrompt: 'Name',
        copySearchTemplate: '{searchName} - Copy',
        getSearchesError: 'Error loading the saved searches.'
      },
      newSearches: {
        fieldsLoading: 'Loading…',
        createSearch: 'Create search',
        modifySearch: 'Modify search',
        searchSaved: 'Search saved',
        errorInvalidCategory: 'Select a valid category.',
        errorDuplicateName: 'A search already exists with this name.',
        errorNameTooLong:
          'The search name is too long. Reduce it to 250 characters.',
        errorEmptyName: 'The search name cannot be empty.',
        errorLoadCategories:
          'Could not load the categories. An error occurred.',
        errorEmptyFieldValue: 'Choose field value or remove empty column',
        addClause: 'Add',
        categoryLabel: 'Category',
        chooseCategory: 'Choose a category',
        queryPreview: 'Search preview',
        on: 'On',
        off: 'Off',
        emptyColumnPlaceholder: 'Type or choose column name',
        flyoutHeader: 'Edit Layout',
        flyoutDescription:
          'Customize the columns to include in your search results and set how you want the results displayed.',
        flyoutColumnSubHeader: 'Column List',
        flyoutColumnLabel: 'Select the columns to include in search results:',
        flyoutColumnTabLabel: 'Columns',
        flyoutVisualizationTabLabel: 'Visualize',
        flyoutVisualizationChartPreview: 'Chart Preview',
        flyoutVisualizationChartPreviewDescription:
          'The selected chart settings result in the following visualization.',
        flyoutVisualizationSummarizeBy: 'Summarize By',
        flyoutVisualizationGroupBy: 'Group By',
        flyoutVisualizationChartType: 'Chart Type',
        flyoutVisualizationChartSettings: 'Chart Settings',
        flyoutVisualizationChartSettingsDescription:
          'Select the required chart configuration settings.',
        flyoutVisualizationVisualizeAs: 'Visualize As',
        flyoutVisualizationVisualization: 'Visualization Settings',
        flyoutVisualizationVisualizationSettingsDescription:
          'Select how you want to display the search results.',
        flyoutChartTypeRequiredLabel: 'The Chart Type field is required.',
        flyoutGroupByRequiredLabel: 'The Group By field is required.',
        flyoutSummarizeByRequiredLabel: 'The Summarize By field is required.',
        flyoutVisualizeAsRequiredLabel: 'The Visualize As field is required.',
        flyoutGroupByColumnDeletedLabel:
          'A column used by the visualization has been removed. Update your visualization.',
        applyColumnChangesButton: 'Preview',
        submitSaveColumns: 'OK',
        cancelEditColumnsButton: 'Cancel',
        editColumnsButton: 'Edit Layout',
        editColumnsHeader: 'Edit Layout',
        removeColumn: 'Remove column',
        addColumnButton: 'Add Column',
        reorder: 'Drag to re-order columns',
        field: 'Field',
        operator: 'Operator',
        value: 'Value',
        date: 'Date',
        hour: 'Hour',
        minute: 'Minute',
        and: 'and',
        hours: 'HH',
        minutes: 'MM',
        badDatetimeError: 'Date or time is invalid.',
        badIntervalError: 'This must be a whole number and greater than zero.',
        searchName: 'Search name',
        searchCategory: 'Search category',
        searchOperators: {
          equals: 'equals',
          equals_number: 'equals',
          does_not_equal: 'does not equal',
          does_not_equal_number: 'does not equal',
          in: 'in',
          not_in: 'not in',
          contains: 'contains',
          does_not_contain: 'does not contain',
          greater_than: 'greater than',
          less_than: 'less than',
          between_number: 'between',
          between_date: 'between',
          before: 'before',
          after: 'after',
          starts_with: 'starts with',
          does_not_start_with: 'does not start with',
          ends_with: 'ends with',
          does_not_end_with: 'does not end with',
          during_last: 'during last',
          greater_than_or_equals: 'greater than or equals',
          less_than_or_equals: 'less than or equals'
        },
        unitHours: 'hours',
        unitDays: 'days',
        removeClauseTooltip: 'Remove filter',
        addClauseTooltip: 'Add filter',
        invalidColumnMessage:
          'The column "{field}" cannot be displayed. You may remove this column or choose another option.',
        unsearchableColumnMessage:
          'The column "{field}" cannot be searched. You may remove this column or choose another option.',
        unknownColumnMessage:
          'Unknown column selected. Select a different column.',
        saveAsSearchPrompt: 'Name',
        saveAsSearchCategoryPrompt: 'Category',
        cancelSaveAsButton: 'Cancel',
        submitSaveAsButton: 'Save',
        saveAsModalTitle: 'Save as search',
        saveModalTitle: 'Save search',
        saveSearchNamePrompt: 'Name',
        saveSearchCategoryPrompt: 'Category',
        saveSuccessfulToast: 'Search saved.',
        saveAsSuccessfulToast: 'Search saved.',
        chipPlaceholder: '+ Value',
        chipSecondaryPlaceholder: 'Value',
        chipsEmptyError: 'Enter at least one value.',
        availableValuesRetrievalError: 'Could not load search options.',
        booleanTrue: 'True',
        booleanFalse: 'False',
        minimum: 'Min.',
        maximum: 'Max.',
        invalidPredefinedValuesError:
          'The following are not valid options and will be removed:',
        multiValuePlaceholder: 'Select option(s)',
        predefinedPlaceholder: 'Select an option',
        invalidCharactersError: 'The search value contains invalid characters.',
        valueCannotBeEmpty: 'Field cannot be empty.',
        nameContainsInvalidCharacters:
          'The search name contains invalid characters.',
        quickSearchTooManyTermsError:
          'You have exceeded the limit of search terms. Remove some terms and try again.',
        quickSearchTermTooLongError:
          'One of your search terms exceeds the allowed length limit. Adjust the search terms and try again.'
      }
    },
    datepicker: {
      today: 'Today',
      clear: 'Clear',
      close: 'Ok'
    },
    runSearch: 'Run Search',
    queryBuilderFieldError: 'Select a field.',
    queryBuilderOperatorError: 'Select an operator.',
    queryBuilderValueError: 'Enter a value.',
    queryBuilderQueryLoadError: 'There was an error loading this saved search.',
    queryBuilderQueryLoadError2: 'Click here to return to saved searches.',
    loading: 'Loading…',
    queryBuilderFieldsLoadError:
      'Failed to load list of event fields. Try again later.',
    queryBuilderDescription: 'Search Filters',
    queryLoadError: 'Failed to load search.',
    queryAccessDenied: 'You do not have access to this search.',
    querySearchType: 'Search Type',
    querySearchCategory: 'Search Category',
    privateType: 'Private',
    sharedType: 'Shared',
    labelType: 'Type',
    visualizationAccessDenied: 'You do not have access to this visualization.',
    queryZeroResults: 'No results were found matching this search.',
    noQueryFoundError:
      'Error: Could not find a search to run. Select a new search and try again.',
    reportCheckingStatus: 'Checking status…',
    reportLoadingNoEstimate: 'Generating {reportType}. Preparing estimate.',
    reportVisualization: 'visualization',
    reportMap: 'Sign-ins',
    reportOverview: 'Overview',
    reportDashboard: 'dashboard',
    reportLoading: 'Your {reportType} will be ready {timeString}.',
    reportError: 'Error loading {reportType}.',
    reportDownloading: 'Rendering {reportType}…',
    reportChangePageExplanation:
      'While your {reportType} is loading, you can safely leave this page and return when it is ready.',
    reportLastUpdated: 'Last updated ',
    loadingEventDetails: 'Loading event details…',
    eventLoadError: 'Could not load details for this event.',
    showEmptyFields: 'Display empty fields',
    nextEvent: 'View next event',
    prevEvent: 'View previous event',
    viewingEvent: 'Viewing event',
    of: 'of',
    filter: 'Filter',
    visualizationRowLimitTitle: 'Event limit reached',
    visualizationRowLimitDescription:
      'You have reached the limit of events that can be visualized. The limit of events is {limit} and you have {totalRows}. Do you still want to visualize this search?',
    visualizationBinsLimitDescription: 'Only showing {limit} out of {totalRows} points from your search. Try choosing another Summarize By option or refining the search criteria to visualize more of the search.',
    rowLimitWarning:
      'Visualizations are limited to {totalRows} events. To provide the best possible experience, consider narrowing your search.',
    rowLimitWarningDashboard:
      'Visualizations are limited to {totalRows} events. A new, optimized version of this dashboard will be automatically generated for you soon.',
    yes: 'Yes',
    no: 'No',
    quickSearchPlaceholder: 'Quick search',
    unsearchableFieldError:
      'The field "{field}" is not searchable. Please select another field.',
    quickSearchBreadcrumb: 'Quick Search',
    quickSearchTitle: 'Quick Search',
    services: {
      exchangeAdmin: 'Exchange Online - Administrative Activity',
      exchangeMailbox: 'Exchange Online - Mailbox Activity',
      sharepointOnline: 'SharePoint Online',
      onedriveBusiness: 'OneDrive',
      teams: 'Teams',
      azureAD: 'Azure Active Directory - Audit Logs',
      azureSignIns: 'Azure Active Directory - Sign-ins'
    },
    addFilterToQuery: 'Add filter on this value',
    newSearchOnValue: 'New search on this value',
    filterAddedNotification: 'Added filter on "{field}" field.',
    copyToClipboard: 'Copy to clipboard',
    copiedToClipboard: 'Text copied to clipboard.',
    moreActionsMenuTitle: 'More options',
    discardUnsavedChangesTitle: 'Unsaved changes',
    discardUnsavedSearchChanges:
      'You have unsaved changes for this search. Are you sure you want to leave this page?',
    addChangeAuditorCardText: 'Learn more about adding hybrid auditing with Change Auditor.',
    addChangeAuditorModalTitle: 'Add Change Auditor Installation',
    addChangeAuditorLearnMore: 'Learn more',
    addChangeAuditorButton: 'ADD CHANGE AUDITOR',
    addChangeAuditorInstructions: `
    <strong>Minimum requirements:</strong>
    <ol>
      <li>Change Auditor 7.0.3 (<a href="https://support.quest.com/change-auditor/latest/download-new-releases" target="_blank">download</a>)</li>
      <li>You must have the Administrator role in Change Auditor.</li>
      <li>You must have the <strong>Can Manage Change Auditor Installation Configuration</strong> permission in On Demand.</li>
    </ol>

    <strong>Setup Instructions</strong>
    <ol>
      <li>Open the Change Auditor client.</li>
      <li>From the <strong>Administration Tasks</strong> tab, select <strong>Configuration | On Demand Audit</strong>.</li>
      <li>Select <strong>Sign in and Configure</strong> to create the connection.</li>
      <li>Enter your Quest account credentials to sign in to On Demand Audit.</li>
      <li>Choose the required organization if prompted and click <strong>Select Organization</strong></li>
      <li>By default, the current installation is used for the configuration name. If required, you can enter a different name for the configuration. This is the configuration name used in On Demand Audit. It does not affect the Change Auditor installation name.</li>
      <li>Click <strong>Finish</strong>.</li>
    </ol>
    `,
    okay: 'OK',
    setupChangeAuditorDescription:
      'Enter a name to identify your Change Auditor installation:',
    setupChangeAuditorInstallationName: 'Installation name',
    setupChangeAuditorDesktopReadError:
      'There was an error loading information about your Change Auditor installation.',
    setupChangeAuditorServiceError:
      'There was an error registering your installation with On Demand.',
    changeAuditorDuplicateInstallationError:
      'This Change Auditor installation has already been registered with another organization. It cannot be registered with more than one organization.',
    coordinator: 'Coordinator',
    next: 'Next',
    changeAuditorProductName: 'Change Auditor',
    installationName: 'Installation name',
    coordinatorHostname: 'Coordinator hostname',
    moduleName: 'Quest On Demand Audit',
    submittingToCoordinator: 'Registering with On Demand Audit. Please wait.',
    registeredWithCoordinator:
      'Successfully registered Change Auditor installation with On Demand Audit.',
    failedCoordinatorRegistration:
      'Failed to register Change Auditor installation with On Demand Audit.',
    close: 'Close',
    addTenantCardText: 'Add Azure AD Tenant',
    addTenantCardLearnMoreText: 'Learn more about adding an Azure AD Tenant to On Demand Audit.',
    addTenantCardLearnMoreLinkText: 'Learn more',
    addTenantButtonText: 'ADD TENANT',
    auditConsentPromptTitle: 'Permission needed',
    selectServicesPrompt: 'Select services below.',
    addTenantDenied: 'Access to add an Azure AD tenant was denied.',
    addTenantFailed: 'Failed to add tenant.',
    grantAuditConsentDenied: 'Access to Audit functionality was denied.',
    grantAuditConsentFailed: 'Access to Audit functionality was denied.',
    alertButtonLabel: 'Alert',
    alertRuleDisableSuccess: 'Alert disabled',
    alertRuleDisableFailed: 'There was an error disabling the alert.',
    alertRuleSaveSuccess: 'Alert saved',
    alertRuleSaveFailed: 'There was an error saving the alert.',
    alertIconTitle: 'Alert',
    alertRuleCreationConfirmationTitle: 'Enable Alert',
    alertRuleCreateConfirmation: 'An alert will be created for this search.',
    alertRuleEnableConfirmation: 'This alert will be enabled.',
    alertTabTitle: 'Alerts',
    alertRuleListNavTitle: 'Alerts',
    alertPlanListNavTitle: 'Alert Plans',
    alertPlanEditorIntro:
      'The following steps will be completed when this alert is triggered:',
    alertActionEmailTitle: 'Email',
    alertActionAddStepButton: 'Add',
    alertPlanPrivate: 'Private',
    alertPlanSave: 'Save',
    alertPlanShared: 'Shared',
    alertRuleSearchNameHeader: 'Alert Name',
    alertRuleTypeHeader: 'Alert Type',
    alertRulesTimesRunHeader: '# of Alerts Last 24h',
    alertRulesNumPlansHeader: '# of Alert Plans',
    alertPlanNameHeader: 'Alert Plan Name',
    alertPlanTypeHeader: 'Alert Plan Type',
    alertPlanNumRulesHeader: '# of Alerts',
    alertActionTypeEmail: 'Email',
    alertActionEmailValuePlaceholder: 'Email address',
    confirmDeleteAlertRuleTitle: 'Delete Alert',
    confirmDeleteAlertRule:
      'Are you sure you want to delete this alert, "{name}"?',
    errorDeletingAlertRule: 'Failed to delete alert.',
    deletedAlertRuleSuccess: 'Deleted alert.',
    noAlertRules: `
      <p>No alerts have been created yet. To set up an alert:</p>
      <ol>
        <li>Run a search.</li>
        <li>Click the <strong>"Alert"</strong> button.</li>
        <li>Follow the prompt that appears.</li>
      </ol>
    `,
    errorLoadingAlertRules: 'There was an error loading your alerts.',
    deleteAlertRuleTooltip: 'Delete',
    confirmDeleteAlertPlanTitle: 'Delete Alert Plan',
    confirmDeleteAlertPlan:
      'Are you sure you want to delete this alert plan, "{name}"?',
    alertPlanHasLinkedRules:
      'The alert plan is in use and cannot be deleted. Remove all associated alerts from "{name}" and try again.',
    errorDeletingAlertPlan: 'Failed to delete alert plan.',
    errorDeletingAlertPlanWithLinkedRules: 'Failed to delete plan.',
    deletedAlertPlanSuccess: 'Deleted alert plan.',
    noAlertPlans: 'No alert plans have been created yet.',
    errorLoadingAlertPlans: 'There was an error loading your alert plans.',
    planNotFound: 'Could not find the alert plan specified.',
    errorLoadingAlertPlan: 'Error loading alert plan.',
    errorLoadingAlertPlanNoPermission: 'You do not have the required access to edit this alert plan. Contact your On Demand administrator for information on the required permissions to manager shared and private alert plans.',
    alertPlanName: 'Plan name',
    savedPlanSuccess: 'Saved plan.',
    emailAddressTooLong:
      'Email addresses must be no longer than 254 characters.',
    tooManyActions: 'The total number of email addresses must be 99 or fewer.',
    savePlanFailed: 'Failed to save plan.',
    alertPlanAlreadyExists: 'A plan already exists with this name',
    alertPlanNameTooLong: 'Plan names must be no longer than 250 characters.',
    newPlanButton: 'New Plan',
    newPlanName: 'New Alert Plan',
    testAlertPlan: 'Send Test',
    testAlertPlanPromptTitle: 'Test Alert Plan',
    testAlertPlanPromptMessage:
      '<p>This will allow you to verify the alert plan is working by sending a test version of this alert to the recipients specified in the plan.</p><br><p>This will send a test message for each action in this alert plan.</p><br><p>Are you sure you want to run these {numActions} action(s)?</p>',
    testAlertPlanSuccess: 'Test messages will be sent.',
    testAlertPlanError: 'There was an error testing the alert plan.',
    discardUnsavedChangesTitleGeneric: 'Unsaved changes',
    discardUnsavedChangesMessageGeneric:
      'You have unsaved changes. Are you sure you want to leave this page?',
    alertRuleDisableConfirmation: 'Do you want to disable this alert?',
    alertRuleDisableConfirmationTitle: 'Disable Alert',
    changeAuditorConnectionOkay: 'Connected and receiving events.',
    changeAuditorDisconnected:
      'Disconnected. If this persists, <a href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/2#TOPIC-907317" target="_blank">click here</a> for troubleshooting information.',
    changeAuditorPaused: 'Paused.',
    changeAuditorUpgradeAvailable:
      'A Change Auditor upgrade is available. <a href="{url}" target="_blank">Click here</a> to see new releases.',
    changeAuditorCriticalUpgradeAvailable:
      'A Change Auditor upgrade is required. <a href="{url}" target="_blank">Click here</a> to learn more.',
    changeAuditorVersion: 'Change Auditor version',
    changeAuditorLastUpdateReceived: 'Last events received',
    changeAuditorAwaitingEvents: 'Event data sync pending',
    coordinatorTitlePlural: 'Coordinators connected',
    statusTitle: 'Status',
    unsavedSearchAlertInfo:
      'This search must be saved before alerts can be enabled',
    updatingCAInstallationStatus: 'Updating status',
    singleAlertPlanLink: 'Alert Plan',
    alertRuleEnabled: 'Enabled',
    alertRuleEnabledSuccess: 'Alert saved.',
    alertRuleDisabledSuccess: 'Alert disabled.',
    alertRuleFailedUpdate: 'Error updating alert.',
    alertDisabledWarning: 'Warning: This alert is currently disabled.',
    alertEnabledSearchDeletionWarning:
      '<br><br><div>This search has associated alerts. Deleting the search will also delete the alerts.</div>',
    alertEnableSuccessDescription1: 'Your alert has been successfully saved.',
    alertEnableSuccessDescription2:
      'This will let you choose what to do when this alert is triggered.',
    alertPlanEditLinkText: 'Click here to edit the alert plan.',
    alertPlanEditNewLinkText:
      'Click here to edit your newly created alert plan.',
    alertPlanMultipleEditLinkText: 'Click here to view your alert plans.',
    deleteAlertPlanTooltip: 'Delete',
    caInstallationMenu: 'Settings',
    caInstallationRemove: 'Remove Installation',
    caInstallationRemoveConfirmTitle: 'Remove installation',
    caInstallationRemoveConfirmMessage:
      'Are you sure you want to remove this Change Auditor installation?<p>This will cause events to stop being forwarded from Change Auditor.</p>',
    caInstallationRemoveSuccess: 'Removed installation successfully.',
    caInstallationRemoveError: 'Error removing installation.',
    caInstallationUnreachableErrorTitle: 'Error Removing Installation',
    caInstallationUnreachableError:
      'We were unable to contact the coordinators associated with this Change Auditor installation.<p>Do you want to remove this installation anyway?</p>',
    caInstallationResume: 'Resume',
    caInstallationPause: 'Pause',
    caInstallationPauseConfirmTitle: 'Pause Sending Events',
    caInstallationPauseConfirmMessage:
      '<p>This will cause Change Auditor to temporarily stop sending events to On Demand.</p><p>Are you sure you want to do this?</p>',
    caInstallationResumeConfirmTitle: 'Resume Sending Events',
    caInstallationResumeConfirmMessage:
      '<p>This will cause Change Auditor to resume sending events to On Demand.</p><p>Are you sure you want to do this?</p>',
    caInstallationPauseSuccess: 'Paused.',
    caInstallationPauseError: 'Failed to pause.',
    caInstallationResumeSuccess: 'Resumed.',
    caInstallationResumeError: 'Failed to resume.',
    caDeleting: 'Removing…',
    caPausing: 'Updating…',
    caResuming: 'Resuming…',
    eventDetailsTitle: 'Event Details',
    backToSearchResults: 'Back to search results',
    loadEventError: 'There was an error loading this event.',
    eventNotFound: 'Error: Event not found.',
    eventDetailsSearchResultBreadcrumbTitle: 'Search Results',
    eventDetailsBreadcrumbTitle: 'Event Details',
    eventPermalink: 'Event Link',
    buyChangeAuditorMessage:
      'You do not currently have a subscription to integrate with Change Auditor. Purchasing an appropriate subscription will allow you to easily synchronize your on-premises Change Auditor events with On Demand.',
    buyPromptButtonLabel: 'Buy',
    renewPromptButtonLabel: 'Renew',
    buyO365Message:
      'You do not currently have a subscription to add an Azure AD tenant.  Purchasing an appropriate subscription will allow you to easily synchronize your Azure AD and Office 365 events with On Demand.',
    buyO365ServicesMessage:
      'You do not currently have a subscription to audit this service. Purchasing a subscription will add support for these events in On Demand Audit.',
    renewSubscriptionTitle: 'Renew On Demand Audit subscription',
    buySubscriptionTitle: 'Purchase On Demand Audit subscription',
    cannotResumeExpiredMessage:
      'Your subscription for Change Auditor functionality has expired and you cannot resume event collection. Renew your subscription to resume event collection. If you do not renew your subscription within {daysToDeletion} day(s), your data will be permanently deleted and cannot be recovered.',
    subscriptionExpiredMessage:
      'Your subscription has expired and you can no longer access this feature. Your configuration settings have been preserved and auditing will continue for the next {daysRemaining} days, although some module capabilities will be limited.  If you do not renew your subscription within {daysToDeletion} day(s), your data will be permanently deleted and cannot be recovered.',
    subscriptionOffboardingMessage:
      'Your subscription has expired and you can no longer access this feature.  Your configuration settings have been preserved, although the service is no longer operational. If you do not renew your subscription within {daysToDeletion} day(s), your data will be permanently deleted and cannot be recovered.',
    subscriptionOffboardedMessage:
      'The data associated with your expired subscription has been deleted and cannot be recovered.  Your subscription cannot be renewed.  Contact your Quest sales representative to start a new subscription.',
    notSubscribedCAWarning: `
      <p>You are not yet subscribed to On Demand Audit. <a href="https://www.quest.com/BuyOnDemandAudit" target="_blank">Click here to register.</a></p>
      <p>If someone else in your organization is already subscribed, <a href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/3#TOPIC-1208005" target="_blank">click here for instructions on gaining access</a>.</p>
    `,
    notSubscribedMessage2:
      'When you have finished registering for a subscription, click <strong>Next</strong>.',
    expiredSubscriptionCAWarning: `
      <p>Your subscription to On Demand Audit has expired. <a href="https://www.quest.com/BuyOnDemandAudit" target="_blank">Click here to renew.</a></p>
      <p>If someone else in your organization is already subscribed, <a href="https://support.quest.com/technical-documents/on-demand-audit/current/user-guide/3#TOPIC-1208005" target="_blank">click here for instructions on gaining access</a>.</p>
    `,
    expiredMessage2:
      'When you have finished renewing your subscription, click <strong>Next</strong>.',
    notSubscribedNextButtonLabel: 'Next',
    checkingSubscriptionMessage: 'Checking subscriptions…',
    retentionTabTitle: 'Retention',
    retentionIntro:
      'Some types of events will be stored for a limited period, relative to the retention period for all other events. See the list below for details.',
    retentionTableHeaderService: 'Service',
    retentionTableHeaderEventType: 'Event Type',
    retentionTableHeaderDescription: 'Description',
    retentionTableHeaderRetentionPeriod: 'Retention Period',
    retentionTableHeaderSubscriptionTier: 'Current Subscription',
    retentionTableAccountProvisioningName: 'Account provisioning',
    retentionTableAzureSuccessfulSigninName: 'Successful sign-ins',
    retentionTableOneDriveFileSyncName: 'File Sync',
    retentionTableOneDriveFileAccessName: 'File Access',
    retentionTableSharePointFileAccessName: 'File Access',
    retentionTableSharePointFileSyncName: 'File Sync',
    retentionTableSharePointPageViewName: 'Page View',
    retentionTableChangeAuditorName: 'Change Auditor',
    retentionTableChangeAuditorActiveDirectoryName: 'Active Directory',
    retentionTableLogonActivityName: 'Logon Activity',
    retentionTableChangeAuditorGroupPolicyName: 'Group Policy',
    retentionTableWindowsFileServerName: 'Windows File Server',
    retentionTableTeamsSigninName: 'Session Started',
    azureADName: 'Azure Active Directory',
    onedriveName: 'OneDrive for Business',
    sharepointName: 'SharePoint Online',
    teamsName: 'Teams',
    noEventTypes:
      'You are not currently collecting any events subject to retention periods.',
    retentionTabBreadcrumb: 'Event Retention',
    retentionWarningAlertTitle: 'Limited Retention Period',
    retentionWarningAlert: `
      <p>All audit events collected will be stored for the length of time determined by your subscription. You may use the Retention tab to view further details. Please note that the following events type(s) will always be stored for a reduced period:</p>
      {eventListHTML}
    `,
    retentionInfoError:
      'There was an error loading event retention information.',
    emptyValueFieldError: 'This field cannot be blank.',
    tenantStatusTitle: 'Tenant Status',
    changeAuditorMoreInfo:
      'Learn more about adding hybrid auditing with Change Auditor',
    eventSuccessful: 'Successful',
    eventFailed: 'Failed',
    targetFieldName: 'Target',
    additionalEventSummaryName: 'Additional Event Summary',
    userFieldName: 'User',
    timeDetectedFieldName: 'Time Detected',
    originFieldName: 'Origin',
    statusFieldName: 'Status',
    modifiedPropertyName: 'Property Name',
    tenantStatusMessageLink: 'Tenant Status Messages',
    updatingTenantConfigStatus: 'Checking tenant configuration…',
    viewSearchButton: 'View Search',
    editAlertPlansButton: 'Edit Alert',
    editAlertPlanButton: 'Edit Plan',
    failedToLoadAlertPlansForRule: 'Error loading alert plans.',
    failedToLoadAlertRulesForPlan: 'Error loading alert rules.',
    alertPlanListTitle: 'Alert plans:',
    notificationTemplateListTitle: 'Notification Templates:',
    alertRuleListTitle: 'Alerts:',
    loadingAlertPlans: 'Loading alert plans…',
    failedToLoadAlertPlansInAlertEditor: 'Error loading alert plans.',
    failedToLoadAlertRuleInAlertEditor: 'Error loading alert.',
    failedToSaveAlertRuleInAlertEditor: 'Error saving alert settings.',
    savedAlertRule: 'Saved alert settings.',
    editPrivateAlertRuleTitle: 'Edit Private Alert',
    editSharedAlertRuleTitle: 'Edit Shared Alert',
    selectPrivatePlansDescription: 'Set private alert plans for this alert:',
    selectSharedPlansDescription: 'Set shared alert plans for this alert:',
    noAlertPlansAdded: 'No alert plans have been added yet for this alert.',
    noAlertRulesAdded: 'No alerts have been added to this alert plan.',
    privateAlertRuleType: 'Private',
    sharedAlertRuleType: 'Shared',
    numPlansSelected: 'alert plan(s) selected',
    newPrivateAlertPlanOption: 'Create new private alert plan',
    newSharedAlertPlanOption: 'Create new shared alert plan',
    alertPlanCreateFailed: 'Failed to create new alert plan.',
    tooltipAddAnAlertPlanToRule:
      'You need to add an alert plan to this alert before you can enable it',
    maintenanceWarningTitle: 'Scheduled Maintenance',
    maintenanceWarningMessage:
      'Audit is currently undergoing scheduled maintenance and will be available soon. We apologize for any inconvenience.',
    maintenanceWarningStatusLink: `<a href="https://status.quest-on-demand.com/" target="_blank">Check System Status</a>`,
    trialLimits: {
      title: 'Trial Limits',
      trialExceeded:
        'The limitations of your free trial have been reached, but you can continue to use your trial. ',
      learnMore: 'Learn more ',
      purchaseSubscription: `or <a href="https://quest.com/buyondemandaudit/" target="_blank" rel="noopener" >purchase a subscription.</a>`,
      details:
        'Your free trial is limited to 3 million audit events. No additional events will be ingested, but you can continue to use all other features of the product until your trial expires.',
      purchaseSubscriptionAction: 'Purchase Subscription'
    },
    exports: {
      csv: 'CSV',
      csvAsZIP: 'CSV as ZIP',
      buttonLabel: 'EXPORT',
      startedMessage: 'Export started',
      downloadStartedMessage: 'Export completed, download started',
      errorMessage: 'Export failed',
      rowLimitTitle: 'Too many records for export',
      cancelConfirmationTitle: 'Search result export in progress',
      cancelConfirmationDescription:
        'Search result export in progress. Leaving this page will cancel the export process. Are you sure you want to leave this page?',
      rowLimitDescription:
        'This search exceeds the maximum number of records permitted for export. Click OK to export the first 50,000 records, or Cancel to refine your search criteria.',
      spinnerLabel: 'Preparing export',
      spinnerDescription:
        'We are preparing your search results for export, this may take a few minutes.',
      cancelSpinnerButtonLabel: 'CANCEL',
      exportCancelled: 'Export cancelled',
      okLabel: 'OK',
      cancelLabel: 'Cancel'
    }
  }
};
