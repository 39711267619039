import { Subject, combineLatest, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IBreadcrumb } from '@ondemand/core';

import { AuditingBreadcrumbsService } from '../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../services/locale-strings.service';
import { TrialStatusService } from '../../services/trial-status.service';
import { TrialLimitsModalComponent } from './trial-limits-modal/trial-limits-modal.component';
import { ProvisioningGuard } from '../../route-guards/provisioning.guard';
import { ProvisioningService } from '../../services/organization-provisioning/provisioning.service';
import { EProvisionedState } from '../../../shared/models/provision.model';

@Component({
  selector: 'auditing-wrapper',
  templateUrl: './auditing-wrapper.component.html',
  styleUrls: ['./auditing-wrapper.component.scss']
})
export class AuditingWrapperComponent implements OnInit, OnDestroy {
  breadcrumbs: IBreadcrumb[] = [];
  relativePath: boolean;
  isTrialLimitReached = false;
  bgColor = '#FFF';
  labels: any;
  dashboardTitle: string;
  criticalActivityTitle: string;

  provisioned = false;
  @ViewChild(TrialLimitsModalComponent)
  trialLimitsModal: TrialLimitsModalComponent;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private breadcrumbsService: AuditingBreadcrumbsService,
    private localeStringsService: LocaleStringsService,
    private trialStatusService: TrialStatusService,
    private provisioningGuard: ProvisioningGuard,
    private provisioningService: ProvisioningService
  ) {
  }

  async ngOnInit() {
    // Subscribe to changed navigation allowed status
    // and initialize it with correct value.
    this.provisioningGuard.navigationAllowed$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((currentProvisioningStatus) => {
      this.provisioned = currentProvisioningStatus;
    });
    this.provisioned = (await this.provisioningService.getProvisioningStatus()) ===
    EProvisionedState.Provisioned;
    this.isTrialLimitReached = false;
    let breadcrumb$ = this.breadcrumbsService.breadcrumbs$.pipe(
      takeUntil(this.ngUnsubscribe)
    );
    let strings$ = this.localeStringsService.strings$.pipe(
      takeUntil(this.ngUnsubscribe)
    );
    combineLatest([breadcrumb$, strings$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([update, labels]) => {
        // Hacky way to avoid the `ExpressionChangedAfterItHasBeenCheckedError` error
        Promise.resolve(null).then(() => {
          this.labels = labels;
          this.setBreadcrumbs(update.breadcrumbs, update.relativePath);
          this.setBackGroundColor(update.breadcrumbs);
        });

        this.dashboardTitle = labels.auditing.pages.auditingDashboard.title;
        this.criticalActivityTitle =
          labels.auditing.pages.criticalActivity.title;
      });
    this.trialStatusService
      .getTrialStatus()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        trialStatus =>
          (this.isTrialLimitReached = trialStatus.isTrialLimitReached)
      );
  }

  onClickLearnMore() {
    this.trialLimitsModal.display();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * @param pageCrumbs Breadcrumb pieces
   * @param relativePath Whether the URLs provided are relative to their siblings or absolute
   */
  setBreadcrumbs(pageCrumbs: IBreadcrumb[], relativePath: boolean = false) {
    const defaultCrumbs = [
      {
        title: this.labels.auditing.auditingModuleName,
        url: relativePath ? 'auditing' : 'auditing/auditing'
      }
    ];

    this.breadcrumbs = [...defaultCrumbs, ...pageCrumbs];

    this.relativePath = relativePath;
  }

  setBackGroundColor(pageCrumbs: IBreadcrumb[]) {
    if (
      pageCrumbs.length > 0 &&
      (pageCrumbs[0].title === this.dashboardTitle ||
        pageCrumbs[0].title === this.criticalActivityTitle)
    ) {
      this.bgColor = '#EAEAEA';
    } else {
      this.bgColor = '#FFFFFF';
    }
  }
}
