/**
 * Component for viewing list of alert plans
 */

import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertAction } from '../../../models/alert-action.model';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, Validator } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({

    providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: AlertActionComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AlertActionComponent),
      multi: true
    }],
    selector: 'alert-action',
    templateUrl: './alert-action.component.html',
    styleUrls: ['./alert-action.component.scss']
})
export class AlertActionComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('actionForm') actionForm: NgForm;
  @Input() canEdit = true;

  action: AlertAction;
  onChange: () => void;

  actionTypes: ActionType[] = [];

  constructor(private localeStrings: LocaleStringsService) { }

  ngOnInit() {
    this.setActionTypes();
  }

  /**
   * Load value from parent form into this component instance
   *
   * @param value ngModel input
   */
  writeValue(value: any) {
    if (value) {
      this.action = value;

      // Trigger first onchange to trigger validation
      this.onChange();

      // Hack to trigger validation only after view is rendered
      setTimeout(() => {
        this.onChange();
      }, 500);

      // TODO: Figure out if change event needs to be triggered here
    }
  }

  /**
   * Set up change propagation function
   *
   * @param fn Function to call with changes
   */
  registerOnChange(fn: any) {
    this.onChange = () => {
      fn(this.action);
    };
    // Trigger initial change event
    this.onChange();
  }

  registerOnTouched() {
    return;
  }

  onActionTypeChange() {
    return;
  }

  onEmailValueChange() {
    this.onChange();
  }

  validate() {
    if (!this.actionForm) {
      return {
        error: 'Form not initialized'
      };
    } else if (this.actionForm.valid) {
      return null;
    } else {
      return {
        error: 'Something invalid in the action form'
      };
    }
  }

  private async setActionTypes() {
    this.actionTypes = [
      {
        id: 'email',
        displayName: await this.localeStrings.string$('auditing.alertActionTypeEmail').pipe(take(1)).toPromise()
      }
    ];
  }

}

export interface ActionType {
  id: string;
  displayName: string;
}
