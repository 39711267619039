/**
 * Takes a template string and replaces template values (surrounded by {}) with
 * the values from the values object.
 *
 * @example
 * // returns 'my favorite fruit is avocado'
 * substituteValues('my favorite fruit is {choice}', { choice: 'avocado' });
 */
export const substituteTemplateValues = (templateString: string, valuesObject: {[key: string]: string}) => {
  let result = templateString;

  if (valuesObject === null || valuesObject === undefined) {
    return result;
  }

  const keys = Object.keys(valuesObject);

  if (keys.length === 0) {
    throw new Error(`The 'valuesObject' has no keys. Acceptable values are object with keys and null`);
  }

  keys.forEach(key => {
    const regEx = new RegExp(`{${key}}`, 'g');
    result = result.replace(regEx, valuesObject[key]);
  });

  return result;
};
