import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { ProvisioningService } from '../../services/organization-provisioning/provisioning.service';
import { EProvisionedState } from '../../../shared/models/provision.model';

@Component({
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.scss']
})
export class InterstitialComponent implements OnInit {
  loading = false;

  constructor(
    private router: Router,
    private provisioningService: ProvisioningService
  ) {}

  async ngOnInit() {
    setTimeout(() => {
      this.displayLoadingMessage();
    }, 1000);

    await this.redirectToProvisioningCheck();
  }

  async redirectToProvisioningCheck() {
    let provisionStatus =
      await this.provisioningService.getProvisioningStatus();
    if (provisionStatus === EProvisionedState.Provisioned) {
      this.router.navigate(['auditing', 'auditing', 'dashboard']);
    } else {
      // No inputs, thinks it's source is ODA web page
      this.router.navigate(['auditing', 'auditing', 'provision']);
    }
  }

  displayLoadingMessage() {
    this.loading = true;
  }
}
