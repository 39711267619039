import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent, IModalWindow } from '@ondemand/core';
import { LocaleStringsService } from '../../../../services/locale-strings.service';
import {
  CriticalActivityDataSet,
  CriticalActivityDismissedOrHideItem
} from '../../../../../shared/models/critical-activity.shared.models';
import { takeUntil } from 'rxjs/operators';
import { HiddenItemEditorRow } from './model/hidden-item-editor.models';

@Component({
  selector: 'hidden-item-editor',
  templateUrl: './hidden-item-editor.component.html',
  styleUrls: ['./hidden-item-editor.component.scss']
})
export class HiddenItemEditorComponent extends BaseComponent implements OnInit {
  @Input() hiddenItems: CriticalActivityDataSet[];
  @Output() hiddenItemsUpdated: EventEmitter<
    CriticalActivityDismissedOrHideItem[]
  >;
  // this is being used as a workaround. this.selectAll is bound to this element,
  // but in some cases, reasigning it does not reflect in the UI. Having a ref
  // to the element seems to allow us to set it.
  @ViewChild('selectAll') selectAllCheckbox: ElementRef<HTMLInputElement>;
  @ViewChild('editHiddenItemsForm') form: NgForm;
  @ViewChild('eventCountHeader') eventCountHeader: ElementRef<HTMLDivElement>;

  modalParams: IModalWindow;

  hiddenItemsRows: HiddenItemEditorRow[];
  hiddenItemCount: number;
  selectedItemCount: number;

  hiddenItemsCountLabel: string;
  selectedItemsCountLabel: string;
  selectAll: boolean;

  constructor(private localeStringsService: LocaleStringsService) {
    super();
    this.hiddenItemsUpdated = new EventEmitter<
      CriticalActivityDismissedOrHideItem[]
    >();
    this.modalParams = { showModal: false };
  }

  ngOnInit(): void {
    this.localeStringsService.strings$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.modalParams.dialogParams = {
          hideCancel: true,
          title: labels.auditing.pages.criticalActivity.hiddenItemsEditor.title
        };
      });
  }

  initializeData() {
    this.hiddenItemsRows = this.hiddenItems.map(item => {
      const eventCount: number = parseInt(item.data.split(',').join(''), 10);

      return {
        ...item,
        data: eventCount > 10000000 ? '10,000,000+' : item.data,
        selected: false
      } as HiddenItemEditorRow;
    });

    this.hiddenItemsRows.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    this.updateCounts();
  }

  updateCounts() {
    this.hiddenItemCount = this.hiddenItemsRows.length;
    this.hiddenItemsCountLabel = `(${this.hiddenItemCount})`;
    this.selectedItemCount = this.hiddenItemsRows.filter(
      row => row.selected
    ).length;
    this.selectedItemsCountLabel = `(${this.selectedItemCount})`;
    this.selectAll =
      this.hiddenItemCount > 0 &&
      this.selectedItemCount === this.hiddenItemCount;

    this.updateEventCountHeaderPadding();
  }

  openModal() {
    // the sequence of steps in this method
    // was critical to fix the checkbox state update issue.
    this.form.reset();
    this.initializeData();
    this.selectAll = false;
    this.modalParams = { ...this.modalParams };
    this.modalParams.showModal = true;

    // workaround for selectAll binding failing
    // to update from setting it in here
    // without relying on binding.
    this.setSelectAll(false);
  }

  closeModal() {
    this.modalParams.showModal = false;
  }

  onCheckChanged(event: any, hiddenItem: HiddenItemEditorRow): void {
    const isChecked: boolean = event.target.checked;
    hiddenItem.selected = isChecked;
    if (!isChecked) {
      this.setSelectAll(isChecked);
    }
    this.updateCounts();
  }

  onSelectAllChanged(event: any): void {
    const isChecked: boolean = event.target.checked;
    this.selectAll = isChecked;

    const updatedRows = this.hiddenItemsRows.map(row => ({
        ...row,
        selected: isChecked
      }));

    this.hiddenItemsRows = updatedRows;

    this.updateCounts();
  }

  onRemoveClicked() {
    const remainingItems = this.hiddenItemsRows.filter(row => !row.selected);
    this.hiddenItemsRows = remainingItems;
    this.updateCounts();
    this.form.form.markAsDirty();
    this.setSelectAll(false);
  }

  onSaveClicked() {
    const itemsRemaining: CriticalActivityDismissedOrHideItem[] =
      this.hiddenItemsRows.map(item => item.dismissOrHideData);

    this.hiddenItemsUpdated.emit(itemsRemaining);
    this.closeModal();
  }

  private setSelectAll(isChecked: boolean) {
    this.selectAll = isChecked;
    this.selectAllCheckbox.nativeElement.checked = isChecked;
  }

  private updateEventCountHeaderPadding(): void {
    if (this.hiddenItemsRows.length > 5) {
      this.eventCountHeader.nativeElement.style.paddingRight = '1.13rem';
    } else {
      this.eventCountHeader.nativeElement.style.paddingRight = '0';
    }
  }
}
