import { Component } from '@angular/core';

@Component({
  selector: 'warning-message',
  template: `
    <div class="warning-message card-panel">
      <div class="icon-container">
        <edc-icon name="status-warning"></edc-icon>
      </div>
      <div class="text-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .warning-message {
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    edc-icon {
      vertical-align: middle;
      margin-right: 0.5rem;
      color: orange;
    }

    .icon-container {
      float: left;
      width: 40px;
    }

    .text-container {
      float: left;
      width: calc(100% - 40px);
    }
  `]
})

export class WarningMessageComponent {
}
