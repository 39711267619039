export class EventColumnSet {
  private columnNames: string[] = [];

  constructor(colNames: string[]) {
    this.addItems(colNames);
  }

  getItems() {
    return this.columnNames;
  }

  /* maintains unique item list */
  add(newColName: string) {
    if (this.exists(newColName) === false) {
      this.columnNames.push(newColName);
      this.columnNames.sort((a, b) => this.compare(a, b));
    }
  }

  /* maintains unique item list */
  addItems(newColNames: string[]) {
    const nonExistingCols = newColNames.filter(
      col => this.exists(col) === false
    );
    const colNamesToAdd = new Set(nonExistingCols);
    this.columnNames = [...this.columnNames, ...colNamesToAdd];
    this.columnNames.sort((a, b) => this.compare(a, b));
  }

  exists(colName: string): boolean {
    return this.columnNames.indexOf(colName) >= 0;
  }

  remove(value: string): boolean {
    if (this.columnNames) {
      const index = this.columnNames.indexOf(value);
      if (index > -1) {
        this.columnNames.splice(index, 1);
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  clear() {
    this.columnNames = [];
  }

  private compare(value1: any, value2: any): number {
    return value1.toLowerCase().localeCompare(value2.toLowerCase());
  }
}
