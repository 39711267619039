export enum StaticIndicatorID {
  AdAccountLockouts = 'adAccountLockouts',
  AllFailedSignins = 'allFailedSignins',
  AzureADCriticalRoleChanges = 'azureADCriticalRoleChanges',
  AzureADFailedSignins = 'azureADFailedSignins',
  AzureADRiskEvents = 'azureADRiskEvents',
  CloudOnlyAzureADUsersCreated = 'cloudOnlyAzureADUsersCreated',
  O365ExternalUserActions = 'o365ExternalUserActions',
}

export enum WidgetID {
  AuditHealth = 'auditHealth',
  CriticalActivities = 'criticalActivity',
  MyFavoriteSearches = 'myFavoriteSearches',
  Signins = 'signins',
  TopActiveUsers = 'topActiveUsers',
}

export interface WidgetInformation {
  widgetId: WidgetID | StaticIndicatorID;
  getDataEndpoint: string;
  putConfigurationEndpoint?: string;
}

export interface StaticIndicatorSetting {
  id: StaticIndicatorID;
  iconName: string;
  displayTitle: string;
}

export let StaticIndicatorConfiguration: Map<
  string,
  StaticIndicatorSetting
> = new Map([
  [
    StaticIndicatorID.AdAccountLockouts,
    {
      id: StaticIndicatorID.AdAccountLockouts,
      iconName: 'lock',
      displayTitle: 'AD account lockouts'
    }
  ],
  [
    StaticIndicatorID.AllFailedSignins,
    {
      id: StaticIndicatorID.AllFailedSignins,
      iconName: 'calendar',
      displayTitle: 'On-premises and Azure AD failed sign-ins'
    }
  ],
  [
    StaticIndicatorID.AzureADCriticalRoleChanges,
    {
      id: StaticIndicatorID.AzureADCriticalRoleChanges,
      iconName: 'changed-membership',
      displayTitle: 'Azure AD critical directory role changes'
    }
  ],
  [
    StaticIndicatorID.AzureADFailedSignins,
    {
      id: StaticIndicatorID.AzureADFailedSignins,
      iconName: 'calendar',
      displayTitle: 'Azure AD failed sign-ins'
    }
  ],
  [
    StaticIndicatorID.AzureADRiskEvents,
    {
      id: StaticIndicatorID.AzureADRiskEvents,
      iconName: 'calendar',
      displayTitle: 'Azure AD risk events'
    }
  ],
  [
    StaticIndicatorID.CloudOnlyAzureADUsersCreated,
    {
      id: StaticIndicatorID.CloudOnlyAzureADUsersCreated,
      iconName: 'user',
      displayTitle: 'Cloud-only Azure AD users created'
    }
  ],
  [
    StaticIndicatorID.O365ExternalUserActions,
    {
      id: StaticIndicatorID.O365ExternalUserActions,
      iconName: 'users',
      displayTitle: 'Office 365 external user actions'
    }
  ]
]);
