<div class="configuration-card-v2">

  <ng-container *ngIf="!initialized && !isDeleting">
    <internal-loading [information]="loadingMessage" isLoading="true"></internal-loading>
  </ng-container>

  <ng-container *ngIf="isDeleting">
    <internal-loading [information]="deletingMessage" isLoading="true"></internal-loading>
  </ng-container>

  <ng-container *ngIf="configStates === BheConfigStates.UnAvailable && initialized && !isDeleting" >

    <img class="add-icon-img" [src]="addIconPath" (click)="addConfig()">

    <h4 class="add-config-title">{{this.addTitle}}</h4>

    <p class="add-config-description">

      {{this.description}}

      <a href="https://www.quest.com/products/attack-path-management-software/#overview" target="_blank">{{this.learnMoreLinkText}}
        <span class="learn-more-icon-box">
          <mat-icon class="learn-more-icon-style" svgIcon="popout"></mat-icon>
        </span>
      </a>

    </p>

    <div class="card-action-buttons-box">
      <od-caption [text]="addButtonText" (captionClicked)="addConfig()"></od-caption>
    </div>

  </ng-container>

  <ng-container *ngIf="configStates === BheConfigStates.Available && initialized && !isDeleting" >
    <div class="status-header-box">
      <img [src]="bloodHoundIconPath">
      <h4>{{this.statusCardTitle}}</h4>
      <div class="loading-indicator-container">
      <div class="progress-indicator" title="{{updatingStatusTitle}}" *ngIf="statusUpdating">
        <materialize-spinner [size]="'small'"></materialize-spinner>
      </div>
      </div>
      <div class="more-dropdown-box">
        <od-more-dropdown
          id="bhe-status-dropdown"
          iconVertical="true"
          xPosition="before"
          yPosition="below"
          [dropdownItems]="dropdownItems"
          (dropdownItemClicked)="dropdownItemEventHandler($event)"
        ></od-more-dropdown>
      </div>
    </div>
    <div class="banner-box">
      <od-notification-banner
        [text]="notificationMessage"
        [mode]="notificationMode"
        [icon]="notificationIconName">
      </od-notification-banner>
    </div>
    <div class="connection-status-box">
      <div class="status-title">
        <span>{{statusLabel}}</span>
      </div>
      <div class="bhe-conn-status-row">
        <div class="bhe-conn-status-column-left">
          <span>{{ lastConfigurationReceived }}</span>
        </div>
        <div class="bhe-conn-status-column-right content-right" *ngIf="this.lastUpdatedRelativeTimeStr">
          <div class="qod-tooltip qod-tooltip-bottom">
          <edc-icon name="clock"></edc-icon>
          <span class="qod-tooltiptext">
            {{ lastUpdatedDate?.toISOString() | date: 'medium'  }}
          </span>
          </div>
          <label>
            {{ lastUpdatedRelativeTimeStr }}
          </label>
        </div>
      </div>
      <div class="bhe-conn-status-row">
        <div class="bhe-conn-status-column-left">
          <span>{{ nextConfigurationSync }}</span>
        </div>
        <div class="bhe-conn-status-column-right content-right">
          <div class="qod-tooltip qod-tooltip-bottom">
          <edc-icon name="clock"></edc-icon>
          <span class="qod-tooltiptext">
            {{ nextSyncTime?.toISOString() | date: 'medium'  }}
          </span>
          </div>
          <label>
            {{ nextSyncRelativeTimeStr }}
          </label>
        </div>
      </div>
    </div>

  <div class="card-action-buttons-box">
    <span>
      <od-caption [text]="editConfigButtonLabel" (captionClicked)="editConfig()"></od-caption>
    </span>
    <span class="remove-btn-box">
      <od-caption [text]="removeConfigButtonLabel" (captionClicked)="removeConfig()"></od-caption>
    </span>
  </div>
</ng-container>
</div>

<bhe-config-editor (closeEvent)="handleEditorClose($event)"></bhe-config-editor>

<od-notification-plan-edit *ngIf="showNotificationPlanEditor"
  [notificationPlanId]="tierZeroNotificationPlanID"
  [ownerModule]="moduleName"
  [isOpen]="showNotificationPlanEditor"
  [displayPlanType]="false"
  (whenClosed)="editorClosed()"
></od-notification-plan-edit>
