/**
 * Route guard to help users avoid losing unsaved search changes
 */
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ActiveQueryService } from '../services/active-query.service';
import { AppFacadeService } from '@ondemand/core';
import { LocaleStringsService } from '../services/locale-strings.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Paths to which navigation is unrestricted.
// This forms the "boundary" of the search editing experience
const unrestrictedPaths = [
  '/auditing/auditing/queries/editor',
  '/auditing/auditing/queries/results'
];

@Injectable()
export class UnsavedEditorChangesGuard implements CanDeactivate<any> {
  constructor(
    private activeQueryService: ActiveQueryService,
    private facade: AppFacadeService,
    private localeStrings: LocaleStringsService
    ) { }

  async canDeactivate(_component: any, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
                      nextState: RouterStateSnapshot): Promise<boolean> {
    // Always allow navigation within the bounds of the search editing workflow
    const withinBounds = unrestrictedPaths.some((path) => nextState.url.includes(path));

    return new Promise<boolean>(async (resolve) => {
      if (withinBounds) {
        resolve(true);
      } else if (!this.activeQueryService.isSaved()) {
        let discardChanges = await this.promptToDiscardChanges();
        if (discardChanges === true) {
          this.activeQueryService.clearQuery();
        }
        resolve(discardChanges === true);
      } else {
        this.activeQueryService.clearQuery();
        resolve(true);
      }

    });
  }

  private async promptToDiscardChanges(): Promise<boolean> {
    let title = await this.localeStrings.string$('auditing.discardUnsavedChangesTitle').pipe(take(1)).toPromise();
    let message = await this.localeStrings.string$('auditing.discardUnsavedSearchChanges').pipe(take(1)).toPromise();
    return this.facade.confirm(title, message);
  }

}
