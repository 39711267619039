/**
 * Component for viewing list of alert plans
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AppFacadeService,
  BaseComponent,
  EDialogType,
  IModalWindow
} from '@ondemand/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertPlan } from '../../../models/alert-plan.model';
import { AlertService } from '../../../services/alerts.service';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { AuditingBreadcrumbsService } from '../../../services/auditing-breadcrumbs.service';
import { AlertRule } from '../../../models/alert-rule.model';
import {
  AlertRuleEditorModalComponent,
  AlertRuleEditorSavedEvent
} from '../alert-rule-editor-modal/alert-rule-editor-modal.component';
import * as fromPermissions from '../../../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import { ReplaySubject } from 'rxjs';
import {
  getLastSavedToolTipInfo,
  getLastSavedLabelText
} from '../../../util/last-saved-info';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../shared/utils/toast.wrapper';

export interface AlertPlanListRow {
  alertPlan: AlertPlan;
  expanded: boolean;
  alertRules?: AlertRule[];
  loadingRules: boolean;
  error?: string;
  hasAlertRules: boolean;
}

@Component({
  templateUrl: './alert-plan-list.component.html',
  styleUrls: ['./alert-plan-list.component.scss']
})
export class AlertPlanListComponent extends BaseComponent implements OnInit {
  @ViewChild(AlertRuleEditorModalComponent)
  ruleEditorModal: AlertRuleEditorModalComponent;
  alertPlanRows: AlertPlanListRow[];
  error: Observable<string>;
  loading = false;
  lastSavedToolTipRefreshProp: string[] = [];
  fromPermissions = fromPermissions;
  successModalParams: IModalWindow;
  successModalContent: {
    linkUrl: string;
    linkTextPath: string;
  };
  deletePlanWithAlertsModalParams: IModalWindow;
  deletePlanMessage: string;

  constructor(
    private alertService: AlertService,
    private localeStrings: LocaleStringsService,
    private facade: AppFacadeService,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private permissionsService: AuditModulePermissionsService,
    private toastService: ODToastService
  ) {
    super();
  }

  async ngOnInit() {
    this.breadcrumbsService.set([
      {
        title: await this.localeStrings
          .string$('auditing.alertPlanListNavTitle')
          .pipe(take(1))
          .toPromise(),
        url: 'auditing/auditing/alerts/plans'
      }
    ]);

    this.deletePlanWithAlertsModalParams = {
      showModal: false,
      dialogParams: {
        title: await this.localeStrings
        .string$('auditing.confirmDeleteAlertPlanTitle')
        .pipe(take(1))
        .toPromise(),
        type: EDialogType.ERROR,
        cancelButtonAction: () => {
          this.closeDeletePlanWithAlertsModal();
        }
      }
    };

    this.loadAlertPlans();
  }

  async onDeletePlanClick(plan: AlertPlan) {
    const alertRuleId: string = null;
    let linkedAlertRules: AlertRule[];
    try {
      const alertRuleLookupResponse = await this.alertService
        .getAlertRules(alertRuleId, plan.id)
        .toPromise();
      linkedAlertRules = alertRuleLookupResponse.body;
    } catch (error) {
      console.error('Failed to look up linked alert rules.');
      this.handleErrorDeletingPlan();
      return;
    }

    const hasLinkedAlertRules = linkedAlertRules.length > 0;
    let deletePlanTitle = await this.localeStrings
      .string$('auditing.confirmDeleteAlertPlanTitle')
      .pipe(take(1))
      .toPromise();
    if (hasLinkedAlertRules) {
      this.deletePlanMessage = await this.localeStrings
        .string$('auditing.alertPlanHasLinkedRules', { name: plan.name })
        .pipe(take(1))
        .toPromise();
      this.deletePlanWithAlertsModalParams = {
        ...this.deletePlanWithAlertsModalParams,
        showModal : true
      };
    } else {
      this.deletePlanMessage = await this.localeStrings
        .string$('auditing.confirmDeleteAlertPlan', { name: plan.name })
        .pipe(take(1))
        .toPromise();
      if (await this.facade.confirm(deletePlanTitle, this.deletePlanMessage)) {
        this.alertService.deleteAlertPlan(plan.id).subscribe(
          async () => {
            const successMessage = await this.localeStrings
              .string$('auditing.deletedAlertPlanSuccess')
              .pipe(take(1))
              .toPromise();

            toastLowerLeft(this.toastService, successMessage, ToastType.Success);
            this.loadAlertPlans();
          },
          async error => {
            console.error('Got error deleting plan:', error);
            this.handleErrorDeletingPlan();
          }
        );
      }
    }
  }

  canCreateNewAlertPlans(): boolean {
    return (
      this.canManageSharedAlertPlans() || this.canManagePrivateAlertPlans()
    );
  }

  canDeleteAlertPlan(plan: AlertPlan): boolean {
    return (
      (plan.isShared && this.canManageSharedAlertPlans()) ||
      (!plan.isShared && this.canManagePrivateAlertPlans())
    );
  }

  canManageSharedAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManageSharedAlertsAndAlertPlans
    ]);
  }

  canManagePrivateAlertPlans(): boolean {
    return this.permissionsService.hasAnyOfPermissions([
      fromPermissions.canManagePrivateAlertsAndAlertPlans
    ]);
  }

  toggleExpandedStatus(row: AlertPlanListRow) {
    if (row.expanded) {
      row.expanded = false;
    } else {
      row.expanded = true;
      this.getLinkedAlertRules(row);
      this.updateLastSavedInfo(row.alertPlan);
    }
  }

  onClickEditRule(alertRule: AlertRule) {
    this.ruleEditorModal.openToEditAlertRule(alertRule.id);
  }

  async onAlertRuleEditSave(savedEvent: AlertRuleEditorSavedEvent) {
    const newPlanCreated = savedEvent.createdAlertPlan !== null;

    if (newPlanCreated) {
      this.showSuccessModal(savedEvent.createdAlertPlan);
    } else {
      const message = await this.localeStrings
        .string$('auditing.savedAlertRule')
        .pipe(take(1))
        .toPromise();

      toastLowerLeft(this.toastService, message, ToastType.Success);
    }
    this.loadAlertPlans();
  }

  private async updateLastSavedInfo(plan: AlertPlan) {
    const lastSavedLabel = await this.localeStrings
      .string$('auditing.lastSavedLabel')
      .pipe(take(1))
      .toPromise();
    const lastSavedTooltip = await this.localeStrings
      .string$('auditing.lastSavedTooltip')
      .pipe(take(1))
      .toPromise();

    setTimeout(() => {
      this.lastSavedToolTipRefreshProp = [];
    }, 200);

    plan.lastSavedToolTipInfo.next(
      getLastSavedToolTipInfo(
        lastSavedTooltip,
        plan.createdDate,
        plan.createdBy,
        plan.modifiedDate,
        plan.modifiedBy
      )
    );

    plan.lastSavedLabelInfo.next(
      getLastSavedLabelText(lastSavedLabel, plan.modifiedBy, plan.modifiedDate)
    );
  }

  private getLinkedAlertRules(row: AlertPlanListRow) {
    row.loadingRules = true;
    const plan = row.alertPlan;
    this.alertService.getAlertRulesForPlan(plan.id).subscribe(
      response => {
        row.loadingRules = false;
        if (response.status === 200) {
          let rules: AlertRule[] = response.body;
          row.alertRules = rules;
        } else {
          console.error('Unexpected response from alert service:', response);
          this.onErrorLoadingRules();
        }
      },
      errorResponse => {
        row.loadingRules = false;
        console.error('Unexpected response from alert service:', errorResponse);
        row.expanded = false;
        this.onErrorLoadingRules();
      }
    );
  }

  private async onErrorLoadingRules() {
    const message = await this.localeStrings
      .string$('auditing.failedToLoadAlertRulesForPlan')
      .pipe(take(1))
      .toPromise();

    toastLowerLeft(this.toastService, message, ToastType.Error);
  }

  private loadAlertPlans() {
    this.alertPlanRows = null;
    this.error = null;
    this.loading = true;
    this.alertService.getAlertPlans().subscribe(
      response => {
        this.loading = false;
        if (response.status === 200) {
          const data: AlertPlan[] = response.body;
          this.alertPlanRows = data.map(plan => {
            plan.lastSavedToolTipInfo = new ReplaySubject<string>(1);
            plan.lastSavedLabelInfo = new ReplaySubject<string>(1);
            return {
              alertPlan: plan,
              expanded: false,
              loadingRules: false,
              hasAlertRules: plan.numAlertRules > 0
            };
          });
        } else {
          console.error(
            'Got unexpected response from alert service:',
            response
          );
          this.error = this.localeStrings.string$(
            'auditing.errorLoadingAlertPlans'
          );
        }
      },
      error => {
        this.loading = false;
        console.error('Error loading alert plans:', error);
        this.error = this.localeStrings.string$(
          'auditing.errorLoadingAlertPlans'
        );
      }
    );
  }

  private async handleErrorDeletingPlan() {
    const errorMessage = await this.localeStrings
      .string$('auditing.errorDeletingAlertPlan')
      .pipe(take(1))
      .toPromise();

    toastLowerLeft(this.toastService, errorMessage, ToastType.Error);
  }

  private async showSuccessModal(createdAlertPlan: AlertPlan) {
    let modalTitle = await this.localeStrings
      .string$('auditing.alertRuleSaveSuccess')
      .pipe(take(1))
      .toPromise();

    this.successModalContent = {
      linkUrl: `/auditing/auditing/alerts/plans/${createdAlertPlan.id}`,
      linkTextPath: 'auditing.alertPlanEditNewLinkText'
    };

    this.successModalParams = {
      showModal: true,
      dialogParams: {
        title: modalTitle,
        type: EDialogType.INFO,
        hideCancel: true,
        actions: [
          {
            name: await this.localeStrings
              .string$('auditing.okay')
              .pipe(take(1))
              .toPromise(),
            action: () => {
              this.closeModal();
            }
          }
        ]
      }
    };
  }

  private closeModal() {
    this.successModalParams.showModal = false;
  }

  private async closeDeletePlanWithAlertsModal() {
    this.deletePlanWithAlertsModalParams = {
      ...this.deletePlanWithAlertsModalParams,
      showModal : false
    };
    const errorMessage = await this.localeStrings
    .string$('auditing.errorDeletingAlertPlanWithLinkedRules')
    .pipe(take(1))
    .toPromise();

    toastLowerLeft(this.toastService, errorMessage, ToastType.Error);
  }
}
