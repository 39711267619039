import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromFeature from '../../reducers/index';

const criticalActivityListState = createSelector(
    fromFeature.selectCriticalActivityFeatureState,
    state => state.criticalActivityList
);

export const selectCriticalActivityListData = createSelector(
    criticalActivityListState,
    state => state.data
);

export const selectCriticalActivityListLoading = createSelector(
    criticalActivityListState,
    state => state.loading
);

export const selectCriticalActivityListError = createSelector(
    criticalActivityListState,
    state => state.error
);

export const selectCriticalActivityListExpired = createSelector(
  criticalActivityListState,
  state => state.expired
);
