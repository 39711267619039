import { CriticalActivityResponse } from '../../../../../shared/models/critical-activity.shared.models';
import * as fromCriticalActivityList from './critical-activity-list.actions';

export interface CriticalActivityListState {
  data: CriticalActivityResponse;
  loading: boolean;
  updating: boolean;
  expired: boolean;
  error: any;
}

export const initialState: CriticalActivityListState = {
  data: null,
  loading: false,
  updating: false,
  expired: false,
  error: null
};

export function reducer(
  state: CriticalActivityListState = initialState,
  action: fromCriticalActivityList.CriticalActivityListAction
): CriticalActivityListState {
  switch (action.type) {
    case fromCriticalActivityList.LOAD_CRITICAL_ACTIVITY_LIST:
      return {
        ...state,
        data: null,
        loading: true
      };
    case fromCriticalActivityList.LOAD_CRITICAL_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        expired: false,
        error: null
      };
    case fromCriticalActivityList.LOAD_CRITICAL_ACTIVITY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case fromCriticalActivityList.UPDATE_CRITICAL_ACTIVITY_LIST:
      return {
        ...state,
        updating: true
      };
    case fromCriticalActivityList.UPDATE_CRITICAL_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        updating: false,
        expired: true,
        error: null
      };
    case fromCriticalActivityList.UPDATE_CRITICAL_ACTIVITY_LIST_FAIL:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    default:
      return state;
  }
}
