import { EServiceID } from './service-ids';
import { EAuditFeatureName } from '../../../services/feature-subscription/feature-subscription.service';

export const tenantServiceList = [
  {
    id: EServiceID.AZURE_AD,
    label: 'auditing.services.azureAD',
    feature: EAuditFeatureName.AZURE_AD_AUDIT_LOGS,
    expiredFeature: EAuditFeatureName.AZURE_AD_AUDIT_LOGS_EXPIRED,
    offboardingFeature: EAuditFeatureName.AZURE_AD_AUDIT_LOGS_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.AZURE_AD_AUDIT_LOGS_OFFBOARDED
  },
  {
    id: EServiceID.AZURE_AD_SIGN_INS,
    label: 'auditing.services.azureSignIns',
    feature: EAuditFeatureName.AZURE_AD_SIGN_INS,
    expiredFeature: EAuditFeatureName.AZURE_AD_SIGN_INS_EXPIRED,
    offboardingFeature: EAuditFeatureName.AZURE_AD_SIGN_INS_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.AZURE_AD_SIGN_INS_OFFBOARDED
  },
  {
    id: EServiceID.EXCHANGE_ADMIN_ACTIVITY,
    label: 'auditing.services.exchangeAdmin',
    feature: EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY,
    expiredFeature: EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_EXPIRED,
    offboardingFeature: EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.EXCHANGE_ONLINE_ADMIN_ACTIVITY_OFFBOARDED
  },
  {
    id: EServiceID.EXCHANGE_MAILBOX_ACTIVITY,
    label: 'auditing.services.exchangeMailbox',
    feature: EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY,
    expiredFeature: EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_EXPIRED,
    offboardingFeature: EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.EXCHANGE_ONLINE_MAILBOX_ACTIVITY_OFFBOARDED
  },
  {
    id: EServiceID.ONEDRIVE,
    label: 'auditing.services.onedriveBusiness',
    feature: EAuditFeatureName.ONEDRIVE,
    expiredFeature: EAuditFeatureName.ONEDRIVE_EXPIRED,
    offboardingFeature: EAuditFeatureName.ONEDRIVE_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.ONEDRIVE_OFFBOARDED
  },
  {
    id: EServiceID.SHAREPOINT,
    label: 'auditing.services.sharepointOnline',
    feature: EAuditFeatureName.SHAREPOINT_ONLINE,
    expiredFeature: EAuditFeatureName.SHAREPOINT_ONLINE_EXPIRED,
    offboardingFeature: EAuditFeatureName.SHAREPOINT_ONLINE_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.SHAREPOINT_ONLINE_OFFBOARDED
  },
  {
    id: EServiceID.TEAMS,
    label: 'auditing.services.teams',
    feature: EAuditFeatureName.TEAMS,
    expiredFeature: EAuditFeatureName.TEAMS_EXPIRED,
    offboardingFeature: EAuditFeatureName.TEAMS_OFFBOARDING,
    offboardedFeature: EAuditFeatureName.TEAMS_OFFBOARDED
  }
];
