<modal-window [params]="modalParams" *ngIf="modalParams">
  <div modal-content>
    <div class="dismiss-critical-activity-modal">
      <div class="main-section">
        <span>{{labels.explanation}}</span>
        <span>{{labels.areYouSure}}</span>
      </div>
      <div class="action-button-container-outer">
        <div class="action-buttons left">
          <input
          type="checkbox"
          id="hide-event-checkbox"
          name="hide-event-checkbox"
          [checked]="hideAllFutureEvents"
          (change)="onCheckboxValueChange($event)"
        />
        <label for="hide-event-checkbox">{{ labels.actionExplanation }}</label>
        </div>
        <div class="action-buttons right">
          <button 
          type="button"
          class="modal-close waves-effect btn-secondary cancel-dismiss-btn"
          (click)="onCancelDismissClick()"
          >
            {{ labels.cancel }}
          </button>
          <button
            type="button"
            class="btn waves-effect confirm-dismiss-btn"
            (click)="onConfirmDismissClick()"
          >
            {{ labels.confirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</modal-window>