/**
 * Component for managing retention settings
 */
import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AppFacadeService, BaseComponent } from '@ondemand/core';
import { AuditingBreadcrumbsService } from '../../services/auditing-breadcrumbs.service';
import { LocaleStringsService } from '../../services/locale-strings.service';
import {
  RetentionInfoService,
  EventRetentionTableEntry
} from '../../services/retention-info/retention-info.service';
import { AuditSerivceName } from '../../../shared/models/audit-workload.models';

@Component({
  templateUrl: './retention-settings.component.html',
  styleUrls: ['./retention-settings.component.scss']
})
export class RetentionSettingsComponent
  extends BaseComponent
  implements OnInit {
  eventRetentionTableEntries: EventRetentionTableEntry[];
  errorMessageReference: string;
  loading = false;

  constructor(
    private facadeService: AppFacadeService,
    private breadcrumbsService: AuditingBreadcrumbsService,
    private localeStrings: LocaleStringsService,
    private retentionInfoService: RetentionInfoService
  ) {
    super();
  }

  async ngOnInit() {
    this.updateBreadcrumbs();
    this.setupTable();
  }

  private async setupTable() {
    this.loading = true;
    try {
      let retentionInfos = await this.retentionInfoService
        .getRetentionTableEntries()
        .toPromise();

      this.eventRetentionTableEntries = retentionInfos;
    } catch (error) {
      this.errorMessageReference = 'auditing.retentionInfoError';
      console.error('Error getting table entries:', error);
    }
    this.loading = false;
  }

  private async updateBreadcrumbs() {
    let crumbs = [
      {
        title: await this.localeStrings
          .string$('auditing.retentionTabBreadcrumb')
          .pipe(take(1))
          .toPromise(),
        url: 'auditing/auditing/retention'
      }
    ];

    this.breadcrumbsService.set(crumbs);
  }
}
