import {
    ActionReducerMap,
    createFeatureSelector,
    MemoizedSelector
  } from '@ngrx/store';
import * as fromCriticalActivityList from '../critical-activity-list/state/critical-activity-list.reducer';

export interface CriticalActivityState {
    criticalActivityList: fromCriticalActivityList.CriticalActivityListState;
}

export const reducers: ActionReducerMap<CriticalActivityState> = {
    criticalActivityList: fromCriticalActivityList.reducer
};

export const selectCriticalActivityFeatureState = createFeatureSelector<CriticalActivityState>(
   'criticalActivityTab'
);
