import { takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { QueryClause, BaseComponent, IInputConfig } from '@ondemand/core';
import { EventField } from '../../../../../models/event-field.model';
import { substituteTemplateValues } from '../../../../../util/template-substituter';
import { TypeAheadConfigurationService } from './helpers/type-ahead-configuration-service';
import { timer } from 'rxjs';
import { AuditingDisplayStringsProvider } from '../../../../../../application-strings-EN';
import { QueryValueChangesExt } from './helpers/query-value-changes-ext';
import { EditorStatus, IColumnData } from '../models/editor-parameters';

@Component({
  selector: 'column-editor',
  templateUrl: './column-editor.component.html',
  styleUrls: ['./column-editor.component.scss']
})
export class ColumnEditorComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() projectionFields: EventField[];
  @Input() allFields: EventField[];
  @Input() groupByFieldDisplayName: string;
  @Output() columnsUpdate: EventEmitter<IColumnData[]>;
  @Output() statusUpdate: EventEmitter<EditorStatus>;

  temporaryColumnOrder: IColumnData[] = [];
  columnUsage: any = {};
  displayBannerLabel: string;
  displayBanner: boolean;
  // type ahead picker setting
  clauseKey: string;
  clause: QueryClause;
  inputConfig: IInputConfig;
  lastDivId = '';
  currentDragulaId: number;

  // Template text
  flyoutColumnSubHeader: string;
  flyoutColumnLabel: string;
  addButton: string;
  removeTooltip: string;
  reorderTooltip: string;
  placeholder: string;
  errorEmptyFieldValue: string;
  invalidColumnMessage: string;
  unknownColumnMessage: string;

  // request to validate input on all inputs
  requestToValidate = false;

  // Used to help track column option draggable elements
  private optionCounter = 0;
  private typeAheadConfigurationService: TypeAheadConfigurationService;

  // variable to prevent excess event firing
  private previousEmittedColumns: string[];

  // editor status
  private editorStatus: EditorStatus;
  private userReactedToThisComponent = false;

  constructor(private dragulaService: DragulaService) {
    super();
    this.typeAheadConfigurationService = new TypeAheadConfigurationService();

    // setup output event emitters
    this.columnsUpdate = new EventEmitter<IColumnData[]>();
    this.statusUpdate = new EventEmitter<EditorStatus>();
  }

  ngOnInit() {
    // Set up element re-ordering via Dragula
    const handleClass = 'column-order-grabber';
    this.dragulaService.createGroup('column-selections', {
      moves: (_el: Element, _container: Element, handle: Element) =>
        handle.className === handleClass
    });

    // Mark form as changed if columns are re-ordered
    this.dragulaService
      .drop()
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(() => {
        this.validateAndEmitNotification();
      });

    this.setupTemplateText();
    this.initializeColumnData();
    this.editorStatus = EditorStatus.Initialized;
    this.requestToValidate = false;
    this.userReactedToThisComponent = false;
    this.displayBanner = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dragulaService.destroy('column-selections');
  }

  initializeColumnData(): void {
    if (!this.allFields) {
      return;
    }

    this.typeAheadConfigurationService.setColumnList(
      this.allFields.map(field => field.displayName)
    );

    this.temporaryColumnOrder = this.projectionFields.map(field => ({
        name: field.id,
        deprecated: !field.retrievable,
        dragulaId: this.optionCounter++,
        clause: this.getQueryClause(field.displayName),
        inputConfig:
          this.typeAheadConfigurationService.getColumnPickerFilterInputConfig()
      } as IColumnData));
    this.updateColumnUsage();
    // initial value to be the original list of columns
    this.previousEmittedColumns = this.projectionFields.map(field => field.id);
  }

  onMouseEnter(): void {
    // on component initialization,
    // the onValueChange() will be invoked multiple times;
    // It is invoked 1 time for each column;
    // This method will help us to determine if we
    // should start firing update events to the parent.
    if (!this.userReactedToThisComponent) {
      this.userReactedToThisComponent = true;
    }
    this.requestToValidate = false;
  }

  onMouseLeave(): void {
    this.requestToValidate = true;
  }

  /* Captures reference (using 'currentDragulaId') to current type-ahead text box.
  'currentDragulaId' is used in updateColumnUsage message to associate field name.
  */
  onClickRaisedOnChild(currentDragulaId: number) {
    this.currentDragulaId = currentDragulaId;
    timer(300).subscribe(() => {
      if (
        document.querySelector(
          '.input-wrapper ul.dropdown-content li.more-data'
        )
      ) {
        document
          .querySelector('.input-wrapper ul.dropdown-content li.more-data')
          .remove();
      }
    });
  }

  positionScrollbarAtTheBottom() {
    const element = document.querySelector('.main-section');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  onValueChange(selectedColumnData: QueryValueChangesExt) {
    let selectedValue = selectedColumnData.value.value;
    this.currentDragulaId = Number(selectedColumnData.fieldId);
    const matchingFieldFromOriginal = this.allFields.find(
      field => field.displayName === selectedValue
    );

    if (matchingFieldFromOriginal) {
      this.updateColumnUsage(selectedValue);
      let deletedSuccessful =
        this.typeAheadConfigurationService.removeAnItemFromFieldNameList(
          selectedValue
        );
      if (deletedSuccessful) {
        this.validateAndEmitNotification();
      }
    } else {
      const currentItem = this.temporaryColumnOrder.find(
        x => x.dragulaId === this.currentDragulaId
      );
      // When a field value is cleared.
      if (currentItem.name) {
        selectedValue = this.getFieldDisplayNameByFieldName(currentItem.name);
        // Restore the field into field display name list.
        this.typeAheadConfigurationService.addAnItemIntoFieldNameList(
          selectedValue
        );
        currentItem.name = ''; // clear field name.
      }
      currentItem.dragulaId = currentItem.dragulaId;
      currentItem.clause.valueComponentState = ''; // clears input text.
      currentItem.clause = this.getQueryClause('');
      currentItem.inputConfig =
        this.typeAheadConfigurationService.getColumnPickerFilterInputConfig();
      currentItem.fieldValid = false;
      this.validateAndEmitNotification();
    }
  }

  /**
   * Handle clicking remove button on a column
   *
   * @param columnIndex Index of column in array
   */
  onClickRemoveColumn(columnIndex: number) {
    const toBeRestoredItemName = this.temporaryColumnOrder[columnIndex].name;
    this.typeAheadConfigurationService.addAnItemIntoFieldNameList(
      this.getFieldDisplayNameByFieldName(toBeRestoredItemName)
    );

    if (
      this.groupByFieldDisplayName ===
      this.temporaryColumnOrder[columnIndex].clause.valueComponentState
    ) {
      this.displayBanner = true;
    }

    // Remove column at index
    this.temporaryColumnOrder.splice(columnIndex, 1);
    this.updateColumnUsage();
    this.validateAndEmitNotification();
  }

  /**
   * Add new column to list of selections
   */
  onClickAddColumn() {
    const dragulaId: number = this.optionCounter++;
    this.temporaryColumnOrder.push({
      name: '',
      dragulaId,
      clauseKey: '',
      clause: this.getQueryClause(''),
      inputConfig:
        this.typeAheadConfigurationService.getColumnPickerFilterInputConfig(),
      fieldValid: true
    });

    this.lastDivId = dragulaId.toString();
    timer(300).subscribe(() => {
      this.positionScrollbarAtTheBottom();
    });

    this.validateAndEmitNotification();
  }

  updateColumnUsage(displayName?: string) {
    let fieldName: string;
    if (displayName) {
      fieldName = this.getFieldNameByDisplayName(displayName);
    }
    // Map indexes of column usage
    this.columnUsage = {};
    this.temporaryColumnOrder.forEach((col: any, i) => {
      if (col.name) {
        // when existing filed is replaced with new field.
        if (
          fieldName &&
          fieldName !== col.name &&
          this.currentDragulaId === col.dragulaId
        ) {
          this.typeAheadConfigurationService.addAnItemIntoFieldNameList(
            this.getFieldDisplayNameByFieldName(col.name)
          );
          col.name = fieldName;
        }
        this.columnUsage[col.name] = i;
        col.fieldValid = true;
      } else if (displayName) {
        if (
          fieldName &&
          this.currentDragulaId === col.dragulaId &&
          !this.isFieldAlreadySelected(fieldName)
        ) {
          col.clause.valueComponentState = displayName;
          col.name = fieldName;
        }
      }
    });
  }

  getInvalidColumnErrorMessage(fieldId: string) {
    const matchingField = this.allFields.find(
      (field: EventField) => field.id === fieldId
    );
    if (matchingField) {
      return substituteTemplateValues(this.invalidColumnMessage, {
        field: matchingField.displayName
      });
    } else {
      return this.unknownColumnMessage;
    }
  }

  /**
   * Function for use by trackBy in Angular template, to force tracking identity of items by array index rather
   * than object identity
   *
   * @param index Array index
   */
  trackByDragulaId(_index: number, option: any) {
    return option.dragulaId;
  }

  // backup the column names actually emitted
  // We only want to emit the column names iff there are any changes
  private validateAndEmitNotification(): void {
    // we only proceed iff user focused on this UI component
    if (!this.userReactedToThisComponent) {
      return;
    }

    // no need to proceed if no valid target
    if (!this.temporaryColumnOrder || this.temporaryColumnOrder.length < 1) {
      return;
    }

    // if there are any column invalid, meaning the editing is in progress
    const editInProgress = !!this.temporaryColumnOrder.find(
      col => !col.name || col.name === ''
    );

    if (editInProgress) {
      if (this.editorStatus !== EditorStatus.EditingInProgress) {
        // notify the parent container the status change
        this.editorStatus = EditorStatus.EditingInProgress;
        this.statusUpdate.emit(this.editorStatus);
      }

      return;
    }

    // reaching here --> meaning all the columns are guaranteed to be OK
    if (this.editorStatus !== EditorStatus.OK) {
      // notify the parent container the status change
      this.editorStatus = EditorStatus.OK;
      this.statusUpdate.emit(this.editorStatus);
    }

    const colNamesToEmit = this.temporaryColumnOrder.map(col => col.name);
    const changesOccurred: boolean =
      this.previousEmittedColumns.length !== colNamesToEmit.length ||
      !this.previousEmittedColumns.every((colName, index) => colName === colNamesToEmit[index]);

    if (changesOccurred) {
      this.previousEmittedColumns = colNamesToEmit;
      this.columnsUpdate.emit(this.temporaryColumnOrder);
    }
  }

  private isFieldAlreadySelected(fieldName: string): boolean {
    const result = this.temporaryColumnOrder.find(x => x.name === fieldName);
    return result !== undefined;
  }

  private getQueryClause(fieldName: string): QueryClause {
    const clause = new QueryClause();
    clause.valueComponentState = fieldName;
    clause.propertyConfig =
      this.typeAheadConfigurationService.getColumnPickerFilterInputConfig().optionalPropertiesConfig[0];
    return clause;
  }

  private getFieldNameByDisplayName(displayName: string): string {
    const matchingField = this.allFields.find(
      (field: EventField) => field.displayName === displayName
    );
    if (matchingField) {
      return matchingField.id;
    } else {
      return undefined;
    }
  }

  private getFieldDisplayNameByFieldName(fieldName: string): string {
    const matchingField = this.allFields.find(
      (field: EventField) => field.id === fieldName
    );
    if (matchingField) {
      return matchingField.displayName;
    } else {
      return undefined;
    }
  }

  private setupTemplateText(): void {
    const flyoutText =
      AuditingDisplayStringsProvider.auditing.pages.newSearches;
    this.flyoutColumnSubHeader = flyoutText.flyoutColumnSubHeader;
    this.flyoutColumnLabel = flyoutText.flyoutColumnLabel;
    this.addButton = flyoutText.addColumnButton;
    this.removeTooltip = flyoutText.removeColumn;
    this.reorderTooltip = flyoutText.reorder;
    this.placeholder = flyoutText.emptyColumnPlaceholder;
    this.errorEmptyFieldValue = flyoutText.errorEmptyFieldValue;
    this.invalidColumnMessage = flyoutText.invalidColumnMessage;
    this.unknownColumnMessage = flyoutText.unknownColumnMessage;
    this.displayBannerLabel =
        AuditingDisplayStringsProvider.auditing.pages.newSearches.flyoutGroupByColumnDeletedLabel;
  }
}
