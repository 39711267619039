import { SigninsWidgetResponse } from '../models/signins.models';

export const LOAD_SIGN_INS = '[Auditing Dashboard] Load Sign-ins';
export const UPDATE_SIGN_INS = '[Auditing Dashboard] Update Sign-ins';

export const LOAD_SIGN_INS_FAIL = '[Auditing Dashboard] Load Sign-ins Fail';
export const UPDATE_SIGN_INS_FAIL = '[Auditing Dashboard] Update Sign-ins Fail';

export const LOAD_SIGN_INS_SUCCESS =
  '[Auditing Dashboard] Load Sign-ins Success';
export const UPDATE_SIGN_INS_SUCCESS =
  '[Auditing Dashboard] Update Sign-ins Success';

export class LoadSignins {
  readonly type = LOAD_SIGN_INS;
}

// eslint-disable-next-line max-classes-per-file
export class LoadSigninsSuccess {
  readonly type = LOAD_SIGN_INS_SUCCESS;
  constructor(public payload: SigninsWidgetResponse) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadSigninsFail {
  readonly type = LOAD_SIGN_INS_FAIL;
  constructor(public payload: any) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateSignins {
  readonly type = UPDATE_SIGN_INS;
  constructor(public payload: string[]) {}
}

// eslint-disable-next-line max-classes-per-file
export class UpdateSigninsSuccess {
  readonly type = UPDATE_SIGN_INS_SUCCESS;
}

// eslint-disable-next-line max-classes-per-file
export class UpdateSigninsFail {
  readonly type = UPDATE_SIGN_INS_FAIL;
  constructor(public payload: any) {}
}

export type SigninsAction =
  | LoadSignins
  | LoadSigninsSuccess
  | LoadSigninsFail
  | UpdateSignins
  | UpdateSigninsSuccess
  | UpdateSigninsFail;
