<od-modal
    [modalConfig]="modalConfig"
    (closeClicked)="closeModalHandler(false)"
  >
  <div modalContent>
    <div>
      <span>{{this.removeModalWarning}}</span>
      <br/>
      <br/>
    </div>
    <div>
      <span>
        {{this.removeModalConfirmation}}
      </span>
    </div>
  </div>

  <div modalFooter class="dialog-btns">
      <div>
        <button (click)='closeModalHandler(false)'class="btn-secondary waves-effect">
          <span class="btn-wrapper">{{this.secondaryButtonText}}</span>
        </button>
      </div>

      <div>
        <button (click)='closeModalHandler(true)' class="btn waves-effect">
          <span class="btn-wrapper">{{this.primaryButtonText}}</span>
        </button>
      </div>
  </div>
</od-modal>
