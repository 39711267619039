import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemoizedSelector, Store } from '@ngrx/store';
import { BaseComponent } from '@ondemand/core';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Query } from '../../../../models/query.model';
import { AuditModulePermissionsService } from '../../../../services/audit-module-permissions.service';
import * as SearchPermissions from '../../../../models/audit-permissions.model';
import { AuditingDashboardResponse } from '../../models/auditing-dashboard.models';
import { StaticIndicatorID } from '../../models/widget-info';
import {
  StaticIndicatorConfigurationResponse,
  StaticIndicatorDataResponse,
  StaticIndicatorData,
  StaticIndicatorDataset,
  StaticIndicatorSettings,
  StaticIndicatorResponse,
  TrendColor
} from './models/static-indicator.models';
import {
  createStaticIndicatorAction,
  createUpdateTrendColorAction
} from './state/static-indicator-action.factory';
import { StaticIndicatorState } from './state/static-indicator.reducer';
import {
  createDataSelector,
  createErrorSelector,
  createLoadingSelector,
  createTrendColorSelector
} from './state/static-indicator.selector.factory';
import { ActiveQueryService } from '../../../../services/active-query.service';

@Component({
  selector: 'static-indicator',
  templateUrl: './static-indicator.component.html',
  styleUrls: ['./static-indicator.component.scss']
})
export class StaticIndicatorComponent extends BaseComponent implements OnInit {
  @Input() iconName: string;
  @Input() iconColor: string;
  @Input() displayTitle: string;
  @Input() refreshObservable$: Observable<void>;

  data$: Observable<StaticIndicatorResponse>;
  dataError$: Observable<boolean>;
  dataLoading$: Observable<any>;
  trendColor$: Observable<string>;

  indicatorData: StaticIndicatorData;
  indicatorSettings: StaticIndicatorSettings;
  userQuery: Query;

  indicatorValue: number;
  indicatorTimeFrame: string;
  trendValue = 0;
  trendTimeFrame: string;
  noChangeText = 'No Change';
  userHasRunSearchPermission = false;
  enablePercentage = false;

  private _id: StaticIndicatorID;

  @Input() set IndicatorId(value: StaticIndicatorID) {
    this._id = value;
    this.initializeRedux();
  }
  get IndicatorId(): StaticIndicatorID {
    return this._id;
  }

  constructor(
    private store: Store<StaticIndicatorState>,
    private activeQueryService: ActiveQueryService,
    private router: Router,
    private permissionsService: AuditModulePermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenToRefresh();
    this.userHasRunSearchPermission =
      this.permissionsService.hasAnyOfPermissions([
        SearchPermissions.canRunPrivateSearch,
        SearchPermissions.canRunSharedSearches
      ]);
  }

  initializeRedux(): void {
    if (this.IndicatorId) {
      this.data$ = this.store.select(createDataSelector(this.IndicatorId));
      this.trendColor$ = this.store.select(
        createTrendColorSelector(this.IndicatorId)
      );
      this.dataLoading$ = this.store.select(
        createLoadingSelector(this.IndicatorId)
      );
      this.dataError$ = this.store.select(
        createErrorSelector(this.IndicatorId)
      );
      this.data$
        .pipe(
          takeUntil(this.destructionSubject),
          filter(response => !!response && !!response.data)
        )
        .subscribe(response => {
          this.indicatorData = response.data[this.IndicatorId];
          this.indicatorSettings = response.configuration[this.IndicatorId];
          this.userQuery = new Query(this.indicatorSettings.userQuery);
          this.update();
        });

      this.loadStaticIndicatorData();
    }
  }

  loadStaticIndicatorData(): void {
    if (this.IndicatorId) {
      this.store.dispatch(createStaticIndicatorAction(this.IndicatorId));
    }
  }

  handleErrorRetry(): void {
    this.loadStaticIndicatorData();
  }

  getPointerStyle(): any {
    return { cursor: this.userHasRunSearchPermission ? 'pointer' : 'default' };
  }

  runQuery(): void {
    if (this.userHasRunSearchPermission) {
      const resultsUrl = '/auditing/auditing/queries/results';
      this.activeQueryService.setQuery(this.userQuery, this.userQuery);
      this.router.navigate([resultsUrl]);
    }
  }

  private listenToRefresh() {
    this.refreshObservable$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(_ => this.loadStaticIndicatorData());
  }

  private updateTrendColor(trendColor: string): void {
    this.store.dispatch(
      createUpdateTrendColorAction(this.IndicatorId, trendColor)
    );
  }

  private update(): void {
    if (
      this.indicatorData &&
      this.indicatorData.datasets &&
      this.indicatorData.datasets.length === 2
    ) {
      const datasetWithTime = this.indicatorData.datasets.map(data => {
        const timeString = data.label.split(' - ', 1)[0];
        const dataTime = new Date(timeString);
        return { ...data, time: dataTime };
      });

      datasetWithTime.sort((a, b) => a.time.valueOf() - b.time.valueOf());

      const dataLen = datasetWithTime.length;
      const prevValue = datasetWithTime[dataLen - 2].data;
      this.indicatorValue = datasetWithTime[dataLen - 1].data;
      this.trendValue = this.indicatorValue - prevValue;

      if (this.trendValue !== 0) {
        const trendColor =
          this.trendValue > 0 ? TrendColor.negative : TrendColor.positive;
        this.updateTrendColor(trendColor);
      }

      const timeFrame = this.getTimeFrame();
      this.indicatorTimeFrame = `During last ${timeFrame}`;
      this.trendTimeFrame = `From previous ${timeFrame}`;
      this.noChangeText = `No change from previous ${timeFrame}`;
    } else {
      this.noChangeText = ' ';
    }
  }

  private getTimeFrame(): string {
    let timeframeStr = '';
    const dateRangeClause = this.userQuery.q.clauses.find(
      clause => clause.field === 'Date' && clause.operator === 'during_last'
    );
    if (dateRangeClause) {
      const dateRangeStr = dateRangeClause.value as string;
      if (dateRangeStr.indexOf('_') >= 0) {
        timeframeStr = dateRangeStr.replace('_', ' ');
      }
    }
    return timeframeStr;
  }
}
