/**
 * Component for Audit module configuration page view
 */

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';

@Component({

  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    return;

  }

}
