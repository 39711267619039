<form #clauseForm="ngForm">
  <div class="row" *ngIf="clause">

    <error-message *ngIf="invalidFieldSelection">{{invalidFieldSelectionMessage}}</error-message>

    <div class="left filter-icon-container"><edc-icon name="filter" title="{{ 'auditing.filter' | localize }}"></edc-icon></div>

    <div class="field-container left input-field">
      <select #fieldSelection="ngModel"
              materialize="material_select"
              [materializeSelectOptions]="fields"
              name="fields-{{i}}"
              id="fields-{{i}}"
              [(ngModel)]="clause.field"
              (change)="onFieldChange()"
              required
              class="field-selection-menu"
              >

          <ng-container *ngFor="let field of fields">
            <option
              [value]="field.id"
              *ngIf="field.isSearchable || field.id === clause.field"
              [disabled]="!field.isSearchable"
              >{{ field.displayName }}</option>
          </ng-container>
      </select>
    </div>

    <div class="operator-container left input-field">
      <select #operator="ngModel"
              materialize="material_select"
              [materializeSelectOptions]="clause.availableOperators"
              [(ngModel)]="clause.operator"
              name="operator-{{i}}"
              id="operator-{{i}}"
              required
              (change)="onOperatorChange()"
              class="operator-selection-menu"
              >
        <ng-container *ngIf="clause.field">
          <ng-container *ngFor="let operator of clause.availableOperators">
            <option [value]="operator.id">{{ operator.label }}</option>
          </ng-container>
        </ng-container>
        </select>
    </div>

    <div class="value-field-container left">
      <ng-container [ngSwitch]="true">

        <ng-container *ngSwitchCase="selectedField?.dataType === 'datetime'">
          <ng-container *ngIf="clause.operator === 'during_last'; else datetimeField">
            <!-- Date Interval Field -->
            <datetime-interval
              name="value-{{i}}"
              [(ngModel)]="clause.value"
              (change)="onValueChange()"
              #dateInterval="ngModel"
            ></datetime-interval>
            <div class="clearfix left validation-error" *ngIf="dateInterval.invalid && dateInterval.dirty">
              <error-message >{{ 'auditing.pages.newSearches.badIntervalError' | localize }}</error-message>
            </div>
          </ng-container>

          <ng-template #datetimeField>
            <!-- Precise date field -->
            <datetime-field
              [index]="i"
              name="value-{{i}}"
              [(ngModel)]="clause.value"
              [operator]="clause.operator"
              (change)="onValueChange()"
              #datetime="ngModel"
            ></datetime-field>
            <div class="clearfix left validation-error" *ngIf="datetime.invalid && datetime.dirty">
              <error-message >{{ 'auditing.pages.newSearches.badDatetimeError' | localize }}</error-message>
            </div>
          </ng-template>

        </ng-container>

        <!-- Boolean value field -->
        <ng-container *ngSwitchCase="selectedField?.dataType === 'boolean' && !predefinedValuesOnly">
          <boolean-field
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.value"
            [operator]="clause.operator"
            (change)="onValueChange()"
            #booleanField="ngModel"
          ></boolean-field>
        </ng-container>

        <!-- Multi-value field, e.g., "In", "Not In"-->
        <ng-container *ngSwitchCase="isMultiValueOperator(clause.operator)">
          <multi-value-field
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.values"
            [operator]="clause.operator"
            [field]="clause.field"
            [predefinedOnly]="predefinedValuesOnly"
            (change)="onValueChange()"
            (reset)="markValuePristine()"
            #multiValue="ngModel"
          ></multi-value-field>
          <div class="clearfix left validation-error" *ngIf="multiValue.dirty && multiValue.invalid">
            <error-message >{{ 'auditing.pages.newSearches.chipsEmptyError' | localize}}</error-message>
          </div>
        </ng-container>

        <!-- Numeric type field -->
        <ng-container *ngSwitchCase="isNumericField() && !predefinedValuesOnly">
          <!-- Map to "value" or "values" depending on whether this has multiple values -->
          <number-field
            *ngIf="isBetweenNumberOperator(clause.operator)"
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.values"
            [operator]="clause.operator"
            (change)="onValueChange()"
            #numberField="ngModel"
            required
          ></number-field>
          <number-field
            *ngIf="!isBetweenNumberOperator(clause.operator)"
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.value"
            [operator]="clause.operator"
            (change)="onValueChange()"
            #numberField="ngModel"
            required
          ></number-field>
        </ng-container>

        <ng-container *ngSwitchCase="!isMultiValueOperator(clause.operator) && predefinedValuesOnly">
          <predefined-value-field
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.value"
            [field]="clause.field"
            (change)="onValueChange()"
          ></predefined-value-field>
        </ng-container>

        <!-- Plain text input by default -->
        <ng-container *ngSwitchDefault>
          <plain-text-field
            #plainTextField="ngModel"
            [index]="i"
            name="value-{{i}}"
            [(ngModel)]="clause.value"
            (change)="onValueChange()"
          ></plain-text-field>

          <div class="clearfix left validation-error" *ngIf="plainTextField.dirty && plainTextField.invalid">
            <error-message >{{ 'auditing.emptyValueFieldError' | localize}}</error-message>
          </div>
        </ng-container>

      </ng-container>
    </div>

    <div class="remove-clause-container left">
      <a *ngIf="removable" (click)="removeClause()"
        class="tooltipped"
        data-position="top"
        attr.data-tooltip="{{ 'auditing.pages.newSearches.removeClauseTooltip' | localize }}"
        materialize="tooltip"
        data-delay="500"
      >
        <edc-icon name="cross"></edc-icon>
      </a>
    </div>

    <p class="error-message validation-error"
      *ngIf="fieldSelection.invalid && (fieldSelection.dirty || fieldSelection.touched)"
      >{{  'auditing.queryBuilderFieldError' | localize }}</p>
    <p class="error-message validation-error"
      *ngIf="operator.invalid && (operator.dirty || operator.touched)"
      >{{  'auditing.queryBuilderOperatorError' | localize }}</p>

  </div>
</form>
