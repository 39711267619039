<qod-dismissible-notification
  *ngIf="displayTimeseriesWarning"
  class ="notification-container"
  [type]="'info'"
  [text]="pointsExceededLabel"
  [dismissible]="true"
  (crossClicked)="handleDismissWarningClick()"
></qod-dismissible-notification>
<div id="timeseries-chart-component-container">
  <div *ngIf="noEvents" class="timeseries-chart-container header-section">
    <qod-attention-image-with-text [imageUrl]="noResultsImagePath" [altImageUrl]="applicationStrings.altImageUrlLabel"
      [mainTitle]="applicationStrings.noResultFound" [subTitle]="applicationStrings.adjustYourFilter">
    </qod-attention-image-with-text>
  </div>
  <div *ngIf='timeSeriesInitialized && !noEvents'>
    <base-generic-chart 
      [displayLegend]="false" 
      [chartLabels]="chartLabels" 
      [options]="options" 
      [chartType]="chartType"
      [containerStyle]="containerStyle" 
      [chartPosition]="chartPosition" 
      [datasets]="datasets"
      [chartColors]="chartColors">
    </base-generic-chart>
    <div class="legend-container">
      <oda-chart-legend (legendItemSelectionChange)="legendItemSelectionChange($event)" [dataItems] ="chartLegendItems"></oda-chart-legend>
    </div>
  </div>
</div>