<div class="hbar-container" >
  
  <div *ngIf="error" class="error-box  one-column-flex-child">
    <error-card
      [error]="errorText"
      [button]="tryAgainText"
      (buttonClick)="retryOnError()">
    </error-card>
  </div>
  
  <div *ngIf="noResults && !error" class="no-results-message-box  one-column-flex-child">
    <qod-attention-image-with-text
      [imageUrl]="noResultsImagePath"
      [altImageUrl]="noResultImgAltText"
      [mainTitle]="noResultFoundTitle"
      [subTitle]="adjustFilterTitle">
    </qod-attention-image-with-text>
  </div>

  <ng-container *ngIf="dataReady && !noResults && !error">
    <div id="hbar-box" class="hbar-area  one-column-flex-child">
      <base-generic-chart
        [displayLegend]="false"
        [chartLabels]="chartLabels"
        [options]="chartOptions"
        [chartType]="chartType"
        [containerStyle]="containerStyle"
        [chartPosition]="canvasStyleObj"
        [datasets]="chartDatasets"
        [chartColors]="chartColors"></base-generic-chart>
    </div>
  
    <div class="legend-items-area  one-column-flex-child">
      <oda-check-legend-hbox
        [legendTitle]="legendTitle"
        [legendItems]="legendItems"
        (changedLegend)="onCheckLegendChanged($event)"
      > </oda-check-legend-hbox>
    </div>
  </ng-container>

</div>