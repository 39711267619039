export interface LegendDataItem {
  label: string;
  value?: string;
  tooltip: string;
  borderColor: string;
  backColor: string;
  order: number;
  checked: boolean;
  disable: boolean;
}

export interface LegendEventArgs {
  index: number;
  show: boolean;
  currentLegendItems: LegendDataItem[];
}

export function formatLegendItemLabel(value: string) {
  const minCharsLength = 21;
  const maxCharsLength = 24;
  if ( value ) {
  if (value.length < maxCharsLength + 1) {
     return  value;
  }
  return  `${value.substring(0, minCharsLength)}...`;
}
  return undefined;
}
