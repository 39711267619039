/**
 * Component for tabbed navigating within auditing module
 */

import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { BaseComponent } from '@ondemand/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EProvisionedState } from '../../../shared/models/provision.model';

import * as fromPermissions from '../../models/audit-permissions.model';
import { ProvisioningGuard } from '../../route-guards/provisioning.guard';
import { ProvisioningService } from '../../services/organization-provisioning/provisioning.service';

@Component({
  selector: 'auditing-tabs',
  templateUrl: './auditing-tabs.component.html',
  styleUrls: ['./auditing-tabs.component.scss']
})
export class AuditingTabsComponent extends BaseComponent implements OnInit {
  navigationAllowed$: Observable<boolean> = of(false);

  fromPermissions: any = fromPermissions;

  constructor(
    private provisioningGuard: ProvisioningGuard,
    private provisioningService: ProvisioningService
  ) {
    super();
  }

  async ngOnInit() {
    // Subscribe to changed navigation allowed status
    // and initialize it with correct value.
    this.provisioningGuard.navigationAllowed$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(currentProvisioningStatus => {
        this.navigationAllowed$ = of(currentProvisioningStatus);
      });
    let provisionStatus =
      (await this.provisioningService.getProvisioningStatus()) ===
      EProvisionedState.Provisioned;
    this.navigationAllowed$ = of(provisionStatus);
  }
}
