export class QueryClause {

  /**
   * Code for search operator, e.g., 'between_date'
   */
  operator: string;
  /**
   * Id of field selected
   */
  field: string;

  /**
   * User-entered value
   */
  value?: any;

  /**
   * Array of values. This is used only in when multiple values are required.
   */
  values?: any[];

  availableOperators: any[] = [];

  constructor(params?: any) {
    Object.assign(this, params);
    if (this.values) {
      // Clone values array
      this.values = this.values.concat();
    }
  }

  toJSON() {
    return {
      operator: this.operator,
      field: this.field,
      value: this.value,
      values: this.values
    };
  }

  equals(clause: QueryClause) {
    return this.field === clause.field &&
      this.operator === clause.operator &&
      this.value === clause.value &&
      JSON.stringify(this.values) === JSON.stringify(clause.values);
  }
}
