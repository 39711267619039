import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { IModalWindow, BaseComponent, EDialogType } from '@ondemand/core';
import { AuditingDisplayStringsProvider } from '../../../../../application-strings-EN';

@Component({
  selector: 'dismiss-modal',
  templateUrl: './dismiss-modal.component.html',
  styleUrls: ['./dismiss-modal.component.scss']
})
export class DismissModalComponent extends BaseComponent implements OnInit {
  @Output() hideItem: EventEmitter<boolean> = new EventEmitter();

  modalParams: IModalWindow;
  errorMessage: string;
  hideAllFutureEvents: boolean;

  labels =
    AuditingDisplayStringsProvider.auditing.pages.criticalActivity
      .dissmissalModal;
  dismissModalTitle: string;
  dismissModalExplanation: string;
  dismissHideActionExplanation: string;
  cancelDismissModal: string;
  confirmDismissModal: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.modalParams = {
      showModal: false,
      dialogParams: {
        type: EDialogType.WARNING,
        title: this.labels.title,
        hideCancel: true
      }
    };
  }

  openModal() {
    // please do all necessary reset in the openModal()
    this.hideAllFutureEvents = false;
    this.modalParams.showModal = true;
    this.modalParams = { ...this.modalParams };
  }

  closeModal() {
    this.modalParams.showModal = false;
    this.modalParams = { ...this.modalParams };
  }

  onCheckboxValueChange(event: any) {
    this.hideAllFutureEvents = event.target.checked;
  }

  onCancelDismissClick() {
    this.closeModal();
  }

  onConfirmDismissClick() {
    this.hideItem.emit(this.hideAllFutureEvents);
    this.closeModal();
  }
}
