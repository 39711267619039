import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';
import { Subject } from 'rxjs';

import * as auditPermissions from '../models/audit-permissions.model';
import { AuditModulePermissionsService } from '../services/audit-module-permissions.service';

@Directive({ selector: '[odaIfHasPermissions]' })
export class PermissionsDirective {
  hasView = false;

  usersPermissions: string[] = [];

  destructionSubject: Subject<any> = new Subject<any>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auditPermissionsService: AuditModulePermissionsService
  ) {}

  @Input() set odaIfHasPermissions(
    requiredPermissions: auditPermissions.AuditPermission[]
  ) {
    const userHasAnyAllowedPermissions = this.auditPermissionsService.hasAnyOfPermissions(
      requiredPermissions
    );

    if (userHasAnyAllowedPermissions) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      // Permissions are not defined.
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
