import { WidgetInformation } from '../../models/widget-info';

export const LOAD_WIDGET_LIST = '[Auditing Dashboard] Load Widget List';
export const LOAD_WIDGET_LIST_FAIL =
  '[Auditing Dashboard] Load Widget List Fail';
export const LOAD_WIDGET_LIST_SUCCESS =
  '[Auditing Dashboard] Load Widget List Success';

export class LoadWidgetList {
  readonly type = LOAD_WIDGET_LIST;
}

// eslint-disable-next-line max-classes-per-file
export class LoadWidgetListSuccess {
  readonly type = LOAD_WIDGET_LIST_SUCCESS;
  constructor(public payload: WidgetInformation[]) {}
}

// eslint-disable-next-line max-classes-per-file
export class LoadWidgetListFail {
  readonly type = LOAD_WIDGET_LIST_FAIL;
  constructor(public payload: any) {}
}

export type WidgetListAction =
  | LoadWidgetList
  | LoadWidgetListFail
  | LoadWidgetListSuccess;
