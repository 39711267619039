<div class="input-field">
  <input
    placeholder=" {{'auditing.pages.newSearches.value' | localize }}"
    #fieldValue="ngModel"
    type="text"
    name="value-{{index}}"
    [(ngModel)]="value"
    (change)="onValueChange()"
    (input)="onValueChange()"
    maxlength="250"
    id="value-{{index}}"
    class="plain-text-field"
    autocomplete="off"
    cannotBeBlank

    reactiveBridge
    (statusChange)="onStatusChange()"
    >
</div>
