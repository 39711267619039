<form #dateForm="ngForm">
<!-- First date -->
<div class="first-date datetime-field left">
  <div class="input-field left date-field">
    <input
      type="text"
      title="{{ 'auditing.pages.newSearches.date' | localize }}"
      placeholder="{{ 'auditing.pages.newSearches.date' | localize }}"
      class="datepicker"
      materialize="pickadate"
      [materializeParams]="[datepickerOptions]"
      id="datepicker-{{index}}"
      name="datepicker-{{index}}"
      [(ngModel)]="formValues.firstDate.date"
      required
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>
  <div class="left calendar-icon-container">
    <edc-icon name="calendar"></edc-icon>
  </div>
  <div class="input-field left hour-field">
    <input
      placeholder="{{ 'auditing.pages.newSearches.hours' | localize }}"
      title="{{ 'auditing.pages.newSearches.hour' | localize }}"
      type="text"
      maxlength="2"
      id="hour-{{index}}"
      name="hour-{{index}}"
      [(ngModel)]="formValues.firstDate.hour"
      (change)="onDateChange()"
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>
  <div class="left time-separator">:</div>
  <div class="input-field left minute-field">
    <input
      type="text"
      maxlength="2"
      placeholder="{{ 'auditing.pages.newSearches.minutes' | localize }}"
      title="{{ 'auditing.pages.newSearches.minute' | localize }}"
      id="minute-{{index}}"
      name="minute-{{index}}"
      [(ngModel)]="formValues.firstDate.minute"
      (change)="onDateChange()"
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>

  <div class="left date-separator" *ngIf="operator === 'between_date'">-</div>
</div>

<!-- Second date (end date) -->
<div class="second-date datetime-field left" *ngIf="operator === 'between_date'">
  <div class="input-field left date-field">
    <input
      required
      placeholder="{{ 'auditing.pages.newSearches.date' | localize }}"
      title="{{ 'auditing.pages.newSearches.date' | localize }}"
      type="text"
      class="datepicker"
      materialize="pickadate"
      [materializeParams]="[datepickerOptions]"
      id="datepicker-end-{{index}}"
      name="datepicker-end-{{index}}"
      [(ngModel)]="formValues.secondDate.date"
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>
  <div class="left calendar-icon-container">
    <edc-icon name="calendar"></edc-icon>
  </div>

  <div class="input-field left hour-field">
    <input
    placeholder="{{ 'auditing.pages.newSearches.hours' | localize }}"
      title="{{ 'auditing.pages.newSearches.hour' | localize }}"
      type="text"
      maxlength="2"
      id="hour-end-{{index}}"
      name="hour-end-{{index}}"
      [(ngModel)]="formValues.secondDate.hour"
      (change)="onDateChange()"
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>
  <div class="left time-separator">:</div>
  <div class="input-field left minute-field">
    <input
      placeholder="{{ 'auditing.pages.newSearches.minutes' | localize }}"
      title="{{ 'auditing.pages.newSearches.minute' | localize }}"
      type="text"
      maxlength="2"
      id="minute-end-{{index}}"
      name="minute-end-{{index}}"
      [(ngModel)]="formValues.secondDate.minute"
      (change)="onDateChange()"
      [class.invalid]="!valid && dateForm.dirty"
      autocomplete="off"
    >
  </div>
</div>
</form>
