import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { AppFacadeService, BaseComponent } from '@ondemand/core';
import {
  BheConfigFormFieldData,
  BloodHoundConfigState
} from './bhe-config-state';
import { BHEStringsEN } from '../../../../../shared/application-strings/bhe-strings-EN';
import { BloodHoundConfigurationService } from '../../../../services/bhe-service/bhe-config.service';
import {
  BHEAPIConfigurationInput,
  BHEConfiguration
} from '../../../../services/bhe-service/bhe-api.data';
import {
  SvgIconName,
  NotificationMode,
  AuditConstants
} from '../../../../../shared/models/ui-constants';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AlertService } from '../../../../services/alerts.service';
import { AlertPlan } from '../../../../models/alert-plan.model';
import { ODToastService } from '@ondemand/ui-components';
import { toastLowerLeft, ToastType } from '../../../../../shared/utils/toast.wrapper';

@Component({
  selector: 'bhe-config-editor',
  templateUrl: './bhe-config-editor.component.html',
  styleUrls: ['./bhe-config-editor.component.scss']
})
export class BloodHoundEnterpriseConfigEditorComponent
  extends BaseComponent
  implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();

  bheConfigForm: UntypedFormGroup;
  bheUrlField: UntypedFormControl;
  bheTokenId: UntypedFormControl;
  bheKey: UntypedFormControl;

  chevronIconName: SvgIconName;
  notificationIconName: SvgIconName;
  notificationMode: NotificationMode;

  // text for UI
  headingText: string;
  subHeadingText: string;
  learnMoreDescription: string;
  learnMoreButtonLabel: string;
  validateBtnLabel: string;
  saveBtnLabel: string;
  cancelBtnLabel: string;
  howtoStepsTitle: string;
  howtoStep1: string;
  howtoStep2: string;
  howtoStep3: string;
  howtoStep4: string;
  howtoStep5: string;
  howtoStep6: string;
  howtoStep7: string;
  urlFieldLabel: string;
  urlFieldPlaceHolder: string;
  urlFieldHint: string;
  tokenFieldLabel: string;
  tokenFieldPlaceHolder: string;
  tokenFieldHint: string;
  keyFieldLabel: string;
  keyFieldPlaceHolder: string;
  notificationMessage: string;
  savingMessage: string;
  // end of Text for UI

  isOpen: boolean;
  learnMoreExpanded: boolean;
  showBanner: boolean;
  inputValidated: boolean;
  savingConfig: boolean;

  existingConfig: BHEConfiguration;
  IsAlertPlanHasRecepientConfigured = false;
  organizationId: string;

  get diableValidateButton(): boolean {
    if (this.inputValidated) {
      return true;
    } else {
      const formOK: boolean =
        this.bheConfigForm.valid &&
        this.bheConfigForm.touched &&
        this.bheUrlField.value &&
        this.bheTokenId.value &&
        this.bheKey.value;

      return !formOK;
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bheAPI: BloodHoundConfigurationService,
    private alertService: AlertService,
    private facadeService: AppFacadeService,
    private toastService: ODToastService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.isOpen = false;
    this.learnMoreExpanded = false;
    this.inputValidated = false;
    this.showBanner = false;
    this.savingConfig = false;
    BloodHoundConfigState.error = false;
    BloodHoundConfigState.saveError = false;
    BloodHoundConfigState.clearFieldStates();
    this.setupText();
    this.organizationId = await this.facadeService.selectedOrgId$
    .pipe(take(1))
    .toPromise();
    this.setIfBHEAlertPlanHasRecepientConfigured();
  }

  openEditor(configFromCaller: BHEConfiguration = null): void {
    this.isOpen = true;
    this.learnMoreExpanded = false;
    this.inputValidated = false;
    this.showBanner = false;
    this.savingConfig = false;
    BloodHoundConfigState.error = false;
    BloodHoundConfigState.saveError = false;
    BloodHoundConfigState.clearFieldStates();
    this.setupLearnMoreText();
    this.bheConfigForm.reset();
    this.existingConfig = configFromCaller;
    if (this.existingConfig) {
      this.bheUrlField.setValue(configFromCaller.apiEndpoint);
    }
  }

  handleCloseEvent(): void {
    this.isOpen = false;
    this.closeEvent.emit(false); // cancel, no configuration saved
  }

  toggleLearnMore(): void {
    this.learnMoreExpanded = !this.learnMoreExpanded;
    this.setupLearnMoreText();
  }

  dismissBanner(): void {
    this.showBanner = false;
    if (BloodHoundConfigState.saveError) {
      BloodHoundConfigState.saveError = false;
    }
  }

  validateBheConfig(): void {
    const configInput = this.getConfigurationInput();

    this.bheAPI
      .validateConfig(configInput)
      .pipe(take(1))
      .subscribe(
        resp => {
          BloodHoundConfigState.error = !resp.ok;
          this.inputValidated = resp.ok;
          this.triggerValidation();
          this.setupNotification();
        },
        error => {
          BloodHoundConfigState.error = true;
          this.triggerValidation();
          this.setupNotification();
        }
      );
  }

  saveBheConfig(): void {
    this.savingConfig = true;
    const configInput = this.getConfigurationInput();
    const response$: Observable<HttpResponse<any>> = this.existingConfig
      ? this.bheAPI.updateConfig(this.existingConfig.id, configInput)
      : this.bheAPI.addNewConfig(configInput);

    response$.pipe(take(1)).subscribe(
      resp => {
        if (!resp.ok) {
          BloodHoundConfigState.saveError = true;
          this.setupNotification();
        } else {
          toastLowerLeft(this.toastService, BHEStringsEN.ConfigEditorSuccessSaveToast, ToastType.Success);
          this.isOpen = false;
          this.closeEvent.emit(!this.IsAlertPlanHasRecepientConfigured);
        }
      },
      error => {
        BloodHoundConfigState.saveError = true;
        this.setupNotification();
      }
    );
  }

  configStateValidator(control: AbstractControl): ValidationErrors | null {
    if (BloodHoundConfigState.error) {
      return { badConfig: true };
    }
    return null;
  }

  urlValidator(control: AbstractControl): ValidationErrors | null {
    let validUrl = true;
    const valueStr = `${control.value}`;

    if (valueStr.startsWith('https://')) {
      try {
        const url = new URL(valueStr);
      } catch {
        validUrl = false;
      }
    } else {
      validUrl = false;
    }
    return validUrl ? null : { invalidUrl: true };
  }

  private setIfBHEAlertPlanHasRecepientConfigured(): void {
    let bheAlertPlanId = `AP.${AuditConstants.BloodhoundAlertPlanId}.${this.organizationId}`;
    this.alertService.getAlertPlan(bheAlertPlanId).pipe(take(1)).subscribe(
      response => {
        if (response.status === 200) {
          const data: AlertPlan = response.body;
          this.IsAlertPlanHasRecepientConfigured = data.actions.length > 0;
        }
      },
      error => {
        this.IsAlertPlanHasRecepientConfigured = false;
      }
    );
  }

  private getConfigurationInput(): BHEAPIConfigurationInput {
    const configInput: BHEAPIConfigurationInput = {
      apiEndpoint: this.bheUrlField.value,
      credential: {
        tokenId: this.bheTokenId.value,
        tokenKey: this.bheKey.value
      }
    };

    return configInput;
  }

  private initForm(): void {
    const guidPattern =
      '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$';
    this.bheUrlField = new UntypedFormControl('', [
      this.urlValidator,
      this.configStateValidator
    ]);
    this.bheTokenId = new UntypedFormControl('', [
      Validators.pattern(guidPattern),
      this.configStateValidator
    ]);
    this.bheKey = new UntypedFormControl('', this.configStateValidator);
    this.bheConfigForm = this.formBuilder.group({
      bheUrlField: this.bheUrlField,
      bheTokenId: this.bheTokenId,
      bheKey: this.bheKey
    });

    this.bheConfigForm.valueChanges
      .pipe(takeUntil(this.destructionSubject))
      .subscribe((x: BheConfigFormFieldData) => {
        if (BloodHoundConfigState.fieldValueChanged(x)) {
          BloodHoundConfigState.setFieldState(x);
          this.inputValidated = false;
          BloodHoundConfigState.error = false;
          this.dismissBanner();
          this.triggerValidation();
        }
      });
  }

  private triggerValidation(): void {
    this.bheUrlField.updateValueAndValidity();
    this.bheTokenId.updateValueAndValidity();
    this.bheKey.updateValueAndValidity();
  }

  private setupNotification(): void {
    this.notificationIconName =
      BloodHoundConfigState.saveError || BloodHoundConfigState.error
        ? SvgIconName.Error
        : SvgIconName.Success;
    this.notificationMessage = BloodHoundConfigState.saveError
      ? BHEStringsEN.ConfigEditorNotificationApiSaveFailure
      : BloodHoundConfigState.error
      ? BHEStringsEN.ConfigEditorNotificationValidationFailure
      : BHEStringsEN.ConfigEditorNotificationValidationSuccess;
    this.notificationMode =
      BloodHoundConfigState.saveError || BloodHoundConfigState.error
        ? NotificationMode.Error
        : NotificationMode.Success;
    this.showBanner = true;
  }

  private setupText(): void {
    this.headingText = BHEStringsEN.ConfigEditorHeading;
    this.subHeadingText = BHEStringsEN.ConfigEditorSubHeading;
    this.learnMoreDescription = BHEStringsEN.ConfigEditorLearnMoreDescription;
    this.validateBtnLabel = BHEStringsEN.ConfigEditorValidateBtnLabel;
    this.saveBtnLabel = BHEStringsEN.ConfigEditorSaveBtnLabel;
    this.cancelBtnLabel = BHEStringsEN.ConfigEditorCancelBtnLabel;
    this.howtoStepsTitle = BHEStringsEN.ConfigEditorHowToStepsTitle;
    this.howtoStep1 = BHEStringsEN.ConfigEditorHowToStep1;
    this.howtoStep2 = BHEStringsEN.ConfigEditorHowToStep2;
    this.howtoStep3 = BHEStringsEN.ConfigEditorHowToStep3;
    this.howtoStep4 = BHEStringsEN.ConfigEditorHowToStep4;
    this.howtoStep5 = BHEStringsEN.ConfigEditorHowToStep5;
    this.howtoStep6 = BHEStringsEN.ConfigEditorHowToStep6;
    this.howtoStep7 = BHEStringsEN.ConfigEditorHowToStep7;
    this.urlFieldLabel = BHEStringsEN.ConfigEditorUrlFieldLabel;
    this.urlFieldPlaceHolder = BHEStringsEN.ConfigEditorUrlFieldPlaceHolder;
    this.urlFieldHint = BHEStringsEN.ConfigEditorUrlFieldHint;
    this.tokenFieldLabel = BHEStringsEN.ConfigEditorTokenIdFieldLabel;
    this.tokenFieldPlaceHolder =
      BHEStringsEN.ConfigEditorTokenIdFieldPlaceHolder;
    this.tokenFieldHint = BHEStringsEN.ConfigEditorTokenIdFieldHint;
    this.keyFieldLabel = BHEStringsEN.ConfigEditorKeyFieldLabel;
    this.keyFieldPlaceHolder = BHEStringsEN.ConfigEditorKeyFieldPlaceHolder;
    this.savingMessage = BHEStringsEN.ConfigEditorSavingWaitMessage;
    this.setupLearnMoreText();
  }

  private setupLearnMoreText(): void {
    this.learnMoreButtonLabel = this.learnMoreExpanded
      ? BHEStringsEN.ConfigEditorLearnMoreExpandedLabel
      : BHEStringsEN.ConfigEditorLearnMoreCollapsedLabel;

    this.chevronIconName = this.learnMoreExpanded
      ? SvgIconName.ChevronUp
      : SvgIconName.ChevronDown;
  }
}
