<general-qod-component [loading]="loading" [error]="error">

  <ng-container component-loading>
    <qod-small-summary-card-loading></qod-small-summary-card-loading>
  </ng-container>

  <ng-container component-error>
    <small-summary-card-misconfiguration
      [icon]="'status-error'"
      [colorOfIcon]="'danger-red'"
      message="{{ 'auditing.pages.auditingDashboard.error' | localize }}"
      [colorOfMessage]="'danger-red'"
      linkText="{{ 'auditing.pages.auditingDashboard.tryAgain' | localize }}"
      (linkClicked)="errorActionClicked.emit()">
    </small-summary-card-misconfiguration>
  </ng-container>

  <ng-container component-data>
    <qod-small-summary-card-data
      [figureValue]="figureValue"
      [figureUnit]="figureUnit"
      [includeChange]="includeChange"
      [change]="change"
      [includePercentage]="enablePercentage"
      [changeTimeFrame]="changeTimeFrame"
      [negativeThing]="negativeTrend"
      [noChangeText]="noChangeText"
      [showExactChange]="true"></qod-small-summary-card-data>
  </ng-container>

</general-qod-component>
