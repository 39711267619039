import { ONDEMAND_CHART_COLORS } from '@ondemand/core';
import { ChartDataSets } from 'chart.js';

// Interface object to encapsulate datapoints used by timeseries chart.
export interface TimeseriesData {
  labels: string[];
  baseline: number[];
  total: number[];
  anomalies: boolean[];
}

// Timeseries charts use the same template, so we need only plug in the relevant datapoints.
export function generateTimeseriesChartDataTemplate(
  backgroundColors: Chart.ChartColor[],
  pointRadius: number[],
  pointStyles: Chart.PointStyle[],
  anomaliesQueryData: TimeseriesData
): ChartDataSets[] {
  const timeseriesChartDataTemplate: ChartDataSets[] = [
    {
      label: 'Anomalies',
      borderColor: '#FF0000',
      backgroundColor: '#FF0000',
      pointStyle: 'triangle'
    },
    {
      label: 'Total',
      borderColor: ONDEMAND_CHART_COLORS.indigo,
      pointBackgroundColor: backgroundColors,
      data: anomaliesQueryData.total,
      backgroundColor: backgroundColors,
      fill: false,
      pointRadius,
      pointHoverRadius: pointRadius,
      pointStyle: pointStyles
    },
    {
      label: 'Baseline',
      borderColor: ONDEMAND_CHART_COLORS.rawSienna,
      pointBackgroundColor: ONDEMAND_CHART_COLORS.rawSienna,
      backgroundColor: ONDEMAND_CHART_COLORS.rawSienna,
      data: anomaliesQueryData.baseline,
      pointRadius: 3,
      pointHoverRadius: 3,
      fill: false
    }
  ];

  return timeseriesChartDataTemplate;
}
