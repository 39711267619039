
<div class="dropdown-button-container">
  <button
    type="button"
    class="btn primary-button"
    [class.disabled]="primaryButtonOptions.disabled"
    [attr.id]="primaryButtonOptions.id"
    (click)="triggerAction(primaryButtonOptions.action)"
    ><edc-icon *ngIf="primaryButtonOptions.icon" name="{{primaryButtonOptions.icon}}"></edc-icon>{{primaryButtonOptions.label}}</button>
  <button
    [class.disabled]="primaryButtonOptions.disabled"
    type="button"
    class="btn secondary-menu"
    (click)="toggleDropdown()"
    ><edc-icon class="secondary-menu-icon" name="chevron-down"></edc-icon></button>

  <div
    class="hidden-dropdown-trigger"
    materialize="dropdown"
    data-activates="dropdown-menu"
    [materializeParams]="[dropdownOptions]"
    [materializeActions]="dropdownActions"
    ></div>

  <ul id="dropdown-menu" class="dropdown-content">
    <li *ngFor="let item of menuOptions" [class.disabled]="item.disabled">
      <a (click)="triggerAction(item.action)"><edc-icon *ngIf="item.icon" name="{{item.icon}}"></edc-icon>{{item.label}}</a>
    </li>
  </ul>
</div>
