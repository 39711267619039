<div class="alert-plan-list-container">
  <internal-loading [isLoading]="loading"></internal-loading>

  <div class="top-section">
    <button [routerLink]="['/auditing/auditing/alerts/plans/new']"
            class="btn right new-alert-plan-button"
            id="new-alert-plan-button"
            *ngIf="canCreateNewAlertPlans()">
      <edc-icon name="plus"></edc-icon>
      {{ 'auditing.newPlanButton' | localize }}
    </button>
  </div>

  <error-message *ngIf="error">{{ error | async }}</error-message>

  <table class="striped" *ngIf="alertPlanRows && !error">
    <thead>
      <tr>
        <th>{{ 'auditing.alertPlanNameHeader' | localize }}</th>
        <th>{{ 'auditing.alertPlanTypeHeader' | localize }}</th>
        <th>{{ 'auditing.alertPlanNumRulesHeader' | localize }}</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr class="alert-plan-row" *ngFor="let row of alertPlanRows">
        <td class="alert-plan-name-cell">
          <a (click)="toggleExpandedStatus(row)"
             class="expand-link"
             attr.aria-controls="plan-{{ row.alertPlan.id }}-rule-list"
             attr.aria-expanded="{{ row.expanded }}">
            <div class="alert-plan-name-container">
              <span class="alert-plan-name">
                {{ row.alertPlan.name }}
              </span>

              <span class="expand-button" *ngIf="!row.expanded">
                <edc-icon name="chevron-right"></edc-icon>
              </span>
              <span class="collapse-button" *ngIf="row.expanded">
                <edc-icon name="chevron-down"></edc-icon>
              </span>
            </div>
          </a>

          <div class="alert-rule-list-container"
               id="rule-{{ row.alertPlan.id }}-rule-list"
               *ngIf="row.expanded">
            <div class="loading-message" *ngIf="row.loadingRules">
              <indeterminate-loader information=""></indeterminate-loader>
            </div>

            <ng-container *ngIf="!row.loadingRules">
              <last-saved-info-field [showLastSavedInfo]="row.alertPlan.isShared"
                                     [lastSavedLabelInfo]="row.alertPlan.lastSavedLabelInfo | async"
                                     [lastSavedToolTipRefreshProp]="lastSavedToolTipRefreshProp"
                                     [lastSavedToolTipInfo]="row.alertPlan.lastSavedToolTipInfo | async"></last-saved-info-field>

              <div>
                <button class="edit-plans-button btn"
                        type="button"
                        [routerLink]="['/auditing/auditing/alerts/plans', row.alertPlan.id]">
                  {{ 'auditing.editAlertPlanButton' | localize }}
                </button>
              </div>

              <span class="alert-rule-list-title"
                    *ngIf="row.alertRules.length === 0">{{ 'auditing.noAlertRulesAdded' | localize }}</span>
              <span class="alert-rule-list-title"
                    *ngIf="row.alertRules.length > 0">{{ 'auditing.alertRuleListTitle' | localize }}</span>
              <ul class="alert-rule-list browser-default">
                <li class="alert-rule" *ngFor="let rule of row.alertRules">
                  <a (click)="onClickEditRule(rule)">{{ rule.name }}</a>
                  <ng-template #alertRuleName>
                    <span class="alert-rule__name">
                      {{
                      rule.name
                      }}
                    </span>
                  </ng-template>
                </li>
              </ul>
            </ng-container>
          </div>
        </td>

        <td class="alert-plan-type">
          <span *ngIf="row.alertPlan.isShared">{{ 'auditing.alertPlanShared' | localize }}</span>
          <span *ngIf="!row.alertPlan.isShared">{{ 'auditing.alertPlanPrivate' | localize }}</span>
        </td>

        <td class="num-alert-rules-cell">
          {{ row.alertPlan.numAlertRules }}
        </td>

        <td>
          <a *ngIf="canDeleteAlertPlan(row.alertPlan) && !row.alertPlan.isBuiltIn"
             (click)="onDeletePlanClick(row.alertPlan)"
             class="delete-plan-link tooltipped"
             materialize="tooltip"
             data-position="top"
             data-delay="50"
             attr.data-tooltip="{{
              'auditing.deleteAlertPlanTooltip' | localize
            }}">
            <edc-icon name="cross"></edc-icon>
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <alert-rule-editor-modal (alertRuleSaved)="onAlertRuleEditSave($event)"></alert-rule-editor-modal>
</div>

<ng-container *ngIf="successModalParams && successModalParams.showModal">
  <modal-window [params]="successModalParams">
    <div modal-content class="success-modal-content">
      <p>{{ 'auditing.alertEnableSuccessDescription1' | localize }}</p>
      <p>
        <a routerLink="{{ successModalContent.linkUrl }}">
          {{
          successModalContent.linkTextPath | localize
          }}
        </a>
        {{ 'auditing.alertEnableSuccessDescription2' | localize }}
      </p>
    </div>
  </modal-window>
</ng-container>

<modal-window [params]="deletePlanWithAlertsModalParams" *ngIf="deletePlanWithAlertsModalParams">
  <div modal-content>
    <div class="delete-plan-with-alerts-modal">
      <span class="delete-plan-message">
        {{ deletePlanMessage }}
      </span>
    </div>
  </div>
</modal-window>
