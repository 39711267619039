export interface BheConfigFormFieldData {
  bheUrlField: string;
  bheTokenId: string;
  bheKey: string;
}

export class BloodHoundConfigState {
  static error = false;
  static saveError = false;

  static urlField = '';
  static tokenIdField = '';
  static tokenKeyField = '';

  static clearFieldStates(): void {
    BloodHoundConfigState.urlField = '';
    BloodHoundConfigState.tokenIdField = '';
    BloodHoundConfigState.tokenKeyField = '';
  }

  static setFieldState(fieldData: BheConfigFormFieldData): void {
    BloodHoundConfigState.urlField = fieldData.bheUrlField;
    BloodHoundConfigState.tokenIdField = fieldData.bheTokenId;
    BloodHoundConfigState.tokenKeyField = fieldData.bheKey;
  }

  static fieldValueChanged(fieldData: BheConfigFormFieldData): boolean {
    return (
      BloodHoundConfigState.urlField !== fieldData.bheUrlField ||
      BloodHoundConfigState.tokenIdField !== fieldData.bheTokenId ||
      BloodHoundConfigState.tokenKeyField !== fieldData.bheKey
    );
  }
}
