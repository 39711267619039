export enum OdaChartType {
  Donut = 'Donut',
  TimeSeries = 'Time series',
  TimeSeriesAnomalyDetection = 'Time series Anomaly Detection',
  Pie = 'Pie',
  H_BAR = 'Horizontal Bar',
}

export enum RenderType {
  // Visualize By dropdown options
  // used in the search results panel
  Grid = 'Grid',
  ChartAndGrid = 'Chart & Grid',
  Chart = 'Chart',
}

export enum IntervalCategory {
  // Summarize by dropdown options
  // used in the search results panel
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}
