import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromFeature from '../../../../reducers';

const updateFavoriteSearchState = createSelector(
  fromFeature.selectAuditingDashboardFeatureState,
  state => state.favoriteSearchEditor
);

export const selectFavoriteSearchEditorUpdateCompletion = createSelector(
  updateFavoriteSearchState,
  state => state.updateCompleted
);

export const selectFavoriteSearchEditorError = createSelector(
  updateFavoriteSearchState,
  state => state.error
);
