import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { NgForm } from '@angular/forms';
import { IModalWindow, BaseComponent } from '@ondemand/core';
import { LocaleStringsService } from '../../../../../services/locale-strings.service';
import { SelectionEntry } from './models/selection-entry';
import {
  FavoriteSearchesCategory,
  FavoriteSearchesQuery
} from '../models/favorite-search.models';
import { FavoriteSearchEditorState } from './state/favorite-search-editor.reducer';
import { Store } from '@ngrx/store';
import {
  selectFavoriteSearchEditorError,
  selectFavoriteSearchEditorUpdateCompletion
} from './state/favorite-search-editor.selector';
import { UpdateFavoriteSearch } from './state/favorite-search-editor.actions';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'favorite-search-editor',
  templateUrl: './favorite-search-editor.component.html',
  styleUrls: ['./favorite-search-editor.component.scss']
})
export class FavoriteSearchEditorComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() favoriteQueryIds: string[];
  @Input() availableCategories: FavoriteSearchesCategory[];
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @ViewChild('editSearchesForm') form: NgForm;

  modalParams: IModalWindow;
  displayCategories: FavoriteSearchesCategory[];
  selectionEntries: SelectionEntry[];
  trackingNumber = 0;
  noSelectedSearchPrompt: string;
  noAvailableSearchesPrompt: string;

  errorOccurred: boolean;
  updateError$: Observable<any>;
  updateCompleted$: Observable<boolean>;

  constructor(
    private store: Store<FavoriteSearchEditorState>,
    private localeStringsService: LocaleStringsService,
    private dragulaService: DragulaService
  ) {
    super();
    this.modalParams = { showModal: false };

    // Set up the drag & drop
    const handleClass = 'item-order-grabber';
    this.dragulaService.createGroup('favorite-searches-bag', {
      moves: (_el: Element, _container: Element, handle: Element) =>
        handle.className === handleClass
    });

    // Mark form as changed if items are re-ordered
    this.dragulaService.drop().pipe(
      takeUntil(this.destructionSubject))
      .subscribe(() => {
        this.form.form.markAsDirty();
      });
  }

  ngOnInit(): void {
    this.localeStringsService.strings$.pipe(
      takeUntil(this.destructionSubject))
      .subscribe(labels => {
        this.modalParams.dialogParams = {
          hideCancel: true,
          title:
            labels.auditing.pages.auditingDashboard.favoriteSearches.editor
              .title
        };

        this.noAvailableSearchesPrompt =
          labels.auditing.pages.auditingDashboard.favoriteSearches.editor.searchDropDown.noSearchesAvailablePrompt;
        this.noSelectedSearchPrompt =
          labels.auditing.pages.auditingDashboard.favoriteSearches.editor.searchDropDown.selectSearchPrompt;
      });

    this.updateError$ = this.store.select(selectFavoriteSearchEditorError);
    this.updateCompleted$ = this.store.select(
      selectFavoriteSearchEditorUpdateCompletion
    );

    this.updateError$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(err => (this.errorOccurred = !!err));

    this.updateCompleted$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(isCompleted => {
        if (isCompleted) {
          this.onUpdateCompletion();
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dragulaService.destroy('favorite-searches-bag');
  }

  initializeDisplayCategories() {
    if (this.availableCategories) {
      this.displayCategories = this.availableCategories.map(cate => ({ id: cate.id, name: cate.name, queries: null }));
    }
  }

  initializeSelectionEntries() {
    if (this.favoriteQueryIds && this.favoriteQueryIds.length > 0) {
      this.selectionEntries = this.favoriteQueryIds.map(id => {
        let entry = new SelectionEntry();
        entry.queryId = id;
        return entry;
      });

      this.updateSelectionEntries();
    } else {
      this.selectionEntries = [];
    }
  }

  updateSelectionEntries() {
    this.selectionEntries.forEach(selection => {
      let currentQueryId: string = null;
      let currentCategoryId: string = null;
      let currentCategory: FavoriteSearchesCategory = null;

      if (selection.queryId) {
        // initialization or selection of search is changed
        currentQueryId = selection.queryId;
        currentCategory = this.findParentCategory(currentQueryId);
        currentCategoryId = currentCategory.id;
      } else if (selection.categoryId) {
        // category selection changed
        currentCategoryId = selection.categoryId;
        currentCategory = this.availableCategories.find(
          x => x.id === currentCategoryId
        );
      } else {
        // new entries added; neither category nor query is selected
        // make a default category selection to the first category
        // with selectable queries inside
        currentCategory = this.availableCategories.find(
          x => this.getSelectableQueries(x, null).length > 0
        );
        currentCategoryId = currentCategory.id;
      }

      selection.categoryId = currentCategoryId;
      selection.queries = this.getSelectableQueries(
        currentCategory,
        currentQueryId
      );

      if (!selection.previousCategoryId) {
        // this happens only during initialization
        // previousCategryId has never been set
        selection.previousCategoryId = currentCategoryId;
      }

      selection.trackNum = this.trackingNumber++;
    });
  }

  closeModal() {
    this.modalParams.showModal = false;
  }

  openModal() {
    this.errorOccurred = false;
    this.selectionEntries = null;
    this.form.reset();
    this.initializeDisplayCategories();
    this.initializeSelectionEntries();
    this.modalParams.showModal = true;
    this.modalParams = { ...this.modalParams };
  }

  onCancelClick() {
    this.closeModal();
  }

  onOKeyClick() {
    let updatedIds = this.selectionEntries
      .map(entry => entry.queryId)
      .filter(id => id);

    this.store.dispatch(new UpdateFavoriteSearch(updatedIds));
  }

  onUpdateCompletion() {
    this.updated.emit();
    this.closeModal();
  }

  onAddSearchClick() {
    this.selectionEntries.push(new SelectionEntry());
    this.updateSelectionEntries();
    this.form.form.markAsDirty();
  }

  onSelectCategoryChange(changedEntry: SelectionEntry) {
    if (changedEntry.categoryId !== changedEntry.previousCategoryId) {
      changedEntry.queryId = null;
      this.updateSelectionEntries();
      this.form.form.markAsDirty();
    }
    changedEntry.previousCategoryId = changedEntry.categoryId;
  }

  onSearchSelectionChange() {
    this.updateSelectionEntries();
    this.form.form.markAsDirty();
  }

  onRemoveButtonClick(selectionEntry: SelectionEntry) {
    this.selectionEntries = this.selectionEntries.filter(
      entry => entry.equal(selectionEntry) === false
    );

    this.updateSelectionEntries();
    this.form.form.markAsDirty();
  }

  trackById(_index: number, option: any) {
    return option.trackNum;
  }

  findParentCategory(querid: string): FavoriteSearchesCategory {
    if (this.availableCategories) {
      return this.availableCategories.find(cate => {
        let matchedQuery = cate.queries.find(query => query.id === querid);
        return matchedQuery !== null && matchedQuery !== undefined;
      });
    }
    return null;
  }

  // filter out already selected queries from the category
  getSelectableQueries(
    cate: FavoriteSearchesCategory,
    selectedQueryId: string
  ): FavoriteSearchesQuery[] {
    let otherConfirmedIds = this.selectionEntries
      .map(entry => entry.queryId)
      .filter(queryId => queryId && queryId !== selectedQueryId);

    return cate.queries.filter(query => otherConfirmedIds.includes(query.id) === false);
  }
}
