import { ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { FixedWidthLabelCaculator } from '../../../../../shared/utils/fixed-width-label.calculator';
import { Cell } from '../../../../../auditing/components/queries/results/results.cell.model';
import { Results } from '../../../../../auditing/components/queries/results/results.model';
import { ODChartColors } from '../../../../models/oda-colors';
import { CheckLegendData } from '../../models/legend-data';
import { CountColumnID, OthersColumnID } from '../../models/chart-constants';

export interface CategoryCount {
  dataKey: number;
  categoryLabel: string;
  categoryValue: object;
  count: number;
  enable: boolean;
}

export class HorizontalBarChartDomainModel {
  dataPoints: CategoryCount[];

  private queryResult: Results;
  private groupByColumnId: string;

  get categoryLabels(): string[] {
    // the enabled labels, truncated with '...' if too long
    // will be used to display on Y Axis
    const caculator = new FixedWidthLabelCaculator();
    return this.dataPoints
      .filter(data => data.enable)
      .map(data => caculator.getFixedWidthLabel(data.categoryLabel));
  }

  get chartDataSets(): ChartDataSets[] {
    const dataCounts = this.dataPoints
      .filter(data => data.enable)
      .map(data => data.count);

    return [
      {
        barThickness: 25,
        maxBarThickness: 25,
        data: dataCounts
      } as ChartDataSets
    ];
  }

  get chartColors(): Color[] {
    const dataColors = this.dataPoints
      .filter(data => data.enable)
      .map(data => ODChartColors[data.dataKey]);
    const hoverColors = dataColors.map(color =>
      window.Chart.helpers.getHoverColor(color)
    );
    return [
      {
        backgroundColor: dataColors,
        hoverBackgroundColor: hoverColors
      } as Color
    ];
  }

  get legendItems(): CheckLegendData[] {
    const items = this.dataPoints.map(data => ({
        dataKey: data.dataKey,
        label: data.categoryLabel,
        tooltip: data.categoryLabel,
        borderColor: ODChartColors[data.dataKey],
        backColor: ODChartColors[data.dataKey],
        checked: data.enable,
        disable: false
      } as CheckLegendData));

    if (items.length === 1) {
      items[0].disable = true;
    }

    return items;
  }

  get categoryCount(): number {
    if (!this.dataPoints) {
      return 0;
    }
    return this.dataPoints.length;
  }

  constructor(chartResult: Results) {
    this.queryResult = chartResult;
    this.initialize();
  }

  setCategoryEnable(categoryLabel: string, enable: boolean): void {
    const idx = this.dataPoints.findIndex(
      data => data.categoryLabel === categoryLabel
    );
    if (idx >= 0) {
      this.dataPoints[idx].enable = enable;
    }
  }

  private initialize() {
    this.groupByColumnId = this.extractGroupByColumnName();
    this.dataPoints = this.extractData();
  }

  private extractGroupByColumnName(): string {
    if (
      !this.queryResult ||
      !this.queryResult.headers ||
      this.queryResult.headers.length < 2
    ) {
      return null;
    }

    const nonCountFields = this.queryResult.headers.filter(
      cell => cell.id !== CountColumnID
    );
    return nonCountFields[0].id;
  }

  private extractData(): CategoryCount[] {
    if (
      !this.queryResult ||
      !this.groupByColumnId ||
      !this.queryResult.rows ||
      this.queryResult.rows.length < 1
    ) {
      return [];
    }

    const datas: CategoryCount[] = this.queryResult.rows.map(row => {
      const countCell: Cell = row.cells.find(
        cell => cell.columnId === CountColumnID
      );
      const labelCell: Cell = row.cells.find(
        cell => cell.columnId === this.groupByColumnId
      );

      return {
        categoryLabel: labelCell.label,
        // label in the Cell should be used instead of just value
        // e.g., for predeifined values, we should use the label
        // to properly configure the clauses
        // it will match the displayed values in the result grid.
        categoryValue: labelCell.value,
        count: countCell.value,
        enable: true
      } as CategoryCount;
    });

    if (!datas || datas.length < 1) {
      return [];
    }

    if (datas.length === 1) {
      datas[0].dataKey = 0;
      return datas;
    }

    const others = datas.find(data => data.categoryLabel === OthersColumnID);
    const orderedDatas = datas
      .filter(data => data.categoryLabel !== OthersColumnID)
      .sort((a, b) =>
         b.count - a.count // descending order
      );

    if (others) {
      orderedDatas.push(others);
    }

    return orderedDatas.map((data, index) => {
      data.dataKey = index; // assign the data key before returing
      return data;
    });
  }
}
