import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  BaseComponent,
  IInputConfig,
  QueryClause,
  QueryFilterChanges
} from '@ondemand/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowClickedEvent,
  RowDataChangedEvent
} from 'ag-grid-community';
import { CriticalActivityListState } from './state/critical-activity-list.reducer';
import {
  selectCriticalActivityListData,
  selectCriticalActivityListError,
  selectCriticalActivityListExpired,
  selectCriticalActivityListLoading
} from './state/critical-activity-list.selectors';
import {
  LoadCriticalActivityList,
  UpdateCriticalActivityList
} from './state/critical-activity-list.actions';
import {
  CriticalActivityDataTimeOut,
  CRITICAL_ACTIVITY_LIST_COLUMN_DEFINITIONS,
  CRITICAL_ACTIVITY_LIST_DEFAULT_COLUMN_DEFINITION,
  CRITICAL_ACTIVITY_LIST_GRID_OPTIONS,
  eventCountColumnDefinition,
  getFilterInputConfig
} from './configuration/critical-activity-list.config';
import { ActivatedRoute } from '@angular/router';
import {
  CriticalActivityDataSet,
  CriticalActivityDismissedOrHideItem,
  CriticalActivityResponse,
  UpdateCriticalActivitySettings
} from '../../../../shared/models/critical-activity.shared.models';
import { Observable } from 'rxjs';
import { CriticalActivityListDataRow } from './models/critical-activity-list.models';
import { executeCriticalActivityFilter } from './services/critical-activity-filters';
import { LocaleStringsService } from '../../../services/locale-strings.service';
import { Query } from '../../../models/query.model';
import { AuditModulePermissionsService } from '../../../services/audit-module-permissions.service';
import * as SearchPermissions from '../../../models/audit-permissions.model';
import {
  isCriticalActivityDatasetValid,
  getCriticalActivityHiddenDatasets,
  getCriticalActivityDismissedDatasets,
  getCriticalActivityVisibleDatasets
} from '../../../../shared/services/critical-activity/critical-activity-lookup';
import { HiddenItemEditorComponent } from './hidden-item-editor/hidden-item-editor.component';
import { transformCriticalActivityListData } from './services/critical-activity-list-transformer';

@Component({
  selector: 'critical-activity-list',
  templateUrl: './critical-activity-list.component.html',
  styleUrls: ['./critical-activity-list.component.scss']
})
export class CriticalActivityListComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('componentHeader') componentHeader: ElementRef;
  @ViewChild(HiddenItemEditorComponent)
  hiddenItemEditor: HiddenItemEditorComponent;

  showFilters: boolean;
  inputConfig: IInputConfig;
  noResultsTemplate: string;

  noFilterResultsTitle: string;
  noFilterResultsMessage: string;
  noCriticalActivity: string;

  noDataMessage: string;
  noResultsFoundMessage: string;
  gridApi: GridApi;
  filtersCount = 0;

  displayFilter = false;
  displayHiddenItems = false;
  waitForInitialFilterChange = false;
  filterQueryClauses: QueryClause[];
  filteredDataRows: CriticalActivityListDataRow[];
  unfilteredCacheData: CriticalActivityDataSet[];
  lastCacheTime: Date;
  dismissedOrHiddenItem: CriticalActivityDismissedOrHideItem = null;
  selectedRowQuery: Query = null;
  flyoutChartQueryId: string = null;
  hasPermissionsForFlyoutChart = false;
  hiddenList: CriticalActivityDataSet[];
  dismissedList: CriticalActivityDataSet[];
  hiddenListCount: number;
  detailsSelected = false;

  gridOptions: GridOptions;
  columnDefs: ColDef[];
  defaultColDef: ColDef;

  data$: Observable<CriticalActivityResponse>;
  dataLoading$: Observable<boolean>;
  dataExpired$: Observable<boolean>;
  dataError$: Observable<any>;
  validDataResponse$: Observable<CriticalActivityResponse>;

  private _showFlyoutRightColumn = false;
  get showFlyoutRightColumn(): boolean {
    return this._showFlyoutRightColumn;
  }
  set showFlyoutRightColumn(value: boolean) {
    this._showFlyoutRightColumn = value;
    this.checkFlyoutRightColumn();
  }

  get noResults(): boolean {
    return this.filteredDataRows && this.filteredDataRows.length === 0;
  }

  constructor(
    private store: Store<CriticalActivityListState>,
    private activatedRoute: ActivatedRoute,
    private stringService: LocaleStringsService,
    private permissionsService: AuditModulePermissionsService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.componentHeader.nativeElement.focus();
  }

  ngOnInit(): void {
    this.noResultsTemplate = '<div></div>';
    this.filterQueryClauses = [];
    this.filteredDataRows = [];
    this.unfilteredCacheData = [];
    this.initializePermissions();

    // initial filter config
    this.inputConfig = getFilterInputConfig();
    this.setTemplateStrings();

    this.data$ = this.store.select(selectCriticalActivityListData);
    this.dataLoading$ = this.store.select(selectCriticalActivityListLoading);
    this.dataError$ = this.store.select(selectCriticalActivityListError);
    this.dataExpired$ = this.store.select(selectCriticalActivityListExpired);
    this.validDataResponse$ = this.data$.pipe(
      takeUntil(this.destructionSubject),
      filter(response => isCriticalActivityDatasetValid(response))
    );

    this.activatedRoute.params
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(params => {
        if (params && params.label) {
          this.inputConfig = getFilterInputConfig(params.label);
          this.showFilters = true;
          this.waitForInitialFilterChange = true;
          this.detailsSelected = true;
        }
      });

    this.validDataResponse$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(response => {
        this.hiddenList = getCriticalActivityHiddenDatasets(response);
        this.dismissedList = getCriticalActivityDismissedDatasets(response);
        this.unfilteredCacheData = getCriticalActivityVisibleDatasets(response);
        this.hiddenListCount = this.hiddenList.length;
        this.prepareListDataAndFilter();
      });

    this.dataExpired$
      .pipe(takeUntil(this.destructionSubject))
      .subscribe(expired => {
        if (expired) {
          this.loadCriticalActivityList();
          // force cache expiration by assigning a time in the past
          this.lastCacheTime = new Date(1990, 1, 1);
        }
      });

    this.columnDefs = CRITICAL_ACTIVITY_LIST_COLUMN_DEFINITIONS;
    this.defaultColDef = CRITICAL_ACTIVITY_LIST_DEFAULT_COLUMN_DEFINITION;
    this.gridOptions = CRITICAL_ACTIVITY_LIST_GRID_OPTIONS;

    this.processDataUpdate();
  }

  processDataUpdate(): void {
    if (this.cacheDataExpire()) {
      this.loadCriticalActivityList();
      this.lastCacheTime = new Date();
    } else {
      this.prepareListDataAndFilter();
    }
  }

  cacheDataExpire(): boolean {
    return (
      !this.lastCacheTime ||
      Number(new Date()) - Number(this.lastCacheTime) >
        CriticalActivityDataTimeOut
    );
  }

  prepareListDataAndFilter(): void {
    if (this.filterQueryClauses && !this.waitForInitialFilterChange) {
      const filteredSourceData = this.unfilteredCacheData.filter(data =>
        executeCriticalActivityFilter(data, this.filterQueryClauses)
      );

      this.filteredDataRows =
        transformCriticalActivityListData(filteredSourceData);
      this.checkOverLays();
    }
  }

  onFiltersChanged(filterChanges: QueryFilterChanges): void {
    this.filtersCount = filterChanges.validClauseCount;
    const filterClauses = filterChanges.filters.model.ruleGroups[0].clauses;

    if (filterChanges.isChangesValid && filterClauses.length > 0) {
      // validate the filters
      this.filterQueryClauses = filterClauses.filter(
        clause => clause && clause.value && clause.value.value
      );
      this.waitForInitialFilterChange = false;
      this.showFlyoutRightColumn = false;
      this.processDataUpdate();
    }
  }

  loadCriticalActivityList(): void {
    this.store.dispatch(new LoadCriticalActivityList());
  }

  onGridSizeChanged(params: GridSizeChangedEvent): void {
    params.api.sizeColumnsToFit();
    if (this.noResults && this.gridApi) {
      this.gridApi.showNoRowsOverlay();
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  onRowClicked(event: RowClickedEvent): void {
    if (event && event.data) {
      const selectedRow = event.data as CriticalActivityListDataRow;
      const sameRowClicked: boolean =
        (!!selectedRow.queryId &&
          !!this.flyoutChartQueryId &&
          selectedRow.queryId === this.flyoutChartQueryId) ||
        (!selectedRow.queryId &&
          !!this.selectedRowQuery &&
          this.selectedRowQuery === selectedRow.query);

      if (!sameRowClicked || !this._showFlyoutRightColumn) {
        this.handleRemoveFlyoutChart();
        this.flyoutChartQueryId = selectedRow.queryId;
        this.selectedRowQuery = selectedRow.query;

        const associatedDismissedOrHiddenObjects =
          this.unfilteredCacheData.filter(
            item => item.label === selectedRow.description
          );

        if (associatedDismissedOrHiddenObjects.length > 0) {
          const ids = associatedDismissedOrHiddenObjects[0];
          this.dismissedOrHiddenItem = {
            dismissalTimestamp: new Date().toUTCString(),
            parameter: ids.dismissOrHideData.parameter,
            queryId: ids.dismissOrHideData.queryId
          };
        }

        // There is a small delay we need here to ensure sync occurs when updating the flyout panel.
        setTimeout(() => {
          this.showFlyoutRightColumn = this.hasPermissionsForFlyoutChart;
        }, 1);
      }
    }
  }

  onRowDataChanged(params: RowDataChangedEvent) {
    // If the "Details" button has been pressed in the critical activity widget,
    // automatically display the flyout chart.
    if (this.detailsSelected && this.filteredDataRows.length > 0) {
      const rows = Array.from(document.getElementsByClassName('ag-row'));
      const firstRow = rows.find(row => {
        const attributes = Array.from(row.attributes);
        const attribute = attributes.find(a => a.name === 'row-index');
        return attribute.nodeValue === '0';
      }) as HTMLDivElement;

      firstRow.click();

      const firstColumn = this.gridOptions.columnApi.getAllDisplayedColumns()[0];
      this.gridApi.setFocusedCell(0, firstColumn);
      this.detailsSelected = false;
    }
  }

  handleRemoveFlyoutChart(): void {
    this.showFlyoutRightColumn = false;
  }

  handleHideOrDismissEvent(hideItem: boolean): void {
    const dismisseditems: CriticalActivityDismissedOrHideItem[] =
      this.dismissedList.map(item => item.dismissOrHideData);

    const hiddenItems: CriticalActivityDismissedOrHideItem[] =
      this.hiddenList.map(item => item.dismissOrHideData);

    if (hideItem) {
      hiddenItems.push(this.dismissedOrHiddenItem);
    } else {
      dismisseditems.push(this.dismissedOrHiddenItem);
    }

    this.updateHiddenOrDismissedItems(hiddenItems, dismisseditems);
  }

  handleEditHiddenItemsClicked(): void {
    if (this.hiddenListCount > 0) {
      this.hiddenItemEditor.openModal();
    }
  }

  handleHiddemItemsUpdated(
    updateHiddenItems: CriticalActivityDismissedOrHideItem[]
  ): void {
    const dismissedItems = this.dismissedList.map(
      item => item.dismissOrHideData
    );
    this.updateHiddenOrDismissedItems(updateHiddenItems, dismissedItems);
  }
  private setTemplateStrings() {
    this.stringService.strings$.pipe(take(1)).subscribe(labels => {
      this.noFilterResultsTitle =
        labels.auditing.pages.criticalActivity.noFilterResultsTemplate.title;

      this.noFilterResultsMessage =
        labels.auditing.pages.criticalActivity.noFilterResultsTemplate.message;

      this.noDataMessage =
        labels.auditing.pages.criticalActivity.noDataTemplate.message;

      this.noCriticalActivity =
        labels.auditing.pages.auditingDashboard.criticalActivity.nodata;
    });
  }
  private updateHiddenOrDismissedItems(
    hiddenCollection: CriticalActivityDismissedOrHideItem[],
    dismissCollection: CriticalActivityDismissedOrHideItem[]
  ): void {
    const payload: UpdateCriticalActivitySettings = {
      resetHiddenItems: hiddenCollection.length < 1,
      hiddenItems: hiddenCollection,
      dismissedItems: dismissCollection
    };

    this.store.dispatch(new UpdateCriticalActivityList(payload));
  }

  private checkOverLays(): void {
    if (this.noResults) {
      if (this.gridApi) {
        this.showNoResultsOverlay();
      } else {
        // This is the case the data may arrive before the grid is initialized.
        // allow short delay to ensure the grid is initialized.
        setTimeout(() => this.showNoResultsOverlay(), 10);
      }
    }

    this.checkPagingPanel();
  }

  private showNoResultsOverlay(): void {
    if (this.filterQueryClauses && this.filterQueryClauses.length > 0) {
      this.noResultsTemplate = `<div>
        <img class="centered_image" src="/assets/auditing/img/no-events.png">
        <h2>${this.noFilterResultsTitle}</h2>
        <h4>${this.noFilterResultsMessage}</h4>
      </div>`;
    } else {
      this.noResultsTemplate = `<div class="center-text high-margin" >
        <div class="status-done-success">
          <img class="centered_image" src="/assets/auditing/img/status-done48.png">
        </div>
        <h2 class="no-data" >${this.noCriticalActivity}</h2>
        <h4 class="no-data-detail">${this.noDataMessage} </h4>
      </div>`;
    }

    this.gridApi.showNoRowsOverlay();
  }

  private checkPagingPanel(): void {
    // Proper Implementation:
    // 1. setting "suppressPaginationPanel" in grid options to true;
    //    so, ag-grid will not render the pagination panel
    // 2. implement our own box for the entire pagination control;
    //    ag-grid library provides call backs for manipulating pages.
    //
    // In the following section, we need to do this
    // post-rendering of the ag-grid (give 10ms delay), so, we
    // can find the element to hide/show
    setTimeout(() => {
      const pagingElements = document.getElementsByClassName('ag-paging-panel');

      if (pagingElements && pagingElements.length === 1) {
        const pagingPanel = pagingElements[0] as HTMLDivElement;
        pagingPanel.hidden = this.noResults;

        if (this.noResults === false) {
          const extraPaginationButtons = document.querySelectorAll('div.ag-paging-button > button');
          Array.from(extraPaginationButtons).forEach(button => button.remove());
        }
      }
    }, 10);
  }

  private checkFlyoutRightColumn(): void {
    if (this.gridOptions && this.gridOptions.columnApi) {
      // please be aware that the following API is using the
      // field name NOT the Column header for lookup.
      this.gridOptions.columnApi.setColumnVisible(
        eventCountColumnDefinition.field,
        !this._showFlyoutRightColumn
      );
    }
  }

  private initializePermissions(): void {
    const sharedSearchPermission = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunSharedSearches
    ]);
    const searchVisualPermission = this.permissionsService.hasAnyOfPermissions([
      SearchPermissions.canRunSearchVisual
    ]);

    this.hasPermissionsForFlyoutChart =
      sharedSearchPermission && searchVisualPermission;
  }
}
