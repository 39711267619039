/**
 * Directive for toggling display of content based on Audit feature flags
 */

import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { AuditingFeatureFlagsService } from '../services/auditing-feature-flags.service';
import { FeatureFlagType } from '../components/shared/feature-flag.enum';
import { Subscription } from 'rxjs';

@Directive({ selector: '[ifAuditFlag]'})
export class IfAuditFlagDirective implements OnInit, OnDestroy {
  @Input() ifAuditFlag: FeatureFlagType;
  private flagServiceSubscription: Subscription;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private flagService: AuditingFeatureFlagsService) { }

  ngOnInit() {
    this.flagServiceSubscription = this.flagService.getFlag(this.ifAuditFlag)
      .subscribe((flagState) => {
        if (flagState === true && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (flagState === false && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy() {
    this.flagServiceSubscription.unsubscribe();
  }
}
