import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable } from 'rxjs';
import {
  EConsentStatus,
  AppFacadeService
} from '@ondemand/core';
import { auditModuleName } from '../util/constants';
import { map } from 'rxjs/operators';

/**
 * Service to retrieve consent values from the Core API.
 *
 * @export
 */
@Injectable()
export class ConsentService {
  /**
   * Initializes a new instance of ConsentService
   */
  constructor(private facade: AppFacadeService) {}

  /**
   * Retrieves the consent status for the Auditing module with the Standard
   * permission
   *
   */
  hasAdminConsentForStandardPermission(
    questTenantId: string
  ): Observable<EConsentStatus> {
    return this.facade
      .tenantConsentList(questTenantId).pipe(
        map(consents => consents.find(a => a.module === auditModuleName)),
        map(a => a.status as EConsentStatus)
      );
  }
}
